/* eslint no-use-before-define: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';

import { Hub } from 'aws-amplify';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';
import isEqual from 'fast-deep-equal';
import { StoreContext } from 'store/store';

import {
  GLOBAL_INFO_STATES,
  HUB_CHANNELS,
  HUB_EVENTS,
  PROPERTY_SETTINGS_TYPES_TO_EXCLUDE_ON_SAVE,
} from 'common/constants';

const useSettings = ({ storeStateInHook = true } = {}) => {
  /* Internal states and refs */

  const { actions, state } = useContext(StoreContext);
  const { globalInfo } = state;
  let originalCampaignSettings = null;
  if (!storeStateInHook) {
    const currentSettings = campaigns.getCurrentCampaignSettings(globalInfo);

    // Only stores a copy of the current campaign settings if they exist.
    if (currentSettings)
      originalCampaignSettings = utility.cloneObject(currentSettings);
  }
  const [currentCampaignSettings, setCurrentCampaignSettings] = useState(null);
  const [currentPropertySettings, setCurrentPropertySettings] = useState(null);
  const initialCampaignSettings = useRef(originalCampaignSettings ?? null);
  const initialPropertySettings = useRef(null);
  const handleReload = useRef(null);
  const handleSave = useRef(null);

  /* Exported states and refs */

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  /* Configure pub-sub message handling */

  useEffect(() => {
    Hub.listen(HUB_CHANNELS.GLOBAL_INFO, handleGlobalInfo);
    Hub.listen(HUB_CHANNELS.SETTINGS, handleMessage);
    return () => {
      Hub.remove(HUB_CHANNELS.GLOBAL_INFO, handleGlobalInfo);
      Hub.remove(HUB_CHANNELS.SETTINGS, handleMessage);
    };
  }, []);

  /* Internal methods */

  const handleGlobalInfo = () => {
    if (typeof handleReload?.current === 'function') {
      handleReload.current();
    }
  };

  const handleMessage = data => {
    const { event } = data.payload;
    if (event === HUB_EVENTS.SETTINGS.REQUEST_SAVE) {
      setSaving(true);
      if (typeof handleSave?.current === 'function') {
        handleSave.current();
      }
    }
  };

  const sendMessage = useCallback((eventName, options) => {
    Hub.dispatch(HUB_CHANNELS.SETTINGS, {
      event: eventName,
      ...options,
    });
  }, []);

  const setUnchanged = useCallback(() => {
    sendMessage(HUB_EVENTS.SETTINGS.STATUS_UNCHANGED);
    setHasChanges(false);
  }, [sendMessage]);

  const settingsArrayToObject = settingsArray => {
    const settingsObject = {};
    settingsArray.forEach(setting => {
      settingsObject[setting.settingsType] = setting;
    });
    return settingsObject;
  };

  /* Exported methods */

  const getCampaignSettings = useCallback(
    async campaignURN => {
      const isFirstLoad = utility.isNull(currentCampaignSettings);
      if (isFirstLoad) {
        setLoading(true);
      }
      try {
        const { campaignSettings } = await API.getCampaignSettings({
          campaignURN,
        });
        const settingsObject = settingsArrayToObject(campaignSettings);
        if (storeStateInHook) {
          setCurrentCampaignSettings(settingsObject);
        }
        initialCampaignSettings.current = utility.cloneObject(settingsObject);
        return settingsObject;
      } catch (error) {
        return null;
      } finally {
        if (isFirstLoad) {
          setLoading(false);
        }
      }
    },
    [currentCampaignSettings, storeStateInHook]
  );

  const getPropertySettings = useCallback(
    async propertyURN => {
      const isFirstLoad = utility.isNull(currentPropertySettings);
      if (isFirstLoad) {
        setLoading(true);
      }
      try {
        const { propertySettings } = await API.getPropertiesSettings({
          propertyURN,
        });
        const settingsObject = settingsArrayToObject(propertySettings);
        if (storeStateInHook) {
          setCurrentPropertySettings(settingsObject);
        }
        initialPropertySettings.current = utility.cloneObject(settingsObject);
        return settingsObject;
      } catch (error) {
        return null;
      } finally {
        if (isFirstLoad) {
          setLoading(false);
        }
      }
    },
    [currentPropertySettings, storeStateInHook]
  );

  const getCampaignSettingType = useCallback(
    settingType => currentCampaignSettings?.[settingType] ?? null,
    [currentCampaignSettings]
  );

  const getCampaignSettingValue = useCallback(
    (settingType, settingKey, initialSettings = false) => {
      const settings = initialSettings
        ? initialCampaignSettings.current
        : currentCampaignSettings;
      return settings?.[settingType]?.[settingKey] ?? null;
    },
    [currentCampaignSettings]
  );

  const getInitialCampaignSettings = useCallback(
    () => utility.cloneObject(initialCampaignSettings.current),
    [initialCampaignSettings]
  );

  const getInitialPropertySettings = useCallback(
    () => utility.cloneObject(initialPropertySettings.current),
    [initialPropertySettings]
  );

  const getPropertySettingValue = useCallback(
    (settingType, settingKey) =>
      currentPropertySettings?.[settingType]?.[settingKey] ?? null,
    [currentPropertySettings]
  );

  const requestGlobalInfo = useCallback(() => {
    actions.refreshGlobalInfo(GLOBAL_INFO_STATES.REFRESHING);
  }, [actions]);

  const requestSave = useCallback(() => {
    sendMessage(HUB_EVENTS.SETTINGS.REQUEST_SAVE);
  }, [sendMessage]);

  const saveBodyElementPositioning = async ({
    campaignURN,
    bodyElementPositioning,
  }) => {
    await API.putBodyElementPositioning({
      campaignURN,
      bodyElementPositioning,
    });
  };

  const saveCampaignDetails = useCallback(
    async ({ campaignURN, ...campaignDetails }) => {
      const originalCampaign = campaigns.getCampaign(
        campaignURN,
        state.globalInfo
      );
      const updatedCampaign = {
        ...originalCampaign,
        ...campaignDetails,
      };
      await API.putCampaigns({
        campaignURN,
        campaignDetails: campaigns.prepareForSave(updatedCampaign),
      });
    },
    [state.globalInfo]
  );

  const saveCampaignSettings = useCallback(
    async ({ campaignURN, campaignSettings = currentCampaignSettings }) => {
      /* Convert settings to an array (if needed) to make it easier to loop over */
      let settingsArray;
      if (Array.isArray(campaignSettings)) {
        settingsArray = campaignSettings;
      } else {
        settingsArray = [];
        Object.keys(campaignSettings).forEach(setting => {
          settingsArray.push(campaignSettings[setting]);
        });
      }
      /* But also create an object version as we need this for tracking */
      const settingsObject = settingsArray.reduce(
        (accumulator, settingsValue) => ({
          ...accumulator,
          [settingsValue.settingsType]: settingsValue,
        }),
        {}
      );
      /* Now identify just the settings that have been changed and save those only */
      const changedSettings = initialCampaignSettings.current
        ? settingsArray.filter(
            setting =>
              !isEqual(
                setting,
                initialCampaignSettings.current[setting.settingsType]
              )
          )
        : campaignSettings;
      await API.putCampaignSettings({
        campaignURN,
        campaignSettings: changedSettings,
      });
      /* Mixpanel tracking */
      const trackingParams = campaigns.getCampaignBrandingDetails({
        campaignSettings: initialCampaignSettings.current,
        suffix: 'Before',
      });
      tracker.track({
        campaignURN,
        eventName: 'Update Campaign Branding Settings',
        trackingParams,
        options: {
          currentCampaignDetails: campaigns.getCampaign(
            campaignURN,
            globalInfo
          ),
          currentCampaignSettings: {
            ...initialCampaignSettings.current,
            ...settingsObject,
          },
        },
      });
      // Get the most up to date campaign settings
      await getCampaignSettings(campaignURN);
      setUnchanged();

      actions.refreshGlobalInfo(GLOBAL_INFO_STATES.UPDATING_SETTINGS, {
        campaignURN,
      });
    },
    [currentCampaignSettings, getCampaignSettings, setUnchanged, actions]
  );

  const savePropertyDetails = useCallback(
    async ({ propertyURN, isStaffUser, ...propertyDetails }) => {
      const originalProperty = properties.getProperty(
        propertyURN,
        state.globalInfo
      );
      const updatedProperty = {
        ...originalProperty,
        ...propertyDetails,
      };
      await API.putProperties({
        propertyURN,
        propertyDetails: properties.prepareForSave(updatedProperty),
        isStaffUser,
      });
    },
    [state.globalInfo]
  );

  const savePropertySettings = useCallback(
    async ({ propertyURN, propertySettings = currentPropertySettings }) => {
      let settingsArray;
      if (Array.isArray(propertySettings)) {
        settingsArray = propertySettings;
      } else {
        settingsArray = [];
        Object.keys(propertySettings).forEach(setting => {
          if (
            PROPERTY_SETTINGS_TYPES_TO_EXCLUDE_ON_SAVE.indexOf(setting) === -1
          ) {
            settingsArray.push(propertySettings[setting]);
          }
        });
      }
      const changedSettings = settingsArray.filter(
        setting =>
          !isEqual(
            setting,
            initialPropertySettings.current[setting.settingsType]
          )
      );
      await API.putPropertiesSettings({
        propertyURN,
        propertySettings: changedSettings,
      });
      // Get the most up to date property settings
      await getPropertySettings(propertyURN);
      setUnchanged();
      actions.refreshGlobalInfo(GLOBAL_INFO_STATES.UPDATING_SETTINGS, {
        propertyURN,
      });
    },
    [currentPropertySettings, getPropertySettings, setUnchanged, actions]
  );

  const setCampaignSettingValues = useCallback(
    (settingsArrayToSet = []) => {
      if (settingsArrayToSet.length !== 0) {
        flushSync(() => {
          const newState = utility.cloneObject(currentCampaignSettings);
          let setChangedFlag = true;
          settingsArrayToSet.forEach(
            ({
              settingType,
              settingKey,
              settingValue,
              doNotSetChangedFlag,
            }) => {
              if (settingType && settingKey) {
                newState[settingType][settingKey] = settingValue;
              }
              if (doNotSetChangedFlag) {
                setChangedFlag = false;
              }
            }
          );
          setCurrentCampaignSettings(newState);
          if (setChangedFlag) {
            if (!isEqual(newState, initialCampaignSettings.current)) {
              sendMessage(HUB_EVENTS.SETTINGS.STATUS_CHANGED);
              setHasChanges(true);
            } else {
              sendMessage(HUB_EVENTS.SETTINGS.STATUS_UNCHANGED);
              setHasChanges(false);
            }
          }
        });
      }
    },
    [currentCampaignSettings, sendMessage]
  );

  const setCampaignSettingValue = useCallback(
    ({
      settingType,
      settingKey,
      settingValue,
      doNotSetChangedFlag = false,
    } = {}) => {
      if (settingType && settingKey) {
        flushSync(() => {
          const newState = utility.cloneObject(currentCampaignSettings);
          newState[settingType][settingKey] = settingValue;
          setCurrentCampaignSettings(newState);
          if (!doNotSetChangedFlag) {
            if (!isEqual(newState, initialCampaignSettings.current)) {
              sendMessage(HUB_EVENTS.SETTINGS.STATUS_CHANGED);
              setHasChanges(true);
            } else {
              sendMessage(HUB_EVENTS.SETTINGS.STATUS_UNCHANGED);
              setHasChanges(false);
            }
          }
        });
      }
    },
    [currentCampaignSettings, sendMessage]
  );

  const setChanged = useCallback(() => {
    sendMessage(HUB_EVENTS.SETTINGS.STATUS_CHANGED);
    setHasChanges(true);
  }, [sendMessage]);

  const setDone = useCallback(() => {
    setSaving(false);
    sendMessage(HUB_EVENTS.SETTINGS.STATUS_UNCHANGED);
    setHasChanges(false);
  }, [sendMessage]);

  const setError = useCallback(
    message => {
      setSaving(false);
      sendMessage(HUB_EVENTS.SETTINGS.STATUS_ERROR, { message });
    },
    [sendMessage]
  );

  const setOnReload = eventHandler => {
    handleReload.current = eventHandler;
  };

  const setOnSave = eventHandler => {
    handleSave.current = eventHandler;
  };

  const setPropertySettingValue = useCallback(
    ({
      settingType,
      settingKey,
      settingValue,
      doNotSetChangedFlag = false,
    } = {}) => {
      if (settingType && settingKey) {
        flushSync(() => {
          const newState = utility.cloneObject(currentPropertySettings);
          newState[settingType][settingKey] = settingValue;
          setCurrentPropertySettings(newState);
          if (!doNotSetChangedFlag) {
            if (!isEqual(newState, initialPropertySettings.current)) {
              sendMessage(HUB_EVENTS.SETTINGS.STATUS_CHANGED);
              setHasChanges(true);
            } else {
              sendMessage(HUB_EVENTS.SETTINGS.STATUS_UNCHANGED);
              setHasChanges(false);
            }
          }
        });
      }
    },
    [currentPropertySettings, sendMessage]
  );

  return useMemo(
    () => ({
      isLoading,
      isSaving,
      getCampaignSettings,
      getCampaignSettingType,
      getCampaignSettingValue,
      getInitialCampaignSettings,
      getInitialPropertySettings,
      getPropertySettings,
      getPropertySettingValue,
      hasChanges,
      requestGlobalInfo,
      requestSave,
      saveCampaignDetails,
      saveCampaignSettings,
      saveBodyElementPositioning,
      savePropertyDetails,
      savePropertySettings,
      setCampaignSettingValue,
      setCampaignSettingValues,
      setChanged,
      setDone,
      setError,
      setOnReload,
      setOnSave,
      setPropertySettingValue,
      setUnchanged,
    }),
    [
      isLoading,
      isSaving,
      getCampaignSettings,
      getCampaignSettingType,
      getCampaignSettingValue,
      getInitialCampaignSettings,
      getInitialPropertySettings,
      getPropertySettings,
      getPropertySettingValue,
      hasChanges,
      requestGlobalInfo,
      requestSave,
      saveCampaignDetails,
      saveCampaignSettings,
      savePropertyDetails,
      savePropertySettings,
      setCampaignSettingValue,
      setCampaignSettingValues,
      setChanged,
      setDone,
      setError,
      setPropertySettingValue,
      setUnchanged,
    ]
  );
};

export default useSettings;
