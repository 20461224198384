import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  Text,
  Tooltip,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import Error from 'components/editor/Error';
import UserAccess from 'components/settings/team/UserAccess';
import UserEntities from 'components/settings/team/UserEntities';
import UserRole from 'components/settings/team/UserRole';

import * as api from 'api/api';
import * as campaigns from 'common/campaigns';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import * as users from 'common/users';
import useGlobalInfo from 'hooks/useGlobalInfo';

import {
  CAMPAIGN_PERMISSION_TYPES,
  FORM_VALIDATION_MODES,
  PROPERTY_PERMISSION_TYPES,
  USER_STATES,
} from 'common/enums';

import './UserAdd.css';

function UserAdd({
  entityNameSingular,
  entityNamePlural,
  entitySortMetric,
  existingUsers,
  onInviteUser,
  propertyURN,
}) {
  const [showModal, setShowModal] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userPermissions, setUserPermissions] = useState([
    {
      propertyURN,
      permissionType: PROPERTY_PERMISSION_TYPES.ADMIN,
    },
  ]);
  const [isEditingEntities, setEditingEntities] = useState(null);

  const InviteUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').trim(),
    emailAddress: Yup.string()
      .email('This is not a valid email address')
      .required('Email is required')
      .trim()
      .notOneOf(existingUsers, 'A user with this email address already exists'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(InviteUserSchema),
    mode: FORM_VALIDATION_MODES.ONSUBMIT,
    shouldUnregister: true,
  });
  const currentValues = watch();

  const globalInfo = useGlobalInfo();

  const resetErrorMessage = () => setErrorMessage('');

  const campaignList = campaigns.getCurrentPropertyCampaigns(globalInfo);

  const permissionType = users.getPropertyPermission(
    propertyURN,
    userPermissions
  );

  const userDetails = {
    name: currentValues.name,
    emailAddress: currentValues.emailAddress,
    userState: USER_STATES.INVITED,
    permissions: userPermissions,
  };

  const handleEntitiesClose = () => {
    setEditingEntities(null);
  };

  const handleEntitiesEdit = user => () => {
    setEditingEntities({
      entityURNs: [],
      user,
    });
  };

  const handleEntitiesSubmit = ({ user, selectedEntities }) => {
    logger.info(
      `UserAdd:handleEntitiesSubmit ${propertyURN} ${user.emailAddress} ${selectedEntities.length} selected entities`
    );

    /* Create permissions for all selected entities */
    const permissionsOnProperty = users.createCampaignPermissions(
      propertyURN,
      selectedEntities
    );
    setUserPermissions(permissionsOnProperty);
    setEditingEntities(null);
  };

  const handleHide = () => {
    if (!isAddingUser) {
      setShowModal(false);
    }
  };

  const handleRoleChange = newPermissionType => {
    logger.info(`UserAdd:handleRoleChange ${newPermissionType}`);

    const permissionsOnProperty = [];
    if (newPermissionType === PROPERTY_PERMISSION_TYPES.EDITOR) {
      /* Changing from ADMIN to EDITOR
         Create VIEW_ONLY permission for property and CAMPAIGN_EDITOR for all campaigns */
      permissionsOnProperty.push({
        propertyURN,
        permissionType: PROPERTY_PERMISSION_TYPES.VIEW_ONLY,
      });
      campaignList?.forEach(campaign => {
        permissionsOnProperty.push({
          propertyURN,
          campaignURN: campaign.campaignURN,
          permissionType: CAMPAIGN_PERMISSION_TYPES.CAMPAIGN_EDITOR,
        });
      });
    } else {
      /* Changing from EDITOR to ADMIN
         Create ADMIN permission for property */
      permissionsOnProperty.push({
        propertyURN,
        permissionType: PROPERTY_PERMISSION_TYPES.ADMIN,
      });
    }
    setUserPermissions(permissionsOnProperty);
  };

  const handleShow = () => {
    reset();
    resetErrorMessage();
    setUserPermissions([
      {
        propertyURN,
        permissionType: PROPERTY_PERMISSION_TYPES.ADMIN,
      },
    ]);
    setShowModal(true);
  };

  const handleUserAdd = async values => {
    setIsAddingUser(true);
    const { name, emailAddress } = values;

    try {
      await api.postPropertiesUser({
        propertyURN,
        fullName: name,
        emailAddress,
        permissionsOnProperty: userPermissions,
      });
      const propertyPermission = users.getPropertyPermission(
        propertyURN,
        userPermissions
      );
      const propertyCampaignPermissions = users.getPropertyCampaignPermissions(
        propertyURN,
        userPermissions
      );
      const trackingParams = {
        'Added user email': emailAddress,
        'Added user role':
          propertyPermission === PROPERTY_PERMISSION_TYPES.ADMIN
            ? 'Admin'
            : 'Editor',
      };
      if (propertyPermission !== PROPERTY_PERMISSION_TYPES.ADMIN) {
        trackingParams['Number of campaigns'] =
          propertyCampaignPermissions.length;
      }
      tracker.track({
        eventName: 'Add User',
        trackingParams,
        options: {
          includeCampaignDetails: false,
        },
      });
      onInviteUser({
        name,
        emailAddress,
        userPermissions,
      });
      setIsAddingUser(false);
      handleHide();
    } catch (error) {
      setErrorMessage(errors.getErrorMessage(error));
      setIsAddingUser(false);
    }
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        <span className="add-user-plus-wrapper d-flex align-items-center justify-content-center mr-2">
          <PlusIcon className="add-user-plus" />
        </span>
        Add user
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isCentered={true}
        isOpen={showModal}
        onClose={handleHide}
        size="small"
      >
        <Modal.Header className="">
          <Modal.Title>Add user</Modal.Title>
        </Modal.Header>
        {errorMessage !== '' && (
          <Error
            errorMessage={errorMessage}
            handleErrorClose={resetErrorMessage}
          />
        )}
        <Modal.Body>
          <Flex gap={6} direction="column">
            <FormControl isInvalid={formErrors.name}>
              <FormControl.FormLabel>Name</FormControl.FormLabel>
              <Input
                {...register('name')}
                type="text"
                disabled={isAddingUser}
              />
              <FormControl.FormErrorMessage>
                {formErrors?.name?.message}
              </FormControl.FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.emailAddress}>
              <FormControl.FormLabel>Email address</FormControl.FormLabel>
              <Input
                {...register('emailAddress')}
                type="email"
                disabled={isAddingUser}
              />
              <FormControl.FormErrorMessage>
                {formErrors?.emailAddress?.message}
              </FormControl.FormErrorMessage>
            </FormControl>
            <Flex mb={4} alignItems="center">
              <FormControl>
                <FormControl.FormLabel>
                  Role
                  <Tooltip
                    label={
                      <VStack>
                        <Text size="sm">
                          <strong>Administrator</strong>: Has access to all
                          campaigns and settings
                        </Text>
                        <Text size="sm">
                          <strong>Editor</strong>: Has access to selected
                          campaigns and limited settings
                        </Text>
                      </VStack>
                    }
                    placement="bottom"
                  >
                    <InfoIcon
                      className="ebx-info-icon"
                      style={{ margin: '2px', cursor: 'pointer' }}
                    />
                  </Tooltip>
                </FormControl.FormLabel>
                <UserRole
                  handleRoleChange={handleRoleChange}
                  isBusy={isAddingUser}
                  permissionType={permissionType}
                  source="AddUser"
                />
              </FormControl>
              <FormControl>
                <FormControl.FormLabel>&nbsp;</FormControl.FormLabel>
                <UserAccess
                  entityNameSingular={entityNameSingular}
                  entityNamePlural={entityNamePlural}
                  handleEntitiesEdit={handleEntitiesEdit}
                  isBusy={isAddingUser}
                  isCurrentUser={false}
                  propertyURN={propertyURN}
                  source="AddUser"
                  user={userDetails}
                />
              </FormControl>
            </Flex>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleHide}
            isDisabled={isAddingUser}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(handleUserAdd)}
            isDisabled={
              isAddingUser ||
              currentValues.name === '' ||
              currentValues.emailAddress === '' ||
              formErrors.name ||
              formErrors.emailAddress
            }
          >
            Add user
          </Button>
        </Modal.Footer>
      </Modal>
      {isEditingEntities && (
        <UserEntities
          availableCampaigns={campaignList}
          buttonText={`Select ${entityNamePlural}`}
          editingEntities={isEditingEntities}
          handleClose={handleEntitiesClose}
          handleSubmit={handleEntitiesSubmit}
          isBusy={isAddingUser}
        />
      )}
    </>
  );
}

UserAdd.propTypes = {
  entityNameSingular: PropTypes.string.isRequired,
  entityNamePlural: PropTypes.string.isRequired,
  entitySortMetric: PropTypes.string.isRequired,
  existingUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onInviteUser: PropTypes.func.isRequired,
  propertyURN: PropTypes.string.isRequired,
};

export default UserAdd;
