/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import * as API from 'api/api';
import * as properties from 'common/properties';
import * as users from 'common/users';

// import { PROPERTY_TYPES } from 'common/constants';

export default async function getPropertiesAndCampaigns(user, current) {
  // Get entities to which the user has access from their permissions
  const access = users.getUserEntityAccess(user);

  // Get details of all propertyData the user has access to
  const propertyData = await API.getProperties({
    propertyURNs: access.allPropertyURNs,
  });

  // Set the initially-selected property
  let propertyURN;
  if (current.propertyURN) {
    propertyURN = current.propertyURN;
  } else {
    propertyURN = properties.getDefaultPropertyURN(propertyData);
  }

  // Fetch settings for the property
  const { propertySettings } = await API.getPropertiesSettings({ propertyURN });
  propertyData[propertyURN].propertySettings = propertySettings;

  // Get details of all campaigns the user has access to
  const campaignURNs = await API.getCampaignList({ propertyURN });
  propertyData[propertyURN].campaigns = {};
  if (campaignURNs.length) {
    const campaigns = await API.getCampaigns({ campaignURNs });
    campaigns.forEach(campaign => {
      propertyData[propertyURN].campaigns[campaign.campaignURN] = campaign;
    });
  }

  return propertyData;
}

/*
const setCurrentPropertyAndCampaign = globalInfo => {
  const n = null;

  return {
    propertyURN: n,
    campaignURN: n,
  };
}
*/
