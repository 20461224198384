import { Col, Row } from 'react-bootstrap';

import DoneIcon from '@material-ui/icons/Done';

import './Success.css';

function Success() {
  return (
    <div className="success__height d-flex justify-content-center align-items-center">
      <div className="p-2">
        <Row className="success__card pb-5 px-4 px-sm-5 pt-4 w-100 m-auto">
          <Col>
            <Row className="font-weight-bold success__card__header mb-3 d-flex justify-content-center">
              You are now unsubscribed
            </Row>
            <Row className="success__card__description mb-3 m-0">
              <div className="text-center">
                <span>{`You won't receive any more emails from `}</span>
                <span>publisher@publisher.com</span>
              </div>
            </Row>
            <Row className="w-100 m-0 d-flex justify-content-center">
              <DoneIcon className="success__tick" />
            </Row>
          </Col>
        </Row>
        <div className="w-100 mt-2">
          <Col className="d-flex justify-content-end powered__text">
            Powered by Echobox
          </Col>
        </div>
      </div>
    </div>
  );
}

export default Success;
