import { Auth } from 'aws-amplify';

import { handleCognitoError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export default async function authVerifyUserEmailSubmit({
  verificationCode,
}: {
  verificationCode: string;
}) {
  if (isUndefined(verificationCode)) {
    mandatory('verificationCode');
  }

  const guid = metrics.start('authVerifyUserEmailSubmit');
  logger.info('API:authVerifyUserEmailSubmit');

  try {
    const response = await Auth.verifyCurrentUserAttributeSubmit(
      'email',
      verificationCode
    );
    metrics.end('authVerifyUserEmailSubmit', guid);
    return response;
  } catch (error) {
    metrics.fail('authVerifyUserEmailSubmit', guid);
    const cognitoError =
      error instanceof Error
        ? handleCognitoError({
            originalError: error,
            errorLocation: 'api/auth/authVerifyUserEmailSubmit',
          })
        : error;
    throw cognitoError;
  }
}
