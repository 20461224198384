/* eslint react/no-danger: "off" */

import { useContext } from 'react';

import { Alert, Box, BoxProps } from '@ebx-ui/ebx-ui-component-library-sdk';

import { STATUS_PAGE_IDS } from 'common/constants';
import * as sanitise from 'common/sanitise';
import { isNullOrUndefined } from 'common/utility';

import { StoreContext } from 'store/store';

function StatusPageNotifications({ ...props }: BoxProps) {
  const { state } = useContext(StoreContext);

  const isHidingStatusPage = () => sessionStorage.getItem('hideStatusPage');

  const coreNotification = state?.statusPageNotifications[STATUS_PAGE_IDS.CORE];
  const newslettersNotification =
    state?.statusPageNotifications[STATUS_PAGE_IDS.NEWSLETTERS];

  if (
    isHidingStatusPage() ||
    (isNullOrUndefined(coreNotification) &&
      isNullOrUndefined(newslettersNotification))
  ) {
    return null;
  }

  return (
    <Box {...props}>
      <Alert flexShrink={0}>
        {!isNullOrUndefined(coreNotification) && (
          <Alert.AlertTitle
            dangerouslySetInnerHTML={{
              __html: sanitise.safeHTML(coreNotification),
            }}
          />
        )}
        {!isNullOrUndefined(newslettersNotification) && (
          <Alert.AlertTitle
            dangerouslySetInnerHTML={{
              __html: sanitise.safeHTML(newslettersNotification),
            }}
          />
        )}
      </Alert>
    </Box>
  );
}

export default StatusPageNotifications;
