import PropTypes from 'prop-types';

import EmailContent from 'components/editor/wysiwyg/EmailContent';

import * as editions from 'common/editions';
import { EDITION_STATE } from 'common/enums';

function EditorBody({
  emailDetails,
  editionDisplayState,
  templateDetails,
  handleUpdateEmail,
  handleUpdateTemplate,
  isSaving,
  isSending,
}) {
  const canEdit = editions.checkIsEditionEditable(editionDisplayState);

  const handleUpdateSubject = async (updatedSubject, callback) => {
    await handleUpdateEmail({
      ...emailDetails,
      editionSubject: updatedSubject,
    });
    if (typeof callback === 'function') {
      callback();
    }
  };

  return (
    <EmailContent
      editionURN={emailDetails.editionURN}
      emailSubject={emailDetails.editionSubject}
      handleUpdateSubject={handleUpdateSubject}
      handleUpdateTemplate={handleUpdateTemplate}
      isBusy={isSaving || isSending}
      isEditable={canEdit}
      isSent={emailDetails.editionState === EDITION_STATE.SENT}
      templateDetails={templateDetails}
    />
  );
}

EditorBody.propTypes = {
  emailDetails: PropTypes.shape({
    editionURN: PropTypes.string.isRequired,
    editionState: PropTypes.string.isRequired,
    editionSubject: PropTypes.string.isRequired,
  }).isRequired,
  editionDisplayState: PropTypes.string.isRequired,
  templateDetails: PropTypes.shape({
    templateURN: PropTypes.string.isRequired,
    campaignURN: PropTypes.string.isRequired,
    design: PropTypes.string.isRequired,
  }).isRequired,
  handleUpdateEmail: PropTypes.func.isRequired,
  handleUpdateTemplate: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isSending: PropTypes.bool.isRequired,
};

EditorBody.defaultProps = {
  isSaving: null,
};

export default EditorBody;
