/* eslint func-names: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import { useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import { useDisclosure, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';

import EditCustomBlock from 'components/editor/blocks/EditCustomBlock';
import EditTextBlock from 'components/editor/curate/EditTextBlock';
import PromotionModal from 'components/editor/curate/PromotionModal';
import BodyAddElement from 'components/settings/campaigns/content/BodyAddElement';
import BodyPromotion from 'components/settings/campaigns/content/BodyPromotion';
import BodySection from 'components/settings/campaigns/content/BodySection';
import BodyTextBlock from 'components/settings/campaigns/content/BodyTextBlock';
import ContentSectionWrapper from 'components/settings/campaigns/content/ContentSectionWrapper';
import CustomBlock from 'components/settings/campaigns/content/CustomBlock';
import EditSectionModal from 'components/settings/campaigns/EditSectionModal';

import * as API from 'api/api';
import * as blocks from 'common/blocks';
import * as campaigns from 'common/campaigns';
import * as sections from 'common/sections';
import * as tags from 'common/tags';
import * as textblocks from 'common/textblocks';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';
// TODO: Awaiting the redesign of the do not reuse articles button.
// import { DEFAULT_SECTION_VALUES } from 'common/enums';

import { PROMOTION_BLOCK_TYPES, SECTION_TYPES } from 'common/enums';

import {
  BodyElementPositioning as BodyElementPositioningType,
  BodyEntities,
  CampaignSection as SectionType,
  NewCampaignSection,
  PromotionBlock as PromotionBlockType,
  TextBlock as TextBlockType,
} from 'common/types';

import './Body.css';

interface BodyProps {
  campaignURN: string;
  globalSections: SectionType[];
  globalPromotionBlocks: PromotionBlockType[];
  globalTextBlocks: TextBlockType[];
  globalBodyElementPositioning: BodyElementPositioningType[];
  bodyEntities: ReadonlyArray<BodyEntities>;
  setBodyEntities: (
    newSections: SectionType[],
    newPromotionBlocks: PromotionBlockType[],
    newTextBlocks: TextBlockType[],
    newBodyElementPositioning: BodyElementPositioningType[]
  ) => void;
}

function Body({
  campaignURN,
  globalSections,
  globalPromotionBlocks,
  globalTextBlocks,
  globalBodyElementPositioning,
  bodyEntities,
  setBodyEntities,
}: BodyProps) {
  const { urn } = useParams<{ urn: string }>();
  const [customBlockOpened, setCustomBlockOpened] = useState(0);
  const globalInfo = useGlobalInfo();
  const globalCampaign = campaigns.getCampaign(urn, globalInfo);
  const [sectionOpened, setSectionOpened] = useState(0);
  const [textBlockOpened, setTextBlockOpened] = useState(0);
  const [savingTextBlock, setSavingTextBlock] = useState(false);
  const [savingPromotionBlock, setSavingPromotionBlock] = useState(false);
  const {
    isOpen: isOpenCustomBlock,
    onOpen: onOpenCustomBlock,
    onClose: onCloseCustomBlock,
  } = useDisclosure();
  const {
    isOpen: isOpenSection,
    onOpen: onOpenSection,
    onClose: onCloseSection,
  } = useDisclosure();
  const {
    isOpen: isOpenTextBlock,
    onOpen: onOpenTextBlock,
    onClose: onCloseTextBlock,
  } = useDisclosure();
  const [promotionBlockOpenedIndex, setPromotionBlockOpenedIndex] =
    useState(-1);
  const [showEditPromoModal, setShowEditPromoModal] = useState(false);
  const [isNewSection, setIsNewSection] = useState(false);
  const [currentSectionType, setCurrentSectionType] = useState(
    SECTION_TYPES.ARTICLES
  );
  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  const mergeTags = campaigns.getCurrentCampaignSettingValue(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.SUBSCRIBER_PERSONALISATION,
    'mergeTags'
  );

  // TODO: Awaiting the redesign of the do not reuse articles button.
  // const [reuseArticles, setReuseArticles] = useState(
  //   globalCampaign.articleReuseLimitDays
  // );
  // const reuseArticles = settings.getSettingValue(
  //   CAMPAIGN_SETTING_TYPES.SECTION_ARTICLE,
  //   'articleReuseLimitDays'
  // );

  let sectionIndex = 0;
  let promotionBlockIndex = 0;
  let textBlockIndex = 0;

  const handleDragEnd = async (result: DropResult) => {
    // dropped outside the list or same position
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const newBodyEntities = Array.from(bodyEntities);
    const [removed] = newBodyEntities.splice(result.source.index, 1);
    newBodyEntities.splice(result.destination.index, 0, removed);

    const newGlobalBodyElementPositioning = Array.from(
      globalBodyElementPositioning
    );
    const [removedElement] = newGlobalBodyElementPositioning.splice(
      result.source.index,
      1
    );
    newGlobalBodyElementPositioning.splice(
      result.destination.index,
      0,
      removedElement
    );

    setBodyEntities(
      globalSections,
      globalPromotionBlocks,
      globalTextBlocks,
      newGlobalBodyElementPositioning
    );
    await settings.saveBodyElementPositioning({
      campaignURN,
      bodyElementPositioning: utility.cloneArray(
        newGlobalBodyElementPositioning
      ),
    });
    settings.requestGlobalInfo();
  };

  const createSection = async (section: NewCampaignSection) => {
    try {
      const newSectionURN = await API.postSection({
        campaignURN: urn,
        sectionDetails: {
          callToActionText: section.callToActionText,
          maxArticleAgeMins: section.maxArticleAgeMins,
          sectionName: section.sectionName,
          showTitle: section.showTitle,
          syndFeeds: section.syndFeeds,
          targetArticles: section.targetArticles,
          sectionType: section.sectionType,
          dataSources: section.dataSources,
        },
      });

      const newSection: SectionType = {
        ...section,
        sectionURN: newSectionURN,
      };
      globalSections.push(newSection);
      globalBodyElementPositioning.push({ elementURN: newSection.sectionURN });
      setBodyEntities(
        globalSections,
        globalPromotionBlocks,
        globalTextBlocks,
        globalBodyElementPositioning
      );
      settings.requestGlobalInfo();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Failed to add section',
      });
    }
  };

  const updateExistingSection = async (section: SectionType) => {
    try {
      await API.putSection({
        sectionURN: section.sectionURN,
        sectionDetails: {
          callToActionText: section.callToActionText,
          maxArticleAgeMins: section.maxArticleAgeMins,
          sectionName: section.sectionName,
          showTitle: section.showTitle,
          syndFeeds: section.syndFeeds,
          targetArticles: section.targetArticles,
          sectionType: section.sectionType,
          dataSources: section.dataSources,
        },
      });
      sectionIndex = globalSections.findIndex(
        globalSection => globalSection.sectionURN === section.sectionURN
      );

      const newGlobalSections = [
        ...globalSections.slice(0, sectionIndex),
        section,
        ...globalSections.slice(sectionIndex + 1),
      ];
      setBodyEntities(
        newGlobalSections,
        globalPromotionBlocks,
        globalTextBlocks,
        globalBodyElementPositioning
      );
      settings.requestGlobalInfo();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Failed to update section',
      });
    }
  };

  const createPromotionBlock = async (
    promotionBlockType: PROMOTION_BLOCK_TYPES
  ) => {
    try {
      const newPromotionBlockURN = await API.postPromotionBlock({
        campaignURN: urn,
        promotionBlockDetails: {
          imageURL: null,
          destinationURL: null,
          promotionBlockType,
        },
      });
      const promotionBlock = {
        promotionBlockURN: newPromotionBlockURN,
        imageURL: null,
        destinationURL: null,
        promotionBlockType,
      };
      globalPromotionBlocks.push(promotionBlock);
      const promotionBlockPosition = {
        elementURN: promotionBlock.promotionBlockURN,
      };
      globalBodyElementPositioning.push(promotionBlockPosition);
      setBodyEntities(
        globalSections,
        globalPromotionBlocks,
        globalTextBlocks,
        globalBodyElementPositioning
      );
      settings.requestGlobalInfo();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Failed to add promotion block',
      });
    }
  };

  const addNewTextBlock = () => {
    const createTextBlock = async () => {
      try {
        const textBlockURN = await API.postTextBlock({
          campaignURN: urn,
          textBlockDetails: {
            textBlockTitle: '',
            textBlockBody: '',
          },
        });
        const textBlock = {
          textBlockURN,
          textBlockBody: '',
          textBlockTitle: '',
        };
        globalTextBlocks.push(textBlock);
        const textBlockPosition = {
          elementURN: textBlock.textBlockURN,
        };
        globalBodyElementPositioning.push(textBlockPosition);
        setBodyEntities(
          globalSections,
          globalPromotionBlocks,
          globalTextBlocks,
          globalBodyElementPositioning
        );
        settings.requestGlobalInfo();
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Failed to add text block',
        });
      }
    };
    createTextBlock();
  };

  const addNewCustomBlock = (blockType: string) => {
    const createCustomBlock = async () => {
      try {
        const textBlockTitle = `@@@${blockType}@@@`;
        const customBlock = blocks.createCustomBlock(blockType);

        const textBlockURN = await API.postTextBlock({
          campaignURN: urn,
          textBlockDetails: {
            textBlockTitle,
            textBlockBody: JSON.stringify(customBlock),
          },
        });
        const textBlock = {
          textBlockURN,
          textBlockTitle,
          textBlockBody: JSON.stringify(customBlock),
        };
        globalTextBlocks.push(textBlock);
        const textBlockPosition = {
          elementURN: textBlock.textBlockURN,
        };
        globalBodyElementPositioning.push(textBlockPosition);
        setBodyEntities(
          globalSections,
          globalPromotionBlocks,
          globalTextBlocks,
          globalBodyElementPositioning
        );
        settings.requestGlobalInfo();
      } catch (error) {
        console.log(error);
        toast({
          variant: 'error',
          title: 'Failed to add custom block',
        });
      }
    };
    createCustomBlock();
  };

  const handleSavePromotionBlock = async (
    newImageURL: string | null,
    newDestinationURL: string | null
  ) => {
    const currentPromotionBlockURN =
      globalPromotionBlocks[promotionBlockOpenedIndex].promotionBlockURN;
    const currentPromotionBlockType =
      globalPromotionBlocks[promotionBlockOpenedIndex].promotionBlockType;
    try {
      await API.putPromotionBlock({
        promotionBlockURN: currentPromotionBlockURN,
        promotionBlockDetails: {
          imageURL: newImageURL,
          destinationURL: newDestinationURL,
          promotionBlockType: currentPromotionBlockType,
        },
      });

      promotionBlockIndex = globalPromotionBlocks.findIndex(
        globalPromotionBlock =>
          globalPromotionBlock.promotionBlockURN === currentPromotionBlockURN
      );

      const newGlobalPromotionBlocks = [
        ...globalPromotionBlocks.slice(0, promotionBlockIndex),
        {
          imageURL: newImageURL,
          destinationURL: newDestinationURL,
          promotionBlockType: currentPromotionBlockType,
          promotionBlockURN: currentPromotionBlockURN,
        },
        ...globalPromotionBlocks.slice(promotionBlockIndex + 1),
      ];

      setBodyEntities(
        globalSections,
        newGlobalPromotionBlocks,
        globalTextBlocks,
        globalBodyElementPositioning
      );
      settings.requestGlobalInfo();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Failed to update promotion block',
      });
    }
    setSavingPromotionBlock(false);
    handleHidePromoModal();
  };

  const editPromotion = (index: number) => {
    setPromotionBlockOpenedIndex(index);
    setShowEditPromoModal(true);
  };

  const handleHidePromoModal = () => {
    setShowEditPromoModal(false);
  };

  const setSectionNumber = () => {
    sectionIndex += 1;
    return sectionIndex;
  };

  const setPromotionBlockNumber = () => {
    promotionBlockIndex += 1;
    return promotionBlockIndex;
  };

  const setTextBlockNumber = () => {
    textBlockIndex += 1;
    return textBlockIndex;
  };

  const handleNewElement = (type: string | null) => {
    if (type === 'section') {
      setCurrentSectionType(SECTION_TYPES.ARTICLES);
      handleShowModal(globalSections.length);
    } else if (type === 'sponsored') {
      setCurrentSectionType(SECTION_TYPES.ARTICLES_SPONSORED);
      handleShowModal(globalSections.length);
    } else if (type === 'image') {
      createPromotionBlock(PROMOTION_BLOCK_TYPES.IMAGE);
    } else if (type === 'advertising') {
      createPromotionBlock(PROMOTION_BLOCK_TYPES.ADVERTISING);
    } else if (type === 'textBlock') {
      addNewTextBlock();
    } else if (typeof type === 'string') {
      addNewCustomBlock(type);
    } else {
      // error
    }
  };

  const handleSaveSection = (section: SectionType | NewCampaignSection) => {
    // TODO: Awaiting the redesign of the do not reuse articles button.
    // settings.setSettingValue({
    //   settingType: CAMPAIGN_SETTING_TYPES.SECTION_ARTICLE,
    //   settingKey: 'articleReuseLimitDays',
    //   settingValue: setReuseArticlesOnSave(),
    // });

    if (section?.sectionURN) {
      updateExistingSection(section as SectionType);
    } else {
      createSection(section as NewCampaignSection);
    }

    const originalSection = sections.getSection(
      section?.sectionURN,
      globalSections
    );

    tracker.track({
      eventName: 'Edit Section',
      trackingParams: {
        Title: section.sectionName,
        'Title (before)': originalSection?.sectionName,
        'Hide section title': !section.showTitle,
        'Hide section title (before)':
          originalSection?.showTitle === undefined
            ? originalSection?.showTitle
            : !originalSection?.showTitle,
        '# Articles to show': section.targetArticles,
        '# Articles to show (before)': originalSection?.targetArticles,
        'Connected syndfeeds': section.syndFeeds,
        'Connected syndfeeds (before)': originalSection?.syndFeeds,
        '# Connected syndfeeds': section.syndFeeds.length,
        '# Connected syndfeeds (before)': originalSection?.syndFeeds.length,
        'Max article age': section?.maxArticleAgeMins,
        'Max article age (before)': originalSection?.maxArticleAgeMins,
        'CTA Button Text': section.callToActionText,
        'CTA Button Text (Before)': originalSection?.callToActionText,
      },
      options: { campaignURN },
    });

    onCloseSection();
  };

  const handleShowCustomBlockModal = (index: number) => {
    onOpenCustomBlock();
    setCustomBlockOpened(index);
  };

  const handleShowModal = (index: number) => {
    onOpenSection();
    setSectionOpened(index);

    if (index > globalSections.length - 1) {
      setIsNewSection(true);
    } else {
      setIsNewSection(false);
      setCurrentSectionType(globalSections[index].sectionType);
    }
  };

  const handleShowTextBlockModal = (index: number) => {
    onOpenTextBlock();
    setTextBlockOpened(index);
  };

  const handleSaveTextBlock = (
    textBlockURN: string,
    text: string,
    title: string,
    callback: Function
  ) => {
    setSavingTextBlock(true);
    const saveTextBlock = async () => {
      try {
        await API.putTextBlock({
          textBlockURN,
          textBlockDetails: {
            textBlockTitle: title,
            textBlockBody: text,
          },
        });
        textBlockIndex = globalTextBlocks.findIndex(
          globalTextBlock => globalTextBlock.textBlockURN === textBlockURN
        );

        const newGlobalTextBlocks = [
          ...globalTextBlocks.slice(0, textBlockIndex),
          {
            textBlockURN,
            textBlockBody: text,
            textBlockTitle: title,
          },
          ...globalTextBlocks.slice(textBlockIndex + 1),
        ];
        setBodyEntities(
          globalSections,
          globalPromotionBlocks,
          newGlobalTextBlocks,
          globalBodyElementPositioning
        );

        const readerFirstNameUsed = tags.isTagUsedInBlock(
          'first_name',
          title,
          text
        );
        const readerLastNameUsed = tags.isTagUsedInBlock(
          'last_name',
          title,
          text
        );
        const originalTextBlock = textblocks.getTextBlock(
          textBlockURN,
          globalTextBlocks
        ) ?? {
          textBlockBody: '',
          textBlockTitle: '',
        };
        const originalBody = originalTextBlock.textBlockBody;
        const originalTitle = originalTextBlock.textBlockTitle;
        tracker.track({
          eventName: 'Edit Text Block',
          trackingParams: {
            'Title Before': originalTitle,
            Title: title,
            'Text Body Before': originalBody,
            'Text Body': text,
            Origin: 'Settings Page',
            'Email Type': 'Newsletter',
            'Reader First Name Used': readerFirstNameUsed,
            'Reader Last Name Used': readerLastNameUsed,
          },
        });

        settings.requestGlobalInfo();
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Failed to update text block',
        });
      }
    };
    saveTextBlock();
    setSavingTextBlock(false);
    callback();
  };

  const handleSectionDelete = async (sectionURN: string) => {
    try {
      await API.deleteSection({ sectionURN });
      let newSections = [...globalSections];
      newSections = newSections.filter(
        section => section.sectionURN !== sectionURN
      );
      let newBodyElementPositioning = [...globalBodyElementPositioning];
      newBodyElementPositioning = newBodyElementPositioning.filter(
        element => element.elementURN !== sectionURN
      );
      setBodyEntities(
        newSections,
        globalPromotionBlocks,
        globalTextBlocks,
        newBodyElementPositioning
      );
      settings.requestGlobalInfo();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Failed to remove section',
      });
    }
  };

  const handlePromotionBlockDelete = async (promotionBlockURN: string) => {
    try {
      await API.deletePromotionBlock({ promotionBlockURN });
      let newPromotionBlocks = [...globalPromotionBlocks];
      newPromotionBlocks = newPromotionBlocks.filter(
        promotionBlock => promotionBlock.promotionBlockURN !== promotionBlockURN
      );
      let newBodyElementPositioning = [...globalBodyElementPositioning];
      newBodyElementPositioning = newBodyElementPositioning.filter(
        element => element.elementURN !== promotionBlockURN
      );
      setBodyEntities(
        globalSections,
        newPromotionBlocks,
        globalTextBlocks,
        newBodyElementPositioning
      );
      settings.requestGlobalInfo();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Failed to remove promotion block',
      });
    }
  };

  const handleTextBlockDelete = (textBlockURN: string) => {
    const deleteTextBlock = async () => {
      try {
        await API.deleteTextBlock({ textBlockURN });
        let newTextBlocks = [...globalTextBlocks];
        newTextBlocks = newTextBlocks.filter(
          textBlock => textBlock.textBlockURN !== textBlockURN
        );
        let newBodyElementPositioning = [...globalBodyElementPositioning];
        newBodyElementPositioning = newBodyElementPositioning.filter(
          element => element.elementURN !== textBlockURN
        );
        setBodyEntities(
          globalSections,
          globalPromotionBlocks,
          newTextBlocks,
          newBodyElementPositioning
        );
        settings.requestGlobalInfo();
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Failed to remove text block',
        });
      }
    };
    deleteTextBlock();
  };

  // TODO: Awaiting the redesign of the do not reuse articles button.
  // const setReuseArticlesOnSave = () => {
  //   if (reuseArticles) {
  //     return DEFAULT_SECTION_VALUES.ARTICLE_REUSE_DAYS;
  //   }
  //   return null;
  // };

  // const handleReuseArticlesChange = (event: { target: { checked: any } }) => {
  //   setReuseArticles(event.target.checked);
  // };

  return (
    <ContentSectionWrapper
      title="Body"
      description="Add, drag or edit elements to arrange the content in your Campaign "
    >
      {/* TODO: Add do not reuse articles button back to new location */}
      {/* <Form.Check
        id="reuse-articles-checkbox"
        className="ebx-h3 mb-3"
        type="checkbox"
        label="Do not reuse articles"
        value={reuseArticles}
        defaultChecked={reuseArticles}
        onChange={handleReuseArticlesChange}
      /> */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={urn}>
          {droppable => (
            <>
              <div
                {...droppable.droppableProps}
                ref={droppable.innerRef}
                className="body-entities-container"
              >
                {bodyEntities.map((entity, index) => (
                  <Draggable
                    key={(function () {
                      if ('sectionURN' in entity) {
                        return entity.sectionURN;
                      }
                      if ('promotionBlockURN' in entity) {
                        return entity.promotionBlockURN;
                      }
                      if ('textBlockURN' in entity) {
                        return entity.textBlockURN;
                      }
                      return null;
                    })()}
                    draggableId={(function () {
                      if ('sectionURN' in entity) {
                        return entity.sectionURN;
                      }
                      if ('promotionBlockURN' in entity) {
                        return entity.promotionBlockURN;
                      }
                      if ('textBlockURN' in entity) {
                        return entity.textBlockURN;
                      }
                      return '';
                    })()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? 'dragging' : undefined}
                      >
                        {(function () {
                          if ('sectionURN' in entity) {
                            return (
                              <BodySection
                                {...entity}
                                onOpenModal={handleShowModal}
                                index={setSectionNumber() - 1}
                                onDelete={handleSectionDelete}
                              />
                            );
                          }
                          if ('promotionBlockURN' in entity) {
                            return (
                              <BodyPromotion
                                {...entity}
                                onDelete={handlePromotionBlockDelete}
                                onEdit={editPromotion}
                                index={setPromotionBlockNumber() - 1}
                              />
                            );
                          }
                          if (
                            'textBlockURN' in entity &&
                            !blocks.isCustomBlock(entity.textBlockTitle)
                          ) {
                            return (
                              <BodyTextBlock
                                {...entity}
                                index={setTextBlockNumber() - 1}
                                onDelete={handleTextBlockDelete}
                                onOpenModal={handleShowTextBlockModal}
                              />
                            );
                          }
                          if (
                            'textBlockURN' in entity &&
                            blocks.isCustomBlock(entity.textBlockTitle)
                          ) {
                            return (
                              <CustomBlock
                                {...entity}
                                index={setTextBlockNumber() - 1}
                                onDelete={handleTextBlockDelete}
                                onOpenModal={handleShowCustomBlockModal}
                              />
                            );
                          }
                          return null;
                        })()}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {droppable.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>
      <BodyAddElement onNewElement={handleNewElement} />
      {isOpenSection && (
        <EditSectionModal
          settings={settings}
          onClose={onCloseSection}
          isOpen={isOpenSection}
          section={globalSections[sectionOpened]}
          newSection={isNewSection}
          handleSave={handleSaveSection}
          sectionType={currentSectionType}
        />
      )}
      {showEditPromoModal && (
        <PromotionModal
          imageURL={
            globalPromotionBlocks[promotionBlockOpenedIndex]?.imageURL ?? ''
          }
          destinationURL={
            globalPromotionBlocks[promotionBlockOpenedIndex]?.destinationURL ??
            ''
          }
          promotionBlockType={
            globalPromotionBlocks[promotionBlockOpenedIndex]?.promotionBlockType
          }
          onHide={handleHidePromoModal}
          onSave={handleSavePromotionBlock}
          show={true}
          origin="Settings"
          isSavingPromotionBlock={savingPromotionBlock}
          setSaving={setSavingPromotionBlock}
        />
      )}
      {isOpenTextBlock && (
        <EditTextBlock
          campaignDetails={globalCampaign}
          editionDetails={null}
          textBlock={globalTextBlocks[textBlockOpened]}
          handleSaveTextBlock={handleSaveTextBlock}
          isSavingTextBlock={savingTextBlock}
          isOpen={isOpenTextBlock}
          onClose={onCloseTextBlock}
          mergeTags={mergeTags}
        />
      )}
      {isOpenCustomBlock && (
        <EditCustomBlock
          customBlock={globalTextBlocks[customBlockOpened]}
          handleSave={handleSaveTextBlock}
          isSaving={savingTextBlock}
          isOpen={isOpenCustomBlock}
          onClose={onCloseCustomBlock}
        />
      )}
    </ContentSectionWrapper>
  );
}

export default Body;
