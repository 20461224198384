import React from 'react';
import { Form } from 'react-bootstrap';

import {
  LightingHighlightIcon,
  Tag,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { SCHEDULE_EDITION_OPTIONS } from 'common/enums';

export interface ExportSchedulerModalRadioProps {
  checked: boolean;
  disabled?: boolean;
  id: string;
  label: string;
  description?: string;
  onChange: () => void;
  children?: React.ReactNode;
}

function SchedulerModalRadio({
  checked,
  disabled,
  id,
  label,
  description,
  onChange,
  children,
}: ExportSchedulerModalRadioProps) {
  // Only creates a styled item description div if user has set a description
  const itemDescription = description ? (
    <div className="mt-2 ebx-h3 modal-item-description">{description}</div>
  ) : null;

  return (
    <div tabIndex={0} onClick={onChange} onKeyDown={onChange} role="button">
      <Form.Check
        type="radio"
        id={id}
        className={
          'd-flex flex-column mb-2 py-3 px-3 schedule-modal-item' +
          `${checked ? '-selected' : ''}`
        }
      >
        <>
          <div className="d-flex align-items-center">
            <Form.Check.Input
              type="radio"
              name="date-range"
              className="mt-0 ml-0"
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
            <Form.Check.Label
              htmlFor={id}
              className="d-flex align-items-center ebx-h3 ml-4 ebx-title-colour modal-item-label"
            >
              {label}
              {id === SCHEDULE_EDITION_OPTIONS.OPTIMAL && (
                <Tag color="blue" icon={<LightingHighlightIcon />} ml={2}>
                  Recommended
                </Tag>
              )}
            </Form.Check.Label>
          </div>
          {checked ? children : itemDescription}
        </>
      </Form.Check>
    </div>
  );
}

SchedulerModalRadio.defaultProps = {
  disabled: false,
  description: null,
};

export default SchedulerModalRadio;
