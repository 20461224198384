import PropTypes from 'prop-types';

import { useDisclosure } from '@ebx-ui/ebx-ui-component-library-sdk';

import LoadTemplateButton from 'components/editor/marketing/LoadTemplateButton';
import LoadTemplateModal from 'components/editor/marketing/LoadTemplateModal';

function LoadTemplate({ campaignURN, handleSave, isBusy }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <LoadTemplateButton handleShow={onOpen} isBusy={isBusy} />

      {isOpen && (
        <LoadTemplateModal
          campaignURN={campaignURN}
          handleSave={handleSave}
          handleClose={onClose}
          showModal={isOpen}
        />
      )}
    </>
  );
}

LoadTemplate.propTypes = {
  campaignURN: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
};

LoadTemplate.defaultProps = {
  isBusy: false,
};

export default LoadTemplate;
