/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import { HOST_TYPES, LOGIN_URLS, LOOPBACK_ADDRESS } from 'common/constants';
import * as url from 'common/url';

export { getHostname, getHostType, getLoginUrl };

const getHostname = () => {
  let hostname;
  if (window.location.hostname === LOOPBACK_ADDRESS) {
    return 'dev';
  }
  const host = window.location.hostname.split('.')[0];
  switch (host) {
    case 'newsletters':
    case 'email':
      hostname = 'live';
      break;
    case 'review':
      hostname = 'review';
      break;
    case 'newsletters-stage':
    case 'stage':
    case 'aman':
      hostname = 'stage';
      break;
    case 'local':
    case 'localhost':
      hostname = 'dev';
      break;
    default:
      throw new Error('Unknown operating environment');
  }
  return hostname;
};

function getHostType(hostname) {
  const tld = hostname
    ? url.getTLD(hostname)
    : url.getTLD(window.location.hostname);
  switch (tld) {
    case 'echobox.dev':
      return HOST_TYPES.STAGING;
    case 'echobox.com':
      return HOST_TYPES.PROD;
    default:
      return HOST_TYPES.DEV;
  }
}

const getLoginUrl = () => {
  const hostType = getHostType();
  switch (hostType) {
    case HOST_TYPES.STAGING:
      return LOGIN_URLS.STAGING;
    case HOST_TYPES.PROD:
      return LOGIN_URLS.PROD;
    default:
      return LOGIN_URLS.DEV;
  }
};
