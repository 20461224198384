/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { Col, FormControl, FormLabel } from 'react-bootstrap';

import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import SettingsPageWrapper from 'components/settings/campaigns/all/SettingsPageWrapper';
import SidebarWrapper from 'components/settings/campaigns/all/SidebarWrapper';
import FooterPrivacyPolicyInput from 'components/settings/campaigns/branding/email//FooterPrivacyPolicyInput';
import FooterPrivacyPolicyTextInput from 'components/settings/campaigns/branding/email//FooterPrivacyPolicyTextInput';
import FooterCheckbox from 'components/settings/campaigns/branding/email/FooterCheckbox';
import { Container, Content } from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';

function AccessRequest() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const bodyCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE,
    'bodyCopy'
  );
  const titleCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE,
    'titleCopy'
  );

  const emailMeLink = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE,
    'buttonText'
  );

  const privacyPolicyEnabled = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PROPERTY_PRIVACY_POLICY,
    'privacyPolicyEnabled'
  );

  const privacyPolicyLink = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PROPERTY_PRIVACY_POLICY,
    'privacyPolicyLink'
  );

  const privacyPolicyLinkText = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PROPERTY_PRIVACY_POLICY,
    'privacyPolicyText'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Preferences-Access-Request:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleBodyCopyChange = event => {
    settings.setPropertySettingValue({
      settingType:
        PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE,
      settingKey: 'bodyCopy',
      settingValue: event.target.value,
    });
  };

  const handleTitleCopyChange = event => {
    settings.setPropertySettingValue({
      settingType:
        PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE,
      settingKey: 'titleCopy',
      settingValue: event.target.value,
    });
  };

  const handleButtonTextChange = event => {
    settings.setPropertySettingValue({
      settingType:
        PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE,
      settingKey: 'buttonText',
      settingValue: event.target.value,
    });
  };

  const setPrivacyPolicyEnabled = value => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.PROPERTY_PRIVACY_POLICY,
      settingKey: 'privacyPolicyEnabled',
      settingValue: value,
    });
  };

  const setPrivacyPolicyLink = value => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.PROPERTY_PRIVACY_POLICY,
      settingKey: 'privacyPolicyLink',
      settingValue: value,
    });
  };

  const setPrivacyPolicyLinkText = value => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.PROPERTY_PRIVACY_POLICY,
      settingKey: 'privacyPolicyText',
      settingValue: value,
    });
  };

  if (!settings || settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }
  return (
    <SettingsPageWrapper>
      <SidebarWrapper
        subPath="subscriber-preferences-center"
        backLabel="subscriber preferences center"
      >
        <Container>
          <div className="mb-4  pt-3">
            <FormLabel className="ebx-h3 mb-1">
              Update your preferences
            </FormLabel>
            <FormControl
              name="titleCopy"
              id="titleCopy"
              required
              className="ebx-input ebx-body-1"
              type="text"
              value={titleCopy}
              onChange={handleTitleCopyChange}
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mb-1">Description</FormLabel>
            <FormControl
              style={{ width: '100%' }}
              className="ebx-input ebx-input-group ebx-body-1 mb-3"
              as="textarea"
              type="text"
              rows={5}
              required
              value={bodyCopy}
              onChange={handleBodyCopyChange}
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mb-1">Email me link</FormLabel>
            <FormControl
              name="buttonText"
              id="buttonText"
              required
              className="ebx-input ebx-body-1"
              type="text"
              value={emailMeLink}
              onChange={handleButtonTextChange}
              autoComplete="off"
            />
          </div>
          <FooterCheckbox
            label="Privacy Policy link"
            id="privacy-policy-link"
            name="privacy-policy-link"
            className="mb-2"
            onCheck={() => setPrivacyPolicyEnabled(true)}
            onUncheck={() => setPrivacyPolicyEnabled(false)}
            value={privacyPolicyEnabled}
          >
            <FooterPrivacyPolicyInput
              value={privacyPolicyLink}
              onChange={setPrivacyPolicyLink}
            />
            <FooterPrivacyPolicyTextInput
              value={privacyPolicyLinkText}
              onChange={setPrivacyPolicyLinkText}
            />
          </FooterCheckbox>
        </Container>
      </SidebarWrapper>
      <Col xs={12} md={8}>
        <Content>
          <PreviewWrapper
            propertyURN={propertyURN}
            previewTypes={[PREVIEW_TYPES.ACCESS_PREFERENCES]}
            settings={settings}
          />
        </Content>
      </Col>
    </SettingsPageWrapper>
  );
}

export default AccessRequest;
