import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function Error({ errorMessage, handleCancel }) {
  return (
    <>
      <Modal.Body className="p-0 text-center p-4 ebx-modal-body">
        <div className="error--text">{errorMessage}</div>
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleCancel}
        >
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
};

Error.defaultProps = {
  errorMessage: 'Error 404 - Next step not found!',
};

export default Error;
