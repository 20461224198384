/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as campaigns from 'common/campaigns';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';
import * as errors from 'common/errors';
import { loadFont } from 'common/googlefonts';
import * as logger from 'common/logger';
import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import Loading from 'components/settings/Loading';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { BackLink, Content, Sidebar } from '../../common';
import SettingsPanel from '../../SettingsPanel';
import ContentSetting from './ContentSetting';
import SignUpSetting from './SignUpSetting';

export default function DefaultSubBranding() {
  const { urn } = useParams();
  const globalInfo = useGlobalInfo();
  const campaignName = campaigns.getCampaignName(urn, globalInfo);
  const [settingsPanel, setSettingsPanel] = useState(
    BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.OPTIONS
  );

  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  useEffect(() => {
    // pub-sub
    const handleSave = async () => {
      logger.info('DefaultSubBranding:handleSave');

      try {
        await settings.saveCampaignSettings({
          campaignURN: urn,
        });

        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, urn]);

  const signupFooterText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'signupFooterText'
  );

  const privacyPolicyLinkText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
    'privacyPolicyLinkText'
  );

  const privacyPolicyURL = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
    'privacyPolicy'
  );

  let titleCopy = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'titleCopy'
  );
  if (titleCopy) {
    titleCopy = titleCopy.replace('{campaignName}', campaignName);
  }

  let bodyCopy = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'bodyCopy'
  );
  if (bodyCopy) {
    bodyCopy = bodyCopy.replace('{campaignName}', campaignName);
  }

  const signupButtonLabel = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'signupButtonLabel'
  );

  const subscribeError = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'subscribeError'
  );

  const ogImageURL = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'ogImageURL'
  );

  const emailInputLabel = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'emailInputLabel'
  );

  const titleFont = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'titleFont'
  );

  const bodyFont = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'bodyFont'
  );

  loadFont(titleFont);
  loadFont(bodyFont);

  const isFirstNameLastNameEnabled = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'isFirstNameLastNameEnabled'
  );
  const firstNameLabel = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'firstNameLabel'
  );
  const lastNameLabel = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'lastNameLabel'
  );

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Row className="settings-branding w-100 m-0">
      <Col xs={12} md={4} xl={3} className="p-0">
        <Sidebar>
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.OPTIONS && (
            <div>
              <BackLink
                linkURL={`/settings/campaigns/${urn}/branding`}
                linkText="Back to Branding Settings"
              />
              <Button
                variant="link"
                className="link-unstyled ebx-no-underline w-100 p-0 mt-3"
                onClick={() =>
                  setSettingsPanel(
                    BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.CONTENT
                  )
                }
              >
                <SettingsPanel title="Content" />
              </Button>
              <Button
                variant="link"
                className="link-unstyled ebx-no-underline w-100 p-0"
                onClick={() =>
                  setSettingsPanel(
                    BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.INPUTS
                  )
                }
              >
                <SettingsPanel title="Sign up section" />
              </Button>
            </div>
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.CONTENT && (
            <ContentSetting
              settingType={BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE}
              setSettingsPanel={setSettingsPanel}
              titleCopy={titleCopy}
              bodyCopy={bodyCopy}
              settings={settings}
              isEmail={true}
            />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.INPUTS && (
            <SignUpSetting
              setSettingsPanel={setSettingsPanel}
              emailInputLabel={emailInputLabel}
              signupFooterText={signupFooterText}
              signupButtonLabel={signupButtonLabel}
              subscribeError={subscribeError}
              ogImageURL={ogImageURL}
              privacyPolicyLinkText={privacyPolicyLinkText}
              privacyPolicyURL={privacyPolicyURL}
              isFirstNameLastNameEnabled={isFirstNameLastNameEnabled}
              firstNameLabel={firstNameLabel}
              lastNameLabel={lastNameLabel}
              settings={settings}
            />
          )}
        </Sidebar>
      </Col>
      <Col xs={12} md={8} xl={9} className="p-0">
        <Content>
          <PreviewWrapper
            campaignURN={urn}
            previewTypes={[PREVIEW_TYPES.HOSTED_SUBS_PAGE]}
            settings={settings}
          />
        </Content>
      </Col>
    </Row>
  );
}
