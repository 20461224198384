import {
  Button,
  CheckCircleIcon,
  Flex,
  FormControl,
  Input,
  Modal,
  Text,
  Toggle,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChangeEvent, useState } from 'react';

import * as API from 'api/api';
import { AxiosError } from 'axios';

import { FRONTEND_FORM_STATES } from 'common/enums';
import { getErrorMessage } from 'common/errors';
import * as tracker from 'common/tracker';
import { ErrorResponse } from 'common/types';

interface InputStateProps {
  campaignURN: string;
  emailAddress: string;
  handleClose: () => void;
  handleSuccess: () => void;
  setModalState: (newState: FRONTEND_FORM_STATES) => void;
}

function InputState({
  campaignURN,
  emailAddress,
  handleClose,
  handleSuccess,
  setModalState,
}: InputStateProps) {
  const toast = useToast();
  const [isFullyDelete, setIsFullyDelete] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const confirmationMessage = 'DELETE';

  const handleFullyDeleteSwitch = () => {
    setIsFullyDelete(val => !val);
  };

  const handleConfirmationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteConfirmation(event.target.value);
  };

  const removeButtonDisabled =
    isFullyDelete && deleteConfirmation !== confirmationMessage;

  const handleRemoveSubscribers = async () => {
    try {
      tracker.track({
        eventName: 'Remove Subscribers',
        trackingParams: {
          'Number Removed': 1,
          'Historical Data Deleted': isFullyDelete,
        },
      });

      await API.deleteCampaignSubscribers({
        campaignURN,
        subscriberDetails: [
          {
            email: emailAddress,
          },
        ],
        fullyDelete: isFullyDelete,
      });

      handleSuccess();
      setModalState(FRONTEND_FORM_STATES.SUCCESS);
    } catch (error) {
      if (
        (error as AxiosError<ErrorResponse>).response?.data?.error?.target ===
        'email'
      ) {
        toast({
          variant: 'error',
          title: (error as AxiosError<ErrorResponse>).response?.data.error
            .message,
        });
      } else {
        toast({ variant: 'error', title: getErrorMessage(error) });
      }
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Unsubscribe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm" color="gray.600">
          Are you sure you want to unsubscribe{' '}
          <span className="font-weight-bold">{emailAddress}</span>?
        </Text>
        <Modal.Divider />
        <FormControl display="flex" alignItems="start">
          <Toggle
            id="fullyDelete"
            mr={3}
            checked={isFullyDelete}
            onChange={handleFullyDeleteSwitch}
          />
          <FormControl.FormLabel htmlFor="fullyDelete" m={0}>
            Delete all historical data
            <Text size="sm" color="gray.600" pt={1}>
              Permanently delete all historical data associated with this
              subscriber (for Right-to-be-Forgotten-Requests)
            </Text>
          </FormControl.FormLabel>
        </FormControl>
        {isFullyDelete && (
          <FormControl pt={6}>
            <FormControl.FormLabel htmlFor="fullyDeleteConfirmationText">
              Type {confirmationMessage} to confirm
            </FormControl.FormLabel>
            <Input
              id="fullyDeleteConfirmationText"
              type="text"
              maxLength={120}
              value={deleteConfirmation}
              onChange={handleConfirmationChange}
            />
          </FormControl>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleRemoveSubscribers}
          isDisabled={removeButtonDisabled}
        >
          Remove subscriber
        </Button>
      </Modal.Footer>
    </>
  );
}

interface SuccessStateProps {
  emailAddress: string;
  handleClose: () => void;
}

function SuccessState({ emailAddress, handleClose }: SuccessStateProps) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Successfully removed subscriber</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={1}>
          <CheckCircleIcon size={5} color="green.600" />
          <Text size="sm" color="gray.600">
            We have unsubscribed{' '}
            <span className="font-weight-bold">{emailAddress}</span> from your
            Campaign.
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}

interface SingleUnsubscribeModalProps {
  campaignURN: string;
  emailAddress: string;
  isOpen: boolean;
  onClose: () => void;
  handleSuccess: () => void;
}

function SingleUnsubscribeModal({
  campaignURN,
  emailAddress,
  isOpen,
  onClose,
  handleSuccess,
}: SingleUnsubscribeModalProps) {
  const [modalState, setModalState] = useState(FRONTEND_FORM_STATES.INPUT);

  const handleClose = () => {
    setModalState(FRONTEND_FORM_STATES.INPUT);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered={true}>
      {modalState === FRONTEND_FORM_STATES.INPUT && (
        <InputState
          campaignURN={campaignURN}
          emailAddress={emailAddress}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          setModalState={setModalState}
        />
      )}
      {modalState === FRONTEND_FORM_STATES.SUCCESS && (
        <SuccessState emailAddress={emailAddress} handleClose={handleClose} />
      )}
    </Modal>
  );
}

export default SingleUnsubscribeModal;
