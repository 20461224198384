/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  Container,
  Description,
  Title,
} from 'components/settings/campaigns/common';
import { useEffect, useState } from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';

import Loading from 'components/settings/Loading';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as url from 'common/url';

import { PROPERTY_SETTING_TYPES } from 'common/constants';

import '../../Campaign.css';

export default function AllCampaignsBrandingSuccess() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();
  const [redirectURLError, setRedirectURLError] = useState('');

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Branding-Success:handleSave');

      if (redirectURL.length > 0) {
        let urlToValidate = redirectURL;
        if (
          urlToValidate.indexOf('http://') === -1 &&
          urlToValidate.indexOf('https://') === -1
        ) {
          urlToValidate = `https://${urlToValidate}`;
        }
        settings.setPropertySettingValue({
          settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
          settingKey: 'redirectURL',
          settingValue: urlToValidate,
        });
        if (!url.isValidURL(urlToValidate)) {
          setRedirectURLError('Please enter a valid URL, or leave blank');
          return;
        }
        setRedirectURLError('');
      }

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const apiRedirectURLError = errors.getErrorMessage(error);
        settings.setError(apiRedirectURLError);
        toast({ variant: 'error', title: apiRedirectURLError });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const confirmationTitleCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'confirmationTitleCopy'
  );

  const confirmationBodyCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'confirmationBodyCopy'
  );

  /* Removed Redirect message temporarily until it is actually used */
  // const redirectMessageCopy = settings.getPropertySettingValue(
  //   PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
  //   'redirectMessageCopy'
  // );

  const redirectURL = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'redirectURL'
  );

  const handleConfirmationTitleChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'confirmationTitleCopy',
      settingValue: event.target.value,
    });
  };

  const handleConfirmationBodyChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'confirmationBodyCopy',
      settingValue: event.target.value,
    });
  };

  /* Removed Redirect message temporarily until it is actually used */
  // const handleRedirectMessageChange = event => {
  //   settings.setPropertySettingValue({
  //     settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
  //     settingKey: 'redirectMessageCopy',
  //     settingValue: event.target.value,
  //   });
  // };

  const handleRedirectURLChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'redirectURL',
      settingValue: event.target.value,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Container>
      <Title className="mb-3">Success</Title>
      <Description className="mb-3">
        Configure the messaging shown after a reader successfully subscribes to
        one or more Campaigns, and optionally redirect to a page of your choice
      </Description>
      <div className="mb-4">
        <div>
          <FormLabel className="ebx-h3 mb-1">Confirmation title</FormLabel>
          <FormControl
            name="confirmationTitleCopy"
            id="confirmationTitleCopy"
            required
            className="ebx-input ebx-body-1"
            type="text"
            value={confirmationTitleCopy}
            onChange={handleConfirmationTitleChange}
            autoComplete="off"
          />
        </div>
      </div>

      <div className="mb-4">
        <div>
          <FormLabel className="ebx-h3 mb-1">Confirmation body</FormLabel>
          <FormControl
            name="confirmationBodyCopy"
            id="confirmationBodyCopy"
            required
            className="ebx-input ebx-body-1"
            type="text"
            value={confirmationBodyCopy}
            onChange={handleConfirmationBodyChange}
            autoComplete="off"
          />
        </div>
      </div>

      {/* Removed Redirect message temporarily until it is actually used */}
      {/* <div className="mb-4">
        <div>
          <FormLabel className="ebx-h3 mb-1">Redirect message</FormLabel>
          <FormControl
            name="redirectMessageCopy"
            id="redirectMessageCopy"
            required
            className="ebx-input ebx-body-1"
            type="text"
            value={redirectMessageCopy}
            onChange={handleRedirectMessageChange}
            autoComplete="off"
          />
        </div>
      </div> */}

      <div className="mb-4">
        <div>
          <FormLabel className="ebx-h3 mb-1">Redirect URL</FormLabel>
          {redirectURLError && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {redirectURLError}
            </div>
          )}
          <FormControl
            name="redirectURL"
            id="redirectURL"
            required
            className="ebx-input ebx-body-1"
            type="text"
            value={redirectURL}
            onChange={handleRedirectURLChange}
            autoComplete="off"
          />
        </div>
      </div>
    </Container>
  );
}
