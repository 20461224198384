import { Button, Card } from '@ebx-ui/ebx-ui-component-library-sdk';

import CampaignPerformanceStatistics from 'components/campaigns/subscribers/CampaignPerformanceStatistics';

import { SubscriberInsights, SubscriberUpload } from 'common/types';

interface DefaultProps {
  campaignDetails: {
    campaignURN: string;
    campaignName: string;
    createdByEmail: string;
    createdUnixTime: number;
    lastUpdatedByEmail?: string;
    lastUpdatedUnixTime?: number;
  };
  campaignInsights: SubscriberInsights | null;
  isShutOff: boolean;
  latestUpload?: SubscriberUpload;
  openAnalytics: () => void;
}

function Default({
  campaignDetails,
  campaignInsights = null,
  isShutOff,
  latestUpload,
  openAnalytics,
}: DefaultProps) {
  return (
    <Card
      py={6}
      borderBottomRadius={0}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <CampaignPerformanceStatistics
        campaignURN={campaignDetails.campaignURN}
        campaignInsights={campaignInsights}
        isShutOff={isShutOff}
        latestUpload={latestUpload}
      />
      {!isShutOff && (
        <Button variant="link" onClick={openAnalytics}>
          View analytics
        </Button>
      )}
    </Card>
  );
}

export default Default;
