/* eslint @typescript-eslint/no-use-before-define: "off" */

export { isTagUsedInBlock };

const isTagUsedInText = (tag: string, text: string) =>
  // eslint-disable-next-line no-useless-concat
  text.includes('${' + `${tag}` + '}');

const isTagUsedInBlock = (tag: string, title: string, body: string) => {
  const isTagUsedInTitle = isTagUsedInText(tag, title);
  const isTagUsedInBody = isTagUsedInText(tag, body);
  if (isTagUsedInTitle && !isTagUsedInBody) {
    return 'In Title';
  }
  if (isTagUsedInTitle && isTagUsedInBody) {
    return 'In Title and Text Body';
  }
  if (!isTagUsedInTitle && isTagUsedInBody) {
    return 'In Text Body';
  }
  return 'Not Used';
};
