/* eslint @typescript-eslint/no-use-before-define: "off" */

import Moment from 'moment-timezone';

import { OCCURRENCES, WEEKDAYS } from 'common/constants';
import { mod } from 'common/maths';

export {
  createCron,
  getCronParts,
  getCronDayOfWeek,
  getCronHours,
  getMonthlySchedule,
  isMonthlyCron,
};

interface cronParts {
  seconds: string;
  minutes: string;
  hours: string;
  dayOfMonth: string;
  month: string;
  dayOfWeek: string;
  year?: string;
}

interface monthlySchedule {
  occurrence: string | null;
  dayOfWeek: string | null;
}

const CRON_WEEKDAYS = [WEEKDAYS[6], ...WEEKDAYS.slice(0, 6)];

const getCronParts = (cronString: string): cronParts => {
  const cronArray = cronString.split(' ');

  const cronParts = {
    seconds: cronArray[0],
    minutes: cronArray[1],
    hours: cronArray[2],
    dayOfMonth: cronArray[3],
    month: cronArray[4],
    dayOfWeek: cronArray[5],
    year: cronArray.length > 6 ? cronArray[6] : undefined,
  };

  return cronParts;
};

const isNumeric = (str: string) => !isNaN(parseInt(str, 10));

const dayNumMap: Map<string, number> = CRON_WEEKDAYS.reduce(
  (map, currentString, currentIndex) => {
    map.set(currentString, currentIndex + 1);
    return map;
  },
  new Map<string, number>()
);

const mapNumberToDay = (n: string) => {
  const dayValue = parseInt(n, 10);
  return CRON_WEEKDAYS[dayValue - 1];
};

const splitRange = (days: string) => {
  const [start, end] = days.split('-');
  const startDay = isNumeric(start) ? mapNumberToDay(start) : start;
  const startIndex = CRON_WEEKDAYS.indexOf(startDay);
  const endDay = isNumeric(end) ? mapNumberToDay(end) : end;
  const endIndex = CRON_WEEKDAYS.indexOf(endDay);

  if (startIndex < endIndex) {
    return CRON_WEEKDAYS.slice(startIndex, endIndex + 1);
  }
  if (endIndex < startIndex) {
    return [
      ...CRON_WEEKDAYS.slice(0, endIndex + 1),
      ...CRON_WEEKDAYS.slice(startIndex),
    ];
  }

  // start and end are equal
  return [startDay];
};

const getCronDayOfWeek = (cronString?: string | null) => {
  if (cronString == null) {
    return [];
  }

  const cronDayOfWeekString = getCronParts(cronString).dayOfWeek;

  if (cronDayOfWeekString === '') {
    return [];
  }

  if (cronDayOfWeekString === '*') {
    return CRON_WEEKDAYS;
  }
  if (cronDayOfWeekString === '?') {
    return [];
  }
  if (cronDayOfWeekString === 'L') {
    return [CRON_WEEKDAYS[CRON_WEEKDAYS.length - 1]];
  }
  if (cronDayOfWeekString.match(/[#L/]/g)) {
    // We aren't using this for weekly crons
    return [];
  }

  const dayOfWeekArray = cronDayOfWeekString
    .split(',')
    .reduce<string[]>(
      (prev, days) =>
        days.includes('-') ? [...prev, ...splitRange(days)] : [...prev, days],
      []
    );

  return dayOfWeekArray.map(day =>
    isNumeric(day) ? mapNumberToDay(day) : day
  );
};

const isMonthlyCron = (cronString?: string | null): boolean => {
  if (cronString == null) {
    return false;
  }

  const cronDayOfWeekString = getCronParts(cronString).dayOfWeek;

  if (cronDayOfWeekString.match(/([1-7])(#1|L)/g)) {
    return true;
  }

  return false;
};

const getMonthlySchedule = (cronString?: string | null): monthlySchedule => {
  const emptySchedule = {
    occurrence: null,
    dayOfWeek: null,
  };

  if (cronString == null) {
    return emptySchedule;
  }

  const cronDayOfWeekString = getCronParts(cronString).dayOfWeek;
  const matches = [...cronDayOfWeekString.matchAll(/([1-7])(#1|L)/g)];
  if (matches.length !== 1) {
    return emptySchedule;
  }

  return {
    occurrence: matches[0][2] === 'L' ? OCCURRENCES.LAST : OCCURRENCES.FIRST,
    dayOfWeek: mapNumberToDay(matches[0][1]),
  };
};

const getHourOffset = (timezone?: string | null): number => {
  if (timezone == null) {
    return 0;
  }
  return Moment().tz(timezone).utcOffset() / 60;
};

const getCronHours = (
  cronString?: string | null,
  timezone?: string | null
): number => {
  if (cronString == null) {
    return 0;
  }

  const hourOffset = getHourOffset(timezone);

  const cronHoursString = getCronParts(cronString).hours;

  if (cronHoursString.match(/[-,*/]/g)) {
    // Assumes hours will be a single number (i.e. won't accept special characters -,*/)
    return 0;
  }

  const cronHours = parseInt(cronHoursString, 10);

  return mod(cronHours + hourOffset, 24);
};

const createCron = ({
  hours,
  dayOfWeek,
  monthlySchedule,
  timezone,
}: {
  hours: number;
  dayOfWeek?: string[];
  monthlySchedule?: {
    occurrence: string | null;
    dayOfWeek: string | null;
  };
  timezone?: string | null;
}) => {
  const hourOffset = getHourOffset(timezone);
  const offsetHours = mod(hours - hourOffset, 24);

  if (typeof dayOfWeek !== 'undefined') {
    const days = dayOfWeek.join(',');
    return `0 0 ${offsetHours} ? * ${days} *`;
  }

  if (monthlySchedule?.occurrence && monthlySchedule?.dayOfWeek) {
    const occurrence =
      monthlySchedule.occurrence === OCCURRENCES.FIRST ? '#1' : 'L';
    const weekDay = dayNumMap.get(monthlySchedule.dayOfWeek);

    return `0 0 ${offsetHours} ? * ${weekDay}${occurrence} *`;
  }

  return null;
};
