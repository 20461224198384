/* eslint react/forbid-prop-types: "off" */

import {
  DuplicateCopyIcon,
  Flex,
  Heading,
  IconButton,
  Text,
  Tooltip,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as clipboard from 'common/clipboard';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import useDeveloperMode from 'hooks/useDeveloperMode';
import useStaffMode from 'hooks/useStaffMode';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';

import { EDITION_APPROVAL_STATES, EDITION_SUBJECT_TYPES } from 'common/enums';

import { STAFF_MODAL_STATES } from 'components/staff/StaffModalWrapper';

import './StaffModal.css';

function StaffModal({
  show,
  handleClose,
  setCurrentComponent,
  isProcessing,
  setIsProcessing,
  globalInfo,
  isStaffUser,
}) {
  const campaignDetails = campaigns.getCurrentCampaign(globalInfo);
  const propertyDetails = properties.getCurrentProperty(globalInfo);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isStaffModeActive, setStaffModeActive] = useStaffMode();
  const [isDeveloperModeActive, setDeveloperModeActive] = useDeveloperMode(
    'TEC-17271' // Developer Mode
  );

  const debugMetadata = {
    campaign: {
      name: campaignDetails?.campaignName,
      urn: campaignDetails?.campaignURN,
    },
    property: {
      name: propertyDetails?.propertyName,
      urn: propertyDetails?.propertyURN,
    },
    user: {
      email: globalInfo?.user?.emailAddress,
    },
  };

  const toast = useToast();

  const handleDeveloperModeToggle = () => {
    setDeveloperModeActive(!isDeveloperModeActive);
    window.location.reload(false);
  };

  const handleGenerateError = () => {
    toast({ variant: 'success', title: 'Error generated' });
    window.methodDoesNotExist();
  };

  const handleNewEdition = () => {
    logger.info('StaffModal:handleNewEdition');
    const addNewEdition = async () => {
      setIsProcessing(true);
      try {
        const subjectType =
          campaigns.getCurrentCampaignSettingValue(
            globalInfo,
            CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
            'subjectType'
          ) ?? EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE;
        const tomorrow = datetime.getUnixTimestamp() + 24 * 60 * 60;
        const date = new Date(tomorrow * 1000);
        const subject = editions.getEditionSubject(
          campaignDetails,
          date,
          subjectType
        );
        const editionURN = await API.postEditions({
          campaignURN: campaignDetails.campaignURN,
          editionDetails: {
            approvalState: EDITION_APPROVAL_STATES.NEW,
            editionSubject: subject,
            scheduledUnixTime: tomorrow,
            populate: false,
          },
        });
        tracker.track({
          eventName: 'New Edition',
        });
        setIsProcessing(false);
        if (!isStaffModeActive) {
          await handleStaffModeToggle();
        }
        window.location.href = `/editions/${editionURN}/review`;
      } catch (error) {
        setErrorMessage(errors.getErrorMessage(error));
        console.log(error);
        setIsProcessing(false);
      }
    };
    addNewEdition();
  };

  const handleNewProperty = () => {
    setCurrentComponent(STAFF_MODAL_STATES.NEW_PROPERTY);
  };

  const handleOpenImpersonate = () => {
    setCurrentComponent(STAFF_MODAL_STATES.IMPERSONATE_USER);
  };

  const handleOpenUploadEmailQuota = () => {
    setCurrentComponent(STAFF_MODAL_STATES.UPLOAD_EMAIL_QUOTA);
  };

  const handleStaffModeToggle = () => {
    setStaffModeActive(!isStaffModeActive);
    window.location.reload(false);
  };

  const handleCopyDebugMetadata = () => {
    const stringifiedMetadata = `Campaign Name: ${debugMetadata.campaign.name}
    Campaign URN: ${debugMetadata.campaign.urn}
    Property Name: ${debugMetadata.property.name}
    Property URN: ${debugMetadata.property.urn}
    Current User Email: ${debugMetadata.user.email}
    `.replace(/  +/g, '');
    clipboard.setClipboardData(null, stringifiedMetadata);
    toast({ variant: 'light', title: 'Copied debug metadata' });
  };

  useEffect(() => {
    setIsProcessing(false);
    setTimeout(() => {
      document.getElementById('username')?.focus();
    }, 100);
  }, [show, setIsProcessing]);

  return (
    <Form noValidate={true}>
      <Modal.Body className="ebx-default-modal-body ebx-body-1 ebx-modal-body">
        {/* Tools that are only available to Staff Users */}
        {isStaffUser && (
          <div className="mb-4">
            <div className="ebx-h3 mb-1">Admin tools</div>
            <div className={`${isStaffUser ? 'mb-1' : 'mb-3'}`}>
              <Tooltip
                label="Impersonate a user with an email address"
                placement="top"
              >
                <Button
                  variant="link"
                  onClick={handleOpenImpersonate}
                  className="ebx-h3 p-0"
                  type="button"
                  disabled={isProcessing}
                >
                  Impersonate
                </Button>
              </Tooltip>
            </div>
            <div className="mb-1">
              <Tooltip
                label="Create a new Edition for the campaign you are currently viewing"
                placement="top"
              >
                <Button
                  variant="link"
                  onClick={handleNewEdition}
                  className="ebx-h3 p-0"
                  type="button"
                  disabled={isProcessing}
                >
                  Create a new Edition
                </Button>
              </Tooltip>
            </div>
            <div className="mb-1">
              <Tooltip label="Create a new Property" placement="top">
                <Button
                  variant="link"
                  onClick={handleNewProperty}
                  className="ebx-h3 p-0"
                  type="button"
                  disabled={isProcessing}
                >
                  Create a new Property
                </Button>
              </Tooltip>
            </div>
            <div className="mb-1">
              <Tooltip
                label="Upload a CSV listing the Email Quotas for each property"
                placement="top"
              >
                <Button
                  variant="link"
                  onClick={handleOpenUploadEmailQuota}
                  className="ebx-h3 p-0"
                  type="button"
                  disabled={isProcessing}
                >
                  Upload Email Quota
                </Button>
              </Tooltip>
            </div>
          </div>
        )}

        {/* Tools that are available to Echobox Users / Staff Users  */}
        <div className="ebx-h3 mt-2 mb-1">Staff tools</div>
        {errorMessage && (
          <div className="d-flex align-items-center error--text mt-2 mb-2">
            <span>{errorMessage}</span>
          </div>
        )}
        <Tooltip
          label="Staff Mode allows you to view and edit an Edition without losing
              the ‘Generate Edition’ button. Useful for demos"
          placement="top"
        >
          <div className="staff-mode__box">
            <Button variant="link" type="button" className="m-0 p-0">
              <Form.Check
                id="staff-mode-checkbox"
                name="staff-mode"
                type="checkbox"
                label="Staff mode"
                className="ebx-h3 staff-mode__checkbox"
                onChange={handleStaffModeToggle}
                checked={isStaffModeActive}
                value={isStaffModeActive}
                disabled={isProcessing}
              />
            </Button>
          </div>
        </Tooltip>
        <Tooltip
          label="Developer Mode allows you to access features which are currently
              in development and not yet suitable for public viewing."
          placement="top"
        >
          <div className="staff-mode__box">
            <Button variant="link" type="button" className="m-0 p-0">
              <Form.Check
                id="developer-mode-checkbox"
                name="developer-mode"
                type="checkbox"
                label="Developer mode"
                className="ebx-h3 staff-mode__checkbox"
                onChange={handleDeveloperModeToggle}
                checked={isDeveloperModeActive}
                value={isDeveloperModeActive}
                disabled={isProcessing}
              />
            </Button>
          </div>
        </Tooltip>
        {isDeveloperModeActive && (
          <>
            <div className="ebx-h3 mt-4 mb-1">Developer tools</div>
            <div className="mt-1 mb-1">
              <Button
                variant="link"
                onClick={handleGenerateError}
                className="ebx-h3 p-0"
                type="button"
                disabled={isProcessing}
              >
                Simulate unhandled error
              </Button>
            </div>
          </>
        )}
        <Flex direction="column" mt={4} alignItems="flex-start" gap={2}>
          <Flex alignItems="center" gap={1}>
            <Heading fontSize="14px">Debug Metadata</Heading>
            <IconButton
              borderWidth={0}
              icon={<DuplicateCopyIcon />}
              size="sm"
              onClick={handleCopyDebugMetadata}
            />
          </Flex>
          <Text size="xs">Campaign Name: {debugMetadata.campaign.name}</Text>
          <Text size="xs">Campaign URN: {debugMetadata.campaign.urn}</Text>
          <Text size="xs">Property Name: {debugMetadata.property.name}</Text>
          <Text size="xs">Property URN: {debugMetadata.property.urn}</Text>
          <Text size="xs">Current User Email: {debugMetadata.user.email}</Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Form>
  );
}

StaffModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setCurrentComponent: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  setIsProcessing: PropTypes.func.isRequired,
  globalInfo: PropTypes.shape({
    current: PropTypes.shape({
      campaignURN: PropTypes.string.isRequired,
      propertyURN: PropTypes.string.isRequired,
    }).isRequired,
    properties: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
    user: PropTypes.shape({
      emailAddress: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isStaffUser: PropTypes.bool.isRequired,
};

export default StaffModal;
