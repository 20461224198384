import {
  AddIcon,
  Card,
  EditPenIcon,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Thumbnail from 'components/common/Thumbnail';
import EditTextBlock from 'components/editor/curate/EditTextBlock';

import { EDITION_APPROVAL_STATES } from 'common/enums';
import * as sanitise from 'common/sanitise';
import { TextBlock as TextBlockType } from 'common/types';

interface TextBlockProps {
  campaignDetails: {
    campaignURN: string;
    campaignName: string;
  };
  editionDetails: {
    editionURN: string;
    approvalState: keyof typeof EDITION_APPROVAL_STATES;
    scheduledUnixTime: number;
  };
  textBlock: TextBlockType;
  handleSaveTextBlock: () => void;
  isSavingTextBlock: boolean;
  personalisationSettings: any;
}

function TextBlock({
  campaignDetails,
  editionDetails,
  textBlock,
  handleSaveTextBlock,
  isSavingTextBlock,
  personalisationSettings,
}: TextBlockProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShow = () => {
    onOpen();
  };

  // Sanitise the text block body
  const sanitisedTextBlockBody = sanitise.safeHTML(textBlock.textBlockBody);

  const isTextBlockEmpty = () =>
    !sanitisedTextBlockBody && !textBlock.textBlockTitle;

  const titleProps = {
    empty: {
      content: 'Add title',
      color: 'gray.600',
    },
    exists: {
      content: textBlock.textBlockTitle,
      color: 'gray.900',
    },
  };

  const bodyProps = {
    empty: {
      content: 'Add text',
      color: 'gray.600',
    },
    exists: {
      content: sanitisedTextBlockBody,
      color: 'gray.900',
    },
  };

  const textBlockTitleProps = textBlock.textBlockTitle
    ? titleProps.exists
    : titleProps.empty;

  const textBlockBodyProps = sanitisedTextBlockBody
    ? bodyProps.exists
    : bodyProps.empty;

  return (
    <>
      <Flex flexDir="column" gap={3}>
        <Heading variant="h3">Text</Heading>
        <Card
          px={4}
          borderRadius="4px"
          gap={4}
          onClick={handleShow}
          _hover={{
            boxShadow:
              '0px 1px 2px rgba(14, 30, 57, 0.12), 0px 4px 8px rgba(14, 30, 57, 0.08);',
            cursor: 'pointer',
            outline: '1px solid #e3eaf3',
          }}
        >
          {isTextBlockEmpty() ? (
            <>
              <Heading variant="h4" color="gray.600" flexGrow={1}>
                Add text
              </Heading>
              <AddIcon size={5} color="gray.600" />
            </>
          ) : (
            <Flex gap={3} width="100%">
              {textBlock.imageURL && (
                <Thumbnail
                  image={textBlock.imageURL}
                  height="48px"
                  width="85px"
                  alt="Text block image"
                />
              )}
              <Card.CardContent gap={2} flexGrow={1} minW={0}>
                <Card.CardTitle color={textBlockTitleProps.color}>
                  {textBlockTitleProps.content}
                </Card.CardTitle>
                <Card.CardDescription
                  className="stay-within-parent"
                  color={textBlockBodyProps.color}
                  dangerouslySetInnerHTML={{
                    __html: textBlockBodyProps.content,
                  }}
                  sx={{
                    p: {
                      marginBottom: '0px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </Card.CardContent>
              <Spacer />
              <EditPenIcon size={4} color="gray.600" />
            </Flex>
          )}
        </Card>
      </Flex>
      {isOpen && (
        <EditTextBlock
          campaignDetails={campaignDetails}
          editionDetails={editionDetails}
          textBlock={textBlock}
          handleSaveTextBlock={handleSaveTextBlock}
          isSavingTextBlock={isSavingTextBlock}
          isOpen={isOpen}
          onClose={onClose}
          mergeTags={personalisationSettings.mergeTags}
        />
      )}
    </>
  );
}

export default TextBlock;
