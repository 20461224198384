/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { Col, FormControl, FormLabel } from 'react-bootstrap';

import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import SettingsPageWrapper from 'components/settings/campaigns/all/SettingsPageWrapper';
import SidebarWrapper from 'components/settings/campaigns/all/SidebarWrapper';
import { Container, Content } from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';

function PreferencesCenter() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const updateLabel = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'updatePreferencesButtonText'
  );
  const cancelLabel = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'cancelAllSubscriptionsButtonText'
  );
  const selectAllNewslettersButtonText = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'selectAllNewslettersButtonText'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Preferences-PreferencesCenter:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleupdateLabelChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'updatePreferencesButtonText',
      settingValue: event.target.value,
    });
  };

  const handlecancelLabelChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'cancelAllSubscriptionsButtonText',
      settingValue: event.target.value,
    });
  };

  const handleAllNewslettersButtonChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'selectAllNewslettersButtonText',
      settingValue: event.target.value,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <SettingsPageWrapper>
      <SidebarWrapper
        subPath="subscriber-preferences-center"
        backLabel="subscriber preferences center"
      >
        <Container>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mt-3 mb-2">
              Update preferences
            </FormLabel>
            <FormControl
              name="cancelAllSubscriptionsButtonText"
              id="cancelAllSubscriptionsButtonText"
              required
              className="ebx-input ebx-body-1"
              type="text"
              placeholder="Update preferences"
              value={updateLabel}
              onChange={handleupdateLabelChange}
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mb-2">
              Cancel all your subscriptions
            </FormLabel>
            <FormControl
              className="ebx-input ebx-body-1"
              style={{ width: '100%' }}
              type="text"
              placeholder="Cancel all your subscriptions"
              required
              value={cancelLabel}
              onChange={handlecancelLabelChange}
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mb-2">All newsletters</FormLabel>
            <FormControl
              className="ebx-input ebx-body-1"
              style={{ width: '100%' }}
              type="text"
              placeholder="All newsletters"
              required
              value={selectAllNewslettersButtonText}
              onChange={handleAllNewslettersButtonChange}
              autoComplete="off"
            />
          </div>
        </Container>
      </SidebarWrapper>
      <Col xs={12} md={8}>
        <Content>
          <PreviewWrapper
            propertyURN={propertyURN}
            previewTypes={[PREVIEW_TYPES.MANAGE_PREFERENCES]}
            settings={settings}
          />
        </Content>
      </Col>
    </SettingsPageWrapper>
  );
}

export default PreferencesCenter;
