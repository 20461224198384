import PropTypes from 'prop-types';

function HeaderPreview({ img }) {
  return (
    <div className="header-preview">
      <img src={img} alt="header" />
    </div>
  );
}

HeaderPreview.propTypes = {
  img: PropTypes.string.isRequired,
};

export default HeaderPreview;
