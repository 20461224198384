/* eslint @typescript-eslint/no-use-before-define: "off" */

import * as authentication from 'common/authentication';
import * as logger from 'common/logger';
import { FixTypeLater } from 'common/types';
import * as users from 'common/users';
import { isDefined } from 'common/utility';

import { GLOBAL_INFO_STATES, ROUTE_REDIRECTIONS } from 'common/constants';

export { requireAdmin, requireSignedIn, requireSignedOut };

// All redirector methods return null if no redirection is required

const requireAdmin = (path: string, globalInfo: FixTypeLater) => {
  logger.info(`routing.requireAdmin: ${path}`);

  // Redirections:
  // - Home page if user does not have admin permissions for the current property
  // - Campaign settings page if user without admin permissions tries to access settings

  if (globalInfo) {
    const isAdmin = users.isAdministrator(globalInfo);
    if (!isAdmin) {
      return path.includes('settings')
        ? `/settings${ROUTE_REDIRECTIONS.HOME}`
        : ROUTE_REDIRECTIONS.HOME;
    }
  }

  return null;
};

const requireSignedIn = (path: string, globalInfo: FixTypeLater) => {
  logger.info(`routing.requireSignedIn: ${path}`);

  if (
    globalInfo.state !== GLOBAL_INFO_STATES.INITIALISED &&
    globalInfo.state !== GLOBAL_INFO_STATES.READY
  ) {
    return null;
  }
  return isDefined(globalInfo.user) ? null : ROUTE_REDIRECTIONS.SIGNIN;
};

const requireSignedOut = (path: string, globalInfo: FixTypeLater) => {
  logger.info(`routing.requireSignedOut: ${path}`);

  if (globalInfo.state !== GLOBAL_INFO_STATES.READY) {
    return null;
  }
  return authentication.isSignedIn() && globalInfo.user
    ? ROUTE_REDIRECTIONS.HOME
    : null;
};
