/* eslint @typescript-eslint/no-use-before-define: "off" */

import blocks from 'components/editor/blocks/blocks.json';

export {
  createCustomBlock,
  getBlockList,
  getBlockSchema,
  getBlockType,
  getBlockURN,
  isCustomBlock,
};

function createCustomBlock(blockType: string) {
  const blockSchema = getBlockSchema(blockType);
  const customBlock = {};

  // Attributes
  Object.keys(blockSchema.attributes).forEach(key => {
    // @ts-ignore
    const attribute = blockSchema.attributes[key];
    switch (attribute.datatype) {
      case 'align': {
        // @ts-ignore
        customBlock.align = 'left'; // TODO - optionally inherit default value
        break;
      }
      case 'color': {
        // @ts-ignore
        customBlock[key] = attribute.default ?? '#000000'; // TODO - optionally inherit default value
        break;
      }
      case 'percentage': {
        // @ts-ignore
        customBlock[key] = 50; // TODO - optionally inherit default value
        break;
      }
      case 'url': {
        // @ts-ignore
        customBlock[key] = ''; // TODO - optionally inherit default value
        break;
      }
    }
  });

  // Supports
  Object.keys(blockSchema.groups).forEach(key => {
    switch (key) {
      case 'border': {
        // @ts-ignore
        customBlock.border = '1px solid #CC0000'; // TODO - optionally inherit default value
        break;
      }
      case 'divider-border': {
        // @ts-ignore
        customBlock.borderColor = '#CC0000'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.borderStyle = 'solid'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.borderWidth = '1px'; // TODO - optionally inherit default value
        break;
      }
      case 'padding': {
        // @ts-ignore
        customBlock.padding = '10px 20px 10px 20px'; // TODO - optionally inherit default value
        break;
      }
      case 'text': {
        // @ts-ignore
        customBlock.align = 'left'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.color = '#000000'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.content =
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.fontFamily = ''; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.fontSize = '14px'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.fontStyle = 'normal'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.fontWeight = 400; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.letterSpacing = '1em'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.lineHeight = 140; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.textDecoration = 'none'; // TODO - optionally inherit default value
        // @ts-ignore
        customBlock.textTransform = 'none'; // TODO - optionally inherit default value
      }
    }
  });

  return customBlock;
}

function getBlockList() {
  return blocks;
}

function getBlockSchema(blockURN: string) {
  const blockDefinition = getBlockList().filter(
    block => block.blockURN === blockURN
  )?.[0];
  return blockDefinition ?? {};
}

function getBlockType(blockURN: string) {
  const blockSchema = getBlockSchema(blockURN);
  return blockSchema ? blockSchema.title : 'Unknown';
}

function getBlockURN(blockTitle: string | null) {
  const matches =
    blockTitle?.match(/^@@@(urn:newsletter:block:.*)@@@$/) ?? null;
  return matches?.[1] ?? null;
}

function isCustomBlock(blockTitle: string | null) {
  return !!blockTitle?.match(/^@@@urn:newsletter:block:.*@@@$/);
}

export const WYSIWYG_FONT_FAMILIES = [
  { label: 'Andale Mono', value: 'andale mono, times' },
  { label: 'Arial', value: 'arial, helvetica, sans-serif' },
  {
    label: 'Arial Black',
    value: 'arial black, AvenirNext-Heavy, avant garde, arial',
  },
  { label: 'Book Antiqua', value: 'book antiqua, palatino' },
  { label: 'Cabin', value: 'Cabin, sans-serif' },
  { label: 'Comic Sans MS', value: 'comic sans ms, sans-serif' },
  { label: 'Courier New', value: 'courier new, courier' },
  { label: 'Crimson Text', value: 'Crimson Text, serif' },
  { label: 'Georgia', value: 'georgia, palatino' },
  { label: 'Helvetica', value: 'helvetica, sans-serif' },
  { label: 'Impact', value: 'impact, chicago' },
  { label: 'Lato', value: 'Lato, sans-serif' },
  { label: 'Lobster Two', value: 'Lobster Two, cursive' },
  { label: 'Montserrat', value: 'Montserrat, sans-serif' },
  { label: 'Old Standard TT', value: 'Old Standard TT, serif' },
  { label: 'Open Sans', value: 'Open Sans, sans-serif' },
  { label: 'Pacifico', value: 'Pacifico, cursive' },
  { label: 'Playfair Display', value: 'Playfair Display, serif' },
  { label: 'Raleway', value: 'Raleway, sans-serif' },
  { label: 'Rubik', value: 'Rubik, sans-serif' },
  { label: 'Source Sans Pro', value: 'Source Sans Pro, sans-serif' },
  { label: 'Symbol', unstyled: true, value: 'Symbol' },
  { label: 'Tahoma', value: 'tahoma, arial, helvetica, sans-serif' },
  { label: 'Terminal', value: 'terminal, monaco' },
  { label: 'Times New Roman', value: 'times new roman, times' },
  { label: 'Trebuchet MS', value: 'trebuchet ms, geneva' },
  { label: 'Verdana', value: 'verdana, geneva' },
];

export const WYSIWYG_FONT_STYLES = [
  { label: 'Normal', value: 'normal' },
  { label: 'Italic', value: 'italic' },
  { label: 'Oblique', value: 'oblique' },
];

export const WYSIWYG_FONT_WEIGHTS = [
  { label: 'Normal', value: 'normal' },
  { label: 'Bold', value: 'bold' },
];

export const WYSIWYG_LINE_STYLES = [
  { label: 'Solid', value: 'solid' },
  { label: 'Dotted', value: 'dotted' },
  { label: 'Dashed', value: 'dashed' },
];

export const WYSIWYG_TEXT_DECORATIONS = [
  { label: 'None', value: 'none' },
  { label: 'Underline', value: 'underline' },
  { label: 'Strikethrough', value: 'line-through' },
];

export const WYSIWYG_TEXT_TRANSFORMS = [
  { label: 'None', value: 'none' },
  { label: 'Capitalize', value: 'capitalize' },
  { label: 'Upper Case', value: 'uppercase' },
  { label: 'Lower Case', value: 'lowercase' },
];
