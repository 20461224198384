import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Modal from 'components/common/Modal';

import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';

import './VerifiedConfirmationModal.css';

function VerifiedConfirmationModal({ emailAddress, onClose }) {
  return (
    <Modal show dialogClassName="verified-confirmation-modal">
      <Modal.Header>
        <Modal.Title>Email address updated</Modal.Title>
      </Modal.Header>
      <Modal.Body mode="WHITE" className="d-flex align-items-baseline">
        <div className="success-check-frame mr-2 d-flex align-items-center justify-content-center">
          <CheckIcon className="success-check" />
        </div>
        <div className="verify-email-description">
          You&apos;ve successfully updated your email address to{' '}
          <span className="verify-email-address">{emailAddress}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="ebx-btn-primary ebx-btn-md ebx-h3" onClick={onClose}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

VerifiedConfirmationModal.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VerifiedConfirmationModal;
