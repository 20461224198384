import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface PostPromotionBlockArgs {
  campaignURN: string;
  promotionBlockDetails: object;
}

export default async function postPromotionBlock({
  campaignURN,
  promotionBlockDetails,
}: PostPromotionBlockArgs) {
  if (isUndefined(campaignURN)) {
    mandatory('campaignURN');
  }
  if (isUndefined(promotionBlockDetails)) {
    mandatory('promotionBlockDetails');
  }

  const guid = metrics.start('postPromotionBlock');

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/campaigns/${campaignURN}/promotionblocks`;

  const config = {
    url,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
    data: JSON.stringify({
      ...promotionBlockDetails,
    }),
  };

  logger.info(
    `API:postPromotionBlock /campaigns/${campaignURN}/promotionblocks`
  );

  try {
    const response = await axios(config);
    metrics.end('postPromotionBlock', guid);
    return response.data.promotionBlockURN;
  } catch (error) {
    metrics.fail('postPromotionBlock', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postPromotionBlock',
            args: { campaignURN },
          })
        : error;
    throw apiError;
  }
}
