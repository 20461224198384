import { useState } from 'react';

import { useDisclosure } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as API from 'api/api';

import SendPreviewButton from 'components/editor/preview/SendPreviewButton';
import SendPreviewModal from 'components/editor/preview/SendPreviewModal';
import SendPreviewResult from 'components/editor/preview/SendPreviewResult';

import { EDITION_APPROVAL_STATE_NAMES } from 'common/constants';
import { EDITION_APPROVAL_STATES } from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';

interface SendPreviewProps {
  approvalState: keyof typeof EDITION_APPROVAL_STATES | null;
  editionURN: string | null;
  emailAddress: string;
  isDisabled: boolean;
}

function SendPreview({
  approvalState,
  editionURN,
  emailAddress,
  isDisabled,
}: SendPreviewProps) {
  const [isSending, setSending] = useState(false);
  const [sendError, setSendError] = useState('');
  const {
    isOpen: sendPreviewIsOpen,
    onOpen: sendPreviewOnOpen,
    onClose: sendPreviewOnClose,
  } = useDisclosure();
  const {
    isOpen: sendPreviewResultIsOpen,
    onOpen: sendPreviewResultOnOpen,
    onClose: sendPreviewResultOnClose,
  } = useDisclosure();

  const handleSendPreview = async () => {
    setSendError('');
    sendPreviewOnClose();

    if (!editionURN) {
      sendPreviewResultOnOpen();
      setSendError('Unable to send preview.');
      return;
    }

    try {
      setSending(true);
      await API.postPreview({
        editionURN,
        emailAddresses: [emailAddress],
      });
      logger.info(
        `SendPreview:handleSendPreview - sent preview to ${emailAddress}`
      );
      tracker.track({
        eventName: 'Send Preview',
        trackingParams: {
          'Email ID': editionURN,
          'Edition State Before': approvalState
            ? EDITION_APPROVAL_STATE_NAMES[approvalState]
            : '',
          'Email Type': 'Newsletter',
        },
      });
      sendPreviewResultOnOpen();
      setSendError('');
    } catch (error) {
      logger.info(
        `SendPreview:handleSendPreview - failed to send preview to ${emailAddress}`
      );
      sendPreviewResultOnOpen();
      setSendError(errors.getErrorMessage(error));
    }
    setSending(false);
  };

  return (
    <>
      <SendPreviewButton
        handleShow={sendPreviewOnOpen}
        isSending={isSending}
        isDisabled={isDisabled}
      />

      {sendPreviewIsOpen && (
        <SendPreviewModal
          isOpen={sendPreviewIsOpen}
          onClose={sendPreviewOnClose}
          emailAddress={emailAddress}
          handleSend={handleSendPreview}
          isSending={isSending}
        />
      )}

      {sendPreviewResultIsOpen && (
        <SendPreviewResult
          isOpen={sendPreviewResultIsOpen}
          onClose={sendPreviewResultOnClose}
          sendPreviewError={sendError}
        />
      )}
    </>
  );
}

export default SendPreview;
