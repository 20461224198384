import { URN_TYPES } from 'common/constants';

const getURNType = urn => {
  const urnComponents = urn.split(':');

  if (urnComponents.length <= 2) {
    return null;
  }

  if (urnComponents[0] !== 'urn') {
    return null;
  }

  if (urnComponents[1] === 'property' && urnComponents.length === 3) {
    return URN_TYPES.PROPERTY;
  }

  if (urnComponents[1] !== 'newsletter' || urnComponents.length <= 3) {
    return null;
  }

  // If we get here then it is an Email URN

  if (urnComponents.length === 4) {
    switch (urnComponents[2]) {
      case 'campaign':
        return URN_TYPES.CAMPAIGN;
      case 'template':
        return URN_TYPES.TEMPLATE;
      default:
        return null;
    }
  }

  if (urnComponents[2] === 'campaign' && urnComponents.length === 6) {
    // This is a Campaign entity URN
    switch (urnComponents[4]) {
      case 'edition':
        return URN_TYPES.EDITION;
      case 'section':
        return URN_TYPES.SECTION;
      case 'promotionblock':
        return URN_TYPES.PROMOTION_BLOCK;
      case 'textblock':
        return URN_TYPES.TEXT_BLOCK;
      case 'upload':
        return URN_TYPES.UPLOAD;
      default:
        return null;
    }
  }

  return null;
};

const getURNValue = urn => {
  const urnParts = urn.split(':');
  if (urnParts.length > 0) {
    return urnParts[urnParts.length - 1];
  }
  return null;
};

const toURN = (id, urnType) => {
  switch (urnType) {
    case URN_TYPES.PROPERTY:
      return `urn:property:${id}`;
    case URN_TYPES.CAMPAIGN:
      return `urn:newsletter:campaign:${id}`;
    default:
      throw new Error('Unsupported URN type');
  }
};

export { getURNType, getURNValue, toURN };
