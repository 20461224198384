import { Box, Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import { SOCIAL_LINKS } from 'common/constants';
import { SocialPageLinksSettings } from 'common/types';

import FooterCheckbox from 'components/settings/campaigns/content/FooterCheckbox';
import FooterSocialLinkInput from 'components/settings/campaigns/content/FooterSocialLinkInput';

interface FooterSocialProps {
  socialPageSettings: {
    facebookSettings?: SocialPageLinksSettings;
    instagramSettings?: SocialPageLinksSettings;
    twitterSettings?: SocialPageLinksSettings;
    linkedInSettings?: SocialPageLinksSettings;
    pinterestSettings?: SocialPageLinksSettings;
    youtubeSettings?: SocialPageLinksSettings;
    tiktokSettings?: SocialPageLinksSettings;
  };
  handleSocialLinksToggle: (
    newValue: boolean,
    socialSettings: SocialPageLinksSettings | undefined,
    socialNetwork: keyof typeof SOCIAL_LINKS
  ) => void;
  handleSocialLinksChange: (
    newValue: string,
    socialSettings: SocialPageLinksSettings | undefined,
    socialNetwork: keyof typeof SOCIAL_LINKS
  ) => void;
}

function FooterSocial({
  socialPageSettings,
  handleSocialLinksToggle,
  handleSocialLinksChange,
}: FooterSocialProps) {
  return (
    <Box mb={5} lineHeight="inherit">
      <Heading variant="h4" mb={4}>
        Social links
      </Heading>
      <Flex flexDir="column" gap={4}>
        <FooterCheckbox
          label="Facebook"
          id="facebook"
          name="social-links"
          value={socialPageSettings.facebookSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.facebookSettings,
              SOCIAL_LINKS.FACEBOOK
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.facebookSettings,
              SOCIAL_LINKS.FACEBOOK
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://facebook.com/"
            value={socialPageSettings.facebookSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.facebookSettings,
                SOCIAL_LINKS.FACEBOOK
              )
            }
          />
        </FooterCheckbox>
        <FooterCheckbox
          label="Instagram"
          id="instagram"
          name="social-links"
          value={socialPageSettings.instagramSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.instagramSettings,
              SOCIAL_LINKS.INSTAGRAM
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.instagramSettings,
              SOCIAL_LINKS.INSTAGRAM
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://instagram.com/"
            value={socialPageSettings.instagramSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.instagramSettings,
                SOCIAL_LINKS.INSTAGRAM
              )
            }
          />
        </FooterCheckbox>
        <FooterCheckbox
          label="Twitter"
          id="twitter"
          name="social-links"
          value={socialPageSettings.twitterSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.twitterSettings,
              SOCIAL_LINKS.TWITTER
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.twitterSettings,
              SOCIAL_LINKS.TWITTER
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://twitter.com/"
            value={socialPageSettings.twitterSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.twitterSettings,
                SOCIAL_LINKS.TWITTER
              )
            }
          />
        </FooterCheckbox>
        <FooterCheckbox
          label="LinkedIn"
          id="linkedin"
          name="social-links"
          value={socialPageSettings.linkedInSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.linkedInSettings,
              SOCIAL_LINKS.LINKEDIN
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.linkedInSettings,
              SOCIAL_LINKS.LINKEDIN
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://linkedin.com/"
            value={socialPageSettings.linkedInSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.linkedInSettings,
                SOCIAL_LINKS.LINKEDIN
              )
            }
          />
        </FooterCheckbox>
        <FooterCheckbox
          label="Pinterest"
          id="pinterest"
          name="social-links"
          value={socialPageSettings.pinterestSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.pinterestSettings,
              SOCIAL_LINKS.PINTEREST
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.pinterestSettings,
              SOCIAL_LINKS.PINTEREST
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://pinterest.com/"
            value={socialPageSettings.pinterestSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.pinterestSettings,
                SOCIAL_LINKS.PINTEREST
              )
            }
          />
        </FooterCheckbox>
        <FooterCheckbox
          label="YouTube"
          id="youtube"
          name="social-links"
          value={socialPageSettings.youtubeSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.youtubeSettings,
              SOCIAL_LINKS.YOUTUBE
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.youtubeSettings,
              SOCIAL_LINKS.YOUTUBE
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://youtube.com/"
            value={socialPageSettings.youtubeSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.youtubeSettings,
                SOCIAL_LINKS.YOUTUBE
              )
            }
          />
        </FooterCheckbox>
        <FooterCheckbox
          label="TikTok"
          id="tiktok"
          name="social-links"
          value={socialPageSettings.tiktokSettings?.isEnabled ?? false}
          onUncheck={() =>
            handleSocialLinksToggle(
              false,
              socialPageSettings.tiktokSettings,
              SOCIAL_LINKS.TIKTOK
            )
          }
          onCheck={() =>
            handleSocialLinksToggle(
              true,
              socialPageSettings.tiktokSettings,
              SOCIAL_LINKS.TIKTOK
            )
          }
        >
          <FooterSocialLinkInput
            socialURL="https://tiktok.com/"
            value={socialPageSettings.tiktokSettings?.linkPath ?? ''}
            onChange={link =>
              handleSocialLinksChange(
                link,
                socialPageSettings.tiktokSettings,
                SOCIAL_LINKS.TIKTOK
              )
            }
          />
        </FooterCheckbox>
      </Flex>
    </Box>
  );
}

export default FooterSocial;
