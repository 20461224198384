import { Route, Switch } from 'react-router-dom';

import BrandingSettings from 'components/settings/campaigns/Branding';
import ConfirmationBranding from 'components/settings/campaigns/branding/email/ConfirmationBranding';
import EditionBranding from 'components/settings/campaigns/branding/email/EditionBranding';
import VerificationBranding from 'components/settings/campaigns/branding/email/VerificationBranding';
import GeneralStyling from 'components/settings/campaigns/branding/general/GeneralStyling';
import DefaultSubBranding from 'components/settings/campaigns/branding/subscription/DefaultSubBranding';
import SuccessSubBranding from 'components/settings/campaigns/branding/subscription/SuccessSubBranding';
import UnsubscribeBranding from 'components/settings/campaigns/branding/subscription/UnsubscribeBranding';
import VerificationSubBranding from 'components/settings/campaigns/branding/subscription/VerificationSubBranding';

import SettingsWrapper from 'components/settings/SettingsWrapper';

function Routing() {
  return (
    <Switch>
      <Route path="/settings/campaigns/:urn/branding" exact={true}>
        <SettingsWrapper>
          <BrandingSettings />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/:urn/branding/general-styling">
        <GeneralStyling />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/email-confirmation">
        <ConfirmationBranding />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/email-edition">
        <EditionBranding />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/email-verify">
        <VerificationBranding />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/sub-default">
        <DefaultSubBranding />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/sub-verify">
        <VerificationSubBranding />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/sub-success">
        <SuccessSubBranding />
      </Route>
      <Route path="/settings/campaigns/:urn/branding/sub-unsubscribe">
        <UnsubscribeBranding />
      </Route>
    </Switch>
  );
}

export default Routing;
