import { Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import { MODAL_MODES } from 'common/enums';

function Consent({ handleClose, handleBack, handleSubmit }) {
  const [checked, setChecked] = useState(false);

  return (
    <Modal show onHide={handleClose} backdrop="static" centered>
      <Form>
        <Modal.Header className="ebx-modal-header">
          <Modal.Title className="ebx-h1 ebx-title-colour">
            Add subscribers
          </Modal.Title>
          <span className="ml-auto">
            <Button variant="link" className="p-0" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body className="py-4" mode={MODAL_MODES.WHITE}>
          <Form.Check id="certify">
            <Form.Check.Input
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <Form.Check.Label htmlFor="certify">
              <Heading variant="h5" lineHeight={6} userSelect="none">
                Before proceeding, please certify that all these contacts have
                provided explicit consent to receive emails from you.
              </Heading>
            </Form.Check.Label>
          </Form.Check>
        </Modal.Body>
        <Modal.Footer className="ebx-modal-footer">
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            className="ebx-btn-primary ebx-btn-md ebx-h3"
            type="button"
            disabled={!checked}
            onClick={handleSubmit}
          >
            Add subscribers
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

Consent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Consent;
