import {
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import Editor from '@monaco-editor/react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import MergeTag from 'components/common/MergeTag';
import MergeTagModal from 'components/common/MergeTagModal';

import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import useDebounce from 'hooks/useDebounce';
import useGlobalInfo from 'hooks/useGlobalInfo';

import {
  CAMPAIGN_SETTING_TYPES,
  DEFAULT_DEBOUNCE_DELAY,
} from 'common/constants';

import './EmailContent.css';

function EmailContent({
  handleUpdateTemplate,
  isBusy,
  isEditable,
  templateDetails,
}) {
  const [newContent, setNewContent] = useState(templateDetails.content);

  const globalInfo = useGlobalInfo();

  const debouncedEmailContent = useDebounce(newContent, DEFAULT_DEBOUNCE_DELAY);

  const editorRef = useRef(null);

  const {
    isOpen: isOpenMergeTagModal,
    onOpen: onOpenMergeTagModal,
    onClose: onCloseMergeTagModal,
  } = useDisclosure();
  const [currentMergeTag, setCurrentMergeTag] = useState('');
  const mergeTags = useRef({});
  const mergeTagArray = useRef([]);

  const isFirstNameLastNameEnabled = campaigns.getCurrentCampaignSettingValue(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'isFirstNameLastNameEnabled'
  );
  const showMergeTags = isFirstNameLastNameEnabled && isEditable;

  useEffect(() => {
    if (debouncedEmailContent !== templateDetails.content && !isBusy) {
      logger.info('EmailContent:useEffect - saving debounced email content');
      handleUpdateTemplate(
        {
          ...templateDetails,
          content: debouncedEmailContent,
        },
        () => setNewContent(debouncedEmailContent)
      );
    }
  }, [debouncedEmailContent, handleUpdateTemplate, isBusy, templateDetails]);

  if (showMergeTags) {
    const personalisationSettings = campaigns.getCurrentCampaignSettingsType(
      globalInfo,
      CAMPAIGN_SETTING_TYPES.SUBSCRIBER_PERSONALISATION
    );
    if (personalisationSettings?.mergeTags) {
      Object.keys(personalisationSettings.mergeTags).forEach(tagName => {
        mergeTags.current[tagName] = {
          name: personalisationSettings.mergeTags[tagName].displayName,
          // eslint-disable-next-line no-useless-concat
          value: '${' + `${tagName}` + '}',
          defaultValue:
            personalisationSettings.mergeTags[tagName].defaultValue ?? '',
        };
      });
    }
    mergeTagArray.current = mergeTags.current
      ? Object.keys(mergeTags.current).sort()
      : [];
  }

  const addMergeTag = tagName => {
    logger.info(`EmailContent:addMergeTag - merge tag: ${tagName}`);

    const mergeTag = { tagName, ...mergeTags.current[tagName] };
    setCurrentMergeTag(mergeTag);
    onOpenMergeTagModal();
  };

  const handleChange = content => {
    setNewContent(content);
  };

  const handleMount = editor => {
    editorRef.current = editor;
    editorRef.current.updateOptions({ readOnly: isBusy || !isEditable });
  };

  const saveMergeTag = mergeTag => {
    logger.info(`EmailContent:saveMergeTagTitle - ${mergeTag.tagName}`);

    const selection = editorRef.current.getSelection();
    const id = { major: 1, minor: 1 };
    const op = {
      identifier: id,
      range: selection,
      // eslint-disable-next-line prefer-template
      text: '${' + mergeTag.tagName + '}',
      forceMoveMarkers: true,
    };
    editorRef.current.executeEdits('what-does-this-do', [op]);
    onCloseMergeTagModal();
  };

  const editorOptions = {
    minimap: {
      enabled: false,
    },
    tabSize: 2,
    wordWrap: 'on',
  };

  return (
    <>
      <Flex alignItems="center" mb={4} mr={4}>
        <Heading variant="h3" pl={10}>
          HTML
        </Heading>
        <Spacer />
        {showMergeTags &&
          mergeTagArray.current.map(tagName => (
            <MergeTag
              mr={1}
              key={tagName}
              label={mergeTags.current[tagName].name}
              onClick={() => addMergeTag(tagName)}
            />
          ))}
      </Flex>
      <div
        className={`ebx-email-subject w-100 d-flex mb-3 ebx-h-100 html-editor ${
          isEditable && 'ebx-email-subject-active'
        }`}
      >
        <Editor
          className="html-editor ebx-static-input ebx-body-1"
          defaultLanguage="html"
          defaultValue={newContent}
          onChange={handleChange}
          onMount={handleMount}
          options={editorOptions}
          theme="light"
        />
      </div>
      {isOpenMergeTagModal && (
        <MergeTagModal
          isOpen={isOpenMergeTagModal}
          mergeTag={currentMergeTag}
          onClose={onCloseMergeTagModal}
          onSubmit={saveMergeTag}
        />
      )}
    </>
  );
}

EmailContent.propTypes = {
  handleUpdateTemplate: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  templateDetails: PropTypes.shape({
    templateURN: PropTypes.string.isRequired,
    campaignURN: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default EmailContent;
