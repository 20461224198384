import { PREVIEW_TYPE_PROPERTIES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';

import { Nav } from 'react-bootstrap';

import { NonEmptyArray } from 'common/types';
import { Title } from 'components/settings/campaigns/common/index';

interface PreviewSelectorProps {
  previewTypes: NonEmptyArray<PREVIEW_TYPES>;
  currentPreviewType: PREVIEW_TYPES;
  onClick: (previewType: PREVIEW_TYPES) => void;
}

function PreviewSelector({
  previewTypes,
  currentPreviewType,
  onClick,
}: PreviewSelectorProps) {
  return (
    <Nav variant="tabs" className="d-flex w-100">
      {previewTypes.map(previewType => (
        <Nav.Link
          href={`#${PREVIEW_TYPE_PROPERTIES[previewType].LABEL}`}
          key={previewType}
          active={previewType === currentPreviewType}
          onSelect={() => onClick(previewType)}
        >
          <Title className="mb-0" key={previewType}>
            {PREVIEW_TYPE_PROPERTIES[previewType].LABEL}
          </Title>
        </Nav.Link>
      ))}
    </Nav>
  );
}

export default PreviewSelector;
