import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * getResolveURL
 * @param {{ url: string }} args
 */

export default async function getResolveURL({ url = mandatory('url') } = {}) {
  const guid = metrics.start('getResolveURL');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/resolveurl?url=${url}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getResolveURL /resolveurl?url=${url}`);

  try {
    const response = await axios(config);
    metrics.end('getResolveURL', guid);
    return {
      resolvedURL: response.data.resolvedURL,
      mediaURN: response.data?.mediaURN,
    };
  } catch (error) {
    metrics.fail('getResolveURL', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getResolveURL',
      args: { url },
    });
    throw apiError;
  }
}
