import {
  ChevronButton,
  Dropdown,
  Flex,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { MEDIA_DATA_SOURCE_TYPES } from 'common/enums';

interface DataSourceDropdownProps {
  name: string;
  options: { [key: string]: string };
  value: MEDIA_DATA_SOURCE_TYPES;
  onChange: (item: MEDIA_DATA_SOURCE_TYPES) => void;
}

function DataSourceDropdown({
  name,
  options,
  value,
  onChange,
}: DataSourceDropdownProps) {
  return (
    <Flex alignItems="center" justify="space-between">
      <Text size="sm">{name}</Text>
      <Dropdown matchWidth>
        <Dropdown.Button
          as={ChevronButton}
          variant="secondary"
          width="180px"
          textAlign="left"
          chevronColor="gray.600"
        >
          {options[value]}
        </Dropdown.Button>
        <Dropdown.List minWidth="none">
          {Object.keys(options).map(item => (
            <Dropdown.Item
              onClick={() => onChange(item as MEDIA_DATA_SOURCE_TYPES)}
              key={item}
            >
              {options[item]}
            </Dropdown.Item>
          ))}
        </Dropdown.List>
      </Dropdown>
    </Flex>
  );
}

export default DataSourceDropdown;
