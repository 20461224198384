/* eslint react-hooks/exhaustive-deps: "off" */

import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  Card,
  Flex,
  Heading,
  Tag,
  Text,
  Toggle,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import FrequencyMonthly from 'components/settings/campaigns/schedule/FrequencyMonthly';
import FrequencyWeekly from 'components/settings/campaigns/schedule/FrequencyWeekly';

import DomainVerificationIncomplete from 'components/campaigns/DomainVerificationIncomplete';
import {
  Container,
  Description,
  RadioLabel,
  Title,
} from 'components/settings/campaigns/common';
import TimeSlotSlider from 'components/settings/campaigns/schedule/TimeSlotSlider';
import Loading from 'components/settings/Loading';

import * as campaigns from 'common/campaigns';
import * as cron from 'common/cron';
import * as datetime from 'common/datetime';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as schedule from 'common/schedule';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import {
  CAMPAIGN_SETTING_TYPES,
  OPTIMAL_SEND_TIME,
  PROPERTY_SETTING_TYPES,
} from 'common/constants';
import { TIME_ZONE_OPTIONS } from 'common/timezones';

import './AutomationSettings.css';

const SECS_IN_HOUR = 3600;

const formatTimeZone = timeZone => {
  const timeZoneParts = timeZone?.label?.split(' ');
  const zoneName = timeZoneParts?.slice(1).join(' ');
  const zoneOffset = timeZoneParts?.[0];
  return `${zoneName} ${zoneOffset}`;
};

const getPublisherReviewWindow = publisherReviewWindowSecs => {
  // Find window in hours from seconds
  const newPublisherReviewWindow = publisherReviewWindowSecs / 60 / 60;
  return {
    label: newPublisherReviewWindow,
    value: newPublisherReviewWindow,
  };
};

const getFieldValues = scheduleSettings => {
  const schedulingSetting = scheduleSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING];
  const presentationSetting =
    scheduleSettings[CAMPAIGN_SETTING_TYPES.PRESENTATION];
  const autoApprovalSetting =
    scheduleSettings[CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL];

  let timeZone = TIME_ZONE_OPTIONS.find(
    option => option.value === schedulingSetting.timezone
  );
  /* Default to GMT if no time zone setting exists */
  if (!timeZone) {
    timeZone = {
      label: '(GMT+00:00) Greenwich Mean Time',
      value: 'Etc/GMT',
    };
  }

  const autoApprovalEnabled = autoApprovalSetting.isEnabled;

  const timeSlotStart = cron.getCronHours(
    schedulingSetting.editionCreationSchedule,
    timeZone.value
  );
  const timeSlotSeconds = schedulingSetting.sendingTimeslotSizeSeconds;

  const timeSlot = schedule.getTimeSlot({
    timeSlotStart,
    sendingTimeslotSizeSeconds: timeSlotSeconds,
  });

  const isSendTimeOptimal = schedule.isSendTimeOptimal({
    timeSlotStart,
    timeSlotSeconds,
  });

  const { twelveHourTimeFormat } = presentationSetting;

  const selectedDays = cron.getCronDayOfWeek(
    schedulingSetting.editionCreationSchedule
  );

  const isMonthlySelected = cron.isMonthlyCron(
    schedulingSetting.editionCreationSchedule
  );

  const monthlySchedule = cron.getMonthlySchedule(
    schedulingSetting.editionCreationSchedule
  );

  return {
    autoApprovalEnabled,
    isSendTimeOptimal,
    timeZone,
    timeSlot,
    twelveHourTimeFormat,
    selectedDays,
    isMonthlySelected,
    monthlySchedule,
  };
};

const sendTrackingEvent = (campaignURN, before, after) => {
  const autoApprovalBefore =
    before?.settings[CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL].isEnabled;
  const timeSlotStartBefore = cron.getCronHours(
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING].editionCreationSchedule,
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING].timezone
  );
  const sendingTimeslotSizeSecondsBefore =
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
      .sendingTimeslotSizeSeconds;
  const timeSlotBefore = schedule.getTimeSlot({
    timeSlotStart: timeSlotStartBefore,
    sendingTimeslotSizeSeconds: sendingTimeslotSizeSecondsBefore,
  });
  const selectedDaysBefore = cron.getCronDayOfWeek(
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING].editionCreationSchedule
  );
  const monthlyScheduleBefore = cron.getMonthlySchedule(
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING].editionCreationSchedule
  );
  const isMonthlySelectedBefore = cron.isMonthlyCron(
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING].editionCreationSchedule
  );
  const isSendTimeOptimalBefore = schedule.isSendTimeOptimal({
    timeSlotStart: timeSlotStartBefore,
    timeSlotSeconds: sendingTimeslotSizeSecondsBefore,
  });
  const publisherReviewWindowBefore = getPublisherReviewWindow(
    before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
      .publisherReviewWindowSecs
  );

  const trackingParams = {
    'Auto Approval (before)': autoApprovalBefore,
    'Campaign Frequency (before)': campaigns.getCampaignFrequency(
      selectedDaysBefore,
      monthlyScheduleBefore,
      isMonthlySelectedBefore
    ),
    'Campaign Frequency Type (before)': isMonthlySelectedBefore
      ? 'Monthly'
      : 'Weekly',
    'Campaign Time Zone (before)':
      before?.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING].timezone,
    'Campaign Time Format (before)': before?.settings[
      CAMPAIGN_SETTING_TYPES.PRESENTATION
    ].twelveHourTimeFormat
      ? '12-Hour'
      : '24-Hour',
    'Campaign Sending Window (before)': isSendTimeOptimalBefore
      ? 'Optimal'
      : 'Time slot',
    'Start of Timeslot Window (before)': datetime.getFormattedTimeFromParts({
      hour: timeSlotBefore[0],
    }),
    'End of Timeslot Window (before)': datetime.getFormattedTimeFromParts({
      hour: timeSlotBefore[1],
    }),
    'Timeslot Window Length (before)': before?.settings[
      CAMPAIGN_SETTING_TYPES.SCHEDULING
    ]
      ? before.settings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
          .sendingTimeslotSizeSeconds / SECS_IN_HOUR
      : null,
    'Edition Preparation Time (hours) (before)': publisherReviewWindowBefore,
  };

  tracker.track({
    eventName: 'Update Campaign Automation Settings',
    trackingParams,
    options: { campaignURN },
  });
};

const useError = toast => {
  const [errorMessage, setErrorMessage] = useState('');
  const setErrorAndCreateNotification = msg => {
    setErrorMessage(msg);
    if (msg) {
      toast({
        variant: 'error',
        title:
          'Unable to save automation settings, please ensure you have selected all fields.',
      });
    }
  };
  return [errorMessage, setErrorAndCreateNotification];
};

export default function Automation() {
  const { urn: campaignURN } = useParams();
  const globalInfo = useGlobalInfo();

  const toast = useToast();

  /* This is required to know whether we should display a warning that Editions won't send yet (they will still be created) */
  const isDomainVerificationComplete =
    properties.getCurrentPropertySettingValue(
      globalInfo,
      PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
      'isComplete'
    );

  const settings = useSettings();

  useEffect(() => {
    settings.getCampaignSettings(campaignURN);
  }, [campaignURN]);

  const schedulingSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.SCHEDULING
  );

  const presentationSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.PRESENTATION
  );

  const autoApprovalSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL
  );

  const automationSettings = useMemo(
    () => ({
      [CAMPAIGN_SETTING_TYPES.SCHEDULING]: schedulingSettings,
      [CAMPAIGN_SETTING_TYPES.PRESENTATION]: presentationSettings,
      [CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL]: autoApprovalSettings,
    }),
    [presentationSettings, schedulingSettings, autoApprovalSettings]
  );

  const {
    autoApprovalEnabled,
    isSendTimeOptimal,
    isMonthlySelected,
    selectedDays,
    monthlySchedule,
    timeZone,
    timeSlot,
    twelveHourTimeFormat,
  } = settings.isLoading ? {} : getFieldValues(automationSettings);

  const [errorMessage, setErrorMessage] = useError(toast);

  useEffect(() => {
    settings.setOnSave(async () => {
      logger.info('Schedule:handleSave');
      if (selectedDays.length === 0 && !isMonthlySelected) {
        setErrorMessage('Select the days on which you send Editions');
        settings.setChanged();
        return;
      }
      if (
        (monthlySchedule?.dayOfWeek == null ||
          monthlySchedule?.occurrence == null) &&
        isMonthlySelected
      ) {
        setErrorMessage('Select the week and day on which you send Editions');
        settings.setChanged();
        return;
      }

      try {
        // Store before and after values for Mixpanel tracking
        const before = {
          settings: settings.getInitialCampaignSettings(),
        };
        const after = {
          settings: utility.cloneObject(automationSettings),
        };

        // Update campaign settings
        await settings.saveCampaignSettings({
          campaignURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
        // Mixpanel tracking
        sendTrackingEvent(campaignURN, before, after);
      } catch (error) {
        settings.setError(errors.getErrorMessage(error));
        toast({
          variant: 'error',
          title: 'An error occurred while saving changes',
        });
      }
    });
  }, [
    campaignURN,
    globalInfo,
    automationSettings,
    selectedDays,
    monthlySchedule,
    isMonthlySelected,
    settings,
    setErrorMessage,
  ]);

  const handleTwelveHourTimeFormatChange = newValue => () => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.PRESENTATION,
      settingKey: 'twelveHourTimeFormat',
      settingValue: newValue,
    });
  };

  const handleSendTimeChange = newValue => () => {
    if (newValue) {
      settings.setCampaignSettingValues([
        {
          settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
          settingKey: 'sendingTimeslotSizeSeconds',
          settingValue: OPTIMAL_SEND_TIME.SLOT_SIZE_SECONDS,
        },
        {
          settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
          settingKey: 'editionCreationSchedule',
          settingValue: cron.createCron({
            hours: OPTIMAL_SEND_TIME.START_TIME,
            dayOfWeek: selectedDays,
            timezone: timeZone.value,
          }),
        },
      ]);
    } else {
      settings.setCampaignSettingValues([
        {
          settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
          settingKey: 'sendingTimeslotSizeSeconds',
          settingValue: 3600,
        },
        {
          settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
          settingKey: 'editionCreationSchedule',
          settingValue: cron.createCron({
            hours: 0,
            dayOfWeek: selectedDays,
            timezone: timeZone.value,
          }),
        },
      ]);
    }
  };

  const handleTimeSlotChange = newValue => {
    const timeSlotSizeHours = newValue[1] - newValue[0];
    settings.setCampaignSettingValues([
      {
        settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
        settingKey: 'sendingTimeslotSizeSeconds',
        settingValue: timeSlotSizeHours * 60 * 60,
      },
      {
        settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
        settingKey: 'editionCreationSchedule',
        settingValue: cron.createCron({
          hours: newValue[0],
          dayOfWeek: selectedDays,
          timezone: timeZone.value,
        }),
      },
    ]);
  };

  const handleSelectedDaysChange = newValue => {
    setErrorMessage('');
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
      settingKey: 'editionCreationSchedule',
      settingValue: cron.createCron({
        hours: timeSlot[0],
        dayOfWeek: newValue,
        timezone: timeZone.value,
      }),
    });
  };

  const handleIsMonthlySelectedChange = newValue => () => {
    setErrorMessage('');
    if (newValue) {
      settings.setCampaignSettingValue({
        settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
        settingKey: 'editionCreationSchedule',
        settingValue: cron.createCron({
          hours: timeSlot[0],
          monthlySchedule: {
            occurrence: 'First',
            dayOfWeek: 'MON',
          },
          timezone: timeZone.value,
        }),
      });
    } else {
      settings.setCampaignSettingValue({
        settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
        settingKey: 'editionCreationSchedule',
        settingValue: cron.createCron({
          hours: timeSlot[0],
          dayOfWeek: [],
          timezone: timeZone.value,
        }),
      });
    }
  };

  const handleMonthlyScheduleChange = newValue => {
    setErrorMessage('');
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
      settingKey: 'editionCreationSchedule',
      settingValue: cron.createCron({
        hours: timeSlot[0],
        monthlySchedule: newValue,
        timezone: timeZone.value,
      }),
    });
  };

  const handleToggleChange = () => {
    setErrorMessage('');
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL,
      settingKey: 'isEnabled',
      settingValue: !autoApprovalEnabled,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  // For now we will hide the ability to update time format as this setting is not used anywhere
  const isTimeFormatHidden = true;

  return (
    <div className="settings-schedule w-100">
      {!isDomainVerificationComplete && (
        <Container>
          <DomainVerificationIncomplete alertDescription="Echobox doesn't have permission to send emails on your behalf. If you enable automation, Editions will still be created, but we won't be able to send them yet." />
        </Container>
      )}
      <Container>
        <Card justifyContent="space-between">
          <Flex direction="column" gap="1">
            <Flex align="center" gap="1">
              <Heading variant="h4">
                Automatically schedule future Editions
              </Heading>
              <Tag color="blue">Recommended</Tag>
            </Flex>
            <Text size="sm" color="gray.600">
              Save time by not having to manually create each Edition
            </Text>
          </Flex>
          <Toggle
            isChecked={autoApprovalEnabled}
            labelPosition="left"
            onChange={handleToggleChange}
          >
            {autoApprovalEnabled ? 'On' : 'Off'}
          </Toggle>
        </Card>
        <Heading variant="h3" mt="16" mb="4">
          Frequency
        </Heading>
        <>
          <Form.Check
            checked={!isMonthlySelected}
            type="radio"
            name="frequency"
            id="frequency-weekly"
            label={
              <>
                <RadioLabel htmlFor="frequency-weekly">
                  One or more Editions a week
                </RadioLabel>
                {!isMonthlySelected && (
                  <>
                    <div className="ebx-hr-text-colour time-slot-message">
                      Select the days on which you send Editions
                    </div>
                    <FrequencyWeekly
                      selectedDays={selectedDays}
                      onDailyChange={handleSelectedDaysChange}
                      errorMessage={errorMessage}
                    />
                  </>
                )}
              </>
            }
            onChange={handleIsMonthlySelectedChange(false)}
          />

          <Form.Check
            checked={isMonthlySelected}
            type="radio"
            name="frequency"
            id="frequency-monthly"
            label={
              <RadioLabel htmlFor="frequency-monthly">
                One Edition a month
              </RadioLabel>
            }
            onChange={handleIsMonthlySelectedChange(true)}
          />
          <div className="monthly">
            {isMonthlySelected && (
              <FrequencyMonthly
                monthlyOptions={monthlySchedule}
                onMonthlyChange={handleMonthlyScheduleChange}
                errorMessage={errorMessage}
              />
            )}
          </div>
        </>
      </Container>
      {!isTimeFormatHidden && (
        <Container>
          <div className="ebx-h3 ebx-title-colour mb-2 mt-3">Time format</div>
          <Form.Check
            type="radio"
            name="time-format"
            label={
              <RadioLabel htmlFor="12-hour">12-Hour Time (6:00 pm)</RadioLabel>
            }
            checked={twelveHourTimeFormat}
            id="12-hour"
            onChange={handleTwelveHourTimeFormatChange(true)}
          />
          <Form.Check
            type="radio"
            name="time-format"
            label={
              <RadioLabel htmlFor="24-hour">24-Hour Time (18:00)</RadioLabel>
            }
            checked={!twelveHourTimeFormat}
            id="24-hour"
            onChange={handleTwelveHourTimeFormatChange(false)}
          />
        </Container>
      )}
      <Container className="send-times">
        <Title>Send times</Title>
        <Description>
          Select the send times for the Campaign. All send times are optimized
          for all readers
        </Description>
        <Form.Check
          checked={isSendTimeOptimal}
          type="radio"
          name="send-time"
          id="optimal"
          label={
            <>
              <RadioLabel htmlFor="optimal">Optimal</RadioLabel>
              <div className="optimal-message mt-1 ebx-body-1">
                Editions will be sent at optimal times throughout the day,
                starting from 06:00 {formatTimeZone(timeZone)}
              </div>
            </>
          }
          onChange={handleSendTimeChange(true)}
        />

        <Form.Check
          checked={!isSendTimeOptimal}
          type="radio"
          name="send-time"
          id="time-slot"
          label={
            <>
              <RadioLabel htmlFor="time-slot">Time slot</RadioLabel>
              <div className="time-slot-container">
                <div className="ebx-hr-text-colour time-slot-message">
                  Select the time slot Editions will be sent in (based on your
                  Campaign&apos;s timezone)
                </div>
                <TimeSlotSlider
                  value={timeSlot}
                  onChange={handleTimeSlotChange}
                />
              </div>
            </>
          }
          onChange={handleSendTimeChange(false)}
        />
      </Container>
    </div>
  );
}
