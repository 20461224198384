/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import GoogleFontsDropdown from 'components/settings/campaigns/branding/general/GoogleFontsDropdown';
import {
  Container,
  Description,
  Title,
} from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import { getGoogleFonts, loadFont } from 'common/googlefonts';

function Fonts() {
  const [loadingFonts, setIsLoadingFonts] = useState(true);
  const [googleFonts, setGoogleFonts] = useState(['']);
  const [fontsErrorMessage, setFontsErrorMessage] = useState('');

  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const titleFont =
    settings.getPropertySettingValue(
      PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
      'titleFont'
    ) || 'Arial';

  const bodyFont =
    settings.getPropertySettingValue(
      PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
      'bodyFont'
    ) || 'Arial';

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    async function getFonts() {
      let importedGoogleFonts;
      try {
        const response = await getGoogleFonts();
        importedGoogleFonts = response.map(option => ({
          value: option.family,
          label: option.family,
          type: 'google',
        }));
      } catch (error) {
        const errorMessage = `Unable to retrieve all Google Fonts, please try again later...`;
        setFontsErrorMessage(errorMessage);
      }

      try {
        if (importedGoogleFonts) {
          setIsLoadingFonts(true);
          importedGoogleFonts.forEach(googleFont => {
            loadFont(googleFont.value);
          });
          setGoogleFonts(importedGoogleFonts);
        } else {
          setGoogleFonts(['']);
        }
      } catch (error) {
        const errorMessage = `Unable to retrieve all Google Fonts, please try again later...`;
        setFontsErrorMessage(errorMessage);
      }
      setIsLoadingFonts(false);
    }
    getFonts();
  }, []);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns:Branding:General:Fonts:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        // TODO - refetch preview
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleTitleFontChange = event => {
    setFontsErrorMessage('');
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
      settingKey: 'titleFont',
      settingValue: event?.value ?? '',
    });
    if (event?.value && event?.type === 'google') {
      loadFont(event.value);
    }
  };

  const handleBodyFontChange = event => {
    setFontsErrorMessage('');
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
      settingKey: 'bodyFont',
      settingValue: event?.value ?? '',
    });
    if (event?.value && event?.type === 'google') {
      loadFont(event.value);
    }
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Container>
      <Title className="mb-3">Fonts</Title>
      {fontsErrorMessage && (
        <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
          {fontsErrorMessage}
        </div>
      )}
      <Description className="mb-3">
        Select a font for the text in your subscription page
      </Description>
      <div className="settings-subtitle">Title</div>
      <div className="font-dropdown">
        <GoogleFontsDropdown
          googleFonts={googleFonts}
          chosenFontFamily={titleFont}
          onChange={handleTitleFontChange}
          value={titleFont ? { value: titleFont, label: titleFont } : 'Arial'}
          loadingFonts={loadingFonts}
        />
      </div>
      <div className="settings-subtitle">Body</div>
      <div className="font-dropdown">
        <GoogleFontsDropdown
          googleFonts={googleFonts}
          chosenFontFamily={bodyFont}
          onChange={handleBodyFontChange}
          value={bodyFont ? { value: bodyFont, label: bodyFont } : 'Arial'}
          loadingFonts={loadingFonts}
        />
      </div>
    </Container>
  );
}

export default Fonts;
