/* eslint no-param-reassign: "off" */

import * as API from 'api/api';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import { mandatory } from 'common/validation';

import { EDITION_FIELDS } from 'common/constants';
import { EDITION_STATE } from 'common/enums';

const now = datetime.getUnixTimestamp();

export default async function getEmailDetails({
  campaignURN = mandatory('campaignURN'),
  startUnixTime = now - datetime.DAYS(7),
  endUnixTime = now + datetime.DAYS(14),
  fetchSentOnly = false,
} = {}) {
  let editionList = [];
  /* Fetch editions */
  const editionURNs = await API.getEditionList({
    campaignURN,
    startUnixTime,
    endUnixTime,
  });
  if (editionURNs.length > 0) {
    /* Fetch edition details */
    const editionData = await API.getEditions({
      editionURNs,
      fieldList: EDITION_FIELDS.LIST,
    });
    /* Fetch performance insights asynchronously */
    const sentEditions = editionData.filter(
      edition => edition.editionState === EDITION_STATE.SENT
    );
    if (sentEditions.length > 0) {
      /* Merge performance data into edition data */
      const editionPerformancePromise = API.getEditionPerformance({
        editionURNs: sentEditions.map(edition => edition.editionURN),
        startUnixTime,
        endUnixTime,
      });
      sentEditions.forEach(edition => {
        edition.performanceStatistics = (async () => {
          try {
            const editionPerformance = await editionPerformancePromise;
            return editionPerformance.find(
              performanceEntry =>
                performanceEntry.editionURN === edition.editionURN
            );
          } catch {
            // Oh well, never mind
            return undefined;
          }
        })();
      });
    }
    editionList = editionData.filter(
      edition =>
        !fetchSentOnly ||
        edition.editionState === EDITION_STATE.SENT ||
        edition.editionState === EDITION_STATE.FAILED
    );
  }
  /* Sort and return the list of editions */
  return editions.sortEditions(editionList);
}
