import { Box, NavLeft } from '@ebx-ui/ebx-ui-component-library-sdk';

import Logo from 'components/header/Logo';
import ProductTabs from 'components/header/ProductTabs';

function HeaderLeft() {
  return (
    <NavLeft pl={{ base: 4, md: 8 }}>
      <Box mr={8}>
        <Logo />
      </Box>
      <ProductTabs />
    </NavLeft>
  );
}

export default HeaderLeft;
