import * as API from 'api/api';

// import { determineError, getErrorStatus } from 'common/errorHandling';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import { isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import postSignIn from 'services/postSignIn';

export default async function verifyMFA({
  user = mandatory('user'),
  code = mandatory('code'),
  mfaType = mandatory('mfaType'),
} = {}) {
  try {
    const signInResponse = await API.authConfirmSignIn({
      user,
      code,
      mfaType,
    });
    postSignIn(signInResponse);
  } catch (error) {
    const handledError = errors.determineError(error);
    if (isNull(errors.getErrorStatus)) {
      logger.error({
        event: 'MFA Error',
        properties: {
          location: 'service/verifymfa',
          arguments: {
            user,
            code,
            mfaType,
          },
        },
        handledError,
      });
    }
    throw handledError;
  }
}
