/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import Loading from 'components/settings/Loading';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Content, Sidebar } from '../../common';
import ButtonText from './ButtonText';
import EditionLinks from './EditionLinks';
import FooterText from './FooterText';

import './EditionBranding.css';
import EditionText from './EditionText';

export default function EditionBranding() {
  const { urn } = useParams();
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);

  const [settingsPanel, setSettingsPanel] = useState(
    BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.OPTIONS
  );

  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(urn);
    settings.getPropertySettings(propertyURN);
  }, [urn, propertyURN]);

  useEffect(() => {
    // pub-sub
    const handleSave = async () => {
      logger.info('EditionBranding:handleSave');

      try {
        const socialPageLinks = settings.getCampaignSettingValue(
          CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS,
          'socialPageLinks'
        );

        if (socialPageLinks) {
          for (const social of socialPageLinks) {
            if (social.isEnabled === true && !social.linkPath) {
              throw new Error(
                `Social link for ${social.socialNetworkType.toLowerCase()} cannot be blank!`
              );
            }
          }
        }

        await settings.saveCampaignSettings({
          campaignURN: urn,
        });

        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const message = errors.getErrorMessage(error);
        settings.setError(message);
        toast({ variant: 'error', title: message });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, urn]);

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Row className="settings-branding w-100 m-0">
      <Col xs={12} md={4} xl={3}>
        <Sidebar>
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.OPTIONS && (
            <EditionLinks urn={urn} setSettingsPanel={setSettingsPanel} />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.TEXT_OPTIONS && (
            <EditionText
              setSettingsPanel={setSettingsPanel}
              settings={settings}
            />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.BUTTON && (
            <ButtonText
              setSettingsPanel={setSettingsPanel}
              settings={settings}
            />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.FOOTER && (
            <FooterText
              setSettingsPanel={setSettingsPanel}
              settings={settings}
              isEditionSettings={true}
            />
          )}
        </Sidebar>
      </Col>
      <Col xs={12} md={8} xl={9}>
        <Content>
          <PreviewWrapper
            campaignURN={urn}
            previewTypes={[PREVIEW_TYPES.EDITION_TEMPLATE]}
            settings={settings}
          />
        </Content>
      </Col>
    </Row>
  );
}
