/* eslint react/no-danger: "off" */

import { Dispatch, SetStateAction, useState } from 'react';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';

import Modal from 'components/common/Modal';
import URLInput from 'components/common/URLInput';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import { PROMOTION_BLOCK_TYPES } from 'common/enums';
import * as image from 'common/image';
import * as sanitise from 'common/sanitise';
import * as tracker from 'common/tracker';
import * as url from 'common/url';

interface PromotionBlockProps {
  imageURL: string;
  destinationURL: string;
  promotionBlockType?: PROMOTION_BLOCK_TYPES;
  onHide: () => void;
  onSave: (updatedImageURL: string, updatedDestinationURL: string) => void;
  show: boolean;
  origin: string;
  setSaving: Dispatch<SetStateAction<boolean>>;
  isSavingPromotionBlock: boolean;
}

function PromotionModal({
  imageURL,
  destinationURL,
  promotionBlockType,
  onHide,
  onSave,
  show,
  origin,
  setSaving,
  isSavingPromotionBlock,
}: PromotionBlockProps) {
  const [tempImageURL, setTempImageURL] = useState(imageURL);
  const [hasEnteredImageLink, setHasEnteredImageLink] = useState(false);
  const [tempDestinationURL, setTempDestinationURL] = useState(destinationURL);
  const [errorMessage, setErrorMessage] = useState('');

  const title =
    promotionBlockType === PROMOTION_BLOCK_TYPES.IMAGE ? 'Image' : 'Advert';

  const handleImageLinkChange = (newValue: string) => {
    setHasEnteredImageLink(true);
    setTempImageURL(newValue);
  };

  const handleSave = async () => {
    // Only resolve the image if it isn't empty
    if (tempImageURL.length > 0) {
      setSaving(true);
      const imageResolved = await image.doesImageResolve(tempImageURL);

      if (!imageResolved) {
        setErrorMessage('Unable to get image data');
        setSaving(false);
        return;
      }
    }

    setErrorMessage('');

    tracker.track({
      eventName: 'Update Promotion Block',
      trackingParams: {
        Origin: origin,
        'Image URL': imageURL,
        'Image URL (before)': tempImageURL,
        'Forward URL': destinationURL,
        'Forward URL (before)': tempDestinationURL,
      },
    });
    onSave(tempImageURL, tempDestinationURL);
  };

  const helperText =
    origin === 'Settings'
      ? 'Any Image or Link URLs will be carried over to future Editions, unless replaced or removed.<br /><br />JPEG or PNG format preferred. SVG images and animated GIFs may not display on all email clients.'
      : ' JPEG or PNG format preferred. SVG images and animated GIFs may not display on all email clients.';

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className="">
        <Modal.Title className="">{title}</Modal.Title>
        <span className="ml-auto">
          <Button variant="link" className="p-0" onClick={onHide}>
            <CloseIcon />
          </Button>
        </span>
      </Modal.Header>

      <Modal.Body className="ebx-body-colour py-4" mode="DEFAULT">
        <span
          className="stay-within-parent"
          dangerouslySetInnerHTML={{
            __html: sanitise.safeHTML(helperText),
          }}
        />
      </Modal.Body>
      <Modal.Body className="ebx-hr pb-0" mode="WHITE">
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">Image Link</FormLabel>
          <URLInput
            invalidURL={!url.isValidURL(tempImageURL)}
            value={tempImageURL}
            onChange={handleImageLinkChange}
          />
          {errorMessage !== '' && (
            <div className="error--text mt-2 ebx-small-text">
              {errorMessage}
            </div>
          )}
          {hasEnteredImageLink && tempImageURL.length === 0 && (
            <div className="error--text mt-2 ebx-small-text">
              Leaving this field blank will hide the Promotion Block from this
              Edition
            </div>
          )}
        </FormGroup>
      </Modal.Body>
      <Modal.Body className="ebx-hr pb-0" mode="WHITE">
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">
            Link Destination{' '}
            <span className="ebx-hr-text-colour">(optional)</span>
          </FormLabel>
          <URLInput
            invalidURL={!url.isValidURL(tempDestinationURL)}
            value={tempDestinationURL ?? ''}
            onChange={setTempDestinationURL}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          className="ebx-btn-primary ebx-btn-md ebx-h3"
          onClick={handleSave}
          disabled={isSavingPromotionBlock}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// Defaults to Image as promotion blocks don't have a type at edition level.
PromotionModal.defaultProps = {
  promotionBlockType: PROMOTION_BLOCK_TYPES.IMAGE,
};

export default PromotionModal;
