import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { SuggestionsList } from 'components/editor/curate/InspireMe';
import Subheader from 'components/editor/curate/Subheader';
import Error from 'components/editor/Error';
import Preview from 'components/editor/preview/Preview';
import PreviewError from 'components/editor/preview/PreviewError';
import SenderDetails from 'components/editor/preview/SenderDetails';

import {
  Alert,
  Button,
  Dropdown,
  Flex,
  List,
  ListItem,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { EDITION_STATE, PREVIEW_TYPES } from 'common/enums';
import * as string from 'common/string';
import { Campaign, Edition, SectionArticle } from 'common/types';

import StatusPageNotifications from 'components/StatusPageNotifications';
import './CurateWrapper.css';

interface CurateWrapperProps {
  campaignDetails: Campaign;
  handleApprove: (isDraft: boolean) => void;
  isApproving: boolean;
  editionDetails?: Edition | null;
  updateEditionDetails: () => void;
  children: React.ReactNode;
  errorMessage: string;
  errorShow: boolean;
  handleErrorClose: () => void;
  isLoadingPreview: boolean;
  editionPreview?: string | null;
  isEditionEditable: boolean;
  isDomainVerificationComplete: boolean;
  displayDuplicateArticlesAlert: boolean;
  duplicateArticles?: { [mediaURN: string]: SectionArticle[] };
  handleCloseDuplicateArticlesAlert: () => void;
  displayArticleChangedAlert: boolean;
  handleCloseArticleChangedAlert: () => void;
  emailSubjectInputRef: React.MutableRefObject<HTMLInputElement | null>;
  aiSubjectLines: string[];
  setAISubjectLine: (emailSubject: string) => void;
  handleOpenAISubjectLines: () => void;
  showLeftPanel: boolean;
  setTempEmailSubject: (subjectLine: string) => void;
}

function CurateWrapper({
  campaignDetails,
  handleApprove,
  isApproving,
  editionDetails = null,
  updateEditionDetails,
  children,
  errorMessage,
  errorShow,
  handleErrorClose,
  isLoadingPreview,
  editionPreview = null,
  isEditionEditable,
  isDomainVerificationComplete,
  displayDuplicateArticlesAlert,
  duplicateArticles,
  handleCloseDuplicateArticlesAlert,
  displayArticleChangedAlert,
  handleCloseArticleChangedAlert,
  emailSubjectInputRef,
  aiSubjectLines,
  setAISubjectLine,
  handleOpenAISubjectLines,
  showLeftPanel,
  setTempEmailSubject,
}: CurateWrapperProps) {
  const handleUpdateSubject = (isOpen: boolean, event: React.MouseEvent) => {
    if (!isOpen) {
      if (aiSubjectLines.length === 0) {
        emailSubjectInputRef.current?.focus();
        event.preventDefault();
      } else {
        handleOpenAISubjectLines();
      }
    }
  };

  const handleUpdateAISubjectLine = (aiSubjectLine: string) => {
    setTempEmailSubject(aiSubjectLine);
    setAISubjectLine(aiSubjectLine);
    handleCloseArticleChangedAlert();
  };

  return (
    <div className="f-container fixed-hf d-flex main-container">
      <div>
        <Subheader
          approvalState={editionDetails?.approvalState}
          campaignDetails={campaignDetails}
          editionDetails={editionDetails}
          updateEditionDetails={updateEditionDetails}
          handleApprove={handleApprove}
          isApproving={isApproving}
          isEditionEditable={isEditionEditable}
          isDomainVerificationComplete={isDomainVerificationComplete}
        />
        {errorShow ? (
          <div className="curation__sections">
            <Error
              errorMessage={errorMessage}
              handleErrorClose={handleErrorClose}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex main flex-wrap flex-lg-nowrap curation__content bg-white">
        {showLeftPanel && (
          <Flex
            flexDir="column"
            gap={6}
            py={8}
            px={10}
            w={{ base: '100%', lg: '44%' }}
            overflowX="hidden"
            overflowY="auto"
            wordBreak="break-word"
            borderRight="1px"
            borderColor="gray.300"
          >
            <StatusPageNotifications />
            {children}
          </Flex>
        )}
        <Flex
          flexDirection="column"
          w={{ base: '100%', lg: showLeftPanel ? '56%' : '100%' }}
          py={8}
          px={10}
          overflowX="hidden"
          borderTop={{ base: '1px', lg: 'none' }}
          borderColor="gray.300"
        >
          {displayDuplicateArticlesAlert && duplicateArticles && (
            <Alert
              variant="warning"
              flexShrink={0}
              mb={4}
              onClose={handleCloseDuplicateArticlesAlert}
            >
              <Alert.AlertTitle>Duplicate articles detected</Alert.AlertTitle>
              <Alert.AlertDescription>
                The following{' '}
                {string.pluralise(
                  'article',
                  Object.keys(duplicateArticles).length
                )}{' '}
                {string.pluralise(
                  'appear',
                  Object.keys(duplicateArticles).length > 1 ? 1 : 2
                )}{' '}
                multiple times in this Edition:
                <List styleType="disc">
                  {Object.values(duplicateArticles).map(article => (
                    <ListItem>{article[0].title}</ListItem>
                  ))}
                </List>
                Are you sure you want to send out your Edition?
              </Alert.AlertDescription>
            </Alert>
          )}
          {displayArticleChangedAlert && (
            <Alert
              variant="warning"
              flexShrink={0}
              mb={4}
              onClose={handleCloseArticleChangedAlert}
              buttons={
                <Dropdown>
                  {({ isOpen }) => (
                    <>
                      <Dropdown.Button
                        as={Button}
                        variant="link"
                        onClick={event => handleUpdateSubject(isOpen, event)}
                      >
                        Update subject
                      </Dropdown.Button>
                      <SuggestionsList
                        options={aiSubjectLines}
                        setAISubjectLine={handleUpdateAISubjectLine}
                      />
                    </>
                  )}
                </Dropdown>
              }
            >
              <Alert.AlertTitle>
                Subject Line has not been updated
              </Alert.AlertTitle>
              <Alert.AlertDescription>
                An article has been changed but the subject line remains the
                same.
              </Alert.AlertDescription>
            </Alert>
          )}
          <ErrorBoundary FallbackComponent={PreviewError}>
            <Preview
              isLoadingPreview={isLoadingPreview}
              isSent={editionDetails?.editionState === EDITION_STATE.SENT}
              previewSource={editionPreview}
              previewTitle="Send preview"
              previewType={PREVIEW_TYPES.EDITION}
              campaignName={campaignDetails?.campaignName}
              scheduledUnixTime={editionDetails?.scheduledUnixTime}
              editionURN={editionDetails?.editionURN}
              approvalState={editionDetails?.approvalState}
              isToolbarDisabled={isApproving}
            >
              <SenderDetails />
            </Preview>
          </ErrorBoundary>
        </Flex>
      </div>
      {/* </section> */}
    </div>
  );
}

export default CurateWrapper;
