import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { Insight } from 'common/types';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getCampaignInsightsProps {
  campaignURNs: string;
  startUnixTime?: number;
  endUnixTime?: number;
}

export default async function getCampaignInsights({
  campaignURNs,
  startUnixTime,
  endUnixTime,
}: getCampaignInsightsProps) {
  const guid = metrics.start('getCampaignInsights');

  const timeframeQueryParams =
    startUnixTime && endUnixTime
      ? `?startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}`
      : '';

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/campaigns/${campaignURNs}/insights${timeframeQueryParams}`;

  const config = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getCampaignInsights /campaigns/${campaignURNs}/insights`);

  try {
    const response = await axios(config);
    metrics.end('getCampaignInsights', guid);
    const insightMap = new Map();
    response.data.campaignInsights.forEach((insight: Insight) => {
      insightMap.set(insight.campaignURN, insight);
    });
    return insightMap;
  } catch (error) {
    metrics.fail('getCampaignInsights', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getCampaignInsights',
            args: { campaignURNs },
          })
        : error;
    throw apiError;
  }
}
