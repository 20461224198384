/* eslint no-use-before-define: "off" */

import * as utility from 'common/utility';

export const getBlock = (blockURN, promotionBlocks) => {
  let returnBlock = null;

  if (promotionBlocks) {
    promotionBlocks.forEach(block => {
      if (block.promotionBlockURN === blockURN) {
        returnBlock = block;
      }
    });
  }

  return returnBlock;
};

export const updateBlocks = (promotionBlocks, blockDetails) => {
  const updatedBlocks = utility.cloneObject(promotionBlocks);

  updatedBlocks.forEach((block, blockIndex) => {
    if (block.promotionBlockURN === blockDetails.promotionBlockURN) {
      updatedBlocks[blockIndex] = blockDetails;
    }
  });

  return updatedBlocks;
};
