/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import ErrorDisplay from 'components/editor/Error';
import Preview from 'components/editor/preview/Preview';
import PreviewSelector from 'components/editor/preview/PreviewSelector';
import SenderDetails from 'components/editor/preview/SenderDetails';

import * as API from 'api/api';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import fetchPreview from 'common/preview';

import { EXAMPLE_USER, PREVIEW_TYPE_PROPERTIES } from 'common/constants';

import 'components/editor/preview/PreviewWrapper.css';

export default function PreviewWrapper({
  propertyURN,
  campaignURN,
  previewTypes,
  settings,
  forceReload,
  scale,
  maxHeight,
}) {
  const [allPreviews, setAllPreviews] = useState({});
  const [allPreviewLoadings, setAllPreviewLoadings] = useState({});
  const [previewType, setPreviewType] = useState(previewTypes[0]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorShow, setErrorShow] = useState(errorMessage !== '');

  useEffect(() => {
    if (settings.isSaving) {
      return;
    }
    const handleSettingsLoad = async () => {
      try {
        // Check if any of the previews are for a PAGE
        const hasPagePreview = previewTypes.some(
          currentPreviewType =>
            PREVIEW_TYPE_PROPERTIES[currentPreviewType].ASSET_TYPE === 'PAGE'
        );

        let branding = {};
        if (hasPagePreview) {
          if (propertyURN) {
            branding = await API.getPropertyBranding({ propertyURN });
          } else if (campaignURN) {
            branding = await API.getCampaignBranding({ campaignURN });
          }
        }
        previewTypes.forEach(currentPreviewType => {
          let currentBranding = branding;
          if (PREVIEW_TYPE_PROPERTIES[currentPreviewType].POST_NAME) {
            // Add page and emailAddress to branding as cannot be obtained from URL
            // as iframe generated by HTML
            // Clear redirectURL to prevent redirects on previews
            currentBranding = {
              ...branding,
              page: PREVIEW_TYPE_PROPERTIES[currentPreviewType].POST_NAME,
              emailAddress: EXAMPLE_USER,
              redirectURL: '',
            };
          }
          fetchPreview({
            setPreview: previewHtml => {
              setAllPreviews(prevState => ({
                ...prevState,
                [currentPreviewType]: previewHtml,
              }));
            },
            setLoadingPreview: previewLoading => {
              setAllPreviewLoadings(prevState => ({
                ...prevState,
                [currentPreviewType]: previewLoading,
              }));
            },
            setErrorMessage,
            setErrorShow,
            logger,
            previewDetails: {
              propertyURN,
              campaignURN,
              branding: currentBranding,
            },
            previewType: currentPreviewType,
          });
        });
      } catch (error) {
        const currentError = errors.getErrorMessage(error);
        setErrorMessage(currentError);
      }
    };
    handleSettingsLoad();
  }, [settings.isSaving, campaignURN, propertyURN, forceReload]);

  const handleErrorClose = () => setErrorShow(false);

  const topLeft = () => {
    if (previewTypes && previewTypes.length > 1) {
      return (
        <PreviewSelector
          previewTypes={previewTypes}
          currentPreviewType={previewType}
          onClick={p => setPreviewType(p)}
        />
      );
    }
    return <SenderDetails />;
  };

  // Check if there are previews still loading
  const isLoading =
    Object.keys(allPreviewLoadings).length < previewTypes.length ||
    Object.keys(allPreviewLoadings).some(
      previewLoading => allPreviewLoadings[previewLoading]
    );

  return (
    <Preview
      isLoadingPreview={isLoading}
      previewSource={allPreviews[previewType]}
      previewTitle={previewType}
      previewType={previewType}
      scale={scale}
      maxHeight={maxHeight}
    >
      {errorShow ? (
        <div className="w-100 mb-3">
          <div className="preview__error">
            <ErrorDisplay
              errorMessage={errorMessage}
              handleErrorClose={handleErrorClose}
            />
          </div>
        </div>
      ) : (
        topLeft()
      )}
    </Preview>
  );
}

PreviewWrapper.propTypes = {
  propertyURN: PropTypes.string,
  campaignURN: PropTypes.string,
  previewTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  settings: PropTypes.node.isRequired,
  forceReload: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  scale: PropTypes.number,
  maxHeight: PropTypes.string,
};

PreviewWrapper.defaultProps = {
  propertyURN: null,
  campaignURN: null,
  forceReload: null,
  scale: null,
  maxHeight: null,
};
