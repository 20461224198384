import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import styled from 'styled-components';

import ValidationError from 'components/ui/ValidationError';

const inputStyle = {
  fontFamily: 'monospace',
  borderRadius: 6,
  border: '1px solid lightgrey',
  margin: 4,
  paddingLeft: 10,
  width: 36,
  height: 42,
  fontSize: 24,
  boxSizing: 'border-box',
  color: 'black',
  backgroundColor: 'white',
};

const inputStyleInvalid = {
  fontFamily: 'monospace',
  borderRadius: 6,
  border: '1px solid rgb(238, 211, 215)',
  boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
  margin: 4,
  paddingLeft: 10,
  width: 36,
  height: 42,
  fontSize: 24,
  boxSizing: 'border-box',
  color: 'rgb(185, 74, 72)',
  backgroundColor: 'rgb(242, 222, 222)',
};

const StyledValidationError = styled(ValidationError)`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
`;

const StyledReactCodeInput = styled(ReactCodeInput)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

/**
 * React Component to add some default styling to <ReactCodeInput/>
 * Adds default styles for normal input and invalid input states
 * Accepts the following prop (optional):
 * 1) error - an error message. When this is passed, the error message is shown below the input
 * All other props are passed to the ReactCodeInput - https://github.com/40818419/react-code-input
 */
function CodeInput({ error, ...rest }) {
  return (
    <>
      <StyledReactCodeInput
        autoFocus={true}
        inputStyle={inputStyle}
        inputStyleInvalid={inputStyleInvalid}
        {...rest}
      />
      {error && <StyledValidationError errorMessage={error} />}
    </>
  );
}

CodeInput.defaultProps = {
  error: '',
};

CodeInput.propTypes = {
  error: PropTypes.string,
};

export default CodeInput;
