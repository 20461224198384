import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface HeaderLinkProps {
  to: string;
  children: React.ReactNode;
}

function HeaderLink({ to, children }: HeaderLinkProps) {
  const location = useLocation();

  return (
    <div
      className={`px-2 py-auto mx-2 h-100 d-flex align-items-center ${
        location.pathname === to ? 'settings-header-link-active' : ''
      }`}
    >
      <Link
        to={to}
        className={`settings-header-link ${
          location.pathname === to ? 'ebx-blue' : ''
        }`}
      >
        <span className="ebx-h1">{children}</span>
      </Link>
    </div>
  );
}

export default HeaderLink;
