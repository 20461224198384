/* eslint import/prefer-default-export: "off" */

import { GLOBAL_INFO_STATES } from 'common/constants';

const initialState = {
  globalInfo: {
    state: GLOBAL_INFO_STATES.INITIALISED,
  },
  statusPageNotifications: {},
};

if (window.globalInfo) {
  initialState.globalInfo = window.globalInfo;
} else {
  const sessionInfo = sessionStorage.getItem('globalInfo');
  if (sessionInfo) {
    initialState.globalInfo = JSON.parse(sessionInfo);
  }
}

export { initialState };
