import {
  Flex,
  FormatAlignCenterIcon,
  FormatAlignJustifyIcon,
  FormatAlignLeftIcon,
  FormatAlignRightIcon,
  IconButton,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Wrapper from 'components/editor/blocks/Wrapper';

interface AlignPickerProps {
  header?: string;
  label?: string;
  name: string;
  onChange: (key: string, value: any) => void;
  options?: string[];
  value: string;
}

function AlignPicker({
  header,
  label,
  name,
  onChange,
  options,
  value,
}: AlignPickerProps) {
  const handleClick = (option: string) => {
    onChange(name, option);
  };

  let allow;
  if (!options) {
    allow = {
      center: true,
      justify: true,
      left: true,
      right: true,
    };
  } else {
    allow = {
      center: options.indexOf('center') !== -1,
      justify: options.indexOf('justify') !== -1,
      left: options.indexOf('left') !== -1,
      right: options.indexOf('right') !== -1,
    };
  }

  return (
    <Wrapper header={header} label={label}>
      <Flex align="center" gap={2}>
        {allow.left && (
          <IconButton
            aria-label="left"
            icon={<FormatAlignLeftIcon />}
            isActive={value === 'left'}
            onClick={() => handleClick('left')}
            size="lg"
          />
        )}
        {allow.center && (
          <IconButton
            aria-label="center"
            icon={<FormatAlignCenterIcon />}
            isActive={value === 'center'}
            onClick={() => handleClick('center')}
            size="lg"
          />
        )}
        {allow.right && (
          <IconButton
            aria-label="right"
            icon={<FormatAlignRightIcon />}
            isActive={value === 'right'}
            onClick={() => handleClick('right')}
            size="lg"
          />
        )}
        {allow.justify && (
          <IconButton
            aria-label="justify"
            icon={<FormatAlignJustifyIcon />}
            isActive={value === 'justify'}
            onClick={() => handleClick('justify')}
            size="lg"
          />
        )}
      </Flex>
    </Wrapper>
  );
}

export default AlignPicker;
