import { useLocation } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

import HeaderLink from 'components/settings/header/HeaderLink';

const getProfileSublevelName = (sublevel: string) => {
  switch (sublevel) {
    case 'personal-details':
      return 'Personal details';
    case 'password':
      return 'Password';
    default:
      return sublevel;
  }
};

function ProfileLinks() {
  const location = useLocation();
  const profileSettingsPath = '/settings/profile';

  /* Render nothing if we are not showing a property settings page or sub-page */
  if (!location.pathname.startsWith(profileSettingsPath)) {
    return null;
  }

  const profileSubpath = location.pathname
    .replace(profileSettingsPath, '')
    .substring(1);

  const subpathName = getProfileSublevelName(profileSubpath);

  return (
    <>
      <ChevronRight />
      <HeaderLink to={profileSettingsPath}>
        <span className="ebx-h1">Profile</span>
      </HeaderLink>
      {profileSubpath && (
        <>
          <ChevronRight />
          <HeaderLink to={`${profileSettingsPath}/${profileSubpath}`}>
            <span className="ebx-h1">{subpathName}</span>
          </HeaderLink>
        </>
      )}
    </>
  );
}

export default ProfileLinks;
