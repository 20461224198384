/* eslint import/prefer-default-export: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */

import { chunkArray } from './array';
import { cloneArray } from './utility';

export { batchRequests };

/**
 * Convenience function for doing a number of requests in batches
 *
 * @param {array} items - The item identifiers to batch
 * @param {integer} batchSize - The size of each request batch
 * @param {function} makeRequest - Function to do a request for a batch
 */
function batchRequests<Item, ResponseItem>(
  items: Item[],
  batchSize: number,
  makeRequest: (batch: Item[]) => Promise<ResponseItem[]>
): Promise<ResponseItem[]> {
  if (items.length <= batchSize) {
    return makeRequest(items);
  }

  const batches = chunkArray(cloneArray(items) as Item[], batchSize);

  const requests = batches.map(batch => makeRequest(batch));

  return Promise.all(requests).then(responses => responses.flat());
}
