/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { Box, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';

import EmailEditor from 'react-email-editor';

import EmailSubject from 'components/editor/wysiwyg/EmailSubject';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as datetime from 'common/datetime';
import * as logger from 'common/logger';
import * as marketing from 'common/marketing';
import useDebounce from 'hooks/useDebounce';
import useGlobalInfo from 'hooks/useGlobalInfo';

import { UNLAYER_EMBED_PROJECT_ID } from 'common/config';
import {
  CAMPAIGN_SETTING_TYPES,
  DEFAULT_DEBOUNCE_DELAY,
} from 'common/constants';

function EmailContent({
  editionURN,
  emailSubject,
  handleUpdateSubject,
  handleUpdateTemplate,
  isBusy,
  isEditable,
  isSent,
  templateDetails,
}) {
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [isReady, setReady] = useState(false);

  const globalInfo = useGlobalInfo();

  const debouncedUpdate = useDebounce(lastUpdate, DEFAULT_DEBOUNCE_DELAY);

  const emailEditorRef = useRef(null);

  useEffect(() => {
    if (debouncedUpdate !== null && !isBusy) {
      logger.info(
        `EmailContent:useEffect - saving debounced email content ${debouncedUpdate}`
      );
      if (emailEditorRef?.current?.editor) {
        emailEditorRef.current.editor.exportHtml(data => {
          const content = marketing.composeTemplate(data.design, data.html);
          handleUpdateTemplate({
            ...templateDetails,
            content,
          });
        });
      }
    }
  }, [debouncedUpdate]);

  const handleExport = () => {
    emailEditorRef.current.editor.exportHtml(data => {
      const exportName = 'preview.html';
      const exportLink = document.createElement('a');
      const exportURL = `data:text/html;charset=utf-8, ${encodeURIComponent(
        data.html
      )}`;
      exportLink.href = exportURL;
      exportLink.download = exportName;
      document.body.appendChild(exportLink);
      exportLink.click();
      document.body.removeChild(exportLink);
      window.URL.revokeObjectURL(exportURL);
    });
  };

  const onDesignLoaded = () => {
    logger.info('WYSIWYG.EmailContent:onDesignLoaded');
  };

  const onDesignUpdated = () => {
    logger.info('WYSIWYG.EmailContent:onDesignUpdated');
    if (emailEditorRef?.current?.editor) {
      setLastUpdate(datetime.getUnixTimestamp());
    }
  };

  const onReady = () => {
    if (!isReady) {
      logger.info('WYSIWYG.EmailContent:onReady');

      try {
        const templateJSON = JSON.parse(templateDetails.design);
        emailEditorRef.current.editor.loadDesign(templateJSON);
      } catch (error) {
        logger.info(
          'WYSIWYG.EmailContent:onReady - error loading template JSON'
        );
        console.log(templateDetails.design);
      }

      emailEditorRef.current.editor.addEventListener(
        'design:loaded',
        onDesignLoaded
      );
      emailEditorRef.current.editor.addEventListener(
        'design:updated',
        onDesignUpdated
      );

      const personalisationSettings = campaigns.getCurrentCampaignSettingsType(
        globalInfo,
        CAMPAIGN_SETTING_TYPES.SUBSCRIBER_PERSONALISATION
      );
      const mergeTags = {};
      if (personalisationSettings?.mergeTags) {
        Object.keys(personalisationSettings.mergeTags).forEach(tagName => {
          mergeTags[tagName] = {
            name: personalisationSettings.mergeTags[tagName].displayName,
            // eslint-disable-next-line no-useless-concat
            value: '${' + `${tagName}` + '}',
          };
        });
      }
      emailEditorRef.current.editor.setMergeTags(mergeTags);
      setReady(true);
    }
  };

  const handleSaveTemplate = async templateName => {
    setSaving(true);
    logger.info('WYSIWYG.EmailContent:handleSaveTemplate');
    if (emailEditorRef?.current?.editor) {
      await emailEditorRef.current.editor.exportHtml(async data => {
        const content = marketing.composeTemplate(data.design, data.html);
        await API.postMarketingTemplates({
          campaignURN: templateDetails.campaignURN,
          content,
          name: templateName,
        });
      });
    }
    setSaving(false);
  };

  const appearanceConfig = {
    panels: {
      tools: {
        dock: 'left',
      },
    },
  };

  return (
    <Flex
      flexDirection="column"
      height="calc(100% - 10px)"
      width="calc(100% - 10px)"
    >
      <Box py={6} px={10}>
        <EmailSubject
          editionURN={editionURN}
          emailSubject={emailSubject}
          handleExport={handleExport}
          handleUpdateSubject={handleUpdateSubject}
          handleUpdateTemplate={handleUpdateTemplate}
          handleSaveTemplate={handleSaveTemplate}
          isBusy={isBusy || isSaving}
          isEditable={isEditable}
          isSent={isSent}
          showInline={true}
          templateDetails={templateDetails}
        />
      </Box>
      <EmailEditor
        ref={emailEditorRef}
        onReady={onReady}
        projectId={UNLAYER_EMBED_PROJECT_ID}
        appearance={appearanceConfig}
      />
    </Flex>
  );
}

EmailContent.propTypes = {
  editionURN: PropTypes.string.isRequired,
  emailSubject: PropTypes.string.isRequired,
  handleUpdateSubject: PropTypes.func.isRequired,
  handleUpdateTemplate: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  templateDetails: PropTypes.shape({
    templateURN: PropTypes.string.isRequired,
    campaignURN: PropTypes.string.isRequired,
    design: PropTypes.string.isRequired,
  }).isRequired,
  isSent: PropTypes.bool.isRequired,
};

export default EmailContent;
