/* eslint no-underscore-dangle: ["error", { "allow": ["__sentry_xhr__"] }] */
/* eslint no-use-before-define: "off" */
import * as Sentry from '@sentry/react';

import { Hub } from 'aws-amplify';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import * as globalinfo from 'common/globalinfo';
import * as logger from 'common/logger';
import { stringifyKeys, stringifyNonCircular } from 'common/object';
import { errorGuid } from 'common/string';
import {
  cloneError,
  cloneObject,
  isDefined,
  isError,
  isErrorLike,
  isNull,
  isNullOrUndefined,
  isRunningTests,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';

import {
  API_ERROR_MESSAGES,
  API_ERROR_TYPES,
  // FLASH_MESSAGE_TYPES,
  HUB_CHANNELS,
  HUB_EVENTS,
  NON_API_ERROR_MESSAGES,
  NON_API_ERROR_TYPES,
} from 'common/constants';

import { SENTRY_IGNORED_ERRORS } from 'common/config';

export {
  determineError,
  getErrorMessage,
  getErrorStatus,
  handleAPIError,
  handleCognitoError,
  handleExternalAPIError,
  isCognitoError,
  isIgnoredError,
  stringifyError,
  stringifyReport,
};

function determineError(error) {
  // Return Cognito errors without further processing
  if (isCognitoError(error)) {
    return error;
  }
  // Return API errors without further processing
  if (isNetworkError(error)) {
    return error;
  }
  // For reasons currently beyond understanding, trying to use the async
  // version of the JavaScript error handler (which makes a call to getVersion)
  // makes *one* interaction test fail (in ShareNewTest) so just to avoid that
  // when running in test mode we use the original synchronous version
  // of the method
  const jsErrorHandler = isRunningTests() ? handleJSError : handleJSErrorAsync;
  return isNull(getErrorStatus(error)) ? jsErrorHandler(error) : error;
}

function getErrorMessage(error) {
  if (isNull(error)) {
    return null;
  }
  if (typeof error === 'string' || error instanceof String) {
    return error;
  }
  if (error instanceof Error) {
    if (
      isDefined(error.response) &&
      isDefined(error.response.data) &&
      isDefined(error.response.data.error) &&
      isDefined(error.response.data.error.message)
    ) {
      return error.response.data.error.message;
    }
    return error.message;
  }
  if (
    isDefined(error.error) &&
    (typeof error.error === 'string' || error.error instanceof String)
  ) {
    return error.error;
  }
  if (isDefined(error.error) && isDefined(error.error.message)) {
    return error.error.message;
  }
  if (isDefined(error.message)) {
    return error.message;
  }
  return '';
}

function getErrorStatus(error) {
  if (isNull(error)) {
    return null;
  }
  if (isDefined(error.status)) {
    return error.status;
  }
  if (error.response?.status) {
    return error.response.status;
  }
  if (
    !isNull(error) &&
    isDefined(error.code) &&
    error.code === 'ECONNABORTED'
  ) {
    return 408;
  }
  return null;
}

/**
 * handleAPIError
 * @param {{
 *  originalError: object;
 *  errorLocation: string;
 *  args?: object;
 *  ignore403Errors?: boolean;
 * }} args
 */
async function handleAPIError({
  originalError = mandatory('originalError'),
  errorLocation = mandatory('errorLocation'),
  args,
  ignore403Errors = false,
} = {}) {
  // Process JavaScript errors using the correct handler
  if (isUndefined(originalError.config)) {
    return determineError(originalError);
  }

  const error = cloneError(originalError); // Independent copy of original error

  // Handle revised structure of errors returned in Axios >= 0.19.0
  if (originalError.isAxiosError) {
    try {
      if (error.request) {
        error.request = JSON.parse(stringifyNonCircular(originalError.request));
      }
    } catch (e) {
      //
    }
    try {
      if (error.response) {
        error.response = JSON.parse(
          stringifyNonCircular(originalError.response)
        );
      }
    } catch (e) {
      //
    }
  }

  // Strip out information which is duplicated elsewhere
  if (
    isDefined(error.config) &&
    isDefined(error.response) &&
    isDefined(error.response.config)
  ) {
    delete error.config;
  }
  if (
    isDefined(error.request) &&
    isDefined(error.response) &&
    isDefined(error.response.request)
  ) {
    delete error.request;
  }

  // Axios may return config.data already stringified, so convert it back
  // to an object so that we can strip any sensitive data from it if necessary
  if (
    isDefined(error.config) &&
    isDefined(error.config.data) &&
    typeof error.config.data === 'string'
  ) {
    error.config.data = JSON.parse(error.config.data.trim());
  }
  if (
    isDefined(error.response) &&
    isDefined(error.response.config) &&
    isDefined(error.response.config.data) &&
    typeof error.response.config.data === 'string'
  ) {
    error.response.config.data = JSON.parse(error.response.config.data.trim());
  }

  // Ensure we log the original message
  error.origin = originalError.message;

  // Move keys in the __sentry_xhr__ structure up a level
  if (isDefined(error.request) && isDefined(error.request.__sentry_xhr__)) {
    Object.keys(error.request.__sentry_xhr__).forEach(key => {
      error.request[key] = error.request.__sentry_xhr__[key];
    });
    delete error.request.__sentry_xhr__;
  }

  const isOnline = sessionStorage.getItem('isOnline');
  if (error.code === 'ECONNABORTED') {
    error.message = API_ERROR_MESSAGES.API_TIMEOUT;
    error.type = API_ERROR_TYPES.API_TIMEOUT;
  } else if (!isNull(isOnline) && !JSON.parse(isOnline)) {
    error.message = API_ERROR_MESSAGES.API_INITIALISATION;
    error.type = API_ERROR_TYPES.API_INITIALISATION;
  } else if (originalError.message === 'Network Error') {
    error.message = API_ERROR_MESSAGES.API_TERMINATION;
    error.type = API_ERROR_TYPES.API_TERMINATION;
  } else if (isUndefined(error.response)) {
    logger.error({
      event: 'Unidentified Error',
      properties: {
        OriginalError: originalError,
      },
    });
    if (error.request && error.request?.status_code === 0) {
      // XHR status code 0
      error.message = `Code: X-${errorGuid()}. ${API_ERROR_MESSAGES.API_XHR}`;
      error.type = API_ERROR_TYPES.API_XHR;
    } else {
      error.message = `Code: H-${errorGuid()}. ${API_ERROR_MESSAGES.API_HTTP}`;
      error.type = API_ERROR_TYPES.API_HTTP;
    }
  } else if (isDefined(error.response.status)) {
    if (
      error.response.status === 500 ||
      isUndefined(error.response.data) ||
      isUndefined(error.response.data.error)
    ) {
      error.message = `Code: H-${errorGuid()}. ${API_ERROR_MESSAGES.API_HTTP}`;
    } else {
      error.message = error.response.data.error.message;
    }
    error.type = API_ERROR_TYPES.API_HTTP;
  }

  const status = getErrorStatus(originalError);

  // Ignore unauthorised errors received when on the signin page (these could be caused
  // by API requests completing *after* the user has signed out) or when validating
  // a new email address, or when running Cypress tests where "background" requests
  // are prone to failing even though they don't affect the tests themselves
  const currentPath = window?.location?.pathname;
  if (status === 401 && (currentPath === '/signin' || window.Cypress)) {
    return null;
  }

  // Forbidden errors occur when a user no longer has permissions on a property or api
  // In this case we reload the page, which in turn will refresh global info and, if
  // necessary, reset the current property and api
  if (status === 403 && !isRunningTests() && !ignore403Errors) {
    logger.error({
      event: '403 Error',
      properties: {
        OriginalError: originalError,
      },
    });

    let isStaffUser = false;
    try {
      isStaffUser = await authentication.isStaffUser();
    } catch {
      //
    }
    const isImpersonating = authentication.isImpersonating();
    if (!isStaffUser && !isImpersonating) {
      // Add message informing the user
      /*
      logger.info(
        `PubSub: publish ${COMMAND_FLASH_MESSAGES_ADD_MESSAGE} in common/errorHandling.handleAPIError`
      );
      PubSub.publish(COMMAND_FLASH_MESSAGES_ADD_MESSAGE, {
        type: FLASH_MESSAGE_TYPES.ERROR,
        text: `An administrator has changed your permissions. You have been redirected here
             and the selected action has not been performed.`,
      });
      */

      // Sign User Out
      Hub.dispatch(HUB_CHANNELS.GLOBAL_EVENTS, {
        event: HUB_EVENTS.GLOBAL_EVENTS.FORCE_SIGNOUT,
      });
    }
  }

  // Stringify config data
  if (error.config && typeof error.config?.data === 'object') {
    error.config.data = JSON.stringify(error.config.data);
  }
  if (error.response && typeof error.response?.config?.data === 'object') {
    error.response.config.data = JSON.stringify(error.response.config.data);
  }

  logger.error({
    event: 'API Layer Error',
    properties: {
      ErrorLocation: errorLocation,
      Arguments: stringifyKeys(args), // Stringify and strip certain keys first
    },
    error: stringifyKeys(error), // And here
  });

  // Return the user to the signin page if an unauthorised error is received
  // If an auth token still exists in their session this implies the session was terminated
  // on the server side due and therefore we should pass a parameter to the signout page
  if (typeof window !== 'undefined' && status === 401) {
    const isSignedIn = authentication.isSignedIn();
    logger.error({
      event: '401 Error',
      properties: {
        ErrorLocation: errorLocation,
        Arguments: stringifyKeys(args),
        isRefreshingSession: window?.EBX?.isRefreshingSession ?? false,
        isSignedIn,
      },
      error: stringifyKeys(error),
    });
    if (!window?.EBX?.isRefreshingSession) {
      if (isSignedIn) {
        window.location.href = '/signout?timeout';
      } else {
        window.location.href = '/signout';
      }
    }
  }

  if (!isNull(error) && isDefined(error.response) && !isNull(error.response)) {
    if (isDefined(error.response.data)) {
      if (status === 500) {
        return {
          status: error.response.status,
          error: error.message,
        };
      }
      return {
        status: error.response.status,
        error: error.response.data.error,
      };
    }
    if (isDefined(error.response.body)) {
      return {
        status: error.response.status,
        error: error.response.body.error,
      };
    }
    return {
      status: error.response.status,
      error: error.response,
    };
  }

  return error;
}

async function handleJSErrorAsync(originalError) {
  const error = cloneError(originalError); // Independent copy of original error
  if (!isNull(error) && typeof error !== 'string') {
    if (
      isUndefined(error.message) ||
      (error.message.indexOf('Symbol.iterator') === -1 &&
        error.message.indexOf('EBX:INTERNAL') === -1)
    ) {
      // Capture raw error
      if (typeof Sentry !== 'undefined') {
        let version = '0.0.0';
        try {
          version = await API.getVersion();
        } catch (e) {
          //
        }
        Sentry.configureScope(scope => {
          scope.setTag('version', version);
        });
        const globalInfo = globalinfo.getGlobalInfo();
        if (!isNull(globalInfo)) {
          Sentry.configureScope(scope => {
            scope.setUser({
              id: globalInfo.user.username,
              username: globalInfo.user.username,
            });
            scope.setTag('propertyURN', globalInfo.current.propertyURN);
            scope.setTag('campaignURN', globalInfo.current.campaignURN);
          });
        }
        if (isError(error)) {
          Sentry.captureException(error);
        } else if (isErrorLike(error)) {
          Sentry.captureException(new EchoboxError(error));
        } else {
          try {
            Sentry.captureException(new Error(error));
          } catch (e) {
            //
          }
        }
      }
      // Capture categorised error
      if (isDefined(error.message)) {
        error.origin = error.message;
      }
      error.message = `Code: N-${errorGuid()}. ${
        NON_API_ERROR_MESSAGES.NON_API_GENERAL
      }`;
      error.type = NON_API_ERROR_TYPES.NON_API_GENERAL;
      logger.error({
        event: 'Unhandled JavaScript Error',
        error,
      });
    }
  }
  return error;
}

function handleJSError(originalError) {
  const error = cloneError(originalError); // Independent copy of original error
  if (!isNull(error) && typeof error !== 'string') {
    if (
      isUndefined(error.message) ||
      (error.message.indexOf('Symbol.iterator') === -1 &&
        error.message.indexOf('EBX:INTERNAL') === -1)
    ) {
      // Capture raw error
      if (typeof Sentry !== 'undefined') {
        API.getVersion()
          .then(version => {
            Sentry.configureScope(scope => {
              scope.setTag('version', version);
            });
            const globalInfo = globalinfo.getGlobalInfo();
            if (!isNull(globalInfo)) {
              Sentry.configureScope(scope => {
                scope.setUser({
                  id: globalInfo.user.username,
                  username: globalInfo.user.username,
                });
                scope.setTag('propertyURN', globalInfo.current.propertyURN);
                scope.setTag('accountAPIId', globalInfo.current.accountAPIId);
              });
            }
            if (isError(error)) {
              Sentry.captureException(error);
            } else if (isErrorLike(error)) {
              Sentry.captureException(new EchoboxError(error));
            } else {
              try {
                Sentry.captureException(new Error(error));
              } catch (e) {
                //
              }
            }
          })
          .catch(() => {});
      }
      // Capture categorised error
      if (isDefined(error.message)) {
        error.origin = error.message;
      }
      error.message = `Code: N-${errorGuid()}. ${
        NON_API_ERROR_MESSAGES.NON_API_GENERAL
      }`;
      error.type = NON_API_ERROR_TYPES.NON_API_GENERAL;
      logger.error({
        event: 'Unhandled JavaScript Error',
        error,
      });
    }
  }
  return error;
}

/**
 * handleCognitoError
 * @param {{
 *  originalError: Error;
 *  errorLocation: string;
 *  args?: object;
 * }} args
 */
function handleCognitoError({
  originalError = mandatory('originalError'),
  errorLocation = mandatory('errorLocation'),
  args,
} = {}) {
  const error = cloneError(originalError); // Independent copy of original error
  error.type = API_ERROR_TYPES.API_HTTP_COGNITO;

  if (error.code === 'UserNotFoundException') {
    error.message = 'Incorrect username or password.';
  }

  logger.error({
    event: 'AWS Cognito Error',
    properties: {
      ErrorLocation: errorLocation,
      Arguments: stringifyKeys(args), // Stringify dataJSON entries first
    },
    error,
  });

  return error;
}

function handleExternalAPIError({
  originalError = mandatory('originalError'),
  errorLocation = mandatory('errorLocation'),
  args,
} = {}) {
  const error = cloneError(originalError); // Independent copy of original error
  error.type = API_ERROR_TYPES.API_HTTP_EXTERNAL;

  logger.error({
    event: 'External Service Error',
    properties: {
      ErrorLocation: errorLocation,
      Arguments: stringifyKeys(args), // Stringify dataJSON entries first
    },
    error,
  });
}

function isCognitoError(error) {
  return (
    isDefined(error.code) && isDefined(error.message) && isDefined(error.name)
  );
}

function isIgnoredError(event, hint) {
  // Extract details from event
  const exceptions = []; // Error messages extracted from exceptions
  const breadcrumbs = []; // Error messages extracted from breadcrumbs
  try {
    Object.keys(event.exception.values).forEach(key => {
      if (isDefined(event.exception.values[key].value)) {
        exceptions.push(event.exception.values[key].value);
      }
    });
  } catch (e) {
    //
  }
  try {
    Object.keys(event.breadcrumbs).forEach(key => {
      if (isDefined(event.breadcrumbs[key].message)) {
        breadcrumbs.push(event.breadcrumbs[key].message);
      }
    });
  } catch (e) {
    //
  }
  // Extract details from hint
  let message;
  let filename;
  let error;
  try {
    message = hint.originalException.message;
    filename = hint.originalException.filename;
    error = hint.originalException.error;
  } catch (e) {
    //
  }

  let ignored = false;
  SENTRY_IGNORED_ERRORS.forEach(ignore => {
    let matches = false;
    if (isDefined(error) && isDefined(ignore.typeError)) {
      matches = error instanceof TypeError;
    }
    if (!matches && isDefined(filename) && isDefined(ignore.filename)) {
      matches = filename.match(ignore.filename);
    }
    if (!matches && isDefined(message) && isDefined(ignore.message)) {
      matches = message === ignore.message;
    }
    if (!matches && isDefined(exceptions) && isDefined(ignore.message)) {
      matches = exceptions.some(exception => exception.match(ignore.message));
    }
    if (!matches && isDefined(breadcrumbs) && isDefined(ignore.message)) {
      matches = breadcrumbs.some(breadcrumb =>
        breadcrumb.match(ignore.message)
      );
    }
    if (matches) {
      ignored = true;
    }
  });

  return ignored;
}

function isNetworkError(error) {
  return (
    !isNull(error) &&
    isDefined(error.message) &&
    (error.message === API_ERROR_MESSAGES.API_INITIALISATION ||
      error.message === API_ERROR_MESSAGES.API_TIMEOUT ||
      error.message === API_ERROR_MESSAGES.API_TERMINATION ||
      error.message.indexOf(API_ERROR_MESSAGES.API_HTTP) !== -1 ||
      error.message === 'Network Error')
  );
}

function stringifyError(error, filter, space) {
  const plainObject = {};
  Object.getOwnPropertyNames(error).forEach(key => {
    plainObject[key] = error[key];
  });
  return JSON.stringify(plainObject, filter, space);
}

function stringifyReport(report) {
  const stringified = cloneObject(report);

  // Stringify any Arguments entries that are objects
  // This includes things like Arguments.accountAPIIds, Arguments.identifiers,
  // Arguments.permissionsOnProperty and Arguments.propertyURNs which can account for
  // a significant number of different fields
  if (isDefined(stringified.Arguments)) {
    Object.keys(stringified.Arguments).forEach(arg => {
      if (typeof stringified.Arguments[arg] === 'object') {
        try {
          stringified.Arguments[arg] = JSON.stringify(
            stringified.Arguments[arg]
          );
        } catch (e) {
          //
        }
      }
    });
  }

  // Stringify any Error.config and Error.response.config/headers/request entries
  ['Error', 'OriginalError'].forEach(key => {
    if (!isNullOrUndefined(stringified[key])) {
      if (!isNullOrUndefined(stringified[key].config)) {
        try {
          stringified[key].config = JSON.stringify(stringified[key].config);
        } catch (e) {
          //
        }
      }
      if (!isNullOrUndefined(stringified[key].response)) {
        if (!isNullOrUndefined(stringified[key].response.config)) {
          try {
            stringified[key].response.config = JSON.stringify(
              stringified[key].response.config
            );
          } catch (e) {
            //
          }
        }
        if (!isNullOrUndefined(stringified[key].response.headers)) {
          try {
            stringified[key].response.headers = JSON.stringify(
              stringified[key].response.headers
            );
          } catch (e) {
            //
          }
        }
        if (!isNullOrUndefined(stringified[key].response.request)) {
          try {
            stringified[key].response.request = JSON.stringify(
              stringified[key].response.request
            );
          } catch (e) {
            //
          }
        }
      }
    }
  });

  return stringified;
}

class EchoboxError extends Error {
  constructor(error) {
    super(error.message);
    this.name = this.constructor.name;
    this.stack = error.stack;
  }
}
