/* eslint no-use-before-define: "off" */

import isEqual from 'fast-deep-equal';

export {
  addOrReplaceItem,
  arePropsEqual,
  cloneArray,
  cloneError,
  cloneObject,
  createEventHandler,
  isDefined,
  isEmpty,
  isEmptyOrNullOrUndefined,
  isError,
  isErrorLike,
  isNull,
  isNullOrUndefined,
  isRunningLocally,
  isRunningTests,
  isUndefined,
  isEchoboxUser,
  until,
};

/**
 *
 * @param { any[] | undefined } array
 * @param { any } item
 * @param { FixTypeLater | null} property
 * @returns
 */
function addOrReplaceItem(array, item, property = null) {
  const newArray = [...array];
  const index = array.findIndex(a => a[property] === item[property]);
  if (index === -1) {
    newArray.push(item);
  } else {
    newArray.splice(index, 1, item);
  }
  return newArray;
}

function arePropsEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

function cloneArray(arr) {
  return JSON.parse(JSON.stringify(arr));
}

function cloneError(error) {
  /**
   * JSON.stringify-ing an Error object doesn't work as many of its properties are
   * not enumerable, so this method explicitly stringifies the relevant keys
   */
  if (isNullOrUndefined(error)) {
    return error;
  }
  try {
    return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
  } catch (e) {
    return error;
  }
}

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function createEventHandler(fn, ...outer) {
  return function unnamed(...inner) {
    return fn.apply(this, outer.concat(Array.prototype.slice.call(inner, 0)));
  };
}

function isDefined(value) {
  return !isUndefined(value);
}

function isEmpty(value) {
  if (typeof value === 'undefined') return true;
  if (value === null) return true;
  if (typeof value !== 'object') return false;
  return !Object.keys(value).length;
}

function isEmptyOrNullOrUndefined(value) {
  return isEmpty(value) || isNull(value) || isUndefined(value);
}

function isError(error) {
  switch ({}.toString.call(error)) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return error instanceof Error;
  }
}

function isErrorLike(error) {
  return (
    !isError(error) &&
    typeof error === 'object' &&
    isDefined(error) &&
    isDefined(error.message)
  );
}

function isNull(value) {
  return value === null;
}

function isNullOrUndefined(value) {
  return isNull(value) || isUndefined(value);
}

function isRunningLocally() {
  return isUndefined(process.env.CI);
}

function isRunningTests() {
  return process.env.NODE_ENV === 'test';
}
function isUndefined(value) {
  return value === undefined || typeof value === 'undefined';
}

function isEchoboxUser(email) {
  if (email) {
    const emailDomain = email.split('@');
    if (emailDomain[1] === 'echobox.com') {
      return true;
    }
  }

  return false;
}

function until(conditionalFn, fn, intervalInMs, timeoutInMs) {
  if (conditionalFn()) Promise.resolve();
  const startTime = new Date().getTime();
  return new Promise(resolve => {
    // At a regular interval, call the fn
    const interval = setInterval(() => {
      fn();
      const currentTime = new Date().getTime();
      if (conditionalFn() || currentTime - startTime >= timeoutInMs) {
        clearInterval(interval);
        resolve();
      }
    }, intervalInMs);
  });
}
