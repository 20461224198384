import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { batchRequests } from 'common/request';
import { Edition } from 'common/types';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

const MAX_EDITIONS_IN_BATCH = 10;

interface GetEditionsArgs {
  editionURNs: string[];
  fieldList?: string[];
}

export default async function getEditions({
  editionURNs,
  fieldList,
}: GetEditionsArgs): Promise<Edition[]> {
  if (isUndefined(editionURNs)) {
    mandatory('editionURNs');
  }

  return batchRequests(editionURNs, MAX_EDITIONS_IN_BATCH, editionURNBatch =>
    getEditionsNonBatched({ editionURNs: editionURNBatch, fieldList })
  );
}

export async function getEditionsNonBatched({
  editionURNs,
  fieldList,
}: GetEditionsArgs): Promise<Edition[]> {
  if (isUndefined(editionURNs)) {
    mandatory('editionURNs');
  }

  const guid = metrics.start('getEditions');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/editions/${editionURNs}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  if (fieldList) {
    config.url += `?fields=${fieldList.join(',')}`;
  }
  logger.info(
    `API:getEditions /editions/${editionURNs}${
      fieldList ? `?fields=${fieldList.join(',')}` : ''
    }`
  );

  try {
    const response = await axios(config);
    metrics.end('getEditions', guid);
    return response.data?.editions;
  } catch (error) {
    metrics.fail('getEditions', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getEditions',
            args: { editionURNs },
          })
        : error;
    throw apiError;
  }
}
