import { Button, Flex, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';

interface SendPreviewButtonProps {
  handleShow: () => void;
  isDisabled: boolean;
  isSending: boolean;
}

function SendPreviewButton({
  handleShow,
  isSending,
  isDisabled,
}: SendPreviewButtonProps) {
  return (
    <Button
      variant="secondary"
      onClick={handleShow}
      isDisabled={isSending || isDisabled}
    >
      {isSending ? (
        <Flex gap={1} alignItems="center">
          <Spinner color="gray.600" />
          Sending...
        </Flex>
      ) : (
        <>Send preview</>
      )}
    </Button>
  );
}

export default SendPreviewButton;
