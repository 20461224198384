import { Button, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { FixTypeLater } from 'common/types';

import { BackLink } from 'components/settings/campaigns/common';
import Footer from 'components/settings/campaigns/content/Footer';

interface FooterTextProps {
  setSettingsPanel: (settingsPage: string) => void;
  settings: FixTypeLater;
  isEditionSettings?: boolean;
}

function FooterText({
  setSettingsPanel,
  settings,
  isEditionSettings = false,
}: FooterTextProps) {
  const socialLinksSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS
  );

  const privacyPolicySettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY
  );

  const footerTextSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.FOOTER_TEXT
  );

  return (
    <>
      <Button
        variant="link"
        filter="none"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.OPTIONS
          )
        }
        mb={4}
      >
        <BackLink linkText="Back to General Styling" />
      </Button>
      <Heading variant="h3" mb={8}>
        Footer
      </Heading>
      <Footer
        settings={settings}
        socialLinksSettings={socialLinksSettings}
        privacyPolicySettings={privacyPolicySettings}
        footerTextSettings={footerTextSettings}
        isEditionSettings={isEditionSettings}
      />
    </>
  );
}

export default FooterText;
