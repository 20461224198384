import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

function SettingsPageWrapper({ children }) {
  return <Row className="settings-branding w-100 m-0">{children}</Row>;
}

SettingsPageWrapper.propTypes = {
  children: PropTypes.node,
};

SettingsPageWrapper.defaultProps = {
  children: null,
};

export default SettingsPageWrapper;
