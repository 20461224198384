import PropTypes from 'prop-types';
import { Button, Nav, Navbar } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/error-icon.svg';

import './Error.css';

function Error({ errorMessage, handleErrorClose }) {
  return (
    <div className="error__border">
      <Navbar
        collapseOnSelect
        variant="light"
        expand="lg"
        id="subheader"
        className="navbar--height w-100 error__box"
      >
        <Nav className="d-flex flex-wrap flex-row align-items-center px-2 w-100">
          <span>
            <ErrorIcon className="ebx-error-colour mr-2" />
            <span className="ebx-small-text">{errorMessage}</span>
          </span>
          <span className="ml-auto">
            <Button variant="link" className="p-0" onClick={handleErrorClose}>
              <CloseIcon />
            </Button>
          </span>
        </Nav>
      </Navbar>
    </div>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  handleErrorClose: PropTypes.func.isRequired,
};

export default Error;
