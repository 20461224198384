import { Alert } from '@ebx-ui/ebx-ui-component-library-sdk';

function ContentPersonalisation() {
  return (
    <Alert variant="info" mb={6}>
      <Alert.AlertTitle>Content personalisation is enabled</Alert.AlertTitle>
      <Alert.AlertDescription>
        Articles will be selected based on each subscriber&apos;s preferences
      </Alert.AlertDescription>
    </Alert>
  );
}

export default ContentPersonalisation;
