import { Alert, Box } from '@ebx-ui/ebx-ui-component-library-sdk';

export interface EditionErrorMessageProps {
  errorMessage: string;
}

function EditionErrorMessage({ errorMessage }: EditionErrorMessageProps) {
  return (
    <Box mt={4} px={6}>
      <Alert
        variant="error"
        p={3}
        boxShadow="none"
        border="1px solid"
        borderColor="red.600"
        sx={{
          div: {
            gap: '8px',
          },
        }}
      >
        <Alert.AlertDescription
          display="flex"
          gap="2"
          color="gray.900"
          fontWeight="500"
        >
          {errorMessage}
        </Alert.AlertDescription>
      </Alert>
    </Box>
  );
}

export default EditionErrorMessage;
