import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postCampaigns({
  propertyURN = mandatory('propertyURN'),
  campaignDetails = mandatory('campaignDetails'),
}) {
  const guid = metrics.start('postCampaigns');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/campaigns`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      ...campaignDetails,
    }),
  };
  logger.info(`API:postCampaigns /properties/${propertyURN}/campaigns`);

  try {
    const response = await axios(config);
    metrics.end('postCampaigns', guid);
    return response.data;
  } catch (error) {
    metrics.fail('postCampaigns', guid);
    // Just rethrow if the campaign is a duplicate
    if (
      error.response?.status === 422 &&
      error.response.data?.target === 'campaignName'
    ) {
      throw error;
    }
    const apiError = await handleAPIError({
      originalError: error,
      // TODO - work out how to sort out this TypeScript hack at some point
      // @ts-ignore
      errorLocation: 'api/postCampaigns',
      args: { campaignDetails },
    });
    throw apiError;
  }
}
