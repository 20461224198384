import type {
  LoadingStatus,
  Steps,
} from 'components/campaigns/editions/SmartCurationLoadingModal';
import SmartCurationLoadingStatus from 'components/campaigns/editions/SmartCurationLoadingStatus';
import SmartCurationLoadingSubstep from 'components/campaigns/editions/SmartCurationLoadingSubstep';

const getAnalysingStatus = (steps: Steps): LoadingStatus => {
  if (steps.every(step => step.status === 'waiting')) {
    return 'waiting';
  }

  if (steps.every(step => step.status === 'loaded')) {
    return 'loaded';
  }

  return 'loading';
};

interface SmartCurationLoadingStepProps {
  steps: Steps;
  description: string;
}

function SmartCurationLoadingStep({
  steps,
  description,
}: SmartCurationLoadingStepProps) {
  return (
    <>
      <div className="ebx-h2 ebx-title-colour mb-2 loading-step-header d-flex align-items-center">
        <SmartCurationLoadingStatus
          status={getAnalysingStatus(steps)}
          type="step"
        />
        {description}
      </div>
      <div className="mb-2 d-flex">
        <div className="loading-divider" />
        <div className="loading-subsections mt-1">
          {steps.map(step => (
            <SmartCurationLoadingSubstep
              key={step.description}
              status={step.status}
            >
              {step.description}
            </SmartCurationLoadingSubstep>
          ))}
        </div>
      </div>
    </>
  );
}

export default SmartCurationLoadingStep;
