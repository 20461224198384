import { mandatory } from 'common/validation';

import createUploads from 'api/misc/createUploads';

export default async function putS3Uploads({
  uploadURL = mandatory('uploadURL'),
  fileContent = mandatory('fileContent'),
  onProgress = mandatory('onProgress'),
}) {
  try {
    const httpMethod = 'PUT';
    const contentType = 'text/plain';
    await createUploads({
      uploadURL,
      fileContent,
      onProgress,
      httpMethod,
      contentType,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}
