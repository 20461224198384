import PropTypes from 'prop-types';

import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-right-OLD.svg';
import { SETTINGS_PANEL_VARIANTS } from 'common/constants';

import './SettingsPanel.css';
import SettingsPanelBase from './SettingsPanelBase';

export default function SettingsPanel({
  title,
  description,
  className,
  variant,
  icon,
  label,
  ...rest
}) {
  return (
    <SettingsPanelBase
      className={`d-flex settings-panel-${variant} ${className}`}
      {...rest}
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <div>
          <h2 className="settings-panel-title d-flex">
            {icon !== null && (
              <div className="settings-panel-icon-container">
                <img
                  src={`/assets/svg/${icon}.svg`}
                  alt={icon}
                  className="settings-panel-icon"
                />
              </div>
            )}
            <span className={`settings-panel-title-${variant}`}> {title} </span>
            {label}
          </h2>
          {description && (
            <span className="settings-panel-description ebx-body-colour">
              {description}
            </span>
          )}
        </div>

        <div className="settings-panel-actions-container">
          <span className={`settings-panel-arrow-${variant}`}>
            <ArrowRightIcon />
          </span>
        </div>
      </div>
    </SettingsPanelBase>
  );
}

SettingsPanel.defaultProps = {
  title: '',
  description: '',
  buttons: '',
  variant: SETTINGS_PANEL_VARIANTS.DEFAULT,
  className: '',
  icon: null,
  label: null,
};

SettingsPanel.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  buttons: PropTypes.node,
  variant: PropTypes.oneOf([
    SETTINGS_PANEL_VARIANTS.DEFAULT,
    SETTINGS_PANEL_VARIANTS.DISABLED,
    SETTINGS_PANEL_VARIANTS.SUCCESS,
    SETTINGS_PANEL_VARIANTS.INFO,
  ]),
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.node,
};
