import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'react-bootstrap';

import './Processing.css';

function Processing({ handleClose }) {
  return (
    <>
      <Modal.Body className="p-0 ebx-modal-body">
        <div className="uploading__file d-flex p-3 flex-wrap">
          <div className="pr-2 d-flex align-items-center w-100 mb-2">
            <Spinner
              animation="border"
              role="status"
              size="sm"
              className="mr-2"
            >
              <VisuallyHidden>Loading...</VisuallyHidden>
            </Spinner>
            <div className="uploading__title">Processing contacts</div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="border-0 ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button className="ebx-btn-primary ebx-btn-md ebx-h3" disabled={true}>
          Success
        </Button>
      </Modal.Footer>
    </>
  );
}

Processing.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Processing;
