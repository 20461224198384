import { useState } from 'react';

import * as API from 'api/api';
import * as datetime from 'common/datetime';
import * as errors from 'common/errors';

const DASHBOARD_EMAIL_LIST_WINDOW_SECS = datetime.DAYS(60);

const useLoadEmails = campaignURN => {
  const now = datetime.getUnixTimestamp();

  const [isInitialised, setInitialised] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [emptyPages, setEmptyPages] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [endUnixTime, setEndUnixTime] = useState(now + datetime.DAYS(14));
  const [startUnixTime, setStartUnixTime] = useState(
    endUnixTime - DASHBOARD_EMAIL_LIST_WINDOW_SECS
  );
  const [fetchSentOnly, setFetchSentOnly] = useState(false);

  const loadMore = async () => {
    setLoading(true);
    try {
      const data = await API.getEmailDetails({
        campaignURN,
        startUnixTime,
        endUnixTime,
        fetchSentOnly,
      });
      if (data.length > 0) {
        setItems(current => [...current, ...data]);
        setEmptyPages(0);
        setHasNextPage(true);
        setInitialised(true);
        setFetchSentOnly(true);
      } else if (emptyPages === 3) {
        setHasNextPage(
          false
        ); /* We now have four consecutive pages with no Editions, let's assume there are no more */
        setInitialised(true);
      } else {
        setEmptyPages(
          pages => pages + 1
        ); /* Go back another DASHBOARD_EMAIL_LIST_WINDOW_SECS window to look for previous editions */
      }
      setStartUnixTime(current => current - DASHBOARD_EMAIL_LIST_WINDOW_SECS);
      setEndUnixTime(current => current - DASHBOARD_EMAIL_LIST_WINDOW_SECS);
    } catch (err) {
      setErrorMessage(errors.getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return {
    isInitialised,
    isLoading,
    items,
    hasNextPage,
    errorMessage,
    loadMore,
  };
};

export default useLoadEmails;
