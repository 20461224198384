import { Link, useParams } from 'react-router-dom';

import SettingsPanel from 'components/settings/campaigns/SettingsPanel';

import { getCampaignName } from 'common/campaigns';
import useGlobalInfo from 'hooks/useGlobalInfo';

import './Campaign.css';

export default function Campaign() {
  const { urn } = useParams();
  const globalInfo = useGlobalInfo();
  const campaignName = getCampaignName(urn, globalInfo);

  const getCampaignPath = subpage => `/settings/campaigns/${urn}/${subpage}`;

  return (
    <>
      <div className="campaign-title ebx-title-colour">{campaignName}</div>
      <Link className="link-unstyled" to={getCampaignPath('general')}>
        <SettingsPanel
          title="Campaign details"
          description="View sender information, update timezone and disable Campaign"
        />
      </Link>
      <Link className="link-unstyled" to={getCampaignPath('content')}>
        <SettingsPanel
          title="Content"
          description="Configure the structure and content of your Campaign"
        />
      </Link>
      <Link className="link-unstyled" to={getCampaignPath('automation')}>
        <SettingsPanel
          title="Automation"
          description="Select the timing and frequency of your Campaign"
        />
      </Link>
      <Link className="link-unstyled" to={getCampaignPath('sign-up-forms')}>
        <SettingsPanel
          title="Sign-up forms"
          description="Methods for signing up readers to your Campaign"
        />
      </Link>
      <Link
        className="link-unstyled"
        to={getCampaignPath('apps-and-integrations')}
      >
        <SettingsPanel
          title="Apps & Integrations"
          description="Connect Echobox to other platforms"
        />
      </Link>
      <div className="ebx-h1 ebx-title-colour campaign-subheader-margin">
        Campaign styling
      </div>
      <Link className="link-unstyled" to={getCampaignPath('branding')}>
        <SettingsPanel
          title="Branding"
          description="Configure the visual style of your Campaign"
        />
      </Link>
    </>
  );
}
