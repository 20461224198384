import {
  ArrowDownIcon,
  ArrowUpIcon,
  Dropdown,
  Flex,
  MoreOptionsIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  UserRemoveIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import EngagementLabel from 'components/campaigns/subscribers/management/EngagementLabel';
import SingleUnsubscribeModal from 'components/campaigns/subscribers/management/SingleUnsubscribeModal';

import * as datetime from 'common/datetime';
import { SORT_ORDERS, SUBSCRIBER_MANAGEMENT_SORT_FIELDS } from 'common/enums';
import { tdProps, thProps, trProps } from 'common/tables';
import { Subscriber } from 'common/types';

export interface Column {
  name?: keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS;
  label: string;
  width: string;
  dataType?: 'string' | 'number';
}

export interface SortableColumn extends Column {
  name: keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS;
  defaultSortOrder: SORT_ORDERS;
  dataType: 'string' | 'number';
}

export const SUBSCRIBER_MANAGEMENT_COLUMNS: Column[] = [
  {
    name: SUBSCRIBER_MANAGEMENT_SORT_FIELDS.email,
    label: 'Email',
    defaultSortOrder: SORT_ORDERS.ASC,
    dataType: 'string',
  } as SortableColumn,
  {
    name: SUBSCRIBER_MANAGEMENT_SORT_FIELDS.firstName,
    label: 'First name',
    width: '160px',
    dataType: 'string',
  },
  {
    name: SUBSCRIBER_MANAGEMENT_SORT_FIELDS.lastName,
    label: 'Last name',
    width: '160px',
    dataType: 'string',
  },
  {
    name: SUBSCRIBER_MANAGEMENT_SORT_FIELDS.addedUnixTime,
    label: 'Added',
    width: '160px',
    defaultSortOrder: SORT_ORDERS.DESC,
    dataType: 'number',
  } as SortableColumn,
  {
    name: SUBSCRIBER_MANAGEMENT_SORT_FIELDS.engagementRate,
    label: 'Engagement',
    width: '160px',
    defaultSortOrder: SORT_ORDERS.DESC,
    dataType: 'number',
  } as SortableColumn,
  {
    label: '',
    width: '56px',
  },
];

interface SubscriberListProps {
  campaignURN: string;
  subscriberList: Subscriber[];
  sortBy: string;
  sortOrder: string;
  handleSort: (field: keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS) => void;
  handleUnsubscribe: () => void;
}

function SubscriberList({
  campaignURN,
  subscriberList,
  sortBy,
  sortOrder,
  handleSort,
  handleUnsubscribe,
}: SubscriberListProps) {
  const [unsubscribeEmail, setUnsubscribeEmail] = useState('');
  const {
    isOpen: unsubModalIsOpen,
    onOpen: unsubModalOnOpen,
    onClose: unsubModalOnClose,
  } = useDisclosure();

  const handleOpenUnsubModal = (subscriber: Subscriber) => {
    setUnsubscribeEmail(subscriber.email);
    unsubModalOnOpen();
  };

  return (
    <>
      <TableContainer>
        <Table
          variant="simple"
          border="1px"
          borderBottom="none"
          borderColor="gray.300"
          borderRadius="md"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: 0,
            tableLayout: 'fixed',
          }}
        >
          <Thead bg="gray.100">
            <Tr>
              {SUBSCRIBER_MANAGEMENT_COLUMNS.map(column => (
                <Th
                  {...thProps}
                  width={column.width}
                  cursor={
                    (column as SortableColumn).defaultSortOrder
                      ? 'pointer'
                      : 'default'
                  }
                  key={`column-${column.name}`}
                  onClick={
                    (column as SortableColumn).defaultSortOrder
                      ? () => handleSort((column as SortableColumn).name)
                      : () => {}
                  }
                >
                  <Flex gap={2}>
                    {column.label}
                    {column.name === sortBy &&
                      (sortOrder === SORT_ORDERS.ASC ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      ))}
                    {column.name !== sortBy && (
                      <ArrowUpIcon style={{ opacity: 0 }} />
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {subscriberList.map((subscriber: Subscriber) => (
              <Tr key={subscriber.email} {...trProps}>
                <Td {...tdProps}>{subscriber.email}</Td>
                <Td {...tdProps}>
                  {subscriber.firstName ? subscriber.firstName : '-'}
                </Td>
                <Td {...tdProps}>
                  {subscriber.lastName ? subscriber.lastName : '-'}
                </Td>
                <Td {...tdProps}>
                  {datetime.localeDateString(subscriber.addedUnixTime)}
                </Td>
                <Td {...tdProps} py={3.5}>
                  <EngagementLabel subscriber={subscriber} />
                </Td>
                <Td {...tdProps}>
                  <Flex justifyContent="flex-end">
                    <Dropdown>
                      <Dropdown.Button height={5}>
                        <MoreOptionsIcon size={5} color="gray.600" />
                      </Dropdown.Button>
                      <Dropdown.List>
                        <Dropdown.Item
                          icon={<UserRemoveIcon size={5} />}
                          onClick={() => handleOpenUnsubModal(subscriber)}
                        >
                          Unsubscribe
                        </Dropdown.Item>
                      </Dropdown.List>
                    </Dropdown>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {unsubModalIsOpen && (
        <SingleUnsubscribeModal
          campaignURN={campaignURN}
          emailAddress={unsubscribeEmail}
          isOpen={unsubModalIsOpen}
          onClose={unsubModalOnClose}
          handleSuccess={() => handleUnsubscribe()}
        />
      )}
    </>
  );
}

export default SubscriberList;
