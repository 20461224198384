export { findSetting, findSettings };

function findSetting(campaignSettings, settingsType) {
  if (campaignSettings && campaignSettings.campaignSettings) {
    return campaignSettings.campaignSettings.find(
      item => item.settingsType === settingsType
    );
  }
  return null;
}

function findSettings(campaignSettings, settingsTypes) {
  const settings = {};
  for (const type of settingsTypes) {
    const setting = campaignSettings.find(item => item.settingsType === type);
    if (setting) {
      settings[type] = setting;
    }
  }
  return settings;
}
