import PropTypes from 'prop-types';

import GenericError from 'components/GenericError';

function EditorError({ errorMessage }) {
  return (
    <div className="ebx-background-colour">
      <div className="px-4">
        <div className="my-2">
          <div className="ebx-sub-info ebx-sub-info-processing d-flex flex-wrap w-100">
            <div className="w-100 ebx-sub-panel d-flex align-items-center justify-content-center">
              <div className="py-2">
                <GenericError errorMessage={errorMessage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EditorError.propTypes = {
  errorMessage: PropTypes.string,
};

EditorError.defaultProps = {
  errorMessage: undefined,
};

export default EditorError;
