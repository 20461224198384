import { Auth } from 'aws-amplify';

import { handleCognitoError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function authVerifyUserEmail() {
  const guid = metrics.start('authVerifyUserEmail');
  logger.info('API:authVerifyUserEmail');

  try {
    const response = await Auth.verifyCurrentUserAttribute('email');
    metrics.end('authVerifyUserEmail', guid);
    return response;
  } catch (error) {
    metrics.fail('authVerifyUserEmail', guid);
    const cognitoError =
      error instanceof Error
        ? handleCognitoError({
            originalError: error,
            errorLocation: 'api/auth/authVerifyUserEmail',
          })
        : error;
    throw cognitoError;
  }
}
