import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Sidebar } from '../common';

function SidebarWrapper({ subPath, backLabel, children }) {
  return (
    <Col xs={12} md={4}>
      <Sidebar>
        <Link
          to={`/settings/campaigns/allcampaigns${
            subPath !== '' ? `/${subPath}` : ''
          }`}
          className="d-flex align-items-center mb-3"
        >
          <ArrowBackIcon className="settings-back-arrow mr-1" />
          <span className="ebx-h3">Back to {backLabel}</span>
        </Link>
        {children}
      </Sidebar>
    </Col>
  );
}

SidebarWrapper.propTypes = {
  subPath: PropTypes.string,
  backLabel: PropTypes.string,
  children: PropTypes.node,
};

SidebarWrapper.defaultProps = {
  subPath: '',
  backLabel: 'manage your preferences',
  children: null,
};

export default SidebarWrapper;
