import {
  Flex,
  Heading,
  InfoIcon,
  Tag,
  Text,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import PerformanceStatistic from 'components/campaigns/PerformanceStatistic';
import LatestUpload from 'components/campaigns/subscribers/LatestUpload';

import * as API from 'api/api';
import { SubscriberInsights, SubscriberUpload } from 'common/types';

interface CampaignPerformanceStatisticsProps {
  campaignURN: string;
  campaignInsights: SubscriberInsights | null;
  isShutOff: boolean;
  latestUpload?: SubscriberUpload;
}

function CampaignPerformanceStatistics({
  campaignURN,
  campaignInsights = null,
  isShutOff,
  latestUpload,
}: CampaignPerformanceStatisticsProps) {
  const [campaignPerformance, setCampaignPerformance] = useState(null);
  const [performanceLoading, setPerformanceLoading] = useState(true);

  useEffect(() => {
    const fetchPerformanceStatistics = async () => {
      const campaignPerformanceStatistics = await API.getCampaignPerformance({
        campaignURN,
      });
      setCampaignPerformance(campaignPerformanceStatistics);
      setPerformanceLoading(false);
    };
    if (!isShutOff) {
      fetchPerformanceStatistics();
    }
  }, [campaignURN, isShutOff]);

  const noOfSubscribers =
    typeof campaignInsights?.activeSubscribers === 'number' &&
    typeof campaignInsights?.unsubscribedSubscribers === 'number'
      ? campaignInsights.activeSubscribers.toLocaleString()
      : 'N/A';

  return (
    <Flex gap={6} direction={{ base: 'column', sm: 'row' }}>
      <Flex
        direction="column"
        gap={2}
        borderRight={{ base: 'none', sm: '1px solid' }}
        borderColor={{ base: 'none', sm: 'gray.300' }}
      >
        <Flex align="center" direction={{ base: 'column', lg: 'row' }}>
          <Flex align="center">
            <Text size="sm" color="gray.600" mr={1}>
              Open rate
            </Text>
            <Tooltip
              label="Number of individuals who opened your email divided by the number of emails delivered in the Campaign."
              placement="top"
            >
              <InfoIcon color="gray.500" />
            </Tooltip>
          </Flex>
          <Tag color="gray" ml={7} mr={4}>
            Last 30 days
          </Tag>
        </Flex>
        <Heading variant="h4" textAlign={{ base: 'center', sm: 'start' }}>
          <PerformanceStatistic
            performanceLoading={performanceLoading}
            performanceObject={campaignPerformance}
            performanceKey="numUniqueOpens"
          />
        </Heading>
      </Flex>
      <Flex
        direction="column"
        gap={2}
        borderRight={{ base: 'none', sm: '1px solid' }}
        borderColor={{ base: 'none', sm: 'gray.300' }}
      >
        <Flex
          align="center"
          direction={{ base: 'column', lg: 'row' }}
          justify={{ sm: 'flex-start' }}
        >
          <Flex align="center">
            <Text size="sm" color="gray.600" mr={1}>
              Click rate
            </Text>
            <Tooltip
              label="Number of individuals who clicked a link in your email divided by the number of emails delivered in the Campaign."
              placement="top"
            >
              <InfoIcon color="gray.500" />
            </Tooltip>
          </Flex>
          <Tag color="gray" ml={7} mr={4}>
            Last 30 days
          </Tag>
        </Flex>
        <Heading variant="h4" textAlign={{ base: 'center', sm: 'start' }}>
          <PerformanceStatistic
            performanceLoading={performanceLoading}
            performanceObject={campaignPerformance}
            performanceKey="numUniqueClicks"
          />
        </Heading>
      </Flex>
      <Flex direction="column" gap={2}>
        <Flex align="center">
          <Flex align="center">
            <Text size="sm" color="gray.600" mr={1}>
              Subscribers
            </Text>
            <Tooltip
              label="Total number of readers that will receive Editions of your Campaign."
              placement="top"
            >
              <InfoIcon color="gray.500" />
            </Tooltip>
          </Flex>
        </Flex>
        <Heading variant="h4">{isShutOff ? '...' : noOfSubscribers}</Heading>
      </Flex>
      <LatestUpload latestUpload={latestUpload} />
    </Flex>
  );
}

export default CampaignPerformanceStatistics;
