/* eslint react-hooks/exhaustive-deps: "off" */

import { useEffect, useState } from 'react';

import * as API from 'api/api';

import {
  Button,
  Center,
  Modal,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Dropdown } from 'components/settings/campaigns/common';

import { Template } from 'common/types';

interface LoadTemplateModalProps {
  campaignURN: string;
  handleClose: () => void;
  handleSave: (templateURN: string) => void;
  showModal: boolean;
}

function LoadTemplateModal({
  campaignURN,
  handleClose,
  handleSave,
  showModal,
}: LoadTemplateModalProps) {
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [templateList, setTemplateList] = useState<Template[] | []>([]);
  const [currentTemplateURN, setCurrentTemplateURN] = useState('');
  const toast = useToast();

  useEffect(() => {
    const fetchTemplateList = async () => {
      try {
        setLoading(true);
        const retrievedTemplateList = await API.getMarketingTemplateList({
          campaignURN,
        });
        setTemplateList(retrievedTemplateList);
        setLoading(false);
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Unable to load list of existing templates',
        });
        handleClose();
      }
    };
    fetchTemplateList();
  }, [campaignURN]);

  const getMatchingOption = (templateURN: string, templates: Template[]) => {
    const matchingTemplate = templates.find(
      currentTemplate => currentTemplate.templateURN === templateURN
    );
    return matchingTemplate
      ? { label: matchingTemplate.name, value: matchingTemplate.templateURN }
      : null;
  };

  const handleLoad = async () => {
    try {
      setSaving(true);
      await handleSave(currentTemplateURN);
      handleClose();
    } catch (error) {
      toast({ variant: 'error', title: 'Unable to load selected template' });
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (option: { label: string; value: string }) => {
    setCurrentTemplateURN(option.value);
  };

  return (
    <Modal isOpen={showModal} onClose={handleClose} size="small">
      <Modal.Header>
        <Modal.Title>Load Template</Modal.Title>
      </Modal.Header>
      <Modal.Body overflow="visible">
        {isLoading ? (
          <Center my="24px">
            <Spinner size="lg" />
          </Center>
        ) : (
          <VStack align="left">
            <Text fontWeight="medium" size="sm">
              Template
            </Text>
            {templateList.length > 0 ? (
              <Dropdown
                components={{}}
                placeholder="Select Template To Load"
                options={templateList.map(currentTemplate => ({
                  label: currentTemplate.name,
                  value: currentTemplate.templateURN,
                }))}
                value={getMatchingOption(currentTemplateURN, templateList)}
                onChange={handleChange}
                isSearchable
              />
            ) : (
              <Text color="red.600" fontWeight="medium" size="sm">
                Found no previous templates
              </Text>
            )}
          </VStack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleLoad}
          isDisabled={currentTemplateURN === '' || isSaving || isLoading}
        >
          Load
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoadTemplateModal;
