import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * deletePromotionBlock
 * @param {{
 *  promotionBlockURN: string;
 * }} args
 */

interface DeletePromotionBlockArgs {
  promotionBlockURN: string;
}

export default async function deletePromotionBlock({
  promotionBlockURN,
}: DeletePromotionBlockArgs) {
  if (isUndefined(promotionBlockURN)) {
    mandatory('promotionBlockURN');
  }

  const guid = metrics.start('deletePromotionBlock');

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/promotionblocks/${promotionBlockURN}`;

  const config = {
    url,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  logger.info(`API:deletePromotionBlock /promotionblocks/${promotionBlockURN}`);

  try {
    const response = await axios(config);
    metrics.end('deletePromotionBlock', guid);
    return response.data;
  } catch (error) {
    metrics.fail('deletePromotionBlock', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/deletePromotionBlock',
            args: { promotionBlockURN },
          })
        : error;
    throw apiError;
  }
}
