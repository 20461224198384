import { Link as ReactLink } from 'react-router-dom';

import {
  Center,
  FormControl,
  Heading,
  Link,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import SyndfeedDropdown from 'components/settings/campaigns/SyndfeedDropdown';

import { SectionSyndFeed } from 'common/types';

import useGlobalInfo from 'hooks/useGlobalInfo';

interface SyndfeedsProps {
  selected: SectionSyndFeed[];
  setSelected: (syndfeeds: SectionSyndFeed[]) => void;
}

function Syndfeeds({ selected, setSelected }: SyndfeedsProps) {
  const globalInfo = useGlobalInfo();

  const onAdd = (item: SectionSyndFeed) => {
    if (selected) {
      setSelected([...selected, item]);
    } else {
      setSelected([item]);
    }
  };

  const onDelete = (deleteItem: SectionSyndFeed) => {
    setSelected(selected.filter(item => item !== deleteItem));
  };

  return (
    <FormControl mb={6}>
      <Heading variant="h5" mb={2}>
        RSS feeds or sitemaps
      </Heading>
      {globalInfo?.current?.propertyURN ? (
        <SyndfeedDropdown
          onAdd={onAdd}
          onDelete={onDelete}
          selected={selected}
          propertyURN={globalInfo.current.propertyURN}
        />
      ) : (
        <Center>
          <Spinner size="md" color="gray.600" />
        </Center>
      )}
      <FormControl.FormHelperText>
        To add or delete RSS feeds or Sitemaps, head over to{' '}
        <Link as={ReactLink} to="/settings/property">
          Property settings
        </Link>
      </FormControl.FormHelperText>
    </FormControl>
  );
}

export default Syndfeeds;
