import { Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { FRONTEND_FORM_STATES } from 'common/enums';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import Input from './Input';
import Success from './Success';

function Create({ show, handleClose }) {
  const [localState, setLocalState] = useState(FRONTEND_FORM_STATES.INPUT);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered={true}
      isOpen={show}
      onClose={handleClose}
      size="small"
    >
      <ModalPage
        handleClose={handleClose}
        localState={localState}
        setLocalState={setLocalState}
        show={show}
      />
    </Modal>
  );
}

Create.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Create;

function ModalPage({ handleClose, localState, setLocalState, show }) {
  switch (localState) {
    case FRONTEND_FORM_STATES.INPUT:
      return (
        <Input
          handleClose={handleClose}
          show={show}
          setLocalState={setLocalState}
        />
      );
    case FRONTEND_FORM_STATES.SUCCESS:
      return <Success handleClose={handleClose} />;
    default:
      // Shouldn't ever get to here
      return (
        <>
          <Modal.Header>
            <Modal.Title>Error</Modal.Title>
            <span className="ml-auto">
              <Button variant="link" className="p-0" onClick={handleClose}>
                <CloseIcon />
              </Button>
            </span>
          </Modal.Header>
          <Modal.Body className="p-0 text-center p-4 ebx-modal-body">
            <div className="error--text">Error 404 - Next step not found!</div>
          </Modal.Body>
          <Modal.Footer className="ebx-modal-footer">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </>
      );
  }
}

ModalPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  localState: PropTypes.string.isRequired,
  setLocalState: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
