import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import { STAFF_MODAL_STATES } from 'components/staff/StaffModalWrapper';

function PropertyCreated({ setCurrentComponent, newPropertyData }) {
  const handleBack = () => {
    setCurrentComponent(STAFF_MODAL_STATES.STAFF_OPTIONS);
  };

  return (
    <>
      <Modal.Body className="ebx-default-modal-body ebx-body-1 ebx-modal-body">
        <span>
          Property &apos;{newPropertyData.propertyName}&apos; created with URN
          &apos;{newPropertyData.propertyURN}&apos;
        </span>
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleBack}
        >
          Back
        </Button>
      </Modal.Footer>
    </>
  );
}

PropertyCreated.propTypes = {
  setCurrentComponent: PropTypes.func.isRequired,
  newPropertyData: PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
    propertyURN: PropTypes.string.isRequired,
  }).isRequired,
};

export default PropertyCreated;
