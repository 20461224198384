import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import MetricsContainer from 'components/campaigns/analytics/MetricsContainer';
import SubscriberEngagementDemo from 'components/campaigns/analytics/SubscriberEngagementDemo';
import MetricCard from 'components/common/MetricCard';

import { Campaign } from 'common/types';

interface StatisticsDemoProps {
  campaign: Campaign;
}

function StatisticsDemo({ campaign }: StatisticsDemoProps) {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const openRate = isLoading
    ? null
    : {
        value: 34.49,
        change: -1.41,
        moreInfoName: 'opens',
        moreInfoValue: 183002,
        isPercentage: true,
      };

  const clickRate = isLoading
    ? null
    : {
        value: 8.71,
        change: 0,
        moreInfoName: 'clicks',
        moreInfoValue: 15939,
        isPercentage: true,
      };

  const subscribers = isLoading
    ? null
    : {
        value: 530594,
        change: 3.09,
        moreInfoName: 'new subscribers',
        moreInfoValue: 16076,
        isPercentage: false,
      };

  const deliveries = isLoading
    ? null
    : {
        value: 2281554,
        change: -4.17,
        moreInfoName: 'Editions sent',
        moreInfoValue: 29,
        isPercentage: false,
      };

  const unsubscribeRate = isLoading
    ? null
    : {
        value: 0.21,
        change: 0,
        moreInfoName: 'unsubscribes',
        moreInfoValue: 1114,
        isPercentage: true,
      };

  const hardBounceRate = isLoading
    ? null
    : {
        value: 0.01,
        change: 1.91,
        moreInfoName: 'hard bounces',
        moreInfoValue: 21,
        isPercentage: true,
      };

  const softBounceRate = isLoading
    ? null
    : {
        value: 0.05,
        change: -1.28,
        moreInfoName: 'soft bounces',
        moreInfoValue: 110,
        isPercentage: true,
      };

  return (
    <Flex direction="column" gap={4} flexBasis="0">
      <MetricsContainer>
        <MetricCard
          label="Open rate"
          tooltip="Number of individuals who opened your emails divided by the number of emails delivered in your Campaign"
          metric={openRate}
        />
        <MetricCard
          label="Click rate"
          tooltip="Number of individuals who clicked a link in your emails divided by the number of emails delivered in your Campaign"
          metric={clickRate}
        />
        <MetricCard
          label="Subscribers"
          tooltip="Number of individuals who currently subscribe to your Campaign, and the subscribers gained or lost"
          metric={subscribers}
        />
      </MetricsContainer>
      <Flex gap={4}>
        <SubscriberEngagementDemo campaign={campaign} />
        <Flex direction="column" gap={4} flexBasis="50%" flexGrow={0}>
          <MetricsContainer>
            <MetricCard
              size="sm"
              label="Deliveries"
              tooltip="Total emails delivered to subscribers"
              metric={deliveries}
            />
            <MetricCard
              size="sm"
              label="Unsubscribe rate"
              tooltip="Number of individuals who have unsubscribed divided by the number of emails delivered in your Campaign"
              metric={unsubscribeRate}
              isPositive={false}
            />
          </MetricsContainer>
          <MetricsContainer>
            <MetricCard
              size="sm"
              label="Hard bounce rate"
              tooltip="Number of individuals whose emails have hard bounced divided by the number of emails delivered. This is due to a permanent error such as an invalid email address or spam filter"
              metric={hardBounceRate}
              isPositive={false}
            />
            <MetricCard
              size="sm"
              label="Soft bounce rate"
              tooltip="Number of individuals whose emails have soft bounced divided by the number of emails delivered. This is a temporary error, such as someone having a full inbox"
              metric={softBounceRate}
              isPositive={false}
            />
          </MetricsContainer>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default StatisticsDemo;
