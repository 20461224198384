import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';

import CheckCircle from '@material-ui/icons/CheckCircle';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import './ExportModal.css';

function ExportModal({ handleClose }) {
  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="subscriber_export__modal"
      centered
    >
      <Modal.Header className="ebx-modal-header modal-header">
        <Modal.Title className="ebx-h2">
          <div className="d-flex align-items-center">
            <CheckCircle className="mr-2 success-check" />
            <span>Subscribers being exported</span>
          </div>
        </Modal.Title>
        <span className="ml-auto">
          <Button variant="link" className="p-0" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </span>
      </Modal.Header>
      <Form noValidate={true}>
        <Modal.Body className="ebx-body-1 ebx-modal-body modal-body">
          A file containing all of your subscribers is being generated and will
          be sent to your email address once ready.
        </Modal.Body>
        <Modal.Footer className="ebx-modal-footer">
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ExportModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ExportModal;
