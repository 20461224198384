import axios, { AxiosError } from 'axios';

import { ARTICLE_ANALYTICS_SORT_FIELDS, SORT_ORDERS } from 'common/enums';
import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { ErrorResponse } from 'common/types';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getEditionURLInsightsProps {
  campaignURN: string;
  sendStartUnixTime: number;
  sendEndUnixTime: number;
  sortField?: keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS;
  sortOrder?: SORT_ORDERS;
}

export default async function getEditionURLInsights({
  campaignURN,
  sendStartUnixTime,
  sendEndUnixTime,
  sortField,
  sortOrder,
}: getEditionURLInsightsProps) {
  const guid = metrics.start('getEditionURLInsights');

  const timeframeQueryParams = `&sendStartUnixTime=${sendStartUnixTime}&sendEndUnixTime=${sendEndUnixTime}`;
  const sortQueryParams =
    sortField && sortOrder ? `&sort=${sortField}&sortOrder=${sortOrder}` : '';

  const url = `${apiBaseURL(
    APIS.OPTIMISATION_SERVICE,
    ''
  )}/editions/urlinsights?campaignURN=${campaignURN}${timeframeQueryParams}${sortQueryParams}`;

  const config = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.M,
    headers: getRequestHeaders(),
  };
  logger.info(
    `API:getEditionURLInsights editions/urlinsights?campaignURN=${campaignURN}`
  );

  try {
    const response = await axios(config);
    metrics.end('getEditionURLInsights', guid);
    return response.data;
  } catch (error) {
    // Rethrow if we get a 403 from NOS
    if ((error as AxiosError<ErrorResponse>).response?.status === 403) {
      throw error;
    }

    metrics.fail('getEditionURLInsights', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getEditionURLInsights',
            args: {
              campaignURN,
              sendStartUnixTime,
              sendEndUnixTime,
              sortField,
              sortOrder,
            },
          })
        : error;
    throw apiError;
  }
}
