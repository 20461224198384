import { Alert, Link } from '@ebx-ui/ebx-ui-component-library-sdk';

export interface domainVerificationIncompleteProps {
  alertDescription: string;
}

function DomainVerificationIncomplete({
  alertDescription,
}: domainVerificationIncompleteProps) {
  return (
    <Alert variant="warning" gap="3">
      <Alert.AlertTitle>Complete domain verification</Alert.AlertTitle>
      <Alert.AlertDescription>
        {alertDescription} Please complete{' '}
        <Link
          fontWeight="bold"
          href="https://echobox.zendesk.com/hc/en-us/articles/6556821742365"
          color="primary.600"
        >
          these steps
        </Link>
        .
      </Alert.AlertDescription>
    </Alert>
  );
}

export default DomainVerificationIncomplete;
