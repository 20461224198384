import React from 'react';

import {
  BoxProps,
  Center,
  Image,
  ImageIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface ThumbnailProps {
  alt: string;
  defaultIcon?: React.ReactElement;
  height: string;
  image?: string | null;
  width: string;
}

function Thumbnail({
  alt,
  defaultIcon = <ImageIcon size={5} color="gray.500" />,
  height,
  image = null,
  width,
}: ThumbnailProps) {
  // Allows you to change the style of the image container
  const thumbnailProps: BoxProps = {
    height,
    width,
    minWidth: width,
    bgColor: 'gray.100',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'lg',
  };

  const defaultThumbnail = <Center {...thumbnailProps}>{defaultIcon}</Center>;

  return image ? (
    <Image
      {...thumbnailProps}
      src={image}
      fallback={defaultThumbnail}
      alt={alt}
      fit="cover"
    />
  ) : (
    defaultThumbnail
  );
}

export default Thumbnail;
