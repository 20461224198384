/* eslint react/forbid-prop-types: "off" */
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
  ArrowDownloadIcon,
  Button,
  ChevronButton,
  Dropdown,
  useDisclosure,
  UserAddIcon,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import AddModal from 'components/campaigns/subscribers/add/AddManuallyModal';
import UploadModal from 'components/campaigns/upload/Upload';

import {
  FRONTEND_UPLOAD_STATES,
  LOCAL_STATE_STORAGE_KEY,
} from 'common/constants';
import { SUBSCRIBER_MODAL_STATES } from 'common/enums';
import useLocalStorage from 'hooks/useLocalStorage';

import './ManageSubscribers.css';

function ManageSubscribers({ campaignInsights, isShutOff }) {
  const history = useHistory();
  const { id: campaignURN } = useParams();

  const handleManageSubscribers = () => {
    history.push(`/campaigns/${campaignURN}/subscribers`);
  };

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  const {
    isOpen: isOpenUploadModal,
    onOpen: onOpenUploadModal,
    onClose: onCloseUploadModal,
  } = useDisclosure();

  const [localState, setLocalState] = useLocalStorage(
    LOCAL_STATE_STORAGE_KEY,
    null
  );

  const toast = useToast();

  const handleError = message => toast({ variant: 'error', title: message });

  const AddDropdown = useCallback(
    () => (
      <Dropdown placement="bottom-end">
        <Dropdown.Button
          as={ChevronButton}
          variant="secondary"
          chevronColor="gray.600"
        >
          Add subscribers
        </Dropdown.Button>
        <Dropdown.List>
          <Dropdown.Item
            icon={<ArrowDownloadIcon size={5} />}
            onClick={() => {
              setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
              onOpenUploadModal();
            }}
          >
            Upload CSV
          </Dropdown.Item>
          <Dropdown.Item
            icon={<UserAddIcon size={5} />}
            onClick={onOpenAddModal}
          >
            Manually
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>
    ),
    [onOpenAddModal, onOpenUploadModal, setLocalState]
  );

  // Display nothing while loading insights
  if (!(isShutOff || campaignInsights)) {
    return null;
  }

  // If the Campaign has no subscribers
  if (
    campaignInsights?.activeSubscribers === 0 &&
    campaignInsights?.unsubscribedSubscribers === 0 &&
    campaignInsights?.hardBouncedSubscribers === 0 &&
    campaignInsights?.complainedSubscribers === 0
  ) {
    return (
      <>
        <AddDropdown />
        {isOpenAddModal && (
          <AddModal campaignURN={campaignURN} handleClose={onCloseAddModal} />
        )}
        {isOpenUploadModal && (
          <UploadModal
            campaignURN={campaignURN}
            handleClose={onCloseUploadModal}
            handleError={handleError}
            hasActiveSubscribers={false}
            localState={localState}
            modalState={SUBSCRIBER_MODAL_STATES.SUBSCRIBE}
            setLocalState={setLocalState}
          />
        )}
      </>
    );
  }

  return (
    <Button
      variant="secondary"
      isDisabled={isShutOff}
      onClick={handleManageSubscribers}
    >
      Manage subscribers
    </Button>
  );
}

ManageSubscribers.propTypes = {
  campaignInsights: PropTypes.shape({
    activeSubscribers: PropTypes.number.isRequired,
    hardBouncedSubscribers: PropTypes.number.isRequired,
    complainedSubscribers: PropTypes.number.isRequired,
    unsubscribedSubscribers: PropTypes.number.isRequired,
    lastRetrievedUnixTime: PropTypes.number,
  }),
  isShutOff: PropTypes.bool.isRequired,
};

ManageSubscribers.defaultProps = {
  campaignInsights: null,
};

export default ManageSubscribers;
