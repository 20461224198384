import { Link, useParams } from 'react-router-dom';

import * as tracker from 'common/tracker';
import SettingsPanel from 'components/settings/campaigns/SettingsPanel';

import '../Campaign.css';

import { APPS_AND_INTEGRATIONS } from 'common/constants';

function AppsIntegrations() {
  const { urn: campaignURN } = useParams();

  const getSettingsPath = subpage =>
    `/settings/campaigns/${campaignURN}/apps-and-integrations/${subpage}`;

  const handleClick = appName => {
    tracker.track({
      eventName: 'Access Integration Settings',
      trackingParams: {
        'App Name': appName,
      },
      options: { campaignURN },
    });
  };

  return (
    <>
      <div className="campaign-title ebx-title-colour">
        Apps &amp; integrations
      </div>
      <div className="d-flex align-items-center flex-wrap">
        <div className="ml-auto">
          <Link className="ebx-h4" to={getSettingsPath('custom-integration')}>
            Can&apos;t find your integration?
          </Link>
        </div>
      </div>
      {APPS_AND_INTEGRATIONS.map(app => (
        <Link
          className="link-unstyled"
          to={getSettingsPath(app.key)}
          key={app.key}
          onClick={() => handleClick(app.name)}
        >
          <SettingsPanel title={app.name} icon={app.key} />
        </Link>
      ))}
    </>
  );
}

export default AppsIntegrations;
