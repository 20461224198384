import { Link, useParams } from 'react-router-dom';

import { Container, Title } from 'components/settings/campaigns/common';
import SettingsPanel from 'components/settings/campaigns/SettingsPanel';
import DoubleOptIn from 'components/settings/campaigns/signup/DoubleOptIn';

function SignupForms() {
  const { urn } = useParams();

  const getSettingsPage = subpage =>
    `/settings/campaigns/${urn}/sign-up-forms/${subpage}`;

  return (
    <div className="settings-schedule w-100">
      <Container>
        <Title>Sign-up forms</Title>
        <DoubleOptIn />
        <Link
          className="link-unstyled"
          to={getSettingsPage('subscription-page')}
        >
          <SettingsPanel
            title="Subscription page"
            description="Use this page to sign up readers to your Campaign"
          />
        </Link>
        <Link className="link-unstyled" to={getSettingsPage('embedded-form')}>
          <SettingsPanel
            title="Embedded form"
            description="Use this snippet to sign up readers using your own form"
          />
        </Link>
      </Container>
    </div>
  );
}

export default SignupForms;
