import React, { useState } from 'react';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

import * as url from 'common/url';

import './AddFeed.css';

interface AddFeedProps {
  inputDisabled: boolean;
  onAddFeed: (newSyndfeed: string) => Promise<void>;
  syndfeeds: ReadonlyArray<{ feedURL: string; feedURN: string }>;
}

function AddFeed({ onAddFeed, inputDisabled, syndfeeds }: AddFeedProps) {
  const [newSyndfeed, setNewSyndfeed] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleAddSyndfeed = async () => {
    if (newSyndfeed === '') {
      setErrorMessage('Please enter an RSS feed URL');
      return;
    }
    if (!url.isValidURL(newSyndfeed)) {
      setErrorMessage('This is not a valid RSS feed or sitemap URL');
      return;
    }
    if (syndfeeds.some(({ feedURL }) => feedURL === newSyndfeed)) {
      setErrorMessage('This RSS feed or sitemap has already been added');
      return;
    }
    setIsAdding(true);
    await onAddFeed(newSyndfeed);
    setIsAdding(false);
    setNewSyndfeed('');
    setErrorMessage('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSyndfeed(event.currentTarget.value);
    setErrorMessage('');
  };

  return (
    <FormGroup className="add-feed-form-group">
      <FormLabel className="ebx-h3 mb-1">Add RSS feed or sitemap</FormLabel>
      <div className="d-flex align-items-center">
        <FormControl
          className={`ebx-body-1 feed-input w-100 mr-3 settings--input--sm ${
            errorMessage ? 'ebx-input-error' : ''
          }`}
          maxLength={120}
          type="text"
          disabled={inputDisabled}
          value={newSyndfeed}
          onChange={handleChange}
        />

        <Button
          className="ebx-btn ebx-btn-secondary"
          disabled={inputDisabled}
          onClick={handleAddSyndfeed}
        >
          {isAdding ? 'Adding...' : 'Add'}
        </Button>
      </div>
      {errorMessage && (
        <div className="d-flex align-items-center ebx-h3 add-feed-error mt-2 mb-2">
          {errorMessage}
        </div>
      )}
    </FormGroup>
  );
}

export default AddFeed;
