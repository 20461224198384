/* eslint @typescript-eslint/no-use-before-define: "off" */

import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as logger from 'common/logger';
import * as maths from 'common/maths';

import { OPTIMAL_SEND_TIME } from 'common/constants';
import { SCHEDULE_EDITION_OPTIONS } from 'common/enums';

export { getTimeSlot, isSendTimeOptimal, updateTimes };

const SECS_IN_HOUR = 3600;

const getTimeSlot = ({
  timeSlotStart,
  sendingTimeslotSizeSeconds,
}: {
  timeSlotStart: number;
  sendingTimeslotSizeSeconds: number;
}) => {
  // Handle sending timeslots that are < 1 hours
  const timeSlotHours = Math.max(
    1,
    Math.floor(sendingTimeslotSizeSeconds / SECS_IN_HOUR)
  );
  const timeSlotEnd = maths.mod(timeSlotStart + timeSlotHours - 1, 24) + 1;
  return [timeSlotStart, timeSlotEnd];
};

const isSendTimeOptimal = ({
  timeSlotStart,
  timeSlotSeconds,
}: {
  timeSlotStart: number;
  timeSlotSeconds: number;
}) =>
  timeSlotStart === OPTIMAL_SEND_TIME.START_TIME &&
  timeSlotSeconds === OPTIMAL_SEND_TIME.SLOT_SIZE_SECONDS;

// Update send time (unix timestamp) and time slot size (seconds) when updating an edition
const updateTimes = (scheduledTime: number, timeSlotSize: number) => {
  const finishSendingTime = scheduledTime + timeSlotSize;

  const scheduleOption = editions.getScheduleEditionOption(
    scheduledTime,
    finishSendingTime,
    true
  );

  const now = datetime.getUnixTimestamp();

  let updatedScheduledTime = scheduledTime;
  let updatedTimeSlotSize = timeSlotSize;

  switch (scheduleOption) {
    case SCHEDULE_EDITION_OPTIONS.NOW:
      updatedScheduledTime = now;
      updatedTimeSlotSize = 0;
      break;
    case SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME:
      break;
    case SCHEDULE_EDITION_OPTIONS.TIME_SLOT:
      if (scheduledTime < now) {
        // Update time slot start if the start time is in the past
        updatedScheduledTime = now;

        // Update time slot size so end time is the same
        updatedTimeSlotSize = finishSendingTime - updatedScheduledTime;
      }
      break;
    case SCHEDULE_EDITION_OPTIONS.OPTIMAL:
      if (finishSendingTime < now) {
        // If a draft edition's Timeslot is entirely in the past, it is displayed to the user as Optimal.
        // Upon approving or saving as draft, we save the schedule of the optimal time range for the current day.
        const optimalRange = editions.getOptimalTimeslot(new Date());
        updatedScheduledTime = optimalRange.optimalStartTime;
        updatedTimeSlotSize =
          optimalRange.optimalEndTime - optimalRange.optimalStartTime;
      }
      break;
    default:
      logger.error(`Unexpected edition display state: ${scheduleOption}`);
  }
  return {
    scheduledUnixTime: updatedScheduledTime,
    timeSlotSize: updatedTimeSlotSize,
  };
};
