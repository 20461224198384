/* eslint react/forbid-prop-types: "off" */
/* eslint react/forbid-prop-types: "off" */
/* eslint func-names: "off" */

import { Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { isUserDoesNotExistError } from 'api/post/postServiceAuth';
import {
  HUB_CHANNELS,
  HUB_EVENTS,
  ROUTE_REDIRECTIONS,
  URN_TYPES,
} from 'common/constants';
import { FORM_VALIDATION_MODES } from 'common/enums';
import { getErrorMessage } from 'common/errors';
import * as tracker from 'common/tracker';
import * as urns from 'common/urns';
import impersonate from 'services/impersonate';

import { STAFF_MODAL_STATES } from 'components/staff/StaffModalWrapper';

import './StaffModal.css';

Yup.addMethod(Yup.object, 'exactlyOneOf', function (message, list) {
  return this.test({
    name: 'exactlyOneOf',
    message,
    exclusive: true,
    test: value => list.filter(f => !!value[f]).length === 1,
  });
});

const StaffOptionsSchema = Yup.object()
  .shape({
    username: Yup.string()
      .trim()
      .email('Invalid email')
      .max(1000, 'Max length is 1000!'),
    campaignGUID: Yup.string().max(1000, 'Max length is 1000!'),
    propertyID: Yup.string().max(1000, 'Max length is 1000!'),
  })
  .exactlyOneOf('Exactly one of the fields is required and no more', [
    'username',
    'campaignGUID',
    'propertyID',
  ]);

function ImpersonateUser({
  show,
  handleClose,
  setCurrentComponent,
  isProcessing,
  setIsProcessing,
  globalInfo,
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(StaffOptionsSchema),
    mode: FORM_VALIDATION_MODES.ONSUBMIT,
    reValidateMode: FORM_VALIDATION_MODES.ONSUBMIT,
  });

  const handleCancel = () => {
    setCurrentComponent(STAFF_MODAL_STATES.STAFF_OPTIONS);
  };

  useEffect(() => {
    setIsProcessing(false);
    setTimeout(() => {
      document.getElementById('username')?.focus();
    }, 100);
  }, [show, setIsProcessing]);

  const handleImpersonate = async form => {
    setIsProcessing(true);
    try {
      if (form.username) {
        await impersonate({ overrideUsername: form.username });
      } else if (form.campaignGUID) {
        await impersonate({
          overrideCampaignURN: urns.toURN(
            form.campaignGUID,
            URN_TYPES.CAMPAIGN
          ),
        });
      } else if (form.propertyID) {
        await impersonate({
          overridePropertyURN: urns.toURN(form.propertyID, URN_TYPES.PROPERTY),
        });
      } else {
        throw new Error('Cannot impersonate - no fields in the form are set');
      }
      const handleImpersonateComplete = async data => {
        const { event } = data.payload;
        if (event === HUB_EVENTS.GLOBAL_INFO.LOADED) {
          await tracker.setUserDetails(globalInfo);
          tracker.track({ eventName: 'Impersonate' });
          Hub.remove(HUB_CHANNELS.GLOBAL_INFO, handleImpersonateComplete);
          handleClose();
          window.location.href = ROUTE_REDIRECTIONS.HOME;
        }
      };
      Hub.listen(HUB_CHANNELS.GLOBAL_INFO, handleImpersonateComplete);
      //
    } catch (error) {
      setIsProcessing(false);
      // If the user doesn't exist
      if (isUserDoesNotExistError(error)) {
        setError('username', {
          type: 'manual',
          message: 'User does not exist',
        });
      } else {
        setError('username', {
          type: 'manual',
          message: getErrorMessage(error),
        });
      }
    }
    // TODO: Needs to validate client emails in the database to correctly impersonate
    // For now it will just close.
    // handleClose();
  };

  return (
    <Form noValidate={true} onSubmit={handleSubmit(handleImpersonate)}>
      <Modal.Body className="ebx-default-modal-body ebx-body-1 ebx-modal-body">
        <div className="mb-3 mt-2">
          <Tooltip label="Check out additional staff options" placement="top">
            <Button
              variant="link"
              onClick={handleCancel}
              className="ebx-h3 p-0"
              type="button"
              disabled={isProcessing}
            >
              Additional staff options
            </Button>
          </Tooltip>
        </div>
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">Client email</FormLabel>
          <FormControl
            name="username"
            id="username"
            {...register('username')}
            required
            className={`ebx-input ebx-body-1 ${
              errors.username ? 'ebx-input-error' : ''
            }`}
            type="text"
            disabled={isProcessing}
            autoComplete="off"
          />
          {errors.username && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span>{errors.username.message}</span>
            </div>
          )}
          <FormLabel className="ebx-h3 mb-1 mt-3">Campaign GUID</FormLabel>
          <FormControl
            name="campaignGUID"
            id="campaignGUID"
            {...register('campaignGUID')}
            required
            className={`ebx-input ebx-body-1 ${
              errors.username ? 'ebx-input-error' : ''
            }`}
            type="text"
            disabled={isProcessing}
            autoComplete="off"
          />
          {errors.campaignGUID && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span>{errors.campaignGUID.message}</span>
            </div>
          )}
          <FormLabel className="ebx-h3 mb-1 mt-3">Property ID</FormLabel>
          <FormControl
            name="propertyID"
            id="propertyID"
            {...register('propertyID')}
            required
            className={`ebx-input ebx-body-1 ${
              errors.username ? 'ebx-input-error' : ''
            }`}
            type="text"
            disabled={isProcessing}
            autoComplete="off"
          />
          {errors.propertyID && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span>{errors.propertyID.message}</span>
            </div>
          )}
          {errors[''] && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span>{errors[''].message}</span>
            </div>
          )}
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="ebx-btn-primary ebx-btn-md ebx-h3"
          disabled={isProcessing}
          type="submit"
        >
          Impersonate User
        </Button>
      </Modal.Footer>
    </Form>
  );
}

ImpersonateUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setCurrentComponent: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  setIsProcessing: PropTypes.func.isRequired,
  globalInfo: PropTypes.shape({
    current: PropTypes.shape({
      campaignURN: PropTypes.string.isRequired,
      propertyURN: PropTypes.string.isRequired,
    }).isRequired,
    properties: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
    user: PropTypes.shape({
      emailAddress: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ImpersonateUser;
