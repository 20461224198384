/* eslint no-use-before-define: "off" */

export { isValidEmail, isValidInteger, isValidIntegerRange, mandatory };

function isValidEmail(email) {
  // This regex was automatically suggested by GitHub Copilot so don't blame me if it doesn't work...
  const re = /^(.+)@(\S+)$/;
  return re.test(String(email).toLowerCase());
}

function isValidInteger(value) {
  if (Number.isNaN(Number(value))) {
    return false;
  }
  if (Number.isNaN(parseInt(value, 10))) {
    return false;
  }
  return true;
}

function isValidIntegerRange(value, min, max) {
  if (Number.isNaN(Number(value))) {
    return false;
  }
  if (Number.isNaN(parseInt(value, 10))) {
    return false;
  }
  if (value < min || value > max) {
    return false;
  }
  return true;
}

function mandatory(parameterName = '') {
  throw new ReferenceError(`Missing parameter ${parameterName}`);
}
