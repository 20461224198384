/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import AddImageModal from 'components/settings/campaigns/branding/general/AddImageModal';
import LogoPreview from 'components/settings/campaigns/branding/general/LogoPreview';
import {
  // BackLink,
  Container,
  Description,
  Title,
} from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import { UI_MESSAGES } from 'common/messages';

function Navbar() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const logoImageURL = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
    'logoImageURL'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns:Branding:General:Fonts:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        // TODO - refetch preview
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const setLogoImageURL = imageURL => {
    if (imageURL) {
      settings.setPropertySettingValue({
        settingType: PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
        settingKey: 'logoImageURL',
        settingValue: imageURL,
      });
    } else {
      settings.setPropertySettingValue({
        settingType: PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
        settingKey: 'logoImageURL',
        settingValue: '',
      });
    }
  };

  const [showLogoModal, setShowLogoModal] = useState(false);

  const handleSave = imageURL => {
    setLogoImageURL(imageURL);
    setShowLogoModal(false);
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Container>
      <Title className="mb-3">
        <span className="mr-3">Navbar</span>
      </Title>
      {logoImageURL && <LogoPreview img={logoImageURL} />}
      <div className="branding-image-actions">
        {!logoImageURL && (
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            disabled={settings.isSaving}
            onClick={() => setShowLogoModal(true)}
          >
            <PlusIcon className="mr-2" fill="#0e1e39" />
            Add logo image
          </Button>
        )}
        {logoImageURL && (
          <>
            <Button
              className="ebx-btn-secondary ebx-btn-md ebx-h3 mr-2"
              disabled={settings.isSaving}
              onClick={() => setShowLogoModal(true)}
            >
              Change image
            </Button>
            <Button
              variant="link"
              className="ebx-h3 image-btn mr-2"
              disabled={settings.isSaving}
              onClick={() => setLogoImageURL('')}
            >
              Remove image
            </Button>
          </>
        )}
      </div>
      {!logoImageURL && (
        <Description className="mb-0">
          {UI_MESSAGES.IMAGE_GUIDELINES}
        </Description>
      )}
      {showLogoModal && (
        <AddImageModal
          imageURL={logoImageURL}
          onClose={() => setShowLogoModal(false)}
          onSave={handleSave}
          title="Add logo image"
          description={UI_MESSAGES.IMAGE_GUIDELINES}
        />
      )}
    </Container>
  );
}

export default Navbar;
