import { Box, Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

interface WrapperProps {
  children?: React.ReactElement;
  header?: string;
  label?: string;
}

function Wrapper({ children, header, label }: WrapperProps) {
  if (header && label) {
    return (
      <Flex direction="column" gap={4}>
        <Heading variant="h4">{header}</Heading>
        <Flex direction="row" alignItems="center">
          <Box w="50%">{label}</Box>
          <Box w="50%">{children}</Box>
        </Flex>
      </Flex>
    );
  }
  if (label) {
    return (
      <Flex direction="row" alignItems="center">
        <Box w="50%">{label}</Box>
        <Box w="50%">{children}</Box>
      </Flex>
    );
  }
  if (children) {
    return children;
  }
  return null;
}

export default Wrapper;
