import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { UI_MESSAGES } from 'common/messages';

import { BackLink, Container, Description, Title } from '../../common';
import AddImageModal from './AddImageModal';
import LogoPreview from './LogoPreview';

function Logo({ setSettingsPanel, settings }) {
  const logoImageURL = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'logoImageURL'
  );

  const setLogoImageURL = imageURL => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'logoImageURL',
      settingValue: imageURL,
    });
  };

  const [showLogoModal, setShowLogoModal] = useState(false);

  const handleSave = imageURL => {
    setLogoImageURL(imageURL);
    setShowLogoModal(false);
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to General Styling" />
      </Button>
      <Title className="mb-3">
        <span className="mr-3">Logo</span>
      </Title>
      {logoImageURL && <LogoPreview img={logoImageURL} />}
      <div className="branding-image-actions">
        {!logoImageURL && (
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            disabled={settings.isSaving}
            onClick={() => setShowLogoModal(true)}
          >
            <PlusIcon className="mr-2" fill="#0e1e39" />
            Add logo image
          </Button>
        )}
        {logoImageURL && (
          <>
            <Button
              className="ebx-btn-secondary ebx-btn-md ebx-h3 mr-2"
              disabled={settings.isSaving}
              onClick={() => setShowLogoModal(true)}
            >
              Change image
            </Button>
            <Button
              variant="link"
              className="ebx-h3 image-btn mr-2"
              disabled={settings.isSaving}
              onClick={() => setLogoImageURL('')}
            >
              Remove image
            </Button>
          </>
        )}
      </div>
      {!logoImageURL && (
        <Description className="mb-0">
          {UI_MESSAGES.IMAGE_GUIDELINES}
        </Description>
      )}
      {showLogoModal && (
        <AddImageModal
          imageURL={logoImageURL}
          onClose={() => setShowLogoModal(false)}
          onSave={handleSave}
          title="Add logo image"
          description={UI_MESSAGES.IMAGE_GUIDELINES}
        />
      )}
    </Container>
  );
}

Logo.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default Logo;
