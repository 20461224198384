/* eslint react-hooks/exhaustive-deps: "off" */

import { Hub } from 'aws-amplify';
import { useContext, useEffect } from 'react';

import GlobalWrapper from 'containers/GlobalWrapper';

import { StoreContext } from 'store/store';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import { getUnixTimestamp } from 'common/datetime';
import * as intervals from 'common/intervals';
import * as logger from 'common/logger';
import * as maths from 'common/maths';
import * as sentry from 'common/sentry';
import { isDefined } from 'common/utility';

import { REFRESH_INTERVALS, REFRESH_MULTIPLIERS } from 'common/config';
import {
  GLOBAL_INFO_STATES,
  HUB_CHANNELS,
  HUB_EVENTS,
  ROUTE_REDIRECTIONS,
  STATUS_PAGE_IDS,
} from 'common/constants';

function StateWrapper() {
  const { actions, state } = useContext(StoreContext);

  const handleGlobalEvent = async data => {
    const { event } = data.payload;

    if (event === HUB_EVENTS.GLOBAL_EVENTS.FORCE_SIGNOUT) {
      await API.authSignOut();
      authentication.signOut();
      if (window.location.pathname !== ROUTE_REDIRECTIONS.SIGNIN) {
        window.location.reload();
      }
    }

    return () => {
      Hub.remove(HUB_CHANNELS.GLOBAL_EVENTS, handleGlobalEvent);
    };
  };
  useEffect(() => {
    Hub.listen(HUB_CHANNELS.GLOBAL_EVENTS, handleGlobalEvent);
  }, []);

  const handleAuthEvent = async ({ payload }) => {
    const { event, user } = payload;
    logger.info(`State:AUTHENTICATION ${event}`);

    if (event === HUB_EVENTS.AUTHENTICATION.SIGN_IN) {
      signIn(user);
    }

    if (event === HUB_EVENTS.AUTHENTICATION.IMPERSONATE) {
      impersonate(user);
    }

    if (event === HUB_EVENTS.AUTHENTICATION.SIGN_OUT) {
      authentication.signOut();
    }

    return () => {
      Hub.remove(HUB_CHANNELS.AUTHENTICATION, handleAuthEvent);
    };
  };
  useEffect(() => {
    Hub.listen(HUB_CHANNELS.AUTHENTICATION, handleAuthEvent);
  }, []);

  const signIn = async ({ userDetail, clientServiceToken }) => {
    /* Store client service token */
    authentication.setClientServiceToken(clientServiceToken);

    /* Initialise global info */
    logger.info('State:onSignIn - initialising global info...');
    actions.refreshGlobalInfo(GLOBAL_INFO_STATES.LOADING, {
      errorEvent: {
        channel: HUB_CHANNELS.GLOBAL_EVENTS,
        payload: { event: HUB_EVENTS.GLOBAL_EVENTS.FORCE_SIGNOUT },
      },
    });
  };

  const impersonate = async clientServiceToken => {
    /* Store client service token */
    authentication.setClientServiceToken(clientServiceToken);

    /* Reload global info */
    logger.info('State:onImpersonate - initialising global info...');
    actions.refreshGlobalInfo(GLOBAL_INFO_STATES.LOADING, {
      errorEvent: {
        channel: HUB_CHANNELS.GLOBAL_EVENTS,
        payload: { event: HUB_EVENTS.GLOBAL_EVENTS.FORCE_SIGNOUT },
      },
    });
  };

  useEffect(() => {
    localStorage.setItem(
      'refreshInterval',
      maths.randomish(REFRESH_INTERVALS.BASE_VALUE, 5 * 1000)
    );
  }, []);

  // TODO - move some or all of this into a custom hook
  useEffect(() => {
    const getStatusPageNotifications = () => {
      const extractLatestNotification = notifications => {
        let notification = null;
        if (
          notifications &&
          notifications.incidents &&
          notifications.incidents.length > 0
        ) {
          const incident = notifications.incidents[0];
          const incidentUpdates = incident.incident_updates;
          const update = incidentUpdates[0];
          if (
            isDefined(update.display_at) &&
            getUnixTimestamp(new Date(update.display_at)) <= getUnixTimestamp()
          ) {
            const { body } = update;
            notification = `${body} For more information please see <span class="text-700">
            <a href="https://echobox-newsletters.statuspage.io/" target="blank" rel="noopener noreferrer">
            echobox-newsletters.statuspage.io</a></span>.`;
          }
        }
        return notification;
      };

      const fetchStatusPageNotifications = async () => {
        logger.info('State:getStatusPageNotifications');

        try {
          const coreNotifications = await API.getStatusPageIncidents({
            pageId: STATUS_PAGE_IDS.CORE,
          });
          const coreNotification = extractLatestNotification(coreNotifications);
          const newslettersNotifications = await API.getStatusPageIncidents({
            pageId: STATUS_PAGE_IDS.NEWSLETTERS,
          });
          const newslettersNotification = extractLatestNotification(
            newslettersNotifications
          );
          actions.setStatusPageNotifications({
            [STATUS_PAGE_IDS.CORE]: coreNotification,
            [STATUS_PAGE_IDS.NEWSLETTERS]: newslettersNotification,
          });
        } catch (error) {
          //
        }
      };

      fetchStatusPageNotifications();
    };

    getStatusPageNotifications();

    intervals.setItem(
      'getStatusPageNotifications',
      getStatusPageNotifications,
      REFRESH_MULTIPLIERS.STATUS_PAGE
    );

    return () => {
      intervals.removeItem('getStatusPageNotifications');
    };
  }, []);

  useEffect(() => {
    if (
      state?.globalInfo &&
      state.globalInfo?.user &&
      state.globalInfo?.current
    ) {
      sentry.setUserDetails(state.globalInfo.user, state.globalInfo.current);
    }
  }, [state.globalInfo]);

  return <GlobalWrapper user={state?.globalInfo?.user} />;
}

export default StateWrapper;
