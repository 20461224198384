/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import SentEmails from 'components/campaigns/editions/SentEmails';
import UnsentEmails from 'components/campaigns/editions/UnsentEmails';
import Error from 'components/campaigns/Error';

import { isNullOrUndefined } from 'common/utility';

import {
  Button,
  EmptyState,
  SettingsCogIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

function Editions({
  campaignDetails,
  campaignInsights,
  draftEmails,
  errorMessage,
  handleAcknowledge,
  handleOpen,
  isInitialised,
  loadEditionData,
  scheduledEmails,
  sentEmails,
}) {
  const { campaignURN } = campaignDetails;
  const history = useHistory();

  const handleAddSubscribers = () => {
    handleAcknowledge();
    handleOpen();
  };

  const handleViewCampaignSettings = () => {
    history.push(`/settings/campaigns/${campaignURN}/content`);
  };

  const emptyCampaignModal = (
    <EmptyState height="408px">
      <EmptyState.EmptyStateIcon>
        <SettingsCogIcon size={5} color="gray.500" />
      </EmptyState.EmptyStateIcon>
      <EmptyState.EmptyStateHeader>
        Set up your Campaign
      </EmptyState.EmptyStateHeader>
      <EmptyState.EmptyStateDescription>
        Add your content feeds so Echobox can analyze and assemble Editions for
        you.
      </EmptyState.EmptyStateDescription>
      <EmptyState.EmptyStateButtons>
        <Button variant="primary" onClick={handleViewCampaignSettings}>
          View content settings
        </Button>
        {campaignInsights?.activeSubscribers < 1 && (
          <Button variant="secondary" onClick={handleAddSubscribers}>
            Add subscribers
          </Button>
        )}
      </EmptyState.EmptyStateButtons>
    </EmptyState>
  );

  if (!isInitialised) {
    return null;
  }

  if (errorMessage) {
    return <Error errorMessage={errorMessage} />;
  }

  if (
    (isNullOrUndefined(sentEmails) &&
      isNullOrUndefined(draftEmails) &&
      isNullOrUndefined(scheduledEmails)) ||
    (sentEmails.length === 0 &&
      draftEmails.length === 0 &&
      scheduledEmails.length === 0)
  ) {
    return emptyCampaignModal;
  }

  return (
    <>
      <UnsentEmails
        editionList={draftEmails}
        loadEditionData={loadEditionData}
        title="Drafts"
      />
      <UnsentEmails
        editionList={scheduledEmails}
        loadEditionData={loadEditionData}
        title="Scheduled"
      />
      {sentEmails.length > 0 && (
        <SentEmails
          editionList={sentEmails}
          loadEditionData={loadEditionData}
        />
      )}
    </>
  );
}

Editions.propTypes = {
  campaignDetails: PropTypes.shape({
    campaignURN: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    // TODO - expand this fully or use TypeScript
  }),
  campaignInsights: PropTypes.shape({
    activeSubscribers: PropTypes.number.isRequired,
    hardBouncedSubscribers: PropTypes.number.isRequired,
    complainedSubscribers: PropTypes.number.isRequired,
    unsubscribedSubscribers: PropTypes.number.isRequired,
  }),
  draftEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  errorMessage: PropTypes.string,
  handleAcknowledge: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  isInitialised: PropTypes.bool.isRequired,
  loadEditionData: PropTypes.func.isRequired,
  scheduledEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  sentEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
};

Editions.defaultProps = {
  campaignDetails: null,
  campaignInsights: null,
  draftEmails: [],
  errorMessage: null,
  scheduledEmails: [],
  sentEmails: [],
};

export default React.memo(Editions);
