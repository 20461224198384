import { Link } from 'react-router-dom';

import SettingsPanel from 'components/settings/campaigns/SettingsPanel';

import '../../Campaign.css';

export default function General() {
  const getSettingsPath = subpage =>
    `/settings/campaigns/allcampaigns/branding/general/${subpage}`;

  return (
    <div className="px-4 py-2">
      <Link className="link-unstyled" to={getSettingsPath('fonts')}>
        <SettingsPanel title="Fonts" />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('accent-color')}>
        <SettingsPanel title="Accent color" />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('navbar')}>
        <SettingsPanel title="Navbar" />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('header')}>
        <SettingsPanel title="Header" />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('sign-up-section')}>
        <SettingsPanel title="Sign up section" />
      </Link>
    </div>
  );
}
