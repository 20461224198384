/* eslint no-shadow: "off" */
/* eslint prefer-regex-literals: "off" */

import CheckCircle from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Consent from 'components/campaigns/subscribers/add/Consent';
import Error from 'components/campaigns/upload/Error';
import ModalWrapper from 'components/campaigns/upload/ModalWrapper';
import Processing from 'components/campaigns/upload/Processing';
import Select from 'components/campaigns/upload/Select';
import Success from 'components/campaigns/upload/Success';
import Uploading from 'components/campaigns/upload/Uploading';
import Warning from 'components/campaigns/upload/Warning';

import * as API from 'api/api';
import * as errors from 'common/errors';
import * as file from 'common/file';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import * as validation from 'common/validation';

import { MAXIMUM_UPLOAD_SIZE, SUPPORTED_FORMATS } from 'common/config';
import { FRONTEND_UPLOAD_STATES } from 'common/constants';
import {
  SERVER_UPLOAD_STATE,
  SERVER_UPLOAD_TYPE,
  SUBSCRIBER_MODAL_STATES,
} from 'common/enums';

import './Upload.css';

function Upload({
  campaignURN,
  handleClose,
  handleError,
  handleSuccess,
  hasActiveSubscribers,
  localState,
  modalState,
  setLocalState,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasCheckedConfirm, setCheckedConfirm] = useState(false);
  const [isFileValid, setFileValid] = useState(false);
  const [rowsInFile, setRowsInFile] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [uploadType, setUploadType] = useState(
    modalState === SUBSCRIBER_MODAL_STATES.SUBSCRIBE
      ? SERVER_UPLOAD_TYPE.APPEND
      : SERVER_UPLOAD_TYPE.UNSUBSCRIBE
  );
  // File upload progress
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleConfirm = event => {
    setCheckedConfirm(event.target.checked);
  };

  const handleContinue = uploadType => {
    if (modalState === SUBSCRIBER_MODAL_STATES.SUBSCRIBE) {
      setUploadType(uploadType);
      setLocalState(FRONTEND_UPLOAD_STATES.VERIFY);
    } else {
      handleUpload();
    }
  };

  const handleLocalError = errorMessage => {
    logger.info('Upload:handleLocalError');
    setFileValid(false);
    setErrorMessage(errorMessage);
    setUploadProgress(0);
    setLocalState(FRONTEND_UPLOAD_STATES.ERROR);
    handleError(errorMessage);
  };

  const handleRemove = () => {
    logger.info('Upload:handleRemove');

    setFileValid(false);
    setCheckedConfirm(false);
    setErrorMessage(null);
    setSelectedFile(null);
    setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
  };

  const handleSelect = acceptedFiles => {
    setFileValid(true);

    const fileMeta = acceptedFiles[0];
    setSelectedFile(fileMeta);
    logger.info(
      `Upload:handleFile - selected ${fileMeta.name} ${fileMeta.type}`
    );

    // Check file size
    if (fileMeta.size > MAXIMUM_UPLOAD_SIZE) {
      setErrorMessage('This file exceeds the maximum size allowed');
      setFileValid(false);
      return;
    }

    // If the file has a type test against supported types
    if (fileMeta.type !== '') {
      if (!(fileMeta.type in SUPPORTED_FORMATS)) {
        setErrorMessage('Incorrect file type! Must be a CSV file.');
        setFileValid(false);
        return;
      }
    }

    // If the file has no type test against RegEx
    if (fileMeta.type === '') {
      const regexCSV = new RegExp('(.*?).(csv)$');
      if (!regexCSV.test(fileMeta.name)) {
        setErrorMessage('Incorrect file type! Must be a CSV file.');
        setFileValid(false);
        return;
      }
    }

    // Check file content
    // setLocalState(FRONTEND_UPLOAD_STATES.VALIDATING);
    // TODO: removed validating state for now as this currently throws the modal into the default error state
    const reader = new FileReader();
    reader.addEventListener('load', event => {
      try {
        const fileContent = event.target.result;
        logger.info(
          `Upload:handleFile - read ${fileMeta.name} ${fileMeta.type}`
        );
        // Check that the file contains enough rows to be valid
        const fileRows = fileContent.split('\n');
        if (fileRows.length < 2) {
          handleLocalError(
            'File must contain header row and at least one data row'
          );
          setFileValid(false);
          return;
        }
        // Check the contents of the header row
        const headerRow = fileRows[0].replace(/\r/g, '');
        const headerValidationResult = file.validateHeaders(headerRow);

        if (!headerValidationResult?.isValid) {
          handleLocalError(headerValidationResult?.validationMessage);
          setFileValid(false);
          return;
        }

        const emailHeaderIndex = headerValidationResult?.emailHeaderIndex;

        const numberOfHeaderFields = file.numberOfFields(headerRow);
        // Check that all other rows contain valid email addresses
        for (let rowNumber = 1; rowNumber < fileRows.length; rowNumber += 1) {
          const thisRow = fileRows[rowNumber].replace(/\r/g, '');
          if (thisRow !== '') {
            const rowFields = file.splitFields(thisRow);
            const numberOfRowFields = file.numberOfFields(thisRow);
            if (numberOfRowFields !== numberOfHeaderFields) {
              handleLocalError(
                `Row ${rowNumber} does not contain the correct number of fields`
              );
              setFileValid(false);
              return;
            }
            if (!validation.isValidEmail(rowFields?.[emailHeaderIndex])) {
              handleLocalError(
                `Row ${rowNumber} does not contain a valid email address (${rowFields?.[emailHeaderIndex]})`
              );
              setFileValid(false);
              return;
            }
            setRowsInFile(prev => prev + 1);
          }
        }
        // All validation passed successfully
        setErrorMessage(null);
        setFileValid(true);
        setLocalState(FRONTEND_UPLOAD_STATES.CONFIRMING);
        setFileContent(fileContent);
      } catch (error) {
        handleLocalError('There was a problem validating the selected file');
        setFileValid(false);
      }
    });
    reader.readAsText(fileMeta);
  };

  const handleLocalSuccess = () => {
    handleSuccess();
    setLocalState(FRONTEND_UPLOAD_STATES.SUCCESS);
  };

  const handleAcknowledge = () => {
    logger.info('Upload:handleAcknowledge');
    setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
  };

  const handleUpload = async () => {
    setLocalState(FRONTEND_UPLOAD_STATES.UPLOADING);
    let uploadURN;
    try {
      const uploadDetails = await API.postSubscriberUploads({
        campaignURN,
        uploadType,
      });
      uploadURN = uploadDetails?.uploadURN;
      await API.putS3Uploads({
        uploadURL: uploadDetails.presignedURL,
        fileContent,
        onProgress,
      });
      if (modalState === SUBSCRIBER_MODAL_STATES.UNSUBSCRIBE) {
        tracker.track({
          eventName: 'Remove Subscribers',
          trackingParams: {
            'Number Removed': rowsInFile,
            'Historical Data Deleted': false,
          },
        });
      } else {
        let eventName;
        if (!hasActiveSubscribers) {
          eventName = 'Upload Subscribers';
        } else {
          eventName =
            uploadType === SERVER_UPLOAD_TYPE.APPEND
              ? 'Append Subscribers'
              : 'Replace Subscribers';
        }
        tracker.track({
          eventName,
          trackingParams: {
            Method: 'Upload CSV',
            'First Upload?': !hasActiveSubscribers,
          },
        });
      }
      setLocalState(FRONTEND_UPLOAD_STATES.SUCCESS);
    } catch (error) {
      try {
        if (uploadURN) {
          await API.putSubscriberUploads({
            campaignURN,
            uploadURN,
            uploadState: SERVER_UPLOAD_STATE.FAILED,
          });
        }
      } catch {
        logger.error({
          event: `Unable to fail upload ${uploadURN}`,
          error,
        });
      }
      handleLocalError(errors.getErrorMessage(error));
    }
  };

  const onProgress = ({ loaded, total, percent }) => {
    setUploadProgress(percent);
  };

  let modalTitle =
    modalState === SUBSCRIBER_MODAL_STATES.SUBSCRIBE
      ? 'Add subscribers'
      : 'Remove subscribers';
  if (localState === FRONTEND_UPLOAD_STATES.SUCCESS) {
    const successMessage =
      modalState === SUBSCRIBER_MODAL_STATES.SUBSCRIBE
        ? 'Successfully uploaded subscribers'
        : 'Successfully removed subscribers';
    modalTitle = (
      <div className="d-flex align-items-center">
        <CheckCircle className="mr-2 success-check" />
        <span>{successMessage}</span>
      </div>
    );
  }

  return (
    <ModalWrapper handleClose={handleClose} title={modalTitle}>
      <ModalPage
        errorMessage={errorMessage}
        handleAcknowledge={handleAcknowledge}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        handleContinue={handleContinue}
        handleRemove={handleRemove}
        handleSelect={handleSelect}
        handleSuccess={handleLocalSuccess}
        handleUpload={handleUpload}
        hasCheckedConfirm={hasCheckedConfirm}
        isFileValid={isFileValid}
        localState={localState}
        modalState={modalState}
        setLocalState={setLocalState}
        selectedFile={selectedFile}
        uploadProgress={uploadProgress}
        hasActiveSubscribers={hasActiveSubscribers}
      />
    </ModalWrapper>
  );
}

Upload.propTypes = {
  campaignURN: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func,
  hasActiveSubscribers: PropTypes.bool.isRequired,
  localState: PropTypes.string.isRequired,
  modalState: PropTypes.string.isRequired,
  setLocalState: PropTypes.func.isRequired,
};

Upload.defaultProps = {
  handleSuccess: () => {},
};

export default Upload;

function ModalPage({
  errorMessage,
  handleAcknowledge,
  handleClose,
  handleConfirm,
  handleRemove,
  handleSelect,
  handleSuccess,
  handleUpload,
  handleContinue,
  isFileValid,
  localState,
  modalState,
  setLocalState,
  selectedFile,
  uploadProgress,
  hasActiveSubscribers,
}) {
  logger.info(`Upload:ModalPage:${localState}`);
  switch (localState) {
    case FRONTEND_UPLOAD_STATES.SELECTING:
    case FRONTEND_UPLOAD_STATES.CONFIRMING:
      return (
        <Select
          canUpload={isFileValid}
          errorMessage={errorMessage}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          handleRemove={handleRemove}
          handleSelect={handleSelect}
          handleContinue={handleContinue}
          modalState={modalState}
          selectedFile={selectedFile}
          hasActiveSubscribers={hasActiveSubscribers}
        />
      );
    case FRONTEND_UPLOAD_STATES.VERIFY:
      return (
        <Consent
          handleClose={handleClose}
          handleBack={() => setLocalState(FRONTEND_UPLOAD_STATES.SELECTING)}
          handleSubmit={handleUpload}
        />
      );
    case FRONTEND_UPLOAD_STATES.UPLOADING:
      return (
        <Uploading
          errorMessage={errorMessage}
          handleClose={handleClose}
          uploadProgress={uploadProgress}
          handleSuccess={handleSuccess}
          selectedFile={selectedFile}
        />
      );
    case FRONTEND_UPLOAD_STATES.PROCESSING:
      return <Processing handleClose={handleClose} />;
    case FRONTEND_UPLOAD_STATES.SUCCESS:
      return <Success handleClose={handleClose} selectedFile={selectedFile} />;
    case FRONTEND_UPLOAD_STATES.WARNING:
      return (
        <Warning handleClose={handleClose} handleOpen={handleAcknowledge} />
      );
    case FRONTEND_UPLOAD_STATES.ERROR:
      return <Error errorMessage={errorMessage} handleClose={handleClose} />;
    default:
      return <Error errorMessage={errorMessage} handleClose={handleClose} />;
  }
}

ModalPage.propTypes = {
  errorMessage: PropTypes.string,
  handleAcknowledge: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  // handleValidate: PropTypes.func.isRequired,
  isFileValid: PropTypes.bool.isRequired,
  localState: PropTypes.string.isRequired,
  modalState: PropTypes.string.isRequired,
  setLocalState: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  // setLocalState: PropTypes.func.isRequired,
  uploadProgress: PropTypes.number.isRequired,
  hasActiveSubscribers: PropTypes.bool.isRequired,
};

ModalPage.defaultProps = {
  errorMessage: null,
  selectedFile: null,
};
