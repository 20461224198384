import {
  Button,
  Modal,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { saveAs } from 'file-saver';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as datetime from 'common/datetime';
import * as file from 'common/file';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';

import useGlobalInfo from 'hooks/useGlobalInfo';

interface ExportPropertyAnalyticsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ExportPropertyAnalyticsModal({
  isOpen,
  onClose,
}: ExportPropertyAnalyticsModalProps) {
  const globalInfo = useGlobalInfo();
  const toast = useToast();

  const handleSubmit = async () => {
    const propertyURN = properties.getCurrentPropertyURN(globalInfo);
    const endUnixTime = datetime.getUnixTimestamp();
    const startUnixTime = endUnixTime - datetime.DAYS(30);
    const campaignList = campaigns.getCurrentPropertyCampaigns(globalInfo);
    if (propertyURN && campaignList) {
      let exportLink;
      const campaignURNToNameMap: { [key: string]: string } = {};
      campaignList.forEach(campaign => {
        campaignURNToNameMap[campaign.campaignURN] = campaign.campaignName;
      });
      try {
        exportLink = await API.postPropertyAnalytics({
          propertyURN,
          startUnixTime,
          endUnixTime,
          campaignURNToNameMap,
        });
        tracker.track({
          eventName: 'Export Property Analytics',
          trackingParams: {
            'Time Range': 'Last 30 days',
          },
        });
      } catch {
        toast({
          variant: 'error',
          title: 'There was a problem requesting your analytics',
        });
      }
      if (exportLink) {
        try {
          const { data } = await API.getDownloadFile({ url: exportLink });
          const suffix = file.getFileSuffix(exportLink, data);
          const filename = `Echobox Email Analytics${suffix}`;
          saveAs(data, filename);
          onClose();
        } catch {
          toast({
            variant: 'error',
            title: 'There was a problem downloading your analytics data',
          });
        }
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered>
      <Modal.Header>
        <Modal.Title>Export analytics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm">
          Download an analytics CSV for all campaigns for the last 30 days. This
          may take a minute...
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExportPropertyAnalyticsModal;
