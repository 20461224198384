/* eslint react-hooks/exhaustive-deps: "off" */

import { Button, Flex, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import AlignPicker from 'components/editor/blocks/attributes/AlignPicker';
import ColorPicker from 'components/editor/blocks/attributes/ColorPicker';
import NumberPicker from 'components/editor/blocks/attributes/NumberPicker';
import PercentagePicker from 'components/editor/blocks/attributes/PercentagePicker';
import RichTextPicker from 'components/editor/blocks/attributes/RichTextPicker';
import Border from 'components/editor/blocks/groups/Border';
import DividerBorder from 'components/editor/blocks/groups/DividerBorder';
import Padding from 'components/editor/blocks/groups/Padding';
import Text from 'components/editor/blocks/groups/Text';

import * as blocks from 'common/blocks';

interface EditCustomBlockProps {
  customBlock: any;
  handleSave: (
    urn: string,
    text: string,
    title: string,
    callback: Function
  ) => void;
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
}

function EditCustomBlock({
  customBlock,
  handleSave,
  isOpen,
  isSaving,
  onClose,
}: EditCustomBlockProps) {
  const [state, setState] = useState(
    JSON.parse(customBlock.textBlockBody.replace(/[\n\r]/g, ''))
  );

  const handleChange = (key: string, value: any) => {
    // @ts-ignore
    setState(old => ({ ...old, [key]: value }));
  };

  const handleSubmit = () => {
    handleSave(
      customBlock.textBlockURN,
      JSON.stringify(state),
      customBlock.textBlockTitle,
      onClose
    );
  };

  const blockURN = blocks.getBlockURN(customBlock.textBlockTitle);
  // @ts-ignore
  const blockSchema = blocks.getBlockSchema(blockURN);
  // @ts-ignore
  const blockType = blocks.getBlockType(blockURN);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title
          color="gray.600"
          display="flex"
          gap={2}
          alignItems="center"
        >
          {blockType}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex direction="column" gap={4}>
          {Object.keys(blockSchema.attributes).map(key => {
            // @ts-ignore
            const attribute = blockSchema.attributes[key];
            switch (attribute.datatype) {
              case 'align':
                return (
                  <AlignPicker
                    {...attribute}
                    key="align"
                    label="Alignment"
                    name="align"
                    onChange={handleChange}
                    options={attribute}
                    value={state.align}
                  />
                );
              case 'color':
                return (
                  <ColorPicker
                    {...attribute}
                    key={key}
                    name={key}
                    onChange={handleChange}
                    value={state[key]}
                  />
                );
              case 'number':
                return (
                  <NumberPicker
                    {...attribute}
                    key={key}
                    name={key}
                    onChange={handleChange}
                    value={state[key]}
                  />
                );
              case 'percentage':
                return (
                  <PercentagePicker
                    {...attribute}
                    key={key}
                    name={key}
                    onChange={handleChange}
                    value={state[key]}
                  />
                );
              case 'richtext':
                return (
                  <RichTextPicker
                    {...attribute}
                    key={key}
                    name={key}
                    onChange={handleChange}
                    value={state[key]}
                  />
                );
              default:
                return null;
            }
          })}
          {Object.keys(blockSchema.groups).map(key => {
            // @ts-ignore
            // const attribute = blockSchema.groups[key];
            switch (key) {
              case 'border':
                return (
                  <Border
                    key="border"
                    onChange={handleChange}
                    // options={attribute}
                    value={state.border}
                  />
                );
              case 'divider-border':
                return (
                  <DividerBorder
                    key="border"
                    onChange={handleChange}
                    // options={attribute}
                    value={{
                      color: state.borderColor,
                      style: state.borderStyle,
                      width: state.borderWidth,
                    }}
                  />
                );
              case 'padding':
                return (
                  <Padding
                    key="padding"
                    onChange={handleChange}
                    value={state.padding}
                  />
                );
              case 'text':
                return (
                  <Text
                    key="text"
                    onChange={handleChange}
                    // options={attribute}
                    value={{
                      align: state.align,
                      color: state.color,
                      fontFamily: state.fontFamily,
                      fontSize: state.fontSize,
                      fontStyle: state.fontStyle,
                      fontWeight: state.fontWeight,
                      letterSpacing: state.letterSpacing,
                      textDecoration: state.textDecoration,
                      textTransform: state.textTransform,
                    }}
                  />
                );
              default:
                return null;
            }
          })}
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} isLoading={isSaving}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          isLoading={isSaving}
          // isDisabled={imageURLError || isFormInvalid()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditCustomBlock;
