/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { Col, FormControl, FormLabel } from 'react-bootstrap';

import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import SettingsPageWrapper from 'components/settings/campaigns/all/SettingsPageWrapper';
import SidebarWrapper from 'components/settings/campaigns/all/SidebarWrapper';
import { Container, Content } from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';

function PreferencesCenterSuccess() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const updatedCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'preferencesUpdatedCopy'
  );
  const cancelledCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'unsubscribedFromAllCopy'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info(
        'AllCampaigns-Preferences-PreferencesCenterSuccess:handleSave'
      );

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleUpdatedCopyChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'preferencesUpdatedCopy',
      settingValue: event.target.value,
    });
  };

  const handleCancelledCopyChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'unsubscribedFromAllCopy',
      settingValue: event.target.value,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <SettingsPageWrapper>
      <SidebarWrapper
        subPath="subscriber-preferences-center"
        backLabel="subscriber preferences center"
      >
        <Container>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mt-3 mb-2">
              Subscription preferences updated
            </FormLabel>
            <FormControl
              name="unsubscribedFromAllCopy"
              id="unsubscribedFromAllCopy"
              required
              className="ebx-input ebx-body-1"
              type="text"
              placeholder="Subscription preferences updated"
              value={updatedCopy}
              onChange={handleUpdatedCopyChange}
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mb-2">
              Unsubscription to all our newsletters successful
            </FormLabel>
            <FormControl
              className="ebx-input ebx-body-1"
              style={{ width: '100%' }}
              as="textarea"
              type="text"
              placeholder="Unsubscription to all our newsletters successful"
              required
              value={cancelledCopy}
              onChange={handleCancelledCopyChange}
              autoComplete="off"
            />
          </div>
        </Container>
      </SidebarWrapper>
      <Col xs={12} md={8}>
        <Content>
          <PreviewWrapper
            propertyURN={propertyURN}
            previewTypes={[PREVIEW_TYPES.MANAGE_PREFERENCES_SUCCESS]}
            settings={settings}
          />
        </Content>
      </Col>
    </SettingsPageWrapper>
  );
}

export default PreferencesCenterSuccess;
