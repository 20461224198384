/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import URLInput from 'components/common/URLInput';
import useSettings from 'hooks/useSettings';
import { FooterCheckboxContext } from './FooterCheckbox';

function FooterPrivacyPolicyInput({ value, onChange }) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const show = useContext(FooterCheckboxContext);
  const settings = useSettings({ storeStateInHook: false });

  const handleBlur = () => {
    setShowErrorMessage(value.length === 0);
  };

  useEffect(() => {
    if (showErrorMessage) {
      settings.setError();
    }
  }, [showErrorMessage]);

  if (!show) {
    return null;
  }

  return (
    <div className="d-flex align-items-center my-2">
      <div className="d-flex align-items-center footer-input w-100">
        <URLInput
          invalidURL={showErrorMessage}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder="https://your-domain.com/privacy-policy"
          value={value}
        />
      </div>
      {showErrorMessage && (
        <div className="footer-error-message ebx-error-colour ebx-body-1">
          Please provide a privacy policy link
        </div>
      )}
    </div>
  );
}

FooterPrivacyPolicyInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FooterPrivacyPolicyInput;
