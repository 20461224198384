import { LOGIN_MESSAGING_URLS } from '@ebx-auth/ebx-clientauth-frontend-sdk';
import * as environment from 'common/environment';

const postMessage = message => {
  const hostType = environment.getHostType();
  const iframe = document.getElementById('login-app');
  iframe.contentWindow.postMessage(message, LOGIN_MESSAGING_URLS[hostType]);
};

// eslint-disable-next-line import/prefer-default-export
export { postMessage };
