import * as API from 'api/api';
import { AxiosError } from 'axios';

import {
  ARTICLE_ANALYTICS_CS_SORT_FIELDS,
  ARTICLE_ANALYTICS_SORT_FIELDS,
  SORT_ORDERS,
} from 'common/enums';
import { ErrorResponse } from 'common/types';

interface getArticleAnalyticsProps {
  campaignURN: string;
  sendStartUnixTime: number;
  sendEndUnixTime: number;
  sortField: keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS;
  sortOrder: SORT_ORDERS;
}

type csArticle = {
  editionSubject: string;
  editionURN: string;
  imageURL: string;
  mediaURN: string;
  sendStartUnixTime: number;
  title: string;
};

type nosArticle = {
  editionURN: string;
  mediaURN: string;
  numDeliveries: number;
  numClicks: number;
  numUniqueClicksByDeliveries: number;
};

const sortByCS = (mediaData: csArticle[], insightsData: nosArticle[]) => {
  const sorted = mediaData.map((cs: csArticle) => {
    const nos = insightsData.find(
      (element: nosArticle) =>
        element.mediaURN === cs.mediaURN && element.editionURN === cs.editionURN
    );
    return {
      editionSubject: cs.editionSubject,
      editionURN: cs.editionURN,
      imageURL: cs.imageURL,
      mediaURN: cs.mediaURN,
      title: cs.title,
      numDeliveries: nos?.numDeliveries ?? 0,
      numClicks: nos?.numClicks ?? 0,
      clickRate:
        nos?.numUniqueClicksByDeliveries &&
        nos?.numDeliveries &&
        nos.numDeliveries > 0
          ? nos.numUniqueClicksByDeliveries / nos.numDeliveries
          : 0,
      sendStartUnixTime: cs.sendStartUnixTime,
    };
  });
  return sorted.filter(article => article.numDeliveries > 0);
};

const sortByNOS = (mediaData: csArticle[], insightsData: nosArticle[]) => {
  const sorted = insightsData.map((nos: nosArticle) => {
    const cs = mediaData.find(
      (element: csArticle) =>
        element.mediaURN === nos.mediaURN &&
        element.editionURN === nos.editionURN
    );
    return cs
      ? {
          editionSubject: cs?.editionSubject ?? '',
          editionURN: nos.editionURN,
          imageURL: cs?.imageURL ?? '',
          mediaURN: nos.mediaURN,
          title: cs?.title ?? '',
          numDeliveries: nos.numDeliveries,
          numClicks: nos.numClicks,
          clickRate:
            nos.numDeliveries > 0
              ? nos.numUniqueClicksByDeliveries / nos.numDeliveries
              : 0,
          sendStartUnixTime: cs?.sendStartUnixTime,
        }
      : null; // Return null if the corresponding CS record cannot be found
  });
  return sorted.filter(article => (article?.numDeliveries ?? 0) > 0);
};

export default async function getArticleAnalytics({
  campaignURN,
  sendStartUnixTime,
  sendEndUnixTime,
  sortField,
  sortOrder,
}: getArticleAnalyticsProps) {
  // Determine whether we are sorting by a CS or NOS field
  const isSortingByCSField = sortField in ARTICLE_ANALYTICS_CS_SORT_FIELDS;

  // Sorting by CS field
  if (isSortingByCSField) {
    const media = await API.getCampaignEditionMedia({
      campaignURN,
      sendStartUnixTime,
      sendEndUnixTime,
      sortField,
      sortOrder,
    });

    let insights;
    try {
      insights = await API.getEditionURLInsights({
        campaignURN,
        sendStartUnixTime,
        sendEndUnixTime,
      });
    } catch (error) {
      if ((error as AxiosError<ErrorResponse>).response?.status === 403) {
        insights = { data: [] };
      } else {
        throw error;
      }
    }

    if (media.data.length > 0 && insights.data.length > 0) {
      return sortByCS(media.data, insights.data);
    }
    return [];
  }

  // Sorting by NOS field
  let insights;
  try {
    insights = await API.getEditionURLInsights({
      campaignURN,
      sendStartUnixTime,
      sendEndUnixTime,
      sortField,
      sortOrder,
    });
  } catch (error) {
    if ((error as AxiosError<ErrorResponse>).response?.status === 403) {
      insights = { data: [] };
    } else {
      throw error;
    }
  }

  const media = await API.getCampaignEditionMedia({
    campaignURN,
    sendStartUnixTime,
    sendEndUnixTime,
  });
  if (media.data.length > 0 && insights.data.length > 0) {
    return sortByNOS(media.data, insights.data);
  }
  return [];
}
