import PropTypes from 'prop-types';

export default function Title({ className, children, ...rest }) {
  return (
    <div
      style={{ marginBottom: '0.5rem' }}
      className={`ebx-h1 ebx-title-colour ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}

Title.defaultProps = {
  className: '',
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
