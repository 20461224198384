import {
  Button,
  ErrorAlertIcon,
  Flex,
  Modal,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface DeleteTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
}

function DeleteTemplate({ isOpen, onClose, name }: DeleteTemplateProps) {
  const toast = useToast();

  const handleDeleteTemplate = () => {
    toast({
      variant: 'success',
      title: `${name} has been deleted`,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="small">
      <Modal.Header>
        <Modal.Title>Delete template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={2}>
          <ErrorAlertIcon size={5} color="red.600" />
          <Text size="sm" color="gray.600">
            {`Are you sure you want to delete: ${name}? Once it's deleted it cannot be restored.`}
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteTemplate}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteTemplate;
