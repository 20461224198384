/* eslint global-require: "off" */
/* eslint no-underscore-dangle: ["error", { "allow": ["__serialized__"] }] */
/* eslint react/jsx-filename-extension: "off" */
/* eslint react/destructuring-assignment: "off" */

import { createRoot } from 'react-dom/client';

import EchoboxAuth, {
  LOGIN_MESSAGING_URLS,
} from '@ebx-auth/ebx-clientauth-frontend-sdk';
import { EbxProvider } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import axios from 'axios';
import queryClient from 'queryClient';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import ApplicationWrapper from 'containers/ApplicationWrapper';

import * as API from 'api/api';
import { addCorrelationId, logRateLimitWarning } from 'api/common';
import * as cookie from 'common/cookie';
import * as environment from 'common/environment';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as url from 'common/url';
import { isNull } from 'common/utility';

import { AWS_AMPLIFY_CONFIG } from 'common/config';
import { EBX_LAST_USED_PRODUCT_KEY, NO_EXPIRY_DATE } from 'common/constants';

// eslint-disable-next-line prettier/prettier
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line prettier/prettier
import 'assets/css/ebx-ui-kit.css';
// eslint-disable-next-line prettier/prettier
import 'assets/css/index.css';

const hostname = environment.getHostname();

/* Amplify configuration */
Amplify.configure(AWS_AMPLIFY_CONFIG);

EchoboxAuth.configure({
  postServiceAuth: API.postServiceAuth,
  logger,
});

axios.interceptors.request.use(async request => {
  const requestWithCST = await EchoboxAuth.checkAndRefreshClientServiceTokens(
    request
  );
  return requestWithCST;
});
axios.interceptors.request.use(async request => {
  const requestWithCognitoTokens =
    await EchoboxAuth.checkAndRefreshCognitoTokens(request);
  return requestWithCognitoTokens;
});

// Add correlation-id header to all requests
axios.interceptors.request.use(request => addCorrelationId(request));
// Log rate limit warnings
axios.interceptors.response.use(response => logRateLimitWarning(response));

const retriedRequests = new Set();

axios.interceptors.response.use(
  response => {
    const requestKey = `${response.config.method}:${response.config.url}`;
    retriedRequests.delete(requestKey);
    return response;
  },
  error => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      const { config } = error;
      const { method, url: apiUrl } = config;
      const requestKey = `${method}:${apiUrl}`;
      if (!retriedRequests.has(requestKey)) {
        retriedRequests.add(requestKey);
        return axios.request(config);
      }
      retriedRequests.delete(requestKey);
    }
    return Promise.reject(error);
  }
);

/* Sentry configuration */
const dsns = {
  live: 'https://fc9b4425679e4243984271dbe114bbf1@o155242.ingest.sentry.io/5652270',
  secure:
    'https://6377dba9bd8242f085bdaa122eab354f@o155242.ingest.sentry.io/5652269',
  stage:
    'https://233f690b52de4d5a8ae00f7ad22fceed@o155242.ingest.sentry.io/5652267',
  // dev:
  //   'https://fc9a31190fda40aab1047cc1b56eb714@o155242.ingest.sentry.io/5652266',
};
const rates = {
  live: 0.01,
  review: 0.01,
  stage: 0.01,
  local: 0.01,
};
const dsn = dsns[hostname];

if (typeof Sentry !== 'undefined' && typeof dsn !== 'undefined') {
  Sentry.init({
    dsn,
    environment: window.location.host.split('.')[0],
    integrations: [
      new BrowserTracing({
        beforeNavigate: context => ({
          ...context,
          name: window.location.pathname.replace(/\d+/g, '{id}'),
        }),
        // Do not create tracing spans
        shouldCreateSpanForRequest: () => false,
        tracingOrigins: [
          'api-stage.echobox.com',
          'api.echobox.com',
          'campaignapi-stage.service.echobox.com',
          'campaignapi.service.echobox.com',
          'coreapi-stage.service.echobox.com',
          'coreapi.service.echobox.com',
        ],
      }),
    ],
    tracesSampleRate: rates[hostname],
    beforeSend(event, hint) {
      // Ignore non-error promise rejections
      if (
        event &&
        event.__serialized__?.error &&
        event.__serialized__?.status
      ) {
        return null;
      }
      // Handle non-error exceptions
      const ignoredError = errors.isIgnoredError(event, hint);
      if (
        event &&
        event.message &&
        event.message.startsWith('Non-Error exception captured') &&
        hint &&
        hint.originalException?.error &&
        !ignoredError
      ) {
        Sentry.withScope(scope => {
          scope.setExtra('nonErrorException', true);
          Sentry.captureException(hint.originalException.error);
        });
        return null;
      }
      if (!ignoredError) {
        return event;
      }
      return null;
    },
  });
}

// Set the session storage if the the user is online
window.addEventListener('online', () => {
  sessionStorage.setItem('isOnline', true);
});

// Set the session storage if the the user is offline
window.addEventListener('offline', () => {
  sessionStorage.setItem('isOnline', false);
});

// Set the version in the window sesion
if (isNull(sessionStorage.getItem('currentVersion'))) {
  API.getVersion()
    .then(version => {
      sessionStorage.setItem('currentVersion', version);
    })
    .catch(() => {});
}

// Set the current session as online if this script was able to run
sessionStorage.setItem('isOnline', true);

// Start the mocking conditionally
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('api/mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

// Set up EBX Last Used Product Cookie
cookie.setCookieValue(
  EBX_LAST_USED_PRODUCT_KEY,
  `${window.location.protocol}//${window.location.host}`,
  {
    expires: NO_EXPIRY_DATE,
    domain: url.getTLD(window.location.hostname),
  }
);

// update iframe src to point to correct login app based on environment
const hostType = environment.getHostType();
const iframe = document.getElementById('login-app');
iframe.src = LOGIN_MESSAGING_URLS[hostType];

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <EbxProvider>
      <Router>
        <ApplicationWrapper />
      </Router>
    </EbxProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
