/* eslint @typescript-eslint/no-use-before-define: "off" */

import * as string from 'common/string';

import { Metrics, NOSCampaignInsights, SubscriberInsights } from './types';

export {
  calculateClickRateMetric,
  calculateDeliveriesMetric,
  calculateHardBounceRateMetric,
  calculateOpenRateMetric,
  calculateSoftBounceRateMetric,
  calculateSubscriberMetric,
  calculateUnsubscribeRateMetric,
};

function calculateClickRateMetric(
  currentNOSInsights: NOSCampaignInsights | null,
  referenceNOSInsights: NOSCampaignInsights | null
) {
  const clickRateValue = currentNOSInsights
    ? (currentNOSInsights.numUniqueClicks / currentNOSInsights.numDeliveries) *
      100
    : null;
  const referenceClickRateValue = referenceNOSInsights
    ? (referenceNOSInsights.numUniqueClicks /
        referenceNOSInsights.numDeliveries) *
      100
    : null;
  const clickRateChange =
    clickRateValue && referenceClickRateValue
      ? (clickRateValue / referenceClickRateValue - 1) * 100
      : null;
  const clickRateMetric: Metrics = {
    value: clickRateValue,
    change: clickRateChange,
    moreInfoName: string.pluralise(
      'click',
      currentNOSInsights?.numUniqueClicks
    ),
    moreInfoValue: currentNOSInsights?.numUniqueClicks,
    isPercentage: true,
  };
  return clickRateMetric;
}

function calculateDeliveriesMetric(
  currentNOSInsights: NOSCampaignInsights | null,
  referenceNOSInsights: NOSCampaignInsights | null,
  numEditions: number | null
) {
  const deliveryValue = currentNOSInsights
    ? currentNOSInsights.numDeliveries
    : null;
  const referenceDeliveryValue = referenceNOSInsights
    ? referenceNOSInsights.numDeliveries
    : null;
  const deliveryRateChange =
    deliveryValue && referenceDeliveryValue
      ? (deliveryValue / referenceDeliveryValue - 1) * 100
      : null;
  const deliveryMetric: Metrics = {
    value: deliveryValue,
    change: deliveryRateChange,
    moreInfoName: `${string.pluralise('Edition', numEditions)} sent`,
    moreInfoValue: numEditions,
    isPercentage: false,
  };
  return deliveryMetric;
}

function calculateHardBounceRateMetric(
  currentNOSInsights: NOSCampaignInsights | null,
  referenceNOSInsights: NOSCampaignInsights | null
) {
  let hardBounceValue = null;
  let referenceHardBounceValue = null;

  if (currentNOSInsights) {
    const currentTotalSends =
      currentNOSInsights.numDeliveries +
      currentNOSInsights.numHardBounces +
      currentNOSInsights.numSoftBounces;

    hardBounceValue =
      (currentNOSInsights.numHardBounces / currentTotalSends) * 100;

    // Only calculate reference hard bounce rate if we have current rate
    if (referenceNOSInsights) {
      const referenceTotalSends =
        referenceNOSInsights.numDeliveries +
        referenceNOSInsights.numHardBounces +
        referenceNOSInsights.numSoftBounces;

      referenceHardBounceValue =
        (referenceNOSInsights.numHardBounces / referenceTotalSends) * 100;
    }
  }

  const hardBounceRateChange =
    hardBounceValue !== null && referenceHardBounceValue !== null
      ? (hardBounceValue / referenceHardBounceValue - 1) * 100
      : null;

  const hardBounceRateMetric: Metrics = {
    value: hardBounceValue,
    change: hardBounceRateChange,
    moreInfoName: string.pluralise(
      'hard bounce',
      currentNOSInsights?.numHardBounces
    ),
    moreInfoValue: currentNOSInsights?.numHardBounces,
    isPercentage: true,
  };
  return hardBounceRateMetric;
}

function calculateOpenRateMetric(
  currentNOSInsights: NOSCampaignInsights | null,
  referenceNOSInsights: NOSCampaignInsights | null
) {
  const openRateValue = currentNOSInsights
    ? (currentNOSInsights.numUniqueOpens / currentNOSInsights.numDeliveries) *
      100
    : null;
  const referenceOpenRateValue = referenceNOSInsights
    ? (referenceNOSInsights.numUniqueOpens /
        referenceNOSInsights.numDeliveries) *
      100
    : null;
  const openRateChange =
    openRateValue && referenceOpenRateValue
      ? (openRateValue / referenceOpenRateValue - 1) * 100
      : null;
  const openRateMetric: Metrics = {
    value: openRateValue,
    change: openRateChange,
    moreInfoName: string.pluralise('open', currentNOSInsights?.numUniqueOpens),
    moreInfoValue: currentNOSInsights?.numUniqueOpens,
    isPercentage: true,
  };
  return openRateMetric;
}

function calculateSoftBounceRateMetric(
  currentNOSInsights: NOSCampaignInsights | null,
  referenceNOSInsights: NOSCampaignInsights | null
) {
  let softBounceValue = null;
  let referenceSoftBounceValue = null;

  if (currentNOSInsights) {
    const currentTotalSends =
      currentNOSInsights.numDeliveries +
      currentNOSInsights.numHardBounces +
      currentNOSInsights.numSoftBounces;

    softBounceValue =
      (currentNOSInsights.numSoftBounces / currentTotalSends) * 100;

    // Only calculate reference hard bounce rate if we have current rate
    if (referenceNOSInsights) {
      const referenceTotalSends =
        referenceNOSInsights.numDeliveries +
        referenceNOSInsights.numHardBounces +
        referenceNOSInsights.numSoftBounces;

      referenceSoftBounceValue =
        (referenceNOSInsights.numSoftBounces / referenceTotalSends) * 100;
    }
  }
  const softBounceRateChange =
    softBounceValue !== null && referenceSoftBounceValue !== null
      ? (softBounceValue / referenceSoftBounceValue - 1) * 100
      : null;
  const softBounceRateMetric: Metrics = {
    value: softBounceValue,
    change: softBounceRateChange,
    moreInfoName: string.pluralise(
      'soft bounce',
      currentNOSInsights?.numSoftBounces
    ),
    moreInfoValue: currentNOSInsights?.numSoftBounces,
    isPercentage: true,
  };
  return softBounceRateMetric;
}

function calculateSubscriberMetric(
  currentSubscriberInsights: SubscriberInsights | null
) {
  const subscriberValue = currentSubscriberInsights?.activeSubscribers;
  const subscriberRateChange =
    currentSubscriberInsights &&
    currentSubscriberInsights.newSubscribers !== null &&
    currentSubscriberInsights.unsubscribedSubscribers !== null
      ? ((currentSubscriberInsights.newSubscribers -
          currentSubscriberInsights.unsubscribedSubscribers) /
          currentSubscriberInsights.activeSubscribers) *
        100
      : null;

  const subscriberMetric: Metrics = {
    value: subscriberValue ?? null,
    change: subscriberRateChange,
    moreInfoName: string.pluralise(
      'new subscriber',
      currentSubscriberInsights?.newSubscribers
    ),
    moreInfoValue: currentSubscriberInsights?.newSubscribers,
    isPercentage: false,
  };
  return subscriberMetric;
}

function calculateUnsubscribeRateMetric(
  currentNOSInsights: NOSCampaignInsights | null,
  referenceNOSInsights: NOSCampaignInsights | null,
  currentSubscriberInsights: SubscriberInsights | null,
  referenceSubscriberInsights: SubscriberInsights | null
) {
  const unsubscribeRateValue =
    currentSubscriberInsights && currentNOSInsights
      ? (currentSubscriberInsights.unsubscribedSubscribers /
          currentNOSInsights.numDeliveries) *
        100
      : null;
  const referenceUnsubscribeRateValue =
    referenceSubscriberInsights && referenceNOSInsights
      ? (referenceSubscriberInsights.unsubscribedSubscribers /
          referenceNOSInsights.numDeliveries) *
        100
      : null;
  const unsubscribeRateChange =
    unsubscribeRateValue !== null && referenceUnsubscribeRateValue !== null
      ? (unsubscribeRateValue / referenceUnsubscribeRateValue - 1) * 100
      : null;
  const unsubscribeRateMetric: Metrics = {
    value: unsubscribeRateValue,
    change: unsubscribeRateChange,
    moreInfoName: string.pluralise(
      'unsubscribe',
      currentSubscriberInsights?.unsubscribedSubscribers
    ),
    moreInfoValue: currentSubscriberInsights?.unsubscribedSubscribers,
    isPercentage: true,
  };
  return unsubscribeRateMetric;
}
