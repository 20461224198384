import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import { Flex, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import URLInput from 'components/common/URLInput';
import { FooterCheckboxContext } from 'components/settings/campaigns/content/FooterCheckbox';

import useSettings from 'hooks/useSettings';

function FooterPrivacyPolicyInput({ value, validationError, onChange }) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const show = useContext(FooterCheckboxContext);
  const settings = useSettings({ storeStateInHook: false });

  const handleBlur = () => {
    if (value.length === 0) setShowErrorMessage(true);
    else {
      setShowErrorMessage(false);
    }
  };

  useEffect(() => {
    if (showErrorMessage) settings.setError();
  }, [showErrorMessage, settings]);

  if (!show) {
    return null;
  }

  return (
    <Flex alignItems="center" gap={2} mt={3} flexWrap="wrap">
      <Flex alignItems="center" gap={2} grow={1}>
        <URLInput
          invalidURL={showErrorMessage}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder="https://your-domain.com/privacy-policy"
          value={value}
        />
      </Flex>
      {(validationError || showErrorMessage) && (
        <Text size="sm" color="red.600">
          {validationError ?? 'Please provide a privacy policy link'}
        </Text>
      )}
    </Flex>
  );
}

FooterPrivacyPolicyInput.propTypes = {
  value: PropTypes.string.isRequired,
  validationError: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FooterPrivacyPolicyInput;
