import {
  Button,
  CheckCircleIcon,
  Flex,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface SuccessProps {
  handleClose: () => void;
}

function Success({ handleClose }: SuccessProps) {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Successfully submitted Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex align="center" gap={1}>
          <CheckCircleIcon color="green.600" />
          <Text size="sm" color="gray.600">
            Your campaign has been created and is now ready for use!
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}

export default Success;
