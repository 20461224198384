import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface DeleteCampaignArgs {
  campaignURN: string;
}

export default async function deletePromotionBlock({
  campaignURN,
}: DeleteCampaignArgs) {
  if (isUndefined(campaignURN)) {
    mandatory('campaignURN');
  }

  const guid = metrics.start('deleteCampaign');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/campaigns/${campaignURN}`;

  const config = {
    url,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:deleteCampaign /campaigns/${campaignURN}`);

  try {
    const response = await axios(config);
    metrics.end('deleteCampaign', guid);
    return response.data;
  } catch (error) {
    metrics.fail('deleteCampaign', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/deleteCampaign',
            args: { campaignURN },
          })
        : error;
    throw apiError;
  }
}
