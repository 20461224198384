import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { BackLink, Container, Description, Input, Title } from '../../common';

function Style({ setSettingsPanel, settings }) {
  const HEX_MAX_LENGTH = 6;

  const accentColour = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'accentColour'
  );

  const isInvalidColour = colour =>
    -Array.from(colour.toLowerCase()).filter(
      char => '01234567890abcdef'.indexOf(char) === -1
    ).length > 0;

  const handleColourChange = event => {
    event.preventDefault();
    const colour = event.target.value;

    // Remove first character if it is # and trim to 6 char. max
    const trimmedColour = colour.replace(/^#/, '').substring(0, HEX_MAX_LENGTH);

    if (!isInvalidColour(trimmedColour)) {
      settings.setCampaignSettingValue({
        settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
        settingKey: 'accentColour',
        settingValue: trimmedColour,
      });
    }
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to General Styling" />
      </Button>
      <Title className="mb-3">Styling</Title>
      <Description className="mb-3">Select your brand colour</Description>
      <div className="d-flex align-items-center">
        <div className="position-relative">
          <span className="mr-2 input-hash">#</span>
          <Input
            className="accent-color-input"
            value={accentColour}
            onChange={handleColourChange}
          />
          <div
            className="accent-color"
            style={{ backgroundColor: `#${accentColour}` }}
          />
        </div>
      </div>
    </Container>
  );
}

Style.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default Style;
