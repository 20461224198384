import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from 'components/campaigns/subscribers/management/Header';
import SubscriberList, {
  SortableColumn,
  SUBSCRIBER_MANAGEMENT_COLUMNS,
} from 'components/campaigns/subscribers/management/SubscriberList';

import {
  ENGAGEMENT_RATES,
  SORT_ORDERS,
  SUBSCRIBER_MANAGEMENT_SORT_FIELDS,
} from 'common/enums';
import { Subscriber, SubscriberInsights } from 'common/types';

const getTestData = (
  sortField: string,
  sortOrder: string,
  searchString: string
) => {
  const subscribers = [
    {
      email: 'aloremipsum@echobox.com',
      firstName: 'Tony',
      lastName: 'Stark',
      addedUnixTime: 1641038400,
      engagementRate: 0.18,
      engagementLevel: ENGAGEMENT_RATES.MEDIUM,
    },
    {
      email: 'bloremipsum@echobox.com',
      firstName: 'Bruce',
      lastName: 'Banner',
      addedUnixTime: 1643716800,
      engagementRate: 0.72,
      engagementLevel: ENGAGEMENT_RATES.HIGH,
    },
    {
      email: 'cloremipsum@echobox.com',
      firstName: 'Natasha',
      lastName: 'Romanoff',
      addedUnixTime: 1646136000,
      engagementRate: null,
      engagementLevel: ENGAGEMENT_RATES.NO_DATA,
    },
    {
      email: 'dloremipsum@echobox.com',
      firstName: 'Steve',
      lastName: 'Rogers',
      addedUnixTime: 1648814400,
      engagementRate: null,
      engagementLevel: ENGAGEMENT_RATES.COLLECTING,
    },
    {
      email: 'eloremipsum@echobox.com',
      firstName: 'Thor',
      lastName: 'Odinson',
      addedUnixTime: 1648814400,
      engagementRate: 0.18,
      engagementLevel: ENGAGEMENT_RATES.MEDIUM,
    },
    {
      email: 'floremipsum@echobox.com',
      firstName: 'Clint',
      lastName: 'Barton',
      addedUnixTime: 1648814400,
      engagementRate: 0.01,
      engagementLevel: ENGAGEMENT_RATES.LOW,
    },
    {
      email: 'gloremipsum@echobox.com',
      firstName: 'Peter',
      lastName: 'Parker',
      addedUnixTime: 1648814400,
      engagementRate: 0.32,
      engagementLevel: ENGAGEMENT_RATES.MEDIUM,
    },
    {
      email: 'hloremipsum@echobox.com',
      firstName: 'Wanda',
      lastName: 'Maximoff',
      addedUnixTime: 1648814400,
      engagementRate: 0.85,
      engagementLevel: ENGAGEMENT_RATES.HIGH,
    },
  ] as Subscriber[];
  // @ts-ignore
  const sortAscending = sort => (a, b) =>
    typeof a[sort] === 'string'
      ? a[sort].localeCompare(b[sort])
      : a[sort] - b[sort];
  // @ts-ignore
  const sortDescending = sort => (a, b) =>
    typeof a[sort] === 'string'
      ? b[sort].localeCompare(a[sort])
      : b[sort] - a[sort];
  return subscribers
    .sort(
      sortOrder === 'ASC' ? sortAscending(sortField) : sortDescending(sortField)
    )
    .filter(subscriber => subscriber.email.includes(searchString));
};

function SubscriberManagementDemo() {
  const [sortBy, setSortBy] = useState<
    keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS
  >(SUBSCRIBER_MANAGEMENT_SORT_FIELDS.addedUnixTime);
  const [sortOrder, setSortOrder] = useState(SORT_ORDERS.DESC);
  const [searchString, setSearchString] = useState('');

  const { id: campaignURN } = useParams<{ id: string }>();

  const [subscriberList, setSubscriberList] = useState([] as Subscriber[]);

  useEffect(() => {
    setSubscriberList(getTestData(sortBy, sortOrder, searchString));
  }, [campaignURN, sortBy, sortOrder, searchString]);

  const handleSort = (
    field: keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS
  ) => {
    if (sortBy === field) {
      setSortOrder(prevOrder =>
        prevOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC
      );
    } else {
      setSortBy(field);
      const column = SUBSCRIBER_MANAGEMENT_COLUMNS.find(
        col => col.name === field
      ) as SortableColumn;
      setSortOrder(column?.defaultSortOrder ?? SORT_ORDERS.ASC);
    }
  };

  return (
    <Flex direction="column" gap={4}>
      <Header
        campaignInsights={{ activeSubscribers: 8 } as SubscriberInsights}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <SubscriberList
        campaignURN={campaignURN}
        subscriberList={subscriberList}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSort={handleSort}
        handleUnsubscribe={() => {}}
      />
    </Flex>
  );
}

export default SubscriberManagementDemo;
