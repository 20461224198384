import axios from 'axios';

import { API_TIMEOUTS } from 'api/settings';
import { GOOGLE_FONTS_API_KEY } from './constants';

const FONT_CACHE = {};

export { getGoogleFonts, loadFont };

async function getGoogleFonts() {
  const config = {
    url: `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_FONTS_API_KEY}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
  };

  const response = await axios(config);
  return response?.data?.items;
}

function loadFont(family) {
  if (!family || FONT_CACHE[family]) {
    return;
  }
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.type = 'text/css';
  const css = `@import url('https://fonts.googleapis.com/css?family=${family.replace(
    /\s/gi,
    '+'
  )}');`;
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);
  FONT_CACHE[family] = true;
}
