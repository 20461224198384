import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * getURLInfo
 * @param {{ url: string }} args
 * @returns {Promise<object>}
 */
export default async function getURLInfo({ url = mandatory('url') } = {}) {
  const guid = metrics.start('getURLInfo');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/urlinfo?pageURL=${url}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getURLInfo /urlinfo?pageURL=${url}`);

  try {
    const response = await axios(config);
    metrics.end('getURLInfo', guid);
    return response.data;
  } catch (error) {
    metrics.fail('getURLInfo', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getURLInfo',
      args: { url },
    });
    throw apiError;
  }
}
