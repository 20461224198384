/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import { /* useContext, */ useEffect } from 'react';

import Routing from 'containers/Routing';

// import { StoreContext } from 'store/store';

// import useSignedIn from 'hooks/useSignedIn';

import * as intervals from 'common/intervals';
import * as logger from 'common/logger';
import * as utility from 'common/utility';
import * as zendesk from 'common/zendesk';
import useGlobalInfo from 'hooks/useGlobalInfo';

// import { GLOBAL_INFO_STATES } from 'common/constants';
import { REFRESH_MULTIPLIERS } from 'common/config';

import './GlobalWrapper.css';

function GlobalWrapper({ user }) {
  const globalInfo = useGlobalInfo();

  // const { actions } = useContext(StoreContext);

  useEffect(() => {
    if (user && utility.isUndefined(window.beamer_config)) {
      logger.info(`GlobalWrapper - loading Beamer config`);
      window.beamer_config = {
        product_id: 'dzDyqSsc10392',
        selector: '#beamerButton',
        display: 'popup',
        filter_by_url: true,
        bounce: false,
        user_firstname: globalInfo.user.name,
        user_email: globalInfo.user.emailAddress,
        user_id: globalInfo.user.userId,
      };
      if (!document.getElementById('#beamerPopperScript')) {
        const body = document.getElementsByTagName('body')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'beamerPopperScript';
        script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
        script.defer = true;
        body.appendChild(script);
      }
    }
  }, [user]);

  useEffect(() => {
    const refreshGlobalInfo = () => {
      // logger.info('Global:onInterval - refreshing global info...');
      // actions.refreshGlobalInfo(GLOBAL_INFO_STATES.REFRESHING);
    };

    if (user) {
      intervals.setItem(
        'refreshGlobalInfo',
        refreshGlobalInfo,
        REFRESH_MULTIPLIERS.GLOBAL_INFO
      );
    }

    return () => {
      intervals.removeItem('refreshGlobalInfo');
    };
  }, [user]);

  /* Show Zendesk widget when user details are loaded */
  useEffect(() => {
    if (user) {
      zendesk.showWidget(user);
    }
  }, [user]);

  return <Routing />;
}

// TODO - implement property using TypeScript
GlobalWrapper.propTypes = {
  user: PropTypes.shape({
    emailAddress: PropTypes.string,
    keyPrefix: PropTypes.string,
    name: PropTypes.string,
  }),
};

GlobalWrapper.defaultProps = {
  user: null,
};

export default GlobalWrapper;
