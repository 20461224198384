/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';

import Loading from 'components/settings/Loading';
import AddFeed from 'components/settings/property/AddFeed';
import ContentSourceRadio from 'components/settings/property/ContentSourceRadio';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as url from 'common/url';
import { StoreContext } from 'store/store';
// import * as zendesk from 'common/zendesk';

// import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import {
  CORE_PROPERTY_SETTING_TYPES,
  GLOBAL_INFO_STATES,
} from 'common/constants';
import useSettings from 'hooks/useSettings';
import './Feeds.css';

function Feeds() {
  const { actions, state } = useContext(StoreContext);
  const { globalInfo } = state;

  const [syndfeeds, setSyndfeeds] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowContentFromAnyDomain, setIsAllowContentFromAnyDomain] =
    useState('false');
  const allowContentFromAnyDomain = useRef(null);
  const isAllowContentInputDisabled = syndfeeds.length === 0;
  const { isSaving, setChanged, setOnSave, setDone } = useSettings({
    storeStateInHook: false,
  });

  const toast = useToast();

  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const { propertyRootURL } = properties.getCurrentProperty(globalInfo);

  const getCampaignsContainingFeed = syndFeedURN => {
    // TEC-17714 - move this into the common/campaigns library at some point
    const currentProperty = properties.getProperty(propertyURN, globalInfo);
    const campaignList = new Set();
    Object.keys(currentProperty.campaigns).forEach(campaignURN => {
      const campaignDetails = campaigns.getCampaign(campaignURN, globalInfo);
      campaignDetails.sections.forEach(section => {
        section.syndFeeds.forEach(feed => {
          if (feed.syndFeedURN === syndFeedURN) {
            campaignList.add(campaignURN);
          }
        });
      });
    });
    return Array.from(campaignList);
  };

  const getCampaignAfterDeletingFeed = (campaignURN, syndFeedURN) => {
    // TEC-17714 - move this into the common/campaigns library at some point
    const campaignDetails = campaigns.getCampaign(campaignURN, globalInfo);
    const sectionDetails = campaignDetails.sections.map(section => ({
      ...section,
      syndFeeds: section.syndFeeds.filter(
        feed => feed.syndFeedURN !== syndFeedURN
      ),
    }));
    const updatedCampaign = {
      ...campaignDetails,
      sections: sectionDetails,
    };
    return updatedCampaign;
  };

  const getTrackingParams = (
    { rssFeeds = syndfeeds, anyDomain = isAllowContentFromAnyDomain } = {
      rssReeds: syndfeeds,
      anyDomain: isAllowContentFromAnyDomain,
    }
  ) => ({
    rssFeeds: rssFeeds.map(feed => feed.syndFeedURL).join(' - '),
    noOfFeeds: rssFeeds.length,
    anyDomain,
  });

  const sendTrackingEvent = (trackingParamsBefore, trackingParamsAfter) => {
    const trackingParams = {
      'RSS Feeds and Sitemaps': trackingParamsAfter.rssFeeds,
      'RSS Feeds and Sitemaps (before)': trackingParamsBefore.rssFeeds,
      'Number of Feeds': trackingParamsAfter.noOfFeeds,
      'Number of Feeds (before)': trackingParamsBefore.noOfFeeds,
      'Allow Content from any Domain?': trackingParamsAfter.anyDomain,
      'Allow Content from any Domain? (before)': trackingParamsBefore.anyDomain,
    };
    tracker.track({
      eventName: 'Edit RSS Feeds and Sitemaps',
      trackingParams,
      options: {
        includeCampaignDetails: false,
      },
    });
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {
        const syndfeedResponse = await API.getSyndfeeds({ propertyURN });
        const contentSettingsResponse = await API.getPropertySettingsByType({
          propertyURN,
          settingTypeId:
            CORE_PROPERTY_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS,
        });
        if (contentSettingsResponse) {
          setIsAllowContentFromAnyDomain(
            contentSettingsResponse?.[0].enabled.toString()
          );
          allowContentFromAnyDomain.current =
            contentSettingsResponse?.[0].enabled;
        }
        setSyndfeeds(syndfeedResponse?.syndFeeds);
      } catch (error) {
        logger.error(`Property Settings: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [globalInfo, propertyURN]);

  const handleDelete = async urn => {
    try {
      setIsDeleting(true);
      const trackingParamsBefore = getTrackingParams();
      // Delete the feed at Property level
      await API.deleteSyndfeed({ propertyURN, syndfeedURN: urn });
      // Identify Campaigns which use the deleted feed
      const campaignList = getCampaignsContainingFeed(urn);
      // Update those campaigns, removing the feed in question wherever it appears
      const apiActions = [];
      for (
        let campaignIndex = 0;
        campaignIndex < campaignList.length;
        campaignIndex += 1
      ) {
        const campaignURN = campaignList[campaignIndex];
        const updatedCampaign = getCampaignAfterDeletingFeed(campaignURN, urn);
        apiActions.push(
          API.putCampaigns({
            campaignURN,
            campaignDetails: campaigns.prepareForSave(updatedCampaign),
          })
        );
      }
      await Promise.all(apiActions);
      actions.refreshGlobalInfo(GLOBAL_INFO_STATES.REFRESHING);
      // Refresh synd feeds
      const syndfeedResponse = await API.getSyndfeeds({ propertyURN });
      setSyndfeeds(syndfeedResponse?.syndFeeds);
      const trackingParamsAfter = getTrackingParams({
        rssFeeds: syndfeedResponse?.syndFeeds,
      });
      sendTrackingEvent(trackingParamsBefore, trackingParamsAfter);
      toast({ variant: 'success', title: 'Content feed removed' });
    } catch (error) {
      logger.error(`Property Settings delete syndfeed: ${error}`);
      toast({ variant: 'error', title: 'Could not remove content feed' });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAdd = async feedURL => {
    try {
      setIsAdding(true);
      const trackingParamsBefore = getTrackingParams();
      const response = await API.postSyndfeed({ propertyURN, feedURL });
      if (response?.feedURL) {
        const syndfeedResponse = await API.getSyndfeeds({ propertyURN });
        setSyndfeeds(syndfeedResponse?.syndFeeds);
        const trackingParamsAfter = getTrackingParams({
          rssFeeds: syndfeedResponse?.syndFeeds,
        });
        sendTrackingEvent(trackingParamsBefore, trackingParamsAfter);
        toast({ variant: 'success', title: 'Content feed added' });
      }
    } catch (error) {
      logger.error(`Property Settings add syndfeed: ${error}`);
      toast({ variant: 'error', title: 'Error adding content feed' });
    } finally {
      setIsAdding(false);
    }
  };

  const handleRadioClick = value => {
    setIsAllowContentFromAnyDomain(value);
    setChanged();
  };

  useEffect(() => {
    const saveContentOption = async () => {
      const settings = {
        propertyURN,
        enabled: isAllowContentFromAnyDomain === 'true',
        settingTypeId:
          CORE_PROPERTY_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS,
      };
      try {
        const trackingParamsBefore = getTrackingParams({
          anyDomain: allowContentFromAnyDomain.current,
        });
        await API.postPropertySettings(settings);
        const trackingParamsAfter = getTrackingParams({
          anyDomain: isAllowContentFromAnyDomain,
        });
        allowContentFromAnyDomain.current =
          isAllowContentFromAnyDomain === 'true';
        sendTrackingEvent(trackingParamsBefore, trackingParamsAfter);
        toast({ variant: 'success', title: 'Settings saved' });
        setDone();
      } catch (error) {
        logger.error(
          `Property Settings change content domain option: ${error}`
        );
        toast({ variant: 'error', title: 'Error saving settings' });
        setChanged();
      }
    };
    setOnSave(() => {
      saveContentOption();
    });
  }, [
    setOnSave,
    isAllowContentFromAnyDomain,
    propertyURN,
    setDone,
    setChanged,
  ]);

  if (isLoading) return <Loading className="p-4" />;

  return (
    <div className="property-settings property-settings-feeds">
      <div className="campaign-title ebx-title-colour mb-1">
        <span>RSS feeds and sitemaps</span>
      </div>
      <div className="mb-4">Edit your content feeds</div>
      <AddFeed
        inputDisabled={isAdding || isDeleting || isSaving}
        onAddFeed={handleAdd}
        syndfeeds={syndfeeds}
      />
      <div className="feeds-list">
        {syndfeeds.map(({ syndFeedURL, syndFeedURN }) => (
          <ListGroup.Item
            key={syndFeedURN}
            className="ebx-body-1 d-flex align-content-center feed-list-item"
          >
            <a
              href={syndFeedURL}
              target="_blank"
              rel="noopener noreferrer"
              className="ebx-cursor ebx-faded-link"
            >
              {syndFeedURL}
              <OpenInNewIcon className="ml-2 property__open__icon" />
            </a>
            <Button
              variant="link"
              className="p-0 ml-auto ebx-faded-link remove-feed-button"
              onClick={() => handleDelete(syndFeedURN)}
              disabled={isAdding || isDeleting || isSaving}
            >
              <CloseIcon />
            </Button>
          </ListGroup.Item>
        ))}
      </div>
      <div className="mt-4">
        <ContentSourceRadio
          checked={isAllowContentFromAnyDomain === 'true'}
          disabled={isAllowContentInputDisabled || isSaving}
          onChange={() => handleRadioClick('true')}
          value="true"
          label="Allow content from any domain"
          id="any-domain"
        />
      </div>
      <div className="mt-1">
        <ContentSourceRadio
          checked={isAllowContentFromAnyDomain === 'false'}
          disabled={isAllowContentInputDisabled || isSaving}
          onChange={() => handleRadioClick('false')}
          value="false"
          label={`Allow only content from ${url.getDomain(propertyRootURL)}`}
          id="root-domain"
        />
      </div>
    </div>
  );
}

export default Feeds;
