/**
 * The response object from statuspage.io looks like this:
 *
 * {
 *   page: {
 *     id: '42yqqd04dt45',
 *     name: 'Echobox Email',
 *     url: 'https://echobox-newsletters.statuspage.io',
 *     time_zone: 'Etc/UTC',
 *     updated_at: '2021-02-26T15:28:34.457Z'
 *   },
 *   incidents: [
 *     {
 *       incident_updates: [
 *         {
 *           display_at: TIMESTAMP,
 *           body: 'MESSAGE',
 *         },
 *       ],
 *     },
 *     ... // repeat for each incident
 *   ]
 * }
 */

import axios from 'axios';

import { getUnixTimestamp } from 'common/datetime';
import { handleExternalAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS, statusPageURLs } from '../settings';

export default async function getStatusPageIncidents({
  pageId = mandatory('pageId'),
} = {}) {
  const statusPageURL = statusPageURLs[pageId];
  if (isNullOrUndefined(statusPageURL)) {
    // return {
    //   incidents: [
    //     {
    //       incident_updates: [
    //         {
    //           display_at: 1590572977,
    //           body: 'Oh no something has gone terribly wrong.',
    //         },
    //       ],
    //     },
    //   ],
    // };
    return null;
  }

  const guid = metrics.start('getStatusPageIncidents');

  const config = {
    url: `${statusPageURL}/incidents/unresolved.json`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
  };
  logger.info(`API:getStatusPageIncidents ${statusPageURL}`);

  try {
    const response = await axios(config);
    metrics.end('getStatusPageIncidents', guid);
    const simulate = sessionStorage.getItem('simulateStatusPageNotification');
    if (!isNull(simulate)) {
      response.data.incidents.push({
        incident_updates: [
          {
            display_at: getUnixTimestamp(),
            body: 'STATUS PAGE NOTIFICATION FOR TEST PURPOSES ONLY, PLEASE IGNORE.',
          },
        ],
      });
    }
    return response.data;
  } catch (error) {
    metrics.fail('getStatusPageIncidents', guid);
    throw handleExternalAPIError({
      originalError: error,
      errorLocation: 'api/getStatusPageIncidents',
      args: { pageId },
    });
  }
}
