import './EditionSubject.css';

export interface EditionSubjectProps {
  subject?: string;
}

function EditionSubject({ subject }: EditionSubjectProps) {
  if (!subject) {
    return null;
  }
  return (
    <span className="ebx-h2 mr-0 mr-md-2 ebx-edition-subject ebx-edition-subject--color">
      {subject}
    </span>
  );
}

EditionSubject.defaultProps = {
  subject: null,
};

export default EditionSubject;
