import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * postSubscribersExport
 * @param {{
 *  campaignURN: string;
 * }} args
 */
export default async function postSubscribersExport({
  campaignURN = mandatory('campaignURN'),
}) {
  const guid = metrics.start('postSubscribersExport');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/subscribers/export`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  logger.info(
    `API:postSubscribersExport /campaigns/${campaignURN}/subscribers/export`
  );

  try {
    const response = await axios(config);
    metrics.end('postSubscribersExport', guid);
    return response;
  } catch (error) {
    metrics.fail('postSubscribersExport', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postSubscribersExport',
      args: { campaignURN },
    });
    throw apiError;
  }
}
