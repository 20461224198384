import { Flex, Heading, Slider } from '@ebx-ui/ebx-ui-component-library-sdk';

interface PercentageProps {
  name: string;
  label: string;
  onChange: (key: string, value: any) => void;
  value: number;
}

function Percentage({ name, label, onChange, value }: PercentageProps) {
  const handleChange = (newValue: number | number[]) => {
    onChange(name, newValue);
  };

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h4">{label}</Heading>
      <Slider
        min={0}
        max={100}
        step={1}
        onChange={handleChange}
        value={value}
      />
    </Flex>
  );
}

export default Percentage;
