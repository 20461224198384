import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface CampaignURNToNameMap {
  [key: string]: string;
}

interface PostPropertyAnalyticsArgs {
  propertyURN: string;
  startUnixTime: number;
  endUnixTime: number;
  campaignURNToNameMap: CampaignURNToNameMap;
}

export default async function postPropertyAnalytics({
  propertyURN,
  startUnixTime,
  endUnixTime,
  campaignURNToNameMap,
}: PostPropertyAnalyticsArgs) {
  if (isUndefined(propertyURN)) {
    mandatory('propertyURN');
  }

  const guid = metrics.start('postPropertyAnalytics');

  const config: AxiosRequestConfig = {
    url: `${apiBaseURL(APIS.OPTIMISATION_SERVICE, '')}/property/insights`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: {
      propertyURN,
      startUnixTime,
      endUnixTime,
      campaignURNToNameMap,
    },
  };
  logger.info(`API:postPropertyAnalytics /property/insights`);

  try {
    const { data } = await axios(config);
    metrics.end('postPropertyAnalytics', guid);
    return data.analyticsExportS3Link;
  } catch (error) {
    metrics.fail('postPropertyAnalytics', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postPropertyAnalytics',
            args: {
              propertyURN,
              startUnixTime,
              endUnixTime,
              campaignURNToNameMap,
            },
            ignore403Errors: true,
          })
        : error;
    throw apiError;
  }
}
