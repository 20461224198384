import {
  EDITION_SUBJECT_TYPES,
  EMAIL_PREVIEW_TEXT_TYPES,
  ENGAGEMENT_RATES,
  MEDIA_DATA_SOURCE_TYPES,
  PREVIEW_TYPES,
  PRODUCT_TYPES,
  QUERY_CACHE_SCENARIOS,
  USER_TYPES,
} from 'common/enums';
import { MediaDataSources } from 'common/types';

export const API_ERROR_MESSAGES = {
  API_INITIALISATION:
    'Unable to reach Echobox. Please check your internet connection.',
  API_TIMEOUT:
    'Unfortunately, this action timed out. Please refresh the page and try again.',
  API_TERMINATION:
    'Unfortunately, your connection to Echobox was terminated unexpectedly. Please check your internet connection and try again.',
  API_HTTP:
    'Unfortunately, this action could not be completed. Please retry or reload the page.',
  API_XHR:
    'Unfortunately, this action timed out. Please retry or reload the page.',
};

export const API_ERROR_TYPES = {
  API_INITIALISATION: 'API_INITIALISATION',
  API_TIMEOUT: 'API_TIMEOUT',
  API_TERMINATION: 'API_TERMINATION',
  API_HTTP: 'API_HTTP',
  API_HTTP_COGNITO: 'API_HTTP_COGNITO',
  API_HTTP_EXTERNAL: 'API_HTTP_EXTERNAL',
  API_XHR: 'API_XHR',
};

export const APPS_AND_INTEGRATIONS = [
  {
    key: 'active-campaign',
    name: 'ActiveCampaign',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'campaign-monitor',
    name: 'Campaign Monitor',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'chargebee',
    name: 'Chargebee',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'constant-contact',
    name: 'Constant Contact',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'facebook',
    name: 'Facebook',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'google-forms',
    name: 'Google Forms',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'hubspot',
    name: 'Hubspot',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'icontact',
    name: 'iContact',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'linkedin',
    name: 'LinkedIn',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'mailchimp',
    name: 'Mailchimp',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'mailerlite',
    name: 'Mailerlite',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'moosend',
    name: 'Moosend',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'ninja-forms',
    name: 'Ninja Forms',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'salesforce',
    name: 'Salesforce',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'segment',
    name: 'Segment',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'twitter',
    name: 'Twitter',
    canImportSubs: true,
    canExportSubs: false,
  },
  {
    key: 'zoho',
    name: 'Zoho',
    canImportSubs: true,
    canExportSubs: true,
  },
  {
    key: 'custom-integration',
    name: 'Custom Integration',
    canImportSubs: true,
    canExportSubs: true,
  },
];

export const ARTICLE_STATUS = {
  LOADING: 'LOADING',
  EMPTY: 'EMPTY',
  WARN: 'WARN',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const AXIOS_ERROR_CODES = {
  BAD_REQUEST: 'ERR_BAD_REQUEST',
};

export const BRANDING_SETTINGS_SUB_PAGES = {
  SUBSCRIPTION_PAGE: {
    OPTIONS: 'OPTIONS',
    CONTENT: 'CONTENT',
    INPUTS: 'INPUTS',
  },
  SUBSCRIPTION_VERIFICATION_PAGE: {
    OPTIONS: 'OPTIONS',
    CONTENT: 'CONTENT',
  },
  SUBSCRIPTION_SUCCESS_PAGE: {
    OPTIONS: 'OPTIONS',
    CONTENT: 'CONTENT',
    INPUTS: 'INPUTS',
  },
  UNSUBSCRIPTION_PAGE: {
    OPTIONS: 'OPTIONS',
    CONTENT: 'CONTENT',
  },
  GENERAL_STYLING_PAGE_SETTINGS: {
    OPTIONS: 'OPTIONS',
    LOGO: 'LOGO',
    STYLING: 'STYLING',
    HEADER: 'HEADER',
    FONTS: 'FONTS',
  },
  EMAIL_CONFIRMATION_PAGE: {
    OPTIONS: 'OPTIONS',
    CONTENTS: 'CONTENTS',
  },
  EMAIL_EDITION_PAGE: {
    OPTIONS: 'OPTIONS',
    TEXT_OPTIONS: 'TEXT_OPTIONS',
    BUTTON: 'BUTTON',
    FOOTER: 'FOOTER',
  },
  EMAIL_VERIFICATION_PAGE: {
    OPTIONS: 'OPTIONS',
    CONTENTS: 'CONTENTS',
  },
};

export const CAMPAIGN_SETTING_TYPES = {
  AUTO_APPROVAL: 'AUTO_APPROVAL',
  CONTENT_PERSONALISATION: 'CONTENT_PERSONALISATION',
  EDITION_SUBJECT: 'EDITION_SUBJECT',
  FOOTER_TEXT: 'FOOTER_TEXT',
  HOSTED_SUBS_PAGE: 'HOSTED_SUBS_PAGE',
  HOSTED_SUBS_CONFIRMATION: 'HOSTED_SUBS_CONFIRMATION',
  HOSTED_SUBS_VERIFICATION: 'HOSTED_SUBS_VERIFICATION',
  HOSTED_SUBS_UNSUBSCRIBE: 'HOSTED_SUBS_UNSUBSCRIBE',
  PRESENTATION: 'PRESENTATION',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  SCHEDULING: 'SCHEDULING',
  SOCIAL_LINKS: 'SOCIAL_LINKS',
  SUBSCRIBER_CONFIRMATION: 'SUBSCRIBER_CONFIRMATION',
  SUBSCRIPTION: 'SUBSCRIPTION',
  TEMPLATE_STYLING: 'TEMPLATE_STYLING',
  NOTIFY_EDITION_READY: 'NOTIFY_EDITION_READY',
  SECTION_ARTICLE: 'SECTION_ARTICLE',
  MANAGE_YOUR_PREFERENCES: 'MANAGE_YOUR_PREFERENCES',
  SUBSCRIBER_PERSONALISATION: 'SUBSCRIBER_PERSONALISATION',
} as const;

export const CAMPAIGN_STATES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  UNDER_SETUP: 'UNDER_SETUP',
};

export const COGNITO_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};

export const COGNITO_ERROR_MESSAGES = {
  EnableSoftwareTokenMFAException: 'Invalid code. Please try again.',
  UserNotFoundException:
    'User not found, please check the email address is entered correctly.',
  CodeMismatchException: 'Invalid code. Please try again.',
  InternalServerErrorException: 'Something went wrong. Please try again.',
};

export const CORE_PROPERTY_SETTING_TYPES = {
  ALLOW_CONTENT_FROM_ALL_DOMAINS: 21,
};

export const CURATION_PAGE_MOBILE_PREVIEW_WIDTH = 375;

// Controls the first weekday to appear on the datepicker calendar (0 -> Sunday)
export const DATE_PICKER_START_DAY = 1;

export const DAY_IN_UNIX_TIME = {
  SECONDS: 24 * 60 * 60,
};

export const DAY_NAME_MAP = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

export const DEFAULT_DEBOUNCE_DELAY = 1000;

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong! Please try again';

export const DEFAULT_MEDIA_DATA_SOURCES = {
  title: MEDIA_DATA_SOURCE_TYPES.OG,
  description: MEDIA_DATA_SOURCE_TYPES.OG,
  image: MEDIA_DATA_SOURCE_TYPES.OG,
} as MediaDataSources;

export const DESCRIPTION_DATA_SOURCES = {
  [MEDIA_DATA_SOURCE_TYPES.SYND]: 'RSS Description',
  [MEDIA_DATA_SOURCE_TYPES.OG]: 'OG Description',
  [MEDIA_DATA_SOURCE_TYPES.TWITTER]: 'Twitter Description',
  [MEDIA_DATA_SOURCE_TYPES.NONE]: 'Leave Blank',
};

export const EBX_LAST_USED_PRODUCT_KEY = 'EbxLastUsedProduct';

export const EDITION_APPROVAL_STATE_NAMES = {
  NEW: 'Pre-Review',
  ECHOBOX_APPROVED: 'For Review',
  CLIENT_APPROVED: 'Approved',
};

// Edition Display States
// This should reflect the states requested in the product sheet
export const EDITION_DISPLAY_STATES = {
  NEW: 'NEW',
  ECHOBOX_APPROVED: 'ECHOBOX_APPROVED',
  SCHEDULED: 'SCHEDULED',
  AUTOMATED: 'AUTOMATED',
  SENDING: 'SENDING',
  SENT: 'SENT',
  MISSED: 'MISSED',
  FAILED: 'FAILED',
  POPULATING: 'POPULATING',
};

export const EDITION_FIELDS = {
  LIST: [
    'approvalState',
    'editionSource',
    'editionState',
    'editionSubject',
    'editionURN',
    'failMessage',
    'lastUpdatedByEmail',
    'lastUpdatedUnixTime',
    'previewData',
    'scheduledUnixTime',
    'sendingTimeslotSizeSeconds',
    'templateURN',
  ],
  REVIEW: [
    'approvalState',
    'bodyElementPositioning',
    'editionSource',
    'editionState',
    'editionSubject',
    'editionURN',
    'emailPreviewText',
    'lastUpdatedByEmail',
    'lastUpdatedUnixTime',
    'promotionBlocks',
    'scheduledUnixTime',
    'sendingTimeslotSizeSeconds',
    'sections',
    'templateURN',
    'textBlocks',
  ],
};

// Edition Sources
export const EDITION_SOURCES = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
};

export const EDITION_SUBJECT_TYPE_DESCRIPTIONS = {
  [EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE]: 'Headline of first article',
  [EDITION_SUBJECT_TYPES.NAME_DDMM]: 'Campaign name - date DDMM',
  [EDITION_SUBJECT_TYPES.NAME_DDMONTH]: 'Campaign name - date DDMONTH',
  [EDITION_SUBJECT_TYPES.NAME_MMDD]: 'Campaign name - date MMDD',
};

export const EMAIL_PREVIEW_TYPE_DESCRIPTIONS = {
  [EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_TITLE]: 'Headline of first article',
  [EMAIL_PREVIEW_TEXT_TYPES.SECOND_ARTICLE_TITLE]: 'Headline of second article',
  [EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_DESCRIPTION]:
    'Description of first article',
  [EMAIL_PREVIEW_TEXT_TYPES.CUSTOM]: 'Custom preview text',
};

// Edition Display Configuration
// Default settings for each edition state
export const EDITION_DISPLAY_STATE_CONFIGURATION = {
  [EDITION_DISPLAY_STATES.NEW]: {
    isEditionVisible: false,
    isEditionClickable: true,
    isEditionEditable: true,
    isErrorVisible: false,
    wrapperClassName: 'ebx-panel-pending',
    labelClassName: 'ebx-label-new',
    displayLabel: 'Hidden',
    canDelete: true,
  },
  [EDITION_DISPLAY_STATES.ECHOBOX_APPROVED]: {
    isEditionVisible: true,
    isEditionClickable: true,
    isEditionEditable: true,
    isErrorVisible: true,
    wrapperClassName: 'ebx-panel-pending',
    labelClassName: 'ebx-label-missed',
    displayLabel: 'Draft',
    canDelete: true,
  },
  [EDITION_DISPLAY_STATES.SCHEDULED]: {
    isEditionVisible: true,
    isEditionClickable: true,
    isEditionEditable: true,
    isErrorVisible: false,
    wrapperClassName: 'ebx-panel-approved',
    labelClassName: 'ebx-label-approved',
    displayLabel: 'Scheduled',
    canDelete: true,
  },
  [EDITION_DISPLAY_STATES.AUTOMATED]: {
    isEditionVisible: true,
    isEditionClickable: true,
    isEditionEditable: true,
    isErrorVisible: false,
    wrapperClassName: 'ebx-panel-automation',
    labelClassName: 'ebx-label-automation',
    displayLabel: 'Automated',
    canDelete: true,
  },
  [EDITION_DISPLAY_STATES.SENDING]: {
    isEditionVisible: true,
    isEditionClickable: false,
    isEditionEditable: false,
    isErrorVisible: false,
    wrapperClassName: 'ebx-panel-sending',
    labelClassName: 'ebx-label-sending',
    displayLabel: 'Sending',
    canDelete: false,
  },
  [EDITION_DISPLAY_STATES.SENT]: {
    isEditionVisible: true,
    isEditionClickable: true,
    isEditionEditable: false,
    isErrorVisible: false,
    wrapperClassName: 'ebx-panel-approved',
    labelClassName: 'ebx-label-sent',
    displayLabel: 'Sent',
    canDelete: false,
  },
  [EDITION_DISPLAY_STATES.MISSED]: {
    isEditionVisible: true,
    isEditionClickable: true,
    isEditionEditable: false,
    isErrorVisible: true,
    wrapperClassName: 'ebx-panel-missed',
    labelClassName: 'ebx-label-missed',
    displayLabel: 'Missed',
    canDelete: true,
  },
  [EDITION_DISPLAY_STATES.FAILED]: {
    isEditionVisible: true,
    isEditionClickable: true,
    isEditionEditable: false,
    isErrorVisible: true,
    wrapperClassName: 'ebx-panel-pending',
    labelClassName: 'ebx-label-error',
    displayLabel: 'Failed',
    canDelete: true,
  },
  [EDITION_DISPLAY_STATES.POPULATING]: {
    isEditionVisible: true,
    isEditionClickable: false,
    isEditionEditable: false,
    isErrorVisible: false,
    wrapperClassName: 'ebx-panel-populating',
    labelClassName: 'ebx-label-populating',
    displayLabel: 'Building',
    canDelete: false,
  },
};

export const EDITION_POPULATION_LOADING_SECS = 90;

export const EDITOR_TOOLBAR_OPTIONS = {
  DEFAULT: 'DEFAULT',
  EXPANDED: 'EXPANDED',
};

export const EDITOR_TOOLBAR_CONFIGURATIONS = {
  [EDITOR_TOOLBAR_OPTIONS.DEFAULT]: {
    inline: ['bold', 'italic', 'underline'],
    link: ['link', 'unlink'],
  },
  [EDITOR_TOOLBAR_OPTIONS.EXPANDED]: {
    inline: ['bold', 'italic', 'underline'],
    link: ['link', 'unlink'],
    list: ['ordered', 'unordered'],
    textAlign: ['left', 'center', 'right', 'justify'],
  },
};

export const ENGAGEMENT_RATE_PROPERTIES = {
  [ENGAGEMENT_RATES.LOW]: { label: 'Low', colour: 'red' },
  [ENGAGEMENT_RATES.MEDIUM]: { label: 'Medium', colour: 'amber' },
  [ENGAGEMENT_RATES.HIGH]: { label: 'High', colour: 'green' },
  [ENGAGEMENT_RATES.NO_DATA]: { label: 'No data', colour: 'white' },
  [ENGAGEMENT_RATES.COLLECTING]: {
    label: 'Collecting data',
    colour: 'white',
  },
  [ENGAGEMENT_RATES.NEVER]: {
    label: 'Never engaged',
    colour: 'white',
  },
};

export const EXAMPLE_USER = 'example@user.com';

export const FRONTEND_UPLOAD_STATES = {
  INACTIVE: 'INACTIVE',
  SELECTING: 'SELECTING',
  CONFIRMING: 'CONFIRMING',
  LOADING: 'LOADING',
  VERIFY: 'VERIFY',
  VALIDATING: 'VALIDATING',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export const GLOBAL_INFO_STATES = {
  INITIALISED: 'INITIALISED',
  LOADING: 'LOADING',
  READY: 'READY',
  ERROR: 'ERROR',
  REFRESHING: 'REFRESHING',
  SELECTING_CAMPAIGN: 'SELECTING_CAMPAIGN',
  SELECTING_PROPERTY: 'SELECTING_PROPERTY',
  UPDATING_SETTINGS: 'UPDATING_SETTINGS',
};

export const GOOGLE_FONTS_API_KEY = 'AIzaSyBYAfnXc8YOXiHfEHRwyGfW_h-35-mFefo';

export const HOST_TYPES = {
  DEV: 'dev',
  PROD: 'prod',
  STAGING: 'staging',
};

export const HUB_CHANNELS = {
  AUTHENTICATION: 'AUTHENTICATION',
  GLOBAL_INFO: 'GLOBAL_INFO',
  GLOBAL_EVENTS: 'GLOBAL_EVENTS',
  SETTINGS: 'SETTINGS',
};

export const HUB_EVENTS = {
  [HUB_CHANNELS.AUTHENTICATION]: {
    IMPERSONATE: 'IMPERSONATE',
    MFA: 'MFA',
    NEW_PASSWORD: 'NEW_PASSWORD',
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
  },
  [HUB_CHANNELS.GLOBAL_INFO]: {
    LOADED: 'LOADED',
  },
  [HUB_CHANNELS.GLOBAL_EVENTS]: {
    FORCE_SIGNOUT: 'FORCE_SIGNOUT',
  },
  [HUB_CHANNELS.SETTINGS]: {
    REQUEST_SAVE: 'REQUEST_SAVE',
    STATUS_CHANGED: 'STATUS_CHANGED',
    STATUS_ERROR: 'STATUS_ERROR',
    STATUS_SAVING: 'STATUS_SAVING',
    STATUS_UNCHANGED: 'STATUS_UNCHANGED',
  },
};

export const IMAGE_DATA_SOURCES = {
  [MEDIA_DATA_SOURCE_TYPES.SYND]: 'RSS Image',
  [MEDIA_DATA_SOURCE_TYPES.OG]: 'OG Image',
  [MEDIA_DATA_SOURCE_TYPES.TWITTER]: 'Twitter Image',
};

export const KEY_TRANSACTIONS = [];

export const LOCAL_STATE_STORAGE_KEY = 'Echobox.Email.localState';

export const LOGIN_URLS = {
  PROD: 'https://login.echobox.com',
  STAGING: 'https://login.echobox.dev',
  DEV: 'http://localhost:3001',
};

export const LOGIN_APP_MESSAGES = {
  SIGN_OUT: 'SIGN_OUT',
  GET_TOKENS: 'GET_TOKENS',
};

export const IFRAME_MESSAGE_TIMEOUT = 60000;

export const MARKETING_EMAIL_TYPES = {
  HTML: 'HTML',
  WYSIWYG: 'WYSIWYG',
} as const;

export const NO_EXPIRY_DATE = 'Fri, 31 Dec 9999 23:59:59 GMT';

export const NO_PERMISSIONS_PREFIX = 'NoPermissions_';

export const NO_PERMISSIONS_STATUS_CODES = [422, 403, 404, 401];

export const NON_API_ERROR_MESSAGES = {
  NON_API_GENERAL:
    'Unfortunately, this action could not be completed. Please retry or reload the page.',
};

export const NON_API_ERROR_TYPES = {
  NON_API_GENERAL: 'NON_API_GENERAL',
};

export const OCCURRENCES = {
  FIRST: 'First',
  LAST: 'Last',
};

export const ONE_DAY_IN_SECONDS = 86400e3;

export const OPTIMAL_SEND_TIME = {
  START_TIME: 5, // Start at 5am
  SLOT_SIZE_SECONDS: 19 * 60 * 60, // Send for 19 hours
};

export const OPT_IN_TYPES = {
  DOUBLE: 'double',
  SINGLE: 'single',
};

/** References PREVIEW_TYPES from enums.ts */
export const PREVIEW_TYPE_PROPERTIES = {
  [PREVIEW_TYPES.EDITION]: {
    ASSET_TYPE: 'EMAIL',
    ENDPOINT_PATH: 'editions/{editionURN}/preview',
    EXPORTABLE: true,
    SENDABLE: true,
    LABEL: 'Edition',
  },
  [PREVIEW_TYPES.EDITION_TEMPLATE]: {
    ASSET_TYPE: 'EMAIL',
    ENDPOINT_PATH: 'campaigns/{campaignURN}/preview/edition',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Edition',
  },
  [PREVIEW_TYPES.HOSTED_SUBS_PAGE]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'subscribe/{campaignName}/{campaignURN}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Subscribe',
    POST_NAME: 'SUBSCRIBE',
  },
  [PREVIEW_TYPES.HOSTED_SUBS_SUCCESS]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'success/{campaignName}/{campaignURN}/{emailAddress}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Success',
    POST_NAME: 'SUCCESS',
  },
  [PREVIEW_TYPES.HOSTED_SUBS_UNSUBSCRIBE]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'unsubscribe/{campaignURN}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Unsubscribe',
    POST_NAME: 'UNSUBSCRIBE',
  },
  [PREVIEW_TYPES.HOSTED_SUBS_VERIFICATION]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'verify/{campaignName}/{campaignURN}/{emailAddress}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Verify',
    POST_NAME: 'VERIFY',
  },
  [PREVIEW_TYPES.MARKETING_EMAIL]: {
    ASSET_TYPE: 'MARKETING',
    ENDPOINT_PATH: null,
    EXPORTABLE: true,
    SENDABLE: true,
    LABEL: 'Marketing Email',
  },
  [PREVIEW_TYPES.MULTI_CAMPAIGN_SUBS_PAGE]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'subscribe/{propertyURN}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Property Subscribe',
    POST_NAME: 'PROPERTY_SUBSCRIBE',
  },
  [PREVIEW_TYPES.SUBSCRIBER_CONFIRMATION_EMAIL]: {
    ASSET_TYPE: 'EMAIL',
    ENDPOINT_PATH: 'campaigns/{campaignURN}/preview/subscriber-confirmation',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Confirmation Email',
  },
  [PREVIEW_TYPES.SUBSCRIBER_VERIFICATION_EMAIL]: {
    ASSET_TYPE: 'EMAIL',
    ENDPOINT_PATH: 'campaigns/{campaignURN}/preview/subscriber-verification',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Verification Email',
  },
  [PREVIEW_TYPES.ACCESS_PREFERENCES]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'accesspreferences/{propertyURN}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Subscriber Preferences Access',
    POST_NAME: 'ACCESS_PREFERENCES',
  },
  [PREVIEW_TYPES.ACCESS_PREFERENCES_SENT]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'accesspreferencessent/{propertyURN}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Subscriber Preferences Access Sent',
    POST_NAME: 'ACCESS_PREFERENCES_SENT',
  },
  [PREVIEW_TYPES.ACCESS_PREFERENCES_EMAIL]: {
    ASSET_TYPE: 'EMAIL',
    ENDPOINT_PATH: 'campaigns/{campaignURN}/preview/access-your-preferences',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Access Preferences Email',
  },
  [PREVIEW_TYPES.MANAGE_PREFERENCES]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'managepreferences/{propertyURN}',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Subscriber Preferences Center',
    POST_NAME: 'MANAGE_PREFERENCES',
  },
  [PREVIEW_TYPES.MANAGE_PREFERENCES_SUCCESS]: {
    ASSET_TYPE: 'PAGE',
    ENDPOINT_PATH: 'managepreferences/{propertyURN}/success',
    EXPORTABLE: false,
    SENDABLE: false,
    LABEL: 'Subscriber Preferences Center',
    POST_NAME: 'MANAGE_PREFERENCES_SUCCESS',
  },
};

export const PROPERTY_INITIAL_IGNORE_WORDS = ['THE', 'AND', 'BY'];

export const PROPERTY_SETTING_TYPES = {
  ACCESS_YOUR_PREFERENCES_EMAIL: 'ACCESS_YOUR_PREFERENCES_EMAIL',
  ACCESS_YOUR_PREFERENCES_EMAIL_SENT_HOSTED_SUBS_PAGE:
    'ACCESS_YOUR_PREFERENCES_EMAIL_SENT_HOSTED_SUBS_PAGE',
  ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE:
    'ACCESS_YOUR_PREFERENCES_HOSTED_SUBS_PAGE',
  DOMAIN_VERIFICATION_COMPLETE: 'DOMAIN_VERIFICATION_COMPLETE',
  MANAGE_YOUR_PREFERENCES: 'MANAGE_YOUR_PREFERENCES',
  MULTI_CAMPAIGN_HOSTED_SUBS_PAGE: 'MULTI_CAMPAIGN_HOSTED_SUBS_PAGE',
  MULTI_CAMPAIGN_SIGNUP_ORDERING: 'MULTI_CAMPAIGN_SIGNUP_ORDERING',
  PERSONALISATION: 'PERSONALISATION',
  PLAN_DETAILS: 'PLAN_DETAILS',
  PROPERTY_PRIVACY_POLICY: 'PROPERTY_PRIVACY_POLICY',
  PROPERTY_STYLING: 'PROPERTY_STYLING',
};

export const PROPERTY_SETTINGS_TYPES_TO_EXCLUDE_ON_SAVE = [
  PROPERTY_SETTING_TYPES.PLAN_DETAILS,
];

export const PROPERTY_STATES = {
  NEWSIGNUP: 'NEWSIGNUP',
  UNDERSETUP: 'UNDERSETUP',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
};

// React Query Cache values are in miliseconds
const MINUTE_IN_MS = 60 * 1000;
const HOUR_IN_MS = 60 * MINUTE_IN_MS;

export const QUERY_CACHE_VALUES = {
  [QUERY_CACHE_SCENARIOS.ANALYTICS]: {
    CACHE_TIME: 12 * HOUR_IN_MS,
    STALE_TIME: 5 * MINUTE_IN_MS,
  },
  [QUERY_CACHE_SCENARIOS.SUBSCRIBERS]: {
    CACHE_TIME: HOUR_IN_MS,
    STALE_TIME: 5 * MINUTE_IN_MS,
  },
};

export const REACT_PREVENT_RENDER = null;

export const REGISTRATION_URL = 'https://email.echobox.com';

export const ROUTE_REDIRECTIONS = {
  HOME: '/campaigns',
  MFA: '/verifymfa',
  NEW_PASSWORD: '/newpassword',
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
};

export const SENDING_BUFFER_TIME = {
  MINUTES: 15 * 60,
};

export const SETTINGS_PANEL_VARIANTS = {
  DEFAULT: 'default',
  DISABLED: 'disabled',
  INFO: 'info',
  SUCCESS: 'success',
};

export const SOCIAL_LINKS = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
  LINKEDIN: 'LINKEDIN',
  PINTEREST: 'PINTEREST',
  YOUTUBE: 'YOUTUBE',
  TIKTOK: 'TIKTOK',
} as const;

export const SORT_ORDERS = {
  ALPHA: 'ALPHA',
  NUMERIC: 'NUMERIC',
};

export const STATUS_PAGE_IDS = {
  CORE: 'CORE',
  NEWSLETTERS: 'NEWSLETTERS',
} as const;

export const SUBJECT_WARNING_DISPLAY_MAXIMUM = 3;

export const SUBJECT_WARNING_HISTORY_LOCAL_STORAGE_KEY = 'EBX-esw';

export const SUBSCRIBER_LIST_PAGE_SIZE = 20;

export const TEXT_CASES = {
  UPPER: 'UPPER',
  LOWER: 'LOWER',
  CAMEL: 'CAMEL',
};

export const TITLE_DATA_SOURCES = {
  [MEDIA_DATA_SOURCE_TYPES.SYND]: 'RSS Title',
  [MEDIA_DATA_SOURCE_TYPES.OG]: 'OG Title',
  [MEDIA_DATA_SOURCE_TYPES.TWITTER]: 'Twitter Title',
};

export const UNIX_EPOCH = 'Thu, 01 Jan 1970 00:00:00 GMT';

export const URN_TYPES = {
  PROPERTY: 'PROPERTY',
  CAMPAIGN: 'CAMPAIGN',
  EDITION: 'EDITION',
  SECTION: 'SECTION',
  PROMOTION_BLOCK: 'PROMOTION_BLOCK',
  TEXT_BLOCK: 'TEXT_BLOCK',
  UPLOAD: 'UPLOAD',
  TEMPLATE: 'TEMPLATE',
};

export const WEEKDAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const WYSIWYG_EMAIL_DEFAULT_CONTENT = `<!--TEMPLATE:START
TEMPLATE:END-->`;

export const WYSIWYG_TEMPLATE_IDENTIFICATION_REGEX =
  /([\s\S]*?)<!--TEMPLATE:START([\s\S]*?)TEMPLATE:END-->([\s\S]*?)/gim;

export const WYSIWYG_TEMPLATE_REMOVAL_REGEX =
  /([\s\S]*?)(<!--TEMPLATE:START[\s\S]*?TEMPLATE:END-->)([\s\S]*?)/gim;

export const PRODUCT_USER_TYPE_MAP = {
  [USER_TYPES.SOCIAL]: PRODUCT_TYPES.SOCIAL,
  [USER_TYPES.DEFAULT]: PRODUCT_TYPES.EMAIL,
};

export const LOOPBACK_ADDRESS = '127.0.0.1';
export const LOGIN_STARTED = 'Login_Started';
