import { Button } from 'react-bootstrap';

import { AddIcon, Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import { PROMOTION_BLOCK_TYPES } from 'common/enums';

interface AddPromotionProps {
  blockType: string;
  onShowModal: () => void;
}

function AddPromotion({ blockType, onShowModal }: AddPromotionProps) {
  const title =
    blockType === PROMOTION_BLOCK_TYPES.IMAGE ? 'Add image' : 'Add advert';

  return (
    <Button
      className="ebx-article-section w-100 article-rounded p-0 add-promotion text-decoration-none"
      onClick={onShowModal}
      variant="link"
    >
      <div className="article-content ebx-body-2 ebx-faded-colour d-flex align-items-center px-3">
        {title}
        <Box marginLeft="auto">
          <AddIcon size={5} />
        </Box>
      </div>
    </Button>
  );
}

export default AddPromotion;
