import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DoneIcon from '@material-ui/icons/Done';

import './Unsubscribe.css';

function Unsubscribe() {
  return (
    <>
      <div className="unsub__height d-flex align-items-center justify-content-center">
        <div className="unsub__card text-center">
          <Row className="mb-3">
            <Col>
              <div className="unsub__h1">You are now unsubscribed</div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div className="unsub__body-1">
                <span>{`You won't receive any more emails from `}</span>
                <span>publisher@publisher.com</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <DoneIcon className="unsub__tick" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className=" d-flex justify-content-center align-items-center">
        <div className="p-2">
          <Row className="unsub__card pb-5 px-4 px-sm-5 pt-4 w-100 m-auto">
            <Col>
              <Row className="font-weight-bold unsub__card__header mb-3 d-flex justify-content-center">
                Unsubscribe
              </Row>
              <Row className="unsub__card__description mb-3 m-0">
                <div>
                  <span>
                    {`If you unsubscribe you will not receive any more email from `}
                  </span>
                  <span>publisher@publisher.com</span>
                </div>
              </Row>
              <Row className="unsub__card__reason w-100 m-0">
                <Form className="w-100">
                  <Form.Group>
                    <Link to="/unsubscribe/success">
                      <Button
                        className="btn w-100 mt-3 p-2 unsub__card__btn"
                        variant="dark"
                      >
                        Unsubscribe
                      </Button>
                    </Link>
                  </Form.Group>
                </Form>
              </Row>
            </Col>
          </Row>
          <div className="w-100 mt-2">
            <Col className="d-flex justify-content-end powered__text">
              Powered by Echobox
            </Col>
          </div>
        </div>
      </div>
    </>
  );
}

export default Unsubscribe;
