import {
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { MARKETING_EMAIL_TYPES } from 'common/constants';

interface CustomEditorModalProps {
  handleNewMarketing: (
    marketingEmailType: (typeof MARKETING_EMAIL_TYPES)[keyof typeof MARKETING_EMAIL_TYPES]
  ) => {};
  isOpen: boolean;
  onClose: () => void;
}

function CustomEditorModal({
  handleNewMarketing,
  isOpen,
  onClose,
}: CustomEditorModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <Modal.Header>
        <Modal.Title>Choose your editor</Modal.Title>
      </Modal.Header>
      <Modal.Body px={6} maxWidth="fit-content">
        <Flex gap={6}>
          <Card px={4}>
            <Card.CardContent align="center" gap={0}>
              <Image
                src="/assets/img/html-editor.png"
                alt="HTML editor"
                fit="contain"
                width="242px"
                height="182px"
                mb={4}
              />
              <Heading variant="h3" mb={2}>
                HTML
              </Heading>
              <Text size="sm" mb={6} color="gray.600" align="center">
                Create unique emails with a fully customizable HTML editor
              </Text>
              <Button
                width="100%"
                onClick={() => handleNewMarketing(MARKETING_EMAIL_TYPES.HTML)}
              >
                Select
              </Button>
            </Card.CardContent>
          </Card>
          <Card px={4}>
            <Card.CardContent align="center" gap={0}>
              <Image
                src="/assets/img/drag-and-drop-editor.20221028.png"
                alt="Drag &amp; drop editor"
                fit="contain"
                width="242px"
                height="182px"
                mb={4}
              />
              <Heading variant="h3" mb={2}>
                Drag &amp; drop
              </Heading>
              <Text size="sm" mb={6} color="gray.600" align="center">
                Easily drag and drop content blocks into your email
              </Text>
              <Button
                width="100%"
                onClick={() =>
                  handleNewMarketing(MARKETING_EMAIL_TYPES.WYSIWYG)
                }
              >
                Select
              </Button>
            </Card.CardContent>
          </Card>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

export default CustomEditorModal;
