import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postCampaignSubscribers({
  campaignURN = mandatory('campaignURN'),
  subscriberDetails = mandatory('subscriberDetails'),
}) {
  const guid = metrics.start('postSubscriber');

  const request = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS,
      '1'
    )}/campaigns/${campaignURN}/subscribers`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      subscribers: subscriberDetails,
    }),
  };

  try {
    await axios(request);
    metrics.end('postSubscriber', guid);
  } catch (error) {
    metrics.fail('postSubscriber', guid);

    // Just rethrow if the subscriber email is bad
    if (error.response?.data?.error?.target === 'email') {
      throw error;
    }

    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postSubscriber',
      args: { subscriberDetails },
    });
    throw apiError;
  }
}
