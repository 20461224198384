import { Select } from '@ebx-ui/ebx-ui-component-library-sdk';

interface FontPickerProps {
  name: string;
  options: {
    label: string;
    unstyled?: boolean;
    value: string;
  }[];
  onChange: (name: string, value: string) => void;
  value: string;
}

function FontPicker({ name, onChange, options, value }: FontPickerProps) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(name, event.target.value);
  };

  return (
    <Select onChange={handleChange} value={value} w="100%">
      {options.map(option => {
        const styled = !option?.unstyled ?? true;
        return (
          <option
            key={option.value}
            style={styled ? { fontFamily: `${option.value}` } : {}}
            value={option.value}
          >
            {option.label}
          </option>
        );
      })}
    </Select>
  );
}

export default FontPicker;
