import { Tag, TagProps, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import { ENGAGEMENT_RATE_PROPERTIES } from 'common/constants';
import { ENGAGEMENT_RATES } from 'common/enums';
import { Subscriber } from 'common/types';

import useDeveloperMode from 'hooks/useDeveloperMode';

interface EngagementLabelProps {
  subscriber: Subscriber;
}
function EngagementLabel({ subscriber }: EngagementLabelProps) {
  const [isDeveloperModeActive] = useDeveloperMode('NL-4325');

  const { label, colour } =
    ENGAGEMENT_RATE_PROPERTIES[subscriber.engagementLevel];

  if (
    !isDeveloperModeActive &&
    subscriber.engagementLevel === ENGAGEMENT_RATES.NEVER
  ) {
    // Until release, display Never subscribers as Low
    const { label: lowLabel, colour: lowColour } =
      ENGAGEMENT_RATE_PROPERTIES[ENGAGEMENT_RATES.LOW];
    return <Tag color={lowColour as TagProps['color']}>{lowLabel}</Tag>;
  }

  if (
    subscriber.engagementRate === null ||
    subscriber.engagementLevel === ENGAGEMENT_RATES.NEVER
  ) {
    return (
      <Text color="gray.600" size="sm">
        {label}
      </Text>
    );
  }

  return <Tag color={colour as TagProps['color']}>{label}</Tag>;
}

export default EngagementLabel;
