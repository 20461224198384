import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface PostSectionArgs {
  campaignURN: string;
  sectionDetails: object;
}

export default async function postSection({
  campaignURN,
  sectionDetails,
}: PostSectionArgs) {
  if (isUndefined(campaignURN)) {
    mandatory('campaignURN');
  }
  if (isUndefined(sectionDetails)) {
    mandatory('sectionDetails');
  }

  const guid = metrics.start('postSection');

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/campaigns/${campaignURN}/sections`;

  const config = {
    url,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
    data: JSON.stringify({
      ...sectionDetails,
    }),
  };

  logger.info(`API:postSection /campaigns/${campaignURN}/sections`);

  try {
    const response = await axios(config);
    metrics.end('postSection', guid);
    return response.data.sectionURN;
  } catch (error) {
    metrics.fail('postSection', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postSection',
            args: { campaignURN },
          })
        : error;
    throw apiError;
  }
}
