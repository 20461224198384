import {
  Button,
  Card,
  Flex,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

function Loading({ openAnalytics }) {
  return (
    <Card py={6} borderBottomRadius={0} justifyContent="space-between">
      <Flex gap={2} alignItems="center">
        <Text size="sm" color="gray.600">
          Subscribers
        </Text>
        <Spinner size="sm" color="gray.500" />
      </Flex>
      <Button variant="link" onClick={openAnalytics}>
        View analytics
      </Button>
    </Card>
  );
}

Loading.propTypes = {
  openAnalytics: PropTypes.func.isRequired,
};

export default Loading;
