import axios, { ResponseType } from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS } from 'api/settings';
import { CORS_PROXY_URL } from 'common/config';

interface GetDownloadFileArgs {
  url: string;
  responseType?: ResponseType;
}

export default async function getDownloadFile({
  url,
  responseType = 'blob',
}: GetDownloadFileArgs) {
  if (!url) {
    mandatory('url');
  }

  const guid = metrics.start('getDownloadFile');

  const config = {
    url: `${CORS_PROXY_URL}${url}`,
    method: 'GET',
    timeout: API_TIMEOUTS.M,
    responseType,
    headers: {
      'X-Requested-With': 'Echobox',
    },
  };
  logger.info(`API:getDownloadFile ${url}`);

  try {
    const response = await axios(config);
    metrics.end('getDownloadFile', guid);
    return response;
  } catch (error: any) {
    metrics.fail('getDownloadFile', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getDownloadFile',
    });
    throw apiError;
  }
}
