/* eslint react-hooks/exhaustive-deps: "off" */

import { useContext, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Redirect, Route, Switch } from 'react-router-dom';

import AnalyticsDashboard from 'components/campaigns/analytics/AnalyticsDashboard';
import Loading from 'components/campaigns/Loading';
import SubscriberManagement from 'components/campaigns/subscribers/SubscriberManagement';
import SubscriberManagementDemo from 'components/campaigns/subscribers/SubscriberManagementDemo';
import TemplateManagement from 'components/campaigns/templates/TemplateManagement';
import CampaignsView from 'components/campaigns/View';
import CampaignsViewError from 'components/campaigns/ViewError';
import PageWrapper from 'containers/PageWrapper';
import { StoreContext } from 'store/store';

import * as campaigns from 'common/campaigns';
import { EDITION_DISPLAY_STATES, GLOBAL_INFO_STATES } from 'common/constants';
import * as datetime from 'common/datetime';
import isTestProperty from 'common/demo';
import * as editions from 'common/editions';
import { EDITION_APPROVAL_STATES } from 'common/enums';
import * as properties from 'common/properties';

import useDeveloperMode from 'hooks/useDeveloperMode';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useLoadEmails from 'hooks/useLoadEmails';

function CampaignsViewWrapped() {
  const { state } = useContext(StoreContext);

  const campaign = campaigns.getCurrentCampaign(state?.globalInfo);
  const currentPropertyURN = properties.getCurrentPropertyURN(
    state?.globalInfo
  );
  const isPropertyShutOff = properties.isPropertyShutOff(
    currentPropertyURN,
    state?.globalInfo
  );

  const {
    isInitialised,
    isLoading,
    items: editionList,
    hasNextPage,
    errorMessage,
    loadMore,
  } = useLoadEmails(campaign?.campaignURN);
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: isPropertyShutOff || !!errorMessage,
    rootMargin: '0px 0px 400px 0px',
  });
  const loadEditionData = () => window.location.reload();

  const currentTime = datetime.getUnixTimestamp();

  const sentEmails = [];
  const draftEmails = [];
  const scheduledEmails = [];
  if (editionList && editionList.length > 0) {
    editionList.forEach(edition => {
      const {
        approvalState,
        editionSource,
        editionState,
        lastUpdatedUnixTime,
        scheduledUnixTime,
      } = edition;

      const editionDisplayState = editions.getEditionDisplayState(
        approvalState,
        editionSource,
        lastUpdatedUnixTime,
        editionState,
        scheduledUnixTime,
        currentTime
      );

      if (
        editionDisplayState === EDITION_DISPLAY_STATES.SENT ||
        editionDisplayState === EDITION_DISPLAY_STATES.MISSED ||
        editionDisplayState === EDITION_DISPLAY_STATES.FAILED
      ) {
        sentEmails.push(edition);
      } else if (editionDisplayState === EDITION_DISPLAY_STATES.POPULATING) {
        // Add populating editions to the top
        draftEmails.unshift(edition);
      } else if (approvalState === EDITION_APPROVAL_STATES.ECHOBOX_APPROVED) {
        draftEmails.push(edition);
      } else {
        scheduledEmails.push(edition);
      }
    });
  }

  return (
    <>
      <CampaignsView
        draftEmails={draftEmails}
        errorMessage={errorMessage}
        isInitialised={isInitialised}
        loadEditionData={loadEditionData}
        scheduledEmails={scheduledEmails}
        sentEmails={sentEmails}
      />
      <div ref={sentryRef}>{isLoading && <Loading />}</div>
    </>
  );
}

const Campaigns = () => {
  const { state } = useContext(StoreContext);
  const globalInfo = useGlobalInfo();

  const [isDeveloperModeActive] = useDeveloperMode('NL-1802'); // Export Property-Level Analytics

  // TODO - any change to global info state will cause a complete re-render,
  //        which is not really what we want is it Andy, you plonker...

  const campaignURN = state?.globalInfo?.current?.campaignURN;

  return useMemo(() => {
    if (state.globalInfo.state !== GLOBAL_INFO_STATES.READY) {
      return null;
    }

    return (
      <PageWrapper>
        <ErrorBoundary FallbackComponent={CampaignsViewError}>
          <Switch>
            <Route path="/campaigns/:id/analytics">
              <AnalyticsDashboard />
            </Route>
            <Route path="/campaigns/:id/subscribers">
              {isTestProperty(globalInfo) ? (
                <SubscriberManagementDemo />
              ) : (
                <SubscriberManagement />
              )}
            </Route>
            <Route
              path="/campaigns/:id/templates"
              render={
                isDeveloperModeActive
                  ? () => <TemplateManagement />
                  : () => <Redirect to={`/campaigns/${campaignURN}/view`} />
              }
            />
            <Route path="/campaigns/:id/view">
              <CampaignsViewWrapped />
            </Route>
            <Route
              path="/campaigns"
              render={() => <Redirect to={`/campaigns/${campaignURN}/view`} />}
            />
          </Switch>
        </ErrorBoundary>
      </PageWrapper>
    );
  }, [campaignURN]);
};

export default Campaigns;
