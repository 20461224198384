import {
  Box,
  Button,
  Tooltip,
  useTheme,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { ReactComponent as EchoboxLogo } from 'assets/svg/echobox-logo.svg';
import StaffModalWrapper from 'components/staff/StaffModalWrapper';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useStaffUser from 'hooks/useStaffUser';

function Logo() {
  const {
    components: { logo },
  } = useTheme();

  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
  const isStaffUser = useStaffUser();
  const globalInfo = useGlobalInfo();

  const currentVersion = sessionStorage.getItem('currentVersion');

  const handleClose = () => setIsStaffModalOpen(false);

  const handleShow = () => setIsStaffModalOpen(true);

  const image = (
    <Box color={logo.color}>
      <EchoboxLogo />
    </Box>
  );

  const linkContent = currentVersion ? (
    <Tooltip label={`Echobox version ${currentVersion}`}>{image}</Tooltip>
  ) : (
    image
  );

  return (
    <>
      {isStaffUser ? (
        <Button
          variant="link"
          onClick={handleShow}
          _focus={{ boxShadow: 'none' }}
        >
          {linkContent}
        </Button>
      ) : (
        linkContent
      )}
      {isStaffModalOpen && isStaffUser && (
        <StaffModalWrapper
          show={isStaffModalOpen}
          handleClose={handleClose}
          globalInfo={globalInfo}
          isStaffUser={isStaffUser}
        />
      )}
    </>
  );
}

export default Logo;
