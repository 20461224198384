import PropTypes from 'prop-types';

// import WarningIcon from '@material-ui/icons/Warning';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-icon.svg';

function SignInErrors({ errorMessage, errors }) {
  if (errors?.username) {
    return (
      <div className="d-flex align-items-center mt-2 mb-2">
        <WarningIcon className="ebx-error-colour mr-2" />
        <span className="ebx-error-colour ebx-bold-small-text">
          {errors.username.message}
        </span>
      </div>
    );
  }
  if (errors?.password) {
    return (
      <div className="d-flex align-items-center mt-2 mb-2">
        <WarningIcon className="ebx-error-colour mr-2" />
        <span className="ebx-error-colour ebx-bold-small-text">
          {errors.password.message}
        </span>
      </div>
    );
  }
  if (errorMessage) {
    return (
      <div className="d-flex align-items-center mt-2 mb-2">
        <WarningIcon className="ebx-error-colour mr-2" />
        <span className="ebx-error-colour ebx-bold-small-text">
          {errorMessage}
        </span>
      </div>
    );
  }

  return null;
}

SignInErrors.propTypes = {
  errorMessage: PropTypes.string,
  errors: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
};

SignInErrors.defaultProps = {
  errorMessage: null,
  errors: null,
};

export default SignInErrors;
