/* eslint react-hooks/exhaustive-deps: "off" */
import { PROPERTY_SETTING_TYPES } from 'common/constants';
import * as errors from 'common/errors';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  ChevronRightIcon,
  Flex,
  Heading,
  Text,
  Toggle,
  Tooltip,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { ReactComponent as Info } from 'assets/svg/info.svg';

function SubscriberPrefencesCenter() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  const isEnabled = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MANAGE_YOUR_PREFERENCES,
    'isEnabled'
  );

  // Stores previous value of isEnabled
  const toggleRef = useRef(isEnabled);

  const handleToggleChange = async () => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MANAGE_YOUR_PREFERENCES,
      settingKey: 'isEnabled',
      settingValue: !isEnabled,
      doNotSetChangedFlag: true,
    });
  };

  useEffect(() => {
    const handleSave = async () => {
      // Get initial value for Mixpanel tracking
      const before = {
        settings: settings.getInitialPropertySettings(),
      };
      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });

        // Mixpanel event
        sendTrackingEvent(before);
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    // Only saves once isEnabled setting has been updated locally.
    if (toggleRef.current !== null && toggleRef.current !== isEnabled)
      handleSave();

    toggleRef.current = isEnabled;
  }, [isEnabled]);

  const sendTrackingEvent = (before: any) => {
    const subscriberPreferencesEnabledBefore =
      before?.settings[PROPERTY_SETTING_TYPES.MANAGE_YOUR_PREFERENCES]
        .isEnabled;
    const subscriberPreferencesEnabledAfter = isEnabled;

    const trackingParams = {
      'Subscriber Preferences Center Enabled (before)':
        subscriberPreferencesEnabledBefore,
      'Subscriber Preferences Center Enabled (after)':
        subscriberPreferencesEnabledAfter,
    };

    tracker.track({
      eventName: 'Update Property Subscriber Preferences Center Settings',
      trackingParams,
      options: { propertyURN },
    });
  };

  const getSettingsPath = (subpage: string) =>
    `/settings/campaigns/allcampaigns/subscriber-preferences-center/${subpage}`;

  return (
    <>
      <Heading variant="h2" mb="2">
        Subscriber preferences center
      </Heading>
      <Text size="sm" color="gray.600" mb="8">
        Provide a page where subscribers can manage the Campaigns to which they
        are subscribed
      </Text>
      <Card justifyContent="space-between" mb={8}>
        <Flex direction="column">
          <Flex gap="1.5" align="center">
            <Card.CardTitle>Subscriber preferences center page</Card.CardTitle>
            <Tooltip
              label="You can update the label in the Campaign Branding settings"
              placement="top"
            >
              <Info />
            </Tooltip>
          </Flex>
          <Card.CardDescription mt={1}>
            Provide a link in your Editions where subscribers can update their
            campaign preferences
          </Card.CardDescription>
        </Flex>
        <Toggle
          isChecked={!!isEnabled}
          labelPosition="left"
          onChange={handleToggleChange}
        >
          {isEnabled ? 'On' : 'Off'}
        </Toggle>
      </Card>
      <Link className="link-unstyled" to={getSettingsPath('access')}>
        <Card justify="space-between" py={6} mb={2}>
          <Card.CardTitle>Access request page</Card.CardTitle>
          <ChevronRightIcon size={5} />
        </Card>
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('email-sent')}>
        <Card justify="space-between" py={6} mb={2}>
          <Card.CardTitle>Email sent</Card.CardTitle>
          <ChevronRightIcon size={5} />
        </Card>
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('email-request')}>
        <Card justify="space-between" py={6} mb={2}>
          <Card.CardTitle>Email request</Card.CardTitle>
          <ChevronRightIcon size={5} />
        </Card>
      </Link>
      <Link
        className="link-unstyled"
        to={getSettingsPath('preferences-center')}
      >
        <Card justify="space-between" py={6} mb={2}>
          <Card.CardTitle>Preferences center</Card.CardTitle>
          <ChevronRightIcon size={5} />
        </Card>
      </Link>
      <Link
        className="link-unstyled"
        to={getSettingsPath('preferences-center-success')}
      >
        <Card justify="space-between" py={6} mb={2}>
          <Card.CardTitle>Preferences center success</Card.CardTitle>
          <ChevronRightIcon size={5} />
        </Card>
      </Link>
    </>
  );
}

export default SubscriberPrefencesCenter;
