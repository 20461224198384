import { Auth } from 'aws-amplify';

import { handleCognitoError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export default async function authUpdateUserDetails({
  name,
  email,
}: {
  name: string;
  email: string;
}) {
  if (isUndefined(name)) {
    mandatory('name');
  }
  if (isUndefined(email)) {
    mandatory('email');
  }

  const guid = metrics.start('authUpdateUserDetails');
  logger.info('API:authUpdateUserDetails');

  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.updateUserAttributes(user, { name, email });
    metrics.end('authUpdateUserDetails', guid);
    return response;
  } catch (error) {
    metrics.fail('authUpdateUserDetails', guid);
    const cognitoError =
      error instanceof Error
        ? handleCognitoError({
            originalError: error,
            errorLocation: 'api/authUpdateUserDetails',
          })
        : error;

    throw cognitoError;
  }
}
