import { atom } from 'recoil';

export const changingProperty = atom({
  key: 'changingProperty',
  default: false,
});

export const changingCampaign = atom({
  key: 'changingCampaign',
  default: false,
});
