/* eslint no-useless-escape: "off" */

const TIME_ZONES = {
  '(GMT-11:00) Niue Time': 'Pacific/Niue',
  '(GMT-11:00) Samoa Standard Time': 'Pacific/Pago_Pago',
  '(GMT-10:00) Cook Islands Standard Time': 'Pacific/Rarotonga',
  '(GMT-10:00) Hawaii-Aleutian Standard Time': 'Pacific/Honolulu',
  '(GMT-10:00) Hawaii-Aleutian Time': 'America/Adak',
  '(GMT-10:00) Tahiti Time': 'Pacific/Tahiti',
  '(GMT-09:30) Marquesas Time': 'Pacific/Marquesas',
  '(GMT-09:00) Alaska Time - Anchorage': 'America/Anchorage',
  '(GMT-09:00) Alaska Time - Juneau': 'America/Juneau',
  '(GMT-09:00) Alaska Time - Metlakatla': 'America/Metlakatla',
  '(GMT-09:00) Alaska Time - Nome': 'America/Nome',
  '(GMT-09:00) Alaska Time - Sitka': 'America/Sitka',
  '(GMT-09:00) Alaska Time - Yakutat': 'America/Yakutat',
  '(GMT-09:00) Gambier Time': 'Pacific/Gambier',
  '(GMT-08:00) Pacific Time - Los Angeles': 'America/Los_Angeles',
  '(GMT-08:00) Pacific Time - Tijuana': 'America/Tijuana',
  '(GMT-08:00) Pacific Time - Vancouver': 'America/Vancouver',
  '(GMT-08:00) Pitcairn Time': 'Pacific/Pitcairn',
  '(GMT-07:00) Mexican Pacific Standard Time': 'America/Hermosillo',
  '(GMT-07:00) Mexican Pacific Time - Chihuahua': 'America/Chihuahua',
  '(GMT-07:00) Mexican Pacific Time - Mazatlan': 'America/Mazatlan',
  '(GMT-07:00) Mountain Standard Time - Creston': 'America/Creston',
  '(GMT-07:00) Mountain Standard Time - Dawson Creek': 'America/Dawson_Creek',
  '(GMT-07:00) Mountain Standard Time - Phoenix': 'America/Phoenix',
  '(GMT-07:00) Mountain Time - Boise': 'America/Boise',
  '(GMT-07:00) Mountain Time - Cambridge Bay': 'America/Cambridge_Bay',
  '(GMT-07:00) Mountain Time - Dawson': 'America/Dawson',
  '(GMT-07:00) Mountain Time - Denver': 'America/Denver',
  '(GMT-07:00) Mountain Time - Edmonton': 'America/Edmonton',
  '(GMT-07:00) Mountain Time - Inuvik': 'America/Inuvik',
  '(GMT-07:00) Mountain Time - Ojinaga': 'America/Ojinaga',
  '(GMT-07:00) Mountain Time - Whitehorse': 'America/Whitehorse',
  '(GMT-07:00) Mountain Time - Yellowknife': 'America/Yellowknife',
  '(GMT-06:00) Central Standard Time - Belize': 'America/Belize',
  '(GMT-06:00) Central Standard Time - Costa Rica': 'America/Costa_Rica',
  '(GMT-06:00) Central Standard Time - El Salvador': 'America/El_Salvador',
  '(GMT-06:00) Central Standard Time - Guatemala': 'America/Guatemala',
  '(GMT-06:00) Central Standard Time - Managua': 'America/Managua',
  '(GMT-06:00) Central Standard Time - Regina': 'America/Regina',
  '(GMT-06:00) Central Standard Time - Swift Current': 'America/Swift_Current',
  '(GMT-06:00) Central Standard Time - Tegucigalpa': 'America/Tegucigalpa',
  '(GMT-06:00) Central Time - Bahia Banderas': 'America/Bahia_Banderas',
  '(GMT-06:00) Central Time - Beulah, North Dakota':
    'America/North_Dakota/Beulah',
  '(GMT-06:00) Central Time - Center, North Dakota':
    'America/North_Dakota/Center',
  '(GMT-06:00) Central Time - Chicago': 'America/Chicago',
  '(GMT-06:00) Central Time - Knox, Indiana': 'America/Indiana/Knox',
  '(GMT-06:00) Central Time - Matamoros': 'America/Matamoros',
  '(GMT-06:00) Central Time - Menominee': 'America/Menominee',
  '(GMT-06:00) Central Time - Merida': 'America/Merida',
  '(GMT-06:00) Central Time - Mexico City': 'America/Mexico_City',
  '(GMT-06:00) Central Time - Monterrey': 'America/Monterrey',
  '(GMT-06:00) Central Time - New Salem, North Dakota':
    'America/North_Dakota/New_Salem',
  '(GMT-06:00) Central Time - Rainy River': 'America/Rainy_River',
  '(GMT-06:00) Central Time - Rankin Inlet': 'America/Rankin_Inlet',
  '(GMT-06:00) Central Time - Resolute': 'America/Resolute',
  '(GMT-06:00) Central Time - Tell City, Indiana': 'America/Indiana/Tell_City',
  '(GMT-06:00) Central Time - Winnipeg': 'America/Winnipeg',
  '(GMT-06:00) Galapagos Time': 'Pacific/Galapagos',
  '(GMT-05:00) Acre Standard Time - Eirunepe': 'America/Eirunepe',
  '(GMT-05:00) Acre Standard Time - Rio Branco': 'America/Rio_Branco',
  '(GMT-05:00) Colombia Standard Time': 'America/Bogota',
  '(GMT-05:00) Cuba Time': 'America/Havana',
  '(GMT-05:00) Easter Island Time': 'Pacific/Easter',
  '(GMT-05:00) Eastern Standard Time - Atikokan': 'America/Atikokan',
  '(GMT-05:00) Eastern Standard Time - Cancun': 'America/Cancun',
  '(GMT-05:00) Eastern Standard Time - Jamaica': 'America/Jamaica',
  '(GMT-05:00) Eastern Standard Time - Panama': 'America/Panama',
  '(GMT-05:00) Eastern Time - Detroit': 'America/Detroit',
  '(GMT-05:00) Eastern Time - Grand Turk': 'America/Grand_Turk',
  '(GMT-05:00) Eastern Time - Indianapolis': 'America/Indiana/Indianapolis',
  '(GMT-05:00) Eastern Time - Iqaluit': 'America/Iqaluit',
  '(GMT-05:00) Eastern Time - Louisville': 'America/Kentucky/Louisville',
  '(GMT-05:00) Eastern Time - Marengo, Indiana': 'America/Indiana/Marengo',
  '(GMT-05:00) Eastern Time - Monticellol, Kentucky':
    'America/Kentucky/Monticello',
  '(GMT-05:00) Eastern Time - Nassau': 'America/Nassau',
  '(GMT-05:00) Eastern Time - New York': 'America/New_York',
  '(GMT-05:00) Eastern Time - Nipigon': 'America/Nipigon',
  '(GMT-05:00) Eastern Time - Pangnirtung': 'America/Pangnirtung',
  '(GMT-05:00) Eastern Time - Petersburg, Indiana':
    'America/Indiana/Petersburg',
  '(GMT-05:00) Eastern Time - Port-au-Prince': 'America/Port-au-Prince',
  '(GMT-05:00) Eastern Time - Thunder Bay': 'America/Thunder_Bay',
  '(GMT-05:00) Eastern Time - Toronto': 'America/Toronto',
  '(GMT-05:00) Eastern Time - Vevay, Indiana': 'America/Indiana/Vevay',
  '(GMT-05:00) Eastern Time - Vincennes, Indiana': 'America/Indiana/Vincennes',
  '(GMT-05:00) Eastern Time - Winamac, Indiana': 'America/Indiana/Winamac',
  '(GMT-05:00) Ecuador Time': 'America/Guayaquil',
  '(GMT-05:00) Peru Standard Time': 'America/Lima',
  '(GMT-04:00) Amazon Standard Time - Boa Vista': 'America/Boa_Vista',
  '(GMT-04:00) Amazon Standard Time - Campo Grande': 'America/Campo_Grande',
  '(GMT-04:00) Amazon Standard Time - Cuiaba': 'America/Cuiaba',
  '(GMT-04:00) Amazon Standard Time - Manaus': 'America/Manaus',
  '(GMT-04:00) Amazon Standard Time - Porto Velho': 'America/Porto_Velho',
  '(GMT-04:00) Atlantic Standard Time - Barbados': 'America/Barbados',
  '(GMT-04:00) Atlantic Standard Time - Blanc-Sablon': 'America/Blanc-Sablon',
  '(GMT-04:00) Atlantic Standard Time - Curaçao': 'America/Curacao',
  '(GMT-04:00) Atlantic Standard Time - Martinique': 'America/Martinique',
  '(GMT-04:00) Atlantic Standard Time - Port of Spain': 'America/Port_of_Spain',
  '(GMT-04:00) Atlantic Standard Time - Puerto Rico': 'America/Puerto_Rico',
  '(GMT-04:00) Atlantic Standard Time - Santo Domingo': 'America/Santo_Domingo',
  '(GMT-04:00) Atlantic Time - Bermuda': 'Atlantic/Bermuda',
  '(GMT-04:00) Atlantic Time - Glace Bay': 'America/Glace_Bay',
  '(GMT-04:00) Atlantic Time - Goose Bay': 'America/Goose_Bay',
  '(GMT-04:00) Atlantic Time - Halifax': 'America/Halifax',
  '(GMT-04:00) Atlantic Time - Moncton': 'America/Moncton',
  '(GMT-04:00) Atlantic Time - Thule': 'America/Thule',
  '(GMT-04:00) Bolivia Time': 'America/La_Paz',
  '(GMT-04:00) Guyana Time': 'America/Guyana',
  '(GMT-04:00) Venezuela Time': 'America/Caracas',
  '(GMT-03:30) Newfoundland Time': 'America/St_Johns',
  '(GMT-03:00) Argentina Standard Time - Buenos Aires':
    'America/Argentina/Buenos_Aires',
  '(GMT-03:00) Argentina Standard Time - Catamarca':
    'America/Argentina/Catamarca',
  '(GMT-03:00) Argentina Standard Time - Cordoba': 'America/Argentina/Cordoba',
  '(GMT-03:00) Argentina Standard Time - Jujuy': 'America/Argentina/Jujuy',
  '(GMT-03:00) Argentina Standard Time - La Rioja':
    'America/Argentina/La_Rioja',
  '(GMT-03:00) Argentina Standard Time - Mendoza': 'America/Argentina/Mendoza',
  '(GMT-03:00) Argentina Standard Time - Rio Gallegos':
    'America/Argentina/Rio_Gallegos',
  '(GMT-03:00) Argentina Standard Time - Salta': 'America/Argentina/Salta',
  '(GMT-03:00) Argentina Standard Time - San Juan':
    'America/Argentina/San_Juan',
  '(GMT-03:00) Argentina Standard Time - San Luis':
    'America/Argentina/San_Luis',
  '(GMT-03:00) Argentina Standard Time - Tucuman': 'America/Argentina/Tucuman',
  '(GMT-03:00) Argentina Standard Time - Ushuaia': 'America/Argentina/Ushuaia',
  '(GMT-03:00) Brasilia Standard Time - Araguaina': 'America/Araguaina',
  '(GMT-03:00) Brasilia Standard Time - Bahia': 'America/Bahia',
  '(GMT-03:00) Brasilia Standard Time - Belem': 'America/Belem',
  '(GMT-03:00) Brasilia Standard Time - Fortaleza': 'America/Fortaleza',
  '(GMT-03:00) Brasilia Standard Time - Maceio': 'America/Maceio',
  '(GMT-03:00) Brasilia Standard Time - Recife': 'America/Recife',
  '(GMT-03:00) Brasilia Standard Time - Santarem': 'America/Santarem',
  '(GMT-03:00) Brasilia Standard Time - Sao Paulo': 'America/Sao_Paulo',
  '(GMT-03:00) Chile Time': 'America/Santiago',
  '(GMT-03:00) Falkland Islands Standard Time': 'Atlantic/Stanley',
  '(GMT-03:00) French Guiana Time': 'America/Cayenne',
  '(GMT-03:00) Palmer Time': 'Antarctica/Palmer',
  '(GMT-03:00) Paraguay Time': 'America/Asuncion',
  '(GMT-03:00) Rothera Time': 'Antarctica/Rothera',
  '(GMT-03:00) St Pierre & Miquelon Time': 'America/Miquelon',
  '(GMT-03:00) Suriname Time': 'America/Paramaribo',
  '(GMT-03:00) Uruguay Standard Time': 'America/Montevideo',
  '(GMT-02:00) Fernando de Noronha Standard Time': 'America/Noronha',
  '(GMT-02:00) South Georgia Time': 'Atlantic/South_Georgia',
  '(GMT-01:00) Azores Time': 'Atlantic/Azores',
  '(GMT-01:00) Cape Verde Standard Time': 'Atlantic/Cape_Verde',
  '(GMT-01:00) East Greenland Time': 'America/Scoresbysund',
  '(GMT+00:00) Greenwich Mean Time': 'Etc/GMT',
  '(GMT+00:00) Greenwich Mean Time - Abidjan': 'Africa/Abidjan',
  '(GMT+00:00) Greenwich Mean Time - Accra': 'Africa/Accra',
  '(GMT+00:00) Greenwich Mean Time - Bissau': 'Africa/Bissau',
  '(GMT+00:00) Greenwich Mean Time - Danmarkshavn': 'America/Danmarkshavn',
  '(GMT+00:00) Greenwich Mean Time - Monrovia': 'Africa/Monrovia',
  '(GMT+00:00) Greenwich Mean Time - Reykjavik': 'Atlantic/Reykjavik',
  '(GMT+00:00) Greenwich Mean Time - São Tomé': 'Africa/Sao_Tome',
  '(GMT+00:00) Ireland Time': 'Europe/Dublin',
  '(GMT+00:00) United Kingdom Time': 'Europe/London',
  '(GMT+00:00) Western European Time - Canary': 'Atlantic/Canary',
  '(GMT+00:00) Western European Time - Faroe': 'Atlantic/Faroe',
  '(GMT+00:00) Western European Time - Lisbon': 'Europe/Lisbon',
  '(GMT+00:00) Western European Time - Madeira': 'Atlantic/Madeira',
  '(GMT+01:00) Central European Standard Time - Algiers': 'Africa/Algiers',
  '(GMT+01:00) Central European Standard Time - Tunis': 'Africa/Tunis',
  '(GMT+01:00) Central European Time - Amsterdam': 'Europe/Amsterdam',
  '(GMT+01:00) Central European Time - Andorra': 'Europe/Andorra',
  '(GMT+01:00) Central European Time - Belgrade': 'Europe/Belgrade',
  '(GMT+01:00) Central European Time - Berlin': 'Europe/Berlin',
  '(GMT+01:00) Central European Time - Brussels': 'Europe/Brussels',
  '(GMT+01:00) Central European Time - Budapest': 'Europe/Budapest',
  '(GMT+01:00) Central European Time - Ceuta': 'Africa/Ceuta',
  '(GMT+01:00) Central European Time - Copenhagen': 'Europe/Copenhagen',
  '(GMT+01:00) Central European Time - Gibraltar': 'Europe/Gibraltar',
  '(GMT+01:00) Central European Time - Luxembourg': 'Europe/Luxembourg',
  '(GMT+01:00) Central European Time - Madrid': 'Europe/Madrid',
  '(GMT+01:00) Central European Time - Malta': 'Europe/Malta',
  '(GMT+01:00) Central European Time - Monaco': 'Europe/Monaco',
  '(GMT+01:00) Central European Time - Oslo': 'Europe/Oslo',
  '(GMT+01:00) Central European Time - Paris': 'Europe/Paris',
  '(GMT+01:00) Central European Time - Prague': 'Europe/Prague',
  '(GMT+01:00) Central European Time - Rome': 'Europe/Rome',
  '(GMT+01:00) Central European Time - Stockholm': 'Europe/Stockholm',
  '(GMT+01:00) Central European Time - Tirane': 'Europe/Tirane',
  '(GMT+01:00) Central European Time - Vienna': 'Europe/Vienna',
  '(GMT+01:00) Central European Time - Warsaw': 'Europe/Warsaw',
  '(GMT+01:00) Central European Time - Zurich': 'Europe/Zurich',
  '(GMT+01:00) Morocco Time': 'Africa/Casablanca',
  '(GMT+01:00) West Africa Standard Time - Lagos': 'Africa/Lagos',
  '(GMT+01:00) West Africa Standard Time - Ndjamena': 'Africa/Ndjamena',
  '(GMT+01:00) Western Sahara Time': 'Africa/El_Aaiun',
  '(GMT+02:00) Central Africa Time - Khartoum': 'Africa/Khartoum',
  '(GMT+02:00) Central Africa Time - Maputo': 'Africa/Maputo',
  '(GMT+02:00) Central Africa Time - Windhoek': 'Africa/Windhoek',
  '(GMT+02:00) Eastern European Standard Time - Cairo': 'Africa/Cairo',
  '(GMT+02:00) Eastern European Standard Time - Kaliningrad':
    'Europe/Kaliningrad',
  '(GMT+02:00) Eastern European Standard Time - Tripoli': 'Africa/Tripoli',
  '(GMT+02:00) Eastern European Time - Amman': 'Asia/Amman',
  '(GMT+02:00) Eastern European Time - Athens': 'Europe/Athens',
  '(GMT+02:00) Eastern European Time - Beirut': 'Asia/Beirut',
  '(GMT+02:00) Eastern European Time - Bucharest': 'Europe/Bucharest',
  '(GMT+02:00) Eastern European Time - Chisinau': 'Europe/Chisinau',
  '(GMT+02:00) Eastern European Time - Damascus': 'Asia/Damascus',
  '(GMT+02:00) Eastern European Time - Gaza': 'Asia/Gaza',
  '(GMT+02:00) Eastern European Time - Hebron': 'Asia/Hebron',
  '(GMT+02:00) Eastern European Time - Helsinki': 'Europe/Helsinki',
  '(GMT+02:00) Eastern European Time - Kiev': 'Europe/Kiev',
  '(GMT+02:00) Eastern European Time - Nicosia': 'Asia/Nicosia',
  '(GMT+02:00) Eastern European Time - Riga': 'Europe/Riga',
  '(GMT+02:00) Eastern European Time - Sofia': 'Europe/Sofia',
  '(GMT+02:00) Eastern European Time - Tallinn': 'Europe/Tallinn',
  '(GMT+02:00) Eastern European Time - Uzhhorod': 'Europe/Uzhgorod',
  '(GMT+02:00) Eastern European Time - Vilnius': 'Europe/Vilnius',
  '(GMT+02:00) Eastern European Time - Zaporozhye': 'Europe/Zaporozhye',
  '(GMT+02:00) Israel Time': 'Asia/Jerusalem',
  '(GMT+02:00) South Africa Standard Time': 'Africa/Johannesburg',
  '(GMT+03:00) Arabian Standard Time - Baghdad': 'Asia/Baghdad',
  '(GMT+03:00) Arabian Standard Time - Qatar': 'Asia/Qatar',
  '(GMT+03:00) Arabian Standard Time - Riyadh': 'Asia/Riyadh',
  '(GMT+03:00) East Africa Time - Juba': 'Africa/Juba',
  '(GMT+03:00) East Africa Time - Nairobi': 'Africa/Nairobi',
  '(GMT+03:00) Moscow Standard Time - Minsk': 'Europe/Minsk',
  '(GMT+03:00) Moscow Standard Time - Moscow': 'Europe/Moscow',
  '(GMT+03:00) Moscow Standard Time - Simferopol': 'Europe/Simferopol',
  '(GMT+03:00) Syowa Time': 'Antarctica/Syowa',
  '(GMT+03:00) Turkey Time': 'Europe/Istanbul',
  '(GMT+03:30) Iran Time': 'Asia/Tehran',
  '(GMT+04:00) Armenia Standard Time': 'Asia/Yerevan',
  '(GMT+04:00) Azerbaijan Standard Time': 'Asia/Baku',
  '(GMT+04:00) Georgia Standard Time': 'Asia/Tbilisi',
  '(GMT+04:00) Gulf Standard Time': 'Asia/Dubai',
  '(GMT+04:00) Mauritius Standard Time': 'Indian/Mauritius',
  '(GMT+04:00) Réunion Time': 'Indian/Reunion',
  '(GMT+04:00) Samara Standard Time': 'Europe/Samara',
  '(GMT+04:00) Seychelles Time': 'Indian/Mahe',
  '(GMT+04:00) Volgograd Standard Time': 'Europe/Volgograd',
  '(GMT+04:30) Afghanistan Time': 'Asia/Kabul',
  '(GMT+05:00) French Southern & Antarctic Time': 'Indian/Kerguelen',
  '(GMT+05:00) Maldives Time': 'Indian/Maldives',
  '(GMT+05:00) Mawson Time': 'Antarctica/Mawson',
  '(GMT+05:00) Pakistan Standard Time': 'Asia/Karachi',
  '(GMT+05:00) Tajikistan Time': 'Asia/Dushanbe',
  '(GMT+05:00) Turkmenistan Standard Time': 'Asia/Ashgabat',
  '(GMT+05:00) Uzbekistan Standard Time - Samarkand': 'Asia/Samarkand',
  '(GMT+05:00) Uzbekistan Standard Time - Tashkent': 'Asia/Tashkent',
  '(GMT+05:00) West Kazakhstan Time - Aktau': 'Asia/Aqtau',
  '(GMT+05:00) West Kazakhstan Time - Aqtobe': 'Asia/Aqtobe',
  '(GMT+05:00) West Kazakhstan Time - Oral': 'Asia/Oral',
  '(GMT+05:00) West Kazakhstan Time - Qyzylorda': 'Asia/Qyzylorda',
  '(GMT+05:00) Yekaterinburg Standard Time': 'Asia/Yekaterinburg',
  '(GMT+05:30) India Standard Time - Colombo': 'Asia/Colombo',
  '(GMT+05:30) India Standard Time - Kolkata': 'Asia/Kolkata',
  '(GMT+05:45) Nepal Time': 'Asia/Kathmandu',
  '(GMT+06:00) Bangladesh Standard Time': 'Asia/Dhaka',
  '(GMT+06:00) Bhutan Time': 'Asia/Thimphu',
  '(GMT+06:00) East Kazakhstan Time - Almaty': 'Asia/Almaty',
  '(GMT+06:00) Indian Ocean Time': 'Indian/Chagos',
  '(GMT+06:00) Kyrgyzstan Time': 'Asia/Bishkek',
  '(GMT+06:00) Omsk Standard Time': 'Asia/Omsk',
  '(GMT+06:00) Urumqi Time': 'Asia/Urumqi',
  '(GMT+06:00) Vostok Time': 'Antarctica/Vostok',
  '(GMT+06:30) Cocos Islands Time': 'Indian/Cocos',
  '(GMT+07:00) Christmas Island Time': 'Indian/Christmas',
  '(GMT+07:00) Davis Time': 'Antarctica/Davis',
  '(GMT+07:00) Hovd Standard Time': 'Asia/Hovd',
  '(GMT+07:00) Indochina Time - Bangkok': 'Asia/Bangkok',
  '(GMT+07:00) Indochina Time - Ho Chi Minh City': 'Asia/Ho_Chi_Minh',
  '(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk': 'Asia/Krasnoyarsk',
  '(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk': 'Asia/Novokuznetsk',
  '(GMT+07:00) Novosibirsk Standard Time': 'Asia/Novosibirsk',
  '(GMT+07:00) Western Indonesia Time - Jakarta': 'Asia/Jakarta',
  '(GMT+07:00) Western Indonesia Time - Pontianak': 'Asia/Pontianak',
  '(GMT+08:00) Australian Western Standard Time': 'Australia/Perth',
  '(GMT+08:00) Brunei Darussalam Time': 'Asia/Brunei',
  '(GMT+08:00) Central Indonesia Time': 'Asia/Makassar',
  '(GMT+08:00) China Standard Time - Macao': 'Asia/Macau',
  '(GMT+08:00) China Standard Time - Shanghai': 'Asia/Shanghai',
  '(GMT+08:00) Hong Kong Standard Time': 'Asia/Hong_Kong',
  '(GMT+08:00) Irkutsk Standard Time': 'Asia/Irkutsk',
  '(GMT+08:00) Malaysia Time - Kuala Lumpur': 'Asia/Kuala_Lumpur',
  '(GMT+08:00) Malaysia Time - Kuching': 'Asia/Kuching',
  '(GMT+08:00) Philippine Standard Time': 'Asia/Manila',
  '(GMT+08:00) Singapore Standard Time': 'Asia/Singapore',
  '(GMT+08:00) Taipei Standard Time': 'Asia/Taipei',
  '(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan': 'Asia/Choibalsan',
  '(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar': 'Asia/Ulaanbaatar',
  '(GMT+08:45) Australian Central Western Standard Time': 'Australia/Eucla',
  '(GMT+09:00) East Timor Time': 'Asia/Dili',
  '(GMT+09:00) Eastern Indonesia Time': 'Asia/Jayapura',
  '(GMT+09:00) Japan Standard Time': 'Asia/Tokyo',
  '(GMT+09:00) Korean Standard Time - Pyongyang': 'Asia/Pyongyang',
  '(GMT+09:00) Korean Standard Time - Seoul': 'Asia/Seoul',
  '(GMT+09:00) Palau Time': 'Pacific/Palau',
  '(GMT+09:00) Yakutsk Standard Time - Yakutsk': 'Asia/Yakutsk',
  '(GMT+09:30) Australian Central Standard Time': 'Australia/Darwin',
  '(GMT+10:00) Australian Eastern Standard Time - Brisbane':
    'Australia/Brisbane',
  '(GMT+10:00) Australian Eastern Standard Time - Lindeman':
    'Australia/Lindeman',
  '(GMT+10:00) Chamorro Standard Time': 'Pacific/Guam',
  '(GMT+10:00) Chuuk Time': 'Pacific/Chuuk',
  '(GMT+10:00) Dumont-d’Urville Time': 'Antarctica/DumontDUrville',
  '(GMT+10:00) Papua New Guinea Time': 'Pacific/Port_Moresby',
  '(GMT+10:00) Vladivostok Standard Time - Vladivostok': 'Asia/Vladivostok',
  '(GMT+10:30) Central Australia Time - Adelaide': 'Australia/Adelaide',
  '(GMT+10:30) Central Australia Time - Broken Hill': 'Australia/Broken_Hill',
  '(GMT+11:00) Casey Time': 'Antarctica/Casey',
  '(GMT+11:00) Eastern Australia Time - Currie': 'Australia/Currie',
  '(GMT+11:00) Eastern Australia Time - Hobart': 'Australia/Hobart',
  '(GMT+11:00) Eastern Australia Time - Macquarie': 'Antarctica/Macquarie',
  '(GMT+11:00) Eastern Australia Time - Melbourne': 'Australia/Melbourne',
  '(GMT+11:00) Eastern Australia Time - Sydney': 'Australia/Sydney',
  '(GMT+11:00) Kosrae Time': 'Pacific/Kosrae',
  '(GMT+11:00) Lord Howe Time': 'Australia/Lord_Howe',
  '(GMT+11:00) Magadan Standard Time': 'Asia/Magadan',
  '(GMT+11:00) New Caledonia Standard Time': 'Pacific/Noumea',
  '(GMT+11:00) Ponape Time': 'Pacific/Pohnpei',
  '(GMT+11:00) Sakhalin Standard Time': 'Asia/Sakhalin',
  '(GMT+11:00) Solomon Islands Time': 'Pacific/Guadalcanal',
  '(GMT+11:00) Vanuatu Standard Time': 'Pacific/Efate',
  '(GMT+12:00) Anadyr Standard Time': 'Asia/Anadyr',
  '(GMT+12:00) Fiji Time': 'Pacific/Fiji',
  '(GMT+12:00) Gilbert Islands Time': 'Pacific/Tarawa',
  '(GMT+12:00) Marshall Islands Time - Kwajalein': 'Pacific/Kwajalein',
  '(GMT+12:00) Marshall Islands Time - Majuro': 'Pacific/Majuro',
  '(GMT+12:00) Nauru Time': 'Pacific/Nauru',
  '(GMT+12:00) Norfolk Island Time': 'Pacific/Norfolk',
  '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time': 'Asia/Kamchatka',
  '(GMT+12:00) Tuvalu Time': 'Pacific/Funafuti',
  '(GMT+12:00) Wake Island Time': 'Pacific/Wake',
  '(GMT+12:00) Wallis & Futuna Time': 'Pacific/Wallis',
  '(GMT+13:00) New Zealand Time': 'Pacific/Auckland',
  '(GMT+13:00) Phoenix Islands Time': 'Pacific/Enderbury',
  '(GMT+13:00) Tokelau Time': 'Pacific/Fakaofo',
  '(GMT+13:00) Tonga Standard Time': 'Pacific/Tongatapu',
  '(GMT+13:45) Chatham Time': 'Pacific/Chatham',
  '(GMT+14:00) Apia Time': 'Pacific/Apia',
  '(GMT+14:00) Line Islands Time': 'Pacific/Kiritimati',
};

const TIME_ZONE_OPTIONS = Object.keys(TIME_ZONES).map(timeZone => ({
  label: timeZone,
  value: TIME_ZONES[timeZone],
}));

const getOffsetMinutes = timezoneName => {
  let timezone = TIME_ZONE_OPTIONS.find(
    option => option.value === timezoneName
  );
  if (!timezone) {
    timezone = {
      label: '(GMT+00:00) Greenwich Mean Time',
      value: 'Etc/GMT',
    };
  }
  const offset = timezone.label
    .split(' ')[0]
    .replace(/[\(\)]/g, '')
    .replace(/^GMT/, '');
  const offsetSignString = offset.slice(0, 2);
  const offsetHourString = offset.slice(1, 3);
  const offsetMinuteString = offset.slice(4, 6);
  const offsetMinutes =
    Number(offsetHourString) * 60 + Number(offsetMinuteString);
  const offsetNumber = Number(`${offsetSignString}${offsetMinutes}`);
  return offsetNumber;
};

export { getOffsetMinutes, TIME_ZONES, TIME_ZONE_OPTIONS };
