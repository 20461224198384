import {
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { BackLink } from '../../common';

function SignUpSetting({
  setSettingsPanel,
  emailInputLabel,
  signupFooterText,
  signupButtonLabel,
  subscribeError,
  ogImageURL,
  privacyPolicyLinkText,
  privacyPolicyURL,
  isFirstNameLastNameEnabled,
  firstNameLabel,
  lastNameLabel,
  settings,
}) {
  const handleFieldChange = (type, field, event) => {
    settings.setCampaignSettingValue({
      settingType: type,
      settingKey: field,
      settingValue: event.target.value,
    });
  };

  return (
    <>
      <Button
        variant="link"
        className="m-0 p-0"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to Subscribe" />
      </Button>

      <Heading variant="h3" mt={6} mb={4}>
        Sign up section
      </Heading>

      <FormControl mb={6}>
        <FormControl.FormLabel>Email address</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Email address"
          value={emailInputLabel}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
              'emailInputLabel',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={6}>
        <FormControl.FormLabel>Legal requirements text</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Legal requirements"
          value={signupFooterText}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
              'signupFooterText',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={6}>
        <FormControl.FormLabel>Sign up button</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Sign up"
          value={signupButtonLabel}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
              'signupButtonLabel',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={6}>
        <FormControl.FormLabel>Privacy policy text</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Privacy policy"
          value={privacyPolicyLinkText}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
              'privacyPolicyLinkText',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={6}>
        <FormControl.FormLabel>Privacy policy URL</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Privacy policy"
          value={privacyPolicyURL}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
              'privacyPolicyURL',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={6}>
        <FormControl.FormLabel>Subscribe error text</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Subscribe error"
          value={subscribeError}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
              'subscribeError',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={2}>
        <FormControl.FormLabel>Open Graph image URL</FormControl.FormLabel>
        <Input
          type="text"
          placeholder=""
          value={ogImageURL}
          onChange={event =>
            handleFieldChange(
              CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
              'ogImageURL',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <Text size="sm" color="gray.600" mb="8">
        Image that will display when this subscription page is shared on social
        media
      </Text>

      <>
        <Heading variant="h3" mt={6} mb={2}>
          Subscription fields
        </Heading>
        <FormControl>
          <FormControl.FormHelperText mb={4}>
            Choose any additional fields for your Subscription page
          </FormControl.FormHelperText>
          <Flex mb={4}>
            <Checkbox mr={3} isDisabled={true} />
            <FormControl.FormLabel color="gray.600" mb={0}>
              Email address (mandatory)
            </FormControl.FormLabel>
          </Flex>
          <Flex mb={8}>
            <Checkbox
              mr={3}
              isChecked={isFirstNameLastNameEnabled}
              onChange={() =>
                handleFieldChange(
                  CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
                  'isFirstNameLastNameEnabled',
                  { target: { value: !isFirstNameLastNameEnabled } }
                )
              }
            />
            <FormControl.FormLabel color="gray.900" mb={0}>
              First and last name
            </FormControl.FormLabel>
          </Flex>
        </FormControl>
        {isFirstNameLastNameEnabled && (
          <>
            <FormControl mb={8}>
              <FormControl.FormLabel>First name</FormControl.FormLabel>
              <Input
                type="text"
                placeholder="First name"
                value={firstNameLabel}
                onChange={event =>
                  handleFieldChange(
                    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
                    'firstNameLabel',
                    event
                  )
                }
                autoComplete="off"
                required
              />
            </FormControl>
            <FormControl mb={8}>
              <FormControl.FormLabel>Last name</FormControl.FormLabel>
              <Input
                type="text"
                placeholder="Last name"
                value={lastNameLabel}
                onChange={event =>
                  handleFieldChange(
                    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
                    'lastNameLabel',
                    event
                  )
                }
                autoComplete="off"
                required
              />
            </FormControl>
          </>
        )}
      </>
    </>
  );
}

SignUpSetting.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  emailInputLabel: PropTypes.string.isRequired,
  signupFooterText: PropTypes.string.isRequired,
  signupButtonLabel: PropTypes.string.isRequired,
  subscribeError: PropTypes.string.isRequired,
  ogImageURL: PropTypes.string.isRequired,
  privacyPolicyLinkText: PropTypes.string.isRequired,
  privacyPolicyURL: PropTypes.string.isRequired,
  isFirstNameLastNameEnabled: PropTypes.bool,
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  settings: PropTypes.node.isRequired,
};

SignUpSetting.defaultProps = {
  isFirstNameLastNameEnabled: false,
  firstNameLabel: '',
  lastNameLabel: '',
};

export default SignUpSetting;
