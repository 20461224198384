import { EditorState } from 'draft-js';
import { useCallback, useRef, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';

import center from 'assets/svg/align-center.svg';
import justify from 'assets/svg/align-justify.svg';
import left from 'assets/svg/align-left.svg';
import right from 'assets/svg/align-right.svg';
import bold from 'assets/svg/bold.svg';
import italic from 'assets/svg/italic.svg';
import link from 'assets/svg/link.svg';
import ordered from 'assets/svg/list-ordered.svg';
import unordered from 'assets/svg/list-unordered.svg';
import unlink from 'assets/svg/unlink.svg';

import * as url from 'common/url';
import * as utility from 'common/utility';
import useMutationObservable from 'hooks/useMutationObservable';

import {
  EDITOR_TOOLBAR_CONFIGURATIONS,
  EDITOR_TOOLBAR_OPTIONS,
} from 'common/constants';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.css';

export interface ToolbarOptions {
  options: string[];
  inline: any; // TODO - define this fully
  blockType: any; // TODO - define this fully
  list: any; // TODO - define this fully
  textAlign: any; // TODO - define this fully
  link: any; // TODO - define this fully
}

export interface NewslettersEditorProps {
  campaignName: string;
  scheduledUnixTime?: number;
  editorState?: EditorState;
  isDisabled?: boolean;
  onChange: (newState: EditorState) => void;
  onFocus?: () => void;
  onLinkError: (error: string) => void;
  setEditorRef?: () => void;
  toolbarOptions: string; // TODO - define this as an enum
}

function NewslettersEditor({
  campaignName,
  scheduledUnixTime,
  editorState,
  isDisabled,
  onChange,
  onFocus,
  onLinkError,
  setEditorRef,
  toolbarOptions = EDITOR_TOOLBAR_OPTIONS.DEFAULT,
}: NewslettersEditorProps) {
  const [hasSetFocus, setHasSetFocus] = useState(false);

  const editorRef = useRef(null);

  const onListMutation = useCallback(() => {
    const input = document.querySelector('input#linkTitle') as HTMLInputElement;
    if (input && !hasSetFocus) {
      input.focus();
      setHasSetFocus(true);
    }
    if (!input) {
      setHasSetFocus(false);
    }
  }, [hasSetFocus]);

  useMutationObservable(editorRef.current, onListMutation);

  const handleChange = (newState: EditorState) => {
    onChange(newState);
    onLinkError('');
  };

  const handleLink = (event: Event) => {
    const updatedLink = utility.cloneObject(event);

    if (updatedLink.target.startsWith('http://')) {
      onLinkError(
        "Links cannot start with 'http://', they must start with 'https://'"
      );
      return null;
    }

    if (!updatedLink.target.startsWith('https://')) {
      updatedLink.target = `https://${updatedLink.target}`;
    }

    if (!url.isValidURL(updatedLink.target)) {
      onLinkError('Please enter a valid URL');
      return null;
    }

    updatedLink.target = url.addTrackingParameters(
      updatedLink.target,
      campaignName,
      scheduledUnixTime
    );
    onLinkError('');
    return updatedLink;
  };

  const setToolbarConfig = () => {
    // Set base options
    const toolbar: ToolbarOptions = {
      options: [], // Start with all options disabled
      inline: {
        inDropdown: false,
        options: [], // Start with all options disabled
        bold: { icon: bold },
        italic: { icon: italic },
      },
      blockType: {
        inDropdown: false,
        options: [], // Start with all options disabled
      },
      /*
      fontSize: {
        icon: fontSize,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      },
      */
      list: {
        inDropdown: false,
        options: [], // Start with all options disabled
        unordered: { icon: unordered },
        ordered: { icon: ordered },
      },
      textAlign: {
        inDropdown: false,
        options: [], // Start with all options disabled
        left: { icon: left },
        center: { icon: center },
        right: { icon: right },
        justify: { icon: justify },
      },

      link: {
        inDropdown: false,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_blank',
        options: [], // Start with all options disabled
        link: { icon: link },
        unlink: { icon: unlink },
        linkCallback: handleLink,
      },
      /*
      history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
        undo: { icon: undo },
        redo: { icon: redo },
      },
      */
    };

    // Configure required options
    const toolbarConfig = EDITOR_TOOLBAR_CONFIGURATIONS[toolbarOptions];
    Object.keys(toolbarConfig).forEach(option => {
      toolbar.options.push(option);
      // @ts-ignore
      toolbar[option].options = toolbarConfig[option];
    });

    return toolbar;
  };

  return (
    <FormGroup ref={editorRef}>
      <Editor
        editorState={editorState}
        wrapperClassName="wysiwyg-wrapper"
        editorClassName="wysiwyg-editor"
        toolbar={setToolbarConfig()}
        onEditorStateChange={handleChange}
        onFocus={onFocus}
        readOnly={isDisabled}
        ref={setEditorRef}
        handlePastedText={() => false}
      />
    </FormGroup>
  );
}

export default NewslettersEditor;
