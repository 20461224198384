import * as API from 'api/api';

import {
  QUERY_CACHE_VALUES,
  SUBSCRIBER_LIST_PAGE_SIZE,
} from 'common/constants';
import { Subscriber } from 'common/types';
import { useInfiniteQuery } from 'react-query';

interface PageData {
  pageParam: number;
  pageData: Subscriber[];
}

const useLoadSubscribers = (
  campaignURN: string,
  sortBy: string,
  sortOrder: string,
  searchString: string
) => {
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteQuery<PageData, string>(
    ['subscriber data', sortBy, sortOrder, searchString, campaignURN],
    async ({ pageParam = 0 }) => ({
      pageParam,
      pageData: await API.getSubscriberList({
        campaignURN,
        page: pageParam,
        pageSize: SUBSCRIBER_LIST_PAGE_SIZE,
        sortField: sortBy,
        sortOrder,
        searchString,
      }),
    }),
    {
      cacheTime: QUERY_CACHE_VALUES.SUBSCRIBERS.CACHE_TIME,
      staleTime: QUERY_CACHE_VALUES.SUBSCRIBERS.STALE_TIME,
      getNextPageParam: ({ pageParam, pageData }) =>
        pageData.length === 0 ? undefined : pageParam + 1,
    }
  );

  return {
    items: data?.pages.flatMap(page => page.pageData) ?? [],
    errorMessage: error,
    isLoading: isLoading || isFetchingNextPage,
    loadMore: fetchNextPage,
    hasNextPage: !!hasNextPage,
    refetch,
  };
};

export default useLoadSubscribers;
