import { Link } from 'react-router-dom';

import { ReactComponent as SettingsCog } from 'assets/svg/settings-cog.svg';

import HeaderIconButton from 'components/header/HeaderIconButton';

function Settings() {
  return (
    <Link to="/settings/property" data-cy="settings-link">
      <HeaderIconButton
        aria-label="Settings"
        icon={<SettingsCog />}
        data-cy-action="settings"
      />
    </Link>
  );
}

export default Settings;
