import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

interface SendPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  emailAddress: string;
  handleSend: () => void;
  isSending: boolean;
}

function SendPreviewModal({
  isOpen,
  onClose,
  emailAddress,
  handleSend,
  isSending,
}: SendPreviewModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="small">
      <Modal.Header>
        <Modal.Title>Send preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm">Send a preview of this email to {emailAddress}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSend} isDisabled={isSending}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SendPreviewModal;
