import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function putMarketingTemplates({
  campaignURN = mandatory('campaignURN'),
  templateURN = mandatory('templateURN'),
  content = mandatory('content'),
  name,
}) {
  const guid = metrics.start('putMarketingTemplates');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/marketingtemplate`,
    method: 'POST', // Yes this is deliberate
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      templateURN,
      content,
      name,
    }),
  };
  logger.info(
    `API:putMarketingTemplates /campaigns/${campaignURN}/marketingtemplate/${templateURN}`
  );

  try {
    const response = await axios(config);
    metrics.end('putMarketingTemplates', guid);
    return response.data.templateURN;
  } catch (error) {
    metrics.fail('putMarketingTemplates', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/putMarketingTemplates',
      args: { templateURN },
    });
    throw apiError;
  }
}
