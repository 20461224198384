import {
  Card,
  EditPenIcon,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import EditCustomBlock from 'components/editor/blocks/EditCustomBlock';

import * as blocks from 'common/blocks';

interface CustomBlockProps {
  customBlock: any;
  handleSave: () => void;
  isSaving: boolean;
  // personalisationSettings: any;
}

function CustomBlock({ customBlock, handleSave, isSaving }: CustomBlockProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShow = () => {
    onOpen();
  };

  const blockURN = blocks.getBlockURN(customBlock.textBlockTitle);
  if (!blockURN) return null;

  return (
    <>
      <Flex flexDir="column" gap={3}>
        <Heading variant="h3">Custom Block</Heading>
        <Card
          px={4}
          borderRadius="4px"
          gap={4}
          onClick={handleShow}
          _hover={{
            boxShadow:
              '0px 1px 2px rgba(14, 30, 57, 0.12), 0px 4px 8px rgba(14, 30, 57, 0.08);',
            cursor: 'pointer',
            outline: '1px solid #e3eaf3',
          }}
        >
          <Flex gap={3} width="100%">
            <Heading variant="h4" color="gray.600" flexGrow={1}>
              {blocks.getBlockType(blockURN)}
            </Heading>
            <Spacer />
            <EditPenIcon color="gray.600" />
          </Flex>
        </Card>
      </Flex>
      {isOpen && (
        <EditCustomBlock
          customBlock={customBlock}
          handleSave={handleSave}
          isOpen={isOpen}
          isSaving={isSaving}
          onClose={onClose}
        />
      )}
    </>
  );
}

export default CustomBlock;
