/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Campaign from 'components/settings/campaigns/all/subscription/Campaign';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as campaigns from 'common/campaigns';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';
import { mandatory } from 'common/validation';

import { PROPERTY_SETTING_TYPES } from 'common/constants';

function CampaignSequence() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  const getTrackingParams = ({
    initialSequence = mandatory('initialSequence'),
    updatedSequence = mandatory('updatedSequence'),
    initialPosition = mandatory('initialPosition'),
    updatedPosition = mandatory('updatedPosition'),
    campaignURN = mandatory('campaignURN'),
  }) => {
    const campaignName = campaigns.getCampaignName(campaignURN, globalInfo);
    const campaignNames = updatedSequence
      .map(urn => campaigns.getCampaignName(urn, globalInfo))
      .join(', ');
    return {
      Campaigns: campaignNames,
      'Campaign Previous Order': `${campaignName} - ${initialPosition}`,
      'Campaign Order': `${campaignName} - ${updatedPosition}`,
    };
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const initialSequence = utility.cloneObject(campaignList);
    const [movedItem] = campaignList.splice(result.source.index, 1);
    campaignList.splice(result.destination.index, 0, movedItem);
    const updatedSequence = utility.cloneObject(campaignList);
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_SIGNUP_ORDERING,
      settingKey: 'campaignURNOrder',
      settingValue: campaignList,
    });
    tracker.track({
      eventName: 'Update Multi-Campaign Structure',
      trackingParams: getTrackingParams({
        initialSequence,
        updatedSequence,
        initialPosition: result.source.index + 1,
        updatedPosition: result.destination.index + 1,
        campaignURN: movedItem,
      }),
    });
  };

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns:CampaignSequence:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  const campaignList = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_SIGNUP_ORDERING,
    'campaignURNOrder'
  );

  return (
    <>
      <div className="ebx-h2 mt-4 mb-2 mt-5">Campaigns</div>
      <div className="subscription-description ebx-body-1 mb-4">
        Choose the order in which your Campaigns will appear on the subscription
        pages
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={propertyURN}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {campaignList.map((campaignURN, index) => (
                <Draggable
                  key={campaignURN}
                  draggableId={campaignURN}
                  index={index}
                >
                  {draggable => (
                    <div
                      ref={draggable.innerRef}
                      {...draggable.draggableProps}
                      {...draggable.dragHandleProps}
                      className={`mb-2 ${
                        draggable.isDragging ? 'dragging' : ''
                      }`}
                    >
                      <Campaign
                        key={campaignURN}
                        campaignURN={campaignURN}
                        campaignName={campaigns.getCampaignName(
                          campaignURN,
                          globalInfo
                        )}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default CampaignSequence;
