import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import { ARTICLE_ANALYTICS_SORT_FIELDS, SORT_ORDERS } from 'common/enums';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getCampaignEditionMediaProps {
  campaignURN: string;
  sendStartUnixTime: number;
  sendEndUnixTime: number;
  sortField?: keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS;
  sortOrder?: SORT_ORDERS;
}

export default async function getCampaignEditionMedia({
  campaignURN,
  sendStartUnixTime,
  sendEndUnixTime,
  sortField,
  sortOrder,
}: getCampaignEditionMediaProps) {
  const guid = metrics.start('getCampaignEditionMedia');

  const timeframeQueryParams = `?sendStartUnixTime=${sendStartUnixTime}&sendEndUnixTime=${sendEndUnixTime}`;
  const sortQueryParams =
    sortField && sortOrder ? `&sort=${sortField}&sortOrder=${sortOrder}` : '';

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/campaigns/${campaignURN}/editions/media${timeframeQueryParams}${sortQueryParams}`;

  const config = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  logger.info(
    `API:getCampaignEditionMedia /campaigns/${campaignURN}/editions/media`
  );

  try {
    const response = await axios(config);
    metrics.end('getCampaignEditionMedia', guid);
    return response.data;
  } catch (error) {
    metrics.fail('getCampaignEditionMedia', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getCampaignEditionMedia',
            args: {
              campaignURN,
              sendStartUnixTime,
              sendEndUnixTime,
              sortField,
              sortOrder,
            },
          })
        : error;
    throw apiError;
  }
}
