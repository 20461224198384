import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import { COGNITO_ERROR_MESSAGES } from 'common/constants';

/**
 * Async function to verify MFA code after logging in
 * Uses Amplify SDK's confirmSignIn method to verify the code
 * Once verified, user is authenticated
 * @param {object} user       - Cognito user session object
 * @param {string} code       - Code entered by user for MFA
 * @param {string} mfaType    - Type of MFA - will always be SOFTWARE_TOKEN_MFA for Echobox
 * @returns {object} response - Authenticated user object
 */

export default async function authConfirmSignIn({
  user = mandatory('user'),
  code = mandatory('code'),
  mfaType = mandatory('mfaType'),
}) {
  const guid = metrics.start('authConfirmSignIn');
  const username = authentication.getUsername();
  logger.info(`API:authConfirmSignIn ${username}`);
  try {
    const mfaResponse = await Auth.confirmSignIn(user, code, mfaType);
    if (mfaResponse.signInUserSession) {
      const response = await Auth.currentAuthenticatedUser();
      // Here we are adding a new hasSetupMFA property instead of using the preferredMFA property that comes with the response
      // as there is a bug in amplify which doesn't update preferredMFA if the user's MFA has been disabled for any reason (like
      // in the backend)
      response.hasSetupMFA = true;
      metrics.end('authConfirmSignIn', guid);
      return response;
    }
    throw new Error(COGNITO_ERROR_MESSAGES.InternalServerErrorException);
  } catch (error) {
    metrics.fail('authConfirmSignIn', guid);
    throw errors.handleCognitoError({
      originalError: error,
      errorLocation: 'api/authConfirmSignIn',
      args: { username },
    });
  }
}
