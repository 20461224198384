/* eslint no-use-before-define: "off" */

import {
  MARKETING_EMAIL_TYPES,
  WYSIWYG_TEMPLATE_IDENTIFICATION_REGEX,
  WYSIWYG_TEMPLATE_REMOVAL_REGEX,
} from 'common/constants';

export {
  composeTemplate,
  deriveMarketingEmailType,
  extractDesign,
  removeDesign,
};

function composeTemplate(design, html) {
  return `${html}<!--TEMPLATE:START${JSON.stringify(design)}TEMPLATE:END-->`;
}

function deriveMarketingEmailType(templateContent) {
  const containsDesignComment =
    templateContent.includes('<!--TEMPLATE:START') &&
    templateContent.includes('TEMPLATE:END-->');
  return containsDesignComment
    ? MARKETING_EMAIL_TYPES.WYSIWYG
    : MARKETING_EMAIL_TYPES.HTML;
}

function extractDesign(templateContent) {
  /**
   * Assumes that the template consists of a block of HTML, followed by the
   * "encoded" design JSON, and optionally some following content... this method
   * extracts the second of these elements i.e. the design JSON used by the
   * WYSIWYG editor
   */
  const matches = [
    ...templateContent.matchAll(WYSIWYG_TEMPLATE_IDENTIFICATION_REGEX),
  ];
  return matches.length === 1 ? matches[0][2] : '';
}

function removeDesign(templateContent) {
  /**
   * Assumes that the template consists of a block of HTML, followed by the
   * "encoded" design JSON, and optionally some following content... this method
   * deletes the second of these elements i.e. the design JSON used by the
   * WYSIWYG editor, leaving just the HTML
   */
  templateContent.replaceAll(WYSIWYG_TEMPLATE_REMOVAL_REGEX, '$1$3');
}
