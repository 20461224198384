import { Auth } from 'aws-amplify';

import { handleCognitoError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export default async function authChangePassword({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) {
  if (isUndefined(currentPassword)) {
    mandatory('currentPassword');
  }
  if (isUndefined(newPassword)) {
    mandatory('newPassword');
  }

  const guid = metrics.start('authChangePassword');
  logger.info('API:authChangePassword');

  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, currentPassword, newPassword);
    metrics.end('authChangePassword', guid);
    return;
  } catch (error) {
    metrics.fail('authChangePassword', guid);
    const cognitoError =
      error instanceof Error
        ? handleCognitoError({
            originalError: error,
            errorLocation: 'api/authChangePassword',
          })
        : error;

    throw cognitoError;
  }
}
