import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as zendesk from 'common/zendesk';

function ExportSubscribers({ appName }) {
  const handleContactUs = () => {
    zendesk.openWidget();
    zendesk.prefillWidgetDescription(
      `I want to export subscribers from Echobox to ${appName}`
    );
  };

  return (
    <div className="ebx-body-1">
      Webhooks help keep your mailing list synced with a local list on your
      server. To get started,{' '}
      <Link onClick={handleContactUs}>contact support</Link>.
    </div>
  );
}

ExportSubscribers.propTypes = {
  appName: PropTypes.string.isRequired,
};

export default ExportSubscribers;
