import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import * as zendesk from 'common/zendesk';

import './GenericError.css';

function GenericError({ errorMessage }) {
  let displayMessage = errorMessage;
  if (displayMessage.charAt(displayMessage.length - 1) === '.') {
    displayMessage = displayMessage.substring(0, displayMessage.length - 1);
  }

  return (
    <span className="ebx-h2 text-center w-100">
      <span>{displayMessage} </span>
      <Button
        variant="link"
        className="ebx-h2 p-0 generic-error-link"
        onClick={() => zendesk.openWidget()}
      >
        via our help form.
      </Button>
    </span>
  );
}

GenericError.propTypes = {
  errorMessage: PropTypes.string,
};

GenericError.defaultProps = {
  errorMessage: `There was a problem rendering this page. If the problem continues, please
  contact support`,
};

export default GenericError;
