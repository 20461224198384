import {
  Box,
  Button,
  ButtonGroup,
  ChevronButton,
  ChevronDownIcon,
  Dropdown,
  EditPen1Icon,
  Flex,
  HtmlIcon,
  LightingHighlightIcon,
  TemplatesIcon,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { MARKETING_EMAIL_TYPES } from 'common/constants';
import isTestProperty from 'common/demo';

import CustomEditorModal from 'components/campaigns/CustomEditorModal';
import EditionTemplateModal from 'components/campaigns/EditionTemplateModal';

import useDeveloperMode from 'hooks/useDeveloperMode';
import useGlobalInfo from 'hooks/useGlobalInfo';
import { useHistory } from 'react-router-dom';

interface CreateNewProps {
  campaignURN: string;
  handleNewEdition: () => {};
  handleNewMarketing: (
    marketingEmailType: keyof typeof MARKETING_EMAIL_TYPES
  ) => {};
  isDisabled: boolean;
}

function CreateNew({
  campaignURN,
  handleNewEdition,
  handleNewMarketing,
  isDisabled,
}: CreateNewProps) {
  const [isDeveloperModeActive] = useDeveloperMode('NL-3799'); // Template Management

  const {
    isOpen: isOpenCustomEditorModal,
    onOpen: onOpenCustomEditorModal,
    onClose: onCloseCustomEditorModal,
  } = useDisclosure();

  const {
    isOpen: isOpenEditionTemplateModal,
    onOpen: onOpenEditionTemplateModal,
    onClose: onCloseEditionTemplateModal,
  } = useDisclosure();

  const globalInfo = useGlobalInfo();
  const history = useHistory();

  const openTemplateManagement = () => {
    history.push(`/campaigns/${campaignURN}/templates`);
  };

  if (isDeveloperModeActive) {
    return (
      <>
        <Dropdown>
          <Dropdown.Button as={ChevronButton} isDisabled={isDisabled}>
            Create new
          </Dropdown.Button>
          <Dropdown.List>
            <Dropdown.Item
              icon={<LightingHighlightIcon size={5} />}
              onClick={
                isTestProperty(globalInfo)
                  ? onOpenEditionTemplateModal
                  : handleNewEdition
              }
            >
              Optimized newsletter
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              icon={<TemplatesIcon size={5} />}
              onClick={openTemplateManagement}
            >
              From template
            </Dropdown.Item>
            <Dropdown.Item
              icon={<HtmlIcon size={5} />}
              onClick={onOpenCustomEditorModal}
            >
              HTML Email
            </Dropdown.Item>
          </Dropdown.List>
        </Dropdown>

        {isOpenCustomEditorModal && (
          <CustomEditorModal
            handleNewMarketing={handleNewMarketing}
            isOpen={isOpenCustomEditorModal}
            onClose={onCloseCustomEditorModal}
          />
        )}

        {isOpenEditionTemplateModal && isTestProperty(globalInfo) && (
          <EditionTemplateModal
            handleNewEdition={handleNewEdition}
            globalInfo={globalInfo}
            isOpen={isOpenEditionTemplateModal}
            onClose={onCloseEditionTemplateModal}
          />
        )}
      </>
    );
  }

  return (
    <Box pl={2}>
      <ButtonGroup isAttached>
        <Button
          onClick={handleNewEdition}
          borderRightRadius={0}
          boxShadow="inset 0px 0px 0px 0.5px rgb(41, 98, 219)"
          isDisabled={isDisabled}
        >
          <Flex alignItems="center" gap={2}>
            <EditPen1Icon size={4} />
            Create new
          </Flex>
        </Button>
        <Dropdown placement="bottom-end">
          <Dropdown.Button
            as={Button}
            borderLeftRadius={0}
            boxShadow="inset 0px 0px 0px 0.5px rgb(41, 98, 219)"
            onClick={() => {}}
            isDisabled={isDisabled}
          >
            <ChevronDownIcon />
          </Dropdown.Button>
          <Dropdown.List>
            <Dropdown.Item
              icon={<LightingHighlightIcon size={5} />}
              onClick={
                isTestProperty(globalInfo)
                  ? onOpenEditionTemplateModal
                  : handleNewEdition
              }
            >
              Optimized email
            </Dropdown.Item>
            <Dropdown.Item
              icon={<HtmlIcon size={5} />}
              onClick={onOpenCustomEditorModal}
            >
              Custom Email
            </Dropdown.Item>
          </Dropdown.List>
        </Dropdown>
      </ButtonGroup>

      {isOpenCustomEditorModal && (
        <CustomEditorModal
          handleNewMarketing={handleNewMarketing}
          isOpen={isOpenCustomEditorModal}
          onClose={onCloseCustomEditorModal}
        />
      )}

      {isOpenEditionTemplateModal && isTestProperty(globalInfo) && (
        <EditionTemplateModal
          handleNewEdition={handleNewEdition}
          globalInfo={globalInfo}
          isOpen={isOpenEditionTemplateModal}
          onClose={onCloseEditionTemplateModal}
        />
      )}
    </Box>
  );
}

export default CreateNew;
