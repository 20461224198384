import PropTypes from 'prop-types';

import './SettingsPanelBase.css';

export default function SettingsPanelBase({ className, ...rest }) {
  return <div className={`settings-panel mt-2 py-3 ${className}`} {...rest} />;
}

SettingsPanelBase.defaultProps = {
  className: '',
};

SettingsPanelBase.propTypes = {
  className: PropTypes.string,
};
