import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';

import Title from 'components/editor/Title';

import * as logger from 'common/logger';
import useDebounce from 'hooks/useDebounce';

import { DEFAULT_DEBOUNCE_DELAY } from 'common/constants';

function EmailSubject({
  emailSubject,
  handleUpdateSubject,
  isBusy,
  isEditable,
}) {
  const [newSubject, setNewSubject] = useState(emailSubject);
  const inputRef = useRef();

  useEffect(() => {
    /* Restore focus after a brief delay to allow the component to render */
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 250);
    }
  }, [emailSubject, newSubject]);

  const debouncedEmailSubject = useDebounce(newSubject, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    if (debouncedEmailSubject !== emailSubject && !isBusy) {
      logger.info(
        `HTML:EmailSubject:useEffect - saving debounced subject line ${debouncedEmailSubject}`
      );
      handleUpdateSubject(debouncedEmailSubject, () => {
        setNewSubject(debouncedEmailSubject);
      });
    }
  }, [debouncedEmailSubject, emailSubject, handleUpdateSubject, isBusy]);

  const handleBlur = () => {
    if (debouncedEmailSubject !== emailSubject && !isBusy) {
      logger.info('HTML:EmailSubject:handleBlur - saving subject line on blur');
      handleUpdateSubject(debouncedEmailSubject, () => {
        setNewSubject(debouncedEmailSubject);
      });
    }
  };

  return (
    <>
      <Title>Subject Line</Title>
      <div
        className={`ebx-email-subject w-100 d-flex edit-subject ${
          isEditable && 'ebx-email-subject-active'
        }`}
      >
        {isEditable ? (
          <FormControl
            name="editionSubject"
            className="ebx-static-input ebx-body-1 px-3 py-2"
            type="text"
            disabled={isBusy}
            placeholder="Subject"
            maxLength="998"
            value={newSubject}
            onChange={event => setNewSubject(event.target.value)}
            onBlur={handleBlur}
            ref={inputRef}
          />
        ) : (
          <div className="ebx-body-1 px-3 py-2 d-flex align-items-center stay-within-parent">
            <span className="email-subject-text ebx-disabled-text">
              {emailSubject}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

EmailSubject.propTypes = {
  emailSubject: PropTypes.string.isRequired,
  handleUpdateSubject: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default EmailSubject;
