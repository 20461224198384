import {
  ArrowDownloadIcon,
  Button,
  Flex,
  Heading,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Campaign } from 'common/types';
import ExportPropertyAnalyticsModal from 'components/campaigns/ExportPropertyAnalyticsModal';

interface AnalyticsHeaderProps {
  campaign: Campaign;
}

function AnalyticsHeader({ campaign }: AnalyticsHeaderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" gap={3}>
          <Heading variant="h2">{campaign.campaignName}</Heading>
          <Heading variant="h5" color="gray.600">
            Last 30 days
          </Heading>
        </Flex>
        <Button
          leftIcon={<ArrowDownloadIcon size={4} />}
          variant="secondary"
          onClick={onOpen}
        >
          Export Analytics
        </Button>
      </Flex>
      {isOpen && (
        <ExportPropertyAnalyticsModal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
}

export default AnalyticsHeader;
