/* eslint react-hooks/exhaustive-deps: "off" */

import { yupResolver } from '@hookform/resolvers/yup';
import { Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import SignInErrors from 'components/users/SignInErrors';
import SignInTemplate from 'components/users/SignInTemplate';

import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import * as authentication from 'common/authentication';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import * as zendesk from 'common/zendesk';
import signin from 'services/signin';

import {
  DEFAULT_ERROR_MESSAGE,
  HUB_CHANNELS,
  HUB_EVENTS,
  ROUTE_REDIRECTIONS,
} from 'common/constants';

import './SignIn.css';

const SignInSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid email!')
    .max(1000, 'Max length is 1000!')
    .required('Enter your email'),
  password: Yup.string()
    .max(1000, 'Max length is 1000!')
    .required('Enter your password'),
});

function SignIn() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SignInSchema),
    shouldUnregister: true,
  });

  const [isSignedIn, setSignedIn] = useState(false);
  const [isSigningIn, setSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // TODO - use this to store and display signin errors
  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    authentication.clearLocalStorage();
  }, []);

  const handleAuthEvent = async ({ payload }) => {
    const { event } = payload;

    if (event === HUB_EVENTS.AUTHENTICATION.MFA) {
      history.push(ROUTE_REDIRECTIONS.MFA);
    }

    if (event === HUB_EVENTS.AUTHENTICATION.NEW_PASSWORD) {
      history.push(ROUTE_REDIRECTIONS.NEW_PASSWORD);
    }

    return () => {
      Hub.remove(HUB_CHANNELS.AUTHENTICATION, handleAuthEvent);
    };
  };
  useEffect(() => {
    Hub.listen(HUB_CHANNELS.AUTHENTICATION, handleAuthEvent);
  }, []);

  const handlePasswordToggle = () => {
    setShowPassword(value => !value);
  };

  const handleError = error => {
    logger.error({
      event: 'SignIn:handleValidatedSubmit',
      error,
    });
    setSigningIn(false);
    setErrorMessage(DEFAULT_ERROR_MESSAGE);
    // TODO: This currently breaks upon incorrect login details
    // setErrorMessage(errors.getErrorMessage(error));
  };

  const handleValidatedSubmit = async form => {
    setErrorMessage(null);
    setSigningIn(true);
    // TODO - form validation - Rob
    try {
      await signin({
        username: form.username,
        password: form.password,
      });

      const handleSignInComplete = async data => {
        const { event, globalInfo } = data.payload;
        if (event === HUB_EVENTS.GLOBAL_INFO.LOADED) {
          await tracker.setUserDetails(globalInfo);
          tracker.track({ eventName: 'Login' });
          Hub.remove(HUB_CHANNELS.GLOBAL_INFO, handleSignInComplete);
          setSignedIn(true);
        }
      };

      const handleSignInError = data => {
        const { event, error } = data.payload;
        if (event === HUB_EVENTS.GLOBAL_EVENTS.FORCE_SIGNOUT) {
          handleError(error);
        }
      };

      Hub.listen(HUB_CHANNELS.GLOBAL_INFO, handleSignInComplete);
      Hub.listen(HUB_CHANNELS.GLOBAL_EVENTS, handleSignInError);
    } catch (error) {
      handleError(error);
    }
  };

  const handleUsernameChange = event => {
    setUsername(event.target.value);
    errors.username = null;
    setErrorMessage(null);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
    errors.password = null;
    setErrorMessage(null);
  };

  if (isSignedIn) {
    window.location.href = ROUTE_REDIRECTIONS.HOME;
    // return <Redirect to="/campaigns" />;
  }

  zendesk.hideWidget();

  return (
    <SignInTemplate title="Sign in">
      <Form
        className="mt-2"
        noValidate={true}
        onSubmit={handleSubmit(handleValidatedSubmit)}
      >
        <FormGroup>
          <div className="mb-3">
            <FormLabel className="ebx-h5 ebx-label-input">Email</FormLabel>
            <FormControl
              data-cy="username"
              name="username"
              {...register('username')}
              autoFocus
              className={`ebx-input ebx-body-1 ${
                errors.username ? 'ebx-input-error' : ''
              }`}
              type="email"
              placeholder=""
              value={username}
              onChange={event => handleUsernameChange(event)}
              onInput={event => handleUsernameChange(event)}
              disabled={isSigningIn}
              maxLength={128}
            />
            {/* {errors.username && (
                    <div className="d-flex align-items-center error--text mt-2">
                      <WarningIcon className="ebx-error-colour mr-2" />
                      <span className="w-100">{errors.username.message}</span>
                    </div>
                  )} */}
          </div>

          <div className="pb-2">
            <Form.Label className="ebx-h5 ebx-label-input">Password</Form.Label>
            <a
              href="#0"
              id="show-password"
              className="float-right"
              onClick={handlePasswordToggle}
              disabled={isSigningIn}
              tabIndex="-1"
            >
              <span className="ebx-h5">
                {showPassword ? (
                  <VisibilityIcon className="ebx-pw-visible-icon" />
                ) : (
                  <VisibilityOffIcon className="ebx-pw-visible-icon" />
                )}
              </span>
            </a>
            <Form.Control
              data-cy="password"
              name="password"
              {...register('password')}
              id="password"
              className={`ebx-input ebx-body-1 ${
                errors.password && !errors.username ? 'ebx-input-error' : ''
              }`}
              type={showPassword ? 'text' : 'password'}
              placeholder=""
              disabled={isSigningIn}
              value={password}
              onChange={event => handlePasswordChange(event)}
              onInput={event => handlePasswordChange(event)}
            />
            {/* {errors.password && (
                    <div className="d-flex align-items-center error--text mt-2 mb-2">
                      <WarningIcon className="ebx-error-colour mr-2" />
                      <span>{errors.password.message}</span>
                    </div>
                  )} */}
          </div>
        </FormGroup>

        <Button
          data-cy="signIn"
          className="w-100 ebx-btn-primary ebx-btn-lg ebx-h3"
          disabled={isSigningIn}
          type="submit"
        >
          {!isSigningIn ? (
            'Sign in'
          ) : (
            <div>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
              Signing in...
              <VisuallyHidden>Loading...</VisuallyHidden>
            </div>
          )}
        </Button>
        <SignInErrors errorMessage={errorMessage} errors={errors} />
      </Form>
    </SignInTemplate>
  );
}

export default SignIn;
