import {
  Flex,
  FormControl,
  NumberInput,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface ArticleSettingsProps {
  maxArticleAge: { days: number; hours: number };
  maxArticles: number | null;
  minTargetArticles: number;
  setMaxArticles: (value: number | null) => void;
  setMaxArticleAge: ({ days, hours }: { days: number; hours: number }) => void;
}

function ArticleSettings({
  maxArticleAge,
  maxArticles,
  minTargetArticles,
  setMaxArticles,
  setMaxArticleAge,
}: ArticleSettingsProps) {
  const handleMaxArticleArticlesChange = (value: string) => {
    if (value) {
      setMaxArticles(Number(value));
    } else {
      setMaxArticles(null);
    }
  };

  const handleMaxArticleAgeChange = (value: string, name: string) => {
    setMaxArticleAge({
      ...maxArticleAge,
      [name]: value,
    });
  };

  return (
    <FormControl>
      <FormControl.FormLabel>Articles</FormControl.FormLabel>
      <Flex flexDir="column" gap={2}>
        <Flex gap={2} alignItems="center">
          <Text size="sm">Select a maximum of</Text>
          <NumberInput
            max={100}
            min={minTargetArticles}
            value={maxArticles ?? ''}
            onChange={handleMaxArticleArticlesChange}
            w="50px"
          >
            <NumberInput.Field />
          </NumberInput>
          <Text size="sm">articles</Text>
        </Flex>
        <Flex gap={2} alignItems="center">
          <Text size="sm">Select articles from the last</Text>
          <NumberInput
            max={365}
            min={0}
            value={maxArticleAge?.days ?? ''}
            onChange={value => handleMaxArticleAgeChange(value, 'days')}
            w="50px"
          >
            <NumberInput.Field />
          </NumberInput>
          <Text size="sm">days</Text>
          <NumberInput
            max={23}
            min={0}
            value={maxArticleAge.hours ?? ''}
            onChange={value => handleMaxArticleAgeChange(value, 'hours')}
            w="50px"
          >
            <NumberInput.Field />
          </NumberInput>
          <Text size="sm">hours</Text>
        </Flex>
      </Flex>
    </FormControl>
  );
}

export default ArticleSettings;
