/* eslint import/no-unresolved: "off" */
/* eslint no-bitwise: "off" */
/* eslint no-use-before-define: "off" */
/* eslint prefer-regex-literals: "off" */
/* eslint prefer-template: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */

import kebabCase from 'lodash.kebabcase';

import * as datetime from 'common/datetime';
import * as utility from 'common/utility';

export { addTrackingParameters, getDomain, getTLD, isValidURL, splitURL };

interface URLParamSetting {
  key: string;
  value: string;
  editMode: 'NONE' | 'VALUEONLY' | 'FULL';
}

const addTrackingParameters = (
  pageURL: string,
  campaignName: string,
  scheduledUnixTime?: number
) => {
  const urlParams = {
    queryParams: [
      {
        key: 'utm_campaign',
        value: kebabCase(campaignName),
        editMode: 'NONE',
      },
      {
        key: 'utm_medium',
        value: 'email',
        editMode: 'NONE',
      },
      {
        key: 'utm_source',
        value: 'newsletter',
        editMode: 'NONE',
      },
    ] as URLParamSetting[],
    fragmentParams: undefined,
  }; /* Eventually these will come from a property- or campaign-level setting */
  if (scheduledUnixTime) {
    urlParams.queryParams.push({
      key: 'utm_edition',
      value: datetime.formatEditionDate(scheduledUnixTime),
      editMode: 'NONE',
    });
  }

  const [url, query, fragment] = extractURLElements(pageURL);

  let updatedURL = url;
  let queryParams = queryStringToObject(query);
  let fragmentParams = queryStringToObject(fragment);

  if (urlParams.queryParams) {
    queryParams = processURLParams(queryParams, urlParams.queryParams);
  }

  if (urlParams.fragmentParams) {
    fragmentParams = processURLParams(fragmentParams, urlParams.fragmentParams);
  }

  updatedURL += objectToQueryString(queryParams, '?');
  updatedURL += objectToQueryString(fragmentParams, '#');

  return updatedURL;
};

const extractURLElements = (url: string) => {
  let strippedURL = decodeURIComponent(url);

  // Break out the hash portion, e.g. everything after the hash
  let fragmentParameters = '';
  if (strippedURL.indexOf('#') !== -1) {
    // Split on the first hash only
    const hashSplit = strippedURL.split(/#(.*)/);
    // URL now becomes everything except everything after the hash
    [strippedURL, fragmentParameters] = hashSplit;
  }

  // Break out any remaining query portion
  let queryParameters = '';
  if (strippedURL.indexOf('?') !== -1) {
    // Split on the first ? only
    const querySplit = strippedURL.split(/\?(.*)/);
    // URL no longer contains any query or hash portion
    [strippedURL, queryParameters] = querySplit;
  }

  return [strippedURL, queryParameters, fragmentParameters];
};

function getDomain(url: string) {
  const protocolAndPath = url.split('://');
  const domainPath = protocolAndPath[1] ?? protocolAndPath[0];
  const domainName = getRootURL(domainPath);
  const domainParts = domainName.split('.');
  if (domainParts.length < 2) {
    return null;
  }
  if (domainParts[0] === 'www') {
    domainParts.shift();
  }
  return domainParts.join('.').replace(/\/$/, '');
}

function getFragmentParams(url: string) {
  const fragmentParams = url.split('#')[1];
  if (utility.isUndefined(fragmentParams)) {
    return [];
  }

  return fragmentParams.split('?')[0].split('&');
}

function getQueryParams(url: string) {
  const queryParams = url.split('?')[1];
  if (utility.isUndefined(queryParams)) {
    return [];
  }

  return queryParams.split('#')[0].split('&');
}

function getRootURL(url: string) {
  return url.split('?')[0].split('#')[0];
}

function getShareParameters(url: string) {
  return url.replace(getRootURL(url), '');
}

function getTLD(domain: string) {
  const parts = domain.split('.').reverse();
  return parts[1] ? `${parts[1]}.${parts[0]}` : parts[0];
}

function isValidURL(url: string) {
  const regexp = new RegExp(
    '^(?:(?:https?):\\/\\/)(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\\d{2,5})?'
  );
  const result = regexp.test(encodeURI(url));
  return result;
}

function objectToQueryString(
  queryObject: { [key: string]: string },
  queryPrefix: string
) {
  const queryArray = [] as string[];
  let queryString = '';
  let queryValue;
  Object.keys(queryObject).forEach(queryKey => {
    queryValue = queryObject[queryKey];
    if (queryValue !== '') {
      queryArray.push(`${queryKey}=${queryValue}`);
    } else {
      queryArray.push(`${queryKey}`);
    }
  });
  if (queryArray.length > 0) {
    queryString += queryPrefix + queryArray.join('&');
  }

  return queryString;
}

const processURLParams = (
  paramObject: { [key: string]: string },
  settingsArray: URLParamSetting[]
) => {
  const updatedParams = paramObject;

  // Sort settings alphabetically
  settingsArray.sort((a, b) => a.key.localeCompare(b.key));

  // Loop through all settings
  settingsArray.forEach(entry => {
    switch (entry.editMode) {
      case 'NONE':
        // Overwrite existing parameter with value from settings object
        updatedParams[entry.key] = entry.value;

        break;
      case 'VALUEONLY':
        // Use value from settings object if one does not already exist
        if (!paramObject[entry.key] || paramObject[entry.key] === '') {
          updatedParams[entry.key] = entry.value;
        }

        break;
      case 'FULL':
        // Use the parameter value from the settings object if
        // (a) we are copying an unedited URL from one account API to another
        // (b) we are processing an unedited URL and the parameter does not already exist
        if (!paramObject[entry.key]) {
          updatedParams[entry.key] = entry.value;
        }

        break;
      default:
    }
  });

  return updatedParams;
};

function queryStringToObject(queryString: string) {
  const queryObject = {} as { [key: string]: string };
  const queryArray = queryString.split('&');
  queryArray.forEach(queryPair => {
    const queryEntry = queryPair.split('=');
    if (queryEntry.length === 2) {
      queryObject[queryEntry[0]] = queryEntry[1]; /* eslint-disable-line */
    } else if (queryEntry.length > 2) {
      // In the case where the value entry contains an encoded equals %3D but it was decoded should
      // be part of the query object
      queryObject[queryEntry[0]] = queryPair.substring(
        queryPair.indexOf('=') + 1
      );
    }
  });
  return queryObject;
}

function splitURL(url: string) {
  const rootURL = getRootURL(url);
  const shareParameters = getShareParameters(url);
  const queryParams = getQueryParams(url);
  const fragmentParams = getFragmentParams(url);
  return { rootURL, shareParameters, queryParams, fragmentParams };
}
