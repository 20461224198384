/* eslint react-hooks/exhaustive-deps: "off" */

import { Box, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Loading from 'components/settings/Loading';
import TeamTable from 'components/settings/team/TeamTable';
import TeamTableBody from 'components/settings/team/TeamTableBody';
import TeamTableHeader from 'components/settings/team/TeamTableHeader';
import UserAdd from 'components/settings/team/UserAdd';
import UserEntities from 'components/settings/team/UserEntities';

import * as api from 'api/api';
import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as string from 'common/string';
import * as tracker from 'common/tracker';
import type { Permission, PropertyUser } from 'common/types';
import * as users from 'common/users';
import useGlobalInfo from 'hooks/useGlobalInfo';

import PropertyViewError from 'components/campaigns/ViewError';

import {
  CAMPAIGN_PERMISSION_TYPES,
  PROPERTY_PERMISSION_TYPES,
  USER_STATES,
} from 'common/enums';

import './Team.css';

const sortByOptions = ['name', 'emailAddress'] as const;
export type SortByOptions = (typeof sortByOptions)[number];

type User = Pick<
  PropertyUser,
  'name' | 'emailAddress' | 'userState' | 'permissions'
>;

interface TeamProps {
  entityNameSingular: string;
  entityNamePlural: string;
  entitySortMetric: string;
}
interface EditingEntity {
  entityURNs: string[];
  user: User;
}

type EditingEntityState = EditingEntity | null;

function Team({
  entityNameSingular,
  entityNamePlural,
  entitySortMetric,
}: TeamProps) {
  const [isEditingEntities, setEditingEntities] =
    useState<EditingEntityState>(null);
  const [isLoadingUsers, setLoadingUsers] = useState(true);
  const [isSavingUser, setSavingUser] = useState(false);
  const [propertyUsers, setPropertyUsers] = useState<Array<User>>([]);
  const [sortBy, setSortBy] = useState<SortByOptions>('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const globalInfo = useGlobalInfo();
  const history = useHistory();
  const toast = useToast();

  const campaignList = campaigns.getCurrentPropertyCampaigns(globalInfo);
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  let permissionType;

  if (propertyURN) {
    permissionType = users.getPropertyPermission(
      propertyURN,
      globalInfo?.user?.permissions ?? []
    );
  }

  useEffect(() => {
    if (propertyURN) {
      const loadPropertyUsers = async () => {
        setLoadingUsers(true);
        try {
          const unorderedUsers = await api.getPropertyUsers({
            propertyURN,
          });
          setPropertyUsers(unorderedUsers);
          setLoadingUsers(false);
        } catch (error) {
          setLoadingUsers(false);
        }
      };

      loadPropertyUsers();
    }
  }, [propertyURN]);

  if (!propertyURN) {
    // Should never happen
    history.push('/error');
    return null;
  }

  const handleEntitiesClose = () => {
    setEditingEntities(null);
  };

  const handleEntitiesEdit = (user: PropertyUser) => () => {
    setEditingEntities({
      entityURNs: [],
      user,
    });
  };

  const handleEntitiesSubmit = async ({
    user,
    selectedEntities,
  }: {
    user: User;
    selectedEntities: string[];
  }) => {
    logger.info(
      `Team:handleEntitiesSubmit ${propertyURN} ${user.emailAddress} ${selectedEntities.length} selected entities`
    );
    setSavingUser(true);

    /* Create permissions for all selected entities */
    const permissionsOnProperty: Permission[] = users.createCampaignPermissions(
      propertyURN,
      selectedEntities
    );

    /* Save changes to user */
    try {
      await api.postPropertiesUser({
        propertyURN,
        fullName: user.name,
        emailAddress: user.emailAddress,
        permissionsOnProperty,
      });
      const newUsers = propertyUsers.map(propertyUser =>
        propertyUser.emailAddress === user.emailAddress
          ? { ...propertyUser, permissions: permissionsOnProperty }
          : propertyUser
      );
      setPropertyUsers(newUsers);
      const propertyCampaignPermissions = users.getPropertyCampaignPermissions(
        propertyURN,
        user.permissions
      );
      tracker.track({
        eventName: 'Change Permissions',
        trackingParams: {
          'Number of campaigns before': propertyCampaignPermissions.length,
          'Number of campaigns': selectedEntities.length,
        },
        options: {
          includeCampaignDetails: false,
        },
      });
    } catch (error) {
      toast({ variant: 'error', title: 'Unable to save changes' });
    } finally {
      setEditingEntities(null);
      setSavingUser(false);
    }
  };

  const handleInviteUser = ({
    name,
    emailAddress,
    userPermissions,
  }: {
    name: string;
    emailAddress: string;
    userPermissions: Permission[];
  }) => {
    const newUser: User = {
      emailAddress,
      name,
      userState: USER_STATES.INVITED,
      permissions: userPermissions,
    };

    const newUsers = [...propertyUsers, newUser].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setPropertyUsers(newUsers);
  };

  const handleRemoveUser = (email: string, name: string) => {
    const newUserList = propertyUsers.filter(
      user => user.emailAddress !== email
    );
    setPropertyUsers(newUserList);
    toast({ variant: 'success', title: `User ${name} removed.` });
  };

  const handleRoleChange = async ({
    name,
    emailAddress,
    newPermissionType,
  }: {
    name: string;
    emailAddress: string;
    newPermissionType: string;
  }) => {
    logger.info(
      `Team:handleRoleChange ${propertyURN} ${emailAddress} ${newPermissionType}`
    );

    setSavingUser(true);

    const permissionsOnProperty: Permission[] = [];
    if (newPermissionType === PROPERTY_PERMISSION_TYPES.EDITOR) {
      /* Changing from ADMIN to EDITOR
         Create VIEW_ONLY permission for property and CAMPAIGN_EDITOR for all campaigns */
      permissionsOnProperty.push({
        propertyURN,
        permissionType: PROPERTY_PERMISSION_TYPES.VIEW_ONLY,
      });
      campaignList?.forEach(campaign => {
        permissionsOnProperty.push({
          propertyURN,
          campaignURN: campaign.campaignURN,
          permissionType: CAMPAIGN_PERMISSION_TYPES.CAMPAIGN_EDITOR,
        });
      });
    } else {
      /* Changing from EDITOR to ADMIN
         Create ADMIN permission for property */
      permissionsOnProperty.push({
        propertyURN,
        permissionType: PROPERTY_PERMISSION_TYPES.ADMIN,
      });
    }

    /* Save changes to user */
    try {
      await api.postPropertiesUser({
        propertyURN,
        fullName: name,
        emailAddress,
        permissionsOnProperty,
      });
      const newUsers = propertyUsers.map(user =>
        user.emailAddress === emailAddress
          ? { ...user, permissions: permissionsOnProperty }
          : user
      );
      setPropertyUsers(newUsers);
      tracker.track({
        eventName: 'Change Role',
        trackingParams: {
          'New role':
            newPermissionType === PROPERTY_PERMISSION_TYPES.ADMIN
              ? 'Admin'
              : 'Editor',
        },
        options: {
          includeCampaignDetails: false,
        },
      });
    } catch (error) {
      toast({ variant: 'error', title: 'Unable to save changes' });
    } finally {
      setSavingUser(false);
    }
  };

  const handleResendInvite = async (user: PropertyUser) => {
    logger.info(`Team:handleResendInvite ${propertyURN} ${user.emailAddress}`);
    setSavingUser(true);
    try {
      // POST-ing the user with the same permissions will result in them being re-invited
      await api.postPropertiesUser({
        propertyURN,
        fullName: user.name,
        emailAddress: user.emailAddress,
        permissionsOnProperty: user.permissions,
      });
      tracker.track({
        eventName: 'Resend Invite',
        trackingParams: {
          'User Email': user.emailAddress,
        },
        options: {
          includeCampaignDetails: false,
        },
      });
    } finally {
      setSavingUser(false);
    }
  };

  if (
    PROPERTY_PERMISSION_TYPES.ADMIN !==
    (permissionType as PROPERTY_PERMISSION_TYPES)
  ) {
    return <PropertyViewError />;
  }

  return (
    <>
      <Box px="0.5">
        <Row className="d-flex align-items-center team-settings-title-wrapper">
          <span className="team-settings-title">Team</span>
          <span className="ml-auto">
            <UserAdd
              entityNameSingular={entityNameSingular}
              entityNamePlural={entityNamePlural}
              entitySortMetric={entitySortMetric}
              existingUsers={propertyUsers.map(user => user.emailAddress)}
              onInviteUser={handleInviteUser}
              propertyURN={propertyURN}
            />
          </span>
        </Row>
        {isLoadingUsers && (
          <div className="w-100 mt-5">
            <Loading />
          </div>
        )}
        {!isLoadingUsers && (
          <TeamTable>
            <TeamTableHeader
              columnNames={[
                'Name',
                'Email',
                'Role',
                string.toSentenceCase(entityNamePlural),
              ]}
              isBusy={isSavingUser}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
            <TeamTableBody
              currentUser={globalInfo?.user?.emailAddress}
              entityNameSingular={entityNameSingular}
              entityNamePlural={entityNamePlural}
              entitySortMetric={entitySortMetric}
              handleEntitiesEdit={handleEntitiesEdit}
              handleRemoveUser={handleRemoveUser}
              handleResendInvite={handleResendInvite}
              handleRoleChange={handleRoleChange}
              isBusy={isSavingUser}
              propertyURN={propertyURN}
              propertyUsers={propertyUsers}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </TeamTable>
        )}
      </Box>
      {isEditingEntities && (
        <UserEntities
          availableCampaigns={campaignList}
          buttonText="Save changes"
          editingEntities={isEditingEntities}
          handleClose={handleEntitiesClose}
          handleSubmit={handleEntitiesSubmit}
          isBusy={isSavingUser}
        />
      )}
    </>
  );
}

export default Team;
