import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as API from 'api/api';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as settings from 'common/settings';
import * as tracker from 'common/tracker';

import {
  FormControl,
  MoreOptionsIcon,
  Toggle,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { CAMPAIGN_SETTING_TYPES } from 'common/constants';

import AutomationWrapper from './AutomationWrapper';

import './Automation.css';

function Automation({ campaignURN, isShutOff }) {
  const [autoScheduleEnabled, setAutoScheduleEnabled] = useState(false);
  const contentSettingsLink = `/settings/campaigns/${campaignURN}/content`;
  const automationSettingsLink = `/settings/campaigns/${campaignURN}/automation`;
  const toast = useToast();

  useEffect(() => {
    const fetchCampaignSettings = async () => {
      try {
        logger.info('Automation:fetchSettings');
        const campaignSettings = await API.getCampaignSettings({
          campaignURN,
        });
        const isAutoEnabled =
          settings.findSetting(
            campaignSettings,
            CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL
          )?.isEnabled ?? false;
        setAutoScheduleEnabled(isAutoEnabled);
      } catch (error) {
        logger.error({
          event: 'Automation:fetchSettings',
          error,
        });
      }
    };
    fetchCampaignSettings();
  }, [campaignURN]);

  const handleChange = async () => {
    try {
      await API.putCampaignSettings({
        campaignURN,
        campaignSettings: [
          {
            settingsType: CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL,
            isEnabled: !autoScheduleEnabled,
          },
        ],
      });
      tracker.track({
        eventName: 'Toggle Automation',
        trackingParams: {
          'Automation Status Before': autoScheduleEnabled,
        },
      });
      setAutoScheduleEnabled(val => !val);
    } catch (error) {
      toast({ variant: 'error', title: errors.getErrorMessage(error) });
    }
  };

  const handleDropdown = () => {};

  return (
    <AutomationWrapper>
      <div className="automate-switch py-3 px-3">
        <FormControl display="flex" alignItems="start">
          <Toggle
            id="automationSwitch"
            isChecked={autoScheduleEnabled}
            isDisabled={isShutOff}
            mr={3}
            onChange={handleChange}
          />
          <FormControl.FormLabel
            color={isShutOff ? 'gray.600' : 'gray.900'}
            htmlFor="automationSwitch"
            m={0}
          >
            Automatically schedule future editions
          </FormControl.FormLabel>
        </FormControl>
        <div className="d-flex ml-auto">
          <Dropdown
            disabled={isShutOff}
            onClick={handleDropdown}
            className="ml-2"
          >
            <Dropdown.Toggle
              disabled={isShutOff}
              variant="button"
              className="p-0 border-0 d-flex align-items-center"
              // Removes the caret
              bsPrefix="p-0"
            >
              <MoreOptionsIcon size={5} color="gray.600" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              disabled={isShutOff}
              alignRight
              className="ebx-menu-dropdown"
            >
              <Dropdown.Item
                to={automationSettingsLink}
                as={Link}
                className="ebx-h3 dropdown-remove dropdown-button"
              >
                Automation settings
              </Dropdown.Item>
              <Dropdown.Item
                to={contentSettingsLink}
                as={Link}
                className="ebx-h3 dropdown-remove dropdown-button"
              >
                Content settings
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </AutomationWrapper>
  );
}

Automation.propTypes = {
  campaignURN: PropTypes.string.isRequired,
  isShutOff: PropTypes.bool.isRequired,
};

export default Automation;
