/* eslint react-hooks/exhaustive-deps: "off" */

import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';

import AnalyticsHeader from 'components/campaigns/analytics/AnalyticsHeader';
import ArticleAnalytics from 'components/campaigns/analytics/ArticleAnalytics';
import ArticleAnalyticsDemo from 'components/campaigns/analytics/ArticleAnalyticsDemo';
import Statistics from 'components/campaigns/analytics/Statistics';
import StatisticsDemo from 'components/campaigns/analytics/StatisticsDemo';

import * as campaigns from 'common/campaigns';
import isTestProperty from 'common/demo';
import * as tracker from 'common/tracker';

import useGlobalInfo from 'hooks/useGlobalInfo';

function AnalyticsDashboard() {
  const globalInfo = useGlobalInfo();
  const campaign = campaigns.getCurrentCampaign(globalInfo);

  useEffect(() => {
    tracker.track({
      eventName: 'Analytics Page',
    });
  }, []);

  return (
    <Flex flexDir="column" gap={8}>
      <AnalyticsHeader campaign={campaign} />
      {isTestProperty(globalInfo) ? (
        <StatisticsDemo campaign={campaign} />
      ) : (
        <Statistics campaign={campaign} />
      )}
      {!isTestProperty(globalInfo) ? (
        <ArticleAnalytics campaignURN={campaign.campaignURN} />
      ) : (
        <ArticleAnalyticsDemo campaignURN={campaign.campaignURN} />
      )}
    </Flex>
  );
}

export default AnalyticsDashboard;
