import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * postTextBlock
 * @param {{
 *  campaignURN: string;
 *  textBlockDetails: any;
 * }} args
 */
export default async function postTextBlock({
  campaignURN = mandatory('campaignURN'),
  textBlockDetails = mandatory('textBlockDetails'),
}) {
  const guid = metrics.start('postTextBlock');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/campaigns/${campaignURN}/textblocks`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
    data: JSON.stringify({
      ...textBlockDetails,
    }),
  };

  logger.info(`API:postTextBlock /campaigns/${campaignURN}/textblocks`);

  try {
    const response = await axios(config);
    metrics.end('postTextBlock', guid);
    return response.data.textBlockURN;
  } catch (error) {
    metrics.fail('postTextBlock', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postTextBlock',
      args: { campaignURN },
    });
    throw apiError;
  }
}
