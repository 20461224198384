/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import * as Sentry from '@sentry/react';

import * as API from 'api/api';

import { USER_TYPES } from 'common/enums';

export { setUserDetails };

async function setUserDetails(user, current = null) {
  if (typeof Sentry !== 'undefined') {
    let version = '0.0.0';
    try {
      version = await API.getVersion();
    } catch (e) {
      //
    }
    try {
      Sentry.configureScope(scope => {
        scope.setTag('version', version);
        if (user) {
          scope.setUser({
            id: user.username,
            username: user.username,
          });
          scope.setExtra(
            'usertype',
            user.userType === USER_TYPES.ECHOBOX_STAFF ? 'staff' : 'normal'
          );
        }
        if (current) {
          scope.setTag('propertyURN', current.propertyURN);
          scope.setTag('campaignURN', current.campaignURN);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}
