import React, { useState } from 'react';

import {
  Button,
  Heading,
  Input,
  Modal,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import { MergeTag, PersonalisationSettingsObject } from 'common/types';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';

export interface MergeTagModalProps {
  isOpen: boolean;
  mergeTag: MergeTag;
  onClose: () => void;
  onSubmit: (tag: MergeTag) => void;
}

function MergeTagModal({
  isOpen,
  mergeTag,
  onClose,
  onSubmit,
}: MergeTagModalProps) {
  const globalInfo = useGlobalInfo();
  const settings = useSettings({ storeStateInHook: false });

  const [defaultValue, setDefaultValue] = useState(mergeTag.defaultValue);

  const handleMergeTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(event?.target.value);
  };

  const handleSubmit = async () => {
    logger.info(
      `MergeTagModal:handleSubmit - merge tag: ${mergeTag.tagName} default ${defaultValue}`
    );
    if (defaultValue !== mergeTag.defaultValue) {
      const personalisationSettings = campaigns.getCurrentCampaignSettingsType(
        globalInfo,
        CAMPAIGN_SETTING_TYPES.SUBSCRIBER_PERSONALISATION
      ) as PersonalisationSettingsObject | null;
      if (personalisationSettings?.mergeTags) {
        personalisationSettings.mergeTags[mergeTag.tagName].defaultValue =
          defaultValue;
      }
      const campaignURN = campaigns.getCurrentCampaignURN(globalInfo);
      await settings.saveCampaignSettings({
        campaignURN,
        // @ts-ignore
        campaignSettings: [personalisationSettings],
      });
    }
    onSubmit(mergeTag);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      isCentered
      blockScrollOnMount={false} // Prevents this error when two modals open at the same time - https://github.com/chakra-ui/chakra-ui/issues/6213
    >
      <Modal.Body py={4}>
        <Heading variant="h5" mb={2}>
          What should be used if no name is available?
        </Heading>
        <Input
          type="text"
          value={defaultValue}
          onChange={handleMergeTagChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MergeTagModal;
