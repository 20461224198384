import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { CloseIcon } from '@ebx-ui/ebx-ui-component-library-sdk';
import SmartCurationLoadingStep from 'components/campaigns/editions/SmartCurationLoadingStep';

import './SmartCurationLoadingModal.css';

export type LoadingStatus = 'loaded' | 'loading' | 'waiting';
export type Steps = ReadonlyArray<{
  description: string;
  status: LoadingStatus;
}>;

// Delays when underlying event unfinished
const MIN_DELAY = 1000;
const MAX_DELAY = 3000;

// Delays when underlying event finished
const MIN_DELAY_FINISHED = 100;
const MAX_DELAY_FINISHED = 500;

interface SmartCurationLoadingModalProps {
  editionURN: string;
  isOpen: boolean;
  isFinished: boolean;
  onClose: () => void;
}

function SmartCurationLoadingModal({
  editionURN,
  isOpen,
  isFinished,
  onClose,
}: SmartCurationLoadingModalProps) {
  const [steps, setSteps] = useState<Steps>([
    { status: 'loading', description: 'Identifying relevant content' },
    { status: 'waiting', description: 'Scanning copy & images' },
    { status: 'waiting', description: 'Applying filtering rules' },
    { status: 'waiting', description: 'Loading Audience Graph' },
    { status: 'waiting', description: 'Calculating engagement potential' },
    { status: 'waiting', description: 'Specifying optimal content order' },
    { status: 'waiting', description: 'Generating template layout' },
    { status: 'waiting', description: 'Importing copy & images' },
  ]);

  const history = useHistory();

  // Mock the first n - 1 spinners
  useEffect(() => {
    const finishLoadingStep = () => {
      const currentStep = steps.findIndex(step => step.status === 'loading');

      // Don't mock if all are loaded or if on last step
      if (currentStep !== -1 && currentStep !== steps.length - 1) {
        const newSteps: Steps = [
          ...steps.slice(0, currentStep),
          {
            status: 'loaded',
            description: steps[currentStep].description,
          },
          {
            status: 'loading',
            description: steps[currentStep + 1].description,
          },
          ...steps.slice(currentStep + 2),
        ];

        setSteps(newSteps);
      }
    };

    const delay = isFinished
      ? Math.random() * (MAX_DELAY_FINISHED - MIN_DELAY_FINISHED) +
        MIN_DELAY_FINISHED
      : Math.random() * (MAX_DELAY - MIN_DELAY) + MIN_DELAY;

    const id = setTimeout(finishLoadingStep, delay);

    return () => clearTimeout(id);
  }, [steps, isFinished]);

  // Final step
  useEffect(() => {
    const currentStep = steps.findIndex(step => step.status === 'loading');

    if (currentStep === steps.length - 1 && isFinished) {
      setSteps([
        ...steps.slice(0, currentStep),
        {
          status: 'loaded',
          description: steps[currentStep].description,
        },
      ]);
    }
  }, [steps, editionURN, isFinished]);

  // Redirect once everything has loaded
  useEffect(() => {
    const hasLoaded = steps.every(step => step.status === 'loaded');
    if (hasLoaded) {
      history.push(`/editions/${editionURN}/review`);
    }
  }, [history, editionURN, steps]);

  return (
    <Modal show={isOpen} onHide={onClose} centered backdrop="static">
      <Modal.Header className="loading-modal-header">
        <Modal.Title className="loading-modal-title ebx-title-colour">
          Generating optimized Edition
        </Modal.Title>
        <span className="ml-auto">
          <Button variant="link" className="p-0" onClick={onClose}>
            <CloseIcon size={6} />
          </Button>
        </span>
      </Modal.Header>
      <Modal.Body className="loading-modal-body">
        <SmartCurationLoadingStep
          description="Scanning your latest content"
          steps={[steps[0], steps[1], steps[2]]}
        />
        <SmartCurationLoadingStep
          description="Analyzing your content"
          steps={[steps[3], steps[4], steps[5]]}
        />
        <SmartCurationLoadingStep
          description="Assembling your Edition"
          steps={[steps[6], steps[7]]}
        />
      </Modal.Body>
      <Modal.Footer className="loading-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={onClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SmartCurationLoadingModal.propTypes = {
  editionURN: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SmartCurationLoadingModal;
