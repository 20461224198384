import {
  Flex,
  FormControl,
  Text,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';

import * as campaigns from 'common/campaigns';
import {
  CAMPAIGN_SETTING_TYPES,
  EDITOR_TOOLBAR_OPTIONS,
} from 'common/constants';
import * as logger from 'common/logger';
import Editor from 'components/common/Editor';
import MergeTag from 'components/common/MergeTag';
import MergeTagModal from 'components/common/MergeTagModal';
import useGlobalInfo from 'hooks/useGlobalInfo';

interface RichTextEditorProps {
  onChange: (value: string) => void;
  value: string;
}

function RichTextEditor({ onChange, value }: RichTextEditorProps) {
  const globalInfo = useGlobalInfo();

  const campaignDetails = campaigns.getCurrentCampaign(globalInfo);
  const personalisationSettings = campaigns.getCurrentCampaignSettingsType(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.SUBSCRIBER_PERSONALISATION
  );
  const showMergeTags = campaigns.getCurrentCampaignSettingValue(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'isFirstNameLastNameEnabled'
  );
  // @ts-ignore
  const mergeTagArray = personalisationSettings?.mergeTags
    ? // @ts-ignore
      Object.keys(personalisationSettings?.mergeTags).sort()
    : [];

  const [currentMergeTag, setCurrentMergeTag] = useState('');
  const [editorRef, setEditorRef] = useState(null);
  const [linkError, setLinkError] = useState('');
  const getInitialContentState = () => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  };
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(getInitialContentState())
  );

  const {
    isOpen: isOpenMergeTagModal,
    onOpen: onOpenMergeTagModal,
    onClose: onCloseMergeTagModal,
  } = useDisclosure();

  const addMergeTag = (tagName: string) => {
    logger.info(`EditTextBlock:addMergeTag - merge tag: ${tagName}`);
    const mergeTag = {
      tagName,
      // @ts-ignore
      ...personalisationSettings?.mergeTags[tagName],
    };
    setCurrentMergeTag(mergeTag);
    onOpenMergeTagModal();
  };

  const getText = () => {
    let text = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ).replace(/[\n\r]/g, '');
    if (text === '<p></p>') {
      text = '';
    }

    // Ensures all links open in a new tab as opposed to within the iframe
    const filteredText = text.replaceAll('_self', '_blank');
    return filteredText;
  };

  const saveMergeTag = (mergeTag: any) => {
    onCloseMergeTagModal();
    logger.info(`EditTextBlock:saveMergeTagText - ${mergeTag.tagName}`);

    const currentSelection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const newContentState = Modifier.replaceText(
      currentContent,
      currentSelection,
      // eslint-disable-next-line
      '${' + mergeTag.tagName + '}'
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-fragment'
    );
    handleChange(newEditorState);
    // @ts-ignore
    editorRef.focusEditor();
  };

  const handleChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    onChange(getText());
  };

  return (
    <>
      <FormControl isInvalid={!!linkError}>
        <FormControl.FormLabel>
          <Flex align="center" gap={2}>
            Text
          </Flex>
        </FormControl.FormLabel>
        <Text size="sm">
          <Editor
            campaignName={campaignDetails.campaignName}
            /*
            scheduledUnixTime={
              // @ts-ignore
              editionDetails ? editionDetails.scheduledUnixTime : null
            }
            */
            editorState={editorState}
            onChange={handleChange}
            // onFocus={handleFocus}
            onLinkError={setLinkError}
            // @ts-ignore
            setEditorRef={setEditorRef}
            toolbarOptions={EDITOR_TOOLBAR_OPTIONS.EXPANDED}
          />
          {showMergeTags &&
            mergeTagArray.map(tagName => (
              <MergeTag
                // @ts-ignore
                mt={-2}
                mr={1}
                key={tagName}
                // @ts-ignore
                label={personalisationSettings?.mergeTags[tagName].displayName}
                onClick={() => addMergeTag(tagName)}
              />
            ))}
        </Text>
        <FormControl.FormErrorMessage>{linkError}</FormControl.FormErrorMessage>
      </FormControl>
      {isOpenMergeTagModal && (
        <MergeTagModal
          isOpen={isOpenMergeTagModal}
          // @ts-ignore
          mergeTag={currentMergeTag}
          onClose={onCloseMergeTagModal}
          onSubmit={saveMergeTag}
        />
      )}
    </>
  );
}

export default RichTextEditor;
