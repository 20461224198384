import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface PostPreviewArgs {
  editionURN: string;
  emailAddresses?: string[];
}

export default async function postPreview({
  editionURN,
  emailAddresses = [],
}: PostPreviewArgs) {
  const guid = metrics.start('postPreview');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/editions/${editionURN}/preview/email`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      previewEmailRecipients: emailAddresses,
    }),
  };
  logger.info(`API:postPreview /editions/${editionURN}/preview/email`);

  try {
    const response = await axios(config);
    metrics.end('postPreview', guid);
    return response;
  } catch (error) {
    metrics.fail('postPreview', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postPreview',
            args: { editionURN, emailAddresses },
          })
        : error;
    throw apiError;
  }
}
