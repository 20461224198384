import React, { Children, createRef, useEffect, useState } from 'react';

import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';

import LeftPanel from 'components/common/splitview/LeftPanel';
import RightPanel from 'components/common/splitview/RightPanel';
import SplitViewDivider from 'components/common/splitview/SplitViewDivider';

interface SplitViewProps {
  children: React.ReactNode;
}

function SplitView({ children }: SplitViewProps) {
  const [leftWidth, setLeftWidth] = useState<undefined | number>(undefined);
  const [separatorXPosition, setSeparatorXPosition] = useState<
    undefined | number
  >(undefined);
  const [dragging, setDragging] = useState(false);
  const splitPaneRef = createRef<HTMLDivElement>();

  if (Children.count(children) !== 2) {
    throw new Error('SplitView can only be used with two child panels');
  }

  const childrenArray = Children.toArray(children);

  const MIN_LEFT_PANEL_WIDTH = 400;
  const MAX_LEFT_PANEL_WIDTH = 720;

  useEffect(() => {
    const splitViewContainer = document.getElementsByClassName(
      'split-view-container'
    )[0];
    setLeftWidth(splitViewContainer.clientWidth * 0.4); // Left panel 40%
  }, []);

  const onMouseDown = (e: React.MouseEvent) => {
    setSeparatorXPosition(e.clientX);
    setDragging(true);
  };

  const onMouseMove = (e: MouseEvent) => {
    if (dragging) e.preventDefault();
    onMove(e.clientX);
  };

  const onMouseUp = () => {
    setSeparatorXPosition(undefined);
    setDragging(false);
  };

  const onMove = (clientX: number) => {
    if (dragging && leftWidth && separatorXPosition) {
      const newLeftWidth = leftWidth + clientX - separatorXPosition;
      setSeparatorXPosition(clientX);

      if (newLeftWidth < MIN_LEFT_PANEL_WIDTH) {
        setLeftWidth(MIN_LEFT_PANEL_WIDTH);
        return;
      }

      if (newLeftWidth > MAX_LEFT_PANEL_WIDTH) {
        setLeftWidth(MAX_LEFT_PANEL_WIDTH);
        return;
      }

      if (splitPaneRef.current) {
        const splitPaneWidth = splitPaneRef.current.clientWidth;

        if (newLeftWidth > splitPaneWidth - MIN_LEFT_PANEL_WIDTH) {
          setLeftWidth(splitPaneWidth - MIN_LEFT_PANEL_WIDTH);
          return;
        }
      }

      setLeftWidth(newLeftWidth);
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    onMove(e.touches[0].clientX);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchmove', onTouchMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchmove', onTouchMove);
    };
  });

  return (
    <Flex
      ref={splitPaneRef}
      className="split-view-container"
      height="100%"
      flexDir="row"
      align="flex-start"
    >
      <LeftPanel leftWidth={leftWidth} setLeftWidth={setLeftWidth}>
        {childrenArray[0]}
      </LeftPanel>
      <SplitViewDivider
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
      />
      <RightPanel>{childrenArray[1]}</RightPanel>
    </Flex>
  );
}

export default SplitView;
