import {
  Flex,
  Heading,
  Image,
  InfoIcon,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface PreviewTextTooltipProps {
  marginLeft?: number;
}

function PreviewTextTooltip({ marginLeft }: PreviewTextTooltipProps) {
  const tooltipContent = (
    <Flex flexDir="column" gap={5}>
      Tip: Preview text works best when it complements the subject line, has 30
      to 100 characters and sparks curiosity.
      <Flex gap={2}>
        <Image
          src="/assets/img/preview-text-helper.png"
          alt="Preview text helper"
          width="289px"
        />
        <Flex flexDir="column" gap={3} justifyContent="flex-end" mb={6}>
          <Heading variant="h5" color="gray.600">
            Sender name
          </Heading>
          <Heading variant="h5" color="gray.600">
            Subject line
          </Heading>
          <Heading variant="h5" color="gray.600">
            Preview text
          </Heading>
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Tooltip
      label={tooltipContent}
      placement="top"
      px={5}
      pt={5}
      pb={0}
      ml={marginLeft ?? 0}
      maxWidth="440px"
    >
      <InfoIcon color="gray.500" />
    </Tooltip>
  );
}

export default PreviewTextTooltip;
