/* eslint camelcase: ["error", {allow: ["impersonated_by_staff_email", "jwt_decode"]}] */
/* eslint no-use-before-define: "off" */

import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { generateGuid } from 'common/string';
import { isDefined, isNull } from 'common/utility';

export { addCorrelationId, logRateLimitWarning };

function addCorrelationId(request) {
  if (!isNull(request.url.match('echobox'))) {
    const updated = { ...request };
    updated.headers['X-Correlation-Id'] = generateGuid();
    return updated;
  }
  return request;
}

function logRateLimitWarning(response) {
  if (
    isDefined(response.headers) &&
    (isDefined(response.headers['X-EBX-RateLimitWarning']) ||
      isDefined(response.headers['x-ebx-ratelimitwarning']))
  ) {
    logger.error({
      event: 'Rate Limit Warning',
      properties: {
        Metrics: metrics.stringify(),
      },
    });
  }
  return response;
}
