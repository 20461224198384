import AddressURL from 'components/settings/campaigns/all/subscription/AddressURL';

import '../../Campaign.css';

function SubscriptionPage() {
  return (
    <>
      <div className="campaign-title ebx-title-colour">Subscription page</div>
      <div className="subscription-description ebx-body-1 mb-4">
        Set up a single page from which a reader can subscribe to all of your
        Campaigns
      </div>
      <AddressURL />
    </>
  );
}

export default SubscriptionPage;
