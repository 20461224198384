/* eslint react-hooks/exhaustive-deps: "off" */

import { Redirect, Route, Switch } from 'react-router-dom';

import Campaigns from 'components/campaigns/Campaigns';
import Editions from 'components/editor/curate/Editions';
import Marketing from 'components/editor/marketing/Routing';
import Error from 'components/Error';
import Settings from 'components/settings/Settings';
import NewPassword from 'components/users/NewPassword';
import SignIn from 'components/users/SignIn';
import SignOut from 'components/users/SignOut';
import SignUp from 'components/users/SignUp';
import Success from 'components/users/unsubscribe/Success';
import Unsubscribe from 'components/users/unsubscribe/Unsubscribe';
import VerifyMFA from 'components/users/VerifyMFA';
import RequireAuth from 'containers/RequireAuth';

import * as routing from 'common/routing';

function Routing() {
  return (
    <Switch>
      <Route
        path="/"
        exact={true}
        render={() => <Redirect to="/campaigns" />}
      />
      <Route
        path="/campaigns"
        render={() => (
          <RequireAuth redirector={routing.requireSignedIn}>
            <Campaigns />
          </RequireAuth>
        )}
      />
      <Route
        path="/error"
        render={() => (
          <RequireAuth redirector={routing.requireSignedIn}>
            <Error />
          </RequireAuth>
        )}
      />
      <Route
        path="/editions"
        render={() => (
          <RequireAuth redirector={routing.requireSignedIn}>
            <Editions />
          </RequireAuth>
        )}
      />
      <Route path="/login" render={() => <Redirect to="/signin" />} />
      <Route
        path="/marketing"
        render={() => (
          <RequireAuth redirector={routing.requireSignedIn}>
            <Marketing />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings"
        render={() => (
          <RequireAuth redirector={routing.requireSignedIn}>
            <Settings />
          </RequireAuth>
        )}
      />
      <Route
        path="/signin"
        render={() => (
          <RequireAuth redirector={routing.requireSignedOut}>
            <SignIn />
          </RequireAuth>
        )}
      />
      <Route path="/signout">
        <SignOut />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/newpassword">
        <NewPassword />
      </Route>
      <Route exact path="/unsubscribe">
        <Unsubscribe />
      </Route>
      <Route path="/unsubscribe/success">
        <Success />
      </Route>
      <Route
        path="/verifymfa"
        render={() => (
          <RequireAuth redirector={routing.requireSignedOut}>
            <VerifyMFA />
          </RequireAuth>
        )}
      />
      <Route path="/" render={() => <Redirect to="/campaigns" />} />
    </Switch>
  );
}

export default Routing;
