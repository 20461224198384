import * as campaigns from 'common/campaigns';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as timezones from 'common/timezones';
import useGlobalInfo from 'hooks/useGlobalInfo';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';
import { EDITION_STATE, SCHEDULE_EDITION_OPTIONS } from 'common/enums';

export interface DisplayEditionSendTimingProps {
  className?: string | null;
  scheduledUnixTime: number;
  finishedSendingTime?: number | null;
  editionState?: string | null;
}

const displayTimeWindow = (
  startTime: number,
  endTime: number | null | undefined,
  className: string | null | undefined
) => (
  <>
    <span className={className ?? ''}>
      {datetime.getFormattedDateFromUnix({
        timestamp: startTime,
      })}
    </span>
    {endTime && endTime !== startTime && (
      <>
        <span className="mx-1"> - </span>
        <span className={className ?? ''}>
          {datetime.getFormattedDateFromUnix({
            timestamp: endTime,
          })}
        </span>
      </>
    )}
  </>
);

function DisplayEditionSendTiming({
  className,
  scheduledUnixTime,
  finishedSendingTime,
  editionState,
}: DisplayEditionSendTimingProps) {
  const globalInfo = useGlobalInfo();
  const campaignTimezone = campaigns.getCurrentCampaignSettingValue(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.SCHEDULING,
    'timezone'
  );
  const timezoneOffsetSeconds =
    timezones.getOffsetMinutes(campaignTimezone) * 60;

  const isEditionUnsent = !!(
    editionState &&
    editionState !== EDITION_STATE.SENT &&
    editionState !== EDITION_STATE.FAILED
  );

  const scheduleEditionOption = editions.getScheduleEditionOption(
    scheduledUnixTime + timezoneOffsetSeconds,
    (finishedSendingTime ?? 0) + timezoneOffsetSeconds,
    isEditionUnsent
  );

  const unixTimeNow = datetime.getUnixTimestamp();

  switch (scheduleEditionOption) {
    case SCHEDULE_EDITION_OPTIONS.NOW: {
      return <div>Now</div>;
    }
    case SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME: {
      return displayTimeWindow(scheduledUnixTime, null, className);
    }
    case SCHEDULE_EDITION_OPTIONS.TIME_SLOT: {
      const timeSlotStartTime =
        isEditionUnsent &&
        scheduledUnixTime < unixTimeNow &&
        finishedSendingTime &&
        finishedSendingTime > unixTimeNow
          ? unixTimeNow
          : scheduledUnixTime;
      return displayTimeWindow(
        timeSlotStartTime,
        finishedSendingTime,
        className
      );
    }
    case SCHEDULE_EDITION_OPTIONS.OPTIMAL: {
      const scheduledDate: any = datetime.getDateFromUnix(scheduledUnixTime);
      let dateName = scheduledDate.slang;
      const oneDay = 24 * 60 * 60;
      if (
        isEditionUnsent &&
        (dateName === 'Yesterday' || scheduledUnixTime < unixTimeNow - oneDay)
      ) {
        // Tried to send
        dateName = 'Today';
      }
      if (dateName === '') {
        dateName = `${scheduledDate.monthName.substring(0, 3)} ${
          scheduledDate.day
        }`;
      }
      return <div>{dateName}, Optimal</div>;
    }
    default: {
      return displayTimeWindow(
        scheduledUnixTime,
        finishedSendingTime,
        className
      );
    }
  }
}

DisplayEditionSendTiming.defaultProps = {
  className: null,
  finishedSendingTime: null,
  editionState: null,
};

export default DisplayEditionSendTiming;
