import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * putPromotionBlock
 * @param {{
 *  promotionBlockURN: string;
 *  promotionBlockDetails: any;
 * }} args
 */

interface PutPromotionBlockArgs {
  promotionBlockURN: string;
  promotionBlockDetails: object;
}

export default async function putPromotionBlock({
  promotionBlockURN,
  promotionBlockDetails,
}: PutPromotionBlockArgs) {
  if (isUndefined(promotionBlockURN)) {
    mandatory('promotionBlockURN');
  }
  if (isUndefined(promotionBlockDetails)) {
    mandatory('promotionBlockDetails');
  }

  const guid = metrics.start('putPromotionBlock');

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/promotionblocks/${promotionBlockURN}`;

  const config = {
    url,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      ...promotionBlockDetails,
    }),
  };

  logger.info(`API:putPromotionBlock /promotionblocks/${promotionBlockURN}`);

  try {
    const response = await axios(config);
    metrics.end('putPromotionBlock', guid);
    return response.data.promotionBlockURN;
  } catch (error) {
    metrics.fail('putPromotionBlock', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/putPromotionBlock',
            args: { promotionBlockDetails },
          })
        : error;
    throw apiError;
  }
}
