/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { BRANDING_SETTINGS_SUB_PAGES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import Loading from 'components/settings/Loading';
import useSettings from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Content, Sidebar } from '../../common';
import FooterText from './FooterText';
import VerificationContent from './verification/VerificationContent';
import VerificationLinks from './verification/VerificationLinks';

import './EditionBranding.css';

export default function VerificationBranding() {
  const [settingsPanel, setSettingsPanel] = useState(
    BRANDING_SETTINGS_SUB_PAGES.EMAIL_VERIFICATION_PAGE.OPTIONS
  );
  const { urn } = useParams();
  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  useEffect(() => {
    // pub-sub
    const handleSave = async () => {
      logger.info('VerificationBranding:handleSave');

      try {
        await settings.saveCampaignSettings({
          campaignURN: urn,
        });

        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, urn]);

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Row className="settings-branding w-100 m-0">
      <Col xs={12} md={4} xl={3}>
        <Sidebar>
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_VERIFICATION_PAGE.OPTIONS && (
            <VerificationLinks urn={urn} setSettingsPanel={setSettingsPanel} />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_VERIFICATION_PAGE.CONTENTS && (
            <VerificationContent
              setSettingsPanel={setSettingsPanel}
              settings={settings}
            />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_VERIFICATION_PAGE.FOOTER && (
            <FooterText
              setSettingsPanel={setSettingsPanel}
              settings={settings}
            />
          )}
        </Sidebar>
      </Col>
      <Col xs={12} md={8} xl={9}>
        <Content>
          <PreviewWrapper
            campaignURN={urn}
            previewTypes={[PREVIEW_TYPES.SUBSCRIBER_VERIFICATION_EMAIL]}
            settings={settings}
          />
        </Content>
      </Col>
    </Row>
  );
}
