import {
  Button,
  Modal,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { saveAs } from 'file-saver';

import * as API from 'api/api';

import * as file from 'common/file';
import * as tracker from 'common/tracker';

interface ExportEditionAnalyticsModalProps {
  isOpen: boolean;
  editionURN: string | null;
  onClose: () => void;
}

function ExportEditionAnalyticsModal({
  isOpen,
  editionURN,
  onClose,
}: ExportEditionAnalyticsModalProps) {
  const toast = useToast();

  const handleSubmit = async () => {
    if (editionURN) {
      let exportLink;
      try {
        exportLink = await API.getEditionAnalytics({
          editionURNs: [editionURN],
        });
        tracker.track({
          eventName: 'Export Edition Analytics',
          trackingParams: {
            'Email ID': editionURN,
          },
        });
      } catch {
        toast({
          variant: 'error',
          title: 'There was a problem requesting your Edition analytics data',
        });
      }
      if (exportLink) {
        try {
          const { data } = await API.getDownloadFile({ url: exportLink });
          const suffix = file.getFileSuffix(exportLink, data);
          const filename = `Echobox Email Analytics${suffix}`;
          saveAs(data, filename);
          onClose();
        } catch {
          toast({
            variant: 'error',
            title:
              'There was a problem downloading your Edition analytics data',
          });
        }
      }
    } else {
      toast({
        variant: 'error',
        title: 'There was a problem fetching your Edition data',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="small">
      <Modal.Header>
        <Modal.Title>Export analytics</Modal.Title>
      </Modal.Header>
      <Modal.Body display="flex" flexDir="column" gap={2}>
        <Text size="sm" color="gray.600">
          Download an analytics CSV for this Edition. This may take a few
          seconds...
        </Text>
        <Text size="sm" color="gray.600">
          It may take up to 24 hours for a breakdown of links clicked to appear
          in the export.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Download</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExportEditionAnalyticsModal;
