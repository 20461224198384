/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { Col, FormControl, FormLabel } from 'react-bootstrap';

import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import SettingsPageWrapper from 'components/settings/campaigns/all/SettingsPageWrapper';
import SidebarWrapper from 'components/settings/campaigns/all/SidebarWrapper';
import { Container, Content } from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';

function EmailSent() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const bodyCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_EMAIL_SENT_HOSTED_SUBS_PAGE,
    'bodyCopy'
  );
  const titleCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_EMAIL_SENT_HOSTED_SUBS_PAGE,
    'titleCopy'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Preferences-Email-sent:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleBodyCopyChange = event => {
    settings.setPropertySettingValue({
      settingType:
        PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_EMAIL_SENT_HOSTED_SUBS_PAGE,
      settingKey: 'bodyCopy',
      settingValue: event.target.value,
    });
  };

  const handleTitleCopyChange = event => {
    settings.setPropertySettingValue({
      settingType:
        PROPERTY_SETTING_TYPES.ACCESS_YOUR_PREFERENCES_EMAIL_SENT_HOSTED_SUBS_PAGE,
      settingKey: 'titleCopy',
      settingValue: event.target.value,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <SettingsPageWrapper>
      <SidebarWrapper
        subPath="subscriber-preferences-center"
        backLabel="subscriber preferences center"
      >
        <Container>
          <div className="mb-4 pt-3">
            <FormLabel className="ebx-h3 mb-1">Email sent</FormLabel>
            <FormControl
              name="titleCopy"
              id="titleCopy"
              required
              className="ebx-input ebx-body-1"
              type="text"
              placeholder="Email Sent"
              value={titleCopy}
              onChange={handleTitleCopyChange}
              autoComplete="off"
            />
          </div>
          <div className="mb-4">
            <FormLabel className="ebx-h3 mb-1">Description</FormLabel>
            <FormControl
              className="ebx-input ebx-input-group ebx-body-1 mb-3"
              style={{ width: '100%' }}
              as="textarea"
              type="text"
              rows={3}
              placeholder="For security, we've sent an email to your inbox that contains a link to update your preferences."
              required
              value={bodyCopy}
              onChange={handleBodyCopyChange}
              autoComplete="off"
            />
          </div>
        </Container>
      </SidebarWrapper>
      <Col xs={12} md={8}>
        <Content>
          <PreviewWrapper
            propertyURN={propertyURN}
            previewTypes={[PREVIEW_TYPES.ACCESS_PREFERENCES_SENT]}
            settings={settings}
          />
        </Content>
      </Col>
    </SettingsPageWrapper>
  );
}

export default EmailSent;
