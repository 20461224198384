/* eslint react/no-children-prop: "off" */

import {
  AddIcon,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  MinusLessCollapseIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Wrapper from 'components/editor/blocks/Wrapper';

import * as maths from 'common/maths';

interface NumberPickerProps {
  header?: string;
  label?: string;
  max?: number;
  min: number;
  name: string;
  onChange: (name: string, value: string | number) => void;
  step?: number;
  unit?: string;
  value: string | number;
}

function NumberPicker({
  header,
  label,
  max,
  min,
  name,
  onChange,
  step = 1,
  unit,
  value,
}: NumberPickerProps) {
  const number = unit ? Number(`${value}`.replaceAll(unit, '')) : Number(value);

  const handleChange = (event: any) => {
    onChange(
      name,
      unit ? `${event.target.value}${unit}` : Number(event.target.value)
    );
  };

  const handleDecrement = () => {
    let result = number - step;
    result = maths.round(result, maths.decimals(step));
    onChange(name, unit ? `${result}${unit}` : result);
  };

  const handleIncrement = () => {
    let result = number + step;
    result = maths.round(result, maths.decimals(step));
    onChange(name, unit ? `${result}${unit}` : result);
  };

  return (
    <Wrapper header={header} label={label}>
      <Flex alignItems="center" direction="row" gap={1}>
        <IconButton
          aria-label="minus"
          icon={<MinusLessCollapseIcon />}
          isDisabled={number === Number(min)}
          onClick={handleDecrement}
          size="lg"
        />
        <InputGroup>
          <Input type="number" value={number} onChange={handleChange} />
          {unit && <InputRightElement>{unit}</InputRightElement>}
        </InputGroup>
        <IconButton
          aria-label="minus"
          icon={<AddIcon />}
          isDisabled={!!max && number === Number(max)}
          onClick={handleIncrement}
          size="lg"
        />
      </Flex>
    </Wrapper>
  );
}

export default NumberPicker;
