import { Link } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

import './ProfileSectionPanel.css';

export interface ProfileSectionPanelProps {
  className?: string;
  title: string;
  description: string;
  url: string;
}

function ProfileSectionPanel({
  className,
  title,
  description,
  url,
}: ProfileSectionPanelProps) {
  return (
    <Link
      to={`/settings/profile/${url}`}
      className="profile-section-panel-link"
    >
      <div
        className={`ebx-panel ebx-panel-approved px-4 py-3 d-flex align-items-center justify-content-between profile-section-panel ${className}`}
      >
        <div>
          <div className="ebx-h2 mb-1 profile-section-panel-title">{title}</div>
          <div className="ebx-body-1 ebx-body-colour">{description}</div>
        </div>
        <ChevronRight className="profile-section-panel-chevron" />
      </div>
    </Link>
  );
}

ProfileSectionPanel.defaultProps = {
  className: null,
};

export default ProfileSectionPanel;
