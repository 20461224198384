import { Dispatch, SetStateAction, useState } from 'react';

import { Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import AddPromotion from 'components/editor/curate/AddPromotion';
import PromotionBlock from 'components/editor/curate/PromotionBlock';
import PromotionModal from 'components/editor/curate/PromotionModal';

import { PROMOTION_BLOCK_TYPES } from 'common/enums';

interface PromotionProps {
  campaignDetails: any;
  imageURL: string;
  destinationURL: string;
  handleSavePromotionBlock: (
    updatedImageURL: string,
    updatedDestinationURL: string,
    callback: () => void
  ) => void;
  isEditionEditable: boolean;
  isSavingPromotionBlock: boolean;
  promotionBlockURN: string;
  setSaving: Dispatch<SetStateAction<boolean>>;
}

function Promotion({
  campaignDetails,
  imageURL,
  destinationURL,
  handleSavePromotionBlock,
  isEditionEditable,
  isSavingPromotionBlock,
  promotionBlockURN,
  setSaving,
}: PromotionProps) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleSave = (
    updatedImageURL: string,
    updatedDestinationURL: string
  ) => {
    handleSavePromotionBlock(
      updatedImageURL,
      updatedDestinationURL,
      handleHideModal
    );
  };

  const promotionBlock = campaignDetails.promotionBlocks.filter(
    (block: any) => block.promotionBlockURN === promotionBlockURN
  );
  const blockType =
    promotionBlock && promotionBlock.length && promotionBlock.length === 1
      ? promotionBlock[0].promotionBlockType
      : 'IMAGE';
  const title =
    blockType === PROMOTION_BLOCK_TYPES.IMAGE ? 'Image' : 'Advertising';

  return (
    <>
      <Flex flexDir="column" gap={3}>
        <Heading variant="h3">{title}</Heading>
        {!imageURL ? (
          <AddPromotion blockType={blockType} onShowModal={handleShowModal} />
        ) : (
          <PromotionBlock
            imageURL={imageURL}
            destinationURL={destinationURL}
            onShowModal={handleShowModal}
            isEditionEditable={isEditionEditable}
          />
        )}
      </Flex>
      {showModal && (
        <PromotionModal
          promotionBlockType={blockType}
          imageURL={imageURL ?? ''}
          destinationURL={destinationURL ?? ''}
          onHide={handleHideModal}
          onSave={handleSave}
          show={showModal}
          origin="Curate Window"
          isSavingPromotionBlock={isSavingPromotionBlock}
          setSaving={setSaving}
        />
      )}
    </>
  );
}

export default Promotion;
