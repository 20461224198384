import PropTypes from 'prop-types';
import { useContext } from 'react';

import { Flex, Input, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import { FooterCheckboxContext } from './FooterCheckbox';

function FooterPrivacyPolicyTextInput({ value, onChange }) {
  const show = useContext(FooterCheckboxContext);
  const handlePrivacyPolicyTextChange = event => {
    onChange(event.target.value);
  };

  if (!show) {
    return null;
  }

  return (
    <Flex direction="column" gap="2">
      <Text fontWeight="medium" size="sm">
        Privacy Policy link text
      </Text>
      <Input value={value} onChange={handlePrivacyPolicyTextChange} />
    </Flex>
  );
}

FooterPrivacyPolicyTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

FooterPrivacyPolicyTextInput.defaultProps = {
  value: '',
};

export default FooterPrivacyPolicyTextInput;
