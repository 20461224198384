import axios, { AxiosError } from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

import { ErrorResponse } from 'common/types';

interface deleteCampaignSubscribersProps {
  campaignURN: string;
  subscriberDetails: { email: string }[];
  fullyDelete: boolean;
}

export default async function deleteCampaignSubscribers({
  campaignURN,
  subscriberDetails,
  fullyDelete,
}: deleteCampaignSubscribersProps) {
  const guid = metrics.start('deleteSubscribers');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/campaigns/${campaignURN}/subscribers`,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      subscribers: subscriberDetails,
      fullyDelete,
    }),
  };
  logger.info(`API:deleteSubscribers /campaigns/${campaignURN}/subscribers`);

  try {
    const response = await axios(config);
    metrics.end('deleteSubscribers', guid);
    return response.data;
  } catch (error) {
    metrics.fail('deleteSubscribers', guid);

    // Just rethrow if the subscriber email is bad
    if (
      (error as AxiosError<ErrorResponse>).response?.data?.error?.target ===
      'email'
    ) {
      throw error;
    }

    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/deleteSubscribers',
            args: { campaignURN, subscriberDetails, fullyDelete },
          })
        : error;
    throw apiError;
  }
}
