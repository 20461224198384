/* eslint react-hooks/exhaustive-deps: "off" */

import {
  ArrowDownIcon,
  ArrowUpIcon,
  Flex,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React, { useEffect, useState } from 'react';

import * as utility from 'common/utility';

interface Article {
  mediaURN: string;
  title: string;
  imageURL: string;
  editionSubject: string;
  sendDate: number;
  deliveries: number;
  numClicks: number;
  clickRate: number;
}

const rawArticles: Article[] = [
  {
    mediaURN:
      'urn:media:article:https://www.gamesradar.com/the-witcher-3-new-gen-upgrade-finally-cracks-down-on-identical-npcs/',
    title:
      'The Witcher 3 new-gen upgrade finally cracks down on identical NPCs',
    imageURL:
      'https://cdn.mos.cms.futurecdn.net/Vh32WujZBEzmibuw6gp6qN-970-80.jpg.webp',
    editionSubject: 'GamesRadar+ Weekly 25 November 2022',
    sendDate: 1669377600,
    deliveries: 104322,
    numClicks: 8192,
    clickRate: 7.85,
  },
  {
    mediaURN:
      'urn:media:article:https://www.denofgeek.com/movies/dark-evolution-of-wednesday-addams-into-goth-icon/',
    title: 'The Dark Evolution of Wednesday Addams into a Goth Icon',
    imageURL:
      'https://www.denofgeek.com/wp-content/uploads/2022/11/Jenna-Ortega-versus-Christina-Ricci-as-Wednesday.jpg?resize=768%2C432',
    editionSubject: 'Den of Geek Weekly 25 November 2022',
    sendDate: 1669377600,
    deliveries: 98502,
    numClicks: 7030,
    clickRate: 7.14,
  },
  {
    mediaURN:
      'urn:media:article:https://www.engadget.com/stable-diffusion-version-2-update-artist-styles-nsfw-work-124513511.html',
    title:
      'Stable Diffusion update removes ability to copy artist styles or make NSFW works',
    imageURL:
      'https://s.yimg.com/uu/api/res/1.2/UxKv0lbN7Bpo2QPGg5Ys1g--~B/Zmk9ZmlsbDtoPTQzODt3PTg3NTthcHBpZD15dGFjaHlvbg--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2022-11/a0f17a10-6caf-11ed-adef-6471c8c12f2e.cf.webp',
    editionSubject: 'Engadget Weekly 25 November 2022',
    sendDate: 1669377600,
    deliveries: 128512,
    numClicks: 9011,
    clickRate: 7.01,
  },
];

interface Column {
  name: string;
  label: string;
  isSortable: boolean;
  defaultSortOrder?: 'ASC' | 'DESC';
  dataType?: 'string' | 'number';
}

const columns: Column[] = [
  {
    name: 'blank',
    label: '',
    isSortable: false,
  },
  {
    name: 'title',
    label: 'Title',
    isSortable: true,
    defaultSortOrder: 'ASC',
    dataType: 'string',
  },
  {
    name: 'editionSubject',
    label: 'Edition subject',
    isSortable: true,
    defaultSortOrder: 'ASC',
    dataType: 'string',
  },
  {
    name: 'sendDate',
    label: 'Send date',
    isSortable: true,
    defaultSortOrder: 'ASC',
    dataType: 'number',
  },
  {
    name: 'deliveries',
    label: 'Deliveries',
    isSortable: true,
    defaultSortOrder: 'DESC',
    dataType: 'number',
  },
  {
    name: 'numClicks',
    label: 'Total clicks',
    isSortable: true,
    defaultSortOrder: 'DESC',
    dataType: 'number',
  },
  {
    name: 'clickRate',
    label: 'Click rate',
    isSortable: true,
    defaultSortOrder: 'DESC',
    dataType: 'number',
  },
];

interface ArticleAnalyticsProps {
  campaignURN: string;
}

function ArticleAnalytics({ campaignURN }: ArticleAnalyticsProps) {
  const [sortBy, setSortBy] = useState('clickRate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortedArticles, setSortedArticles] = useState(
    utility.cloneObject(rawArticles)
  );

  useEffect(() => {
    const column = columns.find(col => col.name === sortBy);
    if (column && column.dataType === 'string') {
      setSortedArticles(
        utility
          .cloneObject(rawArticles)
          .sort((a: Article, b: Article) =>
            sortOrder === 'ASC'
              ? String(a[sortBy as keyof Article]).localeCompare(
                  String(b[sortBy as keyof Article])
                )
              : String(b[sortBy as keyof Article]).localeCompare(
                  String(a[sortBy as keyof Article])
                )
          )
      );
    } else if (column && column.dataType === 'number') {
      setSortedArticles(
        utility
          .cloneObject(rawArticles)
          .sort((a: Article, b: Article) =>
            sortOrder === 'ASC'
              ? Number(a[sortBy as keyof Article]) -
                Number(b[sortBy as keyof Article])
              : Number(b[sortBy as keyof Article]) -
                Number(a[sortBy as keyof Article])
          )
      );
    }
  }, [sortBy, sortOrder]);

  const trProps: React.ComponentProps<typeof Tr> = {
    boxShadow: 'borderTop',
  };
  const thProps: React.ComponentProps<typeof Th> = {
    borderBottomColor: 'gray.300',
    borderBottomWidth: '1px',
    color: 'gray.900',
    cursor: 'pointer',
    fontSize: 'sm',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    px: 5,
    py: 4,
    textTransform: 'none',
  };
  const tdProps: React.ComponentProps<typeof Td> = {
    background: 'white',
    borderBottomColor: 'gray.300',
    borderBottomWidth: '1px',
    color: 'gray.900',
    fontSize: 'sm',
    overflow: 'hidden',
    px: 5,
    py: 4,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const handleSort = (field: string) => {
    if (sortBy === field) {
      setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      setSortBy(field);
      const column = columns.find(col => col.name === field);
      setSortOrder(column?.defaultSortOrder ?? 'ASC');
    }
  };

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h3">Top links</Heading>
      <TableContainer>
        <Table
          variant="simple"
          border="1px"
          borderBottom="none"
          borderColor="gray.300"
          borderRadius="md"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: 0,
            tableLayout: 'fixed',
          }}
        >
          <Thead bg="gray.100">
            <Tr>
              {columns.map(column => (
                <Th
                  {...thProps}
                  cursor={column.isSortable ? 'pointer' : 'default'}
                  key={`column-${column.name}`}
                  onClick={
                    column.isSortable ? () => handleSort(column.name) : () => {}
                  }
                >
                  <Flex gap={2}>
                    {column.label}
                    {column.name === sortBy &&
                      (sortOrder === 'ASC' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      ))}
                    {column.name !== sortBy && (
                      <ArrowUpIcon style={{ opacity: 0 }} />
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortedArticles.map((article: Article) => (
              <Tr key={article.mediaURN} {...trProps}>
                <Td {...tdProps}>
                  <Tooltip
                    label={
                      <Image
                        src={article.imageURL}
                        maxHeight="240px"
                        borderRadius="base"
                      />
                    }
                    placement="top"
                    gutter={4}
                    maxWidth="unset"
                    p={0}
                  >
                    <Image
                      width="72px"
                      height="40px"
                      src={article.imageURL}
                      alt={article.title}
                      objectFit="cover"
                      borderRadius="6px"
                    />
                  </Tooltip>
                </Td>
                <Td {...tdProps} width="30%">
                  <Tooltip label={article.title} placement="top-start">
                    <span style={{ display: 'initial' }}>{article.title}</span>
                  </Tooltip>
                </Td>
                <Td {...tdProps} width="20%">
                  <Tooltip label={article.editionSubject} placement="top-start">
                    <span style={{ display: 'initial' }}>
                      {article.editionSubject}
                    </span>
                  </Tooltip>
                </Td>
                <Td {...tdProps} width="10%">
                  {new Date(article.sendDate * 1000).toLocaleDateString()}
                </Td>
                <Td {...tdProps} width="10%">
                  {article.deliveries.toLocaleString()}
                </Td>
                <Td {...tdProps} width="10%">
                  {article.numClicks.toLocaleString()}
                </Td>
                <Td {...tdProps} width="10%">
                  {article.clickRate}%
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}

export default ArticleAnalytics;
