import axios from 'axios';

import { handleAPIError } from 'common/errors';
// import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import * as utility from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function putProperties({
  propertyURN = mandatory('propertyURN'),
  propertyDetails = mandatory('propertyDetails'),
  isStaffUser = mandatory('isStaffUser'),
} = {}) {
  const guid = metrics.start('putProperties');

  const data = utility.cloneObject(propertyDetails);
  // Non-staff users cannot update the propertyName or propertyRootURL fields, so remove this if necessary
  if (!isStaffUser) {
    delete data.propertyName;
    delete data.propertyRootURL;
  }

  const config = {
    url: `${apiBaseURL(APIS.CORE)}/properties/${propertyURN}`,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify(data),
  };

  try {
    const response = await axios(config);
    metrics.end('putProperties', guid);
    return response.data;
  } catch (error) {
    metrics.fail('putProperties', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/putProperties',
      args: {
        propertyURN,
        propertyDetails,
      },
    });
    throw apiError;
  }
}
