import PropTypes from 'prop-types';
import { useState } from 'react';

import DatePicker from 'react-datepicker';

import DatePickerInput from 'components/editor/schedule/DatePickerInput';

import { DATE_PICKER_START_DAY } from 'common/constants';
import { getTimeOffset, getUnixTimestamp } from 'common/datetime';

import { POPPER_MODIFIERS } from 'common/config';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

function TimeSlot({
  scheduledUnixTime,
  finishedSendingTime,
  setScheduledUnixTime,
  setFinishedSendingTime,
}) {
  const [startDate, setStartDate] = useState(
    new Date(scheduledUnixTime * 1000)
  );
  const [endDate, setEndDate] = useState(new Date(finishedSendingTime * 1000));

  const changeStartDate = date => {
    // Makes sure new date is larger than the current date so user can't set time in the past
    const newDate = date >= new Date() ? date : new Date();

    setStartDate(newDate);
    setScheduledUnixTime(getUnixTimestamp(newDate));

    if (newDate >= endDate) {
      // If user tries to select a start time greater than the end time, a 30 minute gap is added
      // to the end time.
      const adjustedEndDate = getTimeOffset(newDate, 30);
      setEndDate(new Date(adjustedEndDate));
      // Divide by 1000 as we want unix timestamp in seconds not miliseconds
      setFinishedSendingTime(adjustedEndDate / 1000);
    }
  };

  const changeEndDate = date => {
    if (date > startDate) {
      setEndDate(date);
      setFinishedSendingTime(getUnixTimestamp(date));
    } else {
      // If user tries to select an end time smaller than the start time, a 30 minute gap is added
      // to the end time.
      const adjustedEndDate = getTimeOffset(startDate, 30);
      setEndDate(new Date(adjustedEndDate));
      setFinishedSendingTime(adjustedEndDate / 1000);
    }
  };

  return (
    <div className="mt-1 pl-4 w-100">
      <div className="ebx-body-1 ebx-faded-colour mt-3 mb-1">Start</div>
      <div className="d-flex flex-wrap">
        <div className="mr-2 date-picker-container">
          <DatePicker
            customInput={<DatePickerInput date={true} />}
            dateFormat="d-MMM-yy"
            minDate={new Date()}
            popperModifiers={POPPER_MODIFIERS}
            onChange={changeStartDate}
            selected={startDate}
            calendarStartDay={DATE_PICKER_START_DAY}
          />
        </div>
        <div>
          <DatePicker
            customInput={<DatePickerInput date={false} />}
            dateFormat="HH:mm"
            onChange={changeStartDate}
            popperModifiers={POPPER_MODIFIERS}
            selected={startDate}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="Start Time"
            timeFormat="HH:mm"
            timeIntervals={30}
          />
        </div>
      </div>

      <div className="ebx-body-1 ebx-faded-colour mt-3 mb-1">End</div>
      <div className="d-flex flex-wrap">
        <div className="mr-2 date-picker-container">
          <DatePicker
            customInput={<DatePickerInput date={true} />}
            dateFormat="d-MMM-yy"
            minDate={new Date()}
            onChange={changeEndDate}
            popperModifiers={POPPER_MODIFIERS}
            selected={endDate}
            calendarStartDay={DATE_PICKER_START_DAY}
          />
        </div>
        <div>
          <DatePicker
            customInput={<DatePickerInput date={false} />}
            dateFormat="HH:mm"
            onChange={changeEndDate}
            popperModifiers={POPPER_MODIFIERS}
            selected={endDate}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="End Time"
            timeFormat="HH:mm"
            timeIntervals={30}
          />
        </div>
      </div>
    </div>
  );
}

TimeSlot.propTypes = {
  scheduledUnixTime: PropTypes.number.isRequired,
  finishedSendingTime: PropTypes.number.isRequired,
  setScheduledUnixTime: PropTypes.func.isRequired,
  setFinishedSendingTime: PropTypes.func.isRequired,
};

export default TimeSlot;
