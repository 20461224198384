import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function ErrorMessage({ errorMessage }) {
  return (
    <Modal.Body className="p-0 text-center p-4 ebx-modal-body">
      <div className="error--text">{errorMessage}</div>
    </Modal.Body>
  );
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorMessage;
