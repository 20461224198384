/* eslint react-hooks/exhaustive-deps: "off" */

import {
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import Loading from 'components/settings/Loading';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { PROPERTY_SETTING_TYPES } from 'common/constants';

function SignUp() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState('');

  const emailInputLabel = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'emailInputLabel'
  );
  const signupButtonLabel = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'signupButtonLabel'
  );
  const helperText = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'helperText'
  );
  const isFirstNameLastNameEnabled = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PERSONALISATION,
    'isFirstNameLastNameEnabled'
  );
  const firstNameLabel = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PERSONALISATION,
    'firstNameLabel'
  );
  const lastNameLabel = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PERSONALISATION,
    'lastNameLabel'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Branding-General-SignUp:handleSave');
      if (emailInputLabel.trim().length === 0) {
        setErrorMessage('Please enter a label for the email address field');
        return;
      }
      if (signupButtonLabel.trim().length === 0) {
        setErrorMessage('Please enter a label for the sign up button');
        return;
      }
      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const saveErrorMessage = errors.getErrorMessage(error);
        settings.setError(saveErrorMessage);
        toast({ variant: 'error', title: saveErrorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleFieldChange = (type, field, event) => {
    setErrorMessage('');
    settings.setPropertySettingValue({
      settingType: type,
      settingKey: field,
      settingValue: event.target.value,
    });
    if (
      type === PROPERTY_SETTING_TYPES.PERSONALISATION &&
      field === 'isFirstNameLastNameEnabled' &&
      event.target.value
    ) {
      tracker.track({
        eventName: 'Update Multi-Campaign Personalisation',
        trackingParams: {
          'Personalisation Status': event.target.value ? 'On' : 'Off',
        },
      });
    }
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Heading variant="h3" mt={6} mb={4}>
        Sign up section
      </Heading>
      {errorMessage && (
        <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
          {errorMessage}
        </div>
      )}
      <FormControl mb={6}>
        <FormControl.FormLabel>Email address</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Email address"
          value={emailInputLabel}
          onChange={event =>
            handleFieldChange(
              PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
              'emailInputLabel',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={6}>
        <FormControl.FormLabel>Legal requirements text</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Legal requirements"
          value={helperText}
          onChange={event =>
            handleFieldChange(
              PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
              'helperText',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <FormControl mb={12}>
        <FormControl.FormLabel>Sign up button</FormControl.FormLabel>
        <Input
          type="text"
          placeholder="Sign up"
          value={signupButtonLabel}
          onChange={event =>
            handleFieldChange(
              PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
              'signupButtonLabel',
              event
            )
          }
          autoComplete="off"
          required
        />
      </FormControl>
      <>
        <Heading variant="h3" mt={6}>
          Subscription fields
        </Heading>
        <FormControl>
          <FormControl.FormHelperText mb={4}>
            Choose any additional fields for your Subscription page
          </FormControl.FormHelperText>
          <Flex mb={4}>
            <Checkbox mr={3} isDisabled={true} />
            <FormControl.FormLabel color="gray.600" mb={0}>
              Email address (mandatory)
            </FormControl.FormLabel>
          </Flex>
          <Flex mb={6}>
            <Checkbox
              mr={3}
              isChecked={isFirstNameLastNameEnabled}
              onChange={() =>
                handleFieldChange(
                  PROPERTY_SETTING_TYPES.PERSONALISATION,
                  'isFirstNameLastNameEnabled',
                  { target: { value: !isFirstNameLastNameEnabled } }
                )
              }
            />
            <FormControl.FormLabel color="gray.900" mb={0}>
              First and last name
            </FormControl.FormLabel>
          </Flex>
        </FormControl>
        {isFirstNameLastNameEnabled && (
          <>
            <FormControl mb={8}>
              <FormControl.FormLabel>First name</FormControl.FormLabel>
              <Input
                type="text"
                placeholder="First name"
                value={firstNameLabel}
                onChange={event =>
                  handleFieldChange(
                    PROPERTY_SETTING_TYPES.PERSONALISATION,
                    'firstNameLabel',
                    event
                  )
                }
                autoComplete="off"
                required
              />
            </FormControl>
            <FormControl mb={8}>
              <FormControl.FormLabel>Last name</FormControl.FormLabel>
              <Input
                type="text"
                placeholder="Last name"
                value={lastNameLabel}
                onChange={event =>
                  handleFieldChange(
                    PROPERTY_SETTING_TYPES.PERSONALISATION,
                    'lastNameLabel',
                    event
                  )
                }
                autoComplete="off"
                required
              />
            </FormControl>
          </>
        )}
      </>
    </>
  );
}

export default SignUp;
