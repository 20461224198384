import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

function ImportSubscribers({ appName }) {
  const { urn: campaignURN } = useParams();

  const integrationText =
    appName === 'Custom Integration'
      ? 'You can use Zapier to import subscribers from more than 300 software solutions'
      : `You can use Zapier to automatically import subscribers from ${appName} to Echobox`;

  return (
    <>
      <div className="ebx-body-1">{integrationText}</div>
      <div className="ebx-accordion mt-4">
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <div className="d-flex flex-align-items-center ebx-cursor">
                <ChevronRight />
                <div className="ebx-title ml-1">Step 1 - Create a new Zap</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="ebx-body-1 mb-3">
                  Navigate to{' '}
                  <a
                    href="https://zapier.com/app/dashboard"
                    className="ebx-h4 ebx-cursor"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://zapier.com/app/dashboard
                  </a>{' '}
                  and click Create Zap
                </div>
                <img
                  src="/assets/img/import-step-1.png"
                  alt="Step 1"
                  title="Step 1"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
              <div className="d-flex flex-align-items-center ebx-cursor">
                <ChevronRight />
                <div className="ebx-title ml-1">Step 2 - Set your trigger</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="ebx-body-1 mb-3">
                  A &apos;Trigger&apos; is an event that causes your Zap to
                  fire. For example, if you wanted to sync Google Form responses
                  with your Email Campaigns, your trigger could be &apos;New
                  Response in Spreadsheet&apos;
                </div>
                <img
                  src="/assets/img/import-step-2.png"
                  alt="Step 2"
                  title="Step 2"
                />
                <div className="ebx-body-1 my-3">
                  Just ensure that whatever trigger you use includes the new
                  subscriber&apos;s email address, as that is what will be sent
                  to Echobox.
                </div>
                <div className="ebx-body-1 mb-0">
                  When you&apos;ve set your trigger, press Continue
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
              <div className="d-flex flex-align-items-center ebx-cursor">
                <ChevronRight />
                <div className="ebx-title ml-1">
                  Step 3 - Choose app and event
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className="ebx-body-1">
                  Use{' '}
                  <span className="ebx-link font-weight-bold">
                    Webhooks by Zapier
                  </span>{' '}
                  to automatically send your subscriber information to Echobox
                </div>
                <ul className="ebx-body-1">
                  <li>App = Webhooks by Zapier</li>
                  <li>Action Event = POST</li>
                </ul>
                <img
                  src="/assets/img/import-step-3.png"
                  alt="Step 3"
                  title="Step 3"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
              <div className="d-flex flex-align-items-center ebx-cursor">
                <ChevronRight />
                <div className="ebx-title ml-1">Step 4 - Set up action</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div className="ebx-body-1 mb-3">
                  This explains how your new subscriber will be delivered to
                  Echobox
                </div>
                <img
                  src="/assets/img/import-step-4.png"
                  alt="Step 4"
                  title="Step 4"
                />
                <ul className="ebx-body-1">
                  <li>
                    URL =
                    https://subscribe.campaign-list.com/v1/public/campaigns/subscribe
                  </li>
                  <li>Payload Type = json</li>
                  <li>Data</li>
                  <ul>
                    <li>
                      email = [Select email field from your trigger application]
                    </li>
                    <li>campaignURN = {campaignURN}</li>
                  </ul>
                  <li>Leave the other fields as default</li>
                </ul>
                <div className="ebx-body-1 mb-0">Click Continue</div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
              <div className="d-flex flex-align-items-center ebx-cursor">
                <ChevronRight />
                <div className="ebx-title ml-1">Step 5 - Testing</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <div className="ebx-body-1">
                  You are now ready to test your integration!
                </div>
                <ul className="ebx-body-1 mt-1">
                  <li>Try using Zapier&apos;s testing tool</li>
                  <li>Subscribe test email addresses using your chosen form</li>
                  <li>Remember to check whether the email field is correct</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </>
  );
}

ImportSubscribers.propTypes = {
  appName: PropTypes.string.isRequired,
};

export default ImportSubscribers;
