import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function deleteSyndfeed({
  propertyURN = mandatory('propertyURN'),
  syndfeedURN = mandatory('syndfeedURN'),
}) {
  const guid = metrics.start('deleteSyndfeed');
  const encodedSyndFeedURN = encodeURIComponent(syndfeedURN);

  const url = `${apiBaseURL(
    APIS.CORE,
    '1'
  )}/properties/${propertyURN}/syndfeeds/${encodedSyndFeedURN}`;
  const config = {
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  logger.info(
    `API:deleteSyndfeed /properties/${propertyURN}/syndfeeds/${encodedSyndFeedURN}`
  );

  try {
    const response = await axios.delete(url, config);
    metrics.end('deleteSyndfeed', guid);
    logger.info('deleteSyndfeed', { propertyURN, syndfeedURN });
    return response;
  } catch (error) {
    metrics.fail('deleteSyndfeed', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/deleteSyndfeed',
      args: {
        propertyURN,
        syndfeedURN,
      },
    });
    throw apiError;
  }
}
