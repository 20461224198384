import {
  Button,
  CheckCircleIcon,
  Flex,
  FormControl,
  Input,
  Modal,
  Text,
  Toggle,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChangeEvent, useState } from 'react';

import { Typeahead } from 'react-bootstrap-typeahead';

import * as API from 'api/api';
import { AxiosError } from 'axios';
import { FRONTEND_FORM_STATES } from 'common/enums';
import { getErrorMessage } from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import { ErrorResponse } from 'common/types';
import * as validation from 'common/validation';

interface InputStateProps {
  campaignURN: string;
  isOpen: boolean;
  onClose: () => void;
  setModalState: (modalState: FRONTEND_FORM_STATES) => void;
  handleSuccess?: () => void;
}

function InputState({
  campaignURN,
  isOpen,
  onClose,
  setModalState,
  handleSuccess,
}: InputStateProps) {
  const [subscriberEmailAddresses, setSubscriberEmailAddresses] = useState(
    [] as string[]
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isFullyDelete, setIsFullyDelete] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const confirmationMessage = 'DELETE';

  const handleChange = (selected: { label: string }[]) => {
    const subscriberEmails = [] as string[];
    const lowerCaseEmails = new Set();
    if (selected.length) {
      selected.forEach(item => {
        const itemToAdd = item?.label ?? item;
        if (!validation.isValidEmail(itemToAdd)) {
          setErrorMessage('Invalid email address');
        } else if (!lowerCaseEmails.has(itemToAdd.toLowerCase())) {
          subscriberEmails.push(itemToAdd);
          lowerCaseEmails.add(itemToAdd.toLowerCase());
          setErrorMessage('');
        }
      });
    }
    setSubscriberEmailAddresses(subscriberEmails);
  };

  const handleFullyDeleteSwitch = () => {
    setIsFullyDelete(val => !val);
  };

  const handleConfirmationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteConfirmation(event.target.value);
  };

  const removeButtonDisabled =
    subscriberEmailAddresses.length === 0 ||
    !!errorMessage ||
    (isFullyDelete && deleteConfirmation !== confirmationMessage);

  const handleRemoveSubscribers = async () => {
    if (subscriberEmailAddresses.length === 0) {
      setErrorMessage('At least one email address must be entered');
    } else {
      const subscriberDetails = subscriberEmailAddresses.map(email => ({
        email,
      }));

      try {
        tracker.track({
          eventName: 'Remove Subscribers',
          trackingParams: {
            'Number Removed': subscriberDetails.length,
            'Historical Data Deleted': isFullyDelete,
          },
        });

        await API.deleteCampaignSubscribers({
          campaignURN,
          subscriberDetails,
          fullyDelete: isFullyDelete,
        });
        handleSuccess?.();
        setModalState(FRONTEND_FORM_STATES.SUCCESS);
      } catch (error) {
        if (
          (error as AxiosError<ErrorResponse>).response?.data?.error?.target ===
          'email'
        ) {
          setErrorMessage(
            (error as AxiosError<ErrorResponse>).response?.data.error.message
          );
        } else {
          setErrorMessage(getErrorMessage(error));
        }
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered>
      <Modal.Header>
        <Modal.Title>Unsubscribe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl isInvalid={!!errorMessage}>
          <FormControl.FormLabel display="flex" flexDir="column" gap={2}>
            Email Addresses
          </FormControl.FormLabel>
          <Typeahead
            id="unsubscribe_emails_typeahead"
            emptyLabel="Please type an email address..."
            // @ts-ignore
            onChange={handleChange}
            newSelectionPrefix="Add email: "
            options={[]}
            selected={subscriberEmailAddresses.map(email => ({
              label: email,
            }))}
            placeholder="Please type an email address..."
            allowNew
            multiple
          />
          {!errorMessage ? (
            <FormControl.FormHelperText>
              Unsubscribe these email addresses from your subscriber list.
              Duplicates will be skipped.
            </FormControl.FormHelperText>
          ) : (
            <FormControl.FormErrorMessage>
              {errorMessage}
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
        <Modal.Divider />
        <FormControl display="flex" alignItems="start">
          <Toggle
            id="fullyDelete"
            mr={3}
            checked={isFullyDelete}
            onChange={handleFullyDeleteSwitch}
          />
          <FormControl.FormLabel htmlFor="fullyDelete" m={0}>
            Delete all historical data
            <Text size="sm" color="gray.600" pt={1}>
              Permanently delete all historical data associated with this
              subscriber (for Right-to-be-Forgotten-Requests)
            </Text>
          </FormControl.FormLabel>
        </FormControl>
        {isFullyDelete && (
          <FormControl pt={6}>
            <FormControl.FormLabel>
              Type {confirmationMessage} to confirm
            </FormControl.FormLabel>
            <Input
              type="text"
              maxLength={120}
              value={deleteConfirmation}
              onChange={handleConfirmationChange}
            />
          </FormControl>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleRemoveSubscribers}
          isDisabled={removeButtonDisabled}
        >
          Remove subscribers
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface SuccessStateProps {
  isOpen: boolean;
  onClose: () => void;
}

function SuccessState({ isOpen, onClose }: SuccessStateProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered>
      <Modal.Header>
        <Modal.Title>Successful removed subscribers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={1}>
          <CheckCircleIcon size={5} color="green.600" />
          <Text size="sm" color="gray.600">
            We have unsubscribed these email addresses from your Campaign.
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface UnsubscribeModalProps {
  campaignURN: string;
  isOpen: boolean;
  onClose: () => void;
  handleSuccess?: () => void;
}

function UnsubscribeModal({
  campaignURN,
  isOpen,
  onClose,
  handleSuccess,
}: UnsubscribeModalProps) {
  const [modalState, setModalState] = useState(FRONTEND_FORM_STATES.INPUT);
  switch (modalState) {
    case FRONTEND_FORM_STATES.INPUT:
      return (
        <InputState
          campaignURN={campaignURN}
          isOpen={isOpen}
          onClose={onClose}
          handleSuccess={handleSuccess}
          setModalState={setModalState}
        />
      );
    case FRONTEND_FORM_STATES.SUCCESS:
      return <SuccessState isOpen={isOpen} onClose={onClose} />;
    default:
      logger.error({
        event: 'Unknown modal form state',
        error: `Unknown form state ${modalState}`,
      });
      return null;
  }
}

export default UnsubscribeModal;
