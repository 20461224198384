/* eslint react-hooks/exhaustive-deps: "off" */

import {
  Alert,
  Button,
  Tooltip,
  useDisclosure,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'fast-deep-equal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as DeleteTrash } from 'assets/svg/delete-trash.svg';
import * as campaigns from 'common/campaigns';
import * as errorsCommon from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import * as users from 'common/users';
import * as utility from 'common/utility';
import * as zendesk from 'common/zendesk';
import { Description, Dropdown } from 'components/settings/campaigns/common';
import DeleteCampaignModal from 'components/settings/campaigns/DeleteCampaignModal';
import Loading from 'components/settings/Loading';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import useStaffUser from 'hooks/useStaffUser';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';
import { FORM_VALIDATION_MODES } from 'common/enums';
import { TIME_ZONE_OPTIONS } from 'common/timezones';

import './CampaignDetails.css';

const CampaignDetailsSchema = Yup.object().shape({
  campaignName: Yup.string().required('Campaign name is required'),
  fromName: Yup.string().required('From name is required'),
  fromEmail: Yup.string()
    .email('This is not a valid email address')
    .required('Email address is required'),
  replyToEmail: Yup.string()
    .email('This is not a valid email address')
    .required('Reply-to address is required'),
  returnPath: Yup.string()
    .email('This is not a valid email address')
    .required('Return path is required'),
});

const getTimezone = timezoneString => {
  let timezone = TIME_ZONE_OPTIONS.find(
    option => option.value === timezoneString
  );
  /* Default to GMT if no match found */
  if (!timezone) {
    timezone = {
      label: '(GMT+00:00) Greenwich Mean Time',
      value: 'Etc/GMT',
    };
  }

  return timezone;
};

function CampaignDetails() {
  const { urn } = useParams();
  const globalInfo = useGlobalInfo();
  const settings = useSettings();
  const isStaffUser = useStaffUser();
  const toast = useToast();
  const campaignDetails = campaigns.getCampaign(urn, globalInfo);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [defaultValues, setDefaultValues] = useState({
    campaignName: campaignDetails.campaignName,
    fromName: campaignDetails?.fromName ?? 'Full name',
    fromEmail: campaignDetails?.fromEmail ?? 'email@address.com',
    replyToEmail:
      campaignDetails?.replyToEmails?.join(', ') ?? 'email@address.com',
    returnPath: campaignDetails?.returnPath ?? 'email@address.com',
  });

  const initialValues = useRef(utility.cloneObject(defaultValues));

  const canDeleteCampaign = users.isAdministrator(globalInfo);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(CampaignDetailsSchema),
    mode: FORM_VALIDATION_MODES.ONSUBMIT,
    reValidateMode: FORM_VALIDATION_MODES.ONSUBMIT,
    defaultValues,
  });

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const setChanged = useCallback(() => {
    if (!isEqual(defaultValues, initialValues.current)) {
      settings.setChanged();
    } else {
      settings.setUnchanged();
    }
  }, [defaultValues, settings, initialValues.current]);

  useEffect(() => {
    settings.setOnReload(() => {
      settings.setDone();
      toast({ variant: 'success', title: 'Changes saved successfully' });
    });
    const handleSave = async newCampaignDetails => {
      logger.info('CampaignDetails:handleSave');
      try {
        await settings.saveCampaignSettings({
          campaignURN: urn,
        });

        await settings.saveCampaignDetails({
          campaignURN: urn,
          campaignName: newCampaignDetails.campaignName,
          fromName: newCampaignDetails.fromName,
          fromEmail: newCampaignDetails.fromEmail,
          replyToEmails: [newCampaignDetails.replyToEmail],
          returnPath: newCampaignDetails.returnPath,
        });

        setDefaultValues(utility.cloneObject(newCampaignDetails));
        initialValues.current = utility.cloneObject(newCampaignDetails); // NL-111
      } catch (error) {
        const errorMessage = errorsCommon.getErrorMessage(error);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };
    settings.setOnSave(handleSubmit(handleSave));
  }, [urn, handleSubmit, settings, setChanged]);

  const handleChange = event => {
    defaultValues[event.target.name] = event.target.value;
    setChanged();
  };

  const timezone = getTimezone(
    settings.getCampaignSettingValue(
      CAMPAIGN_SETTING_TYPES.SCHEDULING,
      'timezone'
    )
  );

  const handleTimezoneChange = ({ value }) => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SCHEDULING,
      settingKey: 'timezone',
      settingValue: value,
    });
    tracker.track({
      eventName: 'Update Timezone',
      trackingParams: {
        'Timezone (before)': timezone.value,
        'Timezone (after)': value,
      },
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-100 campaign-details-settings">
      <Row className="pb-2 mb-5">
        <Col>
          <div className="mb-3">
            <span className="ebx-h1">Campaign name</span>
          </div>
          <FormControl
            className="campaign-details--input settings--input--sm ebx-body-1"
            {...register('campaignName')}
            maxLength="120"
            placeholder={defaultValues.campaignName}
            onChange={handleChange}
            type="text"
          />
          {errors.campaignName && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errors.campaignName.message}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-4">
            <span className={isStaffUser ? 'ebx-h1' : 'settings__header'}>
              Sender information
            </span>
            {isStaffUser ? (
              <Alert variant="error" mt={6}>
                <Alert.AlertTitle>Warning</Alert.AlertTitle>
                <Alert.AlertDescription>
                  <strong>Echobox Staff Only:</strong> Please ensure that the
                  sender details match the domain that was verified during the
                  customer&apos;s onboarding. Otherwise, the customer will not
                  be able to send Editions.
                </Alert.AlertDescription>
              </Alert>
            ) : (
              <>
                <span className="ml-3 ebx-body-2 campaign-details__change-label">
                  To change, please{' '}
                </span>
                <Button variant="link" onClick={() => zendesk.openWidget()}>
                  contact support
                </Button>
              </>
            )}
          </div>
          <div className={isStaffUser ? 'ebx-h3' : 'settings__header'}>
            From name
          </div>
          <FormControl
            className="campaign-details--input settings--input--sm ebx-body-1 mb-2"
            {...register('fromName')}
            readOnly={!isStaffUser}
            maxLength="120"
            placeholder={defaultValues.fromName}
            onChange={handleChange}
            type="text"
          />
          {errors.fromName && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errors.fromName.message}
            </div>
          )}
          <p className="campaign-details--help-text mb-4">
            The name readers will see in their inbox
          </p>
          <div className={isStaffUser ? 'ebx-h3' : 'settings__header'}>
            Email address
          </div>
          <FormControl
            className="campaign-details--input settings--input--sm ebx-body-1 mb-2"
            {...register('fromEmail')}
            readOnly={!isStaffUser}
            maxLength="120"
            placeholder={defaultValues.fromEmail}
            onChange={handleChange}
            type="text"
          />
          {errors.fromEmail && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errors.fromEmail.message}
            </div>
          )}
          <p className="campaign-details--help-text mb-4">
            The email address the Campaign will be sent from
          </p>
          <div className={isStaffUser ? 'ebx-h3' : 'settings__header'}>
            Reply-to address
          </div>
          <FormControl
            className="campaign-details--input settings--input--sm ebx-body-1 mb-2"
            {...register('replyToEmail')}
            readOnly={!isStaffUser}
            maxLength="120"
            placeholder={defaultValues.replyToEmail}
            onChange={handleChange}
            type="text"
          />
          {errors.replyToEmail && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errors.replyToEmail.message}
            </div>
          )}
          <p className="campaign-details--help-text mb-4">
            The email address readers can reply to
          </p>
          <div className={isStaffUser ? 'ebx-h3' : 'settings__header'}>
            Return path
          </div>
          <FormControl
            className="campaign-details--input settings--input--sm ebx-body-1 mb-2"
            {...register('returnPath')}
            readOnly={!isStaffUser}
            maxLength="120"
            placeholder={defaultValues.returnPath}
            onChange={handleChange}
            type="text"
          />
          {errors.returnPath && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errors.returnPath.message}
            </div>
          )}
          <p className="campaign-details--help-text">
            The email address that will receive bounce notifications
          </p>
        </Col>
      </Row>
      <div className="mt-5 ebx-h1">Time zone</div>
      <Description>Customise your schedule to your region</Description>
      <div className="mb-5" style={{ maxWidth: '500px' }}>
        <Dropdown
          placeholder="Select time zone"
          options={TIME_ZONE_OPTIONS}
          value={timezone}
          onChange={handleTimezoneChange}
          isSearchable
        />
      </div>
      <Tooltip
        isDisabled={canDeleteCampaign}
        label="Only users with admin permissions can delete a Campaign"
        placement="right"
        shouldWrapChildren={true}
      >
        <Button
          variant="secondary"
          leftIcon={<DeleteTrash />}
          lineHeight="5"
          onClick={onOpen}
          isDisabled={!canDeleteCampaign}
        >
          Delete Campaign
        </Button>
      </Tooltip>
      {isOpen && (
        <DeleteCampaignModal
          isOpen={isOpen}
          onClose={onClose}
          campaignName={campaignDetails?.campaignName}
          campaignURN={campaignDetails?.campaignURN}
        />
      )}
    </div>
  );
}

export default CampaignDetails;
