import PropTypes from 'prop-types';
import { createFilter } from 'react-select';
import WindowedSelect from 'react-windowed-select';

import { loadFont } from 'common/googlefonts';

const emailSafeFonts = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Courier New', label: 'Courier New' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' },
  { value: 'Tahoma', label: 'Tahoma' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Trebuchet MS', label: 'Trebuchet MS' },
  { value: 'Verdana', label: 'Verdana' },
];

const localGoogleFonts = [
  { value: 'Roboto', label: 'Roboto', type: 'google' },
  { value: 'Merriweather', label: 'Merriweather', type: 'google' },
  { value: 'Open Sans', label: 'Open Sans', type: 'google' },
];

function GoogleFontsDropdown({
  googleFonts,
  chosenFontFamily,
  onChange,
  value,
  loadingFonts,
}) {
  if (!googleFonts[0]) {
    localGoogleFonts.forEach(localGoogleFont => {
      loadFont(localGoogleFont.value);
    });
  }

  // When scrolling stops find index and load the 10 fonts ahead and the 10 fonts before.

  const dropdownStyles = {
    control: (provided, { isFocused, isSelected }) => ({
      ...provided,
      borderRadius: '6px !important',
      borderColor: 'var(--gray-300)',
      boxShadow:
        isFocused || isSelected
          ? '0px 0px 0px 4px rgba(51, 113, 230, 0.4) !important'
          : '0px 1px 2px rgba(14, 30, 57, 0.12)',
      fontFamily: chosenFontFamily,
      padding: '6px 2px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: 'var(--gray-600)',
      fontFamily: state.value,
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '6px !important',
      boxShadow:
        '0px 8px 12px rgba(9, 30, 66, 0.08), 0px 0px 1px rgba(9, 30, 66, 0.31)',
      fontFamily: state.value,
      padding: '8px 0',
      top: 'calc(100% + 5px)',
    }),
    option: (provided, state) => ({
      ...provided,
      alignItems: 'center',
      background:
        state.isFocused || state.isSelected
          ? 'var(--gray-100)'
          : 'var(--white)',
      color: state.isSelected ? 'var(--primary-600)' : 'var(--gray-900)',
      display: 'flex',
      fontFamily: state.value,
      fontSize: 14,
      fontWeight: 400,
      justifyContent: 'space-between',
      lineHeight: '20px',
      padding: '8px 16px',
    }),
    noOptionsMessage: provided => ({
      ...provided,
      color: 'var(--gray-500)',
      fontWeight: 400,
      fontSize: 14,
      textAlign: 'left',
    }),
    placeholder: provided => ({
      ...provided,
      color: 'var(--gray-900)',
      opacity: '0.4',
      fontSize: '14px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      fontFamily: state.value,
    }),
  };

  const groupedOptions = [
    {
      label: 'Email Safe Fonts',
      options: emailSafeFonts,
    },
    {
      label: 'Google Fonts',
      options: googleFonts,
    },
  ];

  const localGroupedOptions = [
    {
      label: 'Email Safe Fonts',
      options: emailSafeFonts,
    },
    {
      label: 'Google Fonts',
      options: localGoogleFonts,
    },
  ];

  return (
    <>
      {/* Google fonts do not work locally */}
      {googleFonts[0] ? (
        <WindowedSelect
          options={groupedOptions}
          styles={dropdownStyles}
          noOptionsMessage={() => 'No fonts found'}
          placeholder="Search fonts..."
          onChange={onChange}
          defaultValue={value}
          isDisabled={loadingFonts}
          isLoading={loadingFonts}
          filterOption={createFilter({ ignoreAccents: false })}
          isClearable
        />
      ) : (
        <WindowedSelect
          options={localGroupedOptions}
          styles={dropdownStyles}
          noOptionsMessage={() => 'No fonts found'}
          placeholder="Search fonts..."
          onChange={onChange}
          defaultValue={value}
          isDisabled={loadingFonts}
          isLoading={loadingFonts}
          filterOption={createFilter({ ignoreAccents: false })}
          isClearable
        />
      )}
    </>
  );
}

GoogleFontsDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  googleFonts: PropTypes.array.isRequired,
  chosenFontFamily: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  loadingFonts: PropTypes.bool.isRequired,
};

GoogleFontsDropdown.defaultProps = {
  chosenFontFamily: null,
  value: null,
};

export default GoogleFontsDropdown;
