import React from 'react';

import { Flex, Heading, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

interface ContentSectionWrapperProps {
  title: string;
  description: string;
  tooltip?: React.ReactNode;
  children: React.ReactNode;
}

function ContentSectionWrapper({
  title,
  description,
  tooltip,
  children,
}: ContentSectionWrapperProps) {
  return (
    <Flex flexDir="column">
      <Flex display="flex" alignItems="center" gap={2} mb={2}>
        <Heading variant="h3">{title}</Heading>
        {tooltip}
      </Flex>
      <Text size="sm" color="gray.600" mb={4}>
        {description}
      </Text>
      {children}
    </Flex>
  );
}

export default ContentSectionWrapper;
