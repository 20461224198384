import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  ModalTitle,
} from 'react-bootstrap';

import Modal from 'components/common/Modal';
import VerifiedConfirmationModal from 'components/settings/profile/VerifiedConfirmationModal';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import authVerifyUserEmail from 'api/auth/authVerifyUserEmail';
import authVerifyUserEmailSubmit from 'api/auth/authVerifyUserEmailSubmit';
import * as logger from 'common/logger';

import './VerifyEmailModal.css';

function VerifyEmailModal({ emailAddress, onClose }) {
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleVerify = async () => {
    if (verificationCode.trim() === '') {
      setErrorMessage('Please use the 6 digits code sent to your inbox');
      return;
    }

    setIsSending(true);
    try {
      await authVerifyUserEmailSubmit({ verificationCode });
      setIsSending(false);
      setVerified(true);
    } catch (error) {
      setErrorMessage('Please use the 6 digits code sent to your inbox');
      setVerified(false);
      setIsSending(false);
    }
  };

  const handleChange = e => {
    setErrorMessage('');
    setVerificationCode(e.target.value);
  };

  const handleResendCode = async () => {
    try {
      await authVerifyUserEmail();
    } catch (error) {
      logger.error({
        event: 'handleResendCode Error',
        properties: {
          location: 'components/settings/profile/VerifyEmailModal',
        },
        error,
      });
    }
  };

  if (verified) {
    return (
      <VerifiedConfirmationModal
        emailAddress={emailAddress}
        onClose={onClose}
      />
    );
  }

  return (
    <Modal show dialogClassName="verify-email-modal">
      <Modal.Header>
        <ModalTitle className="verify-email-title">
          Verify your email address
        </ModalTitle>
        <span className="ml-auto">
          <Button
            variant="link"
            className="p-0 verify-email-close"
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </span>
      </Modal.Header>
      <Modal.Body mode="WHITE">
        <div className="verify-email-description">
          <div>
            We have sent an email to{' '}
            <span className="verify-email-address">{emailAddress}</span> with a
            verification code.
          </div>
          <div>Please enter it below to change your email address.</div>
        </div>

        <FormGroup className="verify-email-form-group mb-2">
          <FormLabel className="ebx-h3 mb-1">Verification Code</FormLabel>
          <FormControl
            type="text"
            className={`ebx-input ebx-body-1 verify-email-input ${
              errorMessage ? 'ebx-input-error' : ''
            }`}
            maxLength={6}
            placeholder="The 6 digit code sent to your inbox"
            autoFocus
            value={verificationCode}
            onChange={handleChange}
            disabled={isSending}
          />
          {errorMessage && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errorMessage}
            </div>
          )}
        </FormGroup>
        <Button
          variant="link"
          className="p-0 ebx-link"
          onClick={handleResendCode}
          disabled={isSending}
        >
          Resend code
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3 my-0"
          onClick={onClose}
          disabled={isSending}
        >
          Cancel
        </Button>
        <Button
          className="ebx-btn-primary ebx-btn-md ebx-h3 my-0"
          onClick={handleVerify}
          disabled={isSending}
        >
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

VerifyEmailModal.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VerifyEmailModal;
