import {
  ArrowDownloadIcon,
  Flex,
  Heading,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';

import * as datetime from 'common/datetime';
import { SERVER_UPLOAD_STATE, SERVER_UPLOAD_TYPE } from 'common/enums';
import * as tracker from 'common/tracker';
import { SubscriberUpload } from 'common/types';

interface LatestUploadDateTimeProps {
  timestamp: number;
}

function LatestUploadDateTime({ timestamp }: LatestUploadDateTimeProps) {
  return (
    <Heading variant="h4">
      {datetime.getFormattedDateFromUnix({ timestamp })}
    </Heading>
  );
}

interface LatestUploadProps {
  latestUpload?: SubscriberUpload;
}

interface LatestUploadStateProps {
  state: keyof typeof SERVER_UPLOAD_STATE;
  url?: string;
}

function LatestUploadState({ state, url }: LatestUploadStateProps) {
  const handleExportClick = () => {
    tracker.track({
      eventName: 'Download Errors',
    });
  };

  useEffect(() => {
    const exportErrorsLink = document.getElementById('exportErrorsLink');
    if (exportErrorsLink) {
      exportErrorsLink.addEventListener('click', handleExportClick);
    }

    return () => {
      if (exportErrorsLink) {
        exportErrorsLink.removeEventListener('click', handleExportClick);
      }
    };
  }, []);

  switch (state) {
    case SERVER_UPLOAD_STATE.UPLOADING:
    case SERVER_UPLOAD_STATE.VALIDATING:
    case SERVER_UPLOAD_STATE.INSERTING:
      return <span>Processing...</span>;
    case SERVER_UPLOAD_STATE.COMPLETE:
      return <span>Complete</span>;
    case SERVER_UPLOAD_STATE.FAILED:
      return (
        <Text>
          Failed:
          {url && url !== '' && (
            <>
              {' '}
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                id="exportErrorsLink"
              >
                <span>export errors</span>
                <ArrowDownloadIcon size={5} />
              </a>
            </>
          )}
        </Text>
      );
    default:
      return null;
  }
}

function LatestUpload({ latestUpload }: LatestUploadProps) {
  if (!latestUpload?.uploadTriggerUnixTime) {
    return null; // No upload to report
  }
  if (
    datetime.getUnixTimestamp() - latestUpload.uploadTriggerUnixTime >
    datetime.DAYS(7)
  ) {
    return null; // Last upload was over a week ago
  }
  if (latestUpload.uploadType === SERVER_UPLOAD_TYPE.UNSUBSCRIBE) {
    return null; // Upload facility was used to unsubscribe readers
  }

  return (
    <Flex
      direction="column"
      gap={2}
      pl={6}
      borderLeft={{ base: 'none', sm: '1px solid' }}
      borderColor={{ base: 'none', sm: 'gray.300' }}
    >
      <Flex
        align="center"
        direction={{ base: 'column', lg: 'row' }}
        justify={{ sm: 'flex-start' }}
      >
        <Flex align="center">
          <Text size="sm" color="gray.600" mr={1}>
            Last upload
          </Text>
        </Flex>
      </Flex>
      <Flex align="center" alignItems="center" gap={1}>
        <LatestUploadDateTime timestamp={latestUpload.uploadTriggerUnixTime} />{' '}
        <LatestUploadState
          state={latestUpload.uploadState}
          url={latestUpload?.errorLogURL}
        />
      </Flex>
    </Flex>
  );
}

export default LatestUpload;
