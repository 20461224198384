export enum ARTICLE_ANALYTICS_CS_SORT_FIELDS {
  title = 'title',
  editionSubject = 'editionSubject',
  sendStartUnixTime = 'sendStartUnixTime',
}

export enum ARTICLE_ANALYTICS_NOS_SORT_FIELDS {
  numDeliveries = 'numDeliveries',
  numClicks = 'numClicks',
  clickRate = 'clickRate',
}

export const ARTICLE_ANALYTICS_SORT_FIELDS = {
  ...ARTICLE_ANALYTICS_CS_SORT_FIELDS,
  ...ARTICLE_ANALYTICS_NOS_SORT_FIELDS,
};

export enum CAMPAIGN_PERMISSION_TYPES {
  CAMPAIGN_EDITOR = 'CAMPAIGN_EDITOR',
}

export enum CAMPAIGN_PERMISSION_TYPE_IDS {
  CAMPAIGN_EDITOR = 1,
}

export enum DEFAULT_SECTION_VALUES {
  MAX_ARTICLES = 5,
  ARTICLE_REUSE_DAYS = 28,
}

export enum EDITION_APPROVAL_STATES {
  NEW = 'NEW',
  ECHOBOX_APPROVED = 'ECHOBOX_APPROVED',
  CLIENT_APPROVED = 'CLIENT_APPROVED',
}

export enum EDITION_STATE {
  UNSENT = 'UNSENT',
  SENDING = 'SENDING',
  SENT = 'SENT',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  POPULATING = 'POPULATING',
}

export enum EDITION_SUBJECT_TYPES {
  FIRST_ARTICLE_TITLE = 'FIRST_ARTICLE_TITLE',
  NAME_DDMM = 'NAME_DDMM',
  NAME_MMDD = 'NAME_MMDD',
  NAME_DDMONTH = 'NAME_DDMONTH',
}

export enum EMAIL_PREVIEW_TEXT_TYPES {
  FIRST_ARTICLE_TITLE = 'FIRST_ARTICLE_TITLE',
  SECOND_ARTICLE_TITLE = 'SECOND_ARTICLE_TITLE',
  FIRST_ARTICLE_DESCRIPTION = 'FIRST_ARTICLE_DESCRIPTION',
  CUSTOM = 'CUSTOM',
}

export enum ENGAGEMENT_RATES {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  NO_DATA = 'NO_DATA',
  COLLECTING = 'COLLECTING',
  NEVER = 'NEVER',
}

export enum FRONTEND_FORM_STATES {
  INPUT = 'INPUT',
  VERIFY = 'VERIFY',
  SUCCESS = 'SUCCESS',
}

export enum FORM_VALIDATION_MODES {
  ONCHANGE = 'onChange',
  ONSUBMIT = 'onSubmit',
}

export enum MEDIA_DATA_SOURCE_TYPES {
  NONE = 'NONE',
  OG = 'OG',
  TWITTER = 'TWITTER',
  SYND = 'SYND',
}

export enum MODAL_MODES {
  DEFAULT = 'DEFAULT',
  WHITE = 'WHITE',
}

export enum PREVIEW_TYPES {
  EDITION = 'EDITION',
  EDITION_TEMPLATE = 'EDITION_TEMPLATE',
  HOSTED_SUBS_PAGE = 'HOSTED_SUBS_PAGE',
  HOSTED_SUBS_UNSUBSCRIBE = 'HOSTED_SUBS_UNSUBSCRIBE',
  HOSTED_SUBS_VERIFICATION = 'HOSTED_SUBS_VERIFICATION',
  HOSTED_SUBS_SUCCESS = 'HOSTED_SUBS_SUCCESS',
  MARKETING_EMAIL = 'MARKETING_EMAIL',
  MULTI_CAMPAIGN_SUBS_PAGE = 'MULTI_CAMPAIGN_SUBS_PAGE',
  SUBSCRIBER_CONFIRMATION_EMAIL = 'SUBSCRIBER_CONFIRMATION_EMAIL',
  SUBSCRIBER_VERIFICATION_EMAIL = 'SUBSCRIBER_VERIFICATION_EMAIL',
  ACCESS_PREFERENCES = 'ACCESS_PREFERENCES',
  ACCESS_PREFERENCES_SENT = 'ACCESS_PREFERENCES_SENT',
  ACCESS_PREFERENCES_EMAIL = 'ACCESS_PREFERENCES_EMAIL',
  MANAGE_PREFERENCES = 'MANAGE_PREFERENCES',
  MANAGE_PREFERENCES_SUCCESS = 'MANAGE_PREFERENCES_SUCCESS',
}

export enum PROMOTION_BLOCK_TYPES {
  IMAGE = 'IMAGE',
  ADVERTISING = 'ADVERTISING',
}

export enum PROPERTY_PERMISSION_TYPES {
  NONE = 'NONE',
  VIEW_ONLY = 'VIEW_ONLY',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
}

export enum PROPERTY_PERMISSION_TYPE_IDS {
  NONE = 0,
  VIEW_ONLY = 1,
  EDITOR = 2,
  ADMIN = 5,
}

export enum QUERY_CACHE_SCENARIOS {
  ANALYTICS = 'ANALYTICS',
  SUBSCRIBERS = 'SUBSCRIBERS',
}

export enum SCHEDULE_EDITION_OPTIONS {
  OPTIMAL = 'OPTIMAL',
  TIME_SLOT = 'TIME_SLOT',
  SPECIFIC_TIME = 'SPECIFIC_TIME',
  NOW = 'NOW',
}

export enum SECTION_TYPES {
  ARTICLES = 'ARTICLES',
  ARTICLES_SPONSORED = 'ARTICLES_SPONSORED',
}

export enum SERVER_UPLOAD_STATE {
  UPLOADING = 'UPLOADING',
  VALIDATING = 'VALIDATING',
  INSERTING = 'INSERTING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export enum SERVER_UPLOAD_TYPE {
  REPLACE = 'REPLACE',
  APPEND = 'APPEND',
  UNSUBSCRIBE = 'REMOVE',
}

export enum SORT_ORDERS {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SUBJECT_LINE_WARNINGS {
  LENGTH = 'LENGTH',
  SPAM = 'SPAM',
  HASHTAG = 'HASHTAG',
  PUNCTUATION = 'PUNCTUATION',
  NONE = 'NONE',
}

export enum SUBJECT_LINE_WARNINGS_DESCRIPTION {
  LENGTH = 'Advise shortening subject',
  SPAM = 'Advise removing spam trigger phrases',
  HASHTAG = 'Advise removing hashtags',
  PUNCTUATION = 'Advise removing repeated punctuation',
}

export enum SUBJECT_LINE_WARNINGS_TEXT {
  LENGTH = 'The subject line is too long and may be cut off on mobile devices.',
  SPAM = 'The subject line contains words that could negatively impact your open rate.',
  HASHTAG = 'The subject line contains a hashtag, which could negatively impact your open rate.',
  PUNCTUATION = 'The subject line contains multiple punctuation marks, which could negatively impact your open rate.',
}

export enum SUBSCRIBER_MANAGEMENT_SORT_FIELDS {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  addedUnixTime = 'addedUnixTime',
  engagementRate = 'engagementRate',
}

export enum SUBSCRIBER_MODAL_STATES {
  CLOSED = 'CLOSED',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export enum USER_STATES {
  DISABLED = 'DISABLED',
  INVITED = 'INVITED',
  ENABLED = 'ENABLED',
}

export enum USER_TYPES {
  DEFAULT = 'NEWSLETTER_FRONTEND_USER',
  ECHOBOX_STAFF = 'STAFF_USER',
  SOCIAL = 'SOCIAL_FRONTEND_USER',
}

export enum PRODUCT_TYPES {
  SOCIAL = 'SOCIAL',
  EMAIL = 'EMAIL',
}
