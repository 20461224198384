/* eslint jsx-a11y/anchor-is-valid: "off" */

import PropTypes from 'prop-types';
import { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from 'react-bootstrap';

import { ReactComponent as CheckCircle } from 'assets/svg/check-circle.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down-large.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right-large.svg';
import { ReactComponent as RemoveLink } from 'assets/svg/remove-link.svg';

import './OptimisationOptions.css';

const OPTIMISATION_STATES = {
  ON: true,
  OFF: false,
  UNAVAILABLE: 3,
};

const OPTIMISATION_STATE_DETAILS = {
  [OPTIMISATION_STATES.ON]: {
    label: 'On',
    className: 'ebx-label-active',
    renderIcon: () => <CheckCircle className="mr-1" />,
  },
  [OPTIMISATION_STATES.OFF]: {
    label: 'Off',
    className: 'ebx-label-new',
    renderIcon: () => <RemoveLink className="mr-1" />,
  },
  [OPTIMISATION_STATES.UNAVAILABLE]: {
    label: 'Coming soon',
    className: 'ebx-label-automation',
    renderIcon: null,
  },
};

function CustomToggle({ title, state, showToggle, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  const activeClass = isCurrentEventKey ? 'active' : 'inactive';
  const stateDetail = OPTIMISATION_STATE_DETAILS[state];

  return (
    <a
      className="link-unstyled"
      href="#"
      onClick={showToggle ? decoratedOnClick : null}
    >
      <div className="d-flex flex-align-items-center ebx-cursor accordion-header">
        {showToggle ? (
          <div className="accordion-header-icon">
            {isCurrentEventKey ? (
              <ChevronDown
                className={`card-header-icon card-header-icon-${activeClass}`}
              />
            ) : (
              <ChevronRight
                className={`card-header-icon card-header-icon-${activeClass}`}
              />
            )}
          </div>
        ) : (
          <div className="card-header-icon" />
        )}
        <Card.Header>
          <div className={`card-title card-title-${activeClass}`}>{title}</div>
        </Card.Header>
        <div className="optimisation-option-status ml-auto">
          <div
            className={`${stateDetail.className} px-2 py-1 text-center ebx-h3 d-flex align-items-center`}
          >
            {stateDetail.renderIcon && stateDetail.renderIcon()}
            <span className="mr-1">{stateDetail.label}</span>
          </div>
        </div>
      </div>
    </a>
  );
}

CustomToggle.propTypes = {
  title: PropTypes.string.isRequired,
  state: PropTypes.number.isRequired,
  showToggle: PropTypes.bool,
  eventKey: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

CustomToggle.defaultProps = {
  showToggle: true,
};

function OptimisationOptions({ optimisationUsage }) {
  return (
    <div className="optimisation-options">
      <div className="optimisation-options-header">
        <div className="ebx-h2 mb-1">Optimization level</div>
        <div className="ebx-body-1">
          The higher your optimization level, the higher your Campaign
          performance. For maximum performance, we recommend 100% optimization.
        </div>
      </div>
      <Accordion defaultActiveKey="3">
        <Card>
          <CustomToggle
            eventKey="0"
            title="Timing personalization"
            state={optimisationUsage.timingPersonalisation}
          />
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="card-content ebx-body-1 mb-3">
                Echobox tracks when each subscriber views their emails. Over
                time, Echobox accurately personalizes send times. Each
                subscriber will receive emails at times when they&apos;re most
                likely to open their inbox. This increases your{' '}
                <em>open rate</em>.
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <CustomToggle
            eventKey="2"
            title="Smart content selection"
            state={optimisationUsage.contentOptimisation}
          />
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <div className="card-content ebx-body-1 mb-3">
                Echobox uses advanced deep learning techniques to identify
                content that&apos;s most likely to engage your subscribers. Both
                quantitative and qualitative variables are taken into
                consideration. This increases your <em>click rate</em>.
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <CustomToggle
            eventKey="3"
            title="Smart content order"
            state={optimisationUsage.orderOptimisation}
          />
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <div className="card-content ebx-body-1 mb-3">
                Echobox uses cutting-edge machine learning mechanisms to
                optimally order your content and keep your subscribers more
                engaged. This increases your <em>click rate</em>.
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <CustomToggle
            eventKey="4"
            title="Content personalization"
            state={optimisationUsage?.contentPersonalisation ?? false}
          />
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <div className="card-content ebx-body-1 mb-3">
                Articles will be selected based on each subscriber&apos;s
                preferences.
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

OptimisationOptions.propTypes = {
  optimisationUsage: PropTypes.shape({
    timingPersonalisation: PropTypes.bool.isRequired,
    contentOptimisation: PropTypes.bool.isRequired,
    orderOptimisation: PropTypes.bool.isRequired,
    contentPersonalisation: PropTypes.bool.isRequired,
    optimisationPercentage: PropTypes.number.isRequired,
  }).isRequired,
};

export default OptimisationOptions;
