import type { FormCheckProps } from 'react-bootstrap';
import { FormCheck } from 'react-bootstrap';

type ContentSourceRadioProps = Omit<
  FormCheckProps,
  'className' | 'name' | 'type'
>;

function ContentSourceRadio({
  label,
  ...formCheckProps
}: ContentSourceRadioProps) {
  return (
    <FormCheck
      className="domain-checkbox ebx-h3"
      label={<div className="current-source-radio-label">{label}</div>}
      name="allow-content"
      type="radio"
      {...formCheckProps}
    />
  );
}

export default ContentSourceRadio;
