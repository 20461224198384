/* eslint react-hooks/exhaustive-deps: "off" */

import compare from 'compare-versions';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';

import Error from 'components/Error';
import SessionWrapper from 'containers/SessionWrapper';
import { StoreProvider } from 'store/store';

import * as API from 'api/api';
import * as intervals from 'common/intervals';
import * as logger from 'common/logger';
import { isDefined, isNull, isNullOrUndefined } from 'common/utility';
import { reloadBlocked } from 'state/reloadBlocked';

import { REFRESH_MULTIPLIERS } from 'common/config';

function StoreWrapper() {
  const isReloadBlocked = useRecoilValue(reloadBlocked);

  const reloadLater = packageVersion => {
    sessionStorage.setItem('targetVersion', packageVersion);
  };

  const reloadNow = targetVersion => {
    const currentVersion = sessionStorage.getItem('currentVersion');
    logger.info(`Version changed from ${currentVersion} to ${targetVersion}`);
    sessionStorage.setItem('currentVersion', targetVersion);
    window.location.reload();
  };

  useEffect(() => {
    const targetVersion = sessionStorage.getItem('targetVersion');
    if (!isNull(targetVersion) && !isReloadBlocked) {
      sessionStorage.removeItem('targetVersion');
      reloadNow(targetVersion);
    }
  }, [isReloadBlocked]);

  useEffect(() => {
    const checkVersion = async () => {
      const currentVersion = sessionStorage.getItem('currentVersion');
      const packageVersion = await API.getVersion();
      const isPackageLater = compare(
        packageVersion,
        currentVersion ?? '0.0.0',
        '>'
      );
      if (
        isNullOrUndefined(currentVersion) ||
        (isDefined(packageVersion) && isPackageLater)
      ) {
        if (!isReloadBlocked) {
          reloadNow(packageVersion);
        } else {
          reloadLater(packageVersion);
        }
      }
    };

    checkVersion();
    intervals.setItem(
      'checkVersion',
      checkVersion,
      REFRESH_MULTIPLIERS.CHECK_VERSION
    );

    return () => {
      intervals.removeItem('checkVersion');
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <StoreProvider>
        <SessionWrapper />
      </StoreProvider>
    </ErrorBoundary>
  );
}

export default StoreWrapper;
