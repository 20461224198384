import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Flex,
  Modal,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { StoreContext } from 'store/store';

import * as API from 'api/api';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';

import { GLOBAL_INFO_STATES } from 'common/constants';

export interface deleteCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignName: string;
  campaignURN: string;
}

function DeleteCampaignModal({
  isOpen,
  onClose,
  campaignName,
  campaignURN,
}: deleteCampaignModalProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const history = useHistory();
  const { actions } = useContext(StoreContext);
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < ModalContent.length - 1) setCurrentStep(currentStep + 1);
  };

  const handleCloseModal = () => {
    setCurrentStep(0);
    onClose();
  };

  const continueDelete = () => {
    tracker.track({
      eventName: 'Start Campaign Delete',
      options: { campaignURN },
    });
    nextStep();
  };

  const deleteCampaign = async () => {
    try {
      setIsDeleting(true);
      await API.deleteCampaign({ campaignURN });
      toast({ variant: 'success', title: `${campaignName} has been deleted` });
    } catch (error) {
      logger.error(`Delete Campaign error: ${error}`);
      toast({ variant: 'error', title: 'Failed to delete campaign' });
    } finally {
      setIsDeleting(false);
    }
    tracker.track({
      eventName: 'Delete Campaign',
      options: { campaignURN },
    });
    handleCloseModal();
    history.push('/settings/campaigns');
    // Refresh global info to remove deleted campaign
    actions.refreshGlobalInfo(GLOBAL_INFO_STATES.REFRESHING);
  };

  const ModalContent = [
    {
      Body: (
        <>
          This will permanently delete the Campaign{' '}
          <span className="font-weight-bold">{campaignName}</span>. This means
          you will no longer be able to send Editions to the subscribers of this
          Campaign.
        </>
      ),
      footerButton: (
        <Button variant="secondary" onClick={continueDelete} key={currentStep}>
          Continue
        </Button>
      ),
    },
    {
      Body: (
        <>
          Are you sure you want to delete the Campaign{' '}
          <span className="font-weight-bold">{campaignName}</span>?
        </>
      ),
      footerButton: (
        <Button
          variant="danger"
          onClick={deleteCampaign}
          key={currentStep}
          isDisabled={isDeleting}
        >
          Delete Campaign
        </Button>
      ),
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size="small">
      <Modal.Header>
        <Modal.Title>Delete {campaignName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex>
          <Text size="sm">{ModalContent[currentStep].Body}</Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
        {ModalContent[currentStep].footerButton}
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteCampaignModal;
