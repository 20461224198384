import { Col, Row } from 'react-bootstrap';

import { ReactComponent as EbxLogo } from 'assets/svg/ebx-logo.svg';

function EchoboxLogo() {
  return (
    <Row className="text-center mx-0">
      <Col>
        <EbxLogo className="ebx-logo" />
      </Col>
    </Row>
  );
}

export default EchoboxLogo;
