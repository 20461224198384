import { Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import * as datetime from 'common/datetime';

import { TEXT_CASES } from 'common/constants';
import { SERVER_UPLOAD_STATE } from 'common/enums';

function UploadState({ latestUpload }) {
  const getFormattedDate = timestamp =>
    datetime.getFormattedDateFromUnix({
      timestamp,
      textCase: TEXT_CASES.LOWER,
      useLongForm: true,
    });

  switch (latestUpload.uploadState) {
    case SERVER_UPLOAD_STATE.FAILED:
      return (
        <Text size="sm" color="gray.600">
          Upload failed {getFormattedDate(latestUpload.uploadTriggerUnixTime)}{' '}
          by {latestUpload.uploadedByEmail}
        </Text>
      );
    case SERVER_UPLOAD_STATE.COMPLETE:
      return (
        <Text size="sm" color="gray.600">
          Last uploaded{' '}
          {getFormattedDate(latestUpload.processingCompletedUnixTime)} by{' '}
          {latestUpload.uploadedByEmail}
        </Text>
      );
    default:
      return (
        <Text size="sm" color="gray.600">
          Last uploaded {getFormattedDate(latestUpload.uploadTriggerUnixTime)}{' '}
          by {latestUpload.uploadedByEmail}
        </Text>
      );
  }
}

UploadState.propTypes = {
  latestUpload: PropTypes.shape({
    uploadURN: PropTypes.string.isRequired,
    uploadState: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    uploadTriggerUnixTime: PropTypes.number,
    processingStartUnixTime: PropTypes.number,
    processingCompletedUnixTime: PropTypes.number,
    uploadedByEmail: PropTypes.string,
    uploadType: PropTypes.string.isRequired,
    errorLogURL: PropTypes.string,
  }).isRequired,
};

export default UploadState;
