import {
  ColumnWidthIcon,
  Divider,
  Flex,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface SplitViewDividerProps {
  onMouseDown: (e: React.MouseEvent) => void;
  onMouseUp: () => void;
  onTouchStart: (e: React.TouchEvent) => void;
}

function SplitViewDivider({
  onMouseDown,
  onMouseUp,
  onTouchStart,
}: SplitViewDividerProps) {
  return (
    <Flex
      height="100%"
      justifyContent="center"
      cursor="col-resize"
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onMouseUp}
    >
      <Divider orientation="vertical" mx={10} />
      <ColumnWidthIcon
        size={8}
        p={1}
        boxShadow="border"
        borderRadius="50%"
        position="fixed"
        top="50%"
        bgColor="gray.100"
        color="gray.500"
      />
    </Flex>
  );
}

export default SplitViewDivider;
