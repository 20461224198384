import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface DeletePropertiesUserArgs {
  emailAddress: string;
  propertyURN: string;
}

export default async function deletePropertiesUser({
  emailAddress,
  propertyURN,
}: DeletePropertiesUserArgs) {
  if (isUndefined(propertyURN)) {
    mandatory('propertyURN');
  }
  if (isUndefined(emailAddress)) {
    mandatory('emailAddress');
  }

  const guid = metrics.start('deletePropertiesUser');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/users`;
  const config: AxiosRequestConfig = {
    url,
    method: 'delete',
    params: { id: emailAddress },
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  try {
    const { data } = await axios(config);
    metrics.end('deletePropertiesUser', guid);
    return data;
  } catch (error) {
    metrics.fail('deletePropertiesUser', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/deletePropertiesUser',
            args: {
              emailAddress,
              propertyURN,
            },
          })
        : error;

    throw apiError;
  }
}
