import { useContext, useEffect, useRef, useState } from 'react';

import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as API from 'api/api';

import ExportEditionAnalyticsModal from 'components/common/ExportEditionAnalyticsModal';
import LoadTemplate from 'components/editor/marketing/LoadTemplate';
import SaveTemplate from 'components/editor/marketing/SaveTemplate';
import ExportHTML from 'components/editor/preview/ExportHTML';
import SendPreview from 'components/editor/preview/SendPreview';

import * as authentication from 'common/authentication';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/constants';
import { EDITION_APPROVAL_STATES } from 'common/enums';
import * as logger from 'common/logger';
import { Template } from 'common/types';

import useDebounce from 'hooks/useDebounce';

import { StoreContext } from 'store/store';

interface EmailSubjectProps {
  approvalState: EDITION_APPROVAL_STATES;
  editionURN: string;
  emailSubject: string;
  handleExport: () => void;
  handleUpdateSubject: (subject: string, callback: () => void) => void;
  handleUpdateTemplate: (template: Template) => void;
  handleSaveTemplate: () => void;
  isBusy: boolean;
  isEditable: boolean;
  isSent: boolean;
  templateDetails: Template;
}

function EmailSubject({
  approvalState,
  editionURN,
  emailSubject,
  handleExport,
  handleUpdateSubject,
  handleUpdateTemplate,
  handleSaveTemplate,
  isBusy,
  isEditable,
  isSent,
  templateDetails,
}: EmailSubjectProps) {
  const [newSubject, setNewSubject] = useState(emailSubject);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { state } = useContext(StoreContext);

  const defaultSendPreviewEmail =
    authentication.impersonatingByStaffEmail() ??
    state.globalInfo.user?.emailAddress;

  useEffect(() => {
    /* Restore focus after a brief delay to allow the component to render */
    if (inputRef.current) {
      setTimeout(() => {
        (inputRef.current as HTMLInputElement).focus();
      }, 250);
    }
  }, [emailSubject, newSubject]);

  const debouncedEmailSubject = useDebounce(newSubject, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    if (debouncedEmailSubject !== emailSubject && !isBusy) {
      logger.info(
        `WYSIWYG:EmailSubject:useEffect - saving debounced subject line ${debouncedEmailSubject}`
      );
      handleUpdateSubject(debouncedEmailSubject, () => {
        setNewSubject(debouncedEmailSubject);
      });
    }
  }, [debouncedEmailSubject, emailSubject, handleUpdateSubject, isBusy]);

  const handleBlur = () => {
    if (debouncedEmailSubject !== emailSubject && !isBusy) {
      logger.info(
        'WYSIWYG:EmailSubject:handleBlur - saving subject line on blur'
      );
      handleUpdateSubject(debouncedEmailSubject, () => {
        setNewSubject(debouncedEmailSubject);
      });
    }
  };

  const handleLoadTemplate = async (templateURN: string) => {
    const loadedTemplate = await API.getMarketingTemplate({
      templateURN,
    });
    handleUpdateTemplate({
      ...templateDetails,
      content: loadedTemplate.content,
    });

    // Force refresh as I haven't been able to get the editor state to update correctly
    window.location.reload();
  };

  return (
    <>
      {isOpen && (
        <ExportEditionAnalyticsModal
          editionURN={editionURN}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <FormControl display="flex" alignItems="center" gap={5}>
          <Heading variant="h3" flexShrink={0}>
            Subject Line
          </Heading>
          <Input
            w="540px"
            size="lg"
            fontSize="14px"
            placeholder="Subject"
            _placeholder={{
              fontSize: '14px',
              opacity: 1,
              color: 'gray.600',
            }}
            _hover={{
              boxShadow:
                '0px 1px 2px rgba(14, 30, 57, 0.12), 0px 4px 8px rgba(14, 30, 57, 0.08);',
            }}
            value={newSubject}
            onChange={event => setNewSubject(event.target.value)}
            disabled={isBusy || !isEditable}
            onBlur={handleBlur}
            ref={inputRef}
            name="editionSubject"
            maxLength={998}
          />
        </FormControl>
        <Flex gap="3">
          {isSent && (
            <Button variant="secondary" onClick={onOpen}>
              Export Analytics
            </Button>
          )}
          <LoadTemplate
            campaignURN={templateDetails.campaignURN}
            handleSave={handleLoadTemplate}
            isBusy={isBusy}
          />
          <SaveTemplate handleSave={handleSaveTemplate} isBusy={isBusy} />
          <ExportHTML handleExport={handleExport} />
          {defaultSendPreviewEmail && (
            <SendPreview
              approvalState={approvalState}
              editionURN={editionURN}
              emailAddress={defaultSendPreviewEmail}
              isDisabled={false}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}

export default EmailSubject;
