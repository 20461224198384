import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * deleteEditions
 * @param {{
 *  editionURN: string;
 * }}
 */

export default async function deleteEdition({
  editionURN = mandatory('editionURN'),
}) {
  const guid = metrics.start('deleteEdition');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/editions/${editionURN}`,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:deleteEdition /editions/${editionURN}`);

  try {
    const response = await axios(config);
    metrics.end('deleteEdition', guid);
    return response.data;
  } catch (error) {
    metrics.fail('deleteEdition', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/deleteEdition',
      args: { editionURN },
    });
    throw apiError;
  }
}
