/* eslint-disable react/no-this-in-sfc */
import { useEffect, useState } from 'react';

import {
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
  WatchTimeIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

import * as maths from 'common/maths';
import { Campaign, EngagementStatistics } from 'common/types';

import './SubscriberEngagement.css';

interface SubscriberEngagementDemoProps {
  campaign: Campaign;
}

function SubscriberEngagmentDemo({ campaign }: SubscriberEngagementDemoProps) {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options | null>(
    null
  );
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      configureChart({
        lowActivity: 0.3051,
        mediumActivity: 0.2132,
        highActivity: 0.4817,
        neverActivity: 0,
      });
    }, 1000);
  }, []);

  function configureChart(engagement: EngagementStatistics) {
    const card = document.getElementById('engagement-chart');

    function getEngagementDescription(level: string) {
      switch (level) {
        case 'Low':
          return 'Subscribers who rarely interact with your emails';
        case 'Medium':
          return 'Subscribers who occasionally interact with your emails';
        case 'High':
          return 'Subscribers who regularly interact with your emails';
        default:
          return '';
      }
    }
    setChartOptions({
      chart: {
        type: 'pie',
        width: (card?.clientWidth ?? 600) - 50,
        height: (card?.clientHeight ?? 400) - 50,
        style: {
          fontFamily: 'Roboto',
        },
      },
      colors: [
        'rgba(51, 113, 230, 0.75)',
        'rgba(51, 113, 230, 0.5)',
        'rgba(41, 98, 219, 1)',
      ],
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          borderWidth: 0,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter(): string {
              return `
                    <span style="position: relative; top: -10px">
                      <span style="color: #596A87; fontSize: 14px; fontWeight: 500">${this.point.name}</span>
                      <br />
                      <span style="color: #0E1E39; fontSize: 16px; fontWeight: 500">${this.y}%</span>
                    </span>
                  `;
            },
            useHTML: true,
            verticalAlign: 'top',
          },
          showInLegend: true,
        },
      },
      series: [
        {
          type: 'pie',
          colorByPoint: true,
          data: [
            {
              name: 'Medium',
              y: maths.round(engagement.mediumActivity * 100, 2),
            },
            {
              name: 'Low',
              y: maths.round(engagement.lowActivity * 100, 2),
            },
            {
              name: 'High',
              y: maths.round(engagement.highActivity * 100, 2),
            },
          ],
          innerSize: '55%',
          name: 'Percentage',
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        borderColor: 'transparent',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 0,
        shadow: false,
        useHTML: true,
        formatter: function describeEngagement() {
          return `<div class="highchart-tooltip">
            <h2 class="highchart-tooltip-text">
            ${getEngagementDescription(this.point.name)}</h2>
          </div>`;
        },
      },
    });
  }

  const displayStates = {
    loading: {
      bgColor: 'white',
      component: <Spinner size="md" color="gray.600" />,
    },
    data: {
      bgColor: 'white',
      component: (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      ),
    },
    noData: {
      bgColor: 'gray.100',
      component: <Text color="gray.600">No data</Text>,
    },
    collectingData: {
      bgColor: 'gray.100',
      component: (
        <Flex alignItems="center" gap={1}>
          <WatchTimeIcon size={5} color="gray.500" />
          <Text color="gray.600">Collecting data</Text>
        </Flex>
      ),
    },
  };

  function getEngagementStatisticsState() {
    if (isLoading) {
      return displayStates.loading;
    }
    return displayStates.data;
  }

  const engagementStatistics = getEngagementStatisticsState();

  return (
    <Card
      flexDir="column"
      alignItems="flex-start"
      flexBasis="50%"
      flexGrow={0}
      height="308px"
      id="engagement-chart"
      p={6}
      bgColor={engagementStatistics.bgColor}
    >
      <Heading variant="h5" color="gray.600">
        Subscriber Engagement
      </Heading>
      <Flex
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {engagementStatistics.component}
      </Flex>
    </Card>
  );
}

export default SubscriberEngagmentDemo;
