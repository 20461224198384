/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import { useEffect, /* useMemo, */ useState } from 'react';

import { VStack } from '@ebx-ui/ebx-ui-component-library-sdk';
import { positionZendeskRight } from 'common/zendesk';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import DomainVerificationIncomplete from 'components/campaigns/DomainVerificationIncomplete';
import Editions from 'components/campaigns/Editions';
import EditionsError from 'components/campaigns/editions/EditionsError';
import Header from 'components/campaigns/Header';
import HeaderError from 'components/campaigns/HeaderError';
import PropertySuspensionBanner from 'components/campaigns/PropertySuspensionBanner';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import { isNullOrUndefined } from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useLocalStorage from 'hooks/useLocalStorage';

import { changingCampaign, changingProperty } from 'state/changingEntity';

import {
  FRONTEND_UPLOAD_STATES,
  GLOBAL_INFO_STATES,
  LOCAL_STATE_STORAGE_KEY,
  PROPERTY_SETTING_TYPES,
} from 'common/constants';

import './View.css';

function View({
  draftEmails,
  errorMessage,
  isInitialised,
  loadEditionData,
  scheduledEmails,
  sentEmails,
}) {
  const handleError = useErrorHandler();

  const { id } = useParams();
  const globalInfo = useGlobalInfo();

  const isChangingCampaign = useRecoilValue(changingCampaign);
  const isChangingProperty = useRecoilValue(changingProperty);

  const campaignDetails = campaigns.getCampaign(id, globalInfo);

  const currentPropertyURN = properties.getCurrentPropertyURN(globalInfo);
  const currentPropertyBilling = properties.getPropertyBilling(
    currentPropertyURN,
    globalInfo
  );
  const isPropertyShutOff = properties.isPropertyShutOff(
    currentPropertyURN,
    globalInfo
  );

  /* This is required to know whether we should display a warning that emails won't send */
  const isDomainVerificationComplete =
    properties.getCurrentPropertySettingValue(
      globalInfo,
      PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
      'isComplete'
    );

  /* Campaign insights, to be loaded asynchronously on page load */
  const [campaignInsights, setCampaignInsights] = useState(null);

  /* Open/closed state of upload modal */
  const [isModalActive, setModalActive] = useState(false);

  /* Local file processing state, will be updated when a file is uploaded */
  const [localState, setLocalState] = useLocalStorage(
    LOCAL_STATE_STORAGE_KEY,
    FRONTEND_UPLOAD_STATES.INACTIVE
  );

  const [hasSeenWarning, setSeenWarning] = useState(false);

  useEffect(() => {
    positionZendeskRight();
  }, []);

  useEffect(() => {
    // Check the campaign details exist, to make sure this isn't a property with no campaigns
    if (campaignDetails !== null && !isPropertyShutOff) {
      try {
        logger.info('View:fetchInsights');
        const insightsSource = API.getCampaignInsightsSource({
          campaignURNs: campaignDetails.campaignURN,
          onMessage: event => {
            const data = JSON.parse(event.data);
            const insightData = data.campaignInsights.find(
              insight => insight.campaignURN === campaignDetails.campaignURN
            );
            setCampaignInsights(insightData);
          },
        });

        return () => {
          insightsSource.close();
        };
      } catch (error) {
        handleError(error);
      }
    }

    return () => {};
  }, []);

  const handleAcknowledge = () => {
    setSeenWarning(true);
  };

  const handleClose = () => {
    logger.info('View:handleClose');
    setModalActive(false);
  };

  const handleOpen = () => {
    logger.info('View:handleOpen');
    if (campaignInsights.activeSubscribers && !hasSeenWarning <= 0) {
      setLocalState(FRONTEND_UPLOAD_STATES.WARNING);
    } else {
      setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
    }

    setModalActive(true);
  };

  const handleReset = () => {
    logger.info('View:handleReset');
    setLocalState(null);
  };

  /* Render nothing if we are in the process of changing property or campaign */
  if (
    globalInfo.state === GLOBAL_INFO_STATES.SELECTING_PROPERTY ||
    globalInfo.state === GLOBAL_INFO_STATES.SELECTING_CAMPAIGN ||
    isChangingCampaign ||
    isChangingProperty
  ) {
    return null;
  }

  if (isNullOrUndefined(campaignDetails)) {
    return null;
  }

  const displaySuspensionBanner = !!currentPropertyBilling;
  const displayDomainVerificationBanner = !(
    isDomainVerificationComplete || isPropertyShutOff
  );

  return (
    <>
      {(displaySuspensionBanner || displayDomainVerificationBanner) && (
        <VStack pb={6}>
          {displaySuspensionBanner && (
            <PropertySuspensionBanner
              propertyBilling={currentPropertyBilling}
            />
          )}
          {displayDomainVerificationBanner && (
            <DomainVerificationIncomplete alertDescription="Echobox doesn't have permission to send emails on your behalf." />
          )}
        </VStack>
      )}
      <div className="ebx-background-colour">
        <ErrorBoundary FallbackComponent={HeaderError}>
          <Header
            campaignDetails={campaignDetails}
            campaignInsights={campaignInsights}
            draftEmails={draftEmails}
            handleClose={handleClose}
            handleReset={handleReset}
            isModalActive={isModalActive}
            isShutOff={isPropertyShutOff}
            localState={localState}
            scheduledEmails={scheduledEmails}
            sentEmails={sentEmails}
            setLocalState={setLocalState}
          />
        </ErrorBoundary>
      </div>
      {!isPropertyShutOff && (
        <ErrorBoundary FallbackComponent={EditionsError}>
          <div className="main-content ebx-background-colour">
            <div>
              <Editions
                campaignDetails={campaignDetails}
                campaignInsights={campaignInsights}
                draftEmails={draftEmails}
                errorMessage={errorMessage}
                handleAcknowledge={handleAcknowledge}
                handleOpen={handleOpen}
                isInitialised={isInitialised}
                loadEditionData={loadEditionData}
                scheduledEmails={scheduledEmails}
                sentEmails={sentEmails}
              />
            </div>
          </div>
        </ErrorBoundary>
      )}
    </>
  );
}

View.propTypes = {
  errorMessage: PropTypes.string,
  loadEditionData: PropTypes.func.isRequired,
  draftEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  scheduledEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  sentEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  isInitialised: PropTypes.bool.isRequired,
};

View.defaultProps = {
  errorMessage: null,
  draftEmails: [],
  scheduledEmails: [],
  sentEmails: [],
};

export default View;
