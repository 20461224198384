/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

export { doesImageResolve };

const doesImageResolve = async url =>
  new Promise(resolve => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
