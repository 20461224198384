/* eslint react-hooks/exhaustive-deps: "off" */

import { Box, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory, useParams } from 'react-router-dom';

import Loading from 'components/editor/Loading';
import EditorHeader from 'components/editor/marketing/EditorHeader';
import Preview from 'components/editor/preview/Preview';
import PreviewError from 'components/editor/preview/PreviewError';
import SenderDetails from 'components/editor/preview/SenderDetails';
import Header from 'components/header/Header';
import StatusPageNotifications from 'components/StatusPageNotifications';

import HTMLEditorBody from 'components/editor/html/EditorBody';
import HTMLEditorError from 'components/editor/html/EditorError';

import WYSIWYGEditorBody from 'components/editor/wysiwyg/EditorBody';
import WYSIWYGEditorError from 'components/editor/wysiwyg/EditorError';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as editions from 'common/editions';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as marketing from 'common/marketing';
import * as properties from 'common/properties';
import * as schedule from 'common/schedule';
import * as tracker from 'common/tracker';
import { positionZendeskLeft } from 'common/zendesk';
import useGlobalInfo from 'hooks/useGlobalInfo';

import {
  EDITION_FIELDS,
  MARKETING_EMAIL_TYPES,
  PROPERTY_SETTING_TYPES,
} from 'common/constants';
import * as datetime from 'common/datetime';
import {
  EDITION_APPROVAL_STATES,
  EDITION_STATE,
  PREVIEW_TYPES,
} from 'common/enums';

function EmailEditor() {
  const [emailDetails, setEmailDetails] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  const { id: editionURN } = useParams();

  const globalInfo = useGlobalInfo();
  const campaignDetails = campaigns.getCurrentCampaign(globalInfo);

  /* If the Campaign's domain isn't verified then we won't allow them to send an email */
  const isDomainVerificationComplete =
    properties.getCurrentPropertySettingValue(
      globalInfo,
      PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
      'isComplete'
    );

  const history = useHistory();
  const toast = useToast();

  const currentTime = datetime.getUnixTimestamp();

  const [editionDisplayState, setEditionDisplayState] = useState(
    editions.getEditionDisplayState(
      emailDetails?.approvalState,
      emailDetails?.editionSource,
      emailDetails?.lastUpdatedUnixTime,
      emailDetails?.editionState,
      emailDetails?.scheduledUnixTime,
      currentTime
    )
  );

  useEffect(() => {
    positionZendeskLeft();
  }, []);

  useEffect(() => {
    setEditionDisplayState(
      editions.getEditionDisplayState(
        emailDetails?.approvalState,
        emailDetails?.editionSource,
        emailDetails?.lastUpdatedUnixTime,
        emailDetails?.editionState,
        emailDetails?.scheduledUnixTime,
        datetime.getUnixTimestamp()
      )
    );
  }, [
    emailDetails?.approvalState,
    emailDetails?.editionSource,
    emailDetails?.lastUpdatedUnixTime,
    emailDetails?.editionState,
    emailDetails?.scheduledUnixTime,
    editionDisplayState,
  ]);

  useEffect(() => {
    const fetchEmailDetails = async () => {
      try {
        logger.info('EmailEditor:fetchEmailDetails');

        setLoading(true);
        const editionEmails = await API.getEditions({
          editionURNs: [editionURN],
          fieldList: EDITION_FIELDS.REVIEW,
        });
        const marketingEmail = editionEmails[0];
        setEmailDetails(marketingEmail);
        const marketingTemplate = await API.getMarketingTemplate({
          templateURN: marketingEmail.templateURN,
        });
        setTemplateDetails(marketingTemplate);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast({ variant: 'error', title: errors.getErrorMessage(error) });
      }
    };
    fetchEmailDetails();
  }, [editionURN]);

  const emailType = useMemo(
    () =>
      templateDetails
        ? marketing.deriveMarketingEmailType(templateDetails.content)
        : null,
    [templateDetails]
  );

  if (isLoading) {
    return (
      <div>
        <Header centered={true} />
        <div className="align-items-center curation__loading mx-2 d-flex justify-content-center">
          <div className="m-0">
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  const handleSaveEmail = async saveAsDraft => {
    if (emailDetails.editionSubject === '') {
      toast({ variant: 'error', title: 'Subject cannot be blank' });
      return;
    }
    if (templateDetails.content === '') {
      toast({ variant: 'error', title: 'Email body cannot be blank' });
      return;
    }

    logger.info(`EmailEditor:handleSaveEmail - saveAsDraft ${saveAsDraft}`);

    setSaving(true);

    const updatedTimes = schedule.updateTimes(
      emailDetails.scheduledUnixTime,
      emailDetails.sendingTimeslotSizeSeconds
    );

    try {
      await API.putEditions({
        editionURN: emailDetails.editionURN,
        editionDetails: editions.prepareForSave({
          ...emailDetails,
          approvalState: saveAsDraft
            ? EDITION_APPROVAL_STATES.ECHOBOX_APPROVED
            : EDITION_APPROVAL_STATES.CLIENT_APPROVED,
          editionState: EDITION_STATE.UNSENT,
          scheduledUnixTime: updatedTimes.scheduledUnixTime,
          sendingTimeslotSizeSeconds: updatedTimes.timeSlotSize,
        }),
      });
      setSaving(false);
      if (saveAsDraft) {
        toast({ variant: 'success', title: 'Draft Saved' });
      }

      history.push(`/campaigns/${campaignDetails.campaignURN}`);
    } catch (error) {
      setSaving(false);
      toast({ variant: 'error', title: errors.getErrorMessage(error) });
    }
  };

  const handleUpdateEmail = async email => {
    logger.info('EmailEditor:handleUpdateEmail');

    const updatedTimes = schedule.updateTimes(
      email.scheduledUnixTime,
      email.sendingTimeslotSizeSeconds
    );

    const updatedEmail = {
      ...email,
      scheduledUnixTime: updatedTimes.scheduledUnixTime,
      sendingTimeslotSizeSeconds: updatedTimes.timeSlotSize,
    };

    setEmailDetails(updatedEmail);
    if (updatedEmail.editionSubject !== '') {
      setSaving(true);
      try {
        await API.putEditions({
          editionURN: updatedEmail.editionURN,
          editionDetails: editions.prepareForSave(updatedEmail),
        });
        setSaving(false);
      } catch (error) {
        setSaving(false);
        toast({ variant: 'error', title: errors.getErrorMessage(error) });
      }
    }
  };

  const handleUpdateTemplate = async (updatedTemplate, callback) => {
    setTemplateDetails(updatedTemplate);
    if (updatedTemplate.content !== '') {
      setSaving(true);
      try {
        await API.putMarketingTemplates({
          campaignURN: updatedTemplate.campaignURN,
          templateURN: updatedTemplate.templateURN,
          content: updatedTemplate.content,
        });
        tracker.track({
          eventName: 'Edit Email HTML',
          trackingParams: {
            'Email ID': emailDetails.editionURN,
            'Email Type': 'Marketing Email',
          },
        });
        setSaving(false);
        if (typeof callback === 'function') {
          callback();
        }
      } catch (error) {
        setSaving(false);
        toast({ variant: 'error', title: errors.getErrorMessage(error) });
      }
    }
  };

  /* Extract design JSON from template and remove HTML part (if we haven't already done so) */
  if (
    emailType === MARKETING_EMAIL_TYPES.WYSIWYG &&
    !('design' in templateDetails)
  ) {
    templateDetails.design = marketing.extractDesign(templateDetails.content);
    delete templateDetails.content;
  }

  if (emailType === MARKETING_EMAIL_TYPES.HTML) {
    return (
      <ErrorBoundary FallbackComponent={HTMLEditorError}>
        <div className="f-container fixed-hf d-flex main-container">
          <div>
            <EditorHeader
              campaignDetails={campaignDetails}
              emailDetails={emailDetails}
              editionDisplayState={editionDisplayState}
              handleSaveEmail={handleSaveEmail}
              handleUpdateEmail={handleUpdateEmail}
              isSaving={isSaving}
              isDomainVerificationComplete={isDomainVerificationComplete}
            />
          </div>
          <div className="d-flex main flex-wrap flex-lg-nowrap curation__content bg-white">
            <div className="main curation__edit w-100">
              <StatusPageNotifications py={6} px={10} />
              <HTMLEditorBody
                emailDetails={emailDetails}
                editionDisplayState={editionDisplayState}
                handleUpdateEmail={handleUpdateEmail}
                handleUpdateTemplate={handleUpdateTemplate}
                isSaving={isSaving}
                templateDetails={templateDetails}
              />
            </div>
            <Box py={8} px={10} w="100%">
              <ErrorBoundary FallbackComponent={PreviewError}>
                <Preview
                  editionState={emailDetails?.editionState}
                  isLoadingPreview={false}
                  isSent={emailDetails?.editionState === EDITION_STATE.SENT}
                  previewSource={templateDetails.content}
                  previewTitle="Send preview"
                  previewType={PREVIEW_TYPES.EDITION}
                  campaignName={campaignDetails?.campaignName}
                  scheduledUnixTime={emailDetails?.scheduledUnixTime}
                  editionURN={emailDetails?.editionURN}
                  isToolbarDisabled={isSaving || isSaving}
                >
                  <SenderDetails />
                </Preview>
              </ErrorBoundary>
            </Box>
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  /* emailType === MARKETING_EMAIL_TYPES.WYSIWYG */
  return (
    <ErrorBoundary FallbackComponent={WYSIWYGEditorError}>
      <div className="f-container fixed-hf d-flex main-container">
        <div>
          <EditorHeader
            campaignDetails={campaignDetails}
            emailDetails={emailDetails}
            editionDisplayState={editionDisplayState}
            handleSaveEmail={handleSaveEmail}
            handleUpdateEmail={handleUpdateEmail}
            isSaving={isSaving}
            isDomainVerificationComplete={isDomainVerificationComplete}
          />
        </div>
        <div className="curation__content bg-white">
          <StatusPageNotifications py={6} px={10} />
          <WYSIWYGEditorBody
            emailDetails={emailDetails}
            editionDisplayState={editionDisplayState}
            handleUpdateEmail={handleUpdateEmail}
            handleUpdateTemplate={handleUpdateTemplate}
            isSaving={isSaving}
            templateDetails={templateDetails}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default EmailEditor;
