/* eslint react-hooks/exhaustive-deps: "off" */

import {
  ArrowDownIcon,
  ArrowUpIcon,
  Card,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useRef, useState } from 'react';

import * as API from 'api/api';
import * as datetime from 'common/datetime';
import * as i18n from 'common/i18n';
import * as maths from 'common/maths';
import { tdProps, thProps, trProps } from 'common/tables';
import * as tracker from 'common/tracker';
import * as urns from 'common/urns';
import useIsVisible from 'hooks/useIsVisible';

import { QUERY_CACHE_VALUES } from 'common/constants';
import {
  ARTICLE_ANALYTICS_CS_SORT_FIELDS,
  ARTICLE_ANALYTICS_NOS_SORT_FIELDS,
  ARTICLE_ANALYTICS_SORT_FIELDS,
  SORT_ORDERS,
} from 'common/enums';
import { useQuery } from 'react-query';

interface Article {
  clickRate: number;
  editionSubject: string;
  editionURN: string;
  imageURL: string;
  mediaURN: string;
  numClicks: number;
  numDeliveries: number;
  sendStartUnixTime: number;
  title: string;
}

interface Column {
  name?: keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS;
  label: string;
  isSortable: boolean;
  defaultSortOrder?: SORT_ORDERS;
  dataType?: 'string' | 'number';
  width: string;
}

const columns: Column[] = [
  {
    label: '',
    isSortable: false,
    width: '92px',
  },
  {
    name: ARTICLE_ANALYTICS_CS_SORT_FIELDS.title,
    label: 'Title',
    isSortable: true,
    defaultSortOrder: SORT_ORDERS.ASC,
    dataType: 'string',
    width: '60%',
  },
  {
    name: ARTICLE_ANALYTICS_CS_SORT_FIELDS.editionSubject,
    label: 'Edition subject',
    isSortable: true,
    defaultSortOrder: SORT_ORDERS.ASC,
    dataType: 'string',
    width: '40%',
  },
  {
    name: ARTICLE_ANALYTICS_CS_SORT_FIELDS.sendStartUnixTime,
    label: 'Send date',
    isSortable: true,
    defaultSortOrder: SORT_ORDERS.ASC,
    dataType: 'number',
    width: '144px',
  },
  {
    name: ARTICLE_ANALYTICS_NOS_SORT_FIELDS.numDeliveries,
    label: 'Deliveries',
    isSortable: true,
    defaultSortOrder: SORT_ORDERS.DESC,
    dataType: 'number',
    width: '112px',
  },
  {
    name: ARTICLE_ANALYTICS_NOS_SORT_FIELDS.numClicks,
    label: 'Total clicks',
    isSortable: true,
    defaultSortOrder: SORT_ORDERS.DESC,
    dataType: 'number',
    width: '112px',
  },
  {
    name: ARTICLE_ANALYTICS_NOS_SORT_FIELDS.clickRate,
    label: 'Click rate',
    isSortable: true,
    defaultSortOrder: SORT_ORDERS.DESC,
    dataType: 'number',
    width: '112px',
  },
];

const SORT_BY_KEY = 'articleAnalyticsSortBy';
const SORT_ORDER_KEY = 'articleAnalyticsSortOrder';

interface ArticleAnalyticsProps {
  campaignURN: string;
}

function ArticleAnalytics({ campaignURN }: ArticleAnalyticsProps) {
  const savedSortBy = localStorage.getItem(SORT_BY_KEY);
  const savedSortOrder = localStorage.getItem(SORT_ORDER_KEY);

  const [isSorting, setIsSorting] = useState(false);
  const [sortBy, setSortBy] = useState<
    keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS
  >(
    savedSortBy
      ? (savedSortBy as keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS)
      : ARTICLE_ANALYTICS_SORT_FIELDS.clickRate
  );
  const [sortOrder, setSortOrder] = useState(
    (savedSortOrder as SORT_ORDERS) ?? SORT_ORDERS.DESC
  );

  const browserLocale: string = i18n.getBrowserLocale() ?? 'en-GB';

  const headingRef = useRef(null);
  const isVisible = useIsVisible(headingRef);
  const [hasTrackedView, setHasTrackedView] = useState(false);

  useEffect(() => {
    if (isVisible && !hasTrackedView) {
      const column = getColumn(sortBy);
      tracker.track({
        eventName: 'View Article Table',
        trackingParams: {
          'Article Breakdown Sort Method': `${
            column?.label
          } ${sortOrder.toLowerCase()}`,
        },
      });
      setHasTrackedView(true);
    }
  }, [hasTrackedView, isVisible]);

  const toast = useToast();

  const {
    isLoading,
    data: sortedArticles,
    refetch,
  } = useQuery(
    ['article data', sortBy, sortOrder, campaignURN],
    async () =>
      API.getArticleAnalytics({
        campaignURN,
        sendStartUnixTime: datetime.NOW - datetime.DAYS(30),
        sendEndUnixTime: datetime.NOW,
        sortField: sortBy,
        sortOrder,
      }),
    {
      staleTime: QUERY_CACHE_VALUES.ANALYTICS.STALE_TIME,
      cacheTime: QUERY_CACHE_VALUES.ANALYTICS.CACHE_TIME,
      select: data => data.filter(article => article !== null) as Article[],
      onError: () =>
        toast({
          variant: 'error',
          title: 'Failed to get articles',
        }),
    }
  );

  useEffect(() => {
    const fetchSortedArticles = async () => {
      await refetch();
      setIsSorting(false);
    };
    if (isSorting) {
      fetchSortedArticles();
    }
  }, [sortBy, sortOrder]);

  const getColumn = (column: string) =>
    columns.find(col => col.name === column);

  const handleSort = (field: keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS) => {
    const sortByBefore = sortBy;
    const sortOrderBefore = sortOrder;
    let nextOrder;
    const column = getColumn(field);
    if (sortBy === field) {
      nextOrder =
        sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC;
      localStorage.setItem(SORT_ORDER_KEY, nextOrder);
      setSortOrder(nextOrder);
    } else {
      setSortBy(field);
      nextOrder = column?.defaultSortOrder ?? SORT_ORDERS.ASC;
      setSortOrder(nextOrder);
      localStorage.setItem(SORT_BY_KEY, field);
      localStorage.setItem(SORT_ORDER_KEY, nextOrder);
    }
    const columnBefore = getColumn(sortByBefore);
    setIsSorting(true);
    tracker.track({
      eventName: 'Sort Article Breakdown',
      trackingParams: {
        'Article Breakdown Sort Method': `${
          column?.label
        } ${nextOrder.toLowerCase()}`,
        'Article Breakdown Sort Method Before': `${
          columnBefore?.label
        } ${sortOrderBefore.toLowerCase()}`,
      },
    });
  };

  if ((!sortedArticles || sortedArticles.length === 0) && !isSorting) {
    return (
      <Flex direction="column" gap={4}>
        <Heading variant="h3" ref={headingRef}>
          Top links
        </Heading>
        {isLoading ? (
          <Center mt="5">
            <Spinner size="lg" color="gray.600" />
          </Center>
        ) : (
          <Card bgColor="gray.100">
            <Flex alignItems="center" justifyContent="center" width="100%">
              <Heading variant="h4" fontWeight="normal" color="gray.600">
                No data
              </Heading>
            </Flex>
          </Card>
        )}
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h3" ref={headingRef}>
        Top links
      </Heading>
      <TableContainer border="1px" borderColor="gray.300" borderRadius="md">
        <Table
          variant="simple"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: 0,
            tableLayout: 'fixed',
          }}
        >
          <Thead bg="gray.100">
            <Tr>
              {columns.map(column => (
                <Th
                  {...thProps}
                  cursor={column.isSortable ? 'pointer' : 'default'}
                  key={`column-${column.name}`}
                  onClick={
                    column.isSortable && column?.name
                      ? () =>
                          handleSort(
                            column.name as keyof typeof ARTICLE_ANALYTICS_SORT_FIELDS
                          )
                      : () => {}
                  }
                  width={column.width}
                >
                  <Flex gap={2}>
                    {column.label}
                    {column.name === sortBy &&
                      (sortOrder === 'ASC' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      ))}
                    {column.name !== sortBy && (
                      <ArrowUpIcon style={{ opacity: 0 }} />
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              sortedArticles &&
              sortedArticles.map((article: Article, index: number) => {
                const cellProps = { ...tdProps };
                if (index === sortedArticles.length - 1) {
                  cellProps.borderBottom = 'none';
                }
                return (
                  <Tr key={article.mediaURN + article.editionURN} {...trProps}>
                    <Td {...cellProps}>
                      <Tooltip
                        label={
                          <Image
                            src={article.imageURL}
                            maxHeight="240px"
                            borderRadius="base"
                          />
                        }
                        placement="top"
                        gutter={4}
                        maxWidth="unset"
                        p={0}
                      >
                        <LinkBox>
                          <LinkOverlay
                            href={urns.getURNValue(article.mediaURN)}
                            isExternal={true}
                          >
                            <Image
                              width="72px"
                              height="40px"
                              src={article.imageURL}
                              alt={article.title}
                              objectFit="cover"
                              borderRadius="6px"
                            />
                          </LinkOverlay>
                        </LinkBox>
                      </Tooltip>
                    </Td>
                    <Td {...cellProps}>
                      <Tooltip label={article.title} placement="top-start">
                        <Link
                          href={urns.getURNValue(article.mediaURN)}
                          isExternal={true}
                        >
                          <Text
                            size="sm"
                            _hover={{
                              color: 'primary.600',
                            }}
                            display="initial"
                          >
                            {article.title}
                          </Text>
                        </Link>
                      </Tooltip>
                    </Td>
                    <Td {...cellProps}>
                      <Tooltip
                        label={article.editionSubject}
                        placement="top-start"
                      >
                        <span style={{ display: 'initial' }}>
                          {article.editionSubject}
                        </span>
                      </Tooltip>
                    </Td>
                    <Td {...cellProps}>
                      {new Date(
                        article.sendStartUnixTime * 1000
                      ).toLocaleDateString(browserLocale, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </Td>
                    <Td {...cellProps}>
                      {article.numDeliveries.toLocaleString(browserLocale)}
                    </Td>
                    <Td {...cellProps}>
                      {article.numClicks.toLocaleString(browserLocale)}
                    </Td>
                    <Td {...cellProps}>
                      {maths.percent(article.clickRate, 2)}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {isLoading && (
        <Center mt={2.5}>
          <Spinner color="gray.600" />
        </Center>
      )}
    </Flex>
  );
}

export default ArticleAnalytics;
