/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { BRANDING_SETTINGS_SUB_PAGES } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import Loading from 'components/settings/Loading';
import useSettings from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Content, Sidebar } from '../../common';
import Fonts from './Fonts';
import Header from './Header';
import Links from './Links';
import Logo from './Logo';
import Styling from './Styling';

import './GeneralStyling.css';

export default function GeneralStyling() {
  const { urn } = useParams();
  const [settingsPanel, setSettingsPanel] = useState(
    BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.OPTIONS
  );

  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  useEffect(() => {
    // pub-sub
    const handleSave = async () => {
      logger.info('GeneralStyling:handleSave');

      try {
        await settings.saveCampaignSettings({
          campaignURN: urn,
        });

        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, urn]);

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Row className="settings-branding w-100 m-0">
      <Col xs={12} md={4} xl={3}>
        <Sidebar>
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS
              .OPTIONS && (
            <Links urn={urn} setSettingsPanel={setSettingsPanel} />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.LOGO && (
            <Logo setSettingsPanel={setSettingsPanel} settings={settings} />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS
              .STYLING && (
            <Styling setSettingsPanel={setSettingsPanel} settings={settings} />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS
              .HEADER && (
            <Header setSettingsPanel={setSettingsPanel} settings={settings} />
          )}
          {settingsPanel ===
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.FONTS && (
            <Fonts setSettingsPanel={setSettingsPanel} settings={settings} />
          )}
        </Sidebar>
      </Col>
      <Col xs={12} md={8} xl={9}>
        <Content>
          <PreviewWrapper
            campaignURN={urn}
            previewTypes={[
              PREVIEW_TYPES.EDITION_TEMPLATE,
              PREVIEW_TYPES.HOSTED_SUBS_PAGE,
            ]}
            settings={settings}
          />
        </Content>
      </Col>
    </Row>
  );
}
