import { Route, Switch } from 'react-router-dom';

import EmbeddedForm from 'components/settings/campaigns/EmbeddedForm';
import SignupForms from 'components/settings/campaigns/signup/SignupForms';
import SubscriptionPage from 'components/settings/campaigns/signup/SubscriptionPage';
import SettingsWrapper from 'components/settings/SettingsWrapper';

function Routing() {
  return (
    <Switch>
      <Route path="/settings/campaigns/:urn/sign-up-forms" exact={true}>
        <SettingsWrapper>
          <SignupForms />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/:urn/sign-up-forms/subscription-page">
        <SettingsWrapper>
          <SubscriptionPage />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/:urn/sign-up-forms/embedded-form">
        <SettingsWrapper>
          <EmbeddedForm />
        </SettingsWrapper>
      </Route>
    </Switch>
  );
}

export default Routing;
