/* eslint-disable react-hooks/exhaustive-deps */

import { Flex, Heading, Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';

import InspireMe from 'components/editor/curate/InspireMe';

import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import useDebounce from 'hooks/useDebounce';
import useLocalStorage from 'hooks/useLocalStorage';

import {
  DEFAULT_DEBOUNCE_DELAY,
  EDITION_APPROVAL_STATE_NAMES,
  SUBJECT_WARNING_DISPLAY_MAXIMUM,
  SUBJECT_WARNING_HISTORY_LOCAL_STORAGE_KEY,
} from 'common/constants';
import {
  SUBJECT_LINE_WARNINGS,
  SUBJECT_LINE_WARNINGS_DESCRIPTION,
  SUBJECT_LINE_WARNINGS_TEXT,
} from 'common/enums';
import { getSubjectWarningTypes } from 'common/subject';
import { isNullOrUndefined } from 'common/utility';

import './EmailSubject.css';

const getFirstDisplayableWarningType = ({
  subjectWarningHistory,
  subjectWarningTypes,
}) => {
  const displayedWarningType = subjectWarningTypes.find(subjectWarningType => {
    const warningTypeHistory = subjectWarningHistory?.[subjectWarningType];
    return !(
      warningTypeHistory?.numberOfTimesShown >
        SUBJECT_WARNING_DISPLAY_MAXIMUM ||
      warningTypeHistory?.neverShowAgain === true
    );
  });

  if (isNullOrUndefined(displayedWarningType)) {
    return SUBJECT_LINE_WARNINGS.NONE;
  }

  return displayedWarningType;
};

export default function EmailSubject({
  approvalState,
  emailSubject,
  emailSubjectInputRef,
  aiSubjectLines,
  hasSeenAISubjectLines,
  handleOpenAISubjectLines,
  defaultEmailSubject,
  handleSaveEditionSubject,
  isSavingEditionSubject,
  isEditionEditable,
  isApproving,
  setAISubjectLine,
  tempEmailSubject,
  setTempEmailSubject,
}) {
  const [editSubject, setEditSubject] = useState(false);
  const [hasFocus, setHasFocus] = useState(true);

  const [subjectWarningHistory, setSubjectWarningHistory] = useLocalStorage(
    SUBJECT_WARNING_HISTORY_LOCAL_STORAGE_KEY,
    {}
  );
  const [subjectWarningTypes, setSubjectWarningTypes] = useState(
    getSubjectWarningTypes(emailSubject)
  );

  useEffect(() => {
    const handleApprove = () => {
      // Want to build a new subject warning type history based on the current history and unresolved warnings
      // Reset number of times shown but if warning shown > MAX times never show it again
      const modifiedWarningTypeHistories = subjectWarningTypes
        .map(warningType => ({
          [warningType]: {
            numberOfTimesShown: 0,
            neverShowAgain:
              subjectWarningHistory?.[warningType]?.neverShowAgain === true ||
              (Number(
                subjectWarningHistory?.[warningType]?.numberOfTimesShown
              ) || 0) > SUBJECT_WARNING_DISPLAY_MAXIMUM,
          },
        }))
        .reduce(
          (currentHistory, combinedHistory) =>
            Object.assign(currentHistory, combinedHistory),
          {}
        );

      setSubjectWarningHistory({
        ...subjectWarningHistory,
        ...modifiedWarningTypeHistories,
      });
    };
    if (isApproving) {
      handleApprove();
    }
  }, [
    isApproving,
    setSubjectWarningHistory,
    subjectWarningHistory,
    subjectWarningTypes,
  ]);

  const debouncedEmailSubject = useDebounce(
    tempEmailSubject,
    DEFAULT_DEBOUNCE_DELAY
  );

  useEffect(() => {
    if (debouncedEmailSubject !== emailSubject) {
      logger.info(
        `EmailSubject:useEffect - saving debounced subject line ${debouncedEmailSubject}`
      );
      handleSaveEditionSubject(debouncedEmailSubject, () => {
        if (hasFocus) {
          setTimeout(() => {
            emailSubjectInputRef.current.focus();
          }, 0);
        }
        setEditSubject(false);
      });
    }
  }, [debouncedEmailSubject, emailSubject]);

  useEffect(() => {
    if (editSubject) emailSubjectInputRef.current.focus();
    setHasFocus(true);
  }, [editSubject, emailSubjectInputRef]);

  const handleBlur = () => {
    setHasFocus(false);
  };

  const handleChange = () => {
    setEditSubject(true);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleSaveAISubjectLine = aiSubjectLine => {
    setTempEmailSubject(aiSubjectLine);
    setAISubjectLine(aiSubjectLine);
  };

  const handleSubjectChange = event => {
    setTempEmailSubject(event.target.value);
    setSubjectWarningTypes(getSubjectWarningTypes(event.target.value));
  };

  const displayedWarningType = getFirstDisplayableWarningType({
    subjectWarningHistory,
    subjectWarningTypes,
  });

  // On showing the tooltip warning increment the number of times we have displayed the warning
  const handleShowSubjectWarning = () => {
    const warningTypeHistory = subjectWarningHistory?.[displayedWarningType];
    if (isNullOrUndefined(warningTypeHistory)) {
      setSubjectWarningHistory({
        ...subjectWarningHistory,
        [displayedWarningType]: {
          numberOfTimesShown: 1,
          neverShowAgain: false,
        },
      });
    } else {
      setSubjectWarningHistory({
        ...subjectWarningHistory,
        [displayedWarningType]: {
          ...warningTypeHistory,
          numberOfTimesShown:
            (Number(warningTypeHistory?.numberOfTimesShown) || 0) + 1,
        },
      });
    }
    // Mixpanel tracking
    tracker.track({
      eventName: 'Display Subject Warning Tooltip',
      trackingParams: {
        'Edition State Before': EDITION_APPROVAL_STATE_NAMES[approvalState],
        'Email Type': 'Newsletter',
        'Warning Type': SUBJECT_LINE_WARNINGS_DESCRIPTION[displayedWarningType],
      },
    });
  };

  return (
    <Flex flexDir="column" gap={3}>
      <Heading variant="h3">Subject Line</Heading>
      <div
        className={`ebx-email-subject w-100 d-flex ${
          isEditionEditable && 'ebx-email-subject-active'
        }`}
      >
        {isEditionEditable ? (
          <Tooltip
            label={SUBJECT_LINE_WARNINGS_TEXT[displayedWarningType]}
            placement="top"
            isDisabled={
              !(
                SUBJECT_LINE_WARNINGS_TEXT[displayedWarningType] &&
                defaultEmailSubject !== emailSubject
              )
            }
            onOpen={handleShowSubjectWarning}
          >
            <FormControl
              name="editionSubject"
              className="ebx-static-input ebx-body-1"
              type="text"
              disabled={isSavingEditionSubject}
              placeholder={defaultEmailSubject}
              maxLength="998"
              value={tempEmailSubject}
              onBlur={handleBlur}
              onChange={handleSubjectChange}
              onFocus={handleFocus}
              ref={emailSubjectInputRef}
              onClick={handleChange}
              onKeyDown={handleChange}
              style={{ textOverflow: 'ellipsis' }}
            />
          </Tooltip>
        ) : (
          <div className="ebx-body-1 px-3 py-2 d-flex align-items-center stay-within-parent">
            <span className="email-subject-text ebx-disabled-text">
              {emailSubject}
            </span>
          </div>
        )}
      </div>
      {aiSubjectLines?.length > 0 && (
        <InspireMe
          options={aiSubjectLines}
          setAISubjectLine={handleSaveAISubjectLine}
          hasSeenAISubjectLines={hasSeenAISubjectLines}
          handleOpenAISubjectLines={handleOpenAISubjectLines}
        />
      )}
    </Flex>
  );
}

EmailSubject.propTypes = {
  approvalState: PropTypes.string.isRequired,
  emailSubject: PropTypes.string.isRequired,
  emailSubjectInputRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  aiSubjectLines: PropTypes.arrayOf(PropTypes.string),
  setAISubjectLine: PropTypes.func.isRequired,
  hasSeenAISubjectLines: PropTypes.bool.isRequired,
  handleOpenAISubjectLines: PropTypes.func.isRequired,
  defaultEmailSubject: PropTypes.string,
  handleSaveEditionSubject: PropTypes.func.isRequired,
  isSavingEditionSubject: PropTypes.bool.isRequired,
  isEditionEditable: PropTypes.bool.isRequired,
  isApproving: PropTypes.bool.isRequired,
  tempEmailSubject: PropTypes.string.isRequired,
  setTempEmailSubject: PropTypes.func.isRequired,
};

EmailSubject.defaultProps = {
  emailSubjectInputRef: null,
  aiSubjectLines: [],
  defaultEmailSubject: '',
};
