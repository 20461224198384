import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { ReactComponent as CheckIcon } from 'assets/svg/sign-check-14.svg';
import { ReactComponent as UncheckIcon } from 'assets/svg/sign-uncheck-14.svg';

function Icon({ isRuleValid }) {
  return isRuleValid ? <CheckIcon /> : <UncheckIcon />;
}

Icon.propTypes = {
  isRuleValid: PropTypes.bool.isRequired,
};

function PasswordRules({ passwordRules }) {
  const {
    containsTenCharacters,
    containsLowerCase,
    containsUpperCase,
    containsNumberCharacter,
  } = passwordRules;

  return (
    <Row className="ml-2 label-text">
      <Col>
        <Row className="d-flex align-items-center">
          <Col xs={1}>
            <Icon isRuleValid={containsTenCharacters} />
          </Col>
          <Col xs={10} className="pl-1 mb-1">
            At least 10 characters long
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={1}>
            <Icon isRuleValid={containsLowerCase} />
          </Col>
          <Col xs={10} className="pl-1 mb-1">
            One lowercase character
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={1}>
            <Icon isRuleValid={containsUpperCase} />
          </Col>
          <Col xs={10} className="pl-1 mb-1">
            One uppercase character
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={1}>
            <Icon isRuleValid={containsNumberCharacter} />
          </Col>
          <Col xs={10} className="pl-1 mb-1">
            One number character
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PasswordRules.propTypes = {
  passwordRules: PropTypes.shape({
    containsTenCharacters: PropTypes.bool.isRequired,
    containsLowerCase: PropTypes.bool.isRequired,
    containsUpperCase: PropTypes.bool.isRequired,
    containsNumberCharacter: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PasswordRules;
