/* eslint react/no-danger: "off" */
import { useState } from 'react';

import {
  Card,
  DragDropIcon,
  Flex,
  LightingHighlightIcon,
  Tag,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as sanitise from 'common/sanitise';
import { MediaDataSources } from 'common/types';

import Thumbnail from 'components/common/Thumbnail';
import EditArticle from 'components/editor/curate/EditArticle';

interface ArticleProps {
  articleURL?: string;
  title?: string;
  description?: string;
  handleSaveArticleDetails: (
    sectionURN: string,
    index: number,
    finalURL: string,
    title: string,
    description: string,
    imageURL: string,
    mediaURN: string,
    callback: () => void
  ) => void;
  handleDeleteArticle: (sectionURN: string, index: number) => void;
  imageURL?: string;
  index: number;
  isSavingArticleDetails: boolean;
  sectionURN: string;
  dataSources: MediaDataSources;
  showOptimalTooltip: boolean;
}

function Article({
  articleURL = '',
  title = '',
  description = '',
  handleSaveArticleDetails,
  handleDeleteArticle,
  imageURL = '',
  index,
  isSavingArticleDetails,
  sectionURN,
  dataSources,
  showOptimalTooltip,
}: ArticleProps) {
  const [isShowModal, setIsShowModal] = useState(false);

  // On hover fade effect
  const fadeEffect = {
    content: `''`,
    width: '32px',
    height: '100%',
    position: 'absolute',
    right: '0',
    top: '0',
    background: 'linear-gradient(to right, transparent, white)',
  };

  return (
    <Card
      px={2}
      borderRadius="4px"
      alignItems="flex-start"
      gap={2}
      _hover={{
        boxShadow:
          '0px 1px 2px rgba(14, 30, 57, 0.12), 0px 4px 8px rgba(14, 30, 57, 0.08);',
        cursor: 'grab',
        outline: '1px solid #e3eaf3',
      }}
      className="article"
    >
      <DragDropIcon size={5} color="gray.500" />
      <Thumbnail
        image={imageURL}
        height="48px"
        width="85px"
        alt="Article image"
      />
      <Card.CardContent ml={2} gap={2} flexGrow={1} minW={0}>
        <Flex gap={2} alignItems="center">
          <Card.CardTitle
            flexGrow={1}
            pr={3}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            position="relative"
            sx={{
              '.article:hover &': {
                textOverflow: 'clip',
                _before: fadeEffect,
              },
            }}
          >
            {title}
          </Card.CardTitle>
          {showOptimalTooltip && (
            <Tooltip
              label="Article picked based on estimated performance"
              placement="top"
            >
              <Tag color="blue" icon={<LightingHighlightIcon />} />
            </Tooltip>
          )}
          {articleURL !== '' && (
            <EditArticle
              articleURL={articleURL}
              description={description}
              handleSaveArticleDetails={handleSaveArticleDetails}
              handleDeleteArticle={handleDeleteArticle}
              imageURL={imageURL}
              index={index}
              isSavingArticleDetails={isSavingArticleDetails}
              sectionURN={sectionURN}
              dataSources={dataSources}
              title={title}
              isShowModal={isShowModal}
              hideModal={() => setIsShowModal(false)}
              showModal={() => setIsShowModal(true)}
            />
          )}
        </Flex>
        <Card.CardDescription
          color="gray.900"
          dangerouslySetInnerHTML={{
            __html: sanitise.safeHTML(description),
          }}
        />
      </Card.CardContent>
    </Card>
  );
}

export default Article;
