/* eslint @typescript-eslint/no-use-before-define: "off" */

import sanitizeHtml from 'sanitize-html';

export { noHTML, safeHTML };

// Strips string from any html tags
function noHTML(html: string | null) {
  if (html === null) return null;

  // Given we don't want any html, we don't want to pass escaped characters into our sanitisation
  const noEscapedCharacters = htmlDecode(html);

  const config = {
    allowedTags: [],
    allowedSchemes: ['http', 'https', 'mailto'],
  };

  const sanitisedHTML = sanitizeHtml(noEscapedCharacters, config);

  // If we strip all html we don't want to include escaped characters
  return htmlDecode(sanitisedHTML);
}

// Unescapes HTML characters when you want to have regular text
function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent as string;
}

// Strips any potentially harmful tags
function safeHTML(html: string) {
  const config = {
    allowedTags: ['a', 'em', 'li', 'ol', 'p', 'strong', 'ul'],
    allowedAttributes: {
      a: ['href', { name: 'target', values: ['_blank'] }],
      em: [],
      li: [],
      ol: [],
      p: ['style'],
      strong: [],
      ul: [],
    },
    allowedStyles: {
      p: {
        'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      },
    },
    allowedSchemes: ['http', 'https', 'mailto'],
  };

  const sanitisedHTML = sanitizeHtml(html, config);

  return sanitisedHTML;
}
