import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS, getRequestHeaders } from 'api/settings';

export default async function createUploads({
  uploadURL = mandatory('uploadURL'),
  fileContent = mandatory('fileContent'),
  onProgress = mandatory('onProgress'),
  httpMethod = mandatory('httpMethod'),
  contentType = mandatory('contentType'),
}) {
  const methodUploads = `${httpMethod.toLowerCase()}Uploads`;
  const guid = metrics.start(methodUploads);

  const config = {
    url: uploadURL,
    method: httpMethod,
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': contentType,
    }),
    data: fileContent,
    onUploadProgress: event => {
      if (event.lengthComputable) {
        const loaded = event.loaded.toString();
        const total = event.total.toString();
        const percent = Math.floor((event.loaded / event.total) * 10000) / 100;
        onProgress({ loaded, total, percent });
      }
    },
  };
  logger.info(`API:${methodUploads} /${uploadURL}`);

  try {
    const response = await axios(config);
    metrics.end(`${methodUploads}`, guid);
    return response.data;
  } catch (error) {
    metrics.fail(`${methodUploads}`, guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: `api/${methodUploads}`,
      args: { uploadURL },
    });
    throw apiError;
  }
}
