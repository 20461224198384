import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  Button,
  Input,
  Modal,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

function SaveTemplateModal({ handleClose, handleSave, showModal }) {
  const [isSaving, setSaving] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const toast = useToast();

  const saveTemplate = async () => {
    try {
      setSaving(true);
      await handleSave(templateName);
      handleClose();
      toast({ variant: 'success', title: 'Successfully saved template' });
    } catch (error) {
      toast({ variant: 'error', title: 'Unable to save selected template' });
    } finally {
      setSaving(false);
    }
  };

  const handleChange = event => {
    setTemplateName(event.target.value);
  };

  return (
    <Modal isOpen={showModal} onClose={handleClose} size="small">
      <Modal.Header>
        <Modal.Title>Save Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VStack align="left">
          <Text fontWeight="medium" size="sm">
            Template Name
          </Text>
          <Input onChange={handleChange} />
        </VStack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={saveTemplate}
          isDisabled={templateName === '' || isSaving}
        >
          {isSaving ? <Spinner /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SaveTemplateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default SaveTemplateModal;
