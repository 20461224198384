import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/svg/arrow-up.svg';

import './common.css';

function Title({ className, ...rest }) {
  return (
    <div
      className={`settings-title ebx-hr-text-colour ${className}`}
      {...rest}
    />
  );
}

function Description({ className, ...rest }) {
  return (
    <div
      className={`settings-description ebx-hr-text-colour  ${className}`}
      {...rest}
    />
  );
}

function Divider() {
  return <hr className="settings-divider" />;
}

function Container({ className = null, ...rest }) {
  return <div className={`settings-container ${className}`} {...rest} />;
}

function Dropdown({ components, ...rest }) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <Select
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      components={{
        ...components,
        DropdownIndicator: menuIsOpen ? ArrowUpIcon : ArrowDownIcon,
      }}
      classNamePrefix="ebx-select"
      {...rest}
    />
  );
}

function RadioLabel(props) {
  return <div className="ebx-title-colour settings-radio-label" {...props} />;
}

function Input({ className, ...rest }) {
  return (
    <Form.Control
      className={`ebx-input ebx-settings-input ebx-title-colour ${className}`}
      type="text"
      {...rest}
    />
  );
}

function LabelInput({
  inputLabel,
  inputType,
  inputValue,
  handleInputChange,
  rowAmount,
}) {
  return (
    <div className="mb-4">
      <FormLabel className="ebx-h3 mb-1">{inputLabel}</FormLabel>
      <FormControl
        as={inputType}
        rows={rowAmount}
        required
        className="ebx-input ebx-body-1"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        autoComplete="off"
      />
    </div>
  );
}

function DragAndDrop({ dropzone }) {
  const { getRootProps, getInputProps } = dropzone;

  return (
    <div {...getRootProps()} className="settings-drag-zone select-drag-zone">
      <input {...getInputProps()} />
      <p className="ebx-h3 ebx-title-colour">Drag and drop image</p>
      <Button className="ebx-btn-secondary ebx-btn-md ebx-h3">
        Select image
      </Button>
    </div>
  );
}

function Sidebar({ className, ...rest }) {
  return (
    <div
      className={`${className} settings-container settings-sidebar`}
      {...rest}
    />
  );
}

function Content({ className, ...rest }) {
  return (
    <div
      className={`${className} settings-container settings-content`}
      {...rest}
    />
  );
}

function BackLink({ linkURL, linkText }) {
  return (
    <Flex alignItems="center">
      {linkURL ? (
        <Link to={linkURL}>
          <ArrowBackIcon className="settings-back-arrow mr-1" />
          <span className="ebx-h3">{linkText}</span>
        </Link>
      ) : (
        <>
          <ArrowBackIcon className="settings-back-arrow mr-1" />
          <span className="ebx-h3">{linkText}</span>
        </>
      )}
    </Flex>
  );
}

DragAndDrop.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dropzone: PropTypes.object.isRequired,
};

Input.defaultProps = {
  className: '',
};

Input.propTypes = {
  className: PropTypes.string,
};

LabelInput.defaultProps = {
  inputLabel: '',
  inputType: 'input',
  inputValue: '',
  rowAmount: 3,
};

LabelInput.propTypes = {
  inputLabel: PropTypes.string,
  inputType: PropTypes.string,
  inputValue: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  rowAmount: PropTypes.number,
};

Title.defaultProps = {
  className: '',
};

Container.defaultProps = {
  className: '',
};

Title.propTypes = {
  className: PropTypes.string,
};

Container.propTypes = {
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  components: {},
};

Dropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object,
};

Description.defaultProps = {
  className: '',
};

Description.propTypes = {
  className: PropTypes.string,
};

Sidebar.propTypes = {
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  className: '',
};

Content.propTypes = {
  className: PropTypes.string,
};
Content.defaultProps = {
  className: '',
};

BackLink.propTypes = {
  linkURL: PropTypes.string,
  linkText: PropTypes.string,
};
BackLink.defaultProps = {
  linkURL: '',
  linkText: '',
};

export {
  Title,
  Description,
  Divider,
  Container,
  Dropdown,
  RadioLabel,
  Input,
  LabelInput,
  DragAndDrop,
  Sidebar,
  Content,
  BackLink,
};
