import { Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import UploadState from 'components/campaigns/subscribers/add/UploadState';
import UploadStatistics from 'components/campaigns/subscribers/add/UploadStatistics';

function UploadDetails({ latestUpload }) {
  if (!latestUpload || !latestUpload.uploadTriggerUnixTime) {
    return null;
  }

  if (latestUpload.errorLogURL !== '') {
    return (
      <Flex gap={2} alignItems="center">
        <Heading variant="h5" size="sm">
          Upload
        </Heading>
        <UploadState latestUpload={latestUpload} />
        <UploadStatistics latestUpload={latestUpload} />
      </Flex>
    );
  }

  return (
    <Flex gap={2} alignItems="center">
      <Heading variant="h5" size="sm">
        Uploaded successfully
      </Heading>
    </Flex>
  );
}

UploadDetails.propTypes = {
  latestUpload: PropTypes.shape({
    uploadURN: PropTypes.string.isRequired,
    uploadState: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    uploadTriggerUnixTime: PropTypes.number,
    processingStartUnixTime: PropTypes.number,
    processingCompletedUnixTime: PropTypes.number,
    uploadByEmail: PropTypes.string,
    uploadType: PropTypes.string.isRequired,
    errorLogURL: PropTypes.string,
  }),
};

UploadDetails.defaultProps = {
  latestUpload: null,
};

export default UploadDetails;
