import { Hub } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import CodeInput from 'components/ui/CodeInput';
import SignInErrors from 'components/users/SignInErrors';
import SignInTemplate from 'components/users/SignInTemplate';

import * as authentication from 'common/authentication';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';

import verifymfa from 'services/verifymfa';

import { HUB_CHANNELS, HUB_EVENTS, ROUTE_REDIRECTIONS } from 'common/constants';

// import GlobalInfoContext from 'context/GlobalInfoContext';

const StyledCodeInput = styled(CodeInput)`
  display: flex !important;
  justify-content: space-between;
`;

function VerifyMFA() {
  const history = useHistory();

  const [isVerifying, setIsVerifying] = useState(false);
  const [isSignedIn, setSignedIn] = useState(false);

  const [userInput, setUserInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const { global } = useContext(GlobalInfoContext);
  // isMounted is used to keep track of when the component is mounted. This helps avoid setting state on unmounted components
  const isMounted = useRef(true);
  const verify = useRef();

  /**
   * User details are stored in window.AWS between signing in and entering the MFA code
   * If the page is refreshed manually, these details will be lost, so redirect back
   * to the sign in page to force the user to sign in again, otherwise this page will never work
   */

  const user = authentication.getChallengeUser();
  if (!user) {
    history.push(ROUTE_REDIRECTIONS.SIGNIN);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage('');
    setIsVerifying(true);
    try {
      await verifymfa({
        user,
        code: userInput,
        mfaType: user?.challengeName,
      });
      const handleSignInComplete = async data => {
        const { event, globalInfo } = data.payload;
        if (event === HUB_EVENTS.GLOBAL_INFO.LOADED) {
          await tracker.setUserDetails(globalInfo);
          tracker.track({ eventName: 'Login' });
          Hub.remove(HUB_CHANNELS.GLOBAL_INFO, handleSignInComplete);
          setSignedIn(true);
        }
      };
      Hub.listen(HUB_CHANNELS.GLOBAL_INFO, handleSignInComplete);
    } catch (error) {
      logger.info('VerifyMFA:verifyCodeFailure');
      setErrorMessage(errors.getErrorMessage(errors.determineError(error)));
      if (isMounted.current) setIsVerifying(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (userInput.length === 6) verify.current.click();
  }, [userInput]);

  useEffect(() => {
    if (isSignedIn) {
      window.location.href = ROUTE_REDIRECTIONS.HOME;
    }
  }, [isSignedIn]);

  return (
    <SignInTemplate title="Two-factor authentication">
      <div className="signin_box">
        <p>
          Open the authentication app on your device to view your authentication
          code and verify your identity
        </p>
        <form>
          <StyledCodeInput
            type="number"
            fields={6}
            isValid={!errorMessage}
            value={userInput}
            onChange={value => setUserInput(value)}
          />
          <Button
            className="w-100 ebx-btn-primary ebx-btn-lg ebx-h3 btn btn-primary mt-4 mb-1"
            variant="dark"
            id="mfa-verify"
            data-cy-action="verify"
            loading
            loadingEl="Verifying"
            type="submit"
            onClick={handleSubmit}
            ref={verify}
            size="sm"
            disabled={isVerifying}
          >
            Verify
          </Button>
          <SignInErrors errorMessage={errorMessage} />
        </form>
      </div>
    </SignInTemplate>
  );
}

export default VerifyMFA;
