/* eslint react/forbid-prop-types: "off" */

import PropTypes from 'prop-types';
import { createContext, useMemo, useReducer } from 'react';

import { useActions } from 'store/actions';
import { initialState } from 'store/initial';
import { reducer } from 'store/reducer';

/**
 * @typedef Permission
 * @property { import('common/enums').PROPERTY_PERMISSION_TYPES } permissionType
 * @property { string } propertyURN
 * @property { string | undefined } campaignURN
 */

/**
 * @typedef GlobalInfo
 * @property {{ propertyURN: string; campaignURN: string; } | undefined} current
 * @property {{ any } | undefined} properties
 * @property {{ name: string; emailAddress: string; userType: import('common/enums').USER_TYPES; permissions: Permission[] } | undefined} user
 * @property { string } state
 */

/**
 * @typedef StoreContextType
 * @property {{ globalInfo: GlobalInfo; statusPageNotifications: {CORE: string, NEWSLETTERS: string}; }} state
 * @property { import('react').DispatchWithoutAction } dispatch
 * @property { ReturnType<useActions> } actions
 */

/**
 * @type {import('react').Context<StoreContextType>}
 */
const StoreContext = createContext({ state: initialState });

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  const context = useMemo(
    () => ({ state, dispatch, actions }),
    [actions, state]
  );
  return (
    <StoreContext.Provider value={context}>{children}</StoreContext.Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.any,
};

StoreProvider.defaultProps = {
  children: null,
};

export { StoreContext, StoreProvider };
