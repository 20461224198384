/* eslint no-use-before-define: "off" */

import * as cron from 'common/cron';
import * as datetime from 'common/datetime';
import * as properties from 'common/properties';
import * as schedule from 'common/schedule';
import * as utility from 'common/utility';

import { CAMPAIGNS_USING_HOSTED_SUBSCRIPTIONS } from 'common/config';
import {
  CAMPAIGN_SETTING_TYPES,
  CAMPAIGN_STATES,
  DAY_NAME_MAP,
  EDITION_SUBJECT_TYPE_DESCRIPTIONS,
  EMAIL_PREVIEW_TYPE_DESCRIPTIONS,
} from 'common/constants';
import { SECTION_TYPES } from 'common/enums';
import { mandatory } from './validation';

const SECS_IN_HOUR = 3600;

export {
  getCampaign,
  getCampaignBrandingDetails,
  getCampaignFrequency,
  getCampaignName,
  getCampaignPropertyURN,
  getCampaignPropertyMap,
  getCampaignTrackingDetails,
  getCurrentCampaign,
  getCurrentCampaignSettings,
  getCurrentCampaignSettingsType,
  getCurrentCampaignSettingValue,
  getCurrentCampaignURN,
  getCurrentCampaignName,
  getCurrentPropertyCampaigns,
  getPropertyCampaigns,
  prepareForSave,
  setCurrentCampaignURN,
  shouldShowAddSubscribers,
  sortCampaignList,
};

/**
 * getCampaign
 * @param {string} campaignURN
 * @param {object} globalInfo
 * @returns {import('common/config').Campaign}
 */
const getCampaign = (campaignURN, globalInfo) => {
  // TODO try to make this a bit less of a mess
  let campaign = null;
  Object.keys(globalInfo?.properties).forEach(propertyURN => {
    if (globalInfo.properties[propertyURN]?.campaigns) {
      Object.keys(globalInfo.properties[propertyURN]?.campaigns).forEach(
        campId => {
          if (campId === campaignURN) {
            campaign =
              globalInfo.properties[propertyURN]?.campaigns[campaignURN];
          }
        }
      );
    }
  });
  return campaign;
};

const getCampaignBrandingDetails = ({
  campaignSettings = mandatory('campaignSettings'),
  suffix = '',
} = {}) => {
  const campaignBrandingDetails = {
    'Accent Colour':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]?.accentColour ??
      '',
    'Title Font':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]?.titleFont ??
      '',
    'Body Font':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]?.bodyFont ?? '',
    'Logo (Branding)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]?.logoImageURL ??
      'NULL',
    'Header Image (Branding)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]
        ?.headerImageURL ?? 'NULL',
    'Header Image link (Branding)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]
        ?.headerLinkURL ?? 'NULL',
    'Read more text (Edition)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]
        ?.callToActionText ?? 'NULL',
    'Unsubscribe text (Edition)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING]
        ?.unsubscribeLinkText ?? 'NULL',
    'Privacy Policy text (Edition)': campaignSettings[
      CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY
    ]?.isEnabled
      ? campaignSettings[CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY]
          ?.privacyPolicyLinkText
      : '',
    'Privacy Policy link (Edition)': campaignSettings[
      CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY
    ]?.isEnabled
      ? campaignSettings[CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY]?.privacyPolicy
      : '',
    'Custom text link (Edition)': campaignSettings[
      CAMPAIGN_SETTING_TYPES.FOOTER_TEXT
    ]?.isEnabled
      ? campaignSettings[CAMPAIGN_SETTING_TYPES.FOOTER_TEXT]?.footerText
      : '',
    'Header text (Verification Email)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION]
        ?.verificationEmailTitleCopy ?? 'NULL',
    'Body text (Verification Email)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION]
        ?.verificationEmailBodyCopy ?? 'NULL',
    'Action button text (Verification Email)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION]
        ?.verificationEmailButtonText ?? 'NULL',
    'Header text (Subscription Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS]?.titleCopy ?? 'NULL',
    'Description text (Subscription Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS]?.bodyCopy ?? 'NULL',
    'Email label (Subscription Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS]?.emailInputLabel ??
      'NULL',
    'Helper text (Subscription Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS]?.signupFooterText ??
      'NULL',
    'Action button text (Subscription Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS]?.signupButtonLabel ??
      'NULL',
    'Header text (Verification Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION]
        ?.verificationTitleCopy ?? 'NULL',
    'Description text (Verification Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION]
        ?.verificationBodyCopy ?? 'NULL',
    'Header text (Success Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION]
        ?.confirmationTitleCopy ?? 'NULL',
    'Description text (Success Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION]
        ?.confirmationBodyCopy ?? 'NULL',
    'Redirect link (Success Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE]?.redirectURL ??
      'NULL',
    'Header text (Unsubscribe Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE]
        ?.unsubscribeTitleCopy ?? 'NULL',
    'Description text (Unsubscribe Page)':
      campaignSettings[CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE]
        ?.unsubscribeBodyCopy ?? 'NULL',
    'Collect Subscriber First and Last Names': campaignSettings[
      CAMPAIGN_SETTING_TYPES.HOSTED_SUBS
    ]?.isFirstNameLastNameEnabled
      ? 'TRUE'
      : 'FALSE',
  };

  if (suffix !== '') {
    for (const key in campaignBrandingDetails) {
      if (Object.prototype.hasOwnProperty.call(campaignBrandingDetails, key)) {
        campaignBrandingDetails[`${key} ${suffix}`] =
          campaignBrandingDetails[key];
        delete campaignBrandingDetails[key];
      }
    }
  }

  return campaignBrandingDetails;
};

const getCampaignFrequency = (
  selectedDays,
  monthlySchedule,
  isMonthlySelected
) => {
  if (!isMonthlySelected) {
    return selectedDays.map(day => DAY_NAME_MAP[day] ?? day).join('-');
  }
  return `${monthlySchedule.occurrence}-${monthlySchedule.dayOfWeek}`;
};

/**
 * getCampaignName
 * @param {string} campaignURN
 * @param {object} globalInfo
 * @returns {string | null}
 */
const getCampaignName = (campaignURN, globalInfo) => {
  const campaign = getCampaign(campaignURN, globalInfo);
  return campaign ? campaign.campaignName : null;
};

const getCampaignPropertyURN = (campaignURN, globalInfo) => {
  const campaignPropertyMap = getCampaignPropertyMap(globalInfo);
  return campaignPropertyMap?.[campaignURN] ?? null;
};

const getCampaignPropertyMap = globalInfo => {
  if (!globalInfo?.properties) return null;
  const map = {};
  Object.keys(globalInfo.properties).forEach(propertyURN => {
    const property = globalInfo.properties[propertyURN];
    if (property.campaigns) {
      Object.keys(property.campaigns).forEach(campaignURN => {
        map[campaignURN] = propertyURN;
      });
    }
  });
  return map;
};

const getCampaignTrackingDetails = ({
  campaignDetails = mandatory('campaignDetails'),
  campaignSettings = mandatory('campaignSettings'),
  suffix = '',
} = {}) => {
  const campaignFrequency = getCampaignFrequency(
    cron.getCronDayOfWeek(
      campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
        .editionCreationSchedule
    ),
    cron.getMonthlySchedule(
      campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
        .editionCreationSchedule
    ),
    cron.isMonthlyCron(
      campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
        .editionCreationSchedule
    )
  );
  const timeSlotStart = cron.getCronHours(
    campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING].editionCreationSchedule,
    campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING].timezone
  );
  const { sendingTimeslotSizeSeconds } =
    campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING];

  const timeSlot = schedule.getTimeSlot({
    timeSlotStart,
    sendingTimeslotSizeSeconds,
  });
  const isSendTimeOptimal = schedule.isSendTimeOptimal(
    timeSlotStart,
    sendingTimeslotSizeSeconds
  );
  const startOfTimeslotWindow = datetime.getFormattedTimeFromParts({
    hour: timeSlot[0],
  });
  const endOfTimeslotWindow = datetime.getFormattedTimeFromParts({
    hour: timeSlot[1],
  });
  const timeslotWindowLength =
    campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING]
      .sendingTimeslotSizeSeconds / SECS_IN_HOUR;

  const campaignTrackingDetails = {
    'Automation Status': campaignSettings[CAMPAIGN_SETTING_TYPES.AUTO_APPROVAL]
      .isEnabled
      ? 'On'
      : 'Off',
    'Default Subject':
      EDITION_SUBJECT_TYPE_DESCRIPTIONS[
        campaignSettings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT].subjectType
      ],
    '# Articles Blocks':
      campaignDetails?.sections.filter(
        section => section.sectionType === SECTION_TYPES.ARTICLES
      ).length ?? 0,
    '# Sponsored Articles Blocks':
      campaignDetails?.sections.filter(
        section => section.sectionType === SECTION_TYPES.ARTICLES_SPONSORED
      ).length ?? 0,
    '# Advertising Blocks': campaignDetails?.promotionBlocks.length ?? 0,
    '# Text Blocks': campaignDetails?.textBlocks.length ?? 0,
    'Campaign Timezone':
      campaignSettings[CAMPAIGN_SETTING_TYPES.SCHEDULING]?.timezone ?? '',
    'Campaign Time Format': campaignSettings[
      CAMPAIGN_SETTING_TYPES.PRESENTATION
    ]?.twelveHourFormat
      ? '12-hour'
      : '24-hour',
    'Campaign Frequency': campaignFrequency,
    'Campaign Sending Window': isSendTimeOptimal ? 'Optimal' : 'Time slot',
    'Default Start of Timeslot Window': startOfTimeslotWindow,
    'Default End of Timeslot Window': endOfTimeslotWindow,
    'Default Timeslot Window Length': timeslotWindowLength,
    'Default Email Preview Text':
      EMAIL_PREVIEW_TYPE_DESCRIPTIONS[
        campaignSettings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
          .defaultEmailPreviewTextType
      ],
    'Custom Email Preview Text':
      campaignSettings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
        .customDefaultEmailPreviewText,
    'CTA Button Text': campaignDetails?.sections.map(section => ({
      sectionURN: section.sectionURN,
      'Call to action text': section?.callToActionText,
    })),
  };

  if (suffix !== '') {
    for (const key in campaignTrackingDetails) {
      if (Object.prototype.hasOwnProperty.call(campaignTrackingDetails, key)) {
        campaignTrackingDetails[`${key} ${suffix}`] =
          campaignTrackingDetails[key];
        delete campaignTrackingDetails[key];
      }
    }
  }

  return campaignTrackingDetails;
};

const getCurrentCampaign = globalInfo => {
  const campaignURN = getCurrentCampaignURN(globalInfo);
  if (utility.isNull(campaignURN)) return null;
  return getCampaign(campaignURN, globalInfo);
};

const getCurrentCampaignSettings = globalInfo => {
  const campaignSettingsArray = getSettingsForCurrentCampaign(globalInfo);
  const campaignSettingsObject = campaignSettingsArray?.reduce(
    (accumulator, value) => ({ ...accumulator, [value.settingsType]: value }),
    {}
  );
  return campaignSettingsObject;
};

const getCurrentCampaignSettingsType = (globalInfo, settingsType) =>
  getSettingForCurrentCampaign(globalInfo, settingsType);

const getCurrentCampaignSettingValue = (
  globalInfo,
  settingsType,
  settingKey
) => {
  const setting = getSettingForCurrentCampaign(globalInfo, settingsType);
  return setting?.[settingKey] ?? null;
};

const getCurrentCampaignURN = globalInfo =>
  globalInfo?.current?.campaignURN ?? null;

const getCurrentCampaignName = globalInfo => {
  const campaign = getCurrentCampaign(globalInfo);
  return campaign ? campaign.campaignName : null;
};

const getCurrentPropertyCampaigns = globalInfo => {
  if (utility.isNull(globalInfo)) return null;
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  return getPropertyCampaigns(propertyURN, globalInfo);
};

const getPropertyCampaigns = (propertyURN, globalInfo) => {
  if (utility.isNull(globalInfo)) return null;
  return Object.values(globalInfo?.properties?.[propertyURN]?.campaigns) ?? [];
};

const getSettingForCurrentCampaign = (globalInfo, settingsType) => {
  const campaignSettings = getSettingsForCurrentCampaign(globalInfo);

  let returnSetting = null;
  campaignSettings.forEach(setting => {
    if (setting.settingsType === settingsType) {
      returnSetting = setting;
    }
  });
  return returnSetting;
};

const getSettingsForCurrentCampaign = globalInfo => {
  const campaign = getCurrentCampaign(globalInfo);
  return campaign?.campaignSettings ?? null;
};

/**
 * prepareForSave
 *
 * Takes a campaign object and strips out all fields that don't need to be provided
 * for a POST or PUT request
 *
 * @param {object} campaign
 * @returns {object}
 */
const prepareForSave = campaign => {
  const campaignToSave = utility.cloneObject(campaign);
  const fieldsToSave = [
    'campaignName',
    'campaignState',
    'fromName',
    'replyToEmails',
    'fromEmail',
    'returnPath',
  ];
  Object.keys(campaign).forEach(key => {
    if (fieldsToSave.indexOf(key) === -1) {
      delete campaignToSave[key];
    }
  });
  return campaignToSave;
};

const setCurrentCampaignURN = (globalInfo, propertyURN = null) => {
  if (utility.isNull(propertyURN)) return null;
  if (Object.keys(globalInfo.properties[propertyURN].campaigns).length === 0)
    return null;
  // TODO this might need to be slightly more sophisticated...
  return globalInfo.properties[propertyURN].campaigns[
    Object.keys(globalInfo.properties[propertyURN].campaigns)[0]
  ].campaignURN;
};

const shouldShowAddSubscribers = globalInfo => {
  const campaignURN = getCurrentCampaignURN(globalInfo);
  return CAMPAIGNS_USING_HOSTED_SUBSCRIPTIONS.indexOf(campaignURN) === -1;
};

const sortCampaignList = campaignList => {
  // Split campaign list into active and under setup
  const active = [];
  const setup = [];

  campaignList.forEach(campaign => {
    (campaign.campaignState === CAMPAIGN_STATES.UNDER_SETUP
      ? setup
      : active
    ).push(campaign);
  });

  // Sort alphabetically for now
  [active, setup].forEach(array =>
    array.sort((a, b) => (a.campaignName > b.campaignName ? 1 : -1))
  );

  // Insert campaigns under setup at the bottom of campaign list
  return [...active, ...setup];
};
