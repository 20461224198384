/* eslint-disable react/no-this-in-sfc */
import { useEffect, useState } from 'react';

import {
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
  WatchTimeIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

import * as API from 'api/api';
import { QUERY_CACHE_VALUES } from 'common/constants';
import * as datetime from 'common/datetime';
import { EDITION_STATE } from 'common/enums';
import * as logger from 'common/logger';
import * as maths from 'common/maths';
import { Campaign, EngagementStatistics } from 'common/types';

import useDeveloperMode from 'hooks/useDeveloperMode';
import { useQuery } from 'react-query';
import './SubscriberEngagement.css';

interface SubscriberEngagementProps {
  campaign: Campaign;
}

function SubscriberEngagment({ campaign }: SubscriberEngagementProps) {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options | null>(
    null
  );

  const [isDeveloperModeActive] = useDeveloperMode('NL-4325');

  const toast = useToast();

  const {
    isLoading: isEngagementDataLoading,
    data: engagementData,
    error: subEngagementError,
  } = useQuery(
    ['engagement data', campaign.campaignURN],
    async () => {
      logger.info('Analytics:fetchSubEngagement');
      return API.getCampaignEngagement({
        campaignURN: campaign.campaignURN,
      });
    },
    {
      staleTime: QUERY_CACHE_VALUES.ANALYTICS.STALE_TIME,
      cacheTime: QUERY_CACHE_VALUES.ANALYTICS.CACHE_TIME,
      onError: () => {
        logger.error({
          event: 'Analytics:fetchSubEngagement',
          subEngagementError,
        });
        toast({
          variant: 'error',
          title: 'Failed to get subscriber engagement data',
        });
      },
    }
  );

  const {
    isLoading: isNumEditionsLoading,
    data: numEditions,
    error: numEditionsError,
  } = useQuery(
    ['engagement num editions', campaign.campaignURN],
    async () => {
      logger.info('Analytics:fetchNumEditions - subsriberEngagement');
      return API.getEditionList({
        campaignURN: campaign.campaignURN,
        startUnixTime: datetime.NOW - datetime.DAYS(28),
        endUnixTime: datetime.NOW,
        filterByState: EDITION_STATE.SENT,
      });
    },
    {
      enabled: engagementData
        ? Object.keys(engagementData).length === 0
        : false,
      select: data => data.length,
      staleTime: QUERY_CACHE_VALUES.ANALYTICS.STALE_TIME,
      cacheTime: QUERY_CACHE_VALUES.ANALYTICS.CACHE_TIME,
      onError: () => {
        logger.error({
          event: 'Analytics:fetchNumEditions - subsriberEngagement',
          numEditionsError,
        });
        toast({
          variant: 'error',
          title: 'Failed to load engagement data',
        });
      },
    }
  );

  useEffect(() => {
    if (engagementData && Object.keys(engagementData).length > 0)
      configureChart(engagementData, isDeveloperModeActive);
  }, [engagementData, isDeveloperModeActive]);

  function configureChart(
    engagement: EngagementStatistics,
    displayNever: boolean
  ) {
    const card = document.getElementById('engagement-chart');

    function getEngagementDescription(level: string) {
      switch (level) {
        case 'Low':
          return 'Subscribers who rarely interact with your emails';
        case 'Medium':
          return 'Subscribers who occasionally interact with your emails';
        case 'High':
          return 'Subscribers who regularly interact with your emails';
        case 'Never engaged':
          return 'Subscribers who never interact with your emails';
        default:
          return '';
      }
    }

    let seriesData = [
      {
        name: 'High',
        y: maths.round(engagement.highActivity * 100, 2),
      },
      {
        name: 'Medium',
        y: maths.round(engagement.mediumActivity * 100, 2),
      },
      {
        name: 'Low',
        y: maths.round(engagement.lowActivity * 100, 2),
      },
      {
        name: 'Never engaged',
        y: maths.round(engagement.neverActivity * 100, 2),
      },
    ];

    let colors = [
      'rgba(41, 98, 219, 1)',
      'rgba(41, 98, 219, 0.8)',
      'rgba(41, 98, 219, 0.6)',
      'rgba(41, 98, 219, 0.4)',
    ];

    if (!displayNever) {
      seriesData = [
        {
          name: 'Medium',
          y: maths.round(engagement.mediumActivity * 100, 2),
        },
        {
          name: 'Low',
          y: maths.round(engagement.lowActivity * 100, 2),
        },
        {
          name: 'High',
          y: maths.round(engagement.highActivity * 100, 2),
        },
      ];

      colors = [
        'rgba(51, 113, 230, 0.75)',
        'rgba(51, 113, 230, 0.5)',
        'rgba(41, 98, 219, 1)',
      ];
    }

    setChartOptions({
      chart: {
        type: 'pie',
        width: (card?.clientWidth ?? 600) - 50,
        height: (card?.clientHeight ?? 400) - 50,
        style: {
          fontFamily: 'Roboto',
        },
      },
      colors,
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          borderWidth: 0,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter(): string {
              return `
                    <span style="position: relative; top: -10px">
                      <span style="color: #596A87; fontSize: 14px; fontWeight: 500">${
                        this.point.name
                      }</span>
                      <br />
                      <span style="color: #0E1E39; fontSize: 16px; fontWeight: 500">${parseFloat(
                        this.percentage.toFixed(2)
                      )}%</span>
                    </span>
                  `;
            },
            useHTML: true,
            verticalAlign: 'top',
          },
          showInLegend: true,
        },
      },
      series: [
        {
          type: 'pie',
          colorByPoint: true,
          data: seriesData,
          innerSize: '55%',
          name: 'Percentage',
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        borderColor: 'transparent',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 0,
        shadow: false,
        useHTML: true,
        formatter: function describeEngagement() {
          return `<div class="highchart-tooltip">
            <h2 class="highchart-tooltip-text">
            ${getEngagementDescription(this.point.name)}</h2>
          </div>`;
        },
      },
    });
  }

  const displayStates = {
    loading: {
      bgColor: 'white',
      component: <Spinner size="md" color="gray.600" />,
    },
    data: {
      bgColor: 'white',
      component: (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      ),
    },
    noData: {
      bgColor: 'gray.100',
      component: <Text color="gray.600">No data</Text>,
    },
    collectingData: {
      bgColor: 'gray.100',
      component: (
        <Flex alignItems="center" gap={1}>
          <WatchTimeIcon size={5} color="gray.500" />
          <Text color="gray.600">Collecting data</Text>
        </Flex>
      ),
    },
  };

  function getEngagementStatisticsState() {
    // Return loading state if we're still waiting for engagement data
    if (isEngagementDataLoading || isNumEditionsLoading) {
      return displayStates.loading;
    }

    if (engagementData && Object.keys(engagementData).length === 0) {
      // If there is no data and an edition was sent in the last 4 weeks, it means we should have data so must be collecting
      if (numEditions > 0) {
        return displayStates.collectingData;
      }
      return displayStates.noData;
    }
    return displayStates.data;
  }

  const engagementStatistics = getEngagementStatisticsState();

  return (
    <Card
      flexDir="column"
      alignItems="flex-start"
      flexBasis="50%"
      flexGrow={0}
      height="308px"
      id="engagement-chart"
      p={6}
      bgColor={engagementStatistics.bgColor}
    >
      <Heading variant="h5" color="gray.600">
        Subscriber Engagement
      </Heading>
      <Flex
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {engagementStatistics.component}
      </Flex>
    </Card>
  );
}

export default SubscriberEngagment;
