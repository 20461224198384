import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useFragmentParameters() {
  let { hash } = useLocation();
  if (hash.substring(0, 1) === '#') {
    hash = hash.substring(1);
  }
  return useMemo(() => new URLSearchParams(hash), [hash]);
}

export default useFragmentParameters;
