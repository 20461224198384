import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import { COGNITO_CHALLENGE_NAMES } from 'common/constants';
import { USER_TYPES } from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

export default async function authSignIn({
  username = mandatory('username'),
  password = mandatory('password'),
} = {}) {
  const guid = metrics.start('authSignIn');
  logger.info(`API:authSignIn ${username}`);

  try {
    const signinResponse = await Auth.signIn(username.trim(), password);
    if (
      signinResponse.challengeName !==
        COGNITO_CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA &&
      signinResponse?.signInUserSession
    ) {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken();
      const accessGroups = accessToken.payload['cognito:groups'];
      if (
        accessGroups.indexOf('NEWSLETTER_FRONTEND_USER') === -1 &&
        accessGroups.indexOf('STAFF_USER') === -1
      ) {
        throw new Error(
          'You are not connected to an Email property. Contact Echobox to find out more.'
        );
      }
      signinResponse.userType =
        accessGroups.indexOf('STAFF_USER') !== -1
          ? USER_TYPES.ECHOBOX_STAFF
          : USER_TYPES.DEFAULT;
    }
    metrics.end('authSignIn', guid);
    authentication.storeChallengeDetails(signinResponse);
    return signinResponse;
  } catch (error) {
    metrics.fail('authSignIn', guid);
    throw errors.handleCognitoError({
      originalError: error,
      errorLocation: 'api/authSignIn',
      args: { username },
    });
  }
}
