import { Dropdown } from 'react-bootstrap';

import BodyElement from 'components/settings/campaigns/content/BodyElement';

import { ReactComponent as DragHandle } from 'assets/svg/drag-handle.svg';
import { ReactComponent as Ellipsis } from 'assets/svg/ellipsis.svg';

import EmptyImage from 'assets/svg/empty-image.svg';

import { PROMOTION_BLOCK_TYPES } from 'common/enums';
import * as string from 'common/string';
import './BodyPromotion.css';

interface BodyPromotionProps {
  imageURL?: string | null;
  promotionBlockType: PROMOTION_BLOCK_TYPES;
  onDelete: (promotionBlockURN: string) => void;
  promotionBlockURN: string;
  onEdit: (index: number) => void;
  index: number;
}

function BodyPromotion({
  promotionBlockType,
  imageURL,
  onDelete,
  promotionBlockURN,
  onEdit,
  index,
}: BodyPromotionProps) {
  const openPromoModal = () => {
    document.body.click();
    onEdit(index);
  };

  const promotionText = imageURL ? 'Link added' : 'Add an image using a URL';

  return (
    <BodyElement className="promotion-body-element">
      <div className="d-flex align-items-start h-100">
        <div className="drop-handle promotion-drag-handle mr-2">
          <DragHandle />
        </div>
        <div>
          <div className="ebx-h2 ebx-text-overflow ebx-whitescape-nowrap w-100 ebx-title-colour promotion-image-title">
            {string.capitalise(promotionBlockType)}
          </div>
          <div className="ebx-text-overflow ebx-whitescape-nowrap ebx-body-1 mt-2 element-description">
            {promotionText}
          </div>
        </div>
        {imageURL ? (
          <div className="ml-auto">
            <img src={imageURL} alt="" className="h-100 body-promotion-image" />
          </div>
        ) : (
          <div className="ml-auto body-promotion-empty-image-background h-100 d-flex align-items-center justify-content-center">
            <img src={EmptyImage} alt="" />
          </div>
        )}
        <Dropdown>
          <Dropdown.Toggle
            className="p-0 border-0 ml-3 section-dropdown-toggle"
            bsPrefix="p-0" // Removes the caret
          >
            <Ellipsis />
          </Dropdown.Toggle>

          <Dropdown.Menu className="ebx-profile-menu">
            <Dropdown.Item
              className="ebx-profile-option px-3 py-2"
              onClick={openPromoModal}
            >
              <span className="ebx-h3 body-promotion-link">Edit</span>
            </Dropdown.Item>
            <Dropdown.Item
              className="ebx-profile-option px-3 py-2"
              onClick={() => onDelete(promotionBlockURN)}
            >
              <span className="ebx-h3 body-promotion-delete">Delete</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </BodyElement>
  );
}

BodyPromotion.defaultProps = {
  imageURL: null,
};

export default BodyPromotion;
