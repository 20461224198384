/* eslint consistent-return: "off" */

import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
};

function useMutationObservable(
  targetElement,
  callback,
  options = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    if (!callback || typeof callback !== 'function') {
      console.warn(
        `You must provide a valid callback function, instead you've provided ${callback}`
      );
      return;
    }
    const { debounceTime } = options;
    const obs = new MutationObserver(
      debounceTime > 0 ? debounce(callback, debounceTime) : callback
    );
    setObserver(obs);
  }, [callback, options, setObserver]);

  useEffect(() => {
    if (!observer) return;
    if (!targetElement) {
      console.warn(
        `You must provide a valid DOM element to observe, instead you've provided ${targetElement}`
      );
    }
    const { config } = options;
    try {
      observer.observe(targetElement, config);
    } catch (e) {
      console.error(e);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetElement, options]);
}

export default useMutationObservable;
