import {
  AddIcon,
  Card,
  DeleteTrashIcon,
  Dropdown,
  EditPenIcon,
  Flex,
  FlexProps,
  Heading,
  MoreOptionsIcon,
  Portal,
  Spinner,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as datetime from 'common/datetime';

import DeleteTemplate from 'components/common/template/DeleteTemplate';
import RenameTemplate from 'components/common/template/RenameTemplate';
import TemplateThumbnail from 'components/common/template/TemplateThumbnail';

const templateCardProps = {
  w: '248px',
  h: '300px',
  p: '3',
  flexDir: 'column',
  justifyContent: 'center',
  gap: 3,
  pos: 'relative',
  _hover: {
    cursor: 'pointer',
    _before: {
      content: '""',
      position: 'absolute',
      inset: 0,
      boxShadow: 'lg',
      borderRadius: 'lg',
    },
    _active: {
      _before: {
        content: '""',
        position: 'absolute',
        inset: 0,
        boxShadow: 'none',
      },
    },
  },
} as FlexProps;

interface TemplateCardProps {
  isLoading?: boolean;
  lastUpdatedUnixTime?: number;
  lastUsedUnixTime?: number | null;
  name: string | null;
  newTemplate?: boolean;
  thumbnail?: string;
}

function TemplateCard({
  isLoading = false,
  lastUpdatedUnixTime,
  lastUsedUnixTime,
  name,
  newTemplate = false,
  thumbnail = '',
}: TemplateCardProps) {
  const {
    isOpen: isRenameTemplateOpen,
    onOpen: onRenameTemplateOpen,
    onClose: onRenameTemplateClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteTemplateOpen,
    onOpen: onDeleteTemplateOpen,
    onClose: onDeleteTemplateClose,
  } = useDisclosure();

  const lastUsed = lastUsedUnixTime
    ? `Last used: ${datetime.getAbbreviatedDateFromUnix(lastUsedUnixTime)}`
    : 'Not yet used';

  const lastUpdated = `Last updated: ${datetime.getAbbreviatedDateFromUnix(
    lastUpdatedUnixTime
  )}`;

  if (isLoading) {
    return (
      <Card
        w={templateCardProps.w}
        h={templateCardProps.h}
        p={templateCardProps.p}
        justifyContent="center"
      >
        <Spinner height={5} width={5} color="gray.500" />
      </Card>
    );
  }

  if (newTemplate) {
    return (
      <Card
        bg="gray.100"
        boxShadow="borderPrimary"
        {...templateCardProps}
        _active={{
          bg: 'primary.50',
        }}
      >
        <AddIcon size={12} color="primary.600" />
        <Heading variant="h4" color="primary.600">
          New
        </Heading>
      </Card>
    );
  }

  return (
    <>
      <Card
        alignItems="unset"
        role="group"
        {...templateCardProps}
        _active={{
          bg: 'gray.100',
        }}
      >
        <TemplateThumbnail name={name ?? ''} thumbnail={thumbnail} />
        <Flex grow={1} justifyContent="space-between">
          <Card.CardContent>
            <Card.CardTitle>{name}</Card.CardTitle>
            <Card.CardDescription fontSize="xs" lineHeight={4}>
              {lastUsed}
            </Card.CardDescription>
          </Card.CardContent>
          <Dropdown placement="bottom-end">
            <Dropdown.Button as={MoreOptionsIcon} size={5} zIndex={1} />
            <Portal>
              <Dropdown.List>
                <Dropdown.Group title={lastUpdated}>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    icon={<EditPenIcon size={5} />}
                    onClick={onRenameTemplateOpen}
                  >
                    Rename
                  </Dropdown.Item>
                  <Dropdown.Item
                    color="red.600"
                    icon={<DeleteTrashIcon size={5} color="red.600" />}
                    onClick={onDeleteTemplateOpen}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Group>
              </Dropdown.List>
            </Portal>
          </Dropdown>
        </Flex>
      </Card>

      {isRenameTemplateOpen && (
        <RenameTemplate
          isOpen={isRenameTemplateOpen}
          name={name ?? ''}
          onClose={onRenameTemplateClose}
        />
      )}

      {isDeleteTemplateOpen && (
        <DeleteTemplate
          isOpen={isDeleteTemplateOpen}
          name={name ?? ''}
          onClose={onDeleteTemplateClose}
        />
      )}
    </>
  );
}

export default TemplateCard;
