import { useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';

import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';

import { EDITION_DISPLAY_STATES } from 'common/constants';
import { DAY, getUnixTimestamp } from 'common/datetime';
import { SCHEDULE_EDITION_OPTIONS } from 'common/enums';
import { Edition as EditionType } from 'common/types';

import {
  Button,
  Modal,
  useDisclosure,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import EditionSendTimes from 'components/campaigns/editions/EditionSendTimes';
import OptimalSend from './OptimalSend';
import SchedulerModalRadio from './SchedulerModalRadio';
import SpecificTime from './SpecificTime';
import TimeSlot from './TimeSlot';

import './Scheduler.css';

interface SchedulerProps {
  title: string;
  edition: EditionType;
  editionDisplayState: string;
  updateEditionDetails: ({
    scheduledUnixTime,
    sendingTimeslotSizeSeconds,
  }: {
    scheduledUnixTime: number;
    sendingTimeslotSizeSeconds: number;
  }) => void;
  initialFinishedSendingTime: number;
  scheduleEditionButton: boolean;
}

function Scheduler({
  title,
  edition,
  editionDisplayState,
  updateEditionDetails,
  initialFinishedSendingTime,
  scheduleEditionButton,
}: SchedulerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSavingTiming, setIsSavingTiming] = useState(false);
  const [scheduledUnixTime, setScheduledUnixTime] = useState(
    edition.scheduledUnixTime
  );
  const [finishedSendingTime, setFinishedSendingTime] = useState(
    initialFinishedSendingTime
  );
  const [option, setOption] = useState<keyof typeof SCHEDULE_EDITION_OPTIONS>(
    editions.getScheduleEditionOption(
      edition.scheduledUnixTime,
      initialFinishedSendingTime,
      true
    )
  );

  const isEdition = editions.isEdition(edition);

  const toast = useToast();

  const editionStateLabel = () => {
    const stateLabels = {
      [EDITION_DISPLAY_STATES.NEW]: 'Pre-Review',
      [EDITION_DISPLAY_STATES.ECHOBOX_APPROVED]: 'Pre-Review',
      [EDITION_DISPLAY_STATES.SCHEDULED]: 'Approved',
      [EDITION_DISPLAY_STATES.AUTOMATED]: 'Approved',
    };
    return stateLabels[editionDisplayState];
  };

  const handleShow = () => {
    setOption(
      editions.getScheduleEditionOption(
        scheduledUnixTime,
        finishedSendingTime,
        true
      )
    );
    onOpen();
  };

  const handleChangeOption = (
    newScheduleOption: keyof typeof SCHEDULE_EDITION_OPTIONS
  ) => {
    // Initialize the values for the date pickers when you select a different schedule option
    if (option !== newScheduleOption) {
      switch (newScheduleOption) {
        case SCHEDULE_EDITION_OPTIONS.NOW: {
          const now = getUnixTimestamp();
          initializeTimes(now, now);
          break;
        }
        case SCHEDULE_EDITION_OPTIONS.TIME_SLOT: {
          const timeslotStart = getTimeslotStart();
          const duration =
            scheduledUnixTime === finishedSendingTime
              ? 1800
              : finishedSendingTime - scheduledUnixTime;
          initializeTimes(timeslotStart, timeslotStart + duration);
          break;
        }
        case SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME: {
          const timeslotStart = getTimeslotStart();
          initializeTimes(timeslotStart, timeslotStart);
          break;
        }
        case SCHEDULE_EDITION_OPTIONS.OPTIMAL: {
          const optimalRange = editions.getOptimalTimeslot(new Date());
          initializeTimes(
            optimalRange.optimalStartTime,
            optimalRange.optimalEndTime
          );
          break;
        }
        default:
          initializeTimes(scheduledUnixTime, finishedSendingTime);
      }
      setOption(newScheduleOption);
    }
  };

  const getTimeslotStart = () => {
    const HALF_HOUR = 1800;
    const HOUR = 3600;
    const MINUTE = 60;

    const now = datetime.getUnixTimestamp(); // Unix timestamp now
    const prevHalfHour = now - (now % HALF_HOUR); // Get the start of the previous half hour block
    let nextHour = prevHalfHour + HOUR; // Add an hour to this
    // If this isn't at least an hour in the future...
    if (nextHour - now < HOUR - MINUTE) {
      nextHour += HALF_HOUR; // ... add another half hour
    }
    return nextHour;
  };

  const initializeTimes = (start: number, end: number) => {
    setScheduledUnixTime(start);
    setFinishedSendingTime(end);
  };

  const handleSaveTiming = async () => {
    setIsSavingTiming(true);

    if (checkScheduleErrors()) {
      setIsSavingTiming(false);
    } else {
      try {
        const fieldsToUpdate = {
          scheduledUnixTime,
          sendingTimeslotSizeSeconds: finishedSendingTime - scheduledUnixTime,
        };
        await updateEditionDetails(fieldsToUpdate);
        tracker.track({
          eventName: 'Change Timeslot',
          trackingParams: {
            'Email ID': edition.editionURN,
            'Edition State Before': editionStateLabel(),
            'Start of Timeslot Window Before':
              datetime.getFormattedTimeFromUnix({
                timestamp: edition.scheduledUnixTime,
              }),
            'End of Timeslot Window Before': datetime.getFormattedTimeFromUnix({
              timestamp: initialFinishedSendingTime,
            }),
            'Timeslot Window Length Before': Math.floor(
              (initialFinishedSendingTime - edition.scheduledUnixTime) /
                datetime.HOUR
            ),
            'Email Type': isEdition ? 'Newsletter' : 'Marketing Email',
          },
        });
        toast({ variant: 'success', title: 'Saved new schedule' });
      } catch (error) {
        logger.error({
          event: 'Curation:handleSaveScheduleTime',
          error,
        });
        toast({ variant: 'error', title: 'Error saving schedule' });
      } finally {
        setIsSavingTiming(false);
        onClose();
      }
    }
  };

  // Check for any edition schedule errors
  const checkScheduleErrors = () => {
    if (finishedSendingTime - scheduledUnixTime > DAY) {
      toast({ variant: 'error', title: 'Maximum Time Slot is 24 hours' });
      return true;
    }
    if (finishedSendingTime < getUnixTimestamp()) {
      if (option === SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME) {
        toast({
          variant: 'error',
          title: "Specific Time Schedule can't be in the past",
        });
        return true;
      }
      if (option === SCHEDULE_EDITION_OPTIONS.TIME_SLOT) {
        toast({
          variant: 'error',
          title: "End of Time Slot can't be in the past",
        });
        return true;
      }
      if (option === SCHEDULE_EDITION_OPTIONS.OPTIMAL) {
        toast({ variant: 'error', title: "Optimal date can't be in the past" });
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow} mr="2" h="38px">
        <EditionSendTimes
          scheduledUnixTime={edition.scheduledUnixTime}
          finishedSendingTime={initialFinishedSendingTime}
          scheduleEditionButton={scheduleEditionButton}
          editionState={edition.editionState}
        />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup className="mb-0">
              <SchedulerModalRadio
                id="OPTIMAL"
                label="Optimal"
                description="The best send time for maximum engagement."
                checked={option === SCHEDULE_EDITION_OPTIONS.OPTIMAL}
                onChange={() => {
                  handleChangeOption(SCHEDULE_EDITION_OPTIONS.OPTIMAL);
                }}
              >
                <OptimalSend
                  scheduledUnixTime={scheduledUnixTime}
                  setScheduledUnixTime={setScheduledUnixTime}
                  setFinishedSendingTime={setFinishedSendingTime}
                />
              </SchedulerModalRadio>
              <SchedulerModalRadio
                id="TIME_SLOT"
                label="Time Slot"
                checked={option === SCHEDULE_EDITION_OPTIONS.TIME_SLOT}
                onChange={() => {
                  handleChangeOption(SCHEDULE_EDITION_OPTIONS.TIME_SLOT);
                }}
              >
                <TimeSlot
                  scheduledUnixTime={scheduledUnixTime}
                  finishedSendingTime={finishedSendingTime}
                  setScheduledUnixTime={setScheduledUnixTime}
                  setFinishedSendingTime={setFinishedSendingTime}
                />
              </SchedulerModalRadio>
              <SchedulerModalRadio
                id="SPECIFIC_TIME"
                label="Specific Time"
                checked={option === SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME}
                onChange={() => {
                  handleChangeOption(SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME);
                }}
              >
                <SpecificTime
                  scheduledUnixTime={scheduledUnixTime}
                  setScheduledUnixTime={setScheduledUnixTime}
                  setFinishedSendingTime={setFinishedSendingTime}
                />
              </SchedulerModalRadio>
              <SchedulerModalRadio
                id="NOW"
                label="Now"
                checked={option === SCHEDULE_EDITION_OPTIONS.NOW}
                onChange={() => {
                  handleChangeOption(SCHEDULE_EDITION_OPTIONS.NOW);
                }}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveTiming}
            isDisabled={isSavingTiming}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Scheduler;
