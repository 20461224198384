import axios, { AxiosError } from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface PutEditionArgs {
  editionURN: string;
  editionDetails: object;
}
export default async function putEditions({
  editionURN,
  editionDetails,
}: PutEditionArgs) {
  const guid = metrics.start('putEditions');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/editions/${editionURN}`;

  const config = {
    url,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      ...editionDetails,
    }),
  };
  logger.info(`API:putEditions /editions/${editionURN}`);

  try {
    const response = await axios(config);
    metrics.end('putEditions', guid);
    return response.data;
  } catch (error) {
    metrics.fail('putEditions', guid);
    // Rethrow bad request
    const err = error as AxiosError;
    if (err.response?.status === 400) {
      throw err.response.data;
    }
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/putEditions',
            args: { editionDetails },
          })
        : error;
    throw apiError;
  }
}
