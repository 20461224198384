/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useSettings from 'hooks/useSettings';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';

import './DoubleOptIn.css';

function DoubleOptIn() {
  const { urn: campaignURN } = useParams();
  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(campaignURN);
  }, []);

  const isDoubleOptInActive = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.SUBSCRIPTION,
    'isDoubleOptInEnabled'
  );

  const doubleOptInLabelText = isDoubleOptInActive ? 'On' : 'Off';

  useEffect(() => {
    // pub-sub
    const handleSave = async () => {
      logger.info('DoubleOptIn:handleSave');

      try {
        await settings.saveCampaignSettings({
          campaignURN,
        });

        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, campaignURN]);

  const handleChange = async () => {
    const updatedValue = !isDoubleOptInActive;
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SUBSCRIPTION,
      settingKey: 'isDoubleOptInEnabled',
      settingValue: updatedValue,
      doNotSetChangedFlag: true,
    });
    // Try to save
    settings.requestSave();
    // Track change
    tracker.track({
      eventName: 'Toggle Double Opt-In',
      trackingParams: {
        'Double Opt-In Status': updatedValue,
      },
      options: { campaignURN },
    });
  };

  return (
    <div className="ebx-panel ebx-panel-approved px-4 py-3 mb-2 d-flex align-items-center justify-content-between w-100">
      <div>
        <div className="ebx-h2 ebx-title-colour double-opt-in-switch">
          <span>Double opt-in enabled</span>
          <div className="d-flex align-items-center ml-2 recommended-tag">
            <div>Recommended</div>
          </div>
        </div>
        <div className="ebx-body-1 ebx-subtitle-colour">
          When a visitor subscribes to your Campaign, they are sent an email to
          confirm their subscription
        </div>
      </div>
      <div className="double-opt-in-switch">
        <Form.Switch.Label htmlFor="doubleOptInSwitch" className="mr-2">
          {doubleOptInLabelText}
        </Form.Switch.Label>
        <Form.Switch
          className="double-opt-in-switch"
          id="doubleOptInSwitch"
          disabled={settings.isLoading || settings.isSaving}
          checked={isDoubleOptInActive}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default DoubleOptIn;
