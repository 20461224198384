import { Tbody } from '@ebx-ui/ebx-ui-component-library-sdk';

import type { FixTypeLater, PropertyUser } from 'common/types';
import { SortByOptions } from 'components/settings/team/Team';

import UserDetail from 'components/settings/team/UserDetail';

interface TeamTableBodyProps {
  currentUser: string | undefined;
  entityNameSingular: string;
  entityNamePlural: string;
  entitySortMetric: string;
  handleEntitiesEdit: (user: PropertyUser) => () => void;
  handleRemoveUser: (email: string, name: string) => void;
  handleResendInvite: (user: PropertyUser) => void;
  handleRoleChange: ({
    name,
    emailAddress,
    newPermissionType,
  }: {
    name: string;
    emailAddress: string;
    newPermissionType: string;
  }) => void;
  isBusy: boolean;
  propertyURN: string;
  propertyUsers: FixTypeLater;
  sortBy: SortByOptions;
  sortOrder: 'asc' | 'desc';
}

function TeamTableBody({
  currentUser,
  entityNameSingular,
  entityNamePlural,
  entitySortMetric,
  handleEntitiesEdit,
  handleRemoveUser,
  handleResendInvite,
  handleRoleChange,
  isBusy,
  propertyURN,
  propertyUsers,
  sortBy,
  sortOrder,
}: TeamTableBodyProps) {
  const orderedUsers = propertyUsers.sort((a: FixTypeLater, b: FixTypeLater) =>
    sortOrder === 'asc'
      ? a[sortBy].localeCompare(b[sortBy])
      : b[sortBy].localeCompare(a[sortBy])
  );

  return (
    <Tbody bg="white">
      {orderedUsers.map((user: FixTypeLater) => (
        <UserDetail
          entityNameSingular={entityNameSingular}
          entityNamePlural={entityNamePlural}
          entitySortMetric={entitySortMetric}
          handleEntitiesEdit={handleEntitiesEdit}
          handleRemoveUser={handleRemoveUser}
          handleResendInvite={handleResendInvite}
          handleRoleChange={handleRoleChange}
          isBusy={isBusy}
          isCurrentUser={user.emailAddress === currentUser}
          key={user.emailAddress}
          propertyURN={propertyURN}
          user={user}
        />
      ))}
    </Tbody>
  );
}

export default TeamTableBody;
