import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import ProfileWrapper from 'components/settings/profile/ProfileWrapper';

import authChangePassword from 'api/auth/authChangePassword';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import { isNull } from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { USER_TYPES } from 'common/enums';

import './Password.css';

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter your new password.')
    .test('strongPasswordRequired', '', value => {
      const passwordRegex = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{10,}$';
      const match = value?.match(passwordRegex);
      return !isNull(match);
    })
    .trim(),
  verifyPassword: Yup.string()
    .required('Please verify your new password.')
    .test(
      'matchingRequired',
      'Passwords do not match',
      function checkPasswordValues(value) {
        return value === this.parent.newPassword;
      }
    )
    .trim(),
});

interface PasswordForm {
  currentPassword: string;
  newPassword: string;
  verifyPassword: string;
}

function Password() {
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const globalInfo = useGlobalInfo();
  const currentUser = globalInfo.user;
  const history = useHistory();
  const { setChanged, setUnchanged, setOnSave, setError, setDone } =
    useSettings({ storeStateInHook: false });
  const toast = useToast();

  const {
    register,
    formState: { errors: formErrors },
    handleSubmit,
    reset,
  } = useForm<PasswordForm>({
    mode: 'onSubmit',
    resolver: yupResolver(PasswordSchema),
    shouldUnregister: true,
  });

  if (currentUser?.userType === USER_TYPES.ECHOBOX_STAFF) {
    history.push('/settings/profile');
  }

  const handleSave = useCallback(
    async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      setIsSubmittingPassword(true);
      try {
        await authChangePassword({ currentPassword, newPassword });
        setIsSubmittingPassword(false);
        setErrorMessage('');
        reset();
        tracker.track({
          eventName: 'Change User Settings',
          trackingParams: {
            'Name Before': currentUser?.name,
            'Email Before': currentUser?.emailAddress,
            'Password Changed?': true,
          },
        });
        setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        if (isNull(errors.getErrorStatus(error))) {
          logger.error({
            event: 'handlePasswordSave Error',
            properties: {
              location: 'components/settings/profile/Password',
            },
            error,
          });
        }
        setIsSubmittingPassword(false);
        setErrorMessage(errors.getErrorMessage(errors.determineError(error)));
        setError();
        toast({
          variant: 'error',
          title: 'An error occurred while saving changes',
        });
      }
    },
    [
      currentUser?.emailAddress,
      currentUser?.name,
      reset,
      setDone,
      setError,
      toast,
    ]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setUnchanged();
    } else {
      setChanged();
    }
  };

  useEffect(() => {
    setOnSave(() => {
      handleSubmit(handleSave)();
    });
  }, [handleSave, handleSubmit, setOnSave]);

  useEffect(() => {
    if (
      errorMessage === 'Incorrect username or password.' ||
      formErrors?.newPassword ||
      formErrors?.verifyPassword
    ) {
      setError();
    } else {
      setChanged();
    }
  }, [formErrors, setError, errorMessage, setChanged]);

  useEffect(() => {
    setUnchanged();
  }, [setUnchanged]);

  const currentPassword = { ...register('currentPassword') };
  const isWrongPassword = errorMessage === 'Incorrect username or password.';
  return (
    <ProfileWrapper title="Password" description="Update your password">
      <FormGroup className="mb-4">
        <FormLabel className="ebx-h3 mb-1">Current password</FormLabel>

        <FormControl
          {...currentPassword}
          onChange={e => {
            currentPassword.onChange(e);
            handleChange(e as any);
          }}
          type="password"
          className={`ebx-input ebx-body-1 ${
            isWrongPassword ? 'ebx-input-error' : ''
          }`}
          disabled={isSubmittingPassword}
        />
        {isWrongPassword && (
          <div className="d-flex align-items-center error--text mt-2 mb-2">
            <span className="w-100">This is not your password</span>
          </div>
        )}
      </FormGroup>

      <FormGroup className="mb-4">
        <FormLabel className="ebx-h3 mb-1">New password</FormLabel>

        <FormControl
          {...register('newPassword')}
          type="password"
          className={`ebx-input ebx-body-1 ${
            formErrors.newPassword ? 'ebx-input-error' : ''
          }`}
          disabled={isSubmittingPassword}
          aria-describedby="passwordHelpBlock"
        />
        {formErrors.newPassword && (
          <div className="d-flex align-items-center error--text mt-2 mb-2">
            <span className="w-100">{formErrors.newPassword.message}</span>
          </div>
        )}
        <FormText
          className="password-help-block ebx-body-1"
          id="passwordHelpBlock"
        >
          <ul>
            <li className={`${formErrors.newPassword ? 'error--text' : ''}`}>
              Minimum 10 characters
            </li>
            <li className={`${formErrors.newPassword ? 'error--text' : ''}`}>
              At least one number
            </li>
            <li className={`${formErrors.newPassword ? 'error--text' : ''}`}>
              At least one uppercase letter
            </li>
            <li className={`${formErrors.newPassword ? 'error--text' : ''}`}>
              At least one lowercase letter
            </li>
          </ul>
        </FormText>
      </FormGroup>

      <FormGroup>
        <FormLabel className="ebx-h3 mb-1">Verify new password</FormLabel>

        <FormControl
          {...register('verifyPassword')}
          type="password"
          className={`ebx-input ebx-body-1 ${
            formErrors.verifyPassword ? 'ebx-input-error' : ''
          }`}
          disabled={isSubmittingPassword}
        />
        {formErrors.verifyPassword && (
          <div className="d-flex align-items-center error--text mt-2 mb-2">
            <span className="w-100">{formErrors.verifyPassword.message}</span>
          </div>
        )}
      </FormGroup>
    </ProfileWrapper>
  );
}

export default Password;
