import { Center, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth, Hub } from 'aws-amplify';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import StateWrapper from 'containers/StateWrapper';
import { StoreContext } from 'store/store';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import * as cookie from 'common/cookie';
import * as environment from 'common/environment';
import * as logger from 'common/logger';
import * as loginApp from 'common/loginapp';
import * as object from 'common/object';
import * as tracker from 'common/tracker';
import * as url from 'common/url';
import * as utility from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';

import {
  AXIOS_ERROR_CODES,
  EBX_LAST_USED_PRODUCT_KEY,
  GLOBAL_INFO_STATES,
  HUB_CHANNELS,
  HUB_EVENTS,
  IFRAME_MESSAGE_TIMEOUT,
  LOGIN_APP_MESSAGES,
  LOGIN_STARTED,
  NO_EXPIRY_DATE,
  NO_PERMISSIONS_PREFIX,
  ROUTE_REDIRECTIONS,
} from 'common/constants';
import { UI_MESSAGES } from 'common/messages';
import { CENTRALISED_LOGIN } from 'common/toggles';

const IS_GETTING_TOKENS_KEY = 'isGettingTokens';

const loginUrl = environment.getLoginUrl();

const saveTokens = tokens => {
  Object.keys(tokens).forEach(key => {
    const value =
      typeof tokens[key] === 'object'
        ? JSON.stringify(tokens[key])
        : tokens[key];
    cookie.setCookieValue(key, value, { sameSite: 'strict' });
  });
};

function SessionWrapper() {
  const [isLoading, setIsLoading] = useState(true);
  const [isGettingTokens, setIsGettingTokens] = useState(!!CENTRALISED_LOGIN);
  const { actions } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();
  const global = useGlobalInfo();
  const loginStartedTime = useRef(cookie.getCookieValue(LOGIN_STARTED));

  const handleSignInComplete = async data => {
    const { event, globalInfo } = data.payload;
    if (event === HUB_EVENTS.GLOBAL_INFO.LOADED) {
      if (document.referrer.includes(loginUrl)) {
        await tracker.setUserDetails(globalInfo);
        tracker.track({ eventName: 'Login' });
        if (loginStartedTime.current) {
          const loginDurationMs = Date.now() - loginStartedTime.current;
          logger.track({
            event: 'Login Time',
            properties: {
              runTimeMS: loginDurationMs,
            },
          });
        }
      }
      Hub.remove(HUB_CHANNELS.GLOBAL_INFO, handleSignInComplete);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getBimi = async () => {
      await fetch(`/resolveBIMI?domain=https://echobox.com`);
    };
    getBimi();
  }, []);

  useEffect(() => {
    if (loginStartedTime.current) {
      cookie.deleteCookie(LOGIN_STARTED, {
        domain: url.getTLD(window.location.hostname),
      });
    }
  }, []);

  /**
   * UseEffect to check if a user has an active cognito session otherwise
   * attempt to get tokens from login app using iframe.
   * If the user is not signed in, redirect to login page.
   */
  useEffect(() => {
    const checkCognitoSession = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        logger.info(`Current session is not valid: ${error}`);
        if (CENTRALISED_LOGIN) {
          // listen for tokens from login ui
          window.onmessage = async event => {
            if (
              event.origin === loginUrl &&
              sessionStorage.getItem(IS_GETTING_TOKENS_KEY) === 'true'
            ) {
              const {
                data: { tokens, status },
              } = event;
              if (status === 401) {
                sessionStorage.setItem(IS_GETTING_TOKENS_KEY, false);
                window.location.href = loginUrl;
                return;
              }
              if (tokens) {
                sessionStorage.setItem(IS_GETTING_TOKENS_KEY, false);
                if (loginStartedTime.current) {
                  const loginTime = Date.now() - loginStartedTime.current;
                  logger.track({
                    event: 'Login Time - Page Loaded',
                    properties: {
                      runTimeMS: loginTime,
                    },
                  });
                }
                saveTokens(tokens);
                setIsGettingTokens(false);
              }
            } else if (
              event?.data?.source &&
              event.data.source !== 'react-devtools-bridge' &&
              event.data.source !== 'react-devtools-content-script'
            ) {
              logger.info(
                `Received message from unexpected origin: ${event.origin}`
              );
            }
          };
          // get tokens from login app
          sessionStorage.setItem(IS_GETTING_TOKENS_KEY, true);
          await utility.until(
            () => sessionStorage.getItem(IS_GETTING_TOKENS_KEY) === 'false',
            () => loginApp.postMessage(LOGIN_APP_MESSAGES.GET_TOKENS),
            100,
            IFRAME_MESSAGE_TIMEOUT
          );
          if (
            sessionStorage.getItem(IS_GETTING_TOKENS_KEY) === 'true' &&
            loginStartedTime.current
          ) {
            logger.error({
              event: 'SESSION_WRAPPER_CANNOT_GET_TOKENS',
            });
          }
        }
      } finally {
        sessionStorage.setItem(IS_GETTING_TOKENS_KEY, false);
        setIsGettingTokens(false);
      }
    };
    if (isGettingTokens) {
      checkCognitoSession();
    }
  }, [isGettingTokens]);

  /**
   * UseEffect to setup global info and client service token if a valid cognito session is found.
   */
  useEffect(() => {
    const setupData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const keyPrefix = `${user.keyPrefix}.${user.username}`;
        authentication.setKeyPrefix(keyPrefix);
        /* Check if client service token and global info are present */
        if (
          !authentication.isSignedIn() ||
          global.state !== GLOBAL_INFO_STATES.READY
        ) {
          /* Exchange access and id tokens for client service token */
          const clientServiceToken = await API.postServiceAuth();
          /* Extract user details, including permissions, from the two responses */
          const userDetail = authentication.getUserDetails(
            user,
            clientServiceToken
          );
          if (!userDetail.userType) {
            throw new ReferenceError(UI_MESSAGES.SIGNIN_NULL_USER_TYPE);
          }
          /* Store client service token */
          authentication.setClientServiceToken(clientServiceToken);

          /* Initialise global info */
          Hub.listen(HUB_CHANNELS.GLOBAL_INFO, handleSignInComplete);

          /* Fetch global info */
          actions.refreshGlobalInfo(GLOBAL_INFO_STATES.LOADING, {
            errorEvent: {
              channel: HUB_CHANNELS.GLOBAL_EVENTS,
              payload: { event: HUB_EVENTS.GLOBAL_EVENTS.FORCE_SIGNOUT },
            },
          });
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        if (
          CENTRALISED_LOGIN &&
          error?.noPermissions &&
          document.referrer.includes(loginUrl) &&
          loginStartedTime.current
        ) {
          // Set up No Permissions Cookie
          cookie.setCookieValue(
            `${NO_PERMISSIONS_PREFIX}${window.location.host}`,
            'true',
            {
              expires: NO_EXPIRY_DATE,
              domain: url.getTLD(window.location.hostname),
            }
          );
          cookie.deleteCookie(EBX_LAST_USED_PRODUCT_KEY, {
            domain: url.getTLD(window.location.hostname),
          });
          window.location.href = loginUrl;
          return;
        }
        if (error?.code === AXIOS_ERROR_CODES.BAD_REQUEST) {
          await API.authSignOut();
        }
        logger.error({
          event: 'Session Wrapper Error',
          error: object.stringifyKeys(error), // And here
        });
        /* Destroy client-side authentication details */
        authentication.signOut();
        /* Redirect to sign in page */
        if (CENTRALISED_LOGIN) {
          setIsLoading(false);
          history.push(ROUTE_REDIRECTIONS.SIGNOUT);
        } else {
          history.push(location.pathname);
          setIsLoading(false);
        }
      }
    };

    if (!isGettingTokens) {
      setupData();
    }
    // disabling dependency array rule as currently the actions are not wrapped in useCallback
    // so the useeffect keeps running in a loop when specifying dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGettingTokens]);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="md" />
      </Center>
    );
  }

  return <StateWrapper />;
}

export default SessionWrapper;
