import { useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
// import * as Yup from 'yup';

import EchoboxLogo from 'components/layout/EchoboxLogo';
import PasswordRules from 'components/users/PasswordRules';

import './SignUp.css';

function SignUp() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    console.log(data);
    console.log('SUBMIT');
    setRedirect(true);
  };

  const [redirect, setRedirect] = useState(false);

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const [passwordRules, setPasswordRules] = useState({
    containsTenCharacters: false,
    containsLowerCase: false,
    containsUpperCase: false,
    containsNumberCharacter: false,
    containsSpecialCharacter: false,
  });

  const [propertyName, setPropertyName] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = event => setEmailAddress(event.target.value);

  const handlePasswordChange = event => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    const containsUpperCase = /(?=.*[A-Z])/.test(newPassword);
    const containsLowerCase = /(?=.*[a-z])/.test(newPassword);
    const containsNumberCharacter = /^(?=.*[0-9])/.test(newPassword);
    const containsTenCharacters = newPassword.length >= 10;
    setPasswordRules({
      containsTenCharacters,
      containsLowerCase,
      containsUpperCase,
      containsNumberCharacter,
    });
  };

  const handlePasswordToggle = () => setShowPassword(value => !value);

  const handlePropertyChange = event => setPropertyName(event.target.value);

  // TODO: Formik Yup validation needs to be added here:
  // There were issues changing icon based on yup rules

  // const SignupSchema = Yup.object().shape({
  //   password: Yup.string()
  //     .required('No password provided!')
  //     .min(8, setPasswordRules(true))
  //     .matches(RegExp('(.*[a-z].*)'), setPasswordRules(true))
  //     .matches(RegExp('(.*[A-Z].*)'), setPasswordRules(true))
  //     .matches(RegExp('(.*\\d.*)'), setPasswordRules(true))
  //     .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), setPasswordRules(true)),
  // });

  return (
    <div>
      {redirect === true && <Redirect to="/campaigns" />}
      <EchoboxLogo />
      <div className="sign-up__card mx-auto my-3">
        <div className="font-weight-bold sign-up__card__header">
          Create your Email account
        </div>
        <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            controlId="propertyName"
            className="sign-up__card__inputs font-weight-bold"
          >
            <FormLabel>Property name</FormLabel>
            <FormControl
              name="propertyName"
              {...register('propertyName', { required: true })}
              className="rounded-0 border-dark"
              type="text"
              placeholder=""
              value={propertyName}
              onChange={handlePropertyChange}
            />
            {errors.propertyName && (
              <div className="error--text mt-1">Property Name is required!</div>
            )}
          </FormGroup>

          <FormGroup
            controlId="userEmail"
            className="sign-up__card__inputs font-weight-bold"
          >
            <FormLabel>Email address</FormLabel>
            <FormControl
              name="email"
              {...register('email', { required: true })}
              className="rounded-0 border-dark"
              type="email"
              placeholder=""
              value={emailAddress}
              onChange={handleEmailChange}
            />
            {errors.email && (
              <div className="error--text mt-1">Email is required!</div>
            )}
          </FormGroup>

          <FormGroup
            controlId="userPassword"
            className="sign-up__card__inputs font-weight-bold"
          >
            <Form.Label>Password</Form.Label>
            <Link
              id="show-password"
              className="float-right"
              onClick={handlePasswordToggle}
            >
              {showPassword ? 'Hide password' : 'Show password'}
            </Link>
            <Form.Control
              name="password"
              {...register('password', { required: true })}
              id="password"
              className="rounded-0 border-dark"
              type={showPassword ? 'text' : 'password'}
              placeholder=""
              value={password}
              onChange={handlePasswordChange}
            />
            {errors.password && (
              <div className="error--text mt-1">Password is required!</div>
            )}
          </FormGroup>

          <PasswordRules passwordRules={passwordRules} />

          <Button className="btn w-100 mt-3 p-2" variant="dark" type="submit">
            Create your Echobox account
          </Button>

          <FormLabel className="mt-3 label-text">
            By creating an Echobox account, you&apos;re agreeing to accept the
            Echobox Terms of Service
          </FormLabel>
        </Form>

        <hr />

        <div>
          <div className="d-inline pr-2 label-text">
            Already have an account?
          </div>
          <Link
            to="/signin"
            id="sign-in"
            className="font-weight-bold label-text"
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
