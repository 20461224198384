// We have to use a polyfill to include headers in the request
import { EventSourcePolyfill as EventSource } from 'event-source-polyfill';

import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import { apiBaseURL, APIS, getRequestHeaders } from 'api/settings';

interface getCampaignInsightsSourceProps {
  campaignURNs: string;
  startUnixTime?: number;
  endUnixTime?: number;
  onMessage: () => void;
}

export default function getCampaignInsightsSource({
  campaignURNs,
  startUnixTime,
  endUnixTime,
  onMessage,
}: getCampaignInsightsSourceProps) {
  const guid = metrics.start('getCampaignInsightsSource');

  const timeframeQueryParams =
    startUnixTime && endUnixTime
      ? `?startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}`
      : '';

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/campaigns/${campaignURNs}/insights${timeframeQueryParams}`;

  logger.info(
    `API:getCampaignInsightsSource /campaigns/${campaignURNs}/insights`
  );

  try {
    const source = new EventSource(url, {
      headers: getRequestHeaders(),
    });
    source.onmessage = onMessage;
    metrics.end('getCampaignInsightsSource', guid);
    return source;
  } catch (error) {
    metrics.fail('getCampaignInsightsSource', guid);
    throw error;
  }
}
