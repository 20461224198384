import PropTypes from 'prop-types';

import { Button } from '@ebx-ui/ebx-ui-component-library-sdk';

function LoadTemplateButton({ handleShow, isBusy }) {
  return (
    <Button variant="secondary" isDisabled={isBusy} onClick={handleShow}>
      Load Template
    </Button>
  );
}

LoadTemplateButton.propTypes = {
  handleShow: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
};

LoadTemplateButton.defaultProps = {
  isBusy: false,
};

export default LoadTemplateButton;
