import { Button, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as string from 'common/string';
import type { PropertyUser } from 'common/types';
import * as users from 'common/users';

import { PROPERTY_PERMISSION_TYPES } from 'common/enums';

interface UserAccessProps {
  entityNameSingular: string;
  entityNamePlural: string;
  handleEntitiesEdit: (user: PropertyUser) => () => void;
  isBusy: boolean;
  isCurrentUser: boolean;
  propertyURN: string;
  source?: string;
  user: PropertyUser;
}

function UserAccess({
  entityNameSingular,
  entityNamePlural,
  handleEntitiesEdit,
  isBusy,
  isCurrentUser,
  propertyURN,
  source,
  user,
}: UserAccessProps) {
  const permissionType = users.getPropertyPermission(
    propertyURN,
    user.permissions
  );

  const buttonMargin = source === 'AddUser' ? 3 : 0;

  if (
    isCurrentUser ||
    (permissionType as PROPERTY_PERMISSION_TYPES) ===
      PROPERTY_PERMISSION_TYPES.ADMIN
  ) {
    return (
      <Text size="sm" ml={buttonMargin}>
        All {`${string.toSentenceCase(entityNamePlural)}`}
      </Text>
    );
  }

  const entityPermissions = users.getPropertyCampaignPermissions(
    propertyURN,
    user.permissions
  );
  const entityLabel = `${entityPermissions.length} ${
    entityPermissions.length === 1
      ? string.toSentenceCase(entityNameSingular)
      : string.toSentenceCase(entityNamePlural)
  }`;

  return (
    <Button
      variant="secondary"
      height="9"
      isDisabled={isBusy}
      onClick={handleEntitiesEdit(user)}
      ml={buttonMargin}
    >
      {entityLabel}
    </Button>
  );
}

export default UserAccess;
