import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface DeleteSectionArgs {
  sectionURN: string;
}

export default async function deleteSection({ sectionURN }: DeleteSectionArgs) {
  if (isUndefined(sectionURN)) {
    mandatory('sectionURN');
  }

  const guid = metrics.start('deleteSection');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/sections/${sectionURN}`;

  const config = {
    url,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  logger.info(`API:deleteSection /sections/${sectionURN}`);

  try {
    const response = await axios(config);
    metrics.end('deleteSection', guid);
    return response.data;
  } catch (error) {
    metrics.fail('deleteSection', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/deleteSection',
            args: { sectionURN },
          })
        : error;
    throw apiError;
  }
}
