import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface PutSectionArgs {
  sectionURN: string;
  sectionDetails: object;
}

export default async function putSection({
  sectionURN,
  sectionDetails,
}: PutSectionArgs) {
  if (isUndefined(sectionURN)) {
    mandatory('sectionURN');
  }
  if (isUndefined(sectionDetails)) {
    mandatory('sectionDetails');
  }

  const guid = metrics.start('putSections');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/sections/${sectionURN}`;

  const config = {
    url,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      ...sectionDetails,
    }),
  };

  logger.info(`API:putSection /sections/${sectionURN}`);

  try {
    const response = await axios(config);
    metrics.end('putSection', guid);
    return response.data.sectionURN;
  } catch (error) {
    metrics.fail('putSection', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/putSection',
            args: { sectionDetails },
          })
        : error;
    throw apiError;
  }
}
