import { Box, Flex, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import Editor from 'components/common/Editor';
import { FooterCheckboxContext } from 'components/settings/campaigns/content/FooterCheckbox';

import * as campaigns from 'common/campaigns';

import useGlobalInfo from 'hooks/useGlobalInfo';

import './FooterFreeTextInput.css';

function FooterFreeTextInput({ value, onChange }) {
  const getInitialContentState = () => {
    const blocksFromHtml = htmlToDraft(value ?? '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  };

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(getInitialContentState())
  );
  const [linkError, setLinkError] = useState('');

  const show = useContext(FooterCheckboxContext);
  const globalInfo = useGlobalInfo();
  const { urn } = useParams();
  const campaignName = campaigns.getCampaignName(urn, globalInfo);

  const getEditorStateAsHtml = editor =>
    draftToHtml(convertToRaw(editor.getCurrentContent())).replace(/\n/g, '');

  const handleChange = newEditorState => {
    setEditorState(newEditorState);
    onChange(getEditorStateAsHtml(newEditorState));
  };

  if (!show || !campaignName) {
    return null;
  }

  return (
    <Flex alignItems="center" mt={3} gap={2} flexWrap="wrap">
      <Box className="footer-input" w="100%">
        <Editor
          campaignName={campaignName}
          editorState={editorState}
          onChange={handleChange}
          onLinkError={setLinkError}
        />
      </Box>
      {linkError.length > 0 && (
        <Text size="sm" color="red.600">
          {linkError}
        </Text>
      )}
    </Flex>
  );
}

FooterFreeTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FooterFreeTextInput;
