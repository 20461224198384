/* eslint react-hooks/exhaustive-deps: "off" */

import {
  Card,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  Toggle,
  Tooltip,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import { ReactComponent as Info } from 'assets/svg/info.svg';

import Loading from 'components/settings/Loading';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as url from 'common/url';
import * as zendesk from 'common/zendesk';

import useDebounce from 'hooks/useDebounce';
import useSettings from 'hooks/useSettings';
import useStaffMode from 'hooks/useStaffMode';
import { StoreContext } from 'store/store';

import {
  DEFAULT_DEBOUNCE_DELAY,
  PROPERTY_SETTING_TYPES,
} from 'common/constants';

import './Website.css';

function Website() {
  const { state } = useContext(StoreContext);
  const property = properties.getCurrentProperty(state.globalInfo);
  const propertyURN = properties.getCurrentPropertyURN(state.globalInfo);
  const toast = useToast();
  const settings = useSettings();

  const propertyRootURLRef = useRef();

  const [tempPropertyRootURL, setTempPropertyRootURL] = useState(
    property.propertyRootURL
  );
  const [hasFocus, setHasFocus] = useState(false);
  const [editPropertyRootURL, setEditPropertyRootURL] = useState(false);

  const [isStaffModeActive] = useStaffMode();

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  const debouncedPropertyRootURL = useDebounce(
    tempPropertyRootURL,
    DEFAULT_DEBOUNCE_DELAY
  );

  useEffect(() => {
    if (debouncedPropertyRootURL !== property.propertyRootURL) {
      logger.info(
        `Website:useEffect - saving debounced property root URL ${debouncedPropertyRootURL}`
      );

      handleSavePropertyRootURL(debouncedPropertyRootURL, () => {
        if (hasFocus) {
          setTimeout(() => {
            propertyRootURLRef.current.focus();
          }, 0);
        }
        setEditPropertyRootURL(false);
      });
    }
  }, [debouncedPropertyRootURL, property]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('Website:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const domainVerificationComplete = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
    'isComplete'
  );

  const handleToggleChange = async () => {
    const updatedValue = !domainVerificationComplete;
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
      settingKey: 'isComplete',
      settingValue: updatedValue,
      doNotSetChangedFlag: true,
    });
    // Try to save
    settings.requestSave();
    // Track change
    tracker.track({
      eventName: 'Domain Authentication Complete',
      trackingParams: {
        'Domain Authentication Complete': updatedValue,
      },
    });
  };

  useEffect(() => {
    if (editPropertyRootURL) propertyRootURLRef.current.focus();
    setHasFocus(true);
  }, [editPropertyRootURL, propertyRootURLRef]);

  const handleBlur = () => {
    setHasFocus(false);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleChangePropertyRootURL = event => {
    setTempPropertyRootURL(event.target.value);
  };

  const handleClickPropertyRootURL = () => {
    setEditPropertyRootURL(true);
  };

  const handleSavePropertyRootURL = async (debouncedRootURL, callback) => {
    try {
      await settings.savePropertyDetails({
        propertyURN,
        isStaffUser: isStaffModeActive,
        propertyRootURL: debouncedRootURL,
      });
      settings.requestGlobalInfo();
      toast({ variant: 'success', title: 'Updated property root URL' });
    } catch (error) {
      const errorMessage = errors.getErrorMessage(error);
      toast({ variant: 'error', title: errorMessage });
    }
    callback();
  };

  if (!settings || settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Heading variant="h2" mb="2">
        Website
      </Heading>
      <Text size="sm" color="gray.600" mb="8">
        The root domain of your website
      </Text>
      {isStaffModeActive && (
        <Card justifyContent="space-between" mb={8}>
          <Flex direction="column">
            <Flex gap="1.5" align="center">
              <Card.CardTitle>Domain verification complete</Card.CardTitle>
              <Tooltip
                label="Indicate that domain verification is now complete and the client can send live editions and emails"
                placement="top"
              >
                <Info />
              </Tooltip>
            </Flex>
            <Card.CardDescription mt={1}>
              Indicate that domain verification is now complete and the client
              can send live editions and emails
            </Card.CardDescription>
          </Flex>
          <Toggle
            labelPosition="left"
            disabled={settings.isLoading || settings.isSaving}
            isChecked={domainVerificationComplete}
            onChange={handleToggleChange}
          >
            {domainVerificationComplete ? 'On' : 'Off'}
          </Toggle>
        </Card>
      )}
      <FormControl
        disabled={!isStaffModeActive}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={propertyRootURLRef}
      >
        <FormControl.FormLabel>Root domain</FormControl.FormLabel>
        <Input
          disabled={!isStaffModeActive}
          name="rootdomain"
          type="text"
          value={
            isStaffModeActive
              ? tempPropertyRootURL
              : url.getDomain(tempPropertyRootURL)
          }
          onClick={handleClickPropertyRootURL}
          onChange={handleChangePropertyRootURL}
        />
      </FormControl>
      <Flex align="center" gap={1} mt="12px">
        <Text size="sm">To change, please</Text>
        <Button
          variant="link"
          className="p-0 border-0 contact-support"
          onClick={() => zendesk.openWidget()}
        >
          {' '}
          contact support
        </Button>
      </Flex>
    </>
  );
}

export default Website;
