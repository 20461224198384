/* eslint react-hooks/exhaustive-deps: "off" */

import Loading from 'components/campaigns/Loading';
import SettingsPanel from 'components/settings/campaigns/SettingsPanel';
import useSettings from 'hooks/useSettings';
import { useEffect } from 'react';
import { OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import {
  CAMPAIGN_SETTING_TYPES,
  OPT_IN_TYPES,
  SETTINGS_PANEL_VARIANTS,
} from 'common/constants';

import * as string from 'common/string';

import './Branding.css';

export default function Campaign() {
  const { urn } = useParams();

  const settings = useSettings();

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  const getBrandingPath = subpage =>
    `/settings/campaigns/${urn}/branding/${subpage}`;

  const isDoubleOptInEnabled = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.SUBSCRIPTION,
    'isDoubleOptInEnabled'
  );

  if (settings.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="campaign-title ebx-title-colour">Branding</div>
      <Link className="link-unstyled" to={getBrandingPath('general-styling')}>
        <SettingsPanel
          title="General Styling"
          description="Customize the general branding style for your email and subscription page"
        />
      </Link>

      <div className="ebx-h2 subheader-colour campaign-subheader-margin">
        Email
      </div>
      <Link className="link-unstyled" to={getBrandingPath('email-edition')}>
        <SettingsPanel title="Edition" />
      </Link>
      {!isDoubleOptInEnabled ? (
        <Link
          className="link-unstyled"
          to={getBrandingPath('email-confirmation')}
        >
          <SettingsPanel title="Confirmation" />
        </Link>
      ) : (
        <SettingsPanel
          variant={SETTINGS_PANEL_VARIANTS.DISABLED}
          title="Confirmation"
          label={optInLabel({
            campaignURN: urn,
            optInType: OPT_IN_TYPES.SINGLE,
          })}
        />
      )}
      {isDoubleOptInEnabled ? (
        <Link className="link-unstyled" to={getBrandingPath('email-verify')}>
          <SettingsPanel title="Verification" />
        </Link>
      ) : (
        <SettingsPanel
          variant={SETTINGS_PANEL_VARIANTS.DISABLED}
          title="Verification"
          label={optInLabel({
            campaignURN: urn,
            optInType: OPT_IN_TYPES.DOUBLE,
          })}
        />
      )}

      <div className="ebx-h2 subheader-colour campaign-subheader-margin">
        Subscription page
      </div>
      <Link className="link-unstyled" to={getBrandingPath('sub-default')}>
        <SettingsPanel title="Default" />
      </Link>
      {isDoubleOptInEnabled ? (
        <Link className="link-unstyled" to={getBrandingPath('sub-verify')}>
          <SettingsPanel title="Verification" />
        </Link>
      ) : (
        <SettingsPanel
          variant={SETTINGS_PANEL_VARIANTS.DISABLED}
          title="Verification"
          label={optInLabel({
            campaignURN: urn,
            optInType: OPT_IN_TYPES.DOUBLE,
          })}
        />
      )}
      <Link className="link-unstyled" to={getBrandingPath('sub-success')}>
        <SettingsPanel title="Success" />
      </Link>
      <Link className="link-unstyled" to={getBrandingPath('sub-unsubscribe')}>
        <SettingsPanel title="Unsubscribe" />
      </Link>
    </>
  );
}

function optInLabel({ campaignURN, optInType }) {
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 600 }}
      placement="bottom"
      overlay={
        <Popover
          id="popover-basic"
          className="opt-in-popover px-3"
          style={{ maxWidth: 420 }}
        >
          <Popover.Content className="ebx-small-text ebx-roboto">
            <Row className="ebx-h3 ebx-title-colour my-1">
              {`${string.capitalise(optInType)} opt-in is required`}
            </Row>
            <Row className="my-1">
              {`To customise and use the ${optInType} opt-in functionality, you need to
              activate it in the settings`}
            </Row>
            <Row className="my-1">
              <Link to={`/settings/campaigns/${campaignURN}/sign-up-forms`}>
                <span className="ebx-h3">{`Activate ${optInType} opt-in`}</span>
              </Link>
            </Row>
          </Popover.Content>
        </Popover>
      }
    >
      <div className="ebx-label-disabled ml-2 px-2 py-1 ebx-h3 d-flex align-items-center opt-in-label">
        {`${string.capitalise(optInType)} opt-in only`}
      </div>
    </OverlayTrigger>
  );
}
