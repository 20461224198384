import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import MetricsContainer from 'components/campaigns/analytics/MetricsContainer';
import SubscriberEngagement from 'components/campaigns/analytics/SubscriberEngagement';
import MetricCard from 'components/common/MetricCard';

import * as analytics from 'common/analytics';
import { Campaign } from 'common/types';
import useAnalyticsData from 'hooks/useAnalyticsData';

interface StatisticsProps {
  campaign: Campaign;
}

function Statistics({ campaign }: StatisticsProps) {
  const { numEditions, subscriberInsights, nosInsights, isLoading } =
    useAnalyticsData(campaign);

  const openRate = isLoading
    ? null
    : analytics.calculateOpenRateMetric(
        nosInsights?.current,
        nosInsights?.reference
      );

  const clickRate = isLoading
    ? null
    : analytics.calculateClickRateMetric(
        nosInsights?.current,
        nosInsights?.reference
      );

  const subscribers = isLoading
    ? null
    : analytics.calculateSubscriberMetric(subscriberInsights?.current);

  const deliveries = isLoading
    ? null
    : analytics.calculateDeliveriesMetric(
        nosInsights?.current,
        nosInsights?.reference,
        numEditions
      );

  const unsubscribeRate = isLoading
    ? null
    : analytics.calculateUnsubscribeRateMetric(
        nosInsights?.current,
        nosInsights?.reference,
        subscriberInsights?.current,
        subscriberInsights?.reference
      );

  const hardBounceRate = isLoading
    ? null
    : analytics.calculateHardBounceRateMetric(
        nosInsights?.current,
        nosInsights?.reference
      );

  const softBounceRate = isLoading
    ? null
    : analytics.calculateSoftBounceRateMetric(
        nosInsights?.current,
        nosInsights?.reference
      );

  return (
    <Flex direction="column" gap={4} flexBasis="0">
      <MetricsContainer>
        <MetricCard
          label="Open rate"
          tooltip="Number of individuals who opened your emails divided by the number of emails delivered in your Campaign"
          metric={openRate}
        />
        <MetricCard
          label="Click rate"
          tooltip="Number of individuals who clicked a link in your emails divided by the number of emails delivered in your Campaign"
          metric={clickRate}
        />
        <MetricCard
          label="Subscribers"
          tooltip="Number of individuals who currently subscribe to your Campaign, and the subscribers gained or lost"
          metric={subscribers}
        />
      </MetricsContainer>
      <Flex gap={4}>
        <SubscriberEngagement campaign={campaign} />
        <Flex direction="column" gap={4} flexBasis="50%" flexGrow={0}>
          <MetricsContainer>
            <MetricCard
              size="sm"
              label="Deliveries"
              tooltip="Total emails delivered to subscribers"
              metric={deliveries}
            />
            <MetricCard
              size="sm"
              label="Unsubscribe rate"
              tooltip="Number of individuals who have unsubscribed divided by the number of emails delivered in your Campaign"
              metric={unsubscribeRate}
              isPositive={false}
            />
          </MetricsContainer>
          <MetricsContainer>
            <MetricCard
              size="sm"
              label="Hard bounce rate"
              tooltip="Number of individuals whose emails have hard bounced divided by the number of emails delivered. This is due to a permanent error such as an invalid email address or spam filter"
              metric={hardBounceRate}
              isPositive={false}
            />
            <MetricCard
              size="sm"
              label="Soft bounce rate"
              tooltip="Number of individuals whose emails have soft bounced divided by the number of emails delivered. This is a temporary error, such as someone having a full inbox"
              metric={softBounceRate}
              isPositive={false}
            />
          </MetricsContainer>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Statistics;
