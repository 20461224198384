import { useQuery } from 'react-query';

import * as API from 'api/api';

import { QUERY_CACHE_VALUES } from 'common/constants';
import * as datetime from 'common/datetime';
import { EDITION_STATE } from 'common/enums';
import { Campaign } from 'common/types';

function GetNumEditions(campaign: Campaign) {
  const { isLoading, data: numEditions } = useQuery(
    ['numEditions', campaign.campaignURN],
    async () =>
      API.getEditionList({
        campaignURN: campaign.campaignURN,
        startUnixTime: datetime.NOW - datetime.DAYS(30),
        endUnixTime: datetime.NOW,
        filterByState: EDITION_STATE.SENT,
      }),
    {
      staleTime: QUERY_CACHE_VALUES.ANALYTICS.STALE_TIME,
      cacheTime: QUERY_CACHE_VALUES.ANALYTICS.CACHE_TIME,
      select: data => data.length,
    }
  );

  return { numEditions, isLoading };
}

function GetSubscriberInsights(campaign: Campaign) {
  const { isLoading, data: subscriberInsights } = useQuery(
    ['subscriberInsights', campaign.campaignURN],
    async () => {
      // Get last 30 days first
      const currentInsightsMapPromise = API.getCampaignInsights({
        campaignURNs: campaign.campaignURN,
        startUnixTime: datetime.NOW - datetime.DAYS(30),
        endUnixTime: datetime.NOW,
      });
      // Get 30 previous days
      const referenceInsightsMapPromise = API.getCampaignInsights({
        campaignURNs: campaign.campaignURN,
        startUnixTime: datetime.NOW - datetime.DAYS(60),
        endUnixTime: datetime.NOW - datetime.DAYS(30),
      });
      return Promise.all([
        currentInsightsMapPromise,
        referenceInsightsMapPromise,
      ]);
    },
    {
      staleTime: QUERY_CACHE_VALUES.ANALYTICS.STALE_TIME,
      cacheTime: QUERY_CACHE_VALUES.ANALYTICS.CACHE_TIME,
      select: data => ({
        current: data[0].get(campaign.campaignURN),
        reference: data[1].get(campaign.campaignURN),
      }),
    }
  );

  return { subscriberInsights, isLoading };
}

function GetNosInsights(campaign: Campaign) {
  const { isLoading, data: nosInsights } = useQuery(
    ['nosInsights', campaign.campaignURN],
    async () => {
      // Get last 30 days first
      const currentNOSInsightsDataPromise = API.getCampaignPerformance({
        campaignURN: campaign.campaignURN,
        startUnixTime: datetime.NOW - datetime.DAYS(30),
        endUnixTime: datetime.NOW,
      });

      // Get 30 previous days
      const referenceNOSInsightsDataPromise = API.getCampaignPerformance({
        campaignURN: campaign.campaignURN,
        startUnixTime: datetime.NOW - datetime.DAYS(60),
        endUnixTime: datetime.NOW - datetime.DAYS(30),
      });
      return Promise.all([
        currentNOSInsightsDataPromise,
        referenceNOSInsightsDataPromise,
      ]);
    },
    {
      staleTime: QUERY_CACHE_VALUES.ANALYTICS.STALE_TIME,
      cacheTime: QUERY_CACHE_VALUES.ANALYTICS.CACHE_TIME,
      select: data => ({
        current: data[0],
        reference: data[1],
      }),
    }
  );

  return { nosInsights, isLoading };
}

const useAnalyticsData = (campaign: Campaign) => {
  const { numEditions, isLoading: isNumEditionsLoading } =
    GetNumEditions(campaign);

  const { subscriberInsights, isLoading: isSubscriberInsightsLoading } =
    GetSubscriberInsights(campaign);

  const { nosInsights, isLoading: isNOSInsightsLoading } =
    GetNosInsights(campaign);

  return {
    numEditions,
    subscriberInsights,
    nosInsights,
    isLoading:
      isNumEditionsLoading ||
      isSubscriberInsightsLoading ||
      isNOSInsightsLoading,
  };
};

export default useAnalyticsData;
