import { Dropdown } from 'react-bootstrap';

import BodyElement from 'components/settings/campaigns/content/BodyElement';

import { ReactComponent as DragHandle } from 'assets/svg/drag-handle.svg';
import { ReactComponent as Ellipsis } from 'assets/svg/ellipsis.svg';

import './Body.css';

interface BodySectionProps {
  sectionName: string;
  targetArticles: number;
  onOpenModal: (index: number) => void;
  index: number;
  onDelete: (section: string) => void;
  sectionURN: string;
}

function BodySection({
  sectionName,
  targetArticles,
  onOpenModal,
  index,
  onDelete,
  sectionURN,
}: BodySectionProps) {
  return (
    <BodyElement>
      <div className="d-flex align-items-center">
        <div className="drop-handle mr-2">
          <DragHandle />
        </div>
        <div className="ebx-h2 ebx-text-overflow-ellipsis ebx-whitescape-nowrap ebx-title-colour">
          {sectionName}
        </div>
        <div className="ml-auto d-flex align-items-center">
          <div className="ebx-h3 ebx-label-info px-2 py-1 article-pill mx-3">
            {targetArticles} article
            {targetArticles === 1 ? '' : 's'}
          </div>
          <Dropdown>
            <Dropdown.Toggle
              className="p-0 border-0 section-dropdown-toggle"
              bsPrefix="p-0" // Removes the caret
            >
              <Ellipsis />
            </Dropdown.Toggle>

            <Dropdown.Menu className="ebx-profile-menu">
              <Dropdown.Item
                className="ebx-profile-option px-3 py-2"
                onClick={() => onOpenModal(index)}
              >
                <span className="ebx-h3 body-promotion-link">Edit</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="ebx-profile-option px-3 py-2"
                onClick={() => onDelete(sectionURN)}
              >
                <span className="ebx-h3 body-promotion-delete">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </BodyElement>
  );
}

export default BodySection;
