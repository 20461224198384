import { Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import TemplateCard from 'components/common/template/TemplateCard';

function TemplateManagement() {
  return (
    <Flex flexDir="column" gap={8}>
      <Heading>Templates</Heading>
      <Flex gap={4} flexWrap="wrap">
        <TemplateCard name={null} isLoading />
        <TemplateCard
          lastUpdatedUnixTime={1682415145}
          name="Newsletter"
          thumbnail="/assets/img/newsletter-thumbnail.png"
        />
        <TemplateCard
          lastUpdatedUnixTime={1681415145}
          name="Promotion"
          thumbnail="/assets/img/promotion-thumbnail.png"
        />
        <TemplateCard
          lastUsedUnixTime={1682415145}
          lastUpdatedUnixTime={1680415145}
          name="Breaking news"
          thumbnail="/assets/img/breaking-thumbnail.png"
        />
        <TemplateCard name={null} newTemplate />
      </Flex>
    </Flex>
  );
}

export default TemplateManagement;
