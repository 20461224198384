import {
  Button,
  Flex,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import Error from 'components/editor/Error';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { ReactComponent as Ellipsis } from 'assets/svg/ellipsis.svg';

import * as api from 'api/api';
import * as errors from 'common/errors';
import * as tracker from 'common/tracker';

function UserRemove({ emailAddress, fullName, onRemoveUser, propertyURN }) {
  const [showModal, setShowModal] = useState(false);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetErrorMessage = () => setErrorMessage('');

  const handleShow = () => {
    resetErrorMessage();
    setShowModal(true);
  };

  const handleHide = () => {
    if (!isRemovingUser) {
      setShowModal(false);
    }
  };

  const handleRemoveUser = async () => {
    setIsRemovingUser(true);

    try {
      await api.deletePropertiesUser({
        emailAddress,
        propertyURN,
      });
      tracker.track({
        eventName: 'Remove User',
        trackingParams: {
          'Removed user email': emailAddress,
        },
        options: {
          includeCampaignDetails: false,
        },
      });
      onRemoveUser(emailAddress);
      setIsRemovingUser(false);
      handleHide();
    } catch (error) {
      setErrorMessage(errors.getErrorMessage(error));
      setIsRemovingUser(false);
    }
  };

  return (
    <>
      <Dropdown className="remove-user-dropdown">
        <Dropdown.Toggle
          variant="link"
          className="p-0 ml-3 border-0"
          bsPrefix="p-0"
        >
          <Ellipsis />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="ebx-menu-dropdown">
          <Dropdown.Item className="ebx-h3" onClick={handleShow}>
            Remove user
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal isOpen={showModal} onClose={handleHide} size="small">
        <Modal.Header>
          <Modal.Title>Remove user</Modal.Title>
        </Modal.Header>
        {errorMessage !== '' && (
          <Error
            errorMessage={errorMessage}
            handleErrorClose={resetErrorMessage}
          />
        )}
        <Modal.Body>
          <Flex>
            <Text size="sm">
              {' '}
              Are you sure you want to remove{' '}
              <span className="font-weight-bold">{fullName}</span> from your
              team?
            </Text>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleHide}
            isDisabled={isRemovingUser}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleRemoveUser}
            isDisabled={isRemovingUser}
          >
            Remove {fullName}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

UserRemove.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  propertyURN: PropTypes.string.isRequired,
};

export default UserRemove;
