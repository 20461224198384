import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function authSignOut() {
  const guid = metrics.start('authSignOut');
  logger.info('API:authSignOut');

  try {
    const isStaffUser = await authentication.isStaffUser();
    try {
      await Auth.signOut({ global: !isStaffUser });
    } catch (error) {
      await Auth.signOut();
    }
    metrics.end('authSignOut', guid);
    return;
  } catch (error) {
    metrics.fail('authSignOut', guid);
    throw errors.handleCognitoError({
      originalError: error,
      errorLocation: 'api/authSignOut',
    });
  }
}
