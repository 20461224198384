/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react';

import { Flex, Heading, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import CreateNew from 'components/campaigns/CreateNew';
import SmartCurationLoadingModal from 'components/campaigns/editions/SmartCurationLoadingModal';
import ExportAnalyticsModal from 'components/campaigns/export/ExportAnalyticsModal';
import ExportPropertyAnalyticsModal from 'components/campaigns/ExportPropertyAnalyticsModal';
import ManageSubscribers from 'components/campaigns/ManageSubscribers';
import Subscribers from 'components/campaigns/subscribers/Insights';
import Upload from 'components/campaigns/upload/Upload';
import Modes from 'components/common/Modes';
import OptimisationLevel from 'components/editor/curate/OptimisationLevel';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as errors from 'common/errors';
import * as intervals from 'common/intervals';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import useDeveloperMode from 'hooks/useDeveloperMode';
import useGlobalInfo from 'hooks/useGlobalInfo';

import './Header.css';

import { REFRESH_MULTIPLIERS } from 'common/config';
import {
  CAMPAIGN_SETTING_TYPES,
  EDITION_FIELDS,
  EDITION_POPULATION_LOADING_SECS,
  MARKETING_EMAIL_TYPES,
  WYSIWYG_EMAIL_DEFAULT_CONTENT,
} from 'common/constants';
import {
  EDITION_APPROVAL_STATES,
  EDITION_STATE,
  EDITION_SUBJECT_TYPES,
  SERVER_UPLOAD_STATE,
  SUBSCRIBER_MODAL_STATES,
} from 'common/enums';
import Automation from './automation/Automation';

function Header({
  campaignDetails,
  campaignInsights,
  draftEmails,
  handleClose,
  handleReset,
  isModalActive,
  isShutOff,
  localState,
  scheduledEmails,
  sentEmails,
  setLocalState,
}) {
  const globalInfo = useGlobalInfo();

  const [fileUpload, setFileUpload] = useState(null);

  /* State of Create New Edition Button */
  const [isGeneratingEdition, setIsGeneratingEdition] = useState(false);
  const [isEditionPopulated, setEditionPopulated] = useState(false);
  const [editionURN, setEditionURN] = useState('');

  const toast = useToast();

  const [isDeveloperModeActive] = useDeveloperMode('NL-1802'); // Export Property-Level Analytics

  const history = useHistory();

  const handleNewMarketingEmail = emailType => {
    const createMarketingEmail = async () => {
      setIsGeneratingEdition(true);
      const content =
        emailType === MARKETING_EMAIL_TYPES.HTML
          ? ''
          : WYSIWYG_EMAIL_DEFAULT_CONTENT;
      try {
        const marketingTemplateURN = await API.postMarketingTemplates({
          campaignURN: campaignDetails.campaignURN,
          content,
        });
        const createdEditionURN = await API.postEditions({
          campaignURN: campaignDetails.campaignURN,
          editionDetails: {
            approvalState: EDITION_APPROVAL_STATES.ECHOBOX_APPROVED,
            editionSubject: editions.getEditionSubject(
              campaignDetails,
              new Date(
                (datetime.getUnixTimestamp() + datetime.HOURS(1)) * 1000
              ),
              EDITION_SUBJECT_TYPES.NAME_DDMONTH
            ),
            populate: false,
            templateURN: marketingTemplateURN,
          },
        });
        tracker.track({
          eventName: 'Create New Email',
          trackingParams: {
            'Email ID': createdEditionURN,
            'Email Type': 'Marketing Email',
            'Email Sub Type': emailType,
          },
        });
        history.push(`/marketing/${createdEditionURN}/edit`);
      } catch (error) {
        toast({ variant: 'error', title: errors.getErrorMessage(error) });
      } finally {
        setIsGeneratingEdition(false);
      }
    };
    createMarketingEmail();
  };

  const handleNewPopulatedEdition = () => {
    logger.info('Header:handleNewPopulatedEdition');
    const createPopulatedEdition = async () => {
      setIsGeneratingEdition(true);
      setEditionURN('');
      setEditionPopulated(false);
      try {
        const subjectType =
          campaigns.getCurrentCampaignSettingValue(
            globalInfo,
            CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
            'subjectType'
          ) ?? EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE;
        const inAnHour = datetime.getUnixTimestamp() + 60 * 60;
        const date = new Date(inAnHour * 1000);
        const subject = editions.getEditionSubject(
          campaignDetails,
          date,
          subjectType
        );

        // New Manually created editions have an optimal schedule by default
        const optimalRange = editions.getOptimalTimeslot(new Date());

        const createdEditionURN = await API.postEditions({
          campaignURN: campaignDetails.campaignURN,
          editionDetails: {
            approvalState: EDITION_APPROVAL_STATES.ECHOBOX_APPROVED,
            editionSubject: subject,
            scheduledUnixTime: optimalRange.optimalStartTime,
            sendingTimeslotSizeSeconds:
              optimalRange.optimalEndTime - optimalRange.optimalStartTime,
            populate: true,
          },
        });
        setEditionURN(createdEditionURN);
        tracker.track({
          eventName: 'Create New Email',
          trackingParams: {
            'Email ID': createdEditionURN,
            'Email Type': 'Newsletter',
          },
        });
        setIsGeneratingEdition(false);
      } catch (error) {
        console.log(error);
        setIsGeneratingEdition(false);
      }
    };
    createPopulatedEdition();
    setSmartLoadingOpen(true);
  };

  /* Open/closed state of smart loading modal */
  const [isSmartLoadingOpen, setSmartLoadingOpen] = useState(false);

  useEffect(() => {
    const fetchEdition = async () => {
      logger.info('Header:fetchEdition');
      const editionData = await API.getEditions({
        editionURNs: [editionURN],
        fieldList: EDITION_FIELDS.REVIEW,
      });
      const editionDetail = editionData[0];
      const { editionState } = editionDetail;
      if (editionState !== EDITION_STATE.POPULATING) {
        setEditionPopulated(true);
      }
    };

    const fallbackFinish = () => {
      tracker.track({
        eventName: 'Edition Population Timeout',
        trackingParams: {
          'Email ID': editionURN,
        },
      });

      // Need a better way to refresh editions
      window.location.reload(false);
    };

    let fallbackFinishTimer = null;

    if (editionURN && editionURN !== '' && !isEditionPopulated) {
      fetchEdition();
      intervals.setItem(
        'fetchEdition',
        fetchEdition,
        REFRESH_MULTIPLIERS.FETCH_EDITION
      );

      // If we reach the maximum population loading time, fallback finish is triggered
      fallbackFinishTimer = setTimeout(
        fallbackFinish,
        EDITION_POPULATION_LOADING_SECS * 1000
      );
    }

    return () => {
      intervals.removeItem('fetchEdition');
      clearTimeout(fallbackFinishTimer);
    };
  }, [editionURN, isEditionPopulated, setEditionPopulated]);

  const handleCloseSmartModal = () => {
    setSmartLoadingOpen(false);
    tracker.track({
      eventName: 'Cancel Smart Loading',
      trackingParams: {
        'Email ID': editionURN,
      },
    });

    // Need a better way to refresh editions
    window.location.reload(false);
  };

  /* Open/closed state of export analytics modal */
  const [isExportAnalyticsModalOpen, setExportAnalyticsModalOpen] =
    useState(false);

  useEffect(() => {
    const fetchUploads = async () => {
      logger.info('Header:fetchUploads');
      const uploadURNs = await API.getSubscriberUploadList({
        campaignURN: campaignDetails.campaignURN,
        uploadStates: Object.values(SERVER_UPLOAD_STATE),
      });
      // TODO: If we don't check if uploadURNs exist this will error out.
      if (uploadURNs && uploadURNs.length > 0) {
        const uploadDetails = await API.getSubscriberUploads({
          campaignURN: campaignDetails.campaignURN,
          uploadURN: uploadURNs[0],
        });
        setFileUpload(uploadDetails);
      }
    };

    if (!isShutOff) {
      fetchUploads();
      intervals.setItem(
        'fetchUploads',
        fetchUploads,
        REFRESH_MULTIPLIERS.FETCH_UPLOADS
      );
    }

    return () => {
      intervals.removeItem('fetchUploads');
    };
  }, []);

  const handleError = message => {
    toast({ variant: 'error', title: message });
  };

  let editionDetails = null;
  if (scheduledEmails.length > 0) {
    editionDetails = editions.getFirstEdition(scheduledEmails);
  } else if (draftEmails.length > 0) {
    editionDetails = editions.getFirstEdition(draftEmails);
  } else if (sentEmails.length > 0) {
    editionDetails = editions.getFirstEdition(sentEmails);
  }

  return (
    <div className="ebx-background-colour">
      <div>
        {campaignDetails && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            pb={4}
          >
            <Flex align="center" gap="3">
              <Heading variant="h2">{campaignDetails.campaignName}</Heading>
              {!isShutOff && (
                <OptimisationLevel editionDetails={editionDetails} />
              )}
              <div className="ml-2">
                <Modes />
              </div>
            </Flex>
            <Flex gap={2} alignItems="center">
              <ManageSubscribers
                campaignInsights={campaignInsights}
                isShutOff={isShutOff}
              />
              <CreateNew
                campaignURN={campaignDetails.campaignURN}
                handleNewEdition={handleNewPopulatedEdition}
                handleNewMarketing={handleNewMarketingEmail}
                isDisabled={isGeneratingEdition || isShutOff}
              />
            </Flex>
          </Flex>
        )}
      </div>
      <div>
        {campaignDetails && (
          <Subscribers
            campaignDetails={campaignDetails}
            campaignInsights={campaignInsights}
            isShutOff={isShutOff}
            latestUpload={fileUpload}
            localState={localState}
          />
        )}
      </div>
      <div>
        <Automation
          campaignURN={campaignDetails.campaignURN}
          isShutOff={isShutOff}
        />
      </div>
      <div className="d-none">
        {isModalActive && (
          <Upload
            campaignURN={campaignDetails.campaignURN}
            handleClose={handleClose}
            handleError={handleError}
            handleReset={handleReset}
            hasActiveSubscribers={campaignInsights.activeSubscribers > 0}
            localState={localState}
            modalState={SUBSCRIBER_MODAL_STATES.SUBSCRIBE}
            setLocalState={setLocalState}
          />
        )}
      </div>
      {isExportAnalyticsModalOpen && !isDeveloperModeActive && (
        <ExportPropertyAnalyticsModal
          isOpen={isExportAnalyticsModalOpen}
          onClose={() => setExportAnalyticsModalOpen(false)}
        />
      )}
      {isExportAnalyticsModalOpen && isDeveloperModeActive && (
        <ExportAnalyticsModal
          isOpen={isExportAnalyticsModalOpen}
          onClose={() => setExportAnalyticsModalOpen(false)}
        />
      )}
      {isSmartLoadingOpen && (
        <SmartCurationLoadingModal
          editionURN={editionURN}
          isOpen={isSmartLoadingOpen}
          isFinished={isEditionPopulated}
          onClose={handleCloseSmartModal}
        />
      )}
    </div>
  );
}

Header.propTypes = {
  // TODO - implement using TypeScript
  campaignDetails: PropTypes.shape({
    campaignURN: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    createdByEmail: PropTypes.string.isRequired,
    createdUnixTime: PropTypes.number.isRequired,
    lastUpdatedByEmail: PropTypes.string,
    lastUpdatedUnixTime: PropTypes.number,
  }).isRequired,
  campaignInsights: PropTypes.shape({
    activeSubscribers: PropTypes.number.isRequired,
    hardBouncedSubscribers: PropTypes.number.isRequired,
    complainedSubscribers: PropTypes.number.isRequired,
    unsubscribedSubscribers: PropTypes.number.isRequired,
    lastRetrievedUnixTime: PropTypes.number,
  }),
  draftEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  handleClose: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isModalActive: PropTypes.bool.isRequired,
  isShutOff: PropTypes.bool.isRequired,
  localState: PropTypes.string,
  scheduledEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  sentEmails: PropTypes.arrayOf(
    PropTypes.shape({ editionURN: PropTypes.string })
  ),
  setLocalState: PropTypes.func.isRequired,
};

Header.defaultProps = {
  campaignInsights: null,
  draftEmails: [],
  localState: null,
  scheduledEmails: [],
  sentEmails: [],
};

export default React.memo(Header);
