import { Navbar } from 'react-bootstrap';

import { ReactComponent as EchoboxLogoText } from 'assets/svg/echobox-logo-text.svg';
import { ReactComponent as EchoboxLogo } from 'assets/svg/echobox-logo.svg';

import GenericError from '../GenericError';

function HeaderError() {
  return (
    <Navbar
      collapseOnSelect
      variant="light"
      expand="lg"
      fixed="top"
      className="--header__height ebx-background-colour w-100 justify-content-center justify-content-md-between"
    >
      <Navbar.Brand className="px-2 brand__logo mr-2 ebx-header-left">
        <EchoboxLogo />
        <EchoboxLogoText className="ml-2" />
      </Navbar.Brand>
      <div className="ebx-header-right d-none d-md-block">
        <div className="mr-2 ml-md-auto">
          <GenericError />
        </div>
      </div>
    </Navbar>
  );
}

export default HeaderError;
