import {
  Button,
  Card,
  Flex,
  Text,
  WarningIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React from 'react';

import LatestUpload from 'components/campaigns/subscribers/LatestUpload';

import { SubscriberUpload } from 'common/types';

interface NoSubscribersProps {
  latestUpload: SubscriberUpload;
  openAnalytics: () => void;
}

function NoSubscribers({ latestUpload, openAnalytics }: NoSubscribersProps) {
  return (
    <Card py={6} borderBottomRadius={0} justifyContent="space-between">
      <Flex gap={6}>
        <Flex direction="column" gap={2}>
          <Text size="sm">Subscribers</Text>
          <Flex alignItems="center" gap={2}>
            <WarningIcon size={5} />
            <Text size="sm">Please add subscribers to this Campaign.</Text>
          </Flex>
        </Flex>
        <LatestUpload latestUpload={latestUpload} />
      </Flex>
      <Button variant="link" onClick={openAnalytics}>
        View analytics
      </Button>
    </Card>
  );
}

export default React.memo(NoSubscribers);
