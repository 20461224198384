/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import {
  Flex,
  FormControl,
  Heading,
  Input,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  DEFAULT_DEBOUNCE_DELAY,
  EDITION_APPROVAL_STATE_NAMES,
} from 'common/constants';
import * as sanitise from 'common/sanitise';
import * as tracker from 'common/tracker';
import { Edition } from 'common/types';

import PreviewTextTooltip from 'components/common/PreviewTextTooltip';

import useDebounce from 'hooks/useDebounce';

interface PreviewTextProps {
  edition: Edition;
  updateEdition: (
    updatedFields: {},
    options?: { updatePreview: boolean; refreshCurate: boolean }
  ) => Edition;
}

function PreviewText({ edition, updateEdition }: PreviewTextProps) {
  const [currentPreviewText, setCurrentPreviewText] = useState(
    sanitise.noHTML(edition?.emailPreviewText ?? '')
  );
  const toast = useToast();

  const debouncedPreviewText = useDebounce(
    currentPreviewText,
    DEFAULT_DEBOUNCE_DELAY
  );

  const handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
    setCurrentPreviewText(event.currentTarget.value);
  };

  useEffect(() => {
    const updatePreviewText = async () => {
      try {
        const fieldsToUpdate = {
          emailPreviewText: sanitise.noHTML(debouncedPreviewText),
        };

        const updatedEdition = await updateEdition(fieldsToUpdate, {
          updatePreview: false,
          refreshCurate: false,
        });

        const sanitisedPreviewText = sanitise.noHTML(currentPreviewText);

        // After saving the preview text, we fetch the edition details from the backend to make sure it saved successfully.
        // If the backend and frontend don't agree on the most current preview text, the backend takes precedence.
        if (sanitisedPreviewText !== updatedEdition.emailPreviewText) {
          setCurrentPreviewText(updatedEdition.emailPreviewText ?? '');
          toast({ variant: 'light', title: 'Preview text was out of sync' });
        } else {
          toast({ variant: 'success', title: 'Preview text saved' });
          setCurrentPreviewText(sanitisedPreviewText ?? '');
        }
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Request to save preview text failed',
        });
      } finally {
        tracker.track({
          eventName: 'Edit Preview Text',
          trackingParams: {
            'Email ID': edition.editionURN,
            'Edition State Before': edition.approvalState
              ? EDITION_APPROVAL_STATE_NAMES[edition.approvalState]
              : 'Unknown',
            'Preview Text Before': edition.emailPreviewText,
            'Preview Text': debouncedPreviewText,
          },
        });
      }
    };
    // Only update if the Edition preview text and the current preview text don't match
    if (currentPreviewText !== (edition.emailPreviewText ?? '')) {
      updatePreviewText();
    }
  }, [debouncedPreviewText]);

  return (
    <FormControl>
      <Flex display="flex" alignItems="center" gap={2} mb={3}>
        <Heading variant="h3">Preview text</Heading>
        <PreviewTextTooltip marginLeft={10} />
      </Flex>
      <Input
        size="lg"
        fontSize="14px"
        placeholder="Add text"
        _placeholder={{
          fontSize: '14px',
          opacity: 1,
          color: 'gray.600',
        }}
        _hover={{
          boxShadow:
            '0px 1px 2px rgba(14, 30, 57, 0.12), 0px 4px 8px rgba(14, 30, 57, 0.08);',
        }}
        value={currentPreviewText ?? ''}
        onChange={handleChange}
        name="previewText"
        maxLength={998}
      />
      {!currentPreviewText && (
        <FormControl.FormHelperText mt={3}>
          If you don&apos;t add preview text, the first lines of copy in your
          email will be displayed
        </FormControl.FormHelperText>
      )}
    </FormControl>
  );
}

export default PreviewText;
