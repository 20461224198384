import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';

import type { PropertyUser } from 'common/types';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface GetPropertyUsersArgs {
  propertyURN?: string;
}

export default async function getPropertyUsers({
  propertyURN,
}: GetPropertyUsersArgs) {
  if (isUndefined(propertyURN)) {
    throw new ReferenceError(`Missing parameter propertyURN`);
  }

  const guid = metrics.start('getPropertyUsers');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/users`;
  const config: AxiosRequestConfig = {
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getPropertyUsers /properties/${propertyURN}/users`);

  try {
    const { data } = await axios.get<{ entries: PropertyUser[] }>(url, config);
    metrics.end('getPropertyUsers', guid);
    return data.entries;
  } catch (error) {
    metrics.fail('getPropertyUsers', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getPropertyUsers',
            args: { propertyURN },
          })
        : error;

    throw apiError;
  }
}
