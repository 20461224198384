import * as environment from 'common/environment';

// AWS Amplify credentials
const AWS_AMPLIFY_DEFAULTS = {
  REGION: 'eu-west-1',
  USER_POOL_ID: 'eu-west-1_iUkO72I1J',
  WEB_CLIENT_ID_STAGE: '1m7i09g8jtne7u7ba0ivsbci06', // Echobox-UI-Login-Staging
  WEB_CLIENT_ID_LIVE: 'gli4sjuhplkufch7n9n39jl1', // Echobox-UI-Login
  AUTHENTICATION_FLOW_TYPE: 'USER_SRP_AUTH',
};

const hostType = environment.getHostname();

export const AWS_AMPLIFY_CONFIG = {
  Auth: {
    region: AWS_AMPLIFY_DEFAULTS.REGION,
    userPoolId: AWS_AMPLIFY_DEFAULTS.USER_POOL_ID,
    userPoolWebClientId:
      hostType === 'live'
        ? AWS_AMPLIFY_DEFAULTS.WEB_CLIENT_ID_LIVE
        : AWS_AMPLIFY_DEFAULTS.WEB_CLIENT_ID_STAGE,
    authenticationFlowType: AWS_AMPLIFY_DEFAULTS.AUTHENTICATION_FLOW_TYPE,
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 1,
      secure: hostType !== 'dev',
      sameSite: 'strict',
    },
  },
};

// Campaigns using hosted subscriptions pages (for which the Add Subscribers button should not be available)
export const CAMPAIGNS_USING_HOSTED_SUBSCRIPTIONS = [
  // 'urn:newsletter:campaign:2dff347c-8b0c-4ed3-a4e1-aefdb659e2ce', // Kaaoszine: Kaaoszine Uutiskirje
  'urn:newsletter:campaign:315336f7-e1da-4f6b-8e21-efa55ab6a478', // The Guardian Nigeria: The Guardian Daily Brief
];

// Proxy service used to download files without encountering CORS errors
export const CORS_PROXY_URL = 'https://corsproxy.service.echobox.com/';

// Fields to be excluded or stringified when being logged
export const LOGGLY_SETTINGS = {
  EXCLUDE_IF_EMPTY: ['statusText'],
  KEYS_TO_EXCLUDE: [
    'X-EBX-AccessToken',
    'X-EBX-AuthToken',
    'X-EBX-ClientServiceToken',
    'X-EBX-IdToken',
    'X-EBX-ImpersonateToken',
    'password',
    'oldPassword',
    'newPassword',
  ],
  KEYS_TO_STRINGIFY: ['dataJSON', 'mediaItem'],
};

// Maximum size of subscriber file
export const MAXIMUM_UPLOAD_SIZE = 1024 * 1024 * 1024; // 1GiB - update again when we know what maximum we want to impose

// Popper modifiers to ensure date picker popups are always visible
export const POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [42, 0],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      rootBoundary: 'viewport',
      tether: true,
      altAxis: true,
    },
  },
];

// Refresh intervals in milliseconds
export const REFRESH_INTERVALS = {
  BASE_VALUE: 30 * 1000, // Base value from which several other values are defined
};

export const REFRESH_MULTIPLIERS = {
  CHECK_VERSION: 2, // Check for version update check (every minute)
  FETCH_EDITION: 0.2, // Check edition populated (every six seconds)
  FETCH_INSIGHTS: 1, // Refresh campaign statistics (every thirty seconds)
  FETCH_UPLOADS: 1, // Refresh file uploads (every thirty seconds)
  GLOBAL_INFO: 4, // Refresh global info (every two minutes)
  STATUS_PAGE: 2, // Status page notifications (every minute)
};

// Errors not to send to Sentry
export const SENTRY_IGNORED_ERRORS = [
  {
    typeError: true,
    filename: 'react-dom.production.min.js',
    message: 'e is null',
  },
  {
    typeError: true,
    filename: 'insertTextIntoContentState.js',
    message: "Cannot read property 'length' of null",
  },
  {
    message:
      "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  },
  {
    message: 'HTTP Status 0',
  },
  {
    message: "Cannot read property 'getIn' of undefined",
  },
  {
    message: "Cannot read property 'getCharacterList' of undefined",
  },
  {
    message: "Cannot read property 'getEntityAt' of undefined",
  },
  {
    message: "Cannot read property 'getLength' of undefined",
  },
  {
    message: "Cannot read property 'nodeType' of null",
  },
  {
    message: "Cannot read property 'update' of null",
  },
  {
    message: 'y.getBlockTree(...) is undefined',
  },
  {
    message: 'EBX:INTERNAL - current property has changed',
  },
  {
    message: 'ResizeObserver loop limit exceeded',
  },
  {
    message:
      'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
  },
];

// Log transactions and/or spans using Sentry performance API
export const SENTRY_LOG_SPANS = true;
export const SENTRY_LOG_TRANSACTIONS = true;

export const SUPPORTED_FORMATS = {
  'text/csv': ['.csv'],
  'text/plain': ['.txt'],
  'application/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
};

// Unlayer API key
export const UNLAYER_API_KEY =
  'Q16NqbcI0BwrYUztcof1Of4DUQ1dYcvgtnnaBEpCgD2YQjIT5ajrvtycYiPpsEPn';

// Unlayer project ID
export const UNLAYER_EMBED_PROJECT_ID = 88242;
