import axios from 'axios';

import * as datetime from 'common/datetime';
import { EDITION_STATE } from 'common/enums';
import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

const now = datetime.getUnixTimestamp();

interface getEditionListProps {
  campaignURN: string;
  startUnixTime?: number;
  endUnixTime?: number;
  filterByState?: keyof typeof EDITION_STATE;
}

export default async function getEditionList({
  campaignURN,
  startUnixTime = now - datetime.DAYS(7),
  endUnixTime = now + datetime.DAYS(14),
  filterByState,
}: getEditionListProps) {
  const guid = metrics.start('getEditionList');

  const stateFilterQueryParam = filterByState
    ? `&filterByState=${filterByState}`
    : '';

  const url = `${apiBaseURL(
    APIS.NEWSLETTERS
  )}/campaigns/${campaignURN}/editions?startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}${stateFilterQueryParam}`;

  const config = {
    url,
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getEditionList /campaigns/${campaignURN}/editions`);

  try {
    const response = await axios(config);
    metrics.end('getEditionList', guid);
    return response.data.editionURNs;
  } catch (error) {
    metrics.fail('getEditionList', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getEditionList',
            args: { campaignURN },
          })
        : error;
    throw apiError;
  }
}
