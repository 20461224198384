import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import { Flex, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import URLInput from 'components/common/URLInput';
import { FooterCheckboxContext } from 'components/settings/campaigns/content/FooterCheckbox';

function FooterSocialLinkInput({ socialURL, value, onChange }) {
  // TODO: We need to add real validation here later.
  // We need to set this is validation on submission
  const [hasBeenTypedIn, setHasBeenTypedIn] = useState(false);

  // const invalidURL = pathName.length === 0;
  const show = useContext(FooterCheckboxContext);

  if (!show) {
    return null;
  }

  return (
    <Flex alignItems="center" gap={2} mt={3} flexWrap="wrap">
      <Flex alignItems="center" gap={2} grow={1}>
        <Text size="sm" color="gray.600">
          {socialURL}
        </Text>
        <URLInput
          getFullURL={input => `${socialURL}${input}`}
          invalidURL={!value}
          onChange={onChange}
          value={value}
          setHasBeenTypedIn={setHasBeenTypedIn}
        />
      </Flex>
      {hasBeenTypedIn && !value && (
        <Text size="sm" color="red.600">
          Please provide a valid social media link
        </Text>
      )}
    </Flex>
  );
}

FooterSocialLinkInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  socialURL: PropTypes.string.isRequired,
};

export default FooterSocialLinkInput;
