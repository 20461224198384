/* eslint no-use-before-define: "off" */

import * as campaigns from 'common/campaigns';
import * as utility from 'common/utility';

export {
  areArticlesOptimal,
  // deleteSection,
  getArticle,
  getSection,
  getSectionArticle,
  getSectionArticles,
  getSectionList,
  getSections,
  getCurrentSections,
  isArticleInOptimalPosition,
  // postSections,
  // putSections,
  swapArticleDetails,
  updateArticleDetails,
  updateSections,
};

/**
 * Determines whether all articles in a section are in their optimal positions
 * @param {string} title - description
 * @return {boolean}
 */

/* eslint arrow-body-style: "off" */
const areArticlesOptimal = (sectionDetails, ignoreSequence = true) => {
  // Extract the media URNs for all articles in the section
  let articleURNs = sectionDetails.articles.map(article => article.mediaURN);
  // Return false if no articles exist
  if (articleURNs.length === 0) {
    return true;
  }
  // Optionally sort these if we can ignore the sequence that they appear in
  if (ignoreSequence) {
    articleURNs = articleURNs.sort((a, b) => a.localeCompare(b));
  }
  // Return false if no optimal articles have been specified
  if (sectionDetails.optimalArticleOrdering.length === 0) {
    return true;
  }
  // Extract the optional URNs set when the Edition was initially populated
  let optimalURNs = utility.cloneObject(sectionDetails.optimalArticleOrdering);
  // Optionally sort these if we can ignore the sequence that they appear in
  if (ignoreSequence) {
    optimalURNs = optimalURNs.sort((a, b) => a.localeCompare(b));
  }
  // Now test that the two arrays are equal in length and that the values
  // in each position in either array match
  return (
    articleURNs.length === optimalURNs.length &&
    articleURNs.every((value, index) => value === optimalURNs[index])
  );
};

/*
const deleteSection = (sectionURN, globalInfo) => {
  //
};
*/

const getSection = (sectionURN, sectionDetails) => {
  const indexedSections = sectionDetails.map((section, index) => ({
    ...section,
    index,
  }));

  const section = indexedSections.find(
    indexedSection => sectionURN === indexedSection.sectionURN
  );
  return section ?? null;
};

const getSectionArticle = (sectionURN, index, sectionDetails) => {
  let article = null;
  sectionDetails.forEach(section => {
    if (section.sectionURN === sectionURN) {
      article = section?.articles[index] ?? null;
    }
  });
  return article;
};

const getSectionArticles = (sectionURN, sectionDetails) => {
  // TODO - possibly rewrite using map or reduce or filter or something less clunky
  let articles = [];
  sectionDetails.forEach(section => {
    if (section.sectionURN === sectionURN) {
      articles = section.articles;
    }
  });
  return articles;
};

const getSectionList = (campaignURN, globalInfo) => {
  const campaignDetails = campaigns.getCampaign(campaignURN, globalInfo);
  return campaignDetails?.sections.map(section => section.sectionURN);
};

const getSectionCampaignMap = globalInfo => {
  const sectionCampaignMap = {};

  Object.keys(globalInfo?.properties).forEach(propertyURN => {
    const property = globalInfo.properties[propertyURN];
    if (property?.campaigns) {
      Object.keys(property.campaigns).forEach(campaignURN => {
        const campaign = property.campaigns[campaignURN];
        campaign?.sections.forEach(section => {
          sectionCampaignMap[section.sectionURN] = { campaignURN, section };
        });
      });
    }
  });

  return sectionCampaignMap;
};

const getSections = (sectionURNs, globalInfo) => {
  const sectionCampaignMap = getSectionCampaignMap(globalInfo);
  const sectionList = [];
  Object.keys(sectionCampaignMap).forEach(sectionURN => {
    const sectionMap = sectionCampaignMap[sectionURN];
    if (sectionURNs.indexOf(sectionURN) !== -1) {
      sectionList.push(sectionMap.section);
    }
  });
  return sectionList;
};

const getCurrentSections = globalInfo =>
  campaigns.getCurrentCampaign(globalInfo)?.sections;

/**
 * isArticleInOptimalPosition
 *
 * Given a media URN, an array of articles that have been used to populate a Section
 * in an Edition, and the optimalArticleOrdering array for that Section, returns
 * a Boolean indicating whether that article is still in the optimal position i.e.
 * the position it was placed in when the Edition was originally populated
 *
 * @param {{
 *   mediaURN: string;
 *   editionArticles: object[];
 *   optimalArticleOrdering: string[]
 * }} args
 * @returns {boolean}
 */

const isArticleInOptimalPosition = ({
  mediaURN,
  editionArticles,
  optimalArticleOrdering,
}) => {
  const editionPosition = editionArticles.findIndex(
    article => article.mediaURN === mediaURN
  );
  const optimalPosition = optimalArticleOrdering.indexOf(mediaURN);
  return editionPosition === optimalPosition;
};

/*
const postSections = (campaignURN, sectionDetails, globalInfo) => {
  //
};

const putSections = (sectionURN, sectionDetails, globalInfo) => {
  //
};
*/

const swapArticleDetails = (sections, swapDetails) => {
  const updated = utility.cloneObject(sections);

  updated.forEach(section => {
    // TODO - error handling, maybe just a try...catch around the attempted update
    // TODO - write unit tests for this method
    if (section.sectionURN === swapDetails.sectionURN) {
      const [moved] = section.articles.splice(swapDetails.fromIndex, 1);
      section.articles.splice(swapDetails.toIndex, 0, moved);
    }
  });

  return updated;
};

const updateArticleDetails = (sections, articleDetails) => {
  const updated = utility.cloneObject(sections);

  sections.forEach((section, sectionIndex) => {
    // TODO - error handling, maybe just a try...catch around the attempted update
    if (section.sectionURN === articleDetails.sectionURN) {
      if (updated[sectionIndex].articles.length < articleDetails.index) {
        updated[sectionIndex].articles[articleDetails.index - 1] = {};
      }
      const isUpdatingAll = !utility.isNullOrUndefined(articleDetails.mediaURN);
      /* articleURL, mediaURN, imageURL */
      if (isUpdatingAll) {
        updated[sectionIndex].articles[articleDetails.index - 1].articleURL =
          articleDetails.articleURL;
        updated[sectionIndex].articles[articleDetails.index - 1].mediaURN =
          articleDetails.mediaURN;
      }
      /* description */
      if (!utility.isNullOrUndefined(articleDetails.description)) {
        updated[sectionIndex].articles[articleDetails.index - 1].description =
          articleDetails.description;
      } else {
        updated[sectionIndex].articles[articleDetails.index - 1].description =
          null;
      }
      /* title */
      updated[sectionIndex].articles[articleDetails.index - 1].title =
        articleDetails.title ?? '';
      /* imageURL */
      updated[sectionIndex].articles[articleDetails.index - 1].imageURL =
        articleDetails.imageURL ?? null;
    }
  });

  return updated;
};

const updateSections = (sections, section) => {
  const index = sections.findIndex(
    item => item.sectionURN === section.sectionURN
  );
  sections.splice(index, 1, section);
  return sections;
};

// Return nth article of an edition
const getArticle = (sections, index) => {
  // Create single list of all the articles
  const articleList = sections.flatMap(section => section.articles);
  return articleList[index] ?? null;
};
