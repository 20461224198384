import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postPropertySettings({
  propertyURN = mandatory('propertyURN'),
  autoExpireTime = null,
  dataJSON = null,
  enabled = true,
  settingTypeId = mandatory('settingTypeId'),
}) {
  const guid = metrics.start('postPropertySettings');
  const url = `${apiBaseURL(
    APIS.CORE,
    '1'
  )}/properties/${propertyURN}/settings`;

  const config = {
    url,
    method: 'post',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      autoExpireTime,
      dataJSON,
      enabled,
      settingTypeId,
    }),
  };
  logger.info(`API:postPropertySettings /properties/${propertyURN}/settings`);

  try {
    const { data } = await axios(config);
    metrics.end('postPropertySettings', guid);
    return data;
  } catch (error) {
    metrics.fail('postPropertySettings', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postPropertySettings',
            args: {
              propertyURN,
              autoExpireTime,
              dataJSON,
              enabled,
              settingTypeId,
            },
          })
        : error;
    throw apiError;
  }
}
