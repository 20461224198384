import { useContext } from 'react';

import { StoreContext } from 'store/store';

const useGlobalInfo = () => {
  const { state } = useContext(StoreContext);
  const { globalInfo } = state;

  return globalInfo;
};

export default useGlobalInfo;
