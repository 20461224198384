import { Box, PropertySelect } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';

import { SORT_ORDERS } from 'common/constants';
import { changingProperty } from 'state/changingEntity';
import { StoreContext } from 'store/store';

function EmailPropertySelect() {
  const { actions, state } = useContext(StoreContext);

  const setChangingProperty = useSetRecoilState(changingProperty);

  const handlePropertyChange = (propertyId: number) => {
    const propertyURN = `urn:property:${propertyId}`;

    logger.info(`PropertySelect:handlePropertyChange ${propertyURN}`);
    tracker.track({
      eventName: 'Switch Property',
      trackingParams: {
        'Property Name': properties.getPropertyName(
          propertyURN,
          state.globalInfo
        ),
        'Property ID': propertyURN,
      },
      options: {
        labelPropertyAsPrevious: true,
      },
    });
    setChangingProperty(true);
    actions.changeProperty(propertyURN);
  };

  const currentPropertyId = properties
    .getCurrentPropertyId(state.globalInfo)
    ?.toString();

  const sortedProperties = properties.sortProperties(
    state.globalInfo.properties,
    SORT_ORDERS.ALPHA
  );

  const availableProperties: {
    propertyId: number;
    propertyName: string;
  }[] = sortedProperties.map(property => ({
    propertyId: properties.getPropertyId(property.propertyURN),
    propertyName: properties.getPropertyName(
      property.propertyURN,
      state.globalInfo
    ),
  }));

  if (!currentPropertyId) {
    throw new Error('Current property ID not found');
  }

  if (availableProperties.length === 1) {
    return null;
  }

  return (
    <Box px={4}>
      <PropertySelect
        currentPropertyId={currentPropertyId}
        onChange={handlePropertyChange}
        properties={availableProperties}
      />
    </Box>
  );
}

export default EmailPropertySelect;
