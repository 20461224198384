import { Spinner } from 'react-bootstrap';

import { ReactComponent as SuccessIcon } from 'assets/svg/success-icon-no-fill.svg';

import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import type { LoadingStatus } from 'components/campaigns/editions/SmartCurationLoadingModal';

interface SmartCurationLoadingStatusProps {
  status: LoadingStatus;
  type: 'step' | 'substep';
}

function SmartCurationLoadingStatus({
  status,
  type,
}: SmartCurationLoadingStatusProps) {
  switch (status) {
    case 'loaded':
      return (
        <SuccessIcon className={`loading-${type} loading-${type}-loaded`} />
      );
    case 'loading':
      return (
        <Spinner
          animation="border"
          role="status"
          variant="primary"
          className={`loading-${type} loading-step-loading`}
        >
          <VisuallyHidden>Loading...</VisuallyHidden>
        </Spinner>
      );
    case 'waiting':
      return <div className={`loading-${type} loading-step-waiting`} />;
  }

  return null;
}

export default SmartCurationLoadingStatus;
