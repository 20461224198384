/* eslint @typescript-eslint/no-use-before-define: "off" */

import * as url from 'common/url';
import * as utility from 'common/utility';

export { getFileSuffix, numberOfFields, splitFields, validateHeaders };

const VALID_HEADERS = {
  EMAIL: { label: 'email', values: ['email', 'e-mail', 'email address'] },
  FIRST_NAME: { label: 'first name', values: ['first_name', 'first name'] },
  LAST_NAME: { label: 'last name', values: ['last_name', 'last name'] },
};

const validateHeaders = (headerRow: string) => {
  const headers = splitFields(headerRow);

  let key: keyof typeof VALID_HEADERS;
  for (key in VALID_HEADERS) {
    if (Object.prototype.hasOwnProperty.call(VALID_HEADERS, key)) {
      const { label, values } = VALID_HEADERS[key];
      const currentHeaders = headers.filter(
        header => values.indexOf(header?.toLowerCase()?.trim()) !== -1
      );

      if (currentHeaders.length !== 1) {
        return {
          isValid: false,
          validationMessage: `Found ${
            currentHeaders.length
          } ${label} headers when 1 was expected.
          Valid ${label} headers are ${values.join(', ')} (case-insensitive)`,
        };
      }
    }
  }

  return {
    isValid: true,
    emailHeaderIndex: headers.findIndex(
      header =>
        VALID_HEADERS.EMAIL.values.indexOf(header?.toLowerCase()?.trim()) !== -1
    ),
  };
};

function numberOfFields(fileRow: string) {
  return fileRow.replace(/"[^"]*"/g, '').split(',').length;
}

/**
 * Splits a row from a comma-separated, optionally-quote-delimited file into an array of fields
 * fileRow - the row of data to be parsed
 * returns An array containing each individual field from the row of data
 */

function splitFields(fileRow: string) {
  const rowFields = [];
  let currentField = '';
  let quoteFlag = false;
  for (const character of fileRow) {
    if (character === '"') {
      quoteFlag = !quoteFlag;
    }
    if (character === ',' && !quoteFlag) {
      rowFields.push(currentField);
      currentField = '';
    } else if (character !== '"') {
      currentField += character;
    }
  }
  if (currentField !== '') {
    rowFields.push(currentField);
  }
  return rowFields;
}

function getFileSuffix(link: string, data: Blob) {
  const urlComponents = url.splitURL(link);
  const findSuffix = urlComponents.rootURL.match(/\.[0-9a-z]+$/i);
  let suffix = '';
  if (utility.isNull(findSuffix)) {
    // Determine the file type using the blob as the suffix does not contain the file type
    if (!utility.isNullOrUndefined(data.type) && data.type !== '') {
      suffix = `.${data.type.split('/')[1]}`;
    } else {
      suffix = '';
    }
  } else {
    suffix = findSuffix?.[0] ?? '';
  }
  return suffix;
}
