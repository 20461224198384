import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * putPropertiesSettings - Updates the property settings
 * @param {{
 *  propertyURN: string;
 *  propertySettings: object;
 * }} args
 */
export default async function putPropertiesSettings({
  propertyURN = mandatory('propertyURN'),
  propertySettings = mandatory('propertySettings'),
}) {
  const guid = metrics.start('putPropertiesSettings');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/settings`,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      propertySettings,
    }),
  };
  logger.info(`API:putPropertiesSettings /properties/${propertyURN}/settings`);

  try {
    const response = await axios(config);
    metrics.end('putPropertiesSettings', guid);
    return response.data;
  } catch (error) {
    metrics.fail('putPropertiesSettings', guid);
    const apiError = await handleAPIError({
      originalError: error,
      // TODO - work out how to sort out this TypeScript hack at some point
      // @ts-ignore
      errorLocation: 'api/putPropertiesSettings',
      args: { propertySettings },
    });
    throw apiError;
  }
}
