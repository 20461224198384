export type BodyElementProps = JSX.IntrinsicElements['div'] & {
  innerClassName?: string;
};

function BodyElement({
  children,
  className,
  innerClassName,
  ...rest
}: BodyElementProps) {
  return (
    <div
      className={`ebx-article-section d-flex flex-wrap w-100 rounded ${className}`}
      {...rest}
    >
      <div className={`body-element-content ${innerClassName}`}>{children}</div>
    </div>
  );
}

BodyElement.defaultProps = {
  innerClassName: null,
};

export default BodyElement;
