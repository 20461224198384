import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { getGoogleFonts, loadFont } from 'common/googlefonts';
import { BackLink, Container, Description, Title } from '../../common';

import GoogleFontsDropdown from './GoogleFontsDropdown';

function Fonts({ setSettingsPanel, settings }) {
  const [loadingFonts, setIsLoadingFonts] = useState(true);
  const [googleFonts, setGoogleFonts] = useState(['']);
  const [fontsErrorMessage, setFontsErrorMessage] = useState('');

  const titleFont =
    settings.getCampaignSettingValue(
      CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      'titleFont'
    ) || 'Arial';

  const bodyFont =
    settings.getCampaignSettingValue(
      CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      'bodyFont'
    ) || 'Arial';

  useEffect(() => {
    async function getFonts() {
      let importedGoogleFonts;
      try {
        const response = await getGoogleFonts();
        importedGoogleFonts = response.map(option => ({
          value: option.family,
          label: option.family,
          type: 'google',
        }));
      } catch (error) {
        const errorMessage = `Unable to retrieve all Google Fonts, please try again later...`;
        setFontsErrorMessage(errorMessage);
      }

      try {
        if (importedGoogleFonts) {
          setIsLoadingFonts(true);
          importedGoogleFonts.forEach(googleFont => {
            loadFont(googleFont.value);
          });
          setGoogleFonts(importedGoogleFonts);
        } else {
          setGoogleFonts(['']);
        }
      } catch (error) {
        const errorMessage = `Unable to retrieve all Google Fonts, please try again later...`;
        setFontsErrorMessage(errorMessage);
      }
      setIsLoadingFonts(false);
    }
    getFonts();
  }, []);

  const handleTitleFontChange = event => {
    setFontsErrorMessage('');
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'titleFont',
      settingValue: event?.value ?? '',
    });
    if (event?.value && event?.type === 'google') {
      loadFont(event.value);
    }
  };

  const handleBodyFontChange = event => {
    setFontsErrorMessage('');
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'bodyFont',
      settingValue: event?.value ?? '',
    });
    if (event?.value && event?.type === 'google') {
      loadFont(event.value);
    }
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to General Styling" />
      </Button>
      <Title className="mb-3">Fonts</Title>
      {fontsErrorMessage && (
        <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
          {fontsErrorMessage}
        </div>
      )}
      <Description className="mb-3">
        Select a font for the text in your Campaign and subscription page
      </Description>
      <div className="settings-subtitle">Title</div>
      <div className="font-dropdown">
        <GoogleFontsDropdown
          googleFonts={googleFonts}
          chosenFontFamily={titleFont}
          onChange={handleTitleFontChange}
          value={titleFont ? { value: titleFont, label: titleFont } : 'Arial'}
          loadingFonts={loadingFonts}
        />
      </div>
      <div className="settings-subtitle">Body</div>
      <div className="font-dropdown">
        <GoogleFontsDropdown
          googleFonts={googleFonts}
          chosenFontFamily={bodyFont}
          onChange={handleBodyFontChange}
          value={bodyFont ? { value: bodyFont, label: bodyFont } : 'Arial'}
          loadingFonts={loadingFonts}
        />
      </div>
    </Container>
  );
}

Fonts.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default Fonts;
