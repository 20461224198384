import {
  Box,
  Button,
  EyeIcon,
  Image,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PreviewTemplate from './PreviewTemplate';

interface TemplateThumbnailProps {
  name: string;
  thumbnail: string;
}

function TemplateThumbnail({ name, thumbnail }: TemplateThumbnailProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        w="224px"
        h="100%"
        bg="#F7F7F7"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="lg"
        pt={3}
        px={8}
        position="relative"
        overflowY="hidden"
      >
        <Image
          w="100%"
          src={thumbnail}
          fallback={<Box h="100%" w="100%" bg="white" />}
          alt={`${name} thumbnail`}
          fit="cover"
        />
        <Button
          variant="secondary"
          py={2.5}
          position="absolute"
          top={3}
          right={3}
          visibility="hidden"
          _hover={{ bg: 'gray.100' }}
          _groupHover={{ visibility: 'visible' }}
          _groupActive={{
            visibility: 'hidden',
            _active: { visibility: 'visible' },
          }}
          onClick={onOpen}
        >
          <EyeIcon color="gray.600" />
        </Button>
      </Box>

      {isOpen && (
        <PreviewTemplate
          isOpen={isOpen}
          onClose={onClose}
          name={name}
          preview={thumbnail}
        />
      )}
    </>
  );
}

export default TemplateThumbnail;
