import { useState } from 'react';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import * as campaigns from 'common/campaigns';
import * as clipboard from 'common/clipboard';
import * as properties from 'common/properties';
import { generateSubscriptionLink } from 'common/string';
import * as tracker from 'common/tracker';
import useGlobalInfo from 'hooks/useGlobalInfo';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import ViewIcon from '@mui/icons-material/VisibilityOutlined';

import '../Subscription.css';

const CopiedPopover = (
  <Popover className="copied__popover">
    <Popover.Content className="ebx-h3">Copied</Popover.Content>
  </Popover>
);

function SubscriptionPage() {
  const { urn: campaignURN } = useParams();
  const globalInfo = useGlobalInfo();
  const campaignDetails = campaigns.getCampaign(campaignURN, globalInfo);
  const [showCopiedAddress, setShowCopiedAddress] = useState(false);

  const subscriptionLink = generateSubscriptionLink(
    properties,
    globalInfo,
    campaignDetails
  );

  const handleLiveFormCopy = event => {
    setShowCopiedAddress(true);
    event.preventDefault();
    clipboard.setClipboardData(null, subscriptionLink);
    tracker.track({
      eventName: 'Copy Subscription Snippet',
      trackingParams: {
        'Snippet Type': 'Address',
      },
      options: { campaignURN },
    });
    setTimeout(() => setShowCopiedAddress(false), 3000);
  };

  return (
    <div className="subscription-sections">
      <Row className="pb-2 px-3">
        <Col className="px-0">
          <div className="mb-3">
            <div className="ebx-h1 mb-2">Subscription page</div>
          </div>
          <div className="ebx-h3 mb-1">Address URL</div>
          <a
            className="ebx-display-box px-3 d-flex mb-3 align-items-center subscription--display subscription__address--input overflow"
            href={subscriptionLink}
            target="_blank"
            rel="noreferrer"
          >
            <span className="ebx-body-1">{subscriptionLink}</span>
            <LaunchIcon className="external-link-icon ml-3" />
          </a>
          <Button
            className="ebx-btn-secondary copy--button mr-3"
            href={`${subscriptionLink}`}
            // onClick={handleViewPage}
            target="_blank"
          >
            <ViewIcon className="view-icon" />
            <span className="ml-2 ebx-h3">View page</span>
          </Button>
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={CopiedPopover}
            show={showCopiedAddress}
            rootClose
          >
            <Button
              className="ebx-btn-secondary copy--button"
              onClick={handleLiveFormCopy}
            >
              <ContentCopyIcon className="copy-icon" />
              <span className="ml-2 ebx-h3">Copy link</span>
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </div>
  );
}

export default SubscriptionPage;
