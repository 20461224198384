import { useLocation } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

import HeaderLink from 'components/settings/header/HeaderLink';

function TeamLinks() {
  const location = useLocation();

  /* Render nothing if we are not showing a property settings page or sub-page */
  if (!location.pathname.startsWith('/settings/team')) {
    return null;
  }

  return (
    <>
      <ChevronRight />
      <HeaderLink to="/settings/team">
        <span className="ebx-h1">Team</span>
      </HeaderLink>
    </>
  );
}

export default TeamLinks;
