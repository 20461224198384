/* eslint no-bitwise: "off" */
/* eslint no-use-before-define: "off" */
/* eslint prefer-template: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */

import kebabCase from 'lodash.kebabcase';

import { mandatory } from 'common/validation';
import { Campaign } from './types';
import { isUndefined } from './utility';

export {
  errorGuid,
  formatFileSize,
  isEchoboxEmail,
  isValidTicketNumber,
  generateGuid,
  pluralise,
  updatePlural,
  updateWarningStyle,
  generateSubscriptionLink,
  replacePlaceholders,
  simpleHash,
  capitalise,
  toCamelCase,
  toSentenceCase,
  commaSeparatedList,
};

function errorGuid() {
  let errorHash = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i += 1) {
    errorHash += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return errorHash;
}

function formatFileSize(bytes: number) {
  let size = bytes;
  const multiplier = 1024;
  if (Math.abs(size) < multiplier) {
    return `${size}B`;
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unit = -1;
  do {
    size /= multiplier;
    unit += 1;
  } while (Math.abs(size) >= multiplier && unit < units.length - 1);
  return `${size.toFixed(0)}${units[unit]}`;
}

function generateGuid() {
  const lut = [];
  for (let i = 0; i < 256; i += 1) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16);
  }
  const d0 = (Math.random() * 0xffffffff) | 0;
  const d1 = (Math.random() * 0xffffffff) | 0;
  const d2 = (Math.random() * 0xffffffff) | 0;
  const d3 = (Math.random() * 0xffffffff) | 0;
  return (
    lut[d0 & 0xff] +
    lut[(d0 >> 8) & 0xff] +
    lut[(d0 >> 16) & 0xff] +
    lut[(d0 >> 24) & 0xff] +
    '-' +
    lut[d1 & 0xff] +
    lut[(d1 >> 8) & 0xff] +
    '-' +
    lut[((d1 >> 16) & 0x0f) | 0x40] +
    lut[(d1 >> 24) & 0xff] +
    '-' +
    lut[(d2 & 0x3f) | 0x80] +
    lut[(d2 >> 8) & 0xff] +
    '-' +
    lut[(d2 >> 16) & 0xff] +
    lut[(d2 >> 24) & 0xff] +
    lut[d3 & 0xff] +
    lut[(d3 >> 8) & 0xff] +
    lut[(d3 >> 16) & 0xff] +
    lut[(d3 >> 24) & 0xff]
  );
  // https://stackoverflow.com/a/21963136
}

function isValidTicketNumber(ticketNumber: string) {
  if (ticketNumber.match(/^(PROD|TEC|NL|BM)-[0-9]{2,5}$/g)) {
    return true;
  }
  return false;
}

function isEchoboxEmail(emailAddress: string) {
  const emailParts = emailAddress.split('@');
  if (
    emailParts.length !== 2 ||
    emailParts[1] !== 'echobox.com' ||
    emailParts[0].length === 0
  ) {
    return false;
  }
  return true;
}

function pluralise(word: string, value: number | null | undefined) {
  if (value === null || value === undefined) return word;
  return value === 1 ? word : `${word}s`;
}

function updatePlural(inputLength: number) {
  let plural = 's';
  if (inputLength === 1) {
    plural = '';
  }
  return plural;
}

function updateWarningStyle(inputLength: number, warningValue: number) {
  let warningStyle = { color: '#424D60' };
  if (inputLength <= warningValue) {
    warningStyle = { color: '#CD3D64' };
  }
  return warningStyle;
}

function generateSubscriptionLink(
  properties: any,
  globalInfo: any,
  campaignDetails: Campaign
) {
  const campaignGuid = campaignDetails.campaignURN.split(':').pop();
  const subscriptionLink = encodeURI(
    `https://${kebabCase(
      properties.getCurrentPropertyName(globalInfo)
    )}.campaign-list.com/subscribe/${kebabCase(
      campaignDetails.campaignName
    )}/${campaignGuid}`
  );
  return subscriptionLink;
}

function replacePlaceholders(string: string, variables: any) {
  let result = string;
  for (const variable in variables) {
    if (Object.prototype.hasOwnProperty.call(variables, variable)) {
      const regex = new RegExp(`{${variable}}`, 'g');
      result = result.replace(regex, () => `${variables[variable]}`);
    }
  }

  return result;
}

function simpleHash(string: string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
}

function capitalise(string: string) {
  if (isUndefined(string)) {
    mandatory('string');
  }
  try {
    const lowerCase = string.toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  } catch (error) {
    console.log(error);
    return string;
  }
}

function toCamelCase(string: string) {
  return string
    .split(' ')
    .map(word => word.substr(0, 1).toUpperCase() + word.substr(1))
    .join(' ');
}

function toSentenceCase(string: string) {
  return string
    .split(' ')
    .map(word => word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
}

function commaSeparatedList(list: string[], conjunction?: string) {
  return list
    .map((item, i) => {
      if (conjunction && i !== 0 && i === list.length - 1) {
        return conjunction + item;
      }
      if (i !== 0) {
        return ', ' + item;
      }
      return item;
    })
    .join('');
}
