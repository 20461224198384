/* eslint no-use-before-define: "off" */

import { isDefined, isNullOrUndefined } from 'common/utility';

export { getClipboardData, setClipboardData };

function getClipboardData(event) {
  let text;
  if (isDefined(event.clipboardData)) {
    // Most browsers
    text = event.clipboardData.getData('text/plain');
  } else if (isDefined(window.clipboardData)) {
    // Internet Explorer
    text = window.clipboardData.getData('text');
  } else if (isDefined(event.originalEvent.clipboardData)) {
    // jQuery-generated event
    text = event.originalEvent.clipboardData.getData('text/plain');
  }
  return text;
}

function setClipboardData(event, text) {
  if (!isNullOrUndefined(event)) {
    if (isDefined(event.clipboardData)) {
      // Most browsers
      event.clipboardData.setData('text/plain', text);
    } else if (isDefined(window.clipboardData)) {
      // Internet Explorer
      window.clipboardData.setData('text', text);
    } else if (isDefined(event?.originalEvent?.clipboardData)) {
      // jQuery-generated event
      event.originalEvent.clipboardData.setData('text/plain', text);
    }
  } else if (isDefined(navigator.clipboard)) {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch(() => {});
  }
}
