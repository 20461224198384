import { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { Button, Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import Article from 'components/editor/curate/Article';
import EditContentModal from 'components/editor/curate/EditContentModal';

import * as sections from 'common/sections';
import { EditionSection, MediaDataSources, SectionArticle } from 'common/types';
import * as utility from 'common/utility';

import './Article.css';

interface IndexedSection extends EditionSection {
  index: number;
}

interface CurateSectionProps {
  areAllArticlesOptimal: boolean;
  dataSources: MediaDataSources;
  handleDeleteArticle: (sectionURN: string, index: number) => void;
  handleSaveArticleDetails: (
    sectionURN: string,
    index: number,
    finalURL: string,
    title: string,
    description: string,
    imageURL: string,
    mediaURN: string,
    callback: () => void
  ) => void;
  handleSwapArticles: (
    sectionURN: string,
    sourceIndex: number,
    destinationIndeX: number
  ) => void;
  isEditionEditable: boolean;
  isSavingArticleDetails: boolean;
  section: IndexedSection;
}

function Section({
  areAllArticlesOptimal,
  dataSources,
  handleDeleteArticle,
  handleSaveArticleDetails,
  handleSwapArticles,
  isEditionEditable,
  isSavingArticleDetails,
  section,
}: CurateSectionProps) {
  const articlesToRender = utility.cloneArray(section.articles);

  const [renderedArticles, setRenderedArticles] = useState(articlesToRender);

  const [isOpenArticleModal, setIsOpenArticleModal] = useState(false);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedArticles = Array.from(renderedArticles);
    const [movedItem] = reorderedArticles.splice(result.source.index, 1);
    reorderedArticles.splice(result.destination.index, 0, movedItem);
    setRenderedArticles(reorderedArticles);
    handleSwapArticles(
      section.sectionURN,
      result.source.index,
      result.destination.index
    );
  };

  const renderSectionTitle = () => (
    <Flex alignItems="center" justifyContent="space-between">
      <Heading variant="h3">{section.sectionName}</Heading>
      {isEditionEditable && (
        <Button variant="link" onClick={() => setIsOpenArticleModal(true)}>
          Add article
        </Button>
      )}
    </Flex>
  );

  const renderAddArticleModal = () => {
    if (!isOpenArticleModal) return null;
    return (
      <EditContentModal
        handleSaveArticleDetails={handleSaveArticleDetails}
        hideModal={() => setIsOpenArticleModal(false)}
        index={section.articles.length + 1}
        isSavingArticleDetails={isSavingArticleDetails}
        sectionURN={section.sectionURN}
        dataSources={dataSources}
        isShowModal={isOpenArticleModal}
        modalTitle="Add article"
      />
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={section.sectionURN}>
        {droppable => (
          <Flex flexDir="column" gap={3}>
            {renderSectionTitle()}
            <div {...droppable.droppableProps} ref={droppable.innerRef}>
              {renderedArticles.map(
                (article: SectionArticle, articleIndex: number) => (
                  <Draggable
                    key={article.mediaURN}
                    draggableId={article.mediaURN}
                    index={articleIndex}
                    isDragDisabled={!isEditionEditable}
                  >
                    {draggable => (
                      <div
                        className="article-section"
                        ref={draggable.innerRef}
                        {...draggable.draggableProps}
                        {...draggable.dragHandleProps}
                      >
                        {article.isAdditionalForContentSelection ? null : (
                          <Article
                            key={`article-${article.mediaURN}`}
                            {...article}
                            handleSaveArticleDetails={handleSaveArticleDetails}
                            handleDeleteArticle={handleDeleteArticle}
                            isSavingArticleDetails={isSavingArticleDetails}
                            index={articleIndex + 1}
                            sectionURN={section.sectionURN}
                            dataSources={dataSources}
                            showOptimalTooltip={
                              !areAllArticlesOptimal &&
                              sections.isArticleInOptimalPosition({
                                mediaURN: article.mediaURN,
                                editionArticles: renderedArticles,
                                optimalArticleOrdering:
                                  section?.optimalArticleOrdering ?? [],
                              })
                            }
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                )
              )}
            </div>
            {droppable.placeholder}
          </Flex>
        )}
      </Droppable>
      {renderAddArticleModal()}
    </DragDropContext>
  );
}

export default Section;
