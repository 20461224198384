/* eslint react/forbid-prop-types: "off" */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import ImpersonateUser from 'components/staff/ImpersonateUser';
import NewProperty from 'components/staff/NewProperty';
import PropertyCreated from 'components/staff/PropertyCreated';
import StaffModal from 'components/staff/StaffModal';
import UploadEmailQuota from 'components/staff/upload/UploadEmailQuota';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import './StaffModal.css';

export const STAFF_MODAL_STATES = {
  STAFF_OPTIONS: 'STAFF_OPTIONS',
  NEW_PROPERTY: 'NEW_PROPERTY',
  PROPERTY_CREATED: 'PROPERTY_CREATED',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
  UPLOAD_EMAIL_QUOTA: 'UPLOAD_EMAIL_QUOTA',
};

function StaffModalWrapper({ show, handleClose, globalInfo, isStaffUser }) {
  const [currentComponent, setCurrentComponent] = useState(
    STAFF_MODAL_STATES.STAFF_OPTIONS
  );

  useEffect(() => {
    if (isStaffUser) {
      setCurrentComponent(STAFF_MODAL_STATES.IMPERSONATE_USER);
    }
  }, [isStaffUser]);

  const [newPropertyData, setNewPropertyData] = useState({
    propertyURN: null,
    propertyName: null,
  });
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="impersonate__modal"
      backdrop="static"
    >
      <Modal.Header className="ebx-modal-header">
        <Modal.Title className="ebx-h2">Staff settings</Modal.Title>
        <span className="ml-auto">
          <Button
            variant="link"
            className="p-0"
            onClick={handleClose}
            disabled={isProcessing}
          >
            <CloseIcon />
          </Button>
        </span>
      </Modal.Header>
      {currentComponent === STAFF_MODAL_STATES.STAFF_OPTIONS && (
        <StaffModal
          show={show}
          handleClose={handleClose}
          setCurrentComponent={setCurrentComponent}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          globalInfo={globalInfo}
          isStaffUser={isStaffUser}
        />
      )}
      {currentComponent === STAFF_MODAL_STATES.NEW_PROPERTY && (
        <NewProperty
          show={show}
          setCurrentComponent={setCurrentComponent}
          setNewPropertyData={setNewPropertyData}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      )}
      {currentComponent === STAFF_MODAL_STATES.PROPERTY_CREATED && (
        <PropertyCreated
          show={show}
          setCurrentComponent={setCurrentComponent}
          newPropertyData={newPropertyData}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      )}
      {currentComponent === STAFF_MODAL_STATES.IMPERSONATE_USER && (
        <ImpersonateUser
          show={show}
          handleClose={handleClose}
          setCurrentComponent={setCurrentComponent}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          globalInfo={globalInfo}
        />
      )}
      {currentComponent === STAFF_MODAL_STATES.UPLOAD_EMAIL_QUOTA && (
        <UploadEmailQuota
          show={show}
          handleClose={handleClose}
          setCurrentComponent={setCurrentComponent}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      )}
    </Modal>
  );
}

StaffModalWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  globalInfo: PropTypes.shape({
    current: PropTypes.shape({
      campaignURN: PropTypes.string.isRequired,
      propertyURN: PropTypes.string.isRequired,
    }).isRequired,
    properties: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
    user: PropTypes.shape({
      emailAddress: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isStaffUser: PropTypes.bool.isRequired,
};

export default StaffModalWrapper;
