import {
  Box,
  Flex,
  HtmlIcon,
  MoreOptionsIcon,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import EditionErrorMessage from 'components/campaigns/editions/EditionErrorMessage';
import EditionLastUpdate from 'components/campaigns/editions/EditionLastUpdate';
import EditionPerformanceStatistics from 'components/campaigns/editions/EditionPerformanceStatistics';
import EditionSendTimes from 'components/campaigns/editions/EditionSendTimes';
import EditionState from 'components/campaigns/editions/EditionState';
import EditionSubject from 'components/campaigns/editions/EditionSubject';
import EditionWrapper from 'components/campaigns/editions/EditionWrapper';
import Thumbnail from 'components/common/Thumbnail';

import * as API from 'api/api';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as intervals from 'common/intervals';
import * as logger from 'common/logger';
import type { Edition as EditionType, NOSEditionInsights } from 'common/types';
import useStaffMode from 'hooks/useStaffMode';

import { REFRESH_MULTIPLIERS } from 'common/config';
import { EDITION_DISPLAY_STATES, EDITION_FIELDS } from 'common/constants';
import { EDITION_STATE } from 'common/enums';

import './Edition.css';
import './MarketingEmail.css';

const DEFAULT_SENDING_TIMESLOT_SIZE_SECONDS = 60 * 60 * 3; // 3 hours

export interface EditionProps {
  edition: EditionType;
  loadEditionData: () => void;
}

function Edition({ edition, loadEditionData }: EditionProps) {
  const [performanceStatistics, setPerformanceStatistics] =
    useState<NOSEditionInsights>();
  const [isPerformanceLoading, setIsPerformanceLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const toast = useToast();

  const currentTime = datetime.getUnixTimestamp();

  const {
    approvalState,
    editionSource,
    lastUpdatedUnixTime,
    editionState,
    editionURN,
    sendingTimeslotSizeSeconds,
    scheduledUnixTime,
    failMessage,
    previewData,
  } = edition;

  const editionDisplayState = editions.getEditionDisplayState(
    approvalState,
    editionSource,
    lastUpdatedUnixTime,
    editionState,
    scheduledUnixTime,
    currentTime
  );

  const isMarketing = editions.isMarketingEmail(edition);

  useEffect(() => {
    if (edition.performanceStatistics) {
      edition.performanceStatistics.then(performance => {
        setPerformanceStatistics(performance);
        setIsPerformanceLoading(false);
      });
    }
  }, [edition.performanceStatistics]);

  useEffect(() => {
    const fetchEdition = async () => {
      logger.info('Header:fetchEdition');
      const editionData = await API.getEditions({
        editionURNs: [editionURN],
        fieldList: EDITION_FIELDS.REVIEW,
      });
      const editionDetail = editionData[0];
      const { editionState: newEditionState } = editionDetail;
      if (newEditionState !== EDITION_STATE.POPULATING) {
        // Need to find a better way of refreshing editions
        window.location.reload();
      }
    };
    if (editionDisplayState === EDITION_DISPLAY_STATES.POPULATING) {
      intervals.setItem(
        'fetchEdition',
        fetchEdition,
        REFRESH_MULTIPLIERS.FETCH_EDITION
      );
    }

    return () => {
      intervals.removeItem('fetchEdition');
    };
  }, [editionDisplayState, editionURN]);

  const [isStaffModeActive] = useStaffMode();
  const isEditionVisible = editions.checkIsEditionVisible(editionDisplayState);
  const isEditionClickable =
    editions.checkIsEditionClickable(editionDisplayState);
  const errorMessage =
    failMessage ?? editions.getErrorMessage(editionDisplayState);
  const isErrorPanelVisible = editions.checkIsErrorVisible(editionDisplayState);
  const wrapperClass = editions.setWrapperClass(editionDisplayState);
  const displayLabel = editions.setDisplayLabel(editionDisplayState);
  const canDelete = editions.checkCanDelete(editionDisplayState);

  const finishedSendingTime = scheduledUnixTime
    ? scheduledUnixTime +
      (sendingTimeslotSizeSeconds ?? DEFAULT_SENDING_TIMESLOT_SIZE_SECONDS)
    : null;

  const isSent =
    edition.editionState === EDITION_STATE.SENT ||
    edition.editionState === EDITION_STATE.FAILED;

  const handleDeleteEdition = async () => {
    try {
      setIsDeleting(true);
      await API.deleteEdition({ editionURN });
      toast({ variant: 'success', title: 'Edition removed' });
      loadEditionData();
    } catch (error) {
      logger.error(`View Campaign delete edition: ${error}`);
      toast({ variant: 'error', title: 'Error deleting edition' });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleRemoveDropdown = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
  };

  const editionContent = (
    <EditionWrapper className={wrapperClass}>
      <Flex pl={2}>
        {isMarketing ? (
          <Thumbnail
            defaultIcon={<HtmlIcon size={5} color="gray.500" />}
            height="56px"
            width="100px"
            alt="Edition preview image"
          />
        ) : (
          <Thumbnail
            image={previewData?.heroImageURL}
            height="56px"
            width="100px"
            alt="Edition preview image"
          />
        )}
        <Box flexGrow={1}>
          <Row
            className={`d-flex align-items-start edition-padding${
              isSent ? '-sent' : ''
            }`}
          >
            <Col xs={12} lg={6} xl={7}>
              <div className="d-flex align-items-center">
                {isMarketing && <span className="custom-email">Custom -</span>}
                <EditionSubject subject={edition.editionSubject} />
                {edition.lastUpdatedByEmail && (
                  <EditionLastUpdate
                    lastUpdatedByEmail={edition.lastUpdatedByEmail}
                    lastUpdatedUnixTime={edition.lastUpdatedUnixTime}
                  />
                )}
              </div>
              {editionDisplayState === EDITION_DISPLAY_STATES.SENT &&
                (isPerformanceLoading || performanceStatistics) && (
                  <EditionPerformanceStatistics
                    performanceLoading={isPerformanceLoading}
                    performanceStatistics={performanceStatistics}
                  />
                )}
            </Col>
            <Col
              xs={12}
              lg={3}
              xl={2}
              className="d-flex justify-content-start justify-content-lg-center"
            >
              <div>
                <EditionState
                  editionDisplayState={editionDisplayState}
                  displayLabel={displayLabel}
                />
              </div>
            </Col>
            <Col xs={12} lg={3} xl={3} className="d-flex align-items-start">
              {scheduledUnixTime && finishedSendingTime && (
                <EditionSendTimes
                  scheduledUnixTime={scheduledUnixTime}
                  finishedSendingTime={finishedSendingTime}
                  editionState={edition.editionState}
                />
              )}
              {canDelete && (
                <Dropdown onClick={handleRemoveDropdown} className="ml-2">
                  <Dropdown.Toggle
                    variant="button"
                    className="p-0 border-0"
                    // Removes the caret
                    bsPrefix="p-0"
                  >
                    <MoreOptionsIcon size={5} color="gray.600" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight className="ebx-menu-dropdown">
                    <Dropdown.Item
                      as="button"
                      className="ebx-h3 dropdown-remove dropdown-button"
                      onClick={handleDeleteEdition}
                      disabled={isDeleting}
                    >
                      Remove
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </Row>

          {isErrorPanelVisible && (
            <div>
              {errorMessage !== '' && (
                <Box pb={2}>
                  <EditionErrorMessage errorMessage={errorMessage} />
                </Box>
              )}
            </div>
          )}
        </Box>
      </Flex>
    </EditionWrapper>
  );

  const linkTo = isMarketing
    ? `/marketing/${edition.editionURN}/edit`
    : `/editions/${edition.editionURN}/review`;

  return (
    <div>
      {(isEditionVisible || isStaffModeActive) && (
        <div>
          {isEditionClickable ? (
            <Link to={linkTo} className="edition-link">
              {editionContent}
            </Link>
          ) : (
            <div>{editionContent}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default Edition;
