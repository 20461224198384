/* eslint chai-friendly/no-unused-expressions: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Image,
  InfoIcon,
  Input,
  Modal,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  CAMPAIGN_SETTING_TYPES,
  DEFAULT_MEDIA_DATA_SOURCES,
} from 'common/constants';
import * as datetime from 'common/datetime';
import { DEFAULT_SECTION_VALUES, SECTION_TYPES } from 'common/enums';
import {
  CampaignSection,
  FixTypeLater,
  NewCampaignSection,
} from 'common/types';

import ArticleSettings from 'components/settings/campaigns/ArticleSettings';
import DataSources from 'components/settings/campaigns/DataSources';
import Syndfeeds from 'components/settings/campaigns/Syndfeeds';

import isEqual from 'fast-deep-equal';

const typeProps = {
  [SECTION_TYPES.ARTICLES]: {
    headerLabel: 'section',
    titlePlaceholder: 'Section 01',
    defaultTargetArticles: DEFAULT_SECTION_VALUES.MAX_ARTICLES,
    minTargetArticles: 1,
  },
  [SECTION_TYPES.ARTICLES_SPONSORED]: {
    headerLabel: 'sponsored content',
    titlePlaceholder: 'Sponsored',
    defaultTargetArticles: 0,
    minTargetArticles: 0,
  },
};

interface EditSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSave: (section: CampaignSection | NewCampaignSection) => void;
  section?: CampaignSection | null;
  newSection: boolean;
  sectionType: SECTION_TYPES;
  settings: FixTypeLater;
}

function EditSectionModal({
  isOpen,
  onClose,
  handleSave,
  section = null,
  newSection,
  sectionType,
  settings,
}: EditSectionModalProps) {
  const defaultCallToActionText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'callToActionText'
  );

  const sectionTypeProps =
    typeProps[sectionType] ?? typeProps[SECTION_TYPES.ARTICLES];

  const initializeSectionName = () => {
    if (section?.sectionName) {
      return section.sectionName;
    }
    if (newSection && sectionType === SECTION_TYPES.ARTICLES_SPONSORED) {
      return 'Sponsored';
    }
    return '';
  };

  const sectionURN = section?.sectionURN;
  const [mockSectionName, setMockSectionName] = useState(initializeSectionName);

  const [isValid, setIsValid] = useState(false);
  const [hideTitle, setHideTitle] = useState(
    section ? !section?.showTitle : false
  );

  const [buttonText, setButtonText] = useState(section?.callToActionText);

  const [maxArticles, setMaxArticles] = useState<number | null>(
    section?.targetArticles ?? sectionTypeProps.defaultTargetArticles
  );
  const [isAgeValid, setIsAgeValid] = useState(false);
  const [maxArticleAge, setMaxArticleAge] = useState(
    datetime.minsToDaysAndHours(section?.maxArticleAgeMins ?? null)
  );
  const [selected, setSelected] = useState(
    section?.syndFeeds ? section?.syndFeeds : []
  );

  const [useCustomDataSources, setUseCustomDataSources] = useState(
    section?.dataSources
      ? !isEqual(section?.dataSources, DEFAULT_MEDIA_DATA_SOURCES)
      : false
  );
  const [titleDataSource, setTitleDataSource] = useState(
    section?.dataSources.title ?? DEFAULT_MEDIA_DATA_SOURCES.title
  );
  const [descriptionDataSource, setDescriptionDataSource] = useState(
    section?.dataSources.description ?? DEFAULT_MEDIA_DATA_SOURCES.description
  );
  const [imageDataSource, setImageDataSource] = useState(
    section?.dataSources.image ?? DEFAULT_MEDIA_DATA_SOURCES.image
  );
  const handleButtonTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setButtonText(event.target.value);
  };

  const handleSectionNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMockSectionName(event.target.value);
  };

  const handleHideTitle = () => {
    setHideTitle(!hideTitle);
  };

  const checkSyndfeedValidity = useCallback(() => {
    if (
      (sectionType === SECTION_TYPES.ARTICLES_SPONSORED &&
        selected?.length === 0) ||
      (selected?.length > 0 && maxArticles && maxArticles > 0)
    ) {
      return true;
    }
    return false;
  }, [maxArticles, sectionType, selected]);

  useEffect(() => {
    if ((mockSectionName || hideTitle) && checkSyndfeedValidity()) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [mockSectionName, hideTitle, checkSyndfeedValidity]);

  useEffect(() => {
    if (datetime.daysAndHoursToMins(maxArticleAge)) {
      setIsAgeValid(true);
    } else {
      sectionType === SECTION_TYPES.ARTICLES_SPONSORED && selected?.length === 0
        ? setIsAgeValid(true)
        : setIsAgeValid(false);
    }
  }, [maxArticleAge, sectionType, selected]);

  const handlePrepareSectionForSave = () => {
    const sectionUsed = {
      callToActionText: buttonText,
      sectionURN,
      sectionName: mockSectionName,
      showTitle: !hideTitle,
      syndFeeds: selected,
      targetArticles: maxArticles || 0,
      maxArticleAgeMins: datetime.daysAndHoursToMins(maxArticleAge),
      sectionType,
      dataSources: useCustomDataSources
        ? {
            title: titleDataSource,
            description: descriptionDataSource,
            image: imageDataSource,
          }
        : DEFAULT_MEDIA_DATA_SOURCES,
    };
    handleSave(sectionUsed);
  };

  const buttonTextError = buttonText ? buttonText.length > 120 : false;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title color="gray.600">
          {newSection ? `Add` : `Edit`} {sectionTypeProps.headerLabel}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl>
          <Flex alignItems="center" justifyContent="space-between" mb={2}>
            <Heading variant="h5">Title</Heading>
            <Checkbox
              id="hide-title"
              defaultChecked={hideTitle}
              onChange={handleHideTitle}
            >
              Hide title
            </Checkbox>
          </Flex>
          <Input
            disabled={hideTitle}
            type="text"
            placeholder={sectionTypeProps.titlePlaceholder}
            maxLength={120}
            value={mockSectionName}
            onChange={handleSectionNameChange}
          />
        </FormControl>
        <Modal.Divider />
        <Syndfeeds selected={selected} setSelected={setSelected} />
        <ArticleSettings
          maxArticleAge={maxArticleAge}
          maxArticles={maxArticles}
          minTargetArticles={sectionTypeProps.minTargetArticles}
          setMaxArticles={setMaxArticles}
          setMaxArticleAge={setMaxArticleAge}
        />
        <Modal.Divider />
        <DataSources
          useCustomDataSources={useCustomDataSources}
          setUseCustomDataSources={setUseCustomDataSources}
          titleDataSource={titleDataSource}
          setTitleDataSource={setTitleDataSource}
          descriptionDataSource={descriptionDataSource}
          setDescriptionDataSource={setDescriptionDataSource}
          imageDataSource={imageDataSource}
          setImageDataSource={setImageDataSource}
        />
        <Modal.Divider />
        <FormControl mb={0} isInvalid={buttonTextError}>
          <Flex gap={1} alignItems="center" mb={2}>
            <Heading variant="h5">Button text</Heading>
            <Tooltip
              label={
                <Image
                  src="/assets/img/call-to-action-text-helper.png"
                  alt="Call to action text helper"
                  maxWidth="400px"
                />
              }
              p={5}
              maxWidth="440px"
              placement="top"
            >
              <InfoIcon color="gray.500" />
            </Tooltip>
          </Flex>
          <Input
            type="text"
            maxLength={999}
            placeholder={defaultCallToActionText}
            value={buttonText}
            onChange={handleButtonTextChange}
            id="cta-text"
          />
          {buttonTextError && (
            <FormControl.FormErrorMessage>
              Text cannot be longer than 120 characters
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handlePrepareSectionForSave}
          isDisabled={!(isValid && isAgeValid && !buttonTextError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditSectionModal;
