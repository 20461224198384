import {
  Button,
  Dropdown,
  Flex,
  Heading,
  LightingHighlightIcon,
  Portal,
  Tag,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as string from 'common/string';

interface SuggestionsListProps {
  options: string[];
  setAISubjectLine: (newSubject: string) => void;
}

export function SuggestionsList({
  options,
  setAISubjectLine,
}: SuggestionsListProps) {
  const ListProps = {
    bg: 'gray.100',
    maxWidth: '376px',
    mt: 1,
    p: 4,
  };
  const ItemProps = {
    bg: 'gray.100',
    _hover: {
      bg: 'white',
      border: '1px',
      borderColor: 'gray.300',
      borderStyle: 'solid',
    },
    border: '1px',
    borderColor: 'gray.500',
    borderRadius: 'md',
    borderStyle: 'dashed',
    fontWeight: 400,
    px: 4,
    py: 3,
  };

  return (
    <Portal>
      <Dropdown.List {...ListProps} zIndex={7}>
        <Heading variant="h6" mb={3}>
          SUGGESTED SUBJECTS
        </Heading>
        <Flex direction="column" gap={4}>
          {options.map(option => (
            <Dropdown.Item
              onClick={() => setAISubjectLine(option)}
              {...ItemProps}
              key={`suggestion-${string.simpleHash(option)}`}
            >
              {option}
            </Dropdown.Item>
          ))}
        </Flex>
      </Dropdown.List>
    </Portal>
  );
}

interface InspireMeProps extends SuggestionsListProps {
  hasSeenAISubjectLines: boolean;
  handleOpenAISubjectLines: () => void;
}

function InspireMe({
  options,
  setAISubjectLine,
  hasSeenAISubjectLines,
  handleOpenAISubjectLines,
}: InspireMeProps) {
  const handleClick = (isOpen: boolean) => {
    if (!isOpen) {
      handleOpenAISubjectLines();
    }
  };

  const ButtonProps = {
    _active: {
      bg: 'gray.100',
    },
  };

  return (
    <Flex gap={3}>
      <Dropdown>
        {({ isOpen }) => (
          <>
            <Dropdown.Button
              as={Button}
              variant="secondary"
              onClick={() => handleClick(isOpen)}
              {...ButtonProps}
            >
              <Flex gap={2} alignItems="center">
                <LightingHighlightIcon />
                Inspire me
                {!hasSeenAISubjectLines && (
                  <Tag color="blue">{options.length}</Tag>
                )}
              </Flex>
            </Dropdown.Button>
            <SuggestionsList
              options={options}
              setAISubjectLine={setAISubjectLine}
            />
          </>
        )}
      </Dropdown>
    </Flex>
  );
}

export default InspireMe;
