import {
  ArrowDownloadIcon,
  Button,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface ExportHTMLProps {
  handleExport: () => void;
}

function ExportHTML({ handleExport }: ExportHTMLProps) {
  return (
    <Tooltip label="Export email as HTML" placement="top">
      <Button variant="secondary" onClick={handleExport} py={2.5}>
        <ArrowDownloadIcon />
      </Button>
    </Tooltip>
  );
}

export default ExportHTML;
