import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import type { Permission } from 'common/types';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import { REGISTRATION_URL } from 'common/constants';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface PostPropertiesUserArgs {
  propertyURN: string;
  fullName: string;
  emailAddress: string;
  permissionsOnProperty: Permission[];
}

export default async function postPropertiesUser({
  propertyURN,
  fullName,
  emailAddress,
  permissionsOnProperty,
}: PostPropertiesUserArgs) {
  if (isUndefined(propertyURN)) {
    mandatory('propertyURN');
  }
  if (isUndefined(fullName)) {
    mandatory('fullName');
  }
  if (isUndefined(emailAddress)) {
    mandatory('emailAddress');
  }
  if (isUndefined(permissionsOnProperty)) {
    mandatory('permissionsOnProperty');
  }

  const guid = metrics.start('postPropertiesUser');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/users`;

  const config: AxiosRequestConfig = {
    url,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      name: fullName,
      username: emailAddress,
      emailAddress,
      permissionsOnProperty,
      registrationURL: REGISTRATION_URL,
    }),
  };
  logger.info(
    `API:postPropertiesUser /properties/${propertyURN}/users ${emailAddress}`
  );

  try {
    const { data } = await axios(config);
    metrics.end('postPropertiesUser', guid);
    return data;
  } catch (error) {
    metrics.fail('postPropertiesUser', guid);
    // If the error is a 422, just rethrow
    // @ts-ignore
    if (error.response.status === 422) {
      throw error;
    }
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postPropertiesUsers',
            args: {
              propertyURN,
              fullName,
              emailAddress,
              permissionsOnProperty,
            },
          })
        : error;
    throw apiError;
  }
}
