/* eslint no-control-regex: "off" */

import { Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Form, FormControl, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { StoreContext } from 'store/store';

import * as api from 'api/api';
import * as campaigns from 'common/campaigns';
import { getErrorMessage } from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';

import { FORM_VALIDATION_MODES, FRONTEND_FORM_STATES } from 'common/enums';

const InputSchema = Yup.object().shape({
  campaignName: Yup.string()
    .trim()
    .max(1000, 'Max length is 1000!')
    .required('Campaign name is required'),
});

function Input({ show, handleClose, setLocalState }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(InputSchema),
    mode: FORM_VALIDATION_MODES.ONSUBMIT,
    reValidateMode: FORM_VALIDATION_MODES.ONSUBMIT,
  });

  const { actions, state } = useContext(StoreContext);
  const { globalInfo } = state;

  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const campaignList = campaigns.getPropertyCampaigns(propertyURN, globalInfo);

  const [isCreating, setCreating] = useState(false);
  const [campaignName, setCampaignName] = useState('');

  useEffect(() => {
    setCreating(false);
    setTimeout(() => {
      document.getElementById('campaignName')?.focus();
    }, 100);
  }, [show]);

  const handleCreateCampaign = async () => {
    // Getting weird validation issues so we do the validation here manually
    let validatedCampaignName;
    try {
      const result = await InputSchema.validate({ campaignName });
      validatedCampaignName = result.campaignName;
    } catch (error) {
      setError('campaignName', {
        type: 'manual',
        message: error.errors[0],
      });
      return;
    }

    const matchingCampaign = campaignList.find(
      campaign => campaign.campaignName === validatedCampaignName
    );
    if (matchingCampaign !== undefined) {
      setError('campaignName', {
        type: 'manual',
        message: 'The Campaign name is already in use',
      });
      return;
    }

    logger.info('View:handleCreateCampaign');
    setCreating(true);

    try {
      const { campaignURN } = await api.postCampaigns({
        propertyURN,
        campaignDetails: {
          campaignName,
        },
      });
      tracker.track({
        eventName: 'Create Campaign',
        trackingParams: {
          'Campaign Name': validatedCampaignName,
        },
        options: {
          labelCampaignAsPrevious: true,
        },
      });
      // Change to the new campaign
      actions.changeCampaign(campaignURN, propertyURN);
      setLocalState(FRONTEND_FORM_STATES.SUCCESS);
    } catch (error) {
      setCreating(false);
      if (
        error.response?.status === 422 &&
        error.response.data?.target === 'campaignName'
      ) {
        setError('campaignName', {
          type: 'manual',
          message: 'The Campaign name is already in use',
        });
      } else {
        setError('campaignName', {
          type: 'manual',
          message: getErrorMessage(error),
        });
      }
    }
  };

  const handleChange = event => {
    setCampaignName(event.target.value);
    errors.campaignName = null;
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Create Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate={true} onSubmit={handleSubmit(handleCreateCampaign)}>
          <Form.Group>
            <FormLabel className="ebx-h3 mb-1">Campaign name</FormLabel>
            <FormControl
              name="campaignName"
              id="campaignName"
              {...register('campaignName')}
              required
              autoFocus
              className={`ebx-input ebx-body-1 ${
                errors.campaignName ? 'ebx-input-error' : ''
              }`}
              onChange={handleChange}
              type="text"
              disabled={isCreating}
            />
            {errors.campaignName && (
              <div className="d-flex align-items-center error--text mt-2 mb-2">
                <span>{errors.campaignName.message}</span>
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          isDisabled={isCreating || !campaignName || errors.campaignName}
          onClick={handleSubmit(handleCreateCampaign)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </>
  );
}

Input.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setLocalState: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Input;
