/* eslint prefer-destructuring: "off" */
/* eslint react/destructuring-assignment: "off" */

import PropTypes from 'prop-types';

import { REACT_PREVENT_RENDER } from 'common/constants';

/*
 * Validation error
 */

function ValidationError(props) {
  if (props.errorMessage === '') {
    return REACT_PREVENT_RENDER;
  }
  let url = props.url;
  if (url !== '') {
    // Not all URLs may start with http
    url = url.startsWith('http') ? url : `http://${url}`;
  }

  return (
    <table
      className={`alert_message alert_message_bad error_notification ${
        props.className ?? ''
      }`}
    >
      <div className="mb-3">
        <img className="icon-error" src="/img/icons/ic-error-red.svg" alt="" />
        <span data-cy-id={props.dataCyId}>{props.errorMessage}</span>
        {url !== '' && (
          <span>
            &nbsp;
            <a href={url} target="_blank" rel="noopener noreferrer">
              {props.url}
            </a>
          </span>
        )}
      </div>
    </table>
  );
}

ValidationError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  url: PropTypes.string,
  dataCyId: PropTypes.string,
};

ValidationError.defaultProps = {
  className: null,
  url: '',
  dataCyId: 'errorMessage',
};

export default ValidationError;
