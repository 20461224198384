import {
  Flex,
  Heading,
  Image,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as campaigns from 'common/campaigns';
import useGlobalInfo from 'hooks/useGlobalInfo';

function SenderDetails() {
  const globalInfo = useGlobalInfo();
  const currentCampaign = campaigns.getCurrentCampaign(globalInfo);

  if (currentCampaign.bimiImageUrl) {
    return (
      <Flex alignItems="center" gap={2}>
        <Image
          src={currentCampaign.bimiImageUrl}
          alt={currentCampaign.campaignName}
          fit="contain"
          width="40px"
          height="40px"
          borderRadius="full"
        />
        <Heading variant="h3">{currentCampaign.fromName}</Heading>
        <Text size="md" color="gray.600">
          &lt;{currentCampaign.fromEmail}&gt;
        </Text>
      </Flex>
    );
  }

  return <Heading variant="h3">Preview</Heading>;
}

export default SenderDetails;
