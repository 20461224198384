import { RecoilRoot } from 'recoil';

import StoreWrapper from 'containers/StoreWrapper';

function ApplicationWrapper() {
  return (
    <RecoilRoot>
      <StoreWrapper />
    </RecoilRoot>
  );
}

export default ApplicationWrapper;
