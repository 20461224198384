import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import EmailContent from 'components/editor/html/EmailContent';
import EmailSubject from 'components/editor/html/EmailSubject';

import * as editions from 'common/editions';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import './EditorBody.css';

function EditorBody({
  emailDetails,
  editionDisplayState,
  templateDetails,
  handleUpdateEmail,
  handleUpdateTemplate,
  isSaving,
  isSending,
}) {
  const canEdit = editions.checkIsEditionEditable(editionDisplayState);

  const handleUpdateSubject = async (updatedSubject, callback) => {
    await handleUpdateEmail({
      ...emailDetails,
      editionSubject: updatedSubject,
    });
    if (typeof callback === 'function') {
      callback();
    }
  };

  return (
    <>
      <Box py={8} px={10}>
        <Col className="ebx-h-100 pl-0">
          <Box lineHeight="inherit">
            <EmailSubject
              emailSubject={emailDetails.editionSubject}
              handleUpdateSubject={handleUpdateSubject}
              isBusy={isSaving || isSending}
              isEditable={canEdit}
            />
          </Box>
        </Col>
      </Box>
      <div className="editor-body-bottom-section">
        <EmailContent
          handleUpdateTemplate={handleUpdateTemplate}
          isBusy={isSaving || isSending}
          isEditable={canEdit}
          templateDetails={templateDetails}
        />
      </div>
    </>
  );
}

EditorBody.propTypes = {
  emailDetails: PropTypes.shape({
    editionURN: PropTypes.string.isRequired,
    editionState: PropTypes.string.isRequired,
    editionSubject: PropTypes.string.isRequired,
  }).isRequired,
  editionDisplayState: PropTypes.string.isRequired,
  templateDetails: PropTypes.shape({
    templateURN: PropTypes.string.isRequired,
    campaignURN: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  handleUpdateEmail: PropTypes.func.isRequired,
  handleUpdateTemplate: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isSending: PropTypes.bool.isRequired,
};

export default EditorBody;
