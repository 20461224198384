/* eslint no-use-before-define: "off" */

import { createTimestamp, getDate, toLocalString } from 'common/datetimepicker';
import * as i18n from 'common/i18n';
import { cloneObject, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import { TEXT_CASES } from 'common/constants';

export {
  createTimeRange,
  DAYS,
  endOfToday,
  formatEditionDate,
  getDateFromUnix,
  getAbbreviatedDateFromUnix,
  getFormattedDateFromUnix,
  getFormattedDuration,
  getFormattedTimeFromParts,
  getFormattedTimeFromUnix,
  getFormattedTimePartsFromUnix,
  getFormattedTimeslotsFromUnix,
  getFormattedShareTimeFromUnix,
  getSpecificTime,
  getTimeFilterRange,
  getUnixTimestamp,
  getUnixTimestampFromOffset,
  HOUR,
  HOURS,
  MINUTES,
  NOW,
  DAY,
  localeDateString,
  roundToInterval,
  startOfToday,
  getTimeOffset,
  minsToDaysAndHours,
  daysAndHoursToMins,
};

function createTimeRange(from, to) {
  // Input - from and to times, either of which could be undefined
  // Output - empty if neither parameter is defined
  //          "from" if just one parameter is supplied
  //          "from,to" if both parameters are supplied
  if (isNullOrUndefined(from)) {
    return '';
  }
  if (isNullOrUndefined(to)) {
    return `${from}`;
  }
  return `${from},${to}`;
}

const DAY = 60 * 60 * 24; // Number of seconds in one day

const DAYS = days => DAY * days; // Number of seconds in the specified number of days

/**
 * endOfToday - Returns a Unix timestamp representing the end of today
 */

function endOfToday() {
  return createTimestamp({
    date: getDate(toLocalString(getUnixTimestamp())),
    time: '23:59',
  });
}

function formatEditionDate(timestamp) {
  const dateParts = getDateFromUnix(timestamp);
  dateParts.dayZero = dateParts.day;
  dateParts.monthZero = dateParts.month;
  if (dateParts.day.toString().length < 2) {
    dateParts.dayZero = `0${dateParts.day}`;
  }
  if (dateParts.month.toString().length < 2) {
    dateParts.monthZero = `0${dateParts.month}`;
  }
  return `${dateParts.year}${dateParts.monthZero}${dateParts.dayZero}${dateParts.hourZero}${dateParts.minuteZero}`;
}

/**
 * getDateFromUnix - Returns a date object from a Unix timestamp
 *
 * @param {number} - timestamp
 * @return {object} - object containing date details:
 *                    hour - hour 0-23
 *                    hourZero - hour as string including leading zero if required e.g. 04, 13
 *                    minute - minute 0-59
 *                    minuteZero - minute as string including leading zero if required e.g. 03, 55
 *                    day - day 1-31
 *                    dayName - day name e.g. Monday, Friday
 *                    month - month 1-12
 *                    monthName - month name e.g. March, September
 *                    year - year (four digits)
 *                    slang - 'Yesterday', 'Today', 'Tomorrow' or empty
 */

function getDateFromUnix(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const dayOfWeek = date.getDay();
  let dayName = '';
  switch (dayOfWeek) {
    case 0:
      dayName = 'Sunday';
      break;
    case 1:
      dayName = 'Monday';
      break;
    case 2:
      dayName = 'Tuesday';
      break;
    case 3:
      dayName = 'Wednesday';
      break;
    case 4:
      dayName = 'Thursday';
      break;
    case 5:
      dayName = 'Friday';
      break;
    case 6:
      dayName = 'Saturday';
      break;
    default:
  }

  let month = date.getMonth();
  let monthName = '';
  switch (month) {
    case 0:
      monthName = 'January';
      break;
    case 1:
      monthName = 'February';
      break;
    case 2:
      monthName = 'March';
      break;
    case 3:
      monthName = 'April';
      break;
    case 4:
      monthName = 'May';
      break;
    case 5:
      monthName = 'June';
      break;
    case 6:
      monthName = 'July';
      break;
    case 7:
      monthName = 'August';
      break;
    case 8:
      monthName = 'September';
      break;
    case 9:
      monthName = 'October';
      break;
    case 10:
      monthName = 'November';
      break;
    case 11:
      monthName = 'December';
      break;
    default:
  }
  month += 1;

  const year = date.getFullYear();

  // Work out if we need to adjust the result due to any time zone changes
  // that have occurred between now and the target date
  const startOfDay = new Date().setHours(0, 0, 0, 0);
  const timeZoneOffsets = {
    now: new Date(startOfDay).getTimezoneOffset() * 60 * 1000,
    then: date.getTimezoneOffset() * 60 * 1000,
  };
  const timeZoneDifference = timeZoneOffsets.then - timeZoneOffsets.now;
  const dateOffset = Math.floor(
    (date - startOfDay - timeZoneDifference) / (24 * 60 * 60 * 1000)
  );

  let slang = '';
  if (dateOffset === 0) {
    slang = 'Today';
  } else if (dateOffset === 1) {
    slang = 'Tomorrow';
  } else if (dateOffset === -1) {
    slang = 'Yesterday';
  } else {
    slang = '';
  }

  // Add leading zeros to minutes & hours if < 10
  let minuteZero = '';
  let hourZero = '';
  if (minute.toString().length < 2) {
    // Add leading zeros
    minuteZero = `0${minute}`;
  } else {
    minuteZero = minute.toString();
  }

  if (hour.toString().length < 2) {
    // Add leading zeros
    hourZero = `0${hour}`;
  } else {
    hourZero = hour.toString();
  }

  const dateObject = {
    hour,
    hourZero,
    minute,
    minuteZero,
    day,
    dayName,
    month,
    monthName,
    year,
    slang,
    dateOffset,
  };
  return dateObject;
}

/**
 * getAbbreviatedDateFromUnix - Returns a date object from a Unix timestamp
 *
 * @param {number} - timestamp
 * @return {string} - abbreviated date in the DD/MM/YY format
 */

function getAbbreviatedDateFromUnix(timestamp) {
  const date = new Date(timestamp * 1000);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;
  year = year.toString().slice(-2);

  return `${day}/${month}/${year}`;
}

/**
 * getFormattedDateFromUnix - Returns formatted date string from a Unix timestamp
 *
 * @param {{
 *  timestamp: number;
 *  twelveHourFormat?: boolean;
 *  textCase?: string;
 *  useLongForm?: boolean;
 *  alwaysShowTime?: boolean;
 * }} - timestamp - date/time to be formatted
 *    - twelveHourFormat - flag indicating whether 12-hour format should be used
 * @return {string} - examples:
 *                    'Today 14:00'
 *                    'Yesterday 3:45pm'
 *                    '21 Mar, 09:30'
 */

function getFormattedDateFromUnix({
  timestamp = mandatory('timestamp'),
  twelveHourFormat = false,
  textCase = TEXT_CASES.CAMEL,
  useLongForm = false,
  alwaysShowTime = null,
} = {}) {
  const isMoreThanMonthOld = getUnixTimestamp() - timestamp > 30 * 24 * 60 * 60;
  const showTime = alwaysShowTime ?? !isMoreThanMonthOld;

  const { timeDetail, ampm } = getFormattedTimePartsFromUnix({
    timestamp,
    twelveHourFormat,
    textCase,
  });
  timeDetail.ampm = ampm;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const showYear = timeDetail.year !== currentYear;

  let at;
  let on;
  if (textCase === TEXT_CASES.UPPER) {
    timeDetail.slang = timeDetail.slang.toUpperCase();
    at = 'AT';
    on = 'ON';
    timeDetail.ampm = timeDetail.ampm.toUpperCase();
  } else if (textCase === TEXT_CASES.LOWER) {
    timeDetail.slang = timeDetail.slang.toLowerCase();
    at = 'at';
    on = 'on';
  } else {
    at = 'at';
    on = 'On';
  }

  const time = showTime
    ? `${timeDetail.hourZero}:${timeDetail.minuteZero}${timeDetail.ampm}`
    : '';
  const year = showYear ? timeDetail.year : '';

  if (!useLongForm) {
    if (timeDetail.slang !== '') {
      return `${timeDetail.slang}${time !== '' ? ' ' : ''}${time}`;
    }

    const shortenedMonthName = timeDetail.monthName.substring(0, 3);
    return `${timeDetail.day} ${shortenedMonthName}${
      year !== '' ? ' ' : ''
    }${year}${time !== '' ? ' ' : ''}${time}`;
  }

  if (!showTime) {
    at = ',';
  }

  if (!time) {
    at = '';
  }

  const dayDigit = timeDetail.day % 10;
  let formattedDay = '';

  if (timeDetail.day === 11 || timeDetail.day === 12 || timeDetail.day === 13) {
    formattedDay = `${timeDetail.day}th`;
  } else if (dayDigit === 1) {
    formattedDay = `${timeDetail.day}st`;
  } else if (dayDigit === 2) {
    formattedDay = `${timeDetail.day}nd`;
  } else if (dayDigit === 3) {
    formattedDay = `${timeDetail.day}rd`;
  } else {
    formattedDay = `${timeDetail.day}th`;
  }

  if (timeDetail.slang !== '') {
    return `${timeDetail.slang} ${at} ${time}`;
  }

  return `${on} the ${formattedDay} of ${timeDetail.monthName} ${year}${
    year !== '' ? ' ' : ''
  }${at} ${time}`;
}

/**
 * getFormattedDuration - Returns a value as a whole number of seconds, minutes,
 *                        hours or days (whichever is the most appropriate)
 *
 * @param {number} duration - duration in seconds
 * @return {string} - String representation in the appropriate order of magnitude - examples:
 *                    '45 seconds' - when duration is between 0 and 59 seconds
 *                    '3 minutes' - when duration is between 60 and 3,599 seconds
 *                    '6 hours' - when duration is between 3,600 and 86,399 seconds
 *                    '14 days' - when duration is 86,400 seconds or more
 */

function getFormattedDuration(duration) {
  try {
    const seconds = parseInt(Number(duration), 10);
    if (seconds < 60) {
      return `${seconds} seconds`;
    }
    if (seconds < 3600) {
      return `${parseInt(seconds / 60, 10)} minutes`;
    }
    if (seconds < 86400) {
      return `${parseInt(seconds / 3600, 10)} hours`;
    }
    return `${parseInt(seconds / 86400, 10)} days`;
  } catch (error) {
    return null;
  }
}

/**
 * getFormattedShareTimeFromUnix - Returns a textual description of an
 *                                 estimated share time
 *
 * @param {number} timestamp - Unix timestamp
 * @param {boolean} twelveHourFormat - Use 12-hour time format yes/no
 * @return {string} - String representation of specific time - examples:
 *                    'in < 5 min'
 *                    'in ~ 5 min'
 *                    'in ~ 10 min'
 *                    'in ~ 15 min'
 *                    'in ~ 30 min'
 *                    'in ~ 45 min'
 *                    'in ~ 1h'
 *                    'in ~ 2h'
 *                    'in ~ 3h'
 *                    'today at ~ 1:30pm'
 *                    'tomorrow at ~ 18:45'
 */

function getFormattedShareTimeFromUnix({
  timestamp = mandatory('timestamp'),
  twelveHourFormat = false,
} = {}) {
  // Calculate difference (in seconds) between now and the specified time
  const timeDifference = timestamp - getUnixTimestamp();

  // Work out how to render this as a time difference string
  let result;
  const MINUTES = 60;
  if (timeDifference < 5 * MINUTES) {
    // Less than five minutes
    result = 'in <5 min';
  } else if (timeDifference < 7 * MINUTES) {
    // Less than seven minutes
    result = 'in ~5 min';
  } else if (timeDifference < 12 * MINUTES) {
    // Less than seven minutes
    result = 'in ~10 min';
  } else if (timeDifference < 17 * MINUTES) {
    // Less than seven minutes
    result = 'in ~15 min';
  } else if (timeDifference < 35 * MINUTES) {
    // Less than seven minutes
    result = 'in ~30 min';
  } else if (timeDifference < 50 * MINUTES) {
    // Less than seven minutes
    result = 'in ~45 min';
  } else if (timeDifference < 75 * MINUTES) {
    // Less than seven minutes
    result = 'in ~1h';
  } else if (timeDifference < 135 * MINUTES) {
    // Less than seven minutes
    result = 'in ~2h';
  } else if (timeDifference < 195 * MINUTES) {
    // Less than seven minutes
    result = 'in ~3h';
  } else {
    result = getFormattedDateFromUnix({
      timestamp,
      twelveHourFormat,
      textCase: TEXT_CASES.LOWER, // textCase
      useLongForm: true, // useLongForm
    });
  }

  return result;
}

/**
 * getFormattedTimeFromParts - Returns formatted time string from time parts
 * @param {{
 *  hour: number;
 *  minutes?: number;
 * }} args
 * @returns {string} - String representation of specified timestamp - example: 13:00
 */
function getFormattedTimeFromParts({ hour = mandatory('hour'), minutes = 0 }) {
  if (hour === 24) {
    return '00:00';
  }
  const leadingZeroHour = hour < 10 ? '0' : '';
  const leadingZeroMinute = minutes < 10 ? '0' : '';
  return `${leadingZeroHour}${hour}:${leadingZeroMinute}${minutes}`;
}

/**
 * getFormattedTimeFromUnix - Returns formatted time string from a Unix timestamp
 *
 * @param {object} unixObject - Object containing various elements:
 *                              unixObject.timestamp - Unix timestamp
 *                              unixObject.twelveHourFormat -  Use 12-hour time format yes/no
 *                              unixObject.textCase - Specifies case to use when formatting string
 * @return {string} - String representation of specified timestamp - examples:
 *                    '13:00'
 *                    '1:00pm'
 */

function getFormattedTimeFromUnix({
  timestamp = mandatory('timestamp'),
  twelveHourFormat = false,
  textCase = TEXT_CASES.CAMEL,
}) {
  const { timeDetail, ampm } = getFormattedTimePartsFromUnix({
    timestamp,
    twelveHourFormat,
    textCase,
  });
  timeDetail.ampm = ampm;

  if (textCase === TEXT_CASES.UPPER) {
    timeDetail.ampm = timeDetail.ampm.toUpperCase();
  }

  return `${timeDetail.hourZero}:${timeDetail.minuteZero}${timeDetail.ampm}`;
}

/**
 * getFormattedTimePartsFromUnix - Returns formatted time string from a Unix timestamp
 *
 * @param {number} timestamp  - Unix timestamp
 * @param {boolean} twelveHourFormat - Use 12-hour time format yes/no
 * @param {number} textCase - Specifies case to use when formatting string
 * @return {object} - Object containing various elements:
 *                    timeDetail.hour - hour 0-23
 *                    timeDetail.hourZero - hour as string including leading zero if required e.g. 04, 13
 *                    timeDetail.minute - minute 0-59
 *                    timeDetail.minuteZero - minute as string including leading zero if required e.g. 03, 55
 *                    timeDetail.slang - 'Yesterday', 'Today', 'Tomorrow' or empty
 *                    ampm - 'am' or 'pm' (or 'AM' or 'PM' if text case is UPPER)
 */

function getFormattedTimePartsFromUnix({
  timestamp = mandatory('timestamp'),
  twelveHourFormat = false,
  textCase = TEXT_CASES.CAMEL,
}) {
  const timeDetail = getDateFromUnix(timestamp);

  let ampm = '';
  if (twelveHourFormat === true) {
    timeDetail.hourZero = timeDetail.hour;
    // Check if am/pm setting switched on
    if (timeDetail.hour >= 12) {
      ampm = 'pm';
      if (timeDetail.hour > 12) {
        timeDetail.hourZero = timeDetail.hour - 12;
      }
    } else {
      ampm = 'am';
    }
    // Quick fix here for the first hour of the day which comes up as 00 when it should be 12
    if (timeDetail.slang !== '' && ampm === 'am' && timeDetail.hour === 0) {
      timeDetail.hourZero = '12';
    }
  }

  if (timeDetail.slang !== '') {
    let { slang } = timeDetail;
    if (textCase === TEXT_CASES.UPPER) {
      slang = slang.toUpperCase();
    } else if (textCase === TEXT_CASES.LOWER) {
      slang = slang.toLowerCase();
    }
    timeDetail.slang = slang;
  }

  return {
    timeDetail,
    ampm,
  };
}

/**
 * getFormattedTimeslotsFromUnix - Returns textual description of timeslot
 *
 * @param {number} minTime - Unix timestamp, start of timeslot
 * @param {number} maxTime - Unix timestamp, end of timeslot
 * @param {boolean} twelveHourFormat - Use 12-hour time format yes/no
 * @return {string} - String representation of specified timeslot - examples:
 *                    'Today, 12:00 to 15:00'
 *                    'Today, 12:00 to Tomorrow, 12:00'
 *                    'Tomorrow, 3.30pm to Apr 25, 9:00am'
 *                    'Jun 18, 6:00pm to 9:00pm'
 */

function getFormattedTimeslotsFromUnix({
  minTime = mandatory('minTime'),
  maxTime = mandatory('maxTime'),
  twelveHourFormat = false,
} = {}) {
  const minDetails = getDateFromUnix(minTime);
  const maxDetails = getDateFromUnix(maxTime);
  const minDayString = getFormattedDateFromUnix({
    timestamp: minTime,
    twelveHourFormat,
  });
  const maxDayString = getFormattedDateFromUnix({
    timestamp: maxTime,
    twelveHourFormat,
  });

  const NOW = getUnixTimestamp();
  const THIRTY_DAYS_AGO = 30 * 24 * 60 * 60;

  if (NOW - maxTime > THIRTY_DAYS_AGO && NOW - minTime > THIRTY_DAYS_AGO) {
    if (maxDetails.day === minDetails.day) {
      return minDayString;
    }
    const minDayTimeString = getFormattedTimeFromUnix({
      timestamp: minTime,
      twelveHourFormat,
    });
    return `${minDayTimeString} - ${maxDayString}`;
  }

  if (maxDetails.day === minDetails.day) {
    const maxDayTimeString = getFormattedTimeFromUnix({
      timestamp: maxTime,
      twelveHourFormat,
    });
    return `${minDayString} - ${maxDayTimeString}`;
  }

  return `${minDayString} - ${maxDayString}`;
}

/**
 * getSpecificTime - Returns a timestamp for the current date/time rounded up
 *                   to the nearest five-minute interval
 *
 * @return {number} timestamp
 */

function getSpecificTime() {
  // Get current date/time
  let datetimeNow = getDateFromUnix(getUnixTimestamp());
  // Round up to the nearest five minute interval
  datetimeNow = roundToInterval(datetimeNow);
  return getUnixTimestampFromOffset(datetimeNow);
}

/**
 * getTimeFilterRange - Returns from/to range to use as date filter by share and analytics pages
 *
 * @param {number} - timeRange - number of hours to go back
 * @return {object} - { fromTime, toTime } - timestamps to use for date range
 */

function getTimeFilterRange(timeRange) {
  const fromTime = getUnixTimestamp() - timeRange * 60 * 60;
  const toTime = null;
  return { fromTime, toTime };
}

/**
 * getUnixTimestamp - Returns (current) JavaScript date/time as Unix timestamp
 *
 * @param {object} - dateObj - date object, if not supplied defaults to now
 * @return {number} - Unix timestamp
 */

function getUnixTimestamp(dateObj = new Date()) {
  return Math.round(dateObj.getTime() / 1000);
}

/**
 * getUnixTimestampFromOffset - Returns Unix timestamp date specified as day / hour / minute offsets
 * WARNING: This is the offset from midnight of the current date in the user's local timezone
 * @param {object} - date offset object
 * @return {number} - Unix timestamp
 */

function getUnixTimestampFromOffset(offset) {
  return (
    new Date().setHours(0, 0, 0, 0) / 1000 +
    offset.dateOffset * 24 * 60 * 60 +
    offset.hour * 60 * 60 +
    offset.minute * 60
  );
}

const HOUR = 60 * 60; // Number of seconds in one hour

const HOURS = hours => HOUR * hours; // Number of seconds in the specified number of hours

const MINUTES = minutes => minutes * 60; // Number of seconds in the specified number of minutes

const NO_OF_MINUTES_IN_HOUR = 60;

const NO_OF_MINUTES_IN_DAY = 1440;

const NOW = getUnixTimestamp();

function localeDateString(timestamp) {
  const locale = i18n.getBrowserLocale();
  return new Date(timestamp * 1000).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}

function roundToInterval(timeObject) {
  const timeRounded = cloneObject(timeObject);
  const timeToInterval = timeRounded.minute % 5;
  // Round the minutes down to the nearest five minute interval
  timeRounded.minute -= timeToInterval;
  // Now add five minutes
  timeRounded.minute += 5;
  // Add another five minutes if we are within two minutes of the next interval
  if (timeToInterval >= 3) {
    timeRounded.minute += 5;
  }
  // Update hours / days if necessary
  if (timeRounded.minute >= 60) {
    timeRounded.minute -= 60;
    timeRounded.hour += 1;
    if (timeRounded.hour >= 24) {
      timeRounded.hour -= 24;
      timeRounded.dateOffset += 1;
    }
  }
  // Update display minutes and hours fields
  if (timeRounded.minute.toString().length < 2) {
    timeRounded.minuteZero = `0${timeRounded.minute}`;
  } else {
    timeRounded.minuteZero = timeRounded.minute.toString();
  }
  if (timeRounded.hour.toString().length < 2) {
    timeRounded.hourZero = `0${timeRounded.hour}`;
  } else {
    timeRounded.hourZero = timeRounded.hour.toString();
  }

  return timeRounded;
}

/**
 * startOfToday - Returns a Unix timestamp representing the start of today
 */

function startOfToday() {
  return createTimestamp({
    date: getDate(toLocalString(getUnixTimestamp())),
    time: '00:00',
  });
}

/**
 * getTimeOffset - Returns Unix timestamp of Date object offset by a given amount
 *
 * @param {object} - date object
 * @param {number} - time offset in minutes
 * @return {number} - Unix timestamp
 */

function getTimeOffset(dateObj, timeShift) {
  return new Date(getUnixTimestamp(dateObj) * 1000).setMinutes(
    dateObj.getMinutes() + timeShift
  );
}

/**
 * minsToDaysAndHours - Returns an object of days + hours given x minutes.
 *
 * @param {number | null} - minutes
 * @return {{ days: number, hours: number }}  - {days, hours}
 */

function minsToDaysAndHours(minutes) {
  if (minutes) {
    const convertedDays = Math.floor(minutes / NO_OF_MINUTES_IN_DAY);
    let convertedHours = Math.floor(
      (minutes - convertedDays * NO_OF_MINUTES_IN_DAY) / NO_OF_MINUTES_IN_HOUR
    );
    if (convertedDays < 1 && convertedHours < 1) {
      convertedHours = 1;
    }
    const convertedAge = {
      days: convertedDays,
      hours: convertedHours,
    };
    return convertedAge;
  }

  return {
    days: null,
    hours: null,
  };
}

/**
 * daysAndHoursToMins - Returns minutes given x days and y hours
 *
 * @param {{ days: number, hours: number }}  - {days, hours}
 * @return {number | null} - minutes
 */

const daysAndHoursToMins = ({ days, hours }) => {
  if (days || hours) {
    let convertedMins = 0;
    if (days) {
      convertedMins += Math.floor(
        parseInt(days.toString(), 10) * NO_OF_MINUTES_IN_DAY
      );
    }
    if (hours) {
      convertedMins += Math.floor(
        parseInt(hours.toString(), 10) * NO_OF_MINUTES_IN_HOUR
      );
    }
    return convertedMins;
  }
  return null;
};
