import { Redirect, Route, Switch } from 'react-router-dom';

// import Add from 'components/editions/Add';
import Curation from 'components/editor/curate/Curation';
// import Success from 'components/editions/Success';

function Editions() {
  return (
    <Switch>
      <Route path="/editions/:id/review">
        <Curation />
      </Route>
      <Route path="/editions" exact={true} render={() => <Redirect to="/" />} />
    </Switch>
  );
}

export default Editions;
