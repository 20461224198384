/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

export { getBrowserLocale, getBrowserLocales };

const getBrowserLocale = (options = {}) => {
  const browserLocales = getBrowserLocales(options);
  return browserLocales ? browserLocales[0] : null;
};

const getBrowserLocales = (options = {}) => {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
};
