import { Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as zendesk from 'common/zendesk';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import EchoboxLogo from 'components/layout/EchoboxLogo';
import StatusPageNotifications from 'components/StatusPageNotifications';

function SignInTemplate({ title, children, hideDemo }) {
  const currentVersion = sessionStorage.getItem('currentVersion');

  zendesk.hideWidget();

  return (
    <div className="ebx-sign-in-background">
      <div className="d-flex sign-in__page-height flex-column align-items-center">
        <div className="sign-in__card">
          <div>
            <EchoboxLogo />
          </div>

          <StatusPageNotifications
            className="ebx-status-notification-card"
            small={true}
          />

          <div className="ebx-card mx-auto">
            <div className="font-weight-bold sign-in__card__header pb-4">
              {title}
            </div>

            {children}
            {!hideDemo && (
              <>
                <div className="d-flex text-center align-items-center my-3">
                  <hr className="d-inline-block w-100" />
                  <span className="ebx-hr-text ebx-hr-text-colour w-100 mx-2">
                    New to Echobox?
                  </span>
                  <hr className="d-inline-block w-100" />
                </div>

                <div>
                  <a
                    href="https://www.echobox.com/contact-sales"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="w-100 ebx-btn-secondary ebx-btn-lg ebx-h3">
                      Get in touch for a demo
                    </Button>
                  </a>
                </div>
              </>
            )}
          </div>
          <div className="footer-text">
            <a
              className="ebx-a ebx-h3"
              href="https://www.echobox.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Contact us</span>
            </a>
            <Tooltip
              label={`Echobox Email version ${currentVersion}`}
              placement="top"
            >
              <div className="mx-4 ebx-h3">&copy; Echobox Ltd</div>
            </Tooltip>
            <a
              className="ebx-a ebx-h3"
              href="https://www.echobox.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Back to homepage</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

SignInTemplate.defaultProps = {
  hideDemo: false,
};

SignInTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideDemo: PropTypes.bool,
};

export default SignInTemplate;
