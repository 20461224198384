import useStaffUser from 'hooks/useStaffUser';
import { useEffect, useState } from 'react';

const useStaffMode = () => {
  const [isStaffModeActive, setStaffModeActive] = useState(
    sessionStorage.getItem('isStaffModeActive') !== null
  );
  const isStaffUser = useStaffUser();

  useEffect(() => {
    if (isStaffModeActive && isStaffUser) {
      sessionStorage.setItem('isStaffModeActive', true);
    } else {
      sessionStorage.removeItem('isStaffModeActive');
    }
  }, [isStaffModeActive, isStaffUser]);

  return [isStaffModeActive, setStaffModeActive];
};

export default useStaffMode;
