import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';

import './Check.css';

function Check({ disabled, hasCheckedConfirm, handleConfirm }) {
  return (
    <Modal.Body className="check-body ebx-upload-warning ebx-warning-modal-body ebx-body-1">
      <Form.Check
        id="confirm"
        label={
          <span className="ebx-h3">
            Please confirm that these new quotas are from the latest export. You
            can find a copy of the current Salesforce export{' '}
            <a href="https://echobox.lightning.force.com/lightning/r/Report/00O4K0000035bvWUAQ/view?queryScope=userFolders">
              here
            </a>
            .
          </span>
        }
        className="confirm__warning"
        checked={hasCheckedConfirm}
        disabled={disabled}
        onChange={handleConfirm}
      />
    </Modal.Body>
  );
}

Check.propTypes = {
  disabled: PropTypes.bool,
  hasCheckedConfirm: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

Check.defaultProps = {
  disabled: false,
};

export default Check;
