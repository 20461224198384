/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import { isDefined, isNull } from 'common/utility';

export { getGlobalInfo };

const getGlobalInfo = () => {
  if (window && window.globalInfo) {
    return window.globalInfo;
  }
  const globalInfo = sessionStorage.getItem('globalInfo');
  if (
    isDefined(globalInfo) &&
    !isNull(globalInfo) &&
    globalInfo !== 'undefined'
  ) {
    return JSON.parse(globalInfo);
  }
  return null;
};
