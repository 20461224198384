import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';
import Modal from 'components/common/Modal';

import * as url from 'common/url';

import './AddImageModal.css';

function AddImageModal({ imageURL, onClose, onSave, title, description }) {
  const [tempImageURL, setTempImageURL] = useState(imageURL);
  const [attemptedSaveImageURL, setAttemptedSaveImageURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = event => {
    setErrorMessage('');
    setTempImageURL(event.target.value);
  };

  const handleSave = () => {
    setAttemptedSaveImageURL(tempImageURL);
  };

  const handleError = () => {
    if (attemptedSaveImageURL !== '') {
      setErrorMessage('Please use a valid image link');
    }
  };

  return (
    <Modal show onHide={onClose} dialogClassName="add-image-modal">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <span className="ml-auto">
          <Button variant="link" className="p-0" onClick={onClose}>
            <CloseIcon style={{ color: '#909db5' }} />
          </Button>
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white">
        <img
          className="d-none"
          src={attemptedSaveImageURL}
          onLoad={() => onSave(tempImageURL)}
          onError={handleError}
          alt=""
        />
        <Form.Group className="mb-2">
          <Form.Label className="ebx-h3 ebx-title-colour">Image URL</Form.Label>
          <Form.Control
            className={`ebx-input ebx-input-group ebx-body-1 ${
              errorMessage ? 'ebx-input-error' : ''
            }`}
            type="text"
            placeholder=""
            value={tempImageURL}
            onChange={handleChange}
          />
          {errorMessage && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {errorMessage}
            </div>
          )}
        </Form.Group>
        <div className="ebx-body-1 add-image-description">{description}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3 my-0"
          style={{ marginRight: 12 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="ebx-btn-primary ebx-btn-md ebx-h3 mx-0 my-0"
          onClick={handleSave}
          disabled={
            tempImageURL === '' || !url.isValidURL(tempImageURL) || errorMessage
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddImageModal.propTypes = {
  imageURL: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

AddImageModal.defaultProps = {
  imageURL: '',
};

export default AddImageModal;
