import {
  Button,
  Card,
  Flex,
  Spinner,
  Text,
  VisuallyHidden,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import UploadDetails from 'components/campaigns/subscribers/add/UploadDetails';

function Processing({ latestUpload, openAnalytics }) {
  return (
    <Card py={6} borderBottomRadius={0} justifyContent="space-between">
      <Flex gap={6}>
        <Flex
          alignItems="center"
          gap={2}
          pr={6}
          borderRight="1px solid"
          borderColor="gray.300"
        >
          <Text size="sm" color="gray.600">
            Subscribers
          </Text>
          <Spinner size="sm">
            <VisuallyHidden>Loading...</VisuallyHidden>
          </Spinner>
          <Text size="sm" color="gray.600">
            processing...
          </Text>
        </Flex>
        <Flex pr={6} borderRight="1px solid" borderColor="gray.300">
          {latestUpload && <UploadDetails latestUpload={latestUpload} />}
        </Flex>
      </Flex>
      <Button variant="link" onClick={openAnalytics}>
        View analytics
      </Button>
    </Card>
  );
}

Processing.propTypes = {
  latestUpload: PropTypes.shape({
    processingCompletedUnixTime: PropTypes.number,
    processingStartUnixTime: PropTypes.number,
    uploadedByEmail: PropTypes.string,
    uploadURN: PropTypes.string.isRequired,
    uploadState: PropTypes.string.isRequired,
    uploadTriggerUnixTime: PropTypes.number,
    uploadType: PropTypes.string.isRequired,
    errorLogURL: PropTypes.string,
  }),
  openAnalytics: PropTypes.func.isRequired,
};

Processing.defaultProps = {
  latestUpload: null,
};

export default Processing;
