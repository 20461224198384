/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import {
  Container,
  Description,
  Input,
  Title,
} from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import { PROPERTY_SETTING_TYPES } from 'common/constants';

function AccentColour() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState('');

  const accentColour = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
    'accentColour'
  );

  const HEX_MAX_LENGTH = 6;

  const isInvalidColour = colour =>
    Array.from(colour.toLowerCase()).filter(
      char => '01234567890abcdef'.indexOf(char) === -1
    ).length > 0;

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Branding-General-AccentColour:handleSave');
      const trimmedColour = accentColour
        .replace(/^#/, '')
        .substring(0, HEX_MAX_LENGTH);
      if (isInvalidColour(trimmedColour)) {
        setErrorMessage('Please select a valid hex color');
        return;
      }
      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const saveErrorMessage = errors.getErrorMessage(error);
        settings.setError(saveErrorMessage);
        toast({ variant: 'error', title: saveErrorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleAccentColourChange = event => {
    event.preventDefault();
    setErrorMessage('');
    const colour = event.target.value;
    const trimmedColour = colour.replace(/^#/, '').substring(0, HEX_MAX_LENGTH);
    if (!isInvalidColour(trimmedColour)) {
      settings.setPropertySettingValue({
        settingType: PROPERTY_SETTING_TYPES.PROPERTY_STYLING,
        settingKey: 'accentColour',
        settingValue: trimmedColour,
      });
    }
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Container>
      <Title className="mb-3">Accent color</Title>
      {errorMessage && (
        <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
          {errorMessage}
        </div>
      )}
      <Description className="mb-3">Select your brand color</Description>
      <div className="d-flex align-items-center">
        <div className="position-relative">
          <span className="mr-2 input-hash">#</span>
          <Input
            className="accent-color-input"
            value={accentColour}
            onChange={handleAccentColourChange}
          />
          <div
            className="accent-color"
            style={{ backgroundColor: `#${accentColour}` }}
          />
        </div>
      </div>
    </Container>
  );
}

export default AccentColour;
