import { InfoIcon, Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import PerformanceStatistic from 'components/campaigns/PerformanceStatistic';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import './EditionPerformanceStatistics.css';

function EditionPerformanceStatistics({
  performanceLoading,
  performanceStatistics,
}) {
  return (
    <Row className="pb-2">
      <Col className="edition-statistics-open-rate">
        <div className="pt-3 mb-2 d-flex align-items-center">
          <div className="ebx-faded-colour ebx-body-1 mr-0 mr-md-1">
            Open rate
          </div>
          <Tooltip
            label="Number of individuals who opened your email divided by the number of emails delivered in the Edition."
            placement="top"
          >
            <InfoIcon color="gray.500" />
          </Tooltip>
        </div>
        <div className="ebx-h2 ebx-body-colour">
          <PerformanceStatistic
            performanceLoading={performanceLoading}
            performanceObject={performanceStatistics}
            performanceKey="numUniqueOpens"
          />
        </div>
      </Col>
      <Col>
        <div className="pt-3 mb-2 d-flex align-items-center">
          <div className="ebx-faded-colour ebx-body-1 mr-0 mr-md-1">
            Click rate
          </div>
          <Tooltip
            label="Number of individuals who clicked a link in your email divided by the number of emails delivered in the Edition."
            placement="top"
          >
            <InfoIcon color="gray.500" />
          </Tooltip>
        </div>
        <div className="ebx-h2 ebx-body-colour">
          <PerformanceStatistic
            performanceLoading={performanceLoading}
            performanceObject={performanceStatistics}
            performanceKey="numUniqueClicks"
          />
        </div>
      </Col>
    </Row>
  );
}

EditionPerformanceStatistics.propTypes = {
  performanceLoading: PropTypes.bool.isRequired,
  performanceStatistics: PropTypes.shape({
    numDeliveries: PropTypes.number,
    numUniqueOpens: PropTypes.number,
    numUniqueClicks: PropTypes.number,
  }),
};

EditionPerformanceStatistics.defaultProps = {
  performanceStatistics: null,
};

export default EditionPerformanceStatistics;
