import { useState } from 'react';

import {
  Button,
  FormControl,
  Input,
  Modal,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface RenameTemplateProps {
  isOpen: boolean;
  name: string;
  onClose: () => void;
}

function RenameTemplate({ isOpen, name, onClose }: RenameTemplateProps) {
  const [templateName, setTemplateName] = useState(name);
  const [templateNameError, setTemplateNameError] = useState(false);

  const toast = useToast();

  const handleTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (templateNameError) setTemplateNameError(false);
    setTemplateName(event.target.value);
  };

  const handleRenameTemplate = () => {
    if (templateName === 'Error') {
      setTemplateNameError(true);
      return;
    }
    toast({
      variant: 'success',
      title: 'Template name has been updated',
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="small">
      <Modal.Header>
        <Modal.Title>Rename template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl isInvalid={templateNameError}>
          <FormControl.FormLabel>Name</FormControl.FormLabel>
          <Input
            value={templateName}
            type="text"
            maxLength={120}
            onChange={handleTemplateNameChange}
            id="template-name"
          />
          <FormControl.FormErrorMessage>
            A template with this name already exists
          </FormControl.FormErrorMessage>
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button isDisabled={!templateName} onClick={handleRenameTemplate}>
          Rename
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RenameTemplate;
