import { useLocation } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

import HeaderLink from 'components/settings/header/HeaderLink';

const getSublevelName = (sublevel: string) => {
  switch (sublevel) {
    case 'feeds':
      return 'RSS feeds and sitemaps';
    case 'website':
      return 'Website';
    case 'address':
      return 'Business address';
    default:
      return sublevel;
  }
};

function PropertyLinks() {
  const location = useLocation();

  /* Render nothing if we are not showing a property settings page or sub-page */
  if (!location.pathname.startsWith('/settings/property')) {
    return null;
  }

  const propertySettingsPath = '/settings/property';
  const propertySubpath = location.pathname
    .replace(propertySettingsPath, '')
    .substring(1);

  return (
    <>
      <ChevronRight />
      <HeaderLink to="/settings/property">
        <span className="ebx-h1">Property</span>
      </HeaderLink>
      {propertySubpath && (
        <>
          <ChevronRight />
          <HeaderLink to={`${propertySettingsPath}/${propertySubpath}`}>
            <span className="ebx-h1">{getSublevelName(propertySubpath)}</span>
          </HeaderLink>
        </>
      )}
    </>
  );
}

export default PropertyLinks;
