/* eslint prefer-regex-literals: "off" */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

// import CheckCircle from '@material-ui/icons/CheckCircle';

import Error from 'components/staff/upload/Error';
import Select from 'components/staff/upload/Select';
import Success from 'components/staff/upload/Success';
import Uploading from 'components/staff/upload/Uploading';

import { STAFF_MODAL_STATES } from 'components/staff/StaffModalWrapper';

import * as API from 'api/api';
import * as errors from 'common/errors';
import * as file from 'common/file';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';

import { MAXIMUM_UPLOAD_SIZE, SUPPORTED_FORMATS } from 'common/config';
import { FRONTEND_UPLOAD_STATES } from 'common/constants';

import { apiBaseURL, APIS } from 'api/settings';

function UploadEmailQuota({
  show,
  setCurrentComponent,
  isProcessing,
  setIsProcessing,
}) {
  const [localState, setLocalState] = useState(
    FRONTEND_UPLOAD_STATES.SELECTING
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasCheckedConfirm, setCheckedConfirm] = useState(false);
  const [isFileValid, setFileValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  // File upload progress
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleConfirm = event => {
    setCheckedConfirm(event.target.checked);
  };

  const handleLocalError = message => {
    logger.info('EmailQuotaUpload:handleLocalError');

    setFileValid(false);
    setErrorMessage(message);
    setUploadProgress(0);
  };

  const handleRemove = event => {
    event.preventDefault();
    logger.info('EmailQuotaUpload:handleRemove');

    setFileValid(false);
    setCheckedConfirm(false);
    setErrorMessage(null);
    setSelectedFile(null);
    setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
  };

  const handleSelect = acceptedFiles => {
    setFileValid(true);
    setErrorMessage(null);

    if (acceptedFiles.length === 0) {
      setErrorMessage('Incompatible file');
      setFileValid(false);
      return;
    }

    const fileMeta = acceptedFiles[0];
    setSelectedFile(fileMeta);
    logger.info(
      `EmailQuotaUpload:handleFile - selected ${fileMeta.name} ${fileMeta.type}`
    );

    // Check file size
    if (fileMeta.size > MAXIMUM_UPLOAD_SIZE) {
      setErrorMessage('This file exceeds the maximum size allowed');
      setFileValid(false);
      return;
    }

    // If the file has a type test against supported types
    if (fileMeta.type !== '') {
      if (!SUPPORTED_FORMATS.includes(fileMeta.type)) {
        setErrorMessage('Incorrect file type! Must be a CSV file.');
        setFileValid(false);
        return;
      }
    }

    // If the file has no type test against RegEx
    if (fileMeta.type === '') {
      const regexCSV = new RegExp('(.*?).(csv)$');
      if (!regexCSV.test(fileMeta.name)) {
        setErrorMessage('Incorrect file type! Must be a CSV file.');
        setFileValid(false);
        return;
      }
    }

    logger.info('EmailQuotaUpload:handleFile - CSV file is correct size');

    // Check file content
    const reader = new FileReader();
    reader.addEventListener('load', event => {
      try {
        const content = event.target.result;
        logger.info(
          `EmailQuotaUpload:handleFile - read ${fileMeta.name} ${fileMeta.type}`
        );
        // Check that the file contains enough rows to be valid
        const fileRows = content.split('\n');
        if (fileRows.length < 2) {
          handleLocalError(
            'File must contain header row and at least one data row'
          );
          setFileValid(false);
          return;
        }
        // Check the contents of the header row
        const headerRow = fileRows[0].replace(/\r/g, '');
        if (!headerRow) {
          handleLocalError('Header row is missing or invalid');
          setFileValid(false);
          return;
        }
        const headerFields = file.splitFields(headerRow);
        const numberOfHeaderFields = headerFields.length;
        if (numberOfHeaderFields < 3) {
          handleLocalError(`CSV must have at least 3 columns`);
          setFileValid(false);
          return;
        }

        const uniqueFields = new Set(headerFields);

        if (uniqueFields.size < headerFields.length) {
          handleLocalError(`Duplicate column headers included`);
          setFileValid(false);
          return;
        }

        const properties = new Set();

        // Check that all other rows contain valid Property Id and Quota
        for (let rowNumber = 1; rowNumber < fileRows.length; rowNumber += 1) {
          const thisRow = fileRows[rowNumber].replace(/\r/g, '');
          const rowFields = thisRow.split(',');
          const numberOfRowFields = file.numberOfFields(thisRow);
          if (numberOfRowFields !== numberOfHeaderFields) {
            handleLocalError(
              `Row ${rowNumber} does not contain the correct number of fields`
            );
            setFileValid(false);
            return;
          }

          let propertyID = rowFields[0];
          if (!propertyID) {
            handleLocalError(
              `Row ${rowNumber} does not contain the correct Property ID`
            );
            setFileValid(false);
            return;
          }

          const emailQuota = rowFields[2];
          if (!emailQuota) {
            handleLocalError(
              `Row ${rowNumber} does not contain the correct Email Quota`
            );
            setFileValid(false);
            return;
          }

          const propertyURNRegEx = /urn:property:\d+/;
          if (!propertyURNRegEx.test(propertyID)) {
            // It's not Property URN, try Property ID
            const parsedPropertyId = parseInt(propertyID, 10);
            if (isNaN(parsedPropertyId)) {
              handleLocalError(
                `Row ${rowNumber} does not contain the correct Property ID`
              );
              setFileValid(false);
              return;
            }
            propertyID = `urn:property:${parsedPropertyId}`;
          }

          if (properties.has(propertyID)) {
            handleLocalError(
              `Row ${rowNumber}: There are duplicates for property ${propertyID}`
            );
            setFileValid(false);
            return;
          }

          properties.add(propertyID);

          const parsedQuota = parseInt(emailQuota, 10);

          if (isNaN(parsedQuota) || parsedQuota < 1) {
            handleLocalError(
              `Row ${rowNumber} does not contain the correct Email Quota. It must be a positive integer`
            );
            setFileValid(false);
            return;
          }
        }
        // All validation passed successfully
        setErrorMessage(null);
        setFileValid(true);
        setLocalState(FRONTEND_UPLOAD_STATES.CONFIRMING);
      } catch (error) {
        handleLocalError('There was a problem validating the selected file');
        setFileValid(false);
      }
    });
    reader.readAsText(fileMeta);
  };

  const handleUpload = async () => {
    setLocalState(FRONTEND_UPLOAD_STATES.UPLOADING);
    try {
      const url = `${apiBaseURL(APIS.NEWSLETTERS, '1')}/properties/quotas`;
      const formData = new FormData();
      formData.append('file', selectedFile);
      await API.postS3Uploads({
        uploadURL: url,
        fileContent: formData,
        onProgress,
      });
      tracker.track({
        eventName: 'Email Quota CSV Upload',
      });
      setLocalState(FRONTEND_UPLOAD_STATES.SUCCESS);
    } catch (error) {
      handleLocalError(errors.getErrorMessage(error));
    }
  };

  const onProgress = ({ loaded, total, percent }) => {
    setUploadProgress(percent);
  };

  const handleCancel = () => {
    setCurrentComponent(STAFF_MODAL_STATES.STAFF_OPTIONS);
  };

  useEffect(() => {
    setIsProcessing(false);
    setTimeout(() => {
      document.getElementById('username')?.focus();
    }, 100);
  }, [show, setIsProcessing]);

  return (
    <Form>
      <ModalPage
        errorMessage={errorMessage}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        handleRemove={handleRemove}
        handleSelect={handleSelect}
        handleUpload={handleUpload}
        hasCheckedConfirm={hasCheckedConfirm}
        isFileValid={isFileValid}
        localState={localState}
        selectedFile={selectedFile}
        uploadProgress={uploadProgress}
      />
    </Form>
  );
}

UploadEmailQuota.propTypes = {
  show: PropTypes.bool.isRequired,
  setCurrentComponent: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  setIsProcessing: PropTypes.func.isRequired,
};

export default UploadEmailQuota;

function ModalPage({
  errorMessage,
  handleCancel,
  handleConfirm,
  handleRemove,
  handleSelect,
  handleUpload,
  hasCheckedConfirm,
  isFileValid,
  localState,
  selectedFile,
  uploadProgress,
}) {
  logger.info(`EmailQuotaUpload:ModalPage:${localState}`);
  switch (localState) {
    case FRONTEND_UPLOAD_STATES.SELECTING:
    case FRONTEND_UPLOAD_STATES.CONFIRMING:
      return (
        <Select
          canUpload={hasCheckedConfirm && isFileValid}
          errorMessage={errorMessage}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          handleRemove={handleRemove}
          handleSelect={handleSelect}
          handleUpload={handleUpload}
          hasCheckedConfirm={hasCheckedConfirm}
          selectedFile={selectedFile}
        />
      );
    case FRONTEND_UPLOAD_STATES.UPLOADING:
      return (
        <Uploading
          errorMessage={errorMessage}
          handleCancel={handleCancel}
          uploadProgress={uploadProgress}
          selectedFile={selectedFile}
        />
      );
    case FRONTEND_UPLOAD_STATES.SUCCESS:
      return <Success handleCancel={handleCancel} />;
    case FRONTEND_UPLOAD_STATES.ERROR:
      return <Error errorMessage={errorMessage} handleCancel={handleCancel} />;
    default:
      return <Error errorMessage={errorMessage} handleCancel={handleCancel} />;
  }
}

ModalPage.propTypes = {
  errorMessage: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  hasCheckedConfirm: PropTypes.bool.isRequired,
  isFileValid: PropTypes.bool.isRequired,
  localState: PropTypes.string.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  uploadProgress: PropTypes.number.isRequired,
};

ModalPage.defaultProps = {
  errorMessage: null,
  selectedFile: null,
};
