import PropTypes from 'prop-types';

import './AutomationWrapper.css';

function AutomationWrapper({ children }) {
  return (
    <div className="automation-wrapper-spacing">
      <div className="ebx-panel ebx-panel-missed d-flex automation-wrapper-border">
        <div className="w-100">{children}</div>
      </div>
    </div>
  );
}

AutomationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AutomationWrapper;
