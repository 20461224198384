import React from 'react';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  Flex,
  Th,
  Thead,
  Tr,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { SortByOptions } from 'components/settings/team/Team';

interface TeamTableHeaderProps {
  columnNames: string[];
  isBusy: boolean;
  setSortBy: React.Dispatch<SortByOptions>;
  setSortOrder: React.Dispatch<'asc' | 'desc'>;
  sortBy: SortByOptions;
  sortOrder: 'asc' | 'desc';
}

function TeamTableHeader({
  columnNames,
  isBusy,
  setSortBy,
  setSortOrder,
  sortBy,
  sortOrder,
}: TeamTableHeaderProps) {
  return (
    <Thead bg="gray.100">
      <Tr>
        {columnNames.map(heading => {
          const headerPropertyMap: Record<string, SortByOptions> = {
            Name: 'name',
            Email: 'emailAddress',
          };

          const isSortedHeading = headerPropertyMap[heading] === sortBy;

          // const isSortableHeading = heading === 'Name' || heading === 'Email';
          const isSortableHeading = heading === 'Name';

          const handleHeadingClick = () => {
            if (!isBusy) {
              if (heading === 'Email' || heading === 'Name') {
                if (
                  (heading === 'Email' && sortBy === 'emailAddress') ||
                  (heading === 'Name' && sortBy === 'name')
                ) {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                } else {
                  setSortBy(headerPropertyMap[heading]);
                  setSortOrder('asc');
                }
              }
            }
          };

          return (
            <Th
              key={heading}
              textTransform="none"
              px={5}
              py={4}
              color="gray.900"
              fontSize="sm"
              lineHeight="4"
              fontWeight="medium"
              letterSpacing="normal"
              onClick={isSortableHeading ? handleHeadingClick : () => {}}
              cursor={isSortableHeading ? 'pointer' : 'default'}
            >
              <Flex gap={2}>
                {heading}
                {isSortedHeading &&
                  (sortOrder === 'asc' ? <ArrowDownIcon /> : <ArrowUpIcon />)}
              </Flex>
            </Th>
          );
        })}
      </Tr>
    </Thead>
  );
}

export default TeamTableHeader;
