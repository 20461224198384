import {
  Box,
  Flex,
  FormControl,
  Heading,
  Toggle,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import NumberPicker from 'components/editor/blocks/attributes/NumberPicker';

interface PaddingProps {
  onChange: (name: string, value: string) => void;
  value: string;
}

function Padding({ onChange, value }: PaddingProps) {
  const [allSides, setAllSides] = useState(value.split(' ').length === 1);

  if (!value) return null;

  const handleChange = (padding: string) => {
    onChange('padding', padding);
  };

  const handleChangeAll = (name: string, padding: string) => {
    console.log(padding);
    handleChange(padding);
  };

  const handleChangeBottom = (name: string, bottom: string) => {
    const current = stringToElements(value);
    handleChange(
      elementsToString(current.top, current.right, bottom, current.left)
    );
  };

  const handleChangeLeft = (name: string, left: string) => {
    const current = stringToElements(value);
    handleChange(
      elementsToString(current.top, current.right, current.bottom, left)
    );
  };

  const handleChangeRight = (name: string, right: string) => {
    const current = stringToElements(value);
    handleChange(
      elementsToString(current.top, right, current.bottom, current.left)
    );
  };

  const handleChangeTop = (name: string, top: string) => {
    const current = stringToElements(value);
    handleChange(
      elementsToString(top, current.right, current.bottom, current.left)
    );
  };

  const handleToggleSides = () => {
    if (allSides) {
      handleChange(elementsToString(value, value, value, value));
      setAllSides(false);
    } else {
      const current = stringToElements(value);
      handleChangeAll('', current.top);
      setAllSides(true);
    }
  };

  const elementsToString = (
    top: string,
    right: string,
    bottom: string,
    left: string
  ) => {
    return `${top} ${right} ${bottom} ${left}`;
  };

  const stringToElements = (string: string) => {
    const elements = string.split(' ');
    if (elements.length === 1) {
      const padding = elements[0];
      return { top: padding, right: padding, bottom: padding, left: padding };
    }
    const top = elements[0];
    const right = elements[1];
    const bottom = elements[2];
    const left = elements[3];
    return { top, right, bottom, left };
  };

  const padding = stringToElements(value);

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h4">Padding</Heading>
      <FormControl display="flex" alignItems="start" gap={4}>
        <Toggle
          id="allSides"
          isChecked={allSides}
          onChange={handleToggleSides}
        />
        <FormControl.FormLabel color="gray.900" htmlFor="allSides">
          Apply to all sides
        </FormControl.FormLabel>
      </FormControl>
      {allSides && (
        <Flex alignItems="center" direction="row" gap={4}>
          <Box w="50%">Padding</Box>
          <Box w="50%">
            <NumberPicker
              min={0}
              name="all-sides"
              // @ts-ignore
              onChange={handleChangeAll}
              unit="px"
              value={value}
            />
          </Box>
        </Flex>
      )}
      {!allSides && (
        <>
          <Flex alignItems="center" direction="row" gap={4}>
            <Box>Top</Box>
            <Box>
              <NumberPicker
                min={0}
                name="top"
                // @ts-ignore
                onChange={handleChangeTop}
                unit="px"
                value={padding.top}
              />
            </Box>
            <Box>Bottom</Box>
            <Box>
              <NumberPicker
                min={0}
                name="bottom"
                // @ts-ignore
                onChange={handleChangeBottom}
                unit="px"
                value={padding.bottom}
              />
            </Box>
          </Flex>
          <Flex alignItems="center" direction="row" gap={4}>
            <Box>Left</Box>
            <Box>
              <NumberPicker
                min={0}
                name="left"
                // @ts-ignore
                onChange={handleChangeLeft}
                unit="px"
                value={padding.left}
              />
            </Box>
            <Box>Right</Box>
            <Box>
              <NumberPicker
                min={0}
                name="right"
                // @ts-ignore
                onChange={handleChangeRight}
                unit="px"
                value={padding.right}
              />
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default Padding;
