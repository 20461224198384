import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as users from 'common/users';
import useGlobalInfo from 'hooks/useGlobalInfo';

import { CAMPAIGN_STATES } from 'common/constants';

import Create from 'components/layout/create/Create';
import SettingsPanel from 'components/settings/campaigns/SettingsPanel';

import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-right-OLD.svg';
import { ReactComponent as Spanner } from 'assets/svg/spanner.svg';

import './Campaigns.css';

function CampaignSettings() {
  const [isModalActive, setModalActive] = useState(false);
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const allCampaigns = campaigns.getPropertyCampaigns(propertyURN, globalInfo);
  const isAdmin = users.isAdministrator(globalInfo);

  const showAllCampaignsLink = allCampaigns.length > 1 && isAdmin;
  const allCampaignsSorted = campaigns.sortCampaignList(allCampaigns);

  const handleOpen = () => {
    logger.info('View:handleOpen');
    setModalActive(true);
  };

  const handleClose = () => {
    logger.info('View:handleClose');
    setModalActive(false);
  };

  return (
    <>
      <div className="d-flex flex-align-items">
        <span className="campaign-title ebx-title-colour">Campaigns</span>
        <span className="ml-auto">
          {isAdmin && (
            <Button
              className="ebx-btn-secondary ebx-btn-md ebx-h3 ml-3 d-flex align-items-center"
              onClick={handleOpen}
            >
              <PlusIcon />
              <span className="ml-2">Create new Campaign</span>
            </Button>
          )}
        </span>
      </div>
      {showAllCampaignsLink && (
        <Link className="link-unstyled" to="/settings/campaigns/allcampaigns">
          <SettingsPanel
            title="All Campaigns"
            description="Set up a single page from which a reader can subscribe to all your Campaigns"
            className="mb-5"
          />
        </Link>
      )}
      <div className="campaign-list">
        {allCampaignsSorted?.map(
          ({ campaignName, campaignURN, campaignState }) => (
            <Link
              to={`/settings/campaigns/${campaignURN}`}
              className="link-unstyled"
              key={campaignURN}
              disabled={campaignState === CAMPAIGN_STATES.UNDER_SETUP}
            >
              <Row key={campaignURN} className="flex-align-items campaign">
                <span className="ebx-h2">{campaignName}</span>
                <span className="ml-auto">
                  {campaignState === CAMPAIGN_STATES.UNDER_SETUP ? (
                    <span className="campaign-under-setup-icon">
                      <Spanner />
                    </span>
                  ) : (
                    <ArrowRightIcon />
                  )}
                </span>
              </Row>
            </Link>
          )
        )}
        <div className="d-none">
          {isModalActive && <Create handleClose={handleClose} show />}
        </div>
      </div>
    </>
  );
}

export default CampaignSettings;
