import type { ChangeEvent } from 'react';
import { useState } from 'react';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as logger from 'common/logger';
import type { Campaign, Permission, PropertyUser as User } from 'common/types';
import * as utility from 'common/utility';

import { CAMPAIGN_PERMISSION_TYPES } from 'common/enums';

interface UserEntitiesProps {
  availableCampaigns: Campaign[] | null;
  buttonText: string;
  editingEntities: any;
  handleClose: () => void;
  handleSubmit: ({
    user,
    selectedEntities,
  }: {
    user: User;
    selectedEntities: string[];
  }) => void;
  isBusy: boolean;
}

function UserEntities({
  availableCampaigns,
  buttonText,
  editingEntities,
  handleClose,
  handleSubmit,
  isBusy,
}: UserEntitiesProps) {
  const [selectedEntities, setSelectedEntities] = useState(
    editingEntities?.user?.permissions
      .filter(
        (permission: Permission) =>
          permission.campaignURN !== null &&
          permission.permissionType ===
            CAMPAIGN_PERMISSION_TYPES.CAMPAIGN_EDITOR
      )
      .map((permission: Permission) => permission.campaignURN) ?? []
  );
  const [sortOrder, setSortOrder] = useState('asc');

  const sortCampaigns = (a: Campaign, b: Campaign) => {
    if (sortOrder === 'asc') {
      return a.campaignName > b.campaignName ? 1 : -1;
    }
    return a.campaignName < b.campaignName ? 1 : -1;
  };

  const handleSave = () => {
    handleSubmit({
      user: editingEntities.user,
      selectedEntities,
    });
  };

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const campaignURN = event.target.value;
    logger.info(`UserEntities:handleToggle ${campaignURN}`);
    setSelectedEntities((oldSelectedEntities: string[]) => {
      const newSelectedEntities = utility.cloneArray(oldSelectedEntities);
      const position = newSelectedEntities.indexOf(campaignURN);
      if (position !== -1) {
        newSelectedEntities.splice(position, 1);
      } else {
        newSelectedEntities.push(campaignURN);
      }
      return newSelectedEntities;
    });
  };

  const handleToggleAll = () => {
    logger.info('UserEntities:handleToggleAll');
    setSelectedEntities((oldSelectedEntities: string[]) => {
      let newSelectedEntities;
      if (oldSelectedEntities.length === (availableCampaigns?.length ?? 0)) {
        /* Deselect all */
        newSelectedEntities = [];
      } else {
        /* Select all */
        newSelectedEntities = availableCampaigns?.map(
          campaign => campaign.campaignURN
        );
      }
      return newSelectedEntities;
    });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered={true}
      isOpen={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>{editingEntities.user.emailAddress}</Modal.Title>
        <Modal.Subtitle>Select campaigns</Modal.Subtitle>
      </Modal.Header>
      <Modal.Body padding={0}>
        <Table>
          <Thead bg="gray.100">
            <Tr>
              <Th
                key="toggleAll"
                textTransform="none"
                width="48px"
                px={4}
                py={3}
                color="gray.900"
                fontSize="sm"
                fontWeight="medium"
                borderBottomWidth="1px"
                borderBottomColor="gray.300"
              >
                <Flex height="100%" alignItems="center">
                  <Checkbox
                    isChecked={
                      selectedEntities.length ===
                      (availableCampaigns?.length ?? 0)
                    }
                    onChange={handleToggleAll}
                    mb={0}
                    isDisabled={isBusy}
                  />
                </Flex>
              </Th>
              <Th
                key="entities"
                textTransform="none"
                py={3}
                pl={0}
                color="gray.900"
                fontSize="sm"
                lineHeight="5"
                letterSpacing="normal"
                fontWeight="medium"
                onClick={() => {
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                }}
                cursor="pointer"
                borderBottomWidth="1px"
                borderBottomColor="gray.300"
              >
                <Flex gap={2}>
                  Campaigns
                  <Box bottom="1px" pos="relative">
                    {sortOrder === 'asc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                  </Box>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {availableCampaigns &&
              availableCampaigns.sort(sortCampaigns).map(campaign => (
                <Tr key={`user-entities-edit-${campaign.campaignURN}`}>
                  <Td
                    px={4}
                    py={3}
                    color="gray.900"
                    fontSize="sm"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.300"
                  >
                    <Flex>
                      <Checkbox
                        isChecked={
                          selectedEntities.indexOf(campaign.campaignURN) !== -1
                        }
                        onChange={handleToggle}
                        value={campaign.campaignURN}
                        mb={0}
                        isDisabled={isBusy}
                      />
                    </Flex>
                  </Td>
                  <Td
                    py={3}
                    pl={0}
                    color="gray.900"
                    fontSize="sm"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.300"
                  >
                    {campaign.campaignName}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer boxShadow="none">
        <Button variant="secondary" onClick={handleClose} isDisabled={isBusy}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          isDisabled={selectedEntities.length === 0 || isBusy}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserEntities;
