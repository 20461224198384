import PropTypes from 'prop-types';
import { NavLink } from 'react-bootstrap';

import { ReactComponent as Spanner } from 'assets/svg/spanner.svg';

import { CAMPAIGN_STATES } from 'common/constants';

import './Sidebar.css';

function SidebarLinks({
  campaignURN,
  campaignName,
  campaignState,
  isCurrent,
  onClick,
}) {
  const underSetup = campaignState === CAMPAIGN_STATES.UNDER_SETUP;

  return (
    <NavLink
      id={campaignURN}
      to={`/campaigns/${campaignURN}/view`}
      className={`w-100 d-flex align-items-center py-0 ebx-menu-option sidebar__link__pl mb-2 ${
        underSetup ? 'under-setup' : ''
      }`}
      active={isCurrent}
      disabled={underSetup}
      onClick={onClick}
    >
      <span className="ebx-h2 sidebar__campaign-name py-2 px-3">
        {campaignName}
      </span>
      {underSetup && (
        <span className="ml-auto">
          <Spanner />
        </span>
      )}
      {/* <span className="ml-auto">{approvalTotal}</span> */}
    </NavLink>
  );
}

SidebarLinks.propTypes = {
  campaignURN: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
  campaignState: PropTypes.oneOf([
    CAMPAIGN_STATES.ACTIVE,
    CAMPAIGN_STATES.DELETED,
    CAMPAIGN_STATES.UNDER_SETUP,
  ]).isRequired,
  // approvalTotal: PropTypes.number,
  isCurrent: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

/*
Sidebar.defaultProps = {
  approvalTotal: null,
};
*/

export default SidebarLinks;
