/* eslint no-use-before-define: "off" */

import {
  Button,
  Flex,
  FormControl,
  InfoIcon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  OpenInNewIcon,
  Text,
  Tooltip,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import Editor from 'components/common/Editor';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import MergeTag from 'components/common/MergeTag';
import MergeTagModal from 'components/common/MergeTagModal';

import * as campaigns from 'common/campaigns';
import * as image from 'common/image';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import * as url from 'common/url';
import useGlobalInfo from 'hooks/useGlobalInfo';

import {
  CAMPAIGN_SETTING_TYPES,
  EDITION_APPROVAL_STATE_NAMES,
  EDITOR_TOOLBAR_OPTIONS,
} from 'common/constants';

import { EDITION_APPROVAL_STATES } from 'common/enums';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function EditTextBlock({
  campaignDetails,
  editionDetails,
  textBlock,
  handleSaveTextBlock,
  isSavingTextBlock,
  isOpen,
  onClose,
  mergeTags,
}) {
  const globalInfo = useGlobalInfo();

  const isFirstNameLastNameEnabled = campaigns.getCurrentCampaignSettingValue(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
    'isFirstNameLastNameEnabled'
  );
  const showMergeTags = isFirstNameLastNameEnabled;

  const [linkError, setLinkError] = useState('');
  const {
    isOpen: isOpenMergeTagModal,
    onOpen: onOpenMergeTagModal,
    onClose: onCloseMergeTagModal,
  } = useDisclosure();
  const [currentMergeTag, setCurrentMergeTag] = useState('');
  const contentSettingsLink = `/settings/campaigns/${campaignDetails.campaignURN}/content`;

  const isCampaignTextBlock = !editionDetails;

  const getInitialContentState = () => {
    const block = textBlock?.textBlockBody ?? '';
    const blocksFromHtml = htmlToDraft(block);
    const { contentBlocks, entityMap } = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  };
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(getInitialContentState())
  );
  const [editorRef, setEditorRef] = useState(null);

  const [textBlockTitle, setTextBlockTitle] = useState(
    textBlock?.textBlockTitle ? textBlock?.textBlockTitle : ''
  );

  const [imageURL, setImageURL] = useState(
    textBlock?.imageURL ? textBlock?.imageURL : ''
  );
  const [imageURLError, setImageURLError] = useState(false);

  const mergeTagArray = mergeTags ? Object.keys(mergeTags).sort() : [];

  const [isTitleFocused, setTitleFocused] = useState(false);

  const addMergeTag = (tagName, targetControl) => {
    logger.info(`EditTextBlock:addMergeTag - merge tag: ${tagName}`);
    const mergeTag = { tagName, targetControl, ...mergeTags[tagName] };
    setCurrentMergeTag(mergeTag);
    onOpenMergeTagModal();
  };

  const getEditorStateAsHtml = () =>
    draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(
      /\n/g,
      ''
    );

  const getTextBody = () => {
    let text = getEditorStateAsHtml();
    if (text === '<p></p>') {
      text = '';
    }

    // Ensures all links open in a new tab as opposed to within the iframe
    const filteredText = text.replaceAll('_self', '_blank');
    return filteredText;
  };

  const handleCancel = () => {
    onClose();
    if (editionDetails) {
      tracker.track({
        eventName: 'Cancel Text Block',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
        },
      });
    }
  };

  const handleFocus = event => {
    setTitleFocused(event.target?.id === 'title');
  };

  const handleImageLinkChange = event => {
    setImageURL(event.target.value);
    setImageURLError(false);
  };

  const handleKeyUp = () => {
    const htmlValue = document.getElementById('title').value;
    const reactValue = textBlockTitle;
    if (htmlValue !== reactValue) {
      setTextBlockTitle(htmlValue);
    }
  };

  const handleSubmit = async () => {
    // Only resolve the image if it isn't empty
    if (imageURL.length > 0) {
      const imageResolved = await image.doesImageResolve(imageURL);
      setImageURLError(!imageResolved);
      if (!imageResolved) {
        return;
      }
    }

    // If we're editing a Campaign text block we don't use the image url in the save parameters
    const saveTextBlockParams = [
      textBlock.textBlockURN,
      getTextBody(),
      textBlockTitle,
      ...(isCampaignTextBlock ? [] : [imageURL]),
      onClose,
    ];

    handleSaveTextBlock(...saveTextBlockParams);
  };

  const handleTitleChange = event => {
    setTextBlockTitle(event.target.value);
  };

  // You can only add an imageURL if there is a text block title or body
  const isFormInvalid = () => imageURL && !textBlockTitle && !getTextBody();

  const saveMergeTag = mergeTag => {
    onCloseMergeTagModal();
    switch (mergeTag.targetControl) {
      case 'title':
        saveMergeTagTitle(mergeTag);
        break;
      case 'body':
        saveMergeTagBody(mergeTag);
        break;
      default:
      // Do nothing
    }
  };

  const saveMergeTagBody = mergeTag => {
    logger.info(`EditTextBlock:saveMergeTagText - ${mergeTag.tagName}`);

    const currentSelection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const newContentState = Modifier.replaceText(
      currentContent,
      currentSelection,
      // eslint-disable-next-line
      '${' + mergeTag.tagName + '}'
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-fragment'
    );
    setEditorState(newEditorState);
    editorRef.focusEditor();
  };

  const saveMergeTagTitle = mergeTag => {
    logger.info(`EditTextBlock:saveMergeTagTitle - ${mergeTag.tagName}`);

    const title = document.getElementById('title');
    title.focus();
    let [start, end] = [title.selectionStart, title.selectionEnd];
    if (!isTitleFocused) {
      start = title.value.length;
      end = title.value.length;
    }
    setTitleFocused(false);
    // eslint-disable-next-line
    title.setRangeText('${' + mergeTag.tagName + '}', start, end);
    setTextBlockTitle(title.value);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCancel}>
        <Modal.Header>
          <Modal.Title
            color="gray.600"
            display="flex"
            gap={2}
            alignItems="center"
          >
            Text
            {isCampaignTextBlock && (
              <Tooltip label="Set a default title and text" placement="top">
                <InfoIcon color="gray.500" />
              </Tooltip>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl mb={6}>
            <FormControl.FormLabel mr={0}>
              <Flex align="center" justify="space-between">
                <Flex align="center" gap={2}>
                  Title
                </Flex>
                {!isCampaignTextBlock && (
                  <Text size="sm" fontWeight="500" color="primary.700">
                    <Link as={ReactRouterLink} to={contentSettingsLink}>
                      Change default
                    </Link>
                  </Text>
                )}
              </Flex>
            </FormControl.FormLabel>
            <Input
              type="text"
              placeholder="Text title"
              maxLength="120"
              value={textBlockTitle}
              onChange={handleTitleChange}
              onFocus={handleFocus}
              onKeyUp={handleKeyUp}
              id="title"
            />
            {showMergeTags &&
              mergeTagArray.map(tagName => (
                <MergeTag
                  mt={2}
                  mr={1}
                  key={tagName}
                  label={mergeTags[tagName].displayName}
                  onClick={() => addMergeTag(tagName, 'title')}
                />
              ))}
          </FormControl>
          {!isCampaignTextBlock && (
            <FormControl isInvalid={imageURLError} mb={6}>
              <FormControl.FormLabel>
                Image link
                <Tooltip
                  label="Image can be any width/height, but will be scaled to 536px width for display on desktop devices."
                  placement="top"
                >
                  <InfoIcon
                    className="ebx-info-icon"
                    style={{
                      marginLeft: '8px',
                      position: 'relative',
                      bottom: '1px',
                    }}
                  />
                </Tooltip>
              </FormControl.FormLabel>
              <InputGroup>
                <Input
                  pr={10}
                  type="url"
                  value={imageURL}
                  onChange={handleImageLinkChange}
                />
                {imageURL && url.isValidURL(imageURL) && (
                  <InputRightElement height={9}>
                    <Link
                      href={imageURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      height="inherit"
                      display="flex"
                      alignItems="center"
                    >
                      <OpenInNewIcon color="gray.500" />
                    </Link>
                  </InputRightElement>
                )}
              </InputGroup>
              {!imageURLError ? (
                <FormControl.FormHelperText>
                  JPEG or PNG. SVG images and animated GIFs may not display on
                  all email clients.
                </FormControl.FormHelperText>
              ) : (
                <FormControl.FormErrorMessage>
                  Please use a valid image link
                </FormControl.FormErrorMessage>
              )}
            </FormControl>
          )}
          <FormControl isInvalid={linkError}>
            <FormControl.FormLabel>
              <Flex align="center" gap={2}>
                Text
              </Flex>
            </FormControl.FormLabel>
            <Text size="sm">
              <Editor
                campaignName={campaignDetails.campaignName}
                scheduledUnixTime={
                  editionDetails ? editionDetails.scheduledUnixTime : null
                }
                editorState={editorState}
                onChange={setEditorState}
                onFocus={handleFocus}
                onLinkError={setLinkError}
                setEditorRef={setEditorRef}
                toolbarOptions={EDITOR_TOOLBAR_OPTIONS.EXPANDED}
              />
              {showMergeTags &&
                mergeTagArray.map(tagName => (
                  <MergeTag
                    mt={-2}
                    mr={1}
                    key={tagName}
                    label={mergeTags[tagName].displayName}
                    onClick={() => addMergeTag(tagName, 'body')}
                  />
                ))}
            </Text>
            <FormControl.FormErrorMessage>
              {linkError}
            </FormControl.FormErrorMessage>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onClose}
            isLoading={isSavingTextBlock}
          >
            Cancel
          </Button>
          <Tooltip
            label="Cannot add an image without a title or description"
            isDisabled={!isFormInvalid()}
            shouldWrapChildren
            placement="top"
          >
            <Button
              variant="primary"
              onClick={handleSubmit}
              isLoading={isSavingTextBlock}
              isDisabled={imageURLError || isFormInvalid()}
            >
              Save
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
      {isOpenMergeTagModal && (
        <MergeTagModal
          isOpen={isOpenMergeTagModal}
          mergeTag={currentMergeTag}
          onClose={onCloseMergeTagModal}
          onSubmit={saveMergeTag}
        />
      )}
    </>
  );
}

EditTextBlock.propTypes = {
  campaignDetails: PropTypes.shape({
    campaignName: PropTypes.string.isRequired,
    campaignURN: PropTypes.string.isRequired,
  }).isRequired,
  editionDetails: PropTypes.shape({
    editionURN: PropTypes.string.isRequired,
    approvalState: PropTypes.oneOf(Object.keys(EDITION_APPROVAL_STATES))
      .isRequired,
    scheduledUnixTime: PropTypes.number.isRequired,
  }),
  textBlock: PropTypes.shape({
    textBlockURN: PropTypes.string.isRequired,
    textBlockBody: PropTypes.string,
    textBlockTitle: PropTypes.string.isRequired,
    imageURL: PropTypes.string,
  }).isRequired,
  handleSaveTextBlock: PropTypes.func.isRequired,
  isSavingTextBlock: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mergeTags: PropTypes.objectOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      defaultValue: PropTypes.string.isRequired,
    })
  ),
};

EditTextBlock.defaultProps = {
  editionDetails: {},
  mergeTags: null,
};

export default EditTextBlock;
