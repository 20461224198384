import PropTypes from 'prop-types';

import * as maths from 'common/maths';

function PerformanceStatistic({
  performanceLoading,
  performanceObject,
  performanceKey,
}) {
  if (performanceLoading) {
    return <span>...</span>;
  }

  if (!performanceObject || performanceObject?.numDeliveries === null) {
    return <span>No data available</span>;
  }

  if (performanceObject?.numDeliveries === 0) {
    return <span>Processing</span>;
  }

  return (
    <span>
      {maths.percent(
        performanceObject[performanceKey] / performanceObject.numDeliveries,
        2
      )}
    </span>
  );
}

PerformanceStatistic.propTypes = {
  performanceLoading: PropTypes.bool,
  performanceObject: PropTypes.shape({
    numDeliveries: PropTypes.number,
  }),
  performanceKey: PropTypes.string.isRequired,
};

PerformanceStatistic.defaultProps = {
  performanceLoading: false,
  performanceObject: null,
};

export default PerformanceStatistic;
