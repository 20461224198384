import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * putTextBlock
 * @param {{
 *  textBlockURN: string;
 *  textBlockDetails: object;
 * }} args
 */
export default async function putTextBlock({
  textBlockURN = mandatory('textBlockURN'),
  textBlockDetails = mandatory('textBlockDetails'),
}) {
  const guid = metrics.start('putTextBlock');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/textblocks/${textBlockURN}`,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      ...textBlockDetails,
    }),
  };
  logger.info(`API:putTextBlock /textblocks/${textBlockURN}`);

  try {
    const response = await axios(config);
    metrics.end('putTextBlock', guid);
    return response.data;
  } catch (error) {
    metrics.fail('putTextBlock', guid);
    const apiError = await handleAPIError({
      originalError: error,
      // TODO - work out how to sort out this TypeScript hack at some point
      // @ts-ignore
      errorLocation: 'api/putTextBlock',
      args: { textBlockDetails },
    });
    throw apiError;
  }
}
