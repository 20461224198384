import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as Calendar } from 'assets/svg/calendar.svg';
import { Button } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerInput = forwardRef(({ value, onClick, date }, ref) => (
  <Button className="ebx-btn-secondary ebx-h3 py-2 w-100" onClick={onClick}>
    <div className="d-flex justify-content-between align-items-center">
      {value}
      {date ? <Calendar /> : <ArrowDownIcon className="ml-3" />}
    </div>
  </Button>
));

DatePickerInput.propTypes = {
  value: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  date: PropTypes.bool.isRequired,
};

export default DatePickerInput;
