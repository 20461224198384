/* eslint react-hooks/exhaustive-deps: "off" */

import { Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreviewWrapperInner from 'components/editor/preview/PreviewWrapper';

import * as properties from 'common/properties';
import * as string from 'common/string';
import useGlobalInfo from 'hooks/useGlobalInfo';

import { HUB_CHANNELS, HUB_EVENTS } from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';

import { Sidebar } from '../common';

function PreviewWrapper({ subPath, backLabel, children }) {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);

  /**
   * This is all a bit hacky, but the alternative is a significant rewrite of
   * this corner of the settings universe to resolve the upside-down inside-out
   * way that components are organised... let's fix it when we reorganise
   * all of the settings pages instead.
   *
   * Anyway, the way it works is that we have to listed to the hub event
   * emitted when changes settings have been saved, we then use this to force
   * the preview to re-render by passing in a random string. It's completely
   * NOT how React is supposed to be used, but hey, it works...
   */

  const [guid, setGuid] = useState(string.generateGuid());

  useEffect(() => {
    Hub.listen(HUB_CHANNELS.SETTINGS, handleMessage);
    return () => {
      Hub.remove(HUB_CHANNELS.SETTINGS, handleMessage);
    };
  }, []);

  const handleMessage = data => {
    const { event } = data.payload;
    if (event === HUB_EVENTS.SETTINGS.STATUS_UNCHANGED) {
      setGuid(string.generateGuid());
    }
  };

  return (
    <Row className="settings-branding w-100 m-0">
      <Col xs={12} md={4}>
        <Sidebar>
          <Link
            to={`/settings/campaigns/allcampaigns${
              subPath !== '' ? `/${subPath}` : ''
            }`}
            className="d-flex align-items-center mb-3"
          >
            <ArrowBackIcon className="settings-back-arrow mr-1" />
            <span className="ebx-h3">Back to {backLabel}</span>
          </Link>
          {children}
        </Sidebar>
      </Col>
      <Col xs={12} md={8} className="mt-3">
        <PreviewWrapperInner
          propertyURN={propertyURN}
          previewTypes={[PREVIEW_TYPES.MULTI_CAMPAIGN_SUBS_PAGE]}
          settings={{}}
          forceReload={guid}
        />
      </Col>
    </Row>
  );
}

PreviewWrapper.propTypes = {
  subPath: PropTypes.string,
  backLabel: PropTypes.string,
  children: PropTypes.node,
};

PreviewWrapper.defaultProps = {
  subPath: '',
  backLabel: 'Branding Settings',
  children: null,
};

export default PreviewWrapper;
