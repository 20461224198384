import { Text, VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';
import { ReactComponent as VerifiedIcon } from 'assets/svg/verified.svg';
import Editor from 'components/common/Editor';
import URLInput from 'components/common/URLInput';

import * as API from 'api/api';
import * as image from 'common/image';
import * as string from 'common/string';
import { isValidURL } from 'common/url';
import useDebounce from 'hooks/useDebounce';

import {
  ARTICLE_STATUS,
  DEFAULT_DEBOUNCE_DELAY,
  EDITOR_TOOLBAR_OPTIONS,
} from 'common/constants';
import { MEDIA_DATA_SOURCE_TYPES } from 'common/enums';
import { MediaDataSources } from 'common/types';

import './EditContent.css';

const EditContentSchema = Yup.object().shape({
  url: Yup.string()
    .max(1000, 'Max length is 1000!')
    .required('URL is required!')
    .trim(),
  headline: Yup.string()
    .max(1000, 'Max length is 1000!')
    .required('Headline is required!')
    .trim(),
  description: Yup.string().max(1000, 'Max length is 1000!'),
});

interface EditContentForm {
  url: string;
  headline: string;
  description: string;
}

interface TempUrlDetails {
  title?: string | null;
  ogTitle?: string | null;
  twitterTitle?: string | null;
  description?: string | null;
  ogDescription?: string | null;
  twitterDescription?: string | null;
  ogImages?: ReadonlyArray<string | null> | null;
  twitterImages?: ReadonlyArray<string | null> | null;
  siteName: string;
  lastUpdatedTime: number;
}

interface EditContentModalProps {
  articleURL?: string;
  description?: string;
  handleSaveArticleDetails: (
    sectionURN: string,
    index: number,
    finalURL: string,
    title: string,
    description: string,
    imageURL: string,
    mediaURN: string,
    callback: () => void
  ) => void;
  hideModal: () => void;
  imageURL?: string;
  index: number;
  isSavingArticleDetails: boolean;
  sectionURN: string;
  dataSources: MediaDataSources;
  isShowModal: boolean;
  title?: string;
  modalTitle?: string;
}

function EditContentModal({
  articleURL = '',
  description = '',
  handleSaveArticleDetails,
  hideModal,
  imageURL = '',
  index,
  isSavingArticleDetails,
  sectionURN,
  dataSources,
  isShowModal,
  title = '',
  modalTitle = 'Edit article',
}: EditContentModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue,
  } = useForm<EditContentForm>({
    mode: 'onSubmit',
    resolver: yupResolver(EditContentSchema),
    shouldUnregister: true,
  });
  const [imageError, setImageError] = useState('');

  const getInitialContentState = () => {
    const blocksFromHtml = htmlToDraft(description ?? '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  };
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(getInitialContentState())
  );
  // eslint-disable-next-line
  const [editorRef, setEditorRef] = useState(null);
  const [linkError, setLinkError] = useState('');

  const onSubmit = async () => {
    // Only resolve the image if it isn't empty
    if (tempImageURL?.length && tempImageURL.length > 0) {
      const imageResolved = await image.doesImageResolve(tempImageURL);
      if (!imageResolved) {
        setImageError('Image URL is invalid or not found');
        return;
      }
    }

    handleSaveArticleDetails(
      sectionURN,
      index,
      tempArticleURL,
      tempHeadline,
      tempDescription,
      tempImageURL,
      tempMediaURN,
      handleClose
    );
  };

  const [tempHeadline, setTempHeadline] = useState(title);
  const [tempDescription, setTempDescription] = useState(description);
  const [tempArticleURL, setTempArticleURL] = useState(articleURL);
  const [tempImageURL, setTempImageURL] = useState(imageURL);
  const [tempMediaURN, setTempMediaURN] = useState('');
  const [debouncedURL, setDebouncedURL] = useDebounce(
    tempArticleURL,
    DEFAULT_DEBOUNCE_DELAY,
    true
  );
  const [articleStatus, setArticleStatus] = useState(ARTICLE_STATUS.EMPTY);
  const [articleURLError, setArticleURLError] = useState('');

  const resetText = () => {
    setTempHeadline('');
    setTempDescription('');
  };

  const checkArticle = useCallback(
    async (urlToCheck: string) => {
      if (errors.url) {
        setArticleStatus(ARTICLE_STATUS.EMPTY);
        return;
      }
      setArticleStatus(ARTICLE_STATUS.LOADING);
      try {
        const { resolvedURL, mediaURN } = await API.getResolveURL({
          url: urlToCheck,
        });
        const urlDetails = (await API.getURLInfo({
          url: resolvedURL,
        })) as TempUrlDetails;

        const urlTitle = urlDetails.ogTitle
          ? urlDetails.ogTitle
          : urlDetails.twitterTitle;
        const urlDescription = urlDetails.ogDescription
          ? urlDetails.ogDescription
          : urlDetails.twitterDescription;
        const urlImages = urlDetails.ogImages
          ? urlDetails.ogImages
          : urlDetails.twitterImages;

        const missingFields = [];
        if (!urlTitle) {
          missingFields.push('title');
        }
        if (
          dataSources.description !== MEDIA_DATA_SOURCE_TYPES.NONE &&
          !urlDescription
        ) {
          missingFields.push('description');
        }
        if (!urlImages || !urlImages[0]) {
          missingFields.push('image');
        }

        if (missingFields.length > 0) {
          setArticleStatus(ARTICLE_STATUS.WARN);
          const fields = string.commaSeparatedList(missingFields, ' or ');
          setArticleURLError(`No OG/Twitter ${fields} found for article URL`);
        } else {
          setArticleStatus(ARTICLE_STATUS.SUCCESS);
        }

        setValue('headline', urlTitle ?? '');
        setTempHeadline(urlTitle ?? '');
        setValue('description', urlDescription ?? '');
        setTempDescription(urlDescription ?? '');
        const contentBlock = htmlToDraft(String(urlDescription));
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
        setValue('url', urlToCheck);
        setTempArticleURL(urlToCheck);
        setTempImageURL(
          (urlImages ?? []).length > 0 ? (urlImages ?? [])[0] ?? '' : ''
        );

        setTempMediaURN(mediaURN);
      } catch (error) {
        setArticleStatus(ARTICLE_STATUS.ERROR);
        setArticleURLError('Could not resolve the article URL');
        setFocus('url');
      }
    },
    [errors.url, setFocus, setValue, dataSources]
  );

  useEffect(() => {
    if (articleURL === debouncedURL) {
      return;
    }

    resetText();

    if (debouncedURL !== '') {
      checkArticle(debouncedURL);
    }
  }, [articleURL, checkArticle, debouncedURL]);

  useEffect(() => {
    setTimeout(() => {
      if (isShowModal) {
        setFocus('url');
      }
    }, 100);
  }, [isShowModal, setFocus]);

  const handleClose = () => {
    setArticleStatus(ARTICLE_STATUS.EMPTY);
    hideModal();
    // errors.description = null;
    // errors.headline = null;
  };

  const getEditorStateAsHtml = () =>
    draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(
      /\n/g,
      ''
    );

  const getDescription = () => {
    let text = getEditorStateAsHtml();
    if (text === '<p></p>') {
      text = '';
    }
    return text;
  };

  const handleDescriptionChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    const newDescription = getDescription();
    setTempDescription(newDescription);
    EditorState.createWithContent(getInitialContentState());
  };

  const handleHeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempHeadline(event.target.value);
    errors.headline = undefined;
  };

  const handleImageURLChange = (newImageURL: string) => {
    setTempImageURL(newImageURL);
    setImageError('');
  };

  const handleURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempArticleURL(event.target.value);
    errors.url = undefined;
  };

  const handleURLPasted = (event: React.ClipboardEvent) => {
    const newValue = event.clipboardData.getData('Text');

    // Stop handleURLChange from also being triggered
    event.preventDefault();

    // Immediately update debounced url on paste so loading happens immediately
    setDebouncedURL(newValue);
    setTempArticleURL(newValue);
    errors.url = undefined;
  };

  const isInputDisabled =
    isSavingArticleDetails || articleStatus === ARTICLE_STATUS.LOADING;
  const titleDisabled = isInputDisabled ? 'title-disabled' : '';
  const url = register('url');

  return (
    <Modal show={isShowModal} onHide={handleClose} backdrop="static" centered>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="ebx-modal-header">
          <Modal.Title className="ebx-h2">{modalTitle}</Modal.Title>
          <span className="ml-auto">
            <Button variant="link" className="p-0" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body className="ebx-body-1 ebx-modal-body">
          <FormGroup className="edit-content-form-group">
            <FormLabel className={`ebx-h3 mb-1 ${titleDisabled}`}>
              <div>Article link</div>
            </FormLabel>
            <InputGroup
              className={`mb-2 ${
                errors.url || articleStatus === ARTICLE_STATUS.ERROR
                  ? 'ebx-input-error'
                  : ''
              }`}
            >
              <FormControl
                {...url}
                className="ebx-input url-input ebx-body-1 form-control"
                placeholder=""
                value={tempArticleURL}
                disabled={isInputDisabled}
                onChange={e => {
                  url.onChange(e);
                  handleURLChange(e as any);
                }}
                onPaste={handleURLPasted}
              />
            </InputGroup>
            {/* Yup Errors */}
            {errors.url && articleStatus !== ARTICLE_STATUS.ERROR && (
              <div className="d-flex align-items-center error--text mt-2 mb-2">
                <span className="w-100">{errors.url.message}</span>
              </div>
            )}
            {/* Check URL Errors */}
            {!errors.url &&
              (articleStatus === ARTICLE_STATUS.ERROR ||
                articleStatus === ARTICLE_STATUS.WARN) && (
                <div className="error--text">{articleURLError}</div>
              )}
          </FormGroup>
          <FormGroup className="edit-content-form-group">
            <FormLabel className={`ebx-h3 mb-1 ${titleDisabled}`}>
              <div>Image link</div>
            </FormLabel>
            <InputGroup
              className={`mb-2 ${imageError !== '' ? 'ebx-input-error' : ''}`}
            >
              <URLInput
                invalidURL={!isValidURL(tempImageURL)}
                value={tempImageURL}
                onChange={handleImageURLChange}
                disabled={isInputDisabled}
              />
            </InputGroup>
            <Text size="sm" color="gray.600">
              JPEG or PNG format preferred. SVG images and animated GIFs may not
              display on all email clients.
            </Text>
            {imageError !== '' && (
              <div className="error--text mt-2 ebx-small-text">
                {imageError}
              </div>
            )}
          </FormGroup>
          <FormGroup className="edit-content-form-group">
            <FormLabel className={`ebx-h3 mb-1 ${titleDisabled}`}>
              <div>Headline</div>
            </FormLabel>

            <FormControl
              {...register('headline')}
              type="text"
              placeholder=""
              disabled={isInputDisabled}
              value={tempHeadline}
              onChange={handleHeadlineChange}
              className={`ebx-input url-input ebx-body-1 form-control ${
                errors.headline ? 'ebx-input-error' : ''
              }`}
            />
            {errors.headline && (
              <div className="d-flex align-items-center error--text mt-2 mb-2">
                <span className="w-100">{errors.headline.message}</span>
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel className={`ebx-h3 mb-1 ${titleDisabled}`}>
              <div>Description</div>
            </FormLabel>
            <Editor
              campaignName="campaignDetails.campaignName"
              // @ts-ignore
              scheduledUnixTime="editionDetails ? editionDetails.scheduledUnixTime : null"
              editorState={editorState}
              isDisabled={isInputDisabled}
              onChange={handleDescriptionChange}
              onLinkError={setLinkError}
              // @ts-ignore
              setEditorRef={setEditorRef}
              toolbarOptions={EDITOR_TOOLBAR_OPTIONS.EXPANDED}
            />
            {linkError !== '' && (
              <div className="d-flex align-items-center error--text mt-2 mb-2">
                <span className="w-100">{linkError}</span>
              </div>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="ebx-modal-footer">
          <div className="article-state-message">
            {articleStatus === ARTICLE_STATUS.LOADING && (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="ebx-link mr-2"
                />
                <span className="ebx-link ebx-h3">Loading article</span>
              </>
            )}
            {articleStatus === ARTICLE_STATUS.SUCCESS && (
              <div className="article-success-state">
                <VerifiedIcon className="mr-2" />
                <span className="ebx-h3">Article loaded</span>
              </div>
            )}
          </div>
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            onClick={handleClose}
            disabled={isSavingArticleDetails}
          >
            Cancel
          </Button>
          <Button
            className="ebx-btn-primary ebx-btn-md ebx-h3"
            type="submit"
            disabled={
              (articleStatus !== ARTICLE_STATUS.SUCCESS &&
                articleStatus !== ARTICLE_STATUS.WARN &&
                articleStatus !== ARTICLE_STATUS.EMPTY) ||
              isSavingArticleDetails
            }
          >
            {isSavingArticleDetails ? (
              <div>
                Saving...
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
                <VisuallyHidden>Saving...</VisuallyHidden>
              </div>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditContentModal;
