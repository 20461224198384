import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form, FormGroup, FormLabel, Modal } from 'react-bootstrap';

import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';
import { Typeahead } from 'react-bootstrap-typeahead';

import Consent from 'components/campaigns/subscribers/add/Consent';

import * as API from 'api/api';
import { FRONTEND_FORM_STATES, MODAL_MODES } from 'common/enums';
import { getErrorMessage } from 'common/errors';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import * as validation from 'common/validation';

import './AddManuallyModal.css';

function Input({
  handleClose,
  setModalState,
  errorMessage,
  setErrorMessage,
  subscriberEmailAddresses,
  setSubscriberEmailAddresses,
}) {
  const handleChange = event => {
    const subscriberEmails = [];
    const lowerCaseEmails = new Set();
    if (event.length) {
      event.forEach(item => {
        const itemToAdd = item?.label ?? item;
        if (!validation.isValidEmail(itemToAdd)) {
          setErrorMessage('Invalid email address');
        } else if (!lowerCaseEmails.has(itemToAdd.toLowerCase())) {
          subscriberEmails.push(itemToAdd);
          lowerCaseEmails.add(itemToAdd.toLowerCase());
          setErrorMessage(null);
        }
      });
    }
    setSubscriberEmailAddresses(subscriberEmails);
  };

  return (
    <Modal
      show
      onHide={handleClose}
      className="subscribe-modal"
      backdrop="static"
      centered
    >
      <Form>
        <Modal.Header className="ebx-modal-header">
          <Modal.Title className="ebx-h1 ebx-title-colour">
            Add subscribers
          </Modal.Title>
          <span className="ml-auto">
            <Button variant="link" className="p-0" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body className="ebx-body-1 ebx-modal-body">
          <FormGroup>
            <FormLabel
              className="ebx-h3 mb-2 ebx-title-colour"
              id="preview_label"
            >
              <div>Email addresses</div>
            </FormLabel>
            <Typeahead
              id="subscribe_emails_typeahead"
              name="emailAddresses"
              emptyLabel="Please type an email address..."
              value={subscriberEmailAddresses}
              onChange={handleChange}
              newSelectionPrefix="Add email: "
              options={[]}
              selected={subscriberEmailAddresses}
              placeholder="Please type an email address..."
              allowNew
              multiple
            />
          </FormGroup>
          {!errorMessage ? (
            <div className="d-flex align-items-center mt-2 mb-2">
              <span className="w-100">
                Add subscribers for the Campaign. Any duplicates will be
                skipped.
              </span>
            </div>
          ) : (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span className="w-100">{errorMessage}</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="ebx-modal-footer">
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            onClick={handleClose}
          >
            Back
          </Button>
          <Button
            className="ebx-btn-primary ebx-btn-md ebx-h3"
            type="button"
            disabled={subscriberEmailAddresses.length === 0}
            onClick={() => setModalState(FRONTEND_FORM_STATES.VERIFY)}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

Input.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  subscriberEmailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSubscriberEmailAddresses: PropTypes.func.isRequired,
};

function Success({ handleClose }) {
  return (
    <Modal
      show
      onHide={handleClose}
      className="subscribe-modal"
      backdrop="static"
      centered
    >
      <Form>
        <Modal.Header className="ebx-modal-header">
          <Modal.Title className="ebx-h1 ebx-title-colour">
            Successfully added subscribers
          </Modal.Title>
          <span className="ml-auto">
            <Button variant="link" className="p-0" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body className="py-4" mode={MODAL_MODES.WHITE}>
          <CheckIcon className="success-check" />
          <span className="pl-2 ebx-h4 ebx-title-colour">
            Your subscribers have been added to your Campaign.
          </span>
        </Modal.Body>
        <Modal.Footer className="ebx-modal-footer">
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

Success.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

function AddManuallyModal({
  campaignURN,
  hasActiveSubscribers,
  handleClose,
  handleSuccess,
}) {
  const [modalState, setModalState] = useState(FRONTEND_FORM_STATES.INPUT);
  const [subscriberEmailAddresses, setSubscriberEmailAddresses] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async () => {
    const subscriberDetails = subscriberEmailAddresses.map(email => ({
      email,
    }));

    try {
      tracker.track({
        eventName: 'Upload Subscribers',
        trackingParams: {
          Method: 'Add manually',
          'First Upload?': !hasActiveSubscribers,
        },
      });
      await API.postCampaignSubscribers({ campaignURN, subscriberDetails });
      handleSuccess();
      setModalState(FRONTEND_FORM_STATES.SUCCESS);
    } catch (error) {
      setModalState(FRONTEND_FORM_STATES.INPUT);
      if (error.response?.data?.error?.target === 'email') {
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage(getErrorMessage(error));
      }
    }
  };

  switch (modalState) {
    case FRONTEND_FORM_STATES.INPUT:
      return (
        <Input
          handleClose={handleClose}
          setModalState={setModalState}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          subscriberEmailAddresses={subscriberEmailAddresses}
          setSubscriberEmailAddresses={setSubscriberEmailAddresses}
        />
      );
    case FRONTEND_FORM_STATES.VERIFY:
      return (
        <Consent
          handleClose={handleClose}
          handleBack={() => setModalState(FRONTEND_FORM_STATES.INPUT)}
          handleSubmit={handleSubmit}
        />
      );
    case FRONTEND_FORM_STATES.SUCCESS:
      return <Success handleClose={handleClose} />;
    default:
      logger.error({
        event: 'Unknown modal form state',
        error: `Unknown form state ${modalState}`,
      });
      return null;
  }
}

AddManuallyModal.propTypes = {
  campaignURN: PropTypes.string.isRequired,
  hasActiveSubscribers: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func,
};

AddManuallyModal.defaultProps = {
  handleSuccess: () => {},
};

export default AddManuallyModal;
