import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postEditions({
  campaignURN = mandatory('campaignURN'),
  editionDetails = mandatory('editionDetails'),
}) {
  const guid = metrics.start('postEditions');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/campaigns/${campaignURN}/editions`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      ...editionDetails,
    }),
  };
  logger.info(`API:postEditions /campaigns/${campaignURN}/editions`);

  try {
    const response = await axios(config);
    metrics.end('postEditions', guid);
    return response.data.editionURN;
  } catch (error) {
    metrics.fail('postEditions', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postEditions',
      args: { editionDetails },
    });
    throw apiError;
  }
}
