import * as API from 'api/api';
import { apiBaseURL, APIS } from 'api/settings';
import { EXAMPLE_USER, PREVIEW_TYPE_PROPERTIES } from 'common/constants';
import { replacePlaceholders } from 'common/string';
import { mandatory } from 'common/validation';

const fetchPreview = async ({
  setPreview = mandatory('setPreview'),
  setLoadingPreview = mandatory('setLoadingPreview'),
  setErrorMessage = mandatory('setErrorMessage'),
  setErrorShow = mandatory('setErrorShow'),
  logger = mandatory('logger'),
  previewDetails = mandatory('details'),
  previewType = mandatory('previewType'),
} = {}) => {
  try {
    setLoadingPreview(true);
    const previewURL = getPreviewURL(previewDetails, previewType);
    const previewHTML = await API.getPreview(
      previewDetails,
      previewURL,
      previewType
    );
    setPreview(previewHTML);
    setLoadingPreview(false);
  } catch (error) {
    logger.error({
      event: 'fetchPreview',
      error,
    });
    setErrorMessage('Unable to load preview');
    setErrorShow(true);
    setLoadingPreview(false);
  }
};

function getPreviewURL(previewDetails, previewType) {
  const previewDetailsWithDefaults = {
    campaignName: 'Campaign',
    emailAddress: EXAMPLE_USER,
    ...previewDetails,
  };

  const previewTypeProperties = PREVIEW_TYPE_PROPERTIES[previewType];

  // Validate previewTypeProperties
  if (previewTypeProperties === undefined) {
    throw new Error(
      `No preview type properties found for preview type ${previewType}`
    );
  }

  const previewURL = replacePlaceholders(
    PREVIEW_TYPE_PROPERTIES[previewType].ENDPOINT_PATH,
    previewDetailsWithDefaults
  );
  // Validate previewURL has no more placeholders
  if (previewURL.indexOf('{') !== -1 || previewURL.indexOf('}') !== -1) {
    throw new Error(
      'Insufficient details provided to replace placeholders in preview URL'
    );
  }

  if (PREVIEW_TYPE_PROPERTIES[previewType].ASSET_TYPE === 'PAGE') {
    return `${apiBaseURL(APIS.NEWSLETTERS_SUBS, '')}/${previewURL}`;
  }

  return `${apiBaseURL(APIS.NEWSLETTERS)}/${previewURL}`;
}

export default fetchPreview;
