import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';

import { ErrorAlertIcon, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';

function Error({ errorMessage }) {
  return (
    <div className="error__border">
      <Navbar
        collapseOnSelect
        variant="light"
        expand="lg"
        id="subheader"
        className="navbar--height w-100 error__box"
      >
        <Nav className="d-flex flex-wrap flex-row align-items-center px-2 w-100">
          <Flex>
            <ErrorAlertIcon color="red.600" mr={2} />
            <span className="ebx-small-text">{errorMessage}</span>
          </Flex>
        </Nav>
      </Navbar>
    </div>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default Error;
