/* eslint no-use-before-define: "off" */

import { isNullOrUndefined, isUndefined } from 'common/utility';

import { REFRESH_INTERVALS } from 'common/config';

export { getItem, removeItem, setItem };

const getItem = key => {
  if (window?.EBX?.intervals[key]) {
    return window.EBX.intervals[key];
  }
  return null;
};

const removeItem = key => {
  if (window?.EBX?.intervals[key]) {
    window.clearInterval(window.EBX.intervals[key]);
    delete window.EBX.intervals[key];
  }
};

const setItem = (key, callback, multiplier) => {
  if (isUndefined(window.EBX)) {
    window.EBX = {};
  }
  if (isUndefined(window.EBX.intervals)) {
    window.EBX.intervals = {};
  }
  window.EBX.intervals[key] = window.setInterval(
    callback,
    refreshInterval(multiplier)
  );
};

const refreshInterval = multiplier => {
  let interval = localStorage.getItem('refreshInterval');
  if (isNullOrUndefined(interval)) {
    interval = REFRESH_INTERVALS.BASE_VALUE;
  }
  return interval * multiplier;
};
