import {
  Box,
  Center,
  Flex,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Error from 'components/campaigns/Error';
import Header from 'components/campaigns/subscribers/management/Header';
import SubscriberList, {
  SortableColumn,
  SUBSCRIBER_MANAGEMENT_COLUMNS,
} from 'components/campaigns/subscribers/management/SubscriberList';

import * as API from 'api/api';
import { SORT_ORDERS, SUBSCRIBER_MANAGEMENT_SORT_FIELDS } from 'common/enums';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import { SubscriberInsights } from 'common/types';

import useLoadSubscribers from 'hooks/useLoadSubscribers';
import useInfiniteScroll from 'react-infinite-scroll-hook';

function SubscriberManagement() {
  const [sortBy, setSortBy] = useState<
    keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS
  >(SUBSCRIBER_MANAGEMENT_SORT_FIELDS.addedUnixTime);
  const [sortOrder, setSortOrder] = useState(SORT_ORDERS.DESC);
  const [searchString, setSearchString] = useState('');

  const { id: campaignURN } = useParams<{ id: string }>();

  const [campaignInsights, setCampaignInsights] =
    useState<SubscriberInsights | null>(null);
  const [loadInsights, setLoadInsights] = useState(true);

  useEffect(() => {
    const fetchCampaignInsights = async () => {
      if (loadInsights) {
        try {
          logger.info('SubscriberManagement:fetchCampaignInsights');
          const retrievedCampaignInsights = await API.getCampaignInsights({
            campaignURNs: campaignURN,
          });
          setCampaignInsights(retrievedCampaignInsights.get(campaignURN));
        } catch (error) {
          logger.error({
            event: 'SubscriberManagement:fetchCampaignInsights',
            error,
          });
        } finally {
          setLoadInsights(false);
        }
      }
    };
    fetchCampaignInsights();
  }, [campaignURN, loadInsights]);

  const {
    isLoading,
    items: subscriberList,
    hasNextPage,
    errorMessage,
    loadMore,
    refetch,
  } = useLoadSubscribers(campaignURN, sortBy, sortOrder, searchString);
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!errorMessage,
    rootMargin: '0px 0px 200px 0px',
  });

  useEffect(() => {
    tracker.track({
      eventName: 'Access Subscriber Management Page',
    });
  }, []);

  const handleSubscribersChanged = () => {
    setLoadInsights(true);
    refetch();
  };

  useEffect(() => {
    if (searchString) {
      tracker.track({
        eventName: 'Search Subscribers List',
        trackingParams: {
          'Search terms': searchString,
        },
      });
    }
  }, [searchString]);

  const handleSort = (
    newSortBy: keyof typeof SUBSCRIBER_MANAGEMENT_SORT_FIELDS
  ) => {
    let newSortOrder;
    if (sortBy === newSortBy) {
      newSortOrder =
        sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC;
    } else {
      const column = SUBSCRIBER_MANAGEMENT_COLUMNS.find(
        col => col.name === newSortBy
      ) as SortableColumn;
      newSortOrder = column?.defaultSortOrder ?? SORT_ORDERS.ASC;
    }
    tracker.track({
      eventName: 'Sort Subscribers List',
      trackingParams: {
        'Sort field': newSortBy,
        'Sort order': newSortOrder,
      },
    });
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  };

  return (
    <Flex direction="column" gap={4}>
      <Header
        campaignInsights={campaignInsights}
        searchString={searchString}
        setSearchString={setSearchString}
        handleSubscribersChanged={handleSubscribersChanged}
      />
      {errorMessage ? (
        <Error errorMessage={errorMessage} />
      ) : (
        <>
          <SubscriberList
            campaignURN={campaignURN}
            subscriberList={subscriberList}
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSort={handleSort}
            handleUnsubscribe={handleSubscribersChanged}
          />
          <Box ref={sentryRef} height={8} pt={8} pb={16}>
            {isLoading && (
              <Center>
                <Spinner color="gray.600" />
              </Center>
            )}
          </Box>
        </>
      )}
    </Flex>
  );
}

export default SubscriberManagement;
