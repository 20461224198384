/* eslint no-use-before-define: "off" */

import * as datetime from 'common/datetime';
import * as utility from 'common/utility';

import {
  PROPERTY_INITIAL_IGNORE_WORDS,
  PROPERTY_STATES,
  /* PROPERTY_TYPES, */
  SORT_ORDERS,
} from 'common/constants';

export {
  getCurrentProperty,
  getCurrentPropertyId,
  getCurrentPropertyURN,
  getCurrentPropertyName,
  getDefaultPropertyURN,
  getProperty,
  getPropertyBilling,
  isPropertyShutOff,
  getPropertyId,
  getPropertyURNsByState,
  getPropertyName,
  getCurrentPropertySettings,
  getCurrentPropertySettingValue,
  prepareForSave,
  setCurrentPropertyURN,
  sortAlphabetic,
  sortNumeric,
  sortProperties,
  getPropertyInitials,
};

const getCurrentProperty = globalInfo => {
  const propertyURN = getCurrentPropertyURN(globalInfo);
  return propertyURN ? getProperty(propertyURN, globalInfo) : null;
};

/**
 * getCurrentPropertyURN
 * @param {import('store/store').GlobalInfo} globalInfo
 */
const getCurrentPropertyURN = globalInfo =>
  globalInfo?.current?.propertyURN ?? null;

const getCurrentPropertyName = globalInfo => {
  const property = getCurrentProperty(globalInfo);
  return property ? property.propertyName : null;
};

const getCurrentPropertyId = globalInfo => {
  const currentPropertyURN = getCurrentPropertyURN(globalInfo);
  return currentPropertyURN ? getPropertyId(currentPropertyURN) : null;
};

const getDefaultPropertyURN = propertyData => {
  let defaultPropertyURN = null;
  const sortedProperties = sortProperties(propertyData, SORT_ORDERS.NUMERIC);

  Object.values(sortedProperties).forEach(thisProperty => {
    if (
      !defaultPropertyURN &&
      thisProperty.propertyState === PROPERTY_STATES.ACTIVE /* &&
      thisProperty.propertyType === PROPERTY_TYPES.NEWSLETTERS */
      // TODO possible reinstate the property type test later
    ) {
      defaultPropertyURN = thisProperty.propertyURN;
    }
  });
  return defaultPropertyURN;
};

const getProperty = (propertyURN, globalInfo) =>
  globalInfo?.properties?.[propertyURN] ?? null;

const getPropertyBilling = (propertyURN, globalInfo) =>
  getProperty(propertyURN, globalInfo)?.propertyBilling ?? null;

const isPropertyShutOff = (propertyURN, globalInfo) => {
  const propertyBilling = getPropertyBilling(propertyURN, globalInfo);
  if (propertyBilling == null || propertyBilling?.shutOffUnixTime == null) {
    return false;
  }
  return datetime.getUnixTimestamp() > propertyBilling.shutOffUnixTime;
};

const getPropertyId = propertyURN =>
  Number(propertyURN.replace('urn:property:', ''));

const getPropertyURNsByState = (propertyState, globalInfo) =>
  globalInfo?.properties
    ? Object.keys(globalInfo.properties).filter(
        propertyURN =>
          globalInfo.properties[propertyURN].propertyState === propertyState
      )
    : [];

const getPropertyName = (propertyURN, globalInfo) => {
  const property = getProperty(propertyURN, globalInfo);
  return property ? property.propertyName : null;
};

const getSettingsForCurrentProperty = globalInfo => {
  const property = getCurrentProperty(globalInfo);
  return property ? property.propertySettings : null;
};

const getSettingForCurrentProperty = (globalInfo, settingsType) => {
  const propertySettings = getSettingsForCurrentProperty(globalInfo);

  let returnSetting = null;
  propertySettings.forEach(setting => {
    if (setting.settingsType === settingsType) {
      returnSetting = setting;
    }
  });
  return returnSetting;
};

const getCurrentPropertySettings = globalInfo => {
  const propertySettingsArray = getSettingsForCurrentProperty(globalInfo);
  const propertySettingsObject = propertySettingsArray.reduce(
    (accumulator, value) => ({ ...accumulator, [value.settingsType]: value }),
    {}
  );
  return propertySettingsObject;
};

const getCurrentPropertySettingValue = (
  globalInfo,
  settingsType,
  settingKey
) => {
  const setting = getSettingForCurrentProperty(globalInfo, settingsType);
  return setting?.[settingKey] ?? null;
};

/**
 * prepareForSave
 *
 * Takes a property object and strips out all fields that don't need to be provided
 * for a POST or PUT request
 *
 * @param {object} propert
 * @returns {object}
 */
const prepareForSave = property => {
  const propertyToSave = utility.cloneObject(property);
  const fieldsToSave = [
    'propertyName',
    'propertyRootURL',
    'propertyType',
    'companyDetails',
  ];
  Object.keys(property).forEach(key => {
    if (fieldsToSave.indexOf(key) === -1) {
      delete propertyToSave[key];
    }
  });
  return propertyToSave;
};

const setCurrentPropertyURN = (globalInfo, propertyURN = null) => {
  if (propertyURN) return propertyURN; // TODO - ensure this property is active
  if (globalInfo?.current?.propertyURN) return globalInfo.current.propertyURN;
  return getDefaultPropertyURN(globalInfo.properties);
};

const sortAlphabetic = (a, b) => {
  if (a.propertyName.toLowerCase() < b.propertyName.toLowerCase()) {
    return -1;
  }
  if (a.propertyName.toLowerCase() > b.propertyName.toLowerCase()) {
    return 1;
  }
  return 0;
};

const sortNumeric = (a, b) =>
  getPropertyId(a.propertyURN) - getPropertyId(b.propertyURN);

const sortProperties = (propertyData, sortOrder) => {
  switch (sortOrder) {
    case SORT_ORDERS.ALPHA: {
      return Object.values(propertyData).sort(sortAlphabetic);
    }
    case SORT_ORDERS.NUMERIC: {
      return Object.values(propertyData).sort(sortNumeric);
    }
    default: {
      return Object.values(propertyData);
    }
  }
};

/**
 * getPropertyInitials - Gets the initials of the property
 * @param {string} propertyName
 */
const getPropertyInitials = propertyName => {
  const words = propertyName
    .toUpperCase()
    .split(' ')
    .filter(word => !PROPERTY_INITIAL_IGNORE_WORDS.includes(word));

  if (words.length === 0) {
    return '';
  }
  if (words.length === 1) {
    return words[0].charAt(0);
  }

  return words[0].charAt(0) + words[1].charAt(0);
};
