/* eslint no-use-before-define: "off" */

/* global zE */

let haveHandledSubmit = false;

export {
  hideWidget,
  showWidget,
  onSubmit,
  openWidget,
  positionZendeskLeft,
  positionZendeskRight,
  prefillWidgetDescription,
};

function hideWidget() {
  setWidgetVisible(false);
}

function showWidget(user) {
  setWidgetVisible(true, user);
}

function openWidget() {
  zE('webWidget', 'open');
}

function positionZendeskRight() {
  zE('webWidget', 'updateSettings', {
    webWidget: {
      position: {
        horizontal: 'right',
        vertical: 'bottom',
      },
    },
  });
}

function positionZendeskLeft() {
  zE('webWidget', 'updateSettings', {
    webWidget: {
      position: {
        horizontal: 'left',
        vertical: 'bottom',
      },
    },
  });
}

function setWidgetVisible(show, user) {
  if (typeof zE !== 'undefined') {
    if (typeof user !== 'undefined') {
      zE('webWidget', 'prefill', {
        name: {
          value: user.name ?? '',
        },
        email: {
          value: user.emailAddress ?? '',
        },
      });
    }
    zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          suppress: !show,
        },
      },
    });
  }
}

function prefillWidgetDescription(description) {
  if (typeof zE !== 'undefined') {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          fields: [{ id: 'description', prefill: { '*': description } }],
        },
      },
    });
    haveHandledSubmit = false;
  }
}

function onSubmit(callbackFunction) {
  if (typeof zE !== 'undefined') {
    zE('webWidget:on', 'userEvent', e => {
      if (e.action === 'Contact Form Submitted' && !haveHandledSubmit) {
        callbackFunction();
        haveHandledSubmit = true;
      }
    });
  }
}
