import { Box, Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import ColorPicker from 'components/editor/blocks/attributes/ColorPicker';
import NumberPicker from 'components/editor/blocks/attributes/NumberPicker';
import SelectPicker from 'components/editor/blocks/attributes/SelectPicker';

import * as blocks from 'common/blocks';

interface BorderProps {
  onChange: (key: string, value: any) => void;
  value: string;
}

function Border({ onChange, value }: BorderProps) {
  if (!value) return null;

  const handleChange = (border: string) => {
    onChange('border', border);
  };

  const handleChangeColor = (name: string, color: string) => {
    const current = stringToElements(value);
    handleChange(elementsToString(color, current.style, current.width));
  };

  const handleChangeStyle = (name: string, style: string) => {
    const current = stringToElements(value);
    handleChange(elementsToString(current.color, style, current.width));
  };

  const handleChangeWidth = (name: string, width: string) => {
    const current = stringToElements(value);
    handleChange(elementsToString(current.color, current.style, width));
  };

  const stringToElements = (string: string) => {
    const elements = string.split(' ');
    // @ts-ignore
    const width = elements[0];
    const style = elements[1];
    const color = elements[2];

    return { color, style, width };
  };

  const elementsToString = (color: string, style: string, width: string) => {
    return `${width} ${style} ${color}`;
  };

  const elements = stringToElements(value);

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h4">Border</Heading>
      <Flex direction="column" gap={4}>
        <Flex direction="row" alignItems="center">
          <Box w="50%">Color</Box>
          <Box w="50%">
            <ColorPicker
              name="border-color"
              onChange={handleChangeColor}
              value={elements.color}
            />
          </Box>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box w="50%">Style</Box>
          <Box w="50%">
            <SelectPicker
              name="style"
              onChange={handleChangeStyle}
              // @ts-ignore
              options={blocks.WYSIWYG_LINE_STYLES}
              value={elements.style}
            />
          </Box>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box w="50%">Width</Box>
          <Box w="50%">
            <NumberPicker
              max={10}
              min={0}
              name="border-width"
              // @ts-ignore
              onChange={handleChangeWidth}
              unit="%"
              value={elements.width}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Border;
