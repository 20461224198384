import { HeaderTab, Tabs } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import useGlobalInfo from 'hooks/useGlobalInfo';

import * as authentication from 'common/authentication';
import { PRODUCT_TYPES, USER_TYPES } from 'common/enums';
import { getCurrentPropertyId } from 'common/properties';
import * as tracker from 'common/tracker';

import { ROUTE_REDIRECTIONS } from 'common/constants';
import type { FixTypeLater } from 'common/types';

const URLS = {
  SOCIAL_OVERVIEW: 'https://www.echobox.com/social',
  EMAIL_SIGNIN: 'https://email.echobox.com/signin',
  SOCIAL_SIGNIN: 'https://social.echobox.com/login',
};

const products: Array<PRODUCT_TYPES> = Object.values(PRODUCT_TYPES);

function isProduct(value: unknown): value is PRODUCT_TYPES {
  return typeof value === 'string' && products.includes(value as PRODUCT_TYPES);
}

const getTrackingParams = ({
  before,
  after,
  globalInfo,
}: {
  before: PRODUCT_TYPES;
  after: PRODUCT_TYPES;
  globalInfo: FixTypeLater;
}) => {
  const currentPropertyId = getCurrentPropertyId(globalInfo);

  return {
    Product: after,
    'Product Before': before,
    PropertyID: 'null',
    'Property ID before': currentPropertyId,
  };
};

function ProductTabs() {
  const [selectedValue, setSelectedValue] = useState(PRODUCT_TYPES.EMAIL);
  const global = useGlobalInfo();
  const cognitoGroups = authentication.getCognitoGroups();
  const isExistingSocialUser =
    cognitoGroups?.includes(USER_TYPES.SOCIAL) ||
    cognitoGroups?.includes(USER_TYPES.ECHOBOX_STAFF);

  let socialUrl = URLS.SOCIAL_OVERVIEW;

  if (isExistingSocialUser) socialUrl = URLS.SOCIAL_SIGNIN;

  const handleChange = (value: unknown) => {
    if (!isProduct(value)) {
      throw new Error('Invalid value given to product tab');
    }

    if (value !== selectedValue) {
      tracker.track({
        eventName: 'Switch Product',
        trackingParams: getTrackingParams({
          before: selectedValue,
          after: value,
          globalInfo: global,
        }),
      });
    }
    if (value === PRODUCT_TYPES.SOCIAL) {
      return;
    }
    if (value === PRODUCT_TYPES.EMAIL) setSelectedValue(value);
  };

  return (
    <Tabs
      selectedValue={selectedValue}
      spacing={4}
      onChange={handleChange}
      display={{ base: 'none', md: 'contents' }}
    >
      <HeaderTab
        value={PRODUCT_TYPES.SOCIAL}
        href={socialUrl}
        isExternal={!isExistingSocialUser}
      >
        Social
      </HeaderTab>
      <HeaderTab value={PRODUCT_TYPES.EMAIL} href={ROUTE_REDIRECTIONS.HOME}>
        Email
      </HeaderTab>
    </Tabs>
  );
}
export default ProductTabs;
