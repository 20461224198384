import React from 'react';

import { Table, TableContainer } from '@ebx-ui/ebx-ui-component-library-sdk';

interface TeamTableProps {
  children: React.ReactNode;
}

function TeamTable({ children }: TeamTableProps) {
  return (
    <TableContainer mb={4} overflowX="visible" overflowY="visible">
      <Table
        variant="simple"
        border="1px"
        borderColor="gray.300"
        borderRadius="md"
        sx={{ borderCollapse: 'separate', borderSpacing: 0 }}
      >
        {children}
      </Table>
    </TableContainer>
  );
}

export default TeamTable;
