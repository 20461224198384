import {
  Button,
  Flex,
  Radio,
  RadioGroup,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as campaigns from 'common/campaigns';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as zendesk from 'common/zendesk';

import ContentSectionWrapper from 'components/settings/campaigns/content/ContentSectionWrapper';

interface TemplateUsedProps {
  globalInfo: any;
  campaignURN: string;
}

function TemplateUsed({ globalInfo, campaignURN }: TemplateUsedProps) {
  const propertyName = properties.getCurrentPropertyName(globalInfo);
  const campaignName = campaigns.getCampaignName(campaignURN, globalInfo);

  const trackCustomTemplate = () => {
    tracker.track({
      eventName: 'Request Custom Template',
    });
  };

  const selectCustomTemplate = () => {
    zendesk.openWidget();
    zendesk.prefillWidgetDescription(
      `I would like to upload my own custom template to ${campaignName} (${propertyName})`
    );
    zendesk.onSubmit(trackCustomTemplate);
  };

  return (
    <ContentSectionWrapper
      title="Template used"
      description="Choose between Echobox’s templates or use your own custom designed template"
    >
      <RadioGroup defaultValue="echobox">
        <Flex flexDir="column" gap={4}>
          <Radio value="echobox" mb={0}>
            Echobox template (full template optimization)
          </Radio>
          <Flex flexDir="column">
            <Radio value="custom">
              Custom template (no template optimization)
            </Radio>
            <Flex direction="column" align="flex-start" gap={2} ml={6}>
              <Text size="sm" color="gray.600">
                Our support team will review your custom template and contact
                you with next steps. Please note we can&apos;t guarantee
                uploading any custom templates.
              </Text>
              <Button variant="link" onClick={selectCustomTemplate} p={0} m={0}>
                Contact support
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </RadioGroup>
    </ContentSectionWrapper>
  );
}

export default TemplateUsed;
