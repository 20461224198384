import PropTypes from 'prop-types';
import { useEffect } from 'react';

import * as tracker from 'common/tracker';

import { ReactComponent as DownloadIcon } from 'assets/svg/download-icon.svg';

function UploadStatistics({ latestUpload }) {
  const handleExportClick = () => {
    tracker.track({
      eventName: 'Download Errors',
    });
  };

  useEffect(() => {
    const exportErrorsLink = document.getElementById('exportErrorsLink');
    if (exportErrorsLink) {
      exportErrorsLink.addEventListener('click', handleExportClick);
    }

    return () => {
      if (exportErrorsLink) {
        exportErrorsLink.removeEventListener('click', handleExportClick);
      }
    };
  }, []);

  return (
    <div>
      {latestUpload?.errorLogURL && latestUpload.errorLogURL !== '' && (
        <>
          <span>Some subscribers were not uploaded: </span>
          <a
            href={latestUpload.errorLogURL}
            target="_blank"
            rel="noopener noreferrer"
            id="exportErrorsLink"
            className="ebx-cursor ebx-link"
          >
            <span>export errors</span>
            <span className="ml-1">
              <DownloadIcon className="ebx-download-icon" />
            </span>
          </a>
        </>
      )}
    </div>
  );
}

UploadStatistics.propTypes = {
  latestUpload: PropTypes.shape({
    uploadURN: PropTypes.string.isRequired,
    uploadState: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    uploadTriggerUnixTime: PropTypes.number,
    processingStartUnixTime: PropTypes.number,
    processingCompletedUnixTime: PropTypes.number,
    uploadByEmail: PropTypes.string,
    uploadType: PropTypes.string.isRequired,
    errorLogURL: PropTypes.string,
  }).isRequired,
};

export default UploadStatistics;
