import { mandatory } from 'common/validation';

import createUploads from 'api/misc/createUploads';

export default async function postS3Uploads({
  uploadURL = mandatory('uploadURL'),
  fileContent = mandatory('fileContent'),
  onProgress = mandatory('onProgress'),
}) {
  try {
    const httpMethod = 'POST';
    const contentType = 'multipart/form-data';
    await createUploads({
      uploadURL,
      fileContent,
      onProgress,
      httpMethod,
      contentType,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}
