/* eslint react-hooks/exhaustive-deps: "off" */

import { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as API from 'api/api';

import { PROPERTY_SETTING_TYPES } from 'common/constants';
import * as datetime from 'common/datetime';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import { DateObject, FixTypeLater } from 'common/types';
import * as zendesk from 'common/zendesk';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import { useErrorHandler } from 'react-error-boundary';

interface PlanDetailsProps {
  propertyURN: string;
}

function PlanDetails({ propertyURN }: PlanDetailsProps) {
  const globalInfo = useGlobalInfo();
  const settings = useSettings();
  const property = properties.getCurrentProperty(globalInfo);

  const handleError = useErrorHandler();

  const [propertyInsights, setPropertyInsights] = useState<FixTypeLater | null>(
    null
  );
  const [insightsLoading, setInsightsLoading] = useState(true);

  useEffect(() => {
    const fetchPropertyInsights = async () => {
      if (propertyURN !== null) {
        try {
          logger.info('Property:fetchPropertyInsights');
          const insights = await API.getPropertyInsights({
            propertyURN,
          });
          setPropertyInsights(insights);
          setInsightsLoading(false);
        } catch (error) {
          handleError(error);
          setInsightsLoading(false);
        }
      }
    };
    fetchPropertyInsights();
  }, []);

  const available = Number(
    settings.getPropertySettingValue(
      PROPERTY_SETTING_TYPES.PLAN_DETAILS,
      'monthlyEmailQuotaNum'
    )
  );

  let resetDay = Number(
    settings.getPropertySettingValue(
      PROPERTY_SETTING_TYPES.PLAN_DETAILS,
      'quotaResetDayInMonth'
    )
  );

  // Fallback to 1 if quota reset day is null
  if (resetDay === 0) {
    resetDay = 1;
  }

  const sent = propertyInsights?.emailSendsInCurrentQuotaPeriod;

  const resetDate = new Date(datetime.getUnixTimestamp() * 1000);

  // If we are past the reset day for this month then next reset day will be next month
  if (resetDate.getDate() >= resetDay) {
    resetDate.setMonth(resetDate.getMonth() + 1);
  }

  resetDate.setDate(resetDay);

  const resetDateTime = datetime.getDateFromUnix(
    resetDate.getTime() / 1000
  ) as DateObject;

  const handleChangePlan = () => {
    zendesk.openWidget();
    zendesk.prefillWidgetDescription(
      `I would like to upgrade my package for Echobox Email on ${property.propertyName} Property.`
    );
  };

  return (
    <Card flexDir="column" alignItems="normal">
      <Flex alignItems="center" justifyContent="space-between" mb="30px">
        <Heading variant="h3">Plan details</Heading>
        <Button variant="secondary" onClick={handleChangePlan}>
          Change plan
        </Button>
      </Flex>
      <Flex gap={16}>
        <Flex flexDir="column" gap={2}>
          <Text size="sm" color="gray.600">
            Usage
          </Text>
          {insightsLoading ? (
            <Spinner size="sm" color="gray.600" />
          ) : (
            <Heading variant="h4">
              {sent?.toLocaleString()}
              {available && available !== 0
                ? ` / ${available.toLocaleString()}`
                : ''}
            </Heading>
          )}
        </Flex>
        <Flex flexDir="column" gap={2}>
          <Text size="sm" color="gray.600">
            Usage resets
          </Text>
          <Heading variant="h4">
            {resetDateTime.day} {resetDateTime.monthName}{' '}
            {resetDateTime.year.toString()}
          </Heading>
        </Flex>
      </Flex>
    </Card>
  );
}

export default PlanDetails;
