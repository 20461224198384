import PropTypes from 'prop-types';

function LogoPreview({ img }) {
  return (
    <div className="logo-preview">
      <img src={img} alt="logo" />
    </div>
  );
}

LogoPreview.propTypes = {
  img: PropTypes.string.isRequired,
};

export default LogoPreview;
