import PropTypes from 'prop-types';

import { Button, useDisclosure } from '@ebx-ui/ebx-ui-component-library-sdk';

import SaveTemplateModal from 'components/editor/marketing/SaveTemplateModal';

function SaveTemplate({ handleSave, isBusy }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="secondary" isDisabled={isBusy} onClick={onOpen}>
        Save Template
      </Button>
      {isOpen && (
        <SaveTemplateModal
          handleClose={onClose}
          handleSave={handleSave}
          showModal={isOpen}
        />
      )}
    </>
  );
}

SaveTemplate.propTypes = {
  handleSave: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
};

SaveTemplate.defaultProps = {
  isBusy: false,
};

export default SaveTemplate;
