import { Redirect, Route, Switch } from 'react-router-dom';

import { Flex, useTheme } from '@ebx-ui/ebx-ui-component-library-sdk';

import AllCampaignsRouting from 'components/settings/campaigns/all/Routing';
import AppsIntegrationsRouting from 'components/settings/campaigns/apps/Routing';
import AutomationSettings from 'components/settings/campaigns/AutomationSettings';
import BrandingRouting from 'components/settings/campaigns/branding/Routing';
import CampaignSettings from 'components/settings/campaigns/Campaign';
import CampaignDetailsSettings from 'components/settings/campaigns/CampaignDetails';
import CampaignList from 'components/settings/campaigns/Campaigns';
import ContentSettings from 'components/settings/campaigns/Content';
import SignupFormsRouting from 'components/settings/campaigns/signup/Routing';
import Header from 'components/settings/header/Header';
import PasswordSettings from 'components/settings/profile/Password';
import PersonalDetailsSettings from 'components/settings/profile/PersonalDetails';
import ProfileSettings from 'components/settings/profile/Profile';
import AddressSettings from 'components/settings/property/Address';
import FeedSettings from 'components/settings/property/Feeds';
import PropertySettings from 'components/settings/property/Property';
import WebsiteSettings from 'components/settings/property/Website';
import SettingsWrapper from 'components/settings/SettingsWrapper';
import TeamSettings from 'components/settings/team/Team';
import RequireAuth from 'containers/RequireAuth';

import * as properties from 'common/properties';
import * as routing from 'common/routing';
import useGlobalInfo from 'hooks/useGlobalInfo';

import './Settings.css';

function Settings() {
  const globalInfo = useGlobalInfo();

  // Gets navbar styles defined in the component library theme
  const {
    components: { navbar },
  } = useTheme();

  const property = properties.getCurrentProperty(globalInfo);
  const hasCampaigns = property.campaigns
    ? Object.keys(property.campaigns).length > 0
    : false;

  return (
    <>
      <Header />
      <Flex mt={navbar.height}>
        <Switch>
          <Route
            path="/settings"
            exact={true}
            render={() => <Redirect to="/settings/property" />}
          />
          <Route
            path="/settings/property"
            exact={true}
            render={() => (
              <RequireAuth redirector={routing.requireAdmin}>
                <SettingsWrapper>
                  <PropertySettings />
                </SettingsWrapper>
              </RequireAuth>
            )}
          />
          <Route
            path="/settings/property/address"
            render={() => (
              <RequireAuth redirector={routing.requireAdmin}>
                <SettingsWrapper>
                  <AddressSettings />
                </SettingsWrapper>
              </RequireAuth>
            )}
          />
          <Route
            path="/settings/property/feeds"
            render={() => (
              <RequireAuth redirector={routing.requireAdmin}>
                <SettingsWrapper>
                  <FeedSettings />
                </SettingsWrapper>
              </RequireAuth>
            )}
          />
          <Route
            path="/settings/property/website"
            render={() => (
              <RequireAuth redirector={routing.requireAdmin}>
                <SettingsWrapper>
                  <WebsiteSettings />
                </SettingsWrapper>
              </RequireAuth>
            )}
          />
          <Route path="/settings/profile" exact={true}>
            <SettingsWrapper>
              <ProfileSettings />
            </SettingsWrapper>
          </Route>
          <Route path="/settings/profile/personal-details">
            <SettingsWrapper>
              <PersonalDetailsSettings />
            </SettingsWrapper>
          </Route>
          <Route path="/settings/profile/password">
            <SettingsWrapper>
              <PasswordSettings />
            </SettingsWrapper>
          </Route>
          <Route
            path="/settings/team"
            render={() => (
              <RequireAuth redirector={routing.requireAdmin}>
                <SettingsWrapper>
                  <TeamSettings
                    entityNameSingular="campaign"
                    entityNamePlural="campaigns"
                    entitySortMetric="Subscribers"
                  />
                </SettingsWrapper>
              </RequireAuth>
            )}
          />
          <Route path="/settings/campaigns" exact={true}>
            <CampaignsRouting hasCampaigns={hasCampaigns} />
          </Route>
          <Route path="/settings/campaigns/allcampaigns">
            <AllCampaignsRouting />
          </Route>
          <Route path="/settings/campaigns/:urn" exact={true}>
            <SettingsWrapper>
              <CampaignSettings />
            </SettingsWrapper>
          </Route>
          <Route path="/settings/campaigns/:urn/apps-and-integrations">
            <AppsIntegrationsRouting />
          </Route>
          <Route path="/settings/campaigns/:urn/general">
            <SettingsWrapper>
              <CampaignDetailsSettings />
            </SettingsWrapper>
          </Route>
          <Route path="/settings/campaigns/:urn/content">
            <SettingsWrapper hideSidebar={true}>
              <ContentSettings />
            </SettingsWrapper>
          </Route>
          <Route path="/settings/campaigns/:urn/automation">
            <SettingsWrapper>
              <AutomationSettings />
            </SettingsWrapper>
          </Route>
          <Route path="/settings/campaigns/:urn/branding">
            <BrandingRouting />
          </Route>
          <Route path="/settings/campaigns/:urn/sign-up-forms">
            <SignupFormsRouting />
          </Route>
        </Switch>
      </Flex>
    </>
  );
}
export default Settings;

interface CampaignsRoutingProps {
  hasCampaigns: boolean;
}

function CampaignsRouting({ hasCampaigns }: CampaignsRoutingProps) {
  return hasCampaigns ? (
    <SettingsWrapper>
      <CampaignList />
    </SettingsWrapper>
  ) : (
    <Redirect to="/settings/property" />
  );
}
