import { SetStateAction, useEffect, useState } from 'react';

import * as authentication from 'common/authentication';
import * as string from 'common/string';
import * as utility from 'common/utility';
import * as validation from 'common/validation';

const useDeveloperMode = (
  ticketNumber: string
): [boolean, React.Dispatch<SetStateAction<boolean>>] => {
  if (utility.isNullOrUndefined('ticketNumber')) {
    validation.mandatory('ticketNumber');
  }
  if (!string.isValidTicketNumber(ticketNumber)) {
    throw new Error(
      'Invalid ticket number provided in call to useDeveloperMode'
    );
  }

  const [isDeveloperModeActive, setDeveloperModeActive] = useState(
    sessionStorage.getItem('isDeveloperModeActive') !== null
  );

  useEffect(() => {
    const checkDeveloperModeActive = async () => {
      const isStaffUser = await authentication.isStaffUser();
      if (isDeveloperModeActive && isStaffUser) {
        sessionStorage.setItem('isDeveloperModeActive', 'true');
      } else {
        sessionStorage.removeItem('isDeveloperModeActive');
      }
    };
    checkDeveloperModeActive();
  }, [isDeveloperModeActive]);

  return [isDeveloperModeActive, setDeveloperModeActive];
};

export default useDeveloperMode;
