import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  Button,
  CheckIcon,
  Flex,
  Spinner,
  Text,
  useToast,
  WarningIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const BUTTON_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  COMPLETE: 'COMPLETE',
};

function ResendInvite({ user, handleResendInvite }) {
  const [buttonState, setButtonState] = useState(BUTTON_STATE.INITIAL);
  const toast = useToast();

  const handleClick = async () => {
    setButtonState(BUTTON_STATE.LOADING);
    try {
      await handleResendInvite(user);
      setButtonState(BUTTON_STATE.COMPLETE);
      toast({ variant: 'success', title: 'Invitation sent' });
    } catch (error) {
      setButtonState(BUTTON_STATE.INITIAL);
      toast({ variant: 'error', title: 'Failed to send invitation' });
    }
  };

  return (
    <>
      {buttonState === BUTTON_STATE.INITIAL && (
        <Button variant="secondary" height="9" onClick={handleClick}>
          <Flex align="center" gap="2">
            <WarningIcon />
            Resend Invite
          </Flex>
        </Button>
      )}
      {buttonState === BUTTON_STATE.LOADING && <Spinner mx={58.25} />}
      {buttonState === BUTTON_STATE.COMPLETE && (
        <Flex align="center" gap="1">
          <Text color="green.600" fontWeight="medium" ml={19.5} size="sm">
            Invitation sent
          </Text>
          <CheckIcon color="green.600" size={5} />
        </Flex>
      )}
    </>
  );
}

ResendInvite.propTypes = {
  user: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleResendInvite: PropTypes.func.isRequired,
};

export default ResendInvite;
