/* eslint react/no-danger: "off" */

import { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

import {
  Button,
  DeleteTrashIcon,
  Divider,
  Flex,
  FormControl,
  InfoIcon,
  Input,
  Toggle,
  VisuallyHidden,
  WarningIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as sanitise from 'common/sanitise';
import * as string from 'common/string';

import { MAXIMUM_UPLOAD_SIZE, SUPPORTED_FORMATS } from 'common/config';
import { SERVER_UPLOAD_TYPE, SUBSCRIBER_MODAL_STATES } from 'common/enums';

import './Select.css';

interface SelectProps {
  canUpload: boolean;
  errorMessage?: string;
  handleClose: () => void;
  handleContinue: (uploadType: SERVER_UPLOAD_TYPE) => void;
  handleRemove: () => void;
  handleSelect: (acceptedFiles: File[]) => void;
  hasActiveSubscribers: boolean;
  modalState: SUBSCRIBER_MODAL_STATES;
  selectedFile: File;
}

function Select({
  canUpload,
  errorMessage,
  handleClose,
  handleContinue,
  handleRemove,
  handleSelect,
  hasActiveSubscribers,
  modalState,
  selectedFile,
}: SelectProps) {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: SUPPORTED_FORMATS,
    maxFiles: 1,
    maxSize: MAXIMUM_UPLOAD_SIZE,
    multiple: false,
    noClick: true,
    noDrag: false,
    onDrop: handleSelect,
    preventDropOnDocument: true,
  });

  const [isReplace, setIsReplace] = useState(false);
  const [replaceConfirmation, setReplaceConfirmation] = useState('');
  const confirmationMessage = 'REPLACE ALL';

  const handleReplaceSwitch = () => {
    setIsReplace(val => !val);
  };

  const handlerConfirmationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReplaceConfirmation(event.target.value);
  };

  const continueButtonDisabled =
    !canUpload || (isReplace && replaceConfirmation !== confirmationMessage);

  const handleContinueUploadType = () => {
    const subscribeType = isReplace
      ? SERVER_UPLOAD_TYPE.REPLACE
      : SERVER_UPLOAD_TYPE.APPEND;
    const uploadType =
      modalState === SUBSCRIBER_MODAL_STATES.SUBSCRIBE
        ? subscribeType
        : SERVER_UPLOAD_TYPE.UNSUBSCRIBE;
    handleContinue(uploadType);
  };

  const dropzoneProps = getRootProps();

  useEffect(() => {
    const dropzone = dropzoneProps.ref.current;

    const handleDrop = () => {};

    dropzone?.addEventListener('drop', handleDrop);

    return () => {
      dropzone?.removeEventListener('drop', handleDrop);
    };
  }, [dropzoneProps.ref]);

  return (
    <>
      <Modal.Body className="ebx-upload-body px-3 ebx-body-1 d-flex align-items-center flex-wrap ebx-modal-body">
        <div
          className={`select-drag-zone w-100 mb-2${
            isDragActive ? ' dropzone_active' : ''
          }`}
          {...dropzoneProps}
        >
          <div className="dnd-label ebx-h3">Drag and drop file</div>
          <input {...getInputProps()} />
          <Button variant="secondary" onClick={open}>
            Select .CSV File
          </Button>
        </div>
        <div className="ebx-body-1 ebx-faded-colour">
          CSV - {string.formatFileSize(MAXIMUM_UPLOAD_SIZE)} max
        </div>
        {selectedFile && (
          <div className="ebx-upload-file p-3 mt-3 w-100">
            <Flex justify="space-between" align="center">
              <div className="ebx-h3">{selectedFile.name}</div>
              <Button
                variant="tertiary"
                p={0}
                onClick={handleRemove}
                _hover={{ background: 'none' }}
              >
                <DeleteTrashIcon />
                <VisuallyHidden>Remove</VisuallyHidden>
              </Button>
            </Flex>
            {errorMessage && (
              <div className="mt-3">
                <div className="ebx-error-colour ebx-h3">
                  <InfoIcon mr="1" />
                  <span
                    className="mr-1 stay-within-parent"
                    dangerouslySetInnerHTML={{
                      __html: sanitise.safeHTML(errorMessage),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      {/* Can only replace subscribers if they have subscribers to begin with */}
      {hasActiveSubscribers &&
        modalState === SUBSCRIBER_MODAL_STATES.SUBSCRIBE && (
          <>
            <Divider />
            <Modal.Body className="ebx-body-1 ebx-modal-body">
              <FormControl
                display="flex"
                alignItems="center"
                mb={isReplace ? 3 : 0}
              >
                <Toggle
                  id="replaceSwitch"
                  mr={3}
                  checked={isReplace}
                  onChange={handleReplaceSwitch}
                />
                <FormControl.FormLabel htmlFor="replaceSwitch" m={0}>
                  Replace all subscribers
                </FormControl.FormLabel>
              </FormControl>
              {isReplace && (
                <FormControl>
                  <FormControl.FormLabel htmlFor="replaceConfirmationText">
                    Type {confirmationMessage} to confirm
                  </FormControl.FormLabel>
                  <Input
                    id="replaceConfirmationText"
                    type="text"
                    maxLength={120}
                    value={replaceConfirmation}
                    onChange={handlerConfirmationChange}
                  />
                  <FormControl.FormHelperText
                    display="flex"
                    alignItems="center"
                  >
                    <WarningIcon mr={1} />
                    Warning - you will not be able to recover your previous
                    subscriber list
                  </FormControl.FormHelperText>
                </FormControl>
              )}
            </Modal.Body>
          </>
        )}

      <Modal.Footer className="ebx-modal-footer">
        <div className="mr-auto ml-0">
          <Link
            to="/files/ebx-subscriber-upload-template.csv"
            target="_blank"
            className="ebx-cursor ebx-link ebx-h3"
            download
          >
            <span>Download .CSV template</span>
          </Link>
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          isDisabled={continueButtonDisabled}
          onClick={handleContinueUploadType}
        >
          Continue
        </Button>
      </Modal.Footer>
    </>
  );
}

export default Select;
