/* eslint @typescript-eslint/no-use-before-define: "off" */

import { cloneArray } from 'common/utility';

export { chunkArray, mergeDedupeArrays, compareArrays };

/**
 * chunkArray
 *
 * @param {array} anArray - Array to split
 * @param {integer} chunkSize - Maximum size of each group
 */
function chunkArray<Item>(anArray: Item[], chunkSize: number): Item[][] {
  const chunkedArray = [];

  while (anArray.length) {
    chunkedArray.push(anArray.splice(0, chunkSize));
  }

  return chunkedArray;
}

/**
 * mergeDedupeArrays
 *
 * @param {array} array1 - First array of objects
 * @param {array} array2 - Second array of objects
 * @param {string} key - Key to use to dedupe entries
 */
function mergeDedupeArrays<Item, Key extends keyof Item>(
  array1: Item[],
  array2: Item[],
  key: Key
): Item[] {
  const result = cloneArray(array1);
  array2.forEach(entry => {
    if (result.findIndex((item: Item) => item[key] === entry[key]) === -1) {
      result.push(entry);
    }
  });
  return result;
}

/**
 * compareArrays (For simplicity, comparison was done by !== only. Object comparison is not supported)
 *
 * @param {array} array1 - First array of elements
 * @param {array} array2 - Second array of elements
 * @param {boolean} ordered - elements have to be in order
 */
function compareArrays<Item>(
  array1: Item[],
  array2: Item[],
  ordered: boolean
): boolean {
  if (array1.length !== array2.length) {
    return false;
  }
  const array1copy = cloneArray(array1);
  const array2copy = cloneArray(array2);
  if (!ordered) {
    array1copy.sort();
    array2copy.sort();
  }
  for (let i = 0; i < array1copy.length; i += 1) {
    if (array1copy[i] !== array2copy[i]) {
      return false;
    }
  }
  return true;
}
