import {
  forwardRef,
  IconButton,
  IconButtonProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const HeaderIconButton = forwardRef<IconButtonProps, 'button'>((props, ref) => {
  const hoverAndActiveStyles = {
    bg: 'rgba(14, 30, 57, 0.08)',
    p: '1',
    color: 'gray.600',
    outline: 'none',
  };
  return (
    <IconButton
      ref={ref}
      border="none"
      size="sm"
      color="gray.500"
      _hover={hoverAndActiveStyles}
      _active={hoverAndActiveStyles}
      _focus={{ boxShadow: 'none', outline: 'none' }}
      {...props}
    />
  );
});

export default HeaderIconButton;
