/* eslint @typescript-eslint/no-use-before-define: "off" */

import type { FixTypeLater, Permission } from 'common/types';
import { isDefined, isNullOrUndefined, isUndefined } from 'common/utility';

import {
  CAMPAIGN_PERMISSION_TYPES,
  PROPERTY_PERMISSION_TYPES,
} from 'common/enums';

export {
  createCampaignPermissions,
  getCampaignPermission,
  getPropertyCampaignPermissions,
  getPropertyPermission,
  getUserEntityAccess,
  isAdministrator,
};

const createCampaignPermissions = (
  propertyURN: string,
  campaignList: string[]
): Permission[] => {
  const permissionsOnProperty = [];
  permissionsOnProperty.push({
    propertyURN,
    permissionType: PROPERTY_PERMISSION_TYPES.VIEW_ONLY,
  });
  campaignList?.forEach(campaignURN => {
    permissionsOnProperty.push({
      propertyURN,
      campaignURN,
      permissionType: CAMPAIGN_PERMISSION_TYPES.CAMPAIGN_EDITOR,
    });
  });
  return permissionsOnProperty;
};

const getCampaignPermission = (
  campaignURN: string,
  userPermissions: Permission[]
) => {
  try {
    let permissionType = null;
    userPermissions.forEach(permission => {
      if (
        isDefined(permission.propertyURN) &&
        isDefined(permission.campaignURN) &&
        permission.campaignURN === campaignURN
      ) {
        permissionType = permission.permissionType;
      }
    });
    return permissionType;
  } catch (e) {
    return null;
  }
};

const getPropertyCampaignPermissions = (
  propertyURN: string,
  userPermissions: Permission[]
) => {
  try {
    const propertyCampaignPermissions: Permission[] = [];
    userPermissions.forEach(permission => {
      if (
        isDefined(permission.propertyURN) &&
        !isNullOrUndefined(permission.campaignURN) &&
        permission.propertyURN === propertyURN
      ) {
        propertyCampaignPermissions.push(permission);
      }
    });
    return propertyCampaignPermissions;
  } catch (e) {
    return [];
  }
};

const getPropertyPermission = (
  propertyURN: string,
  userPermissions: Permission[]
) => {
  try {
    let permissionType: PROPERTY_PERMISSION_TYPES | CAMPAIGN_PERMISSION_TYPES =
      PROPERTY_PERMISSION_TYPES.NONE;
    userPermissions.forEach((permission: Permission) => {
      if (
        isDefined(permission.propertyURN) &&
        isNullOrUndefined(permission.campaignURN) &&
        permission.propertyURN === propertyURN
      ) {
        permissionType = permission.permissionType;
      }
    });
    return permissionType;
  } catch (e) {
    return null;
  }
};

const getUserEntityAccess = (user: { permissions: Permission[] }) => {
  // List of all propertyData for which the user has property or API-level permissions
  const allPropertyURNs: string[] = [];
  // List of propertyData for which the user has property-level permissions
  const propertyURNs: string[] = [];
  // List of campaigns for which the user has campaign-level permissions
  const campaignURNs: string[] = [];
  // Map linking account APIs to propertyData
  const apiPropertyMap = new Map();

  if (isDefined(user) && isDefined(user.permissions)) {
    // Property-level permissions
    user.permissions
      .filter(permission => isUndefined(permission.campaignURN))
      .forEach(permission => {
        if (allPropertyURNs.indexOf(permission.propertyURN) === -1) {
          allPropertyURNs.push(permission.propertyURN);
        }
        if (permission.permissionType === PROPERTY_PERMISSION_TYPES.ADMIN) {
          propertyURNs.push(permission.propertyURN);
        }
      });
    // Campaign-level permissions
    user.permissions.forEach((permission: Permission) => {
      if (allPropertyURNs.indexOf(permission.propertyURN) === -1) {
        allPropertyURNs.push(permission.propertyURN);
      }
      if (permission.campaignURN) {
        campaignURNs.push(permission.campaignURN);
      }
      apiPropertyMap.set(permission.campaignURN, permission.propertyURN);
    });
  }

  return { allPropertyURNs, propertyURNs, campaignURNs, apiPropertyMap };
};

const isAdministrator = (globalInfo: FixTypeLater) => {
  if (isDefined(globalInfo) && isDefined(globalInfo.user)) {
    const currentPropertyURN = globalInfo.current.propertyURN;

    // Get user permission for the current property
    const userPermission: Permission = globalInfo.user.permissions.find(
      (permission: Permission) => permission.propertyURN === currentPropertyURN
    );

    // If user has permissions for this property, check if it's admin
    if (userPermission) {
      return userPermission.permissionType === PROPERTY_PERMISSION_TYPES.ADMIN;
    }
  }
  return false;
};
