import { Route, Switch } from 'react-router-dom';

import AppsIntegrations from 'components/settings/campaigns/apps/AppsIntegrations';
import Connections from 'components/settings/campaigns/apps/Connections';
import SettingsWrapper from 'components/settings/SettingsWrapper';

function Routing() {
  return (
    <Switch>
      <Route path="/settings/campaigns/:urn/apps-and-integrations" exact={true}>
        <SettingsWrapper>
          <AppsIntegrations />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/:urn/apps-and-integrations/:app">
        <SettingsWrapper>
          <Connections />
        </SettingsWrapper>
      </Route>
    </Switch>
  );
}

export default Routing;
