/* eslint @typescript-eslint/no-use-before-define: "off" */

import * as API from 'api/api';

import {
  EDITION_DISPLAY_STATES,
  EDITION_DISPLAY_STATE_CONFIGURATION,
  EDITION_SOURCES,
  OPTIMAL_SEND_TIME,
  SENDING_BUFFER_TIME,
} from 'common/constants';

import {
  EDITION_APPROVAL_STATES,
  EDITION_STATE,
  EDITION_SUBJECT_TYPES,
  SCHEDULE_EDITION_OPTIONS,
} from 'common/enums';

import * as i18n from 'common/i18n';
import type { Campaign, Edition as EditionType } from 'common/types';

import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as sections from 'common/sections';

export {
  areAllArticlesOptimal,
  determineOptimisationUsage,
  getEditionDisplayState,
  getEditionSubject,
  getErrorMessage,
  getFirstEdition,
  updateEdition,
  checkIsEditionVisible,
  checkIsEditionClickable,
  checkIsEditionEditable,
  checkIsErrorVisible,
  checkCanDelete,
  isEdition,
  isMarketingEmail,
  setWrapperClass,
  setLabelClass,
  setDisplayLabel,
  prepareForSave,
  sortEditions,
  getScheduleEditionOption,
  getOptimalTimeslot,
};

const areAllArticlesOptimal = (editionDetails: EditionType) => {
  if (editionDetails?.sections) {
    return editionDetails.sections.every(section =>
      sections.areArticlesOptimal(
        {
          articles: section.articles,
          optimalArticleOrdering: section.optimalArticleOrdering,
        },
        false
      )
    );
  }
  return false;
};

const determineOptimisationUsage = (
  contentPersonalisation: boolean,
  editionDetails: EditionType
) => {
  // Timing personalisation - on if the timing option for the Edition is "optimal" or "time slot"
  const timingOption = getScheduleEditionOption(
    editionDetails.scheduledUnixTime,
    editionDetails.scheduledUnixTime +
      Number(editionDetails?.sendingTimeslotSizeSeconds ?? 0),
    true
  );
  const timingPersonalisation =
    timingOption === SCHEDULE_EDITION_OPTIONS.OPTIMAL ||
    timingOption === SCHEDULE_EDITION_OPTIONS.TIME_SLOT;

  // Content optimisation - on if all articles in the Edition match those originally chosen by Echobox
  let contentOptimisation = true;
  if (editionDetails?.sections) {
    editionDetails.sections.forEach(section => {
      if (contentOptimisation) {
        const areArticlesOptimal = sections.areArticlesOptimal(section, true);
        if (!areArticlesOptimal) {
          contentOptimisation = false;
        }
      }
    });
  }

  // Order optimisation - on if all articles in the Edition match those originally chosen by Echobox and are in the same sequence
  let orderOptimisation = true;
  if (editionDetails?.sections) {
    editionDetails.sections.forEach(section => {
      if (orderOptimisation) {
        const areArticlesOptimal = sections.areArticlesOptimal(section, false);
        if (!areArticlesOptimal) {
          orderOptimisation = false;
        }
      }
    });
  }

  // Optimisation percentage
  const optimisationPercentage =
    0 +
    (timingPersonalisation ? 25 : 0) +
    (contentOptimisation ? 25 : 0) +
    (orderOptimisation ? 25 : 0) +
    (contentPersonalisation ? 25 : 0);

  // Return results
  return {
    timingPersonalisation,
    contentOptimisation,
    orderOptimisation,
    contentPersonalisation,
    optimisationPercentage,
  };
};

const getEditionDisplayState = (
  approvalState: string | null | undefined,
  editionSource: string | null | undefined,
  lastUpdatedUnixTime: number | undefined,
  editionState: string | undefined,
  scheduledUnixTime: number | undefined,
  currentTime: number
) => {
  if (editionState === EDITION_STATE.POPULATING) {
    return EDITION_DISPLAY_STATES.POPULATING;
  }

  // New State
  if (
    approvalState === EDITION_APPROVAL_STATES.NEW &&
    scheduledUnixTime &&
    scheduledUnixTime > currentTime
  ) {
    return EDITION_DISPLAY_STATES.NEW;
  }

  // Edition Sent
  if (editionState === EDITION_STATE.SENT) {
    return EDITION_DISPLAY_STATES.SENT;
  }

  // Edition Sending
  // Added a 15 minute buffer to keep the state as sending at send time
  // due to new email requests only being picking up every 10 minutes
  if (
    editionState === EDITION_STATE.SENDING ||
    (editionState === EDITION_STATE.UNSENT &&
      approvalState === EDITION_APPROVAL_STATES.CLIENT_APPROVED &&
      scheduledUnixTime &&
      scheduledUnixTime + SENDING_BUFFER_TIME.MINUTES >= currentTime &&
      scheduledUnixTime < currentTime)
  ) {
    return EDITION_DISPLAY_STATES.SENDING;
  }

  // Edition Missed - Edition was not approved
  // This state is deprecated, so will only appear for Editions scheduled before 22nd April '22
  if (
    ((editionState === EDITION_STATE.UNSENT &&
      approvalState !== EDITION_APPROVAL_STATES.CLIENT_APPROVED) ||
      approvalState === EDITION_APPROVAL_STATES.NEW) &&
    scheduledUnixTime &&
    scheduledUnixTime < currentTime &&
    scheduledUnixTime < 1650581999
  ) {
    return EDITION_DISPLAY_STATES.MISSED;
  }

  // Edition Failed / Error
  if (editionState === EDITION_STATE.FAILED) {
    return EDITION_DISPLAY_STATES.FAILED;
  }

  // Edition Awaiting Generation
  if (approvalState === EDITION_APPROVAL_STATES.ECHOBOX_APPROVED) {
    return EDITION_DISPLAY_STATES.ECHOBOX_APPROVED;
  }

  // Edition Approved (Manual Source)
  if (
    approvalState === EDITION_APPROVAL_STATES.CLIENT_APPROVED &&
    editionSource !== EDITION_SOURCES.AUTO
  ) {
    return EDITION_DISPLAY_STATES.SCHEDULED;
  }

  // Edition Approved (Auto Source)
  if (
    approvalState === EDITION_APPROVAL_STATES.CLIENT_APPROVED &&
    editionSource === EDITION_SOURCES.AUTO
  ) {
    return EDITION_DISPLAY_STATES.AUTOMATED;
  }

  // Default
  return EDITION_DISPLAY_STATES.FAILED;
};

const getEditionSubject = (
  campaignDetails: Campaign,
  date: Date,
  subjectType: EDITION_SUBJECT_TYPES,
  firstArticleTitle: string
) => {
  const day = date.toLocaleDateString(i18n.getBrowserLocale()!, {
    day: 'numeric',
  });
  const month = date.toLocaleDateString(i18n.getBrowserLocale()!, {
    month: 'numeric',
  });
  const longMonth = date.toLocaleDateString(i18n.getBrowserLocale()!, {
    month: 'long',
  });
  let subject;
  switch (subjectType) {
    case EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE:
      subject = firstArticleTitle ?? `${campaignDetails.campaignName}`;
      break;
    case EDITION_SUBJECT_TYPES.NAME_DDMM:
      subject = `${campaignDetails.campaignName} ${day}/${month}`;
      break;
    case EDITION_SUBJECT_TYPES.NAME_MMDD:
      subject = `${campaignDetails.campaignName} ${month}/${day}`;
      break;
    case EDITION_SUBJECT_TYPES.NAME_DDMONTH:
      subject = `${campaignDetails.campaignName} ${day} ${longMonth}`;
      break;
    default:
      // Don't know what this setting should be, assume a default name can't go wrong
      subject = `${campaignDetails.campaignName}`;
  }
  return subject;
};

const getErrorMessage = (editionDisplayState: string) => {
  if (editionDisplayState === EDITION_DISPLAY_STATES.FAILED) {
    return 'An error occurred, Edition not sent';
  }
  return '';
};

/**
 *
 */
const getFirstEdition = (editionList: EditionType[]) => {
  for (let idx = 0; idx < editionList.length; idx += 1) {
    if (editionList[idx].editionURN) {
      return editionList[idx];
    }
  }
  return null;
};

const isEdition = (edition: EditionType) => !isMarketingEmail(edition);

const isMarketingEmail = (edition: EditionType) => !!edition.templateURN;

const updateEdition = async (edition: EditionType, fieldsToUpdate: any) => {
  const { editionURN } = edition;
  const updatedValues = {
    editionURN,
    editionDetails: editions.prepareForSave({
      ...edition,
      approvalState: fieldsToUpdate.approvalState || edition.approvalState,
      editionState: fieldsToUpdate.editionState || edition.editionState,
      scheduledUnixTime:
        fieldsToUpdate.scheduledUnixTime || edition.scheduledUnixTime,
      editionSubject: fieldsToUpdate.editionSubject || edition.editionSubject,
      emailPreviewText:
        fieldsToUpdate.emailPreviewText ?? edition.emailPreviewText,
      sections: fieldsToUpdate.sections || edition.sections,
      textBlocks: fieldsToUpdate.textBlocks || edition.textBlocks,
      promotionBlocks:
        fieldsToUpdate.promotionBlocks || edition.promotionBlocks,
      sendingTimeslotSizeSeconds:
        fieldsToUpdate.sendingTimeslotSizeSeconds ??
        edition.sendingTimeslotSizeSeconds,
    }),
  };

  await API.putEditions(updatedValues);
};

function checkIsEditionVisible(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState]
    .isEditionVisible;
}

function checkIsEditionClickable(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState]
    .isEditionClickable;
}

function checkIsEditionEditable(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState]
    .isEditionEditable;
}

function checkIsErrorVisible(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState]
    .isErrorVisible;
}

function setWrapperClass(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState]
    .wrapperClassName;
}

function setLabelClass(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState]
    .labelClassName;
}

function setDisplayLabel(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState].displayLabel;
}

function checkCanDelete(editionDisplayState: string) {
  return EDITION_DISPLAY_STATE_CONFIGURATION[editionDisplayState].canDelete;
}

/* Take an edition object and strip out all fields that don't need to be provided for a POST/PUT request */
const prepareForSave = (edition: EditionType) => {
  /* Ensure that the sections and textBlocks elements are populated as expected by the endpoint */
  const editionToSave = {
    approvalState: edition.approvalState,
    // bodyElementPositioning: edition.bodyElementPositioning,
    editionSubject: edition.editionSubject,
    emailPreviewText: edition.emailPreviewText,
    scheduledUnixTime: edition.scheduledUnixTime,
    sendingTimeslotSizeSeconds: edition.sendingTimeslotSizeSeconds,
    sections:
      edition?.sections?.map(section => ({
        sectionURN: section.sectionURN,
        articles: section.articles,
        callToActionText: section.callToActionText,
      })) ?? [],
    textBlocks: edition.textBlocks ?? [],
    promotionBlocks:
      edition?.promotionBlocks?.map(block => ({
        promotionBlockURN: block.promotionBlockURN,
        imageURL: block.imageURL === '' ? null : block.imageURL,
        destinationURL:
          block.destinationURL === '' ? null : block.destinationURL,
      })) ?? [],
  } as EditionType;
  if (isMarketingEmail(edition)) {
    delete editionToSave.promotionBlocks;
    delete editionToSave.sections;
    delete editionToSave.textBlocks;
    editionToSave.templateURN = edition.templateURN;
  }

  return editionToSave;
};

const sortEditions = (editionsToSort: any[]) =>
  editionsToSort.sort((a, b) => b.scheduledUnixTime - a.scheduledUnixTime);

function getScheduleEditionOption(
  startTime: number,
  endTime: number | null | undefined,
  isEditionUnsent: boolean
) {
  const unixTimeNow = datetime.getUnixTimestamp();
  // The sending window is zero
  if (!endTime || (endTime && startTime && startTime === endTime)) {
    const DISPLAY_NOW_BUFFER_SECS = 30;
    // Only label Editions as Now if their send time is in the past
    // The buffer is used to ensure Now is displayed when the current time is set as the send time (NL-117)
    // But is after 21st April, this is for back-compatibility with the deprecated Missed state
    if (
      isEditionUnsent &&
      startTime < unixTimeNow + DISPLAY_NOW_BUFFER_SECS &&
      startTime > 1650581999
    ) {
      return SCHEDULE_EDITION_OPTIONS.NOW;
    }
    // Otherwise display the specific time
    return SCHEDULE_EDITION_OPTIONS.SPECIFIC_TIME;
  }
  // Does the time range match the optimal slot for this day?
  const startTimeDate = new Date(startTime * 1000);
  const optimalTimeRange = getOptimalTimeslot(startTimeDate);
  if (
    (startTime === optimalTimeRange.optimalStartTime &&
      endTime === optimalTimeRange.optimalEndTime) ||
    (isEditionUnsent &&
      startTime < unixTimeNow &&
      endTime &&
      endTime < unixTimeNow)
  ) {
    return SCHEDULE_EDITION_OPTIONS.OPTIMAL;
  }
  return SCHEDULE_EDITION_OPTIONS.TIME_SLOT;
}

/**
 * getOptimalTimeslot - Returns Unix timestamps of optimal edition schedule send times
 *
 * @param {object} - date object
 * @return {object} - optimalRange - timestamps to use for date range
 */

function getOptimalTimeslot(dateObj: Date) {
  // Get start of chosen day (hours, minutes, seconds, miliseconds)
  dateObj.setHours(0, 0, 0, 0);

  const tempStartTime = new Date(dateObj);

  // Divide by 1000 as we want unix timestamp in seconds not miliseconds
  const optimalStartTime =
    tempStartTime.setHours(OPTIMAL_SEND_TIME.START_TIME) / 1000;
  const optimalEndTime = optimalStartTime + OPTIMAL_SEND_TIME.SLOT_SIZE_SECONDS;

  const optimalRange = { optimalStartTime, optimalEndTime };

  return optimalRange;
}
