import { Flex, Td, Tr } from '@ebx-ui/ebx-ui-component-library-sdk';

import ResendInvite from 'components/settings/team/ResendInvite';
import UserAccess from 'components/settings/team/UserAccess';
import UserRemove from 'components/settings/team/UserRemove';
import UserRole from 'components/settings/team/UserRole';

import type { FixTypeLater, PropertyUser } from 'common/types';
import * as users from 'common/users';

import { PROPERTY_PERMISSION_TYPES, USER_STATES } from 'common/enums';

import './UserDetail.css';

interface UserDetailProps {
  entityNameSingular: string;
  entityNamePlural: string;
  entitySortMetric: string;
  handleEntitiesEdit: (user: PropertyUser) => () => void;
  handleRemoveUser: (email: string, name: string) => void;
  handleResendInvite: (user: PropertyUser) => void;
  handleRoleChange: ({
    name,
    emailAddress,
    newPermissionType,
  }: {
    name: string;
    emailAddress: string;
    newPermissionType: string;
  }) => void;
  isBusy: boolean;
  isCurrentUser: boolean;
  propertyURN: string;
  user: FixTypeLater;
}

function UserDetail({
  entityNameSingular,
  entityNamePlural,
  entitySortMetric,
  handleEntitiesEdit,
  handleRemoveUser,
  handleResendInvite,
  handleRoleChange,
  isBusy,
  isCurrentUser,
  propertyURN,
  user,
}: UserDetailProps) {
  const permissionType = users.getPropertyPermission(
    propertyURN,
    user.permissions
  );

  const onRemoveUser = () => {
    handleRemoveUser(user.emailAddress, user.name);
  };

  const onRoleChange = (eventKey: any) => {
    handleRoleChange({
      name: user.name,
      emailAddress: user.emailAddress,
      newPermissionType: eventKey,
    });
  };

  return (
    <Tr boxShadow="borderTop" key={user.username}>
      <Td px={5} py={2} color="gray.900" fontSize="sm" border="none">
        {user.name}
      </Td>
      <Td px={5} py={2} color="gray.900" fontSize="sm" border="none">
        <Flex align="center" justifyContent="space-between">
          {user.emailAddress}
          {user.userState === USER_STATES.INVITED && (
            <ResendInvite user={user} handleResendInvite={handleResendInvite} />
          )}
        </Flex>
      </Td>
      <Td px={5} py={2} color="gray.900" fontSize="sm" border="none">
        <UserRole
          handleRoleChange={onRoleChange}
          isBusy={isBusy}
          isCurrentUser={isCurrentUser}
          permissionType={permissionType as PROPERTY_PERMISSION_TYPES}
        />
      </Td>
      <Td px={5} py={2} color="gray.900" fontSize="sm" border="none">
        <UserAccess
          entityNameSingular={entityNameSingular}
          entityNamePlural={entityNamePlural}
          handleEntitiesEdit={handleEntitiesEdit}
          isBusy={isBusy}
          isCurrentUser={isCurrentUser}
          propertyURN={propertyURN}
          user={user}
        />
      </Td>
      <Td px={5} py={2} color="gray.900" fontSize="sm" border="none">
        {!isCurrentUser && (
          <UserRemove
            emailAddress={user.emailAddress}
            fullName={user.name}
            onRemoveUser={onRemoveUser}
            propertyURN={propertyURN}
          />
        )}
      </Td>
    </Tr>
  );
}

export default UserDetail;
