import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { Subscriber } from 'common/types';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getSubscriberListArgs {
  campaignURN: string;
  page: number;
  pageSize: number;
  sortField: string;
  sortOrder: string;
  searchString: string;
}

export default async function getSubscriberList({
  campaignURN,
  page,
  pageSize,
  sortField,
  sortOrder,
  searchString,
}: getSubscriberListArgs): Promise<Subscriber[]> {
  const guid = metrics.start('getSubscriberList');

  const searchStringQueryParam = searchString ? `&search=${searchString}` : '';

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/subscribers?page=${page}&pageSize=${pageSize}&sort=${sortField}&sortOrder=${sortOrder}${searchStringQueryParam}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getSubscriberList /campaigns/${campaignURN}/subscribers`);

  try {
    const response = await axios(config);
    metrics.end('getSubscriberList', guid);
    return response.data.subscribers;
  } catch (error) {
    metrics.fail('getSubscriberList', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getSubscriberList',
            args: { campaignURN },
          })
        : error;
    throw apiError;
  }
}
