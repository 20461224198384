import PropTypes from 'prop-types';

import { Modal as BootstrapModal } from 'react-bootstrap';

import { MODAL_MODES } from 'common/enums';

/**
 * React component for rendering a Modal
 * Acts as a wrapper around React Bootstrap Modal to set up some default styling
 * Accepts the same props as React Bootstrap Modal component
 * @param {import('react-bootstrap').ModalProps} props
 */
function Modal({ backdrop, centered, ...rest }) {
  return <BootstrapModal backdrop={backdrop} centered={centered} {...rest} />;
}

/**
 * React component for rendering a Modal Header
 * Acts as a wrapper around React Bootstrap Modal.Header to set up some default styling
 * Accepts the same props as React Bootstrap Modal.Header component
 */
function ModalHeader({ className, ...rest }) {
  return (
    <BootstrapModal.Header
      className={`ebx-modal-header ${className}`}
      {...rest}
    />
  );
}

/**
 * React component for rendering a Modal Title
 * Acts as a wrapper around React Bootstrap Modal.Title to set up some default styling
 * Accepts the same props as React Bootstrap Modal.Title component
 */
function ModalTitle({ className, ...rest }) {
  return <BootstrapModal.Title className={`ebx-h1 ${className}`} {...rest} />;
}

/**
 * React component for rendering a Modal Body
 * Acts as a wrapper around React Bootstrap Modal.Body to set up some default styling
 * Accepts the same props as React Bootstrap Modal.Body component
 */
function ModalBody({ className, mode, ...rest }) {
  return (
    <BootstrapModal.Body
      className={`${
        mode === MODAL_MODES.DEFAULT ? 'ebx-default-modal-body' : ''
      } ebx-body-1 ebx-modal-body ${className}`}
      {...rest}
    />
  );
}

/**
 * React component for rendering a Modal Footer
 * Acts as a wrapper around React Bootstrap Modal.Footer to set up some default styling
 * Accepts the same props as React Bootstrap Modal.Footer component
 */
function ModalFooter({ className, ...rest }) {
  return (
    <BootstrapModal.Footer
      className={`ebx-modal-footer ${className}`}
      {...rest}
    />
  );
}

Modal.defaultProps = {
  backdrop: 'static',
  centered: true,
};

ModalHeader.defaultProps = {
  className: '',
};

ModalTitle.defaultProps = {
  className: '',
};

ModalBody.defaultProps = {
  className: '',
  mode: MODAL_MODES.DEFAULT,
};

ModalFooter.defaultProps = {
  className: '',
};

Modal.propTypes = {
  backdrop: PropTypes.string,
  centered: PropTypes.bool,
};

ModalHeader.propTypes = {
  className: PropTypes.string,
};

ModalTitle.propTypes = {
  className: PropTypes.string,
};

ModalBody.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODAL_MODES)),
  className: PropTypes.string,
};

ModalFooter.propTypes = {
  className: PropTypes.string,
};

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
