import { Link } from 'react-router-dom';

import CampaignSequence from 'components/settings/campaigns/all/CampaignSequence';
import SettingsPanel from 'components/settings/campaigns/SettingsPanel';

import '../Campaign.css';

function Settings() {
  const getSettingsPath = subpage =>
    `/settings/campaigns/allcampaigns/${subpage}`;

  return (
    <>
      <div className="campaign-title ebx-title-colour">All Campaigns</div>
      <Link className="link-unstyled" to={getSettingsPath('subscription')}>
        <SettingsPanel
          title="Subscription page"
          description="Set up a single page from which a reader can subscribe to all your Campaigns"
        />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('branding')}>
        <SettingsPanel
          title="Branding"
          description="Configure the styling for your subscription page"
        />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('embedded-form')}>
        <SettingsPanel
          title="Embedded form"
          description="Use this snippet to sign up readers using your own form"
        />
      </Link>
      <Link
        className="link-unstyled"
        to={getSettingsPath('subscriber-preferences-center')}
      >
        <SettingsPanel
          title="Subscriber preferences center"
          description="Provide a page where subscribers can manage the Campaigns to which they are subscribed"
        />
      </Link>
      <CampaignSequence />
    </>
  );
}

export default Settings;
