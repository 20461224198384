import React from 'react';
import { NavLink } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

interface SidebarLinkProps {
  children: React.ReactNode;
  to: string;
}

function SidebarLink({ children, to }: SidebarLinkProps) {
  const location = useLocation();

  return (
    <Link to={to} className="sidebar-link">
      <NavLink
        as="span"
        className="w-100 d-flex align-items-center py-0 ebx-menu-option sidebar-option sidebar__link__pl mb-2"
        active={location.pathname.startsWith(to)}
      >
        <span className="ebx-h2 sidebar__campaign-name py-2 px-3">
          {children}
        </span>
      </NavLink>
    </Link>
  );
}

export default SidebarLink;
