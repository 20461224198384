import { Navbar } from 'react-bootstrap';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import GenericError from '../GenericError';

function SidebarError() {
  return (
    <Box boxShadow="borderRight" h="100%" pt={2}>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="d-flex flex-wrap m-0 pb-3 pr-4 pr-md-3 navbar-expand-md sidebar__padding"
      >
        <Box textAlign="left">
          <GenericError />
        </Box>
      </Navbar>
    </Box>
  );
}

export default SidebarError;
