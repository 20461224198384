import { useState } from 'react';

import {
  Flex,
  Radio,
  RadioGroup,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import ContentSectionWrapper from 'components/settings/campaigns/content/ContentSectionWrapper';

import { EDITION_SUBJECT_TYPES, EMAIL_PREVIEW_TEXT_TYPES } from 'common/enums';
import { EditionSubjectSettings } from 'common/types';

interface SubjectLineProps {
  editionSubjectSettings: EditionSubjectSettings | null;
  onChange: (newOption: EDITION_SUBJECT_TYPES) => void;
}

function SubjectLine({ editionSubjectSettings, onChange }: SubjectLineProps) {
  const subjectLineOption =
    editionSubjectSettings?.subjectType ??
    EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE;

  enum CAMPAIGN_NAME_AND_DATE {
    NAME_DDMM = 'NAME_DDMM',
    NAME_MMDD = 'NAME_MMDD',
    NAME_DDMONTH = 'NAME_DDMONTH',
  }

  const [currentCampaignNameAndDate, setCurrentCampaignNameAndDate] = useState(
    subjectLineOption in CAMPAIGN_NAME_AND_DATE
      ? subjectLineOption
      : CAMPAIGN_NAME_AND_DATE.NAME_DDMM
  );

  const isCampaignNameAndDate = subjectLineOption in CAMPAIGN_NAME_AND_DATE;

  const handleOptionChange = (newValue: EDITION_SUBJECT_TYPES) => {
    // If user selects a Campagin Name and Date option this stores the most recent
    if (newValue in CAMPAIGN_NAME_AND_DATE) {
      setCurrentCampaignNameAndDate(newValue);
    }
    onChange(newValue);
  };

  return (
    <ContentSectionWrapper
      title="Subject line"
      description="Choose a subject line format for your Campaign"
    >
      <Flex flexDir="column">
        <RadioGroup
          onChange={(currentValue: EDITION_SUBJECT_TYPES) =>
            handleOptionChange(currentValue)
          }
          defaultValue={subjectLineOption}
        >
          <Flex flexDir="column" gap={4}>
            <Flex flexDir="column" gap={2}>
              <Radio
                value={EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE}
                mb={0}
                // If the preview also uses First Article Title this option can't be used for the subject
                isDisabled={
                  editionSubjectSettings?.defaultEmailPreviewTextType ===
                  EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_TITLE
                }
              >
                The title of the first article in the Edition
              </Radio>
              {subjectLineOption ===
                EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE && (
                <Text size="sm" color="gray.600" ml={6}>
                  Example: Building better companies with responsible AI
                </Text>
              )}
            </Flex>
            <Radio value={currentCampaignNameAndDate} mb={0}>
              Campaign name and date
            </Radio>
            {isCampaignNameAndDate && (
              <Flex flexDir="column" gap={4} ml={6}>
                <Flex flexDir="column" gap={2}>
                  <Radio value={EDITION_SUBJECT_TYPES.NAME_DDMM} mb={0}>
                    DD.MM
                  </Radio>
                  {subjectLineOption === EDITION_SUBJECT_TYPES.NAME_DDMM && (
                    <Text size="sm" color="gray.600" ml={6}>
                      Example: Adriana Diario 15/10
                    </Text>
                  )}
                </Flex>
                <Flex flexDir="column" gap={2}>
                  <Radio value={EDITION_SUBJECT_TYPES.NAME_MMDD} mb={0}>
                    MM.DD
                  </Radio>
                  {subjectLineOption === EDITION_SUBJECT_TYPES.NAME_MMDD && (
                    <Text size="sm" color="gray.600" ml={6}>
                      Example: Adriana Diario 10/15
                    </Text>
                  )}
                </Flex>
                <Flex flexDir="column" gap={2}>
                  <Radio value={EDITION_SUBJECT_TYPES.NAME_DDMONTH} mb={0}>
                    DD.Month
                  </Radio>
                  {subjectLineOption === EDITION_SUBJECT_TYPES.NAME_DDMONTH && (
                    <Text size="sm" color="gray.600" ml={6}>
                      Example: Adriana Diario 15 October
                    </Text>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </RadioGroup>
      </Flex>
    </ContentSectionWrapper>
  );
}

export default SubjectLine;
