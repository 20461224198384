const SPAM_PHRASES = [
  'free',
  'help',
  '% off',
  'reminder',
  'deals',
  'sale ending soon',
  'assistance',
  'act now',
  'all natural',
  'as seen on',
  'attention',
  'bad credit',
  'bargain',
  'best price',
  'billion',
  'certified',
  'dear friend',
  'decision',
  'discount',
  'double your income',
  'eliminate debt',
  'extra income',
  'fast cash',
  'fees',
  'financial freedom',
  'join millions',
  'lose weight',
  'lowest price',
  'make money fast',
  'no medical exams',
  'no purchase necessary',
  'opportunity',
  'partners',
  'performance',
  'rates',
  'satisfaction guaranteed',
  'search engine lists',
  'selling',
  'success',
  'trial',
  'visit our website',
  'report',
  'webinar',
  'book',
  'learn',
  'fwd:',
  're:',
  'perfect',
  'good',
  'wonderful',
  'fantastic',
  'the best',
  'perfect',
  'unbelievable',
  'wonderful',
];

export default SPAM_PHRASES;
