import { ReactComponent as HelpQuestion } from 'assets/svg/help-question.svg';

import { showWidget } from 'common/zendesk';
import HeaderIconButton from 'components/header/HeaderIconButton';
import useGlobalInfo from 'hooks/useGlobalInfo';

declare global {
  interface Window {
    zE?: {
      activate: ({ hideOnClose }: { hideOnClose: boolean }) => void;
    };
  }
}

function Zendesk() {
  const global = useGlobalInfo();

  const handleClick = () => {
    if (window.zE) {
      window.zE.activate({ hideOnClose: true });
      showWidget(global?.user ?? undefined);
    }
  };

  return (
    <HeaderIconButton
      aria-label="Help"
      icon={<HelpQuestion />}
      onClick={handleClick}
    />
  );
}

export default Zendesk;
