import { AddIcon, Tag } from '@ebx-ui/ebx-ui-component-library-sdk';
import React from 'react';

interface MergeTagProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

function MergeTag({ label, onClick, ...rest }: MergeTagProps) {
  return (
    <Tag
      icon={<AddIcon />}
      as="button"
      border="none"
      color="blue"
      onClick={onClick}
      {...rest}
    >
      {label}
    </Tag>
  );
}

export default MergeTag;
