import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * getPropertiesSettings - Updates the property settings
 * @param {{
 *  propertyURN: string;
 * }} args
 */
export default async function getPropertiesSettings({
  propertyURN = mandatory('propertyURN'),
} = {}) {
  const guid = metrics.start('getPropertiesSettings');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/settings`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getPropertiesSettings /properties/${propertyURN}/settings`);

  try {
    const response = await axios(config);
    metrics.end('getPropertiesSettings', guid);
    return response.data;
  } catch (error) {
    metrics.fail('getPropertiesSettings', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertiesSettings',
      args: { propertyURN },
    });
    throw apiError;
  }
}
