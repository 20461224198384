import { Hub } from 'aws-amplify';

import * as API from 'api/api';

import { HUB_CHANNELS, HUB_EVENTS } from 'common/constants';

export default async function impersonate({
  overrideUsername,
  overrideCampaignURN,
  overridePropertyURN,
}) {
  /* Impersonate user */
  const impersonateResponse = await API.postServiceAuth({
    overrideUsername,
    overrideCampaignURN,
    overridePropertyURN,
  });

  /* Impersonate successful */
  Hub.dispatch(HUB_CHANNELS.AUTHENTICATION, {
    event: HUB_EVENTS.AUTHENTICATION.IMPERSONATE,
    user: impersonateResponse,
  });
}
