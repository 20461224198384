/* eslint jsx-a11y/anchor-is-valid: "off" */

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import { ReactComponent as Lightning } from 'assets/svg/lightning.svg';
import OptimisationOptions from 'components/editor/curate/OptimisationOptions';

import * as campaigns from 'common/campaigns';
import * as editions from 'common/editions';
import useGlobalInfo from 'hooks/useGlobalInfo';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';

import './OptimisationLevel.css';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const handleClick = event => {
    event.preventDefault();
    onClick(event);
  };

  return (
    <a className="link-unstyled" href="#" ref={ref} onClick={handleClick}>
      {children}
    </a>
  );
});

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => (
    <div
      ref={ref}
      style={style}
      className={`optimisation-options-container ${className}`}
      aria-labelledby={labeledBy}
    >
      {children}
    </div>
  )
);

CustomMenu.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
};

function OptimisationLevel({ editionDetails }) {
  const globalInfo = useGlobalInfo();
  const isContentPersonalisationEnabled =
    campaigns.getCurrentCampaignSettingValue(
      globalInfo,
      CAMPAIGN_SETTING_TYPES.CONTENT_PERSONALISATION,
      'isContentOrderingEnabled'
    );

  if (!editionDetails) {
    return null;
  }

  const optimisationUsage = editions.determineOptimisationUsage(
    isContentPersonalisationEnabled ?? false,
    editionDetails
  );

  const optimisationLevel =
    optimisationUsage.optimisationPercentage >= 50 ? 'optimal' : 'suboptimal';
  const backgroundClass = `optimisation-level-${optimisationLevel}-bg`;
  const foregroundClass = `optimisation-level-${optimisationLevel}-fg`;

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="optimisation-level-dropdown">
        <Button
          className={`ebx-btn-secondary ebx-btn-md ebx-h3 mr-2 py-1 ${foregroundClass} ${backgroundClass}`}
        >
          <div className="d-flex align-items-center ml-0 ml-lg-auto my-1 ebx-h3 ebx-title-colour text-lg-right">
            <Lightning className={foregroundClass} />
            <span className={`mx-2 optimisation-level ${foregroundClass}`}>
              Optimization at {optimisationUsage.optimisationPercentage}%
            </span>
            <ArrowDown className={foregroundClass} />
          </div>
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        <OptimisationOptions optimisationUsage={optimisationUsage} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

OptimisationLevel.propTypes = {
  editionDetails: PropTypes.shape({
    editionURN: PropTypes.string.isRequired,
    editionState: PropTypes.string.isRequired,
    editionSubject: PropTypes.string.isRequired,
    scheduledUnixTime: PropTypes.number,
    lastUpdatedUnixTime: PropTypes.number,
    lastUpdatedByEmail: PropTypes.string,
  }),
};

OptimisationLevel.defaultProps = {
  editionDetails: null,
};

export default OptimisationLevel;
