import React, { createRef, useState } from 'react';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

interface RightPanelProps {
  children: React.ReactNode;
}

function RightPanel({ children }: RightPanelProps) {
  const rightRef = createRef<HTMLDivElement>();
  const [rightWidth, setRightWidth] = useState(-1);

  React.useEffect(() => {
    if (rightRef.current) {
      if (
        rightRef.current.offsetLeft + rightRef.current.clientWidth <=
        document.documentElement.clientWidth
      ) {
        setRightWidth(rightRef?.current?.clientWidth);
      } else {
        setRightWidth(
          document.documentElement.clientWidth - rightRef.current.offsetLeft
        );
      }
    }
  }, [rightRef]);

  return (
    <Box py={6} flex={1} ref={rightRef}>
      {React.Children.map(children, child =>
        // @ts-ignore
        React.cloneElement(child, { width: rightWidth })
      )}
    </Box>
  );
}

export default RightPanel;
