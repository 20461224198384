import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postMarketingTemplates({
  campaignURN = mandatory('campaignURN'),
  content = '',
  name,
}) {
  const guid = metrics.start('postMarketingTemplates');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/marketingtemplate`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({ content, name }),
  };
  logger.info(
    `API:postMarketingTemplates /campaigns/${campaignURN}/marketingtemplate`
  );

  try {
    const response = await axios(config);
    metrics.end('postMarketingTemplates', guid);
    return response.data.templateURN;
  } catch (error) {
    metrics.fail('postMarketingTemplates', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postMarketingTemplates',
      args: { campaignURN },
    });
    throw apiError;
  }
}
