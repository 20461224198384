import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';

import './ModalWrapper.css';

function ModalWrapper({ handleClose, title, children }) {
  return (
    <Modal backdrop="static" centered onHide={handleClose} show>
      <Form>
        <Modal.Header className="ebx-modal-header">
          <span className="modal__title ebx-h2">{title}</span>
          <span className="ml-auto">
            <Button
              variant="link"
              className="p-0 ebx-faded-colour model-close"
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </span>
        </Modal.Header>
        {children}
      </Form>
    </Modal>
  );
}

ModalWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalWrapper;
