import { getFormattedTimeFromParts } from 'common/datetime';

import './TimeSlotSlider.css';

import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

function valuetext(value: number) {
  return getFormattedTimeFromParts({ hour: value });
}

interface TimeSlotSliderProps {
  value: [number, number];
  onChange: (newValues: number[]) => void;
}

function TimeSlotSlider({ value, onChange }: TimeSlotSliderProps) {
  const handleChange = (newValue: number | number[]) => {
    if (typeof newValue !== 'number') {
      if (newValue[1] - newValue[0] >= 1) {
        onChange(newValue);
      }
    }
  };

  return (
    <div className="time-slot-slider d-flex align-items-center">
      <span className="ebx-title-colour mr-3">{valuetext(value[0])}</span>
      <RangeSlider
        value={value}
        max={24}
        onChange={handleChange}
        getAriaValueText={valuetext}
        size="sm"
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack bg="primary.600" />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} bg="primary.600" />
        <RangeSliderThumb index={1} bg="primary.600" />
      </RangeSlider>
      <span className="ebx-title-colour mx-3">{valuetext(value[1])}</span>
    </div>
  );
}

export default TimeSlotSlider;
