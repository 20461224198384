import EditionSendTimes from 'components/campaigns/editions/EditionSendTimes';
import Scheduler from 'components/editor/schedule/Scheduler';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import { Edition as EditionType } from 'common/types';

interface TimingOptionsProps {
  emailDetails: EditionType;
  handleUpdateEmail: (emailDetails: EditionType) => void;
}

function TimingOptions({
  emailDetails,
  handleUpdateEmail,
}: TimingOptionsProps) {
  const currentTime = datetime.getUnixTimestamp();

  const editionDisplayState = editions.getEditionDisplayState(
    emailDetails.approvalState,
    emailDetails?.editionSource,
    emailDetails?.lastUpdatedUnixTime,
    emailDetails?.editionState,
    emailDetails?.scheduledUnixTime,
    currentTime
  );

  const isEmailEditable = editions.checkIsEditionEditable(editionDisplayState);

  const finishedSendingTime =
    emailDetails.scheduledUnixTime +
    (emailDetails.sendingTimeslotSizeSeconds ?? 0);

  const handleTimingChange = (updatedTiming: {
    scheduledUnixTime: number;
    sendingTimeslotSizeSeconds: number;
  }) => {
    handleUpdateEmail({
      ...emailDetails,
      scheduledUnixTime: updatedTiming.scheduledUnixTime,
      sendingTimeslotSizeSeconds: updatedTiming.sendingTimeslotSizeSeconds,
    });
  };

  if (isEmailEditable) {
    return (
      <Scheduler
        title="Schedule Email"
        edition={emailDetails}
        editionDisplayState={editionDisplayState}
        updateEditionDetails={handleTimingChange}
        initialFinishedSendingTime={finishedSendingTime}
        scheduleEditionButton={true}
      />
    );
  }

  return (
    <Box mr={2}>
      <EditionSendTimes
        scheduledUnixTime={emailDetails.scheduledUnixTime}
        finishedSendingTime={finishedSendingTime}
        editionState={emailDetails.editionState}
      />
    </Box>
  );
}

export default TimingOptions;
