/* eslint camelcase: ["error", {allow: ["jwt_decode"]}] */
/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

import { isNull, isRunningTests, isUndefined } from 'common/utility';

import { COGNITO_CHALLENGE_NAMES } from 'common/constants';
import * as cookie from 'common/cookie';
import {
  CAMPAIGN_PERMISSION_TYPES,
  CAMPAIGN_PERMISSION_TYPE_IDS,
  PROPERTY_PERMISSION_TYPES,
  PROPERTY_PERMISSION_TYPE_IDS,
  USER_STATES,
  USER_TYPES,
} from 'common/enums';
import * as url from 'common/url';

export {
  clearLocalStorage,
  convertPermissions,
  getAccessToken,
  getChallengeDetails,
  getChallengeUser,
  getClientServiceToken,
  getCognitoGroups,
  getIdToken,
  getRefreshToken,
  getUserDetails,
  getUsername,
  hasChallengeDetails,
  isImpersonating,
  isImpersonatingStaffUser,
  impersonatingByStaffEmail,
  isSignedIn,
  isStaffUser,
  setClientServiceToken,
  setKeyPrefix,
  signIn,
  signOut,
  storeChallengeDetails,
};

/**
 * Private functions
 */

const extractUserType = payload =>
  payload['cognito:groups'] ? payload['cognito:groups'][0] : null;

function getItem(key) {
  return sessionStorage.getItem(key);
}

function getKeyPrefix() {
  return cookie.getCookieValue('keyPrefix');
}

function getLocalItem(key) {
  const keyPrefix = getKeyPrefix();
  return localStorage.getItem(`${keyPrefix}.${key}`);
}

function mapCampaignPermissionTypeId(permissionTypeId) {
  const permissionTypeIdMap = {
    [CAMPAIGN_PERMISSION_TYPE_IDS.CAMPAIGN_EDITOR]:
      CAMPAIGN_PERMISSION_TYPES.CAMPAIGN_EDITOR,
  };
  return permissionTypeIdMap[Number(permissionTypeId)];
}

function mapPropertyPermissionTypeId(permissionTypeId) {
  const permissionTypeIdMap = {
    [PROPERTY_PERMISSION_TYPE_IDS.NONE]: PROPERTY_PERMISSION_TYPES.NONE,
    [PROPERTY_PERMISSION_TYPE_IDS.VIEW_ONLY]:
      PROPERTY_PERMISSION_TYPES.VIEW_ONLY,
    [PROPERTY_PERMISSION_TYPE_IDS.EDITOR]: PROPERTY_PERMISSION_TYPES.EDITOR,
    [PROPERTY_PERMISSION_TYPE_IDS.ADMIN]: PROPERTY_PERMISSION_TYPES.ADMIN,
  };
  return permissionTypeIdMap[Number(permissionTypeId)];
}

function removeItem(key) {
  sessionStorage.removeItem(key);
}

function setItem(key, value) {
  sessionStorage.setItem(key, value);
}

function setLocalItem(key, value) {
  const keyPrefix = getKeyPrefix();
  localStorage.setItem(`${keyPrefix}.${key}`, value);
}

/**
 * Public functions
 */

function clearLocalStorage() {
  const prefixToAvoid = [
    '_BEAMER',
    'ZD',
    'getSession',
    'apc_local_id',
    '_hjid',
  ];

  for (const key of Object.keys(localStorage)) {
    let deleteKey = true;
    for (const prefix of prefixToAvoid) {
      if (key.startsWith(prefix)) {
        deleteKey = false;
      }
    }
    if (deleteKey) localStorage.removeItem(key);
  }
}

function convertPermissions(activeUser) {
  if (activeUser) {
    const ebxPermissions = [];
    const propertyPermissions = activeUser['custom:property_permissions'];
    propertyPermissions.split(',').forEach(cognitoPermission => {
      const permissionParts = cognitoPermission.split(':');
      const permissionTypeId = permissionParts[0];
      const propertyId = permissionParts[1];
      const propertyURN = Number.isNaN(propertyId)
        ? propertyId
        : `urn:property:${propertyId}`;
      const ebxPermission = {
        permissionType: mapPropertyPermissionTypeId(permissionTypeId),
        propertyURN,
      };
      ebxPermissions.push(ebxPermission);
    });

    const campaignPermissions = activeUser['custom:campaign_permissions'];
    if (campaignPermissions) {
      campaignPermissions.split(',').forEach(cognitoPermission => {
        const permissionParts = cognitoPermission.split(':');
        const campaignURN = permissionParts[1];
        const permissionTypeId = permissionParts[0];
        const ebxPermission = {
          permissionType: mapCampaignPermissionTypeId(permissionTypeId),
          campaignURN,
        };
        ebxPermissions.push(ebxPermission);
      });
    }

    return ebxPermissions;
  }
  return null;
}

function getCognitoToken(key) {
  const fullKey = `${getKeyPrefix()}.${key}`;
  return cookie.getCookieValue(fullKey);
}

function getAccessToken() {
  return getCognitoToken('accessToken');
}

function getChallengeDetails() {
  return window?.AWS?.challengeParam ?? null;
}

function getChallengeUser() {
  return window?.AWS?.user ?? null;
}

function getClientServiceToken() {
  return getLocalItem('clientServiceToken');
}

function getIdToken() {
  return getCognitoToken('idToken');
}

function getRefreshToken() {
  return getCognitoToken('refreshToken');
}

function getUserDetails(authenticatedUser, clientServiceToken) {
  const activeUser = jwt_decode(clientServiceToken);
  const userDetails = {
    emailAddress: activeUser?.email ?? null,
    keyPrefix: authenticatedUser.keyPrefix,
    lastLoginTime: 0,
    name: activeUser?.name ?? null,
    permissions: convertPermissions(activeUser),
    timeCreated: 0,
    userStateId: USER_STATES.ENABLED,
    userType: authenticatedUser?.signInUserSession?.accessToken?.payload
      ? extractUserType(authenticatedUser.signInUserSession.accessToken.payload)
      : null,
    username: authenticatedUser?.username,
    accessGroups:
      authenticatedUser?.signInUserSession?.accessToken?.payload?.[
        'cognito:groups'
      ],
  };
  return userDetails;
}

function getUsername() {
  try {
    return getKeyPrefix().split('.')[2];
  } catch (error) {
    return null;
  }
}

const getCognitoGroups = () => {
  const idToken = getIdToken();
  if (idToken) {
    const decodedToken = jwt_decode(idToken);
    if (decodedToken) {
      return decodedToken['cognito:groups'];
    }
  }
  return null;
};

function hasChallengeDetails() {
  return !isNull(getChallengeDetails());
}

function isImpersonating() {
  const clientServiceToken = getClientServiceToken();
  const decodedUser = jwt_decode(clientServiceToken);
  return (
    !isNull(clientServiceToken) && 'impersonated_by_staff_email' in decodedUser
  );
}

function isImpersonatingStaffUser() {
  const clientServiceToken = getClientServiceToken();
  const decodedUser = jwt_decode(clientServiceToken);
  return (
    !isNull(clientServiceToken) && // Client service token exists
    'impersonated_by_staff_email' in decodedUser && // Is impersonating another user
    decodedUser.user_groups.indexOf('STAFF_USER') !== -1 // That user is a staff user
  );
}

/**
 * impersonatingByStaffEmail - Returns email of user that is impersonating or null if there's no impersonation
 *
 * @return {string | null}
 */
function impersonatingByStaffEmail() {
  const clientServiceToken = getClientServiceToken();
  const decodedUser = jwt_decode(clientServiceToken);
  if (
    !isNull(clientServiceToken) &&
    'impersonated_by_staff_email' in decodedUser
  ) {
    return decodedUser.impersonated_by_staff_email;
  }
  return null;
}

function isSignedIn() {
  return !isNull(getClientServiceToken());
}

async function isStaffUser() {
  try {
    const userDetail = await Auth.currentAuthenticatedUser();
    const userType = extractUserType(
      userDetail.signInUserSession.accessToken.payload
    );
    return userType === USER_TYPES.ECHOBOX_STAFF;
  } catch (error) {
    return false;
  }
}

function setClientServiceToken(clientServiceToken) {
  return setLocalItem('clientServiceToken', clientServiceToken);
}

function setKeyPrefix(keyPrefix) {
  // temporary change to delete any keyprefix stored at parent domain level
  cookie.deleteCookie('keyPrefix', {
    domain: url.getTLD(window.location.hostname),
  });
  cookie.setCookieValue('keyPrefix', keyPrefix);
}

function deleteClientServiceToken() {
  const keyPrefix = getKeyPrefix();
  localStorage.removeItem(`${keyPrefix}.clientServiceToken`);
}

function signIn(signInResponse) {
  // Remove any existing error messages e.g. session terminated due to inactivity
  removeItem('flashMessages');
  /* Store key prefix and user type */
  const keyPrefix = `${signInResponse.keyPrefix}.${signInResponse.username}`;
  setKeyPrefix(keyPrefix);
  setItem(
    'logToConsole',
    signInResponse.userType
      ? signInResponse.userType === USER_TYPES.ECHOBOX_STAFF
      : false
  );
}

function signOut() {
  // Clear all session data except current version and log level
  const currentVersion = getItem('currentVersion');
  const logToConsole = getItem('logToConsole');
  deleteClientServiceToken();
  deleteAuthCookiesAtTLD();
  sessionStorage.clear();
  // Restore items we want to retain
  setItem('currentVersion', currentVersion);
  setItem('logToConsole', logToConsole ?? false);

  // Clear all performance data
  if (!isRunningTests()) {
    performance.clearMarks();
    performance.clearMeasures();
  }
}

function storeChallengeDetails(authenticatedUser) {
  if (COGNITO_CHALLENGE_NAMES[authenticatedUser?.challengeName]) {
    if (isUndefined(window.AWS)) {
      window.AWS = {};
    }
    window.AWS.challengeParam = authenticatedUser.challengeParam;
    window.AWS.user = authenticatedUser;
  }
}

// temporary function to delete any auth cookies set at the top level domain
function deleteAuthCookiesAtTLD() {
  const tld = url.getTLD(window.location.hostname);
  const keyPrefix = getKeyPrefix();
  if (keyPrefix) {
    const keyPrefixParts = keyPrefix.split('.');
    const sessionKeyPrefix = `${keyPrefixParts[0]}.${keyPrefixParts[1]}`;
    const cookieNames = [
      `${keyPrefix}.clockDrift`,
      `${keyPrefix}.accessToken`,
      `${keyPrefix}.idToken`,
      `${keyPrefix}.refreshToken`,
      `${keyPrefix}.userData`,
      `${sessionKeyPrefix}.LastAuthUser`,
      keyPrefix,
    ];
    cookieNames.forEach(cookieName => {
      cookie.deleteCookie(cookieName, {
        domain: tld,
      });
    });
  }
}
