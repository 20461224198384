/* eslint import/prefer-default-export: "off" */

const types = {
  CHANGE_PROPERTY: 'CHANGE_PROPERTY',
  REFRESH_GLOBAL_INFO: 'REFRESH_GLOBAL_INFO',
  SET_GLOBAL_INFO: 'SET_GLOBAL_INFO',
  SET_GLOBAL_INFO_STATE: 'SET_GLOBAL_INFO_STATE',
  SET_STATUS_PAGE_NOTIFICATIONS: 'SET_STATUS_PAGE_NOTIFICATIONS',
};

export { types };
