import { Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import RichTextEditor from 'components/editor/blocks/attributes/RichTextEditor';

interface RichTextProps {
  header: string;
  name: string;
  onChange: (key: string, value: any) => void;
  value: string;
}

function RichText({ header, name, onChange, value }: RichTextProps) {
  const handleChange = (string: string) => {
    onChange(name, string);
  };

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h4">{header}</Heading>
      <RichTextEditor onChange={handleChange} value={value} />
    </Flex>
  );
}

export default RichText;
