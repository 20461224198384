import axios from 'axios';

import * as datetime from 'common/datetime';
import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { batchRequests } from 'common/request';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

const MAX_EDITIONS_IN_BATCH = 10;

const now = datetime.getUnixTimestamp();

interface GetEditionsPerformanceArgs {
  editionURNs: string[];
  startUnixTime?: number;
  endUnixTime?: number;
}

export default async function getEditionPerformance({
  editionURNs,
  startUnixTime,
  endUnixTime,
}: GetEditionsPerformanceArgs) {
  if (isUndefined(editionURNs)) {
    mandatory('editionURNs');
  }

  return batchRequests(editionURNs, MAX_EDITIONS_IN_BATCH, editionURNBatch =>
    getEditionPerformanceNonBatched({
      editionURNs: editionURNBatch,
      startUnixTime,
      endUnixTime,
    })
  );
}

export async function getEditionPerformanceNonBatched({
  editionURNs,
  startUnixTime = now - datetime.DAYS(30),
  endUnixTime = now,
}: GetEditionsPerformanceArgs) {
  const guid = metrics.start('getEditionPerformance');
  if (isUndefined(editionURNs)) {
    mandatory('editionURNs');
  }

  const config = {
    url: `${apiBaseURL(
      APIS.OPTIMISATION_SERVICE,
      ''
    )}/editions/insights?editionURNs=${editionURNs}&startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(
    `API:getEditionPerformance /editions/insights?editionURNs=${editionURNs}&startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}`
  );

  try {
    const response = await axios(config);
    metrics.end('getEditionPerformance', guid);
    return response.data.editions ?? null;
  } catch (error) {
    metrics.fail('getEditionPerformance', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getEditionPerformance',
            args: { editionURNs },
            ignore403Errors: true,
          })
        : error;
    throw apiError;
  }
}
