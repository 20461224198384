import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function getPropertySettingsByType({
  propertyURN = mandatory('propertyURN'),
  settingTypeId = mandatory('settingTypeIds'),
} = {}) {
  const guid = metrics.start('getPropertySettingsByType');
  const config = {
    url: `${apiBaseURL(
      APIS.CORE,
      '1'
    )}/properties/${propertyURN}/settings/types/${settingTypeId}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(
    `API:getPropertySettingsByType /properties/${propertyURN}/settings/type/${settingTypeId}`
  );

  try {
    // Send a request to /properties/{propertyURN}/syndfeeds
    const response = await axios(config);
    metrics.end('getPropertySettingsByType', guid);
    return response?.data?.entries?.[settingTypeId];
  } catch (error) {
    metrics.fail('getPropertySettingsByType', guid);
    const apiError = await handleAPIError({
      originalError: error,
      args: { propertyURN },
    });
    throw apiError;
  }
}
