import PropTypes from 'prop-types';

function HeaderRight({ children }) {
  return (
    <span className="ml-auto ebx-h3 d-flex align-items-center">{children}</span>
  );
}

HeaderRight.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderRight;
