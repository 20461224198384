/* eslint func-names: "off" */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from 'react-bootstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import * as API from 'api/api';
import { FORM_VALIDATION_MODES } from 'common/enums';
import * as commonErrors from 'common/errors';
import { STAFF_MODAL_STATES } from 'components/staff/StaffModalWrapper';

import './StaffModal.css';

Yup.addMethod(Yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = x => x[path];
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);

    return this.createError({ path: `${this.path}.[${idx}].${path}`, message });
  });
});

const NewPropertySchema = Yup.object().shape({
  propertyName: Yup.string().required('Property name is required'),
  companyDetails: Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    addressLine1: Yup.string().required('Address line 1 is required'),
    city: Yup.string().required('City is required'),
    postcode: Yup.string().required('Postcode is required'),
    country: Yup.string().required('Country is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
  }),
  rootURL: Yup.string().required('Root URL is required'),
  initialUsers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Full name is required'),
        emailAddress: Yup.string()
          .email('This is not a valid email address')
          .required('Email is required'),
      })
    )
    .unique('Duplicate user name', 'name')
    .unique('Duplicate email', 'emailAddress'),
});

function NewProperty({
  show,
  setCurrentComponent,
  setNewPropertyData,
  isProcessing,
  setIsProcessing,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(NewPropertySchema),
    mode: FORM_VALIDATION_MODES.ONSUBMIT,
    reValidateMode: FORM_VALIDATION_MODES.ONSUBMIT,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'initialUsers',
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const handleAddInitialUser = () => {
    append({
      name: '',
      emailAddress: '',
    });
  };

  const handleCancel = () => {
    setCurrentComponent(STAFF_MODAL_STATES.STAFF_OPTIONS);
  };

  const handleCreateProperty = async ({
    propertyName,
    companyDetails,
    initialUsers,
    rootURL,
  }) => {
    try {
      setIsProcessing(true);
      const newPropertyData = await API.postProperties({
        propertyName,
        companyDetails,
        initialUsers,
        rootURL,
      });
      setNewPropertyData({
        propertyName,
        ...newPropertyData,
      });
      setCurrentComponent(STAFF_MODAL_STATES.PROPERTY_CREATED);
    } catch (error) {
      setErrorMessage(commonErrors.getErrorMessage(error));
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    setIsProcessing(false);
    setTimeout(() => {
      document.getElementById('propertyName')?.focus();
    }, 100);
  }, [show, setIsProcessing]);

  return (
    <Form noValidate={true} onSubmit={handleSubmit(handleCreateProperty)}>
      <Modal.Body className="ebx-default-modal-body ebx-body-1 ebx-modal-body">
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">Property name</FormLabel>
          <FormControl
            name="propertyName"
            id="propertyName"
            {...register('propertyName')}
            required
            className={`ebx-input ebx-body-1 ${
              errors.propertyName ? 'ebx-input-error' : ''
            }`}
            type="text"
            disabled={isProcessing}
          />
          {errors.propertyName && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span>{errors.propertyName.message}</span>
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">Root URL</FormLabel>
          <FormControl
            name="rootURL"
            id="rootURL"
            {...register('rootURL')}
            required
            className={`ebx-input ebx-body-1 ${
              errors.rootURL ? 'ebx-input-error' : ''
            }`}
            type="text"
            disabled={isProcessing}
          />
          {errors.rootURL && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span>{errors.rootURL.message}</span>
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">Company details</FormLabel>
          <Form.Row>
            <Col>
              <FormLabel className="ebx-h5 my-1">Company name</FormLabel>
              <FormControl
                name="companyDetails.companyName"
                id="companyDetails.companyName"
                {...register('companyDetails.companyName')}
                required
                className={`ebx-input ebx-body-1 ${
                  errors.companyDetails && errors.companyDetails.companyName
                    ? 'ebx-input-error'
                    : ''
                }`}
                type="text"
                disabled={isProcessing}
              />
              {errors.companyDetails && errors.companyDetails.companyName && (
                <div className="d-flex align-items-center error--text mt-2 mb-2">
                  <span>{errors.companyDetails.companyName.message}</span>
                </div>
              )}
            </Col>
            <Col>
              <FormLabel className="ebx-h5 my-1">City</FormLabel>
              <FormControl
                name="companyDetails.city"
                id="companyDetails.city"
                {...register('companyDetails.city')}
                required
                className={`ebx-input ebx-body-1 ${
                  errors.companyDetails && errors.companyDetails.city
                    ? 'ebx-input-error'
                    : ''
                }`}
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
              {errors.companyDetails && errors.companyDetails.city && (
                <div className="d-flex align-items-center error--text mt-2 mb-2">
                  <span>{errors.companyDetails.city.message}</span>
                </div>
              )}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormLabel className="ebx-h5 my-1">Address line 1</FormLabel>
              <FormControl
                name="companyDetails.addressLine1"
                id="companyDetails.addressLine1"
                {...register('companyDetails.addressLine1')}
                required
                className={`ebx-input ebx-body-1 ${
                  errors.companyDetails && errors.companyDetails.addressLine1
                    ? 'ebx-input-error'
                    : ''
                }`}
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
              {errors.companyDetails && errors.companyDetails.addressLine1 && (
                <div className="d-flex align-items-center error--text mt-2 mb-2">
                  <span>{errors.companyDetails.addressLine1.message}</span>
                </div>
              )}
            </Col>
            <Col>
              <FormLabel className="ebx-h5 my-1">Postcode</FormLabel>
              <FormControl
                name="companyDetails.postcode"
                id="companyDetails.postcode"
                {...register('companyDetails.postcode')}
                required
                className={`ebx-input ebx-body-1 ${
                  errors.companyDetails && errors.companyDetails.postcode
                    ? 'ebx-input-error'
                    : ''
                }`}
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
              {errors.companyDetails && errors.companyDetails.postcode && (
                <div className="d-flex align-items-center error--text mt-2 mb-2">
                  <span>{errors.companyDetails.postcode.message}</span>
                </div>
              )}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormLabel className="ebx-h5 my-1">Address line 2</FormLabel>
              <FormControl
                name="companyDetails.addressLine2"
                id="companyDetails.addressLine2"
                className="ebx-input ebx-body-1"
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
            </Col>
            <Col>
              <FormLabel className="ebx-h5 my-1">Country</FormLabel>
              <FormControl
                name="companyDetails.country"
                id="companyDetails.country"
                {...register('companyDetails.country')}
                required
                className={`ebx-input ebx-body-1 ${
                  errors.companyDetails && errors.companyDetails.country
                    ? 'ebx-input-error'
                    : ''
                }`}
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
              {errors.companyDetails && errors.companyDetails.country && (
                <div className="d-flex align-items-center error--text mt-2 mb-2">
                  <span>{errors.companyDetails.country.message}</span>
                </div>
              )}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormLabel className="ebx-h5 my-1">Address line 3</FormLabel>
              <FormControl
                name="companyDetails.addressLine3"
                id="companyDetails.addressLine3"
                className="ebx-input ebx-body-1"
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
            </Col>
            <Col>
              <FormLabel className="ebx-h5 my-1">Phone number</FormLabel>
              <FormControl
                name="companyDetails.phoneNumber"
                id="companyDetails.phoneNumber"
                {...register('companyDetails.phoneNumber')}
                required
                className={`ebx-input ebx-body-1 ${
                  errors.companyDetails && errors.companyDetails.phoneNumber
                    ? 'ebx-input-error'
                    : ''
                }`}
                type="text"
                disabled={isProcessing}
                autoComplete="off"
              />
              {errors.companyDetails && errors.companyDetails.phoneNumber && (
                <div className="d-flex align-items-center error--text mt-2 mb-2">
                  <span>{errors.companyDetails.phoneNumber.message}</span>
                </div>
              )}
            </Col>
          </Form.Row>
        </FormGroup>
        <FormGroup>
          <FormLabel className="ebx-h3 mb-1">Property users</FormLabel>
          {fields.length !== 0 ? (
            <Form.Row>
              <Col xs={5}>
                <FormLabel className="ebx-h5 mb-1">Full name</FormLabel>
              </Col>
              <Col xs={7}>
                <FormLabel className="ebx-h5 mb-1">Email</FormLabel>
              </Col>
              <Col />
            </Form.Row>
          ) : (
            <Form.Row>
              <Col>
                <FormLabel className="ebx-h5 mb-1">Current user</FormLabel>
              </Col>
            </Form.Row>
          )}
          {fields.map((user, index) => (
            <Form.Row key={user.id}>
              <Col xs={5}>
                <FormControl
                  name={`initialUsers.${index}.name`}
                  id={`initialUsers.${index}.name`}
                  {...register(`initialUsers.${index}.name`)}
                  required
                  className={`ebx-input ebx-body-1 mb-1 ${
                    errors.initialUsers &&
                    errors.initialUsers[index] &&
                    errors.initialUsers[index].name
                      ? 'ebx-input-error'
                      : ''
                  }`}
                  type="text"
                  disabled={isProcessing}
                  autoComplete="off"
                />
                {errors.initialUsers &&
                  errors.initialUsers[index] &&
                  errors.initialUsers[index].name && (
                    <div className="d-flex align-items-center error--text mt-2 mb-2">
                      <span>{errors.initialUsers[index].name.message}</span>
                    </div>
                  )}
              </Col>
              <Col xs={5}>
                <FormControl
                  name={`initialUsers.${index}.emailAddress`}
                  id={`initialUsers.${index}.emailAddress`}
                  {...register(`initialUsers.${index}.emailAddress`)}
                  required
                  className={`ebx-input ebx-body-1 mb-1 ${
                    errors.initialUsers &&
                    errors.initialUsers[index] &&
                    errors.initialUsers[index].emailAddress
                      ? 'ebx-input-error'
                      : ''
                  }`}
                  type="text"
                  disabled={isProcessing}
                  autoComplete="off"
                />
                {errors.initialUsers &&
                  errors.initialUsers[index] &&
                  errors.initialUsers[index].emailAddress && (
                    <div className="d-flex align-items-center error--text mt-2 mb-2">
                      <span>
                        {errors.initialUsers[index].emailAddress.message}
                      </span>
                    </div>
                  )}
              </Col>
              <Col xs={2}>
                <Button
                  name={`initialUsers.${index}.removeUser`}
                  id={`initialUsers.${index}.removeUser`}
                  className="ebx-btn-secondary ebx-btn-md ebx-h3"
                  disabled={isProcessing}
                  onClick={() => remove(index)}
                  type="button"
                >
                  Remove
                </Button>
              </Col>
            </Form.Row>
          ))}
        </FormGroup>
        <Button
          name="addUser"
          id="addUser"
          className="ebx-btn-primary ebx-btn-md ebx-h3"
          disabled={isProcessing}
          onClick={handleAddInitialUser}
          type="button"
        >
          Add User
        </Button>
        {errorMessage && (
          <div className="d-flex align-items-center error--text mt-2 mb-2">
            <span>{errorMessage}</span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="ebx-btn-primary ebx-btn-md ebx-h3"
          disabled={isProcessing}
          type="submit"
        >
          Create property
        </Button>
      </Modal.Footer>
    </Form>
  );
}

NewProperty.propTypes = {
  show: PropTypes.bool.isRequired,
  setCurrentComponent: PropTypes.func.isRequired,
  setNewPropertyData: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  setIsProcessing: PropTypes.func.isRequired,
};

export default NewProperty;
