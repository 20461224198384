import { ChevronDownIcon } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import useOnClickOutside from 'hooks/useOnClickOutside';

import { PROPERTY_PERMISSION_TYPES } from 'common/enums';

import './UserRole.css';

interface UserRoleProps {
  handleRoleChange: (eventKey: any, event: Object) => void;
  isBusy: boolean;
  isCurrentUser: boolean;
  permissionType: string;
  source?: string;
}

function UserRole({
  handleRoleChange,
  isBusy,
  isCurrentUser,
  permissionType,
  source,
}: UserRoleProps) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (ref.current) {
      if (ref.current.classList.contains('show')) {
        ref.current.classList.remove('show');
      }
    }
  });

  const dropdownLabelStyling =
    source === 'AddUser' ? 'user-role-add-dropdown-label' : 'pr-1';

  const dropdownStyling = source === 'AddUser' ? 'user-role-add-dropdown' : '';

  const isAdministrator =
    (permissionType as PROPERTY_PERMISSION_TYPES) ===
    PROPERTY_PERMISSION_TYPES.ADMIN;
  const dropdownLabel = isAdministrator ? 'Administrator' : 'Editor';

  return (
    <Dropdown onSelect={handleRoleChange} id="userRole">
      <Dropdown.Toggle
        className="ebx-btn-secondary ebx-btn-md ebx-h3 user-role-dropdown"
        bsPrefix="p-2 pl-3"
        disabled={isBusy || isCurrentUser}
      >
        <span className={dropdownLabelStyling}>
          <span>{dropdownLabel}</span>
        </span>
        <ChevronDownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu ref={ref} className={dropdownStyling}>
        <Dropdown.Item eventKey={PROPERTY_PERMISSION_TYPES.ADMIN}>
          <span>Administrator</span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey={PROPERTY_PERMISSION_TYPES.EDITOR}>
          <span>Editor</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserRole;
