/* eslint no-restricted-globals: "off" */

import * as zendesk from 'common/zendesk';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';

import SignInBackground from 'assets/img/ebx-background.png';
import EchoboxLogo from 'components/layout/EchoboxLogo';

import GenericError from './GenericError';

import './Error.css';

function Error({ errorMessage }) {
  return (
    <div className="sign-in__page-height d-flex justify-content-center align-items-center">
      <img
        src={SignInBackground}
        className="ebx-signin-background"
        alt="Sign in background"
      />
      <div className="error-card">
        <div>
          <div className="mb-3 mt-2">
            <EchoboxLogo />
          </div>
          <div className="ebx-card mx-auto my-3">
            <Row>
              <GenericError errorMessage={errorMessage} />
            </Row>
            <Row>
              <Col>
                {/*
              <Button
                className="ebx-btn-primary ebx-btn-lg ebx-h2 w-100 mt-3 pr-3"
                onClick={GoBack}
              >
                Go Back
              </Button>
              */}
                <a href="/signout">
                  <Button className="ebx-btn-primary ebx-btn-lg ebx-h2 w-100 mt-3">
                    Sign Out
                  </Button>
                </a>
                <Button
                  className="ebx-btn-secondary ebx-btn-lg ebx-h2 w-100 mt-3"
                  onClick={() => zendesk.openWidget()}
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="w-100 d-flex align-content-end justify-content-center footer-text py-3 flex-wrap">
          <a className="ebx-a" href="https://www.echobox.com/contact">
            <span>Contact us</span>
          </a>
          <span className="mx-4">&copy; Echobox Ltd</span>
          <a className="ebx-a" href="https://email.echobox.com/">
            <span>Back to sign-in</span>
          </a>
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string,
};

Error.defaultProps = {
  errorMessage:
    'Something went wrong! If the problem persists please contact us ',
};

export default Error;
