import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg';
import Check from 'components/staff/upload/Check';

import * as string from 'common/string';

import { MAXIMUM_UPLOAD_SIZE, SUPPORTED_FORMATS } from 'common/config';

import './Select.css';

function Select({
  canUpload,
  errorMessage,
  handleCancel,
  handleConfirm,
  handleRemove,
  handleSelect,
  handleUpload,
  hasCheckedConfirm,
  selectedFile,
}) {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: SUPPORTED_FORMATS,
    maxFiles: 1,
    maxSize: MAXIMUM_UPLOAD_SIZE,
    multiple: false,
    noClick: true,
    noDrag: !hasCheckedConfirm,
    onDrop: handleSelect,
    preventDropOnDocument: true,
  });
  const [showDropzoneError, setShowDropzoneError] = useState(false);

  const dropzoneProps = getRootProps();

  useEffect(() => {
    const dropzone = dropzoneProps.ref.current;

    const handleDrop = event => {
      if (!hasCheckedConfirm) {
        event.preventDefault();
        setShowDropzoneError(true);
      }
    };

    dropzone?.addEventListener('drop', handleDrop);

    return () => {
      dropzone?.removeEventListener('drop', handleDrop);
    };
  }, [dropzoneProps.ref, hasCheckedConfirm]);

  return (
    <>
      <Modal.Body className="ebx-upload-body px-3 ebx-body-1 d-flex align-items-center flex-wrap ebx-modal-body">
        <div
          className={`select-drag-zone w-100 mb-2${
            isDragActive ? ' dropzone_active' : ''
          }`}
          {...dropzoneProps}
        >
          <div
            className={`dnd-label ebx-h3 ${
              !hasCheckedConfirm ? 'dnd-disabled' : ''
            }`}
          >
            Drag and drop file
          </div>
          <input {...getInputProps()} />
          <Button
            className="ebx-btn-secondary ebx-h2 select-file-btn"
            onClick={open}
            disabled={!hasCheckedConfirm}
          >
            Select .CSV File
          </Button>
        </div>
        <div className="ebx-body-1 ebx-faded-colour">
          CSV - CSV - {string.formatFileSize(MAXIMUM_UPLOAD_SIZE)} max
        </div>
        {showDropzoneError && (
          <div className="ebx-error-colour ebx-h3 d-flex align-items-center mt-1">
            <InfoIcon className="ebx-status-icon mr-1" />
            <span className="mr-1">
              Please tick the check box below before selecting an email quotas
              file.
            </span>
          </div>
        )}
        {selectedFile && (
          <div className="ebx-upload-file p-3 mt-3 w-100">
            <div className="d-flex align-items-center">
              <div className="ebx-h3">{selectedFile.name}</div>
              <Link
                onClick={handleRemove}
                className="remove-file d-inline-flex justify-content-end ml-auto text-decoration-none mr-1"
                type="button"
              >
                <DeleteIcon />
                <VisuallyHidden>Remove</VisuallyHidden>
              </Link>
            </div>
            {errorMessage && (
              <div className="mt-3">
                <div className="ebx-error-colour ebx-h3">
                  <InfoIcon className="ebx-status-icon mr-1" />
                  <span className="mr-1">{errorMessage}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Check
        hasCheckedConfirm={hasCheckedConfirm}
        handleConfirm={event => {
          setShowDropzoneError(false);
          handleConfirm(event);
        }}
      />

      <Modal.Footer className="ebx-modal-footer">
        <div className="mr-auto ml-0">
          <Link
            to="/files/ebx-email-quota-upload-template.csv"
            target="_blank"
            className="ebx-cursor ebx-link ebx-h3"
            download
          >
            <span>Download .CSV template</span>
          </Link>
        </div>
        <Button
          onClick={handleCancel}
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
        >
          Cancel
        </Button>
        <Button
          disabled={!canUpload}
          onClick={handleUpload}
          className="ebx-btn-primary ebx-btn-md ebx-h3"
        >
          Upload
        </Button>
      </Modal.Footer>
    </>
  );
}

Select.propTypes = {
  canUpload: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  hasCheckedConfirm: PropTypes.bool.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

Select.defaultProps = {
  errorMessage: null,
  selectedFile: null,
};

export default Select;
