import React from 'react';

import type { LoadingStatus } from 'components/campaigns/editions/SmartCurationLoadingModal';
import SmartCurationLoadingStatus from 'components/campaigns/editions/SmartCurationLoadingStatus';

interface SmartCurationLoadingSubstepProps {
  children: React.ReactNode;
  status: LoadingStatus;
}

function SmartCurationLoadingSubstep({
  status,
  children,
}: SmartCurationLoadingSubstepProps) {
  const className =
    status === 'loading'
      ? 'ebx-h3 ebx-title-colour'
      : 'ebx-body-1 ebx-faded-colour';

  return (
    <div className={`mb-2 d-flex ${className}`}>
      {children}
      <SmartCurationLoadingStatus status={status} type="substep" />
    </div>
  );
}

export default SmartCurationLoadingSubstep;
