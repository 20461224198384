import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface GetMarketingTemplateArgs {
  templateURN: string;
}

export default async function getMarketingTemplate({
  templateURN,
}: GetMarketingTemplateArgs) {
  const guid = metrics.start('getMarketingTemplate');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/marketingtemplates/${templateURN}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
  };
  logger.info(`API:getMarketingTemplate /marketingtemplates/${templateURN}`);

  try {
    const response = await axios(config);
    metrics.end('getMarketingTemplate', guid);
    return (
      response?.data?.templateData[0] ?? {
        templateURN,
        campaignURN: null,
        content: '',
        name: null,
      }
    );
  } catch (error) {
    metrics.fail('getMarketingTemplate', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getMarketingTemplate',
            args: { templateURN },
          })
        : error;
    throw apiError;
  }
}
