import {
  ArrowDownIcon,
  ArrowUpIcon,
  Flex,
  Heading,
  InfoIcon,
  Spinner,
  Text,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as maths from 'common/maths';
import { Metrics } from 'common/types';

const styles = {
  bg: {
    nameSize: 'h4',
    percentageSize: 'h1',
    iconSize: 6,
    isLoadingSize: 16,
  },
  sm: {
    nameSize: 'h5',
    percentageSize: 'h2',
    iconSize: 4,
    isLoadingSize: 14,
  },
} as const;

interface MetricCardProps {
  size?: keyof typeof styles;
  label: string;
  tooltip: string;
  metric: Metrics | null;
  isPositive?: boolean;
}

function MetricCard({
  size = 'bg',
  label,
  tooltip,
  metric,
  isPositive = true,
}: MetricCardProps) {
  const metricSize = styles[size];

  // Loading state. If metric is null then data is still getting fetched.
  if (!metric) {
    return (
      <Flex flexDir="column">
        <MetricHeader size={size} label={label} tooltip={tooltip} />
        <Flex height={metricSize.isLoadingSize}>
          <Spinner size="md" color="gray.600" />
        </Flex>
      </Flex>
    );
  }

  // No data state. We consider that we don't have enough data if the metric has a null value or if the value isn't valid (infinite / NaN values)
  if (metric.value === null || !isFinite(metric.value)) {
    return (
      <Flex flexDir="column">
        <MetricHeader size={size} label={label} tooltip={tooltip} />
        <Flex height={metricSize.isLoadingSize}>
          <Heading
            variant={metricSize.nameSize}
            fontWeight="normal"
            color="gray.600"
          >
            No data
          </Heading>
        </Flex>
      </Flex>
    );
  }

  // Normal display state
  return (
    <Flex flexDir="column">
      <MetricHeader size={size} label={label} tooltip={tooltip} />
      <Flex alignItems="center" gap={2} mb={2}>
        <Heading variant={metricSize.percentageSize}>
          {metric.isPercentage
            ? maths.formatPercentage(metric.value, 2)
            : metric.value.toLocaleString()}
        </Heading>
        {metric.change !== null && (
          <PercentageChange
            value={metric.change}
            textSize={size}
            iconSize={metricSize.iconSize}
            isPositive={isPositive}
          />
        )}
      </Flex>
      <Text size="xs" color="gray.500">
        {metric.moreInfoValue?.toLocaleString()} {metric.moreInfoName}
      </Text>
    </Flex>
  );
}

interface MetricHeaderProps {
  size: string;
  label: string;
  tooltip: string;
}

function MetricHeader({ size, label, tooltip }: MetricHeaderProps) {
  // Protects the tooltips for these metrics from extending outside the screen.
  const tooltipPlacement =
    label === 'Unsubscribe rate' || label === 'Soft bounce rate'
      ? 'top-end'
      : 'top';

  return (
    <Flex alignItems="center" gap={1} mb={4}>
      <Heading variant={size === 'bg' ? 'h4' : 'h5'} color="gray.600">
        {label}
      </Heading>
      <Tooltip label={tooltip} placement={tooltipPlacement}>
        <InfoIcon color="gray.500" />
      </Tooltip>
    </Flex>
  );
}

interface PercentageChangeProps {
  value: number;
  textSize: string;
  iconSize: number;
  isPositive: boolean;
}

function PercentageChange({
  value,
  iconSize,
  textSize,
  isPositive,
}: PercentageChangeProps) {
  const positiveColour = isPositive ? 'green.600' : 'red.600';
  const negativeColour = isPositive ? 'red.600' : 'green.600';

  const rateOfChange = {
    positive: {
      color: positiveColour,
      arrow: <ArrowUpIcon size={iconSize} color={positiveColour} />,
    },
    negative: {
      color: negativeColour,
      arrow: <ArrowDownIcon size={iconSize} color={negativeColour} />,
    },
    neutral: { color: 'gray.500', arrow: null },
  };

  const calculatePercentageType = () => {
    switch (Math.sign(value)) {
      case 1:
        return rateOfChange.positive;
      case -1:
        return rateOfChange.negative;
      default:
        return rateOfChange.neutral;
    }
  };

  const percentageType = calculatePercentageType();

  // If the 30-60 day value is 0 then don't render the delta value as dividing by 0 has not yet been figured out..
  if (!isFinite(value)) {
    return null;
  }

  return (
    <Flex alignItems="center">
      {percentageType.arrow}
      <Text color={percentageType.color} size={textSize === 'bg' ? 'md' : 'sm'}>
        {maths.formatPercentage(value, 2)}
      </Text>
    </Flex>
  );
}

export default MetricCard;
