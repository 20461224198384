import React, { createContext, useReducer } from 'react';
import { Form } from 'react-bootstrap';

export const FooterCheckboxContext = createContext(false);

export interface FooterCheckPropsBox {
  children?: React.ReactNode;
  className?: string;
  id: string;
  label: string;
  name: string;
  value?: boolean;
  onCheck?: () => void;
  onUncheck?: () => void;
}

function FooterCheckbox({
  children,
  className,
  id,
  label,
  name,
  value = false,
  onCheck = () => {},
  onUncheck = () => {},
}: FooterCheckPropsBox) {
  const [checked, toggleChecked] = useReducer(previous => !previous, value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!checked) onCheck();
    else onUncheck();
    toggleChecked();
  };

  return (
    <FooterCheckboxContext.Provider value={checked}>
      <Form.Check type="checkbox" id={id} className={className}>
        <Form.Check.Input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleChange}
        />
        <div>
          <Form.Check.Label className="ebx-h3 ebx-title-colour">
            {label}
          </Form.Check.Label>
          {children}
        </div>
      </Form.Check>
    </FooterCheckboxContext.Provider>
  );
}

FooterCheckbox.defaultProps = {
  children: null,
  className: null,
  value: null,
  onCheck: null,
  onUncheck: null,
};

export default FooterCheckbox;
