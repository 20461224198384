import { Col, Nav, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import ExportSubscribers from 'components/settings/campaigns/apps/ExportSubscribers';
import ImportExportHelp from 'components/settings/campaigns/apps/ImportExportHelp';
import ImportSubscribers from 'components/settings/campaigns/apps/ImportSubscribers';
import { Title } from 'components/settings/campaigns/common';
import useFragmentParameters from 'hooks/useFragmentParameters';

import { APPS_AND_INTEGRATIONS } from 'common/constants';

import './Connections.css';

const TABS = {
  IMPORT: '#import',
  EXPORT: '#export',
};

function Connections() {
  const { app } = useParams();
  const fragmentParams = useFragmentParameters();
  const activeTab =
    fragmentParams.get('export') !== null ? TABS.EXPORT : TABS.IMPORT;

  const appMatches = APPS_AND_INTEGRATIONS.filter(entry => entry.key === app);
  if (appMatches.length !== 1) {
    return null;
  }
  const appDetails = appMatches[0];

  return (
    <Row className="settings-connections w-100 m-0">
      <Col xs={12} sm={8}>
        <Title className="app-name mb-4">{appDetails.name}</Title>
        <Nav
          defaultActiveKey={
            appDetails.canImportSubs ? TABS.IMPORT : TABS.EXPORT
          }
          variant="tabs"
          className="d-flex w-100 mb-4"
        >
          {appDetails.canImportSubs && (
            <Nav.Link href={TABS.IMPORT}>
              <Title className="mb-0">Import subscribers</Title>
            </Nav.Link>
          )}
          {appDetails.canExportSubs && (
            <Nav.Link href={TABS.EXPORT}>
              <Title className="mb-0">Export subscribers</Title>
            </Nav.Link>
          )}
        </Nav>
        {activeTab === TABS.IMPORT && (
          <ImportSubscribers appName={appDetails.name} />
        )}
        {activeTab === TABS.EXPORT && (
          <ExportSubscribers appName={appDetails.name} />
        )}
      </Col>
      <Col xs={12} sm={4}>
        <ImportExportHelp activeTab={activeTab} appName={appDetails.name} />
      </Col>
    </Row>
  );
}

export default Connections;
