import axios, { AxiosRequestConfig } from 'axios';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';

interface GetEditionAnalyticsArgs {
  editionURNs: string[];
}

export default async function getEditionAnalytics({
  editionURNs,
}: GetEditionAnalyticsArgs) {
  if (isUndefined(editionURNs)) {
    throw new ReferenceError(`Missing parameter editionURNs`);
  }

  const guid = metrics.start('getEditionAnalytics');

  const url = `${apiBaseURL(
    APIS.OPTIMISATION_SERVICE,
    ''
  )}/editions/export?editionURNs=${editionURNs}`;

  const config: AxiosRequestConfig = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getEditionAnalytics /editions/export`);

  try {
    const { data } = await axios(config);
    metrics.end('getEditionAnalytics', guid);
    return data.editionReportExportS3Link;
  } catch (error) {
    metrics.fail('getEditionAnalytics', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getEditionAnalytics',
            args: { editionURNs },
            ignore403Errors: true,
          })
        : error;
    throw apiError;
  }
}
