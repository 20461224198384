import { HStack, Navbar, NavRight } from '@ebx-ui/ebx-ui-component-library-sdk';

import HeaderLeft from 'components/header/HeaderLeft';
import Settings from 'components/header/Settings';
import UserDetailsMenu from 'components/header/UserDetailsMenu';
import Zendesk from 'components/header/Zendesk';

export default function Header() {
  return (
    <Navbar leftNav={<HeaderLeft />}>
      <NavRight>
        <HStack spacing="6">
          <HStack spacing="1">
            <Zendesk />
            <Settings />
          </HStack>
          <UserDetailsMenu />
        </HStack>
      </NavRight>
    </Navbar>
  );
}
