import {
  Flex,
  FormControl,
  Heading,
  Toggle,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import DataSourceDropdown from 'components/settings/campaigns/DataSourceDropdown';

import {
  DESCRIPTION_DATA_SOURCES,
  IMAGE_DATA_SOURCES,
  TITLE_DATA_SOURCES,
} from 'common/constants';
import { MEDIA_DATA_SOURCE_TYPES } from 'common/enums';

interface DataSourcesProps {
  useCustomDataSources: boolean;
  setUseCustomDataSources: (value: boolean) => void;
  titleDataSource: MEDIA_DATA_SOURCE_TYPES;
  setTitleDataSource: (value: MEDIA_DATA_SOURCE_TYPES) => void;
  descriptionDataSource: MEDIA_DATA_SOURCE_TYPES;
  setDescriptionDataSource: (value: MEDIA_DATA_SOURCE_TYPES) => void;
  imageDataSource: MEDIA_DATA_SOURCE_TYPES;
  setImageDataSource: (value: MEDIA_DATA_SOURCE_TYPES) => void;
}

function DataSources({
  useCustomDataSources,
  setUseCustomDataSources,
  titleDataSource,
  setTitleDataSource,
  descriptionDataSource,
  setDescriptionDataSource,
  imageDataSource,
  setImageDataSource,
}: DataSourcesProps) {
  const handleCustomDataSourcesChange = () => {
    setUseCustomDataSources(!useCustomDataSources);
  };

  return (
    <FormControl w="324px">
      <Flex gap={3} alignItems="center">
        <Toggle
          isChecked={useCustomDataSources}
          labelPosition="right"
          onChange={handleCustomDataSourcesChange}
        />
        <Heading variant="h5">Use custom data sources</Heading>
      </Flex>
      {useCustomDataSources && (
        <Flex direction="column" gap={2} mt={5}>
          <DataSourceDropdown
            name="Title"
            options={TITLE_DATA_SOURCES}
            value={titleDataSource}
            onChange={setTitleDataSource}
          />
          <DataSourceDropdown
            name="Description"
            options={DESCRIPTION_DATA_SOURCES}
            value={descriptionDataSource}
            onChange={setDescriptionDataSource}
          />
          <DataSourceDropdown
            name="Image"
            options={IMAGE_DATA_SOURCES}
            value={imageDataSource}
            onChange={setImageDataSource}
          />
        </Flex>
      )}
    </FormControl>
  );
}

export default DataSources;
