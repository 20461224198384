/* eslint import/prefer-default-export: "off" */
/* eslint no-useless-catch: "off" */

import { Auth, Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import * as campaigns from 'common/campaigns';
import * as properties from 'common/properties';
import { isNull } from 'common/utility';
import { changingCampaign, changingProperty } from 'state/changingEntity';
import { types } from 'store/types';

import {
  GLOBAL_INFO_STATES,
  HUB_CHANNELS,
  HUB_EVENTS,
  ROUTE_REDIRECTIONS,
} from 'common/constants';

const useActions = (state, dispatch) => {
  const history = useHistory();
  const setChangingCampaign = useSetRecoilState(changingCampaign);
  const setChangingProperty = useSetRecoilState(changingProperty);

  const changeCampaign = (campaignURN, propertyURN) => {
    const changeCampaignComplete = data => {
      const { event } = data.payload;
      if (event === HUB_EVENTS.GLOBAL_INFO.LOADED) {
        Hub.remove(HUB_CHANNELS.GLOBAL_INFO, changeCampaignComplete);
        setChangingCampaign(false);
        history.push(ROUTE_REDIRECTIONS.HOME);
      }
    };
    Hub.listen(HUB_CHANNELS.GLOBAL_INFO, changeCampaignComplete);
    refreshGlobalInfo(GLOBAL_INFO_STATES.SELECTING_CAMPAIGN, {
      campaignURN,
      propertyURN,
    });
  };

  const changeProperty = propertyURN => {
    const changePropertyComplete = data => {
      const { event } = data.payload;
      if (event === HUB_EVENTS.GLOBAL_INFO.LOADED) {
        Hub.remove(HUB_CHANNELS.GLOBAL_INFO, changePropertyComplete);
        setChangingProperty(false);
        history.push(ROUTE_REDIRECTIONS.HOME);
      }
    };
    Hub.listen(HUB_CHANNELS.GLOBAL_INFO, changePropertyComplete);
    refreshGlobalInfo(GLOBAL_INFO_STATES.SELECTING_PROPERTY, { propertyURN });
  };

  const refreshGlobalInfo = (
    reasonCode,
    { propertyURN = null, campaignURN = null, errorEvent = null } = {}
  ) => {
    /* Do NOT refresh if some kind of global info update is already in progress */
    if (
      state.globalInfo.state !== GLOBAL_INFO_STATES.INITIALISED &&
      state.globalInfo.state !== GLOBAL_INFO_STATES.READY
    ) {
      return;
    }

    dispatch({ type: types.SET_GLOBAL_INFO_STATE, payload: reasonCode });

    let initialPropertyURN = propertyURN;
    let initialCampaignURN = campaignURN;
    if (!isNull(campaignURN) && isNull(propertyURN)) {
      initialPropertyURN = campaigns.getCampaignPropertyURN(
        campaignURN,
        state.globalInfo
      );
    }
    /* Preserve existing propertyURN and campaignURN */
    if (reasonCode === GLOBAL_INFO_STATES.REFRESHING) {
      try {
        initialPropertyURN = window.globalInfo.current.propertyURN;
        initialCampaignURN = window.globalInfo.current.campaignURN;
      } catch (error1) {
        try {
          const sessionInfo = sessionStorage.getItem('globalInfo');
          if (sessionInfo) {
            const globalInfo = JSON.parse(sessionInfo);
            initialPropertyURN = globalInfo.current.propertyURN;
            initialCampaignURN = globalInfo.current.campaignURN;
          }
        } catch (error2) {
          /* All hope is lost... */
        }
      }
    }

    const fetchGlobalInfo = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const clientServiceToken = authentication.getClientServiceToken();
        const userDetails = authentication.getUserDetails(
          authenticatedUser,
          clientServiceToken
        );
        const globalInfo = {
          current: {
            propertyURN: initialPropertyURN,
            campaignURN: initialCampaignURN,
          },
          properties: {},
          user: userDetails,
          state: GLOBAL_INFO_STATES.READY,
        };
        globalInfo.properties = await API.getPropertiesAndCampaigns(
          userDetails,
          globalInfo.current
        );
        const newPropertyURN =
          !isNull(initialPropertyURN) &&
          initialPropertyURN in globalInfo.properties
            ? initialPropertyURN
            : properties.setCurrentPropertyURN(globalInfo, initialPropertyURN);
        const newCampaignURN =
          !isNull(initialCampaignURN) &&
          initialCampaignURN in globalInfo.properties[newPropertyURN].campaigns
            ? initialCampaignURN
            : campaigns.setCurrentCampaignURN(globalInfo, newPropertyURN);
        globalInfo.current = {
          propertyURN: newPropertyURN,
          campaignURN: newCampaignURN,
        };
        if (newCampaignURN != null) {
          const campaignSettings = await API.getCampaignSettings({
            campaignURN: newCampaignURN,
          });
          globalInfo.properties[newPropertyURN].campaigns[
            newCampaignURN
          ].campaignSettings = campaignSettings.campaignSettings;
          /* Attempt to get BIMI image */
          try {
            const newCampaign =
              globalInfo.properties[newPropertyURN].campaigns[newCampaignURN];
            if (!('bimiImageUrl' in newCampaign)) {
              const senderDomain = newCampaign.fromEmail.split('@')[1];
              const bimiImageResponse = await fetch(
                `/resolveBIMI?domain=${senderDomain}`
              );
              const bimiImage = await bimiImageResponse.json();
              newCampaign.bimiImageUrl = bimiImage.image;
            }
          } catch {
            /* BIMI image couldn't be obtained, carry on without it */
          }
        }

        /* Copy to global scope */
        window.globalInfo = globalInfo;
        /* Copy to session storage */
        sessionStorage.setItem('globalInfo', JSON.stringify(globalInfo));
        /* Copy to global state */
        dispatch({ type: types.SET_GLOBAL_INFO, payload: globalInfo });
        /* Signal that process is complete */
        Hub.dispatch(HUB_CHANNELS.GLOBAL_INFO, {
          event: HUB_EVENTS.GLOBAL_INFO.LOADED,
          globalInfo,
        });
      } catch (error) {
        if (errorEvent) {
          Hub.dispatch(errorEvent.channel, { ...errorEvent.payload, error });
        }
      }
    };

    fetchGlobalInfo();
  };

  const setGlobalInfo = globalInfo => {
    dispatch({ type: types.SET_GLOBAL_INFO, payload: globalInfo });
  };

  const setStatusPageNotifications = statusPageNotifications => {
    dispatch({
      type: types.SET_STATUS_PAGE_NOTIFICATIONS,
      payload: statusPageNotifications,
    });
  };

  return {
    changeCampaign,
    changeProperty,
    refreshGlobalInfo,
    setGlobalInfo,
    setStatusPageNotifications,
  };
};

export { useActions };
