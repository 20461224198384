import { Link } from 'react-router-dom';

import SettingsPanel from 'components/settings/campaigns/SettingsPanel';

import '../../Campaign.css';

export default function Branding() {
  const getSettingsPath = subpage =>
    `/settings/campaigns/allcampaigns/branding/${subpage}`;

  return (
    <>
      <div className="campaign-title ebx-title-colour">Branding</div>
      <Link className="link-unstyled" to={getSettingsPath('general')}>
        <SettingsPanel title="General styling" />
      </Link>
      <Link className="link-unstyled" to={getSettingsPath('success')}>
        <SettingsPanel title="Success" />
      </Link>
    </>
  );
}
