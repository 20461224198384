import {
  ChevronButton,
  CloseIcon,
  Dropdown,
  Flex,
  Input,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import * as API from 'api/api';

import * as logger from 'common/logger';
import { SectionSyndFeed, SyndFeedData } from 'common/types';

interface SyndfeedDropdownProps {
  onAdd: (item: SectionSyndFeed) => void;
  onDelete: (deleteItem: SectionSyndFeed) => void;
  selected: SectionSyndFeed[];
  propertyURN: string;
}

export default function SyndfeedDropdown({
  onAdd,
  onDelete,
  selected,
  propertyURN,
}: SyndfeedDropdownProps) {
  const [syndfeeds, setSyndfeeds] = useState<SectionSyndFeed[]>([]);

  const handleAdd = (feed: SectionSyndFeed) => {
    onAdd(feed);
    syndfeeds.splice(syndfeeds.indexOf(feed), 1);
  };

  const handleRemove = (item: SectionSyndFeed) => {
    syndfeeds.push(item);
    onDelete(item);
  };

  // Get Property level RSS Feeds
  useEffect(() => {
    const getData = async () => {
      try {
        const syndfeedResponse = await API.getSyndfeeds({ propertyURN });
        // Convert property level RSS feeds to same format as section level feeds
        const convertedFeeds: SectionSyndFeed[] =
          syndfeedResponse?.syndFeeds.map((item: SyndFeedData) => ({
            syndFeedURN: item.syndFeedURN,
          }));

        if (selected.length > 0) {
          const filteredFeeds: SectionSyndFeed[] = [];
          convertedFeeds.forEach(propertyFeed => {
            if (
              !selected.some(
                selectedFeed =>
                  selectedFeed.syndFeedURN === propertyFeed.syndFeedURN
              )
            ) {
              filteredFeeds.push(propertyFeed);
            }
          });
          setSyndfeeds(filteredFeeds);
        } else {
          setSyndfeeds(convertedFeeds);
        }
      } catch (error) {
        logger.error(`Content Settings Syndfeed: ${error}`);
      }
    };
    if (propertyURN) {
      getData();
    }
  }, [propertyURN, selected]);

  // Trim syndfeed for readability
  const trimFeed = (feed: SectionSyndFeed) => {
    const trimmedFeed = feed.syndFeedURN.replace('urn:syndfeed:url:', '');
    return trimmedFeed;
  };

  return (
    <>
      {selected && (
        <>
          {selected.map(item => (
            <Flex gap={2} alignItems="center" mb={2}>
              <Input
                value={trimFeed(item)}
                isReadOnly
                _hover={{ cursor: 'default' }}
              />
              <CloseIcon
                size={5}
                onClick={() => handleRemove(item)}
                cursor="pointer"
              />
            </Flex>
          ))}
        </>
      )}

      {syndfeeds && syndfeeds?.length !== 0 ? (
        <Dropdown matchWidth>
          <Dropdown.Button
            as={ChevronButton}
            variant="secondary"
            width="100%"
            textAlign="left"
            chevronColor="gray.600"
          >
            Select RSS feed or Sitemap
          </Dropdown.Button>
          <Dropdown.List maxH="230px">
            {syndfeeds.map(option => (
              <Dropdown.Item
                onClick={() => handleAdd(option)}
                key={option.syndFeedURN}
              >
                {trimFeed(option)}
              </Dropdown.Item>
            ))}
          </Dropdown.List>
        </Dropdown>
      ) : (
        <Input
          value="No RSS feeds / Sitemaps found..."
          fontWeight="medium"
          isReadOnly
          _hover={{ cursor: 'default' }}
        />
      )}
    </>
  );
}
