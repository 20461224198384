/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';

import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { BackLink, LabelInput } from '../../common';

function ContentSetting({
  settingType,
  setSettingsPanel,
  titleCopy,
  bodyCopy,
  verificationTitleCopy,
  verificationBodyCopy,
  verificationBodyStart,
  verificationBodyEnd,
  verificationRedirectMessage,
  verificationCancelButtonLabel,
  setVerificationBodyStart,
  setVerificationBodyEnd,
  successTitleCopy,
  successBodyCopy,
  successBodyStart,
  successBodyEnd,
  successRedirectMessage,
  successCancelButtonLabel,
  setSuccessBodyStart,
  setSuccessBodyEnd,
  unsubscribeTitleCopy,
  unsubscribeBodyCopy,
  unsubscribeRedirectMessage,
  unsubscribeCancelButtonLabel,
  settings,
  isEmailBoxChecked,
  setIsEmailBoxChecked,
  isEmail,
}) {
  const handleBodyCopyChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
      settingKey: 'bodyCopy',
      settingValue: event.target.value,
    });
  };

  const handleTitleCopyChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_PAGE,
      settingKey: 'titleCopy',
      settingValue: event.target.value,
    });
  };

  // When typing in description, update verificationbodystart
  const handleVerificationBodyStartChange = event => {
    setVerificationBodyStart(event.target.value);
  };

  // When clicking on checkbox hide email & additional description
  const handleEmailIncluded = () => {
    setIsEmailBoxChecked(!isEmailBoxChecked);
  };

  // When typing in description 2, update verificationbodyend
  const handleVerificationBodyEndChange = event => {
    setVerificationBodyEnd(event.target.value);
  };

  useEffect(() => {
    const handleVerificationBodyCopyChange = () => {
      let newVerificationBodyString = '';

      if (isEmailBoxChecked) {
        newVerificationBodyString = `${verificationBodyStart}{emailAddress}${verificationBodyEnd}`;
      } else {
        newVerificationBodyString = verificationBodyStart;
      }

      if (
        verificationBodyCopy &&
        newVerificationBodyString &&
        newVerificationBodyString !== verificationBodyCopy
      ) {
        settings.setCampaignSettingValue({
          settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
          settingKey: 'verificationBodyCopy',
          settingValue: newVerificationBodyString,
        });
      }
    };
    handleVerificationBodyCopyChange();
  }, [verificationBodyStart, verificationBodyEnd, isEmailBoxChecked]);

  const handleVerificationTitleCopyChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
      settingKey: 'verificationTitleCopy',
      settingValue: event.target.value,
    });
  };

  const handleVerificationRedirectMessageChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
      settingKey: 'redirectMessage',
      settingValue: event.target.value,
    });
  };

  const handleVerificationCancelButtonLabelChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
      settingKey: 'cancelButtonLabel',
      settingValue: event.target.value,
    });
  };

  // When typing in description, update verificationbodystart
  const handleSuccessBodyStartChange = event => {
    setSuccessBodyStart(event.target.value);
  };

  // When typing in description 2, update verificationbodyend
  const handleSuccessBodyEndChange = event => {
    setSuccessBodyEnd(event.target.value);
  };

  useEffect(() => {
    const handleSuccessBodyCopyChange = () => {
      let newSuccessBodyString = '';

      if (isEmailBoxChecked) {
        newSuccessBodyString = `${successBodyStart}{emailAddress}${successBodyEnd}`;
      } else {
        newSuccessBodyString = successBodyStart;
      }
      if (
        successBodyCopy &&
        newSuccessBodyString &&
        newSuccessBodyString !== successBodyCopy
      ) {
        settings.setCampaignSettingValue({
          settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
          settingKey: 'confirmationBodyCopy',
          settingValue: newSuccessBodyString,
        });
      }
    };
    handleSuccessBodyCopyChange();
  }, [successBodyStart, successBodyEnd, isEmailBoxChecked]);

  const handleSuccessTitleCopyChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
      settingKey: 'confirmationTitleCopy',
      settingValue: event.target.value,
    });
  };

  const handleSuccessRedirectMessageChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
      settingKey: 'redirectMessage',
      settingValue: event.target.value,
    });
  };

  const handleSuccessCancelButtonLabelChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
      settingKey: 'cancelButtonLabel',
      settingValue: event.target.value,
    });
  };

  const handleUnsubscribeTitleCopyChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
      settingKey: 'unsubscribeTitleCopy',
      settingValue: event.target.value,
    });
  };

  const handleUnsubscribeBodyCopyChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
      settingKey: 'unsubscribeBodyCopy',
      settingValue: event.target.value,
    });
  };

  const handleUnsubscribeRedirectMessageChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
      settingKey: 'redirectMessage',
      settingValue: event.target.value,
    });
  };

  const handleUnsubscribeCancelButtonLabelChange = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
      settingKey: 'cancelButtonLabel',
      settingValue: event.target.value,
    });
  };

  return (
    <div>
      {isEmail && (
        <Button
          variant="link"
          className="m-0 p-0"
          onClick={() =>
            setSettingsPanel(
              BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE.OPTIONS
            )
          }
        >
          <BackLink linkText="Back to Subscribe" />
        </Button>
      )}
      <div className="ebx-h1 mt-3 mb-4">Content</div>
      {settingType === BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_PAGE && (
        <>
          <LabelInput
            inputLabel="Header"
            inputValue={titleCopy}
            handleInputChange={handleTitleCopyChange}
          />
          <LabelInput
            inputLabel="Description"
            inputType="textarea"
            inputValue={bodyCopy}
            handleInputChange={handleBodyCopyChange}
            rowAmount={3}
          />
        </>
      )}

      {settingType ===
        BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_VERIFICATION_PAGE && (
        <>
          <LabelInput
            inputLabel="Header"
            inputValue={verificationTitleCopy}
            handleInputChange={handleVerificationTitleCopyChange}
          />
          <LabelInput
            inputLabel="Description"
            inputType="textarea"
            inputValue={verificationBodyStart}
            handleInputChange={handleVerificationBodyStartChange}
            rowAmount={3}
          />
          {verificationBodyCopy && (
            <div className="mb-4">
              <Button variant="link" type="button" className="m-0 p-0">
                <Form.Check
                  id="staff-mode-checkbox"
                  name="staff-mode"
                  type="checkbox"
                  label="Subscriber email address"
                  className="ebx-h3 staff-mode__checkbox"
                  onChange={handleEmailIncluded}
                  value={isEmailBoxChecked}
                  checked={isEmailBoxChecked}
                />
              </Button>
            </div>
          )}
          {isEmailBoxChecked && (
            <LabelInput
              inputLabel="Additional Description"
              inputType="textarea"
              inputValue={verificationBodyEnd}
              handleInputChange={handleVerificationBodyEndChange}
              rowAmount={3}
            />
          )}
          <LabelInput
            inputLabel="Redirect Message"
            inputValue={verificationRedirectMessage}
            handleInputChange={handleVerificationRedirectMessageChange}
          />
          <LabelInput
            inputLabel="Cancel Button Label"
            inputValue={verificationCancelButtonLabel}
            handleInputChange={handleVerificationCancelButtonLabelChange}
          />
        </>
      )}

      {settingType ===
        BRANDING_SETTINGS_SUB_PAGES.SUBSCRIPTION_SUCCESS_PAGE && (
        <>
          <LabelInput
            inputLabel="Header"
            inputValue={successTitleCopy}
            handleInputChange={handleSuccessTitleCopyChange}
          />
          {/* <LabelInput
            inputLabel="Description"
            inputType="textarea"
            inputValue={successBodyCopy}
            handleInputChange={handleSuccessBodyCopyChange}
            rowAmount={3}
          /> */}
          <LabelInput
            inputLabel="Description"
            inputType="textarea"
            inputValue={successBodyStart}
            handleInputChange={handleSuccessBodyStartChange}
            rowAmount={3}
          />
          {successBodyCopy && (
            <div className="mb-4">
              <Button variant="link" type="button" className="m-0 p-0">
                <Form.Check
                  id="staff-mode-checkbox"
                  name="staff-mode"
                  type="checkbox"
                  label="Subscriber email address"
                  className="ebx-h3 staff-mode__checkbox"
                  onChange={handleEmailIncluded}
                  value={isEmailBoxChecked}
                  checked={isEmailBoxChecked}
                />
              </Button>
            </div>
          )}
          {isEmailBoxChecked && (
            <LabelInput
              inputLabel="Additional Description"
              inputType="textarea"
              inputValue={successBodyEnd}
              handleInputChange={handleSuccessBodyEndChange}
              rowAmount={3}
            />
          )}
          <LabelInput
            inputLabel="Redirect Message"
            inputValue={successRedirectMessage}
            handleInputChange={handleSuccessRedirectMessageChange}
          />
          <LabelInput
            inputLabel="Cancel Button Label"
            inputValue={successCancelButtonLabel}
            handleInputChange={handleSuccessCancelButtonLabelChange}
          />
        </>
      )}

      {settingType === BRANDING_SETTINGS_SUB_PAGES.UNSUBSCRIPTION_PAGE && (
        <>
          <LabelInput
            inputLabel="Header"
            inputValue={unsubscribeTitleCopy}
            handleInputChange={handleUnsubscribeTitleCopyChange}
          />
          <LabelInput
            inputLabel="Description"
            inputType="textarea"
            inputValue={unsubscribeBodyCopy}
            handleInputChange={handleUnsubscribeBodyCopyChange}
            rowAmount={3}
          />
          <LabelInput
            inputLabel="Redirect Message"
            inputValue={unsubscribeRedirectMessage}
            handleInputChange={handleUnsubscribeRedirectMessageChange}
          />
          <LabelInput
            inputLabel="Cancel Button Label"
            inputValue={unsubscribeCancelButtonLabel}
            handleInputChange={handleUnsubscribeCancelButtonLabelChange}
          />
        </>
      )}

      {/* {successBodyCopy && (
        <div>
          <FormLabel className="ebx-h3 mb-1">Description</FormLabel>
          <FormControl
            as="textarea"
            rows={3}
            name="successBodyCopy"
            id="successBodyCopy"
            required
            className="ebx-input ebx-body-1"
            type="text"
            value={successBodyCopy}
            onChange={handleSuccessBodyCopyChange}
            autoComplete="off"
          />
        </div>
      )} */}
      {/* {unsubscribeBodyCopy && (
        <div>
          <FormLabel className="ebx-h3 mb-1">Description</FormLabel>
          <FormControl
            as="textarea"
            rows={3}
            name="unsubscribeBodyCopy"
            id="unsubscribeBodyCopy"
            required
            className="ebx-input ebx-body-1"
            type="text"
            value={unsubscribeBodyCopy}
            onChange={handleUnsubscribeBodyCopyChange}
            autoComplete="off"
          />
        </div>
      )} */}
    </div>
  );
}

ContentSetting.propTypes = {
  settingType: PropTypes.string.isRequired,
  setSettingsPanel: PropTypes.func,
  titleCopy: PropTypes.string,
  bodyCopy: PropTypes.string,
  verificationTitleCopy: PropTypes.string,
  verificationBodyCopy: PropTypes.string,
  verificationBodyStart: PropTypes.string,
  verificationBodyEnd: PropTypes.string,
  verificationRedirectMessage: PropTypes.string,
  verificationCancelButtonLabel: PropTypes.string,
  setVerificationBodyStart: PropTypes.func,
  setVerificationBodyEnd: PropTypes.func,
  successTitleCopy: PropTypes.string,
  successBodyCopy: PropTypes.string,
  successBodyStart: PropTypes.string,
  successBodyEnd: PropTypes.string,
  successRedirectMessage: PropTypes.string,
  successCancelButtonLabel: PropTypes.string,
  setSuccessBodyStart: PropTypes.func,
  setSuccessBodyEnd: PropTypes.func,
  unsubscribeTitleCopy: PropTypes.string,
  unsubscribeBodyCopy: PropTypes.string,
  unsubscribeRedirectMessage: PropTypes.string,
  unsubscribeCancelButtonLabel: PropTypes.string,
  settings: PropTypes.node.isRequired,
  isEmailBoxChecked: PropTypes.bool,
  setIsEmailBoxChecked: PropTypes.func,
  isEmail: PropTypes.bool,
};

ContentSetting.defaultProps = {
  setSettingsPanel: null,
  titleCopy: '',
  bodyCopy: '',
  verificationTitleCopy: '',
  verificationBodyCopy: '',
  verificationBodyStart: '',
  verificationBodyEnd: '',
  verificationRedirectMessage: '',
  verificationCancelButtonLabel: '',
  setVerificationBodyStart: null,
  setVerificationBodyEnd: null,
  successTitleCopy: '',
  successBodyCopy: '',
  successBodyStart: '',
  successBodyEnd: '',
  successRedirectMessage: '',
  successCancelButtonLabel: '',
  setSuccessBodyStart: null,
  setSuccessBodyEnd: null,
  unsubscribeTitleCopy: '',
  unsubscribeBodyCopy: '',
  unsubscribeRedirectMessage: '',
  unsubscribeCancelButtonLabel: '',
  isEmailBoxChecked: false,
  setIsEmailBoxChecked: null,
  isEmail: false,
};

export default ContentSetting;
