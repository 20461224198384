/* eslint dot-notation: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-use-before-define: "off" */

/* global mixpanel */

import {
  PRODUCT_USER_TYPE_MAP,
  PROPERTY_SETTING_TYPES,
} from 'common/constants';
import { USER_TYPES } from 'common/enums';

import * as authentication from 'common/authentication';
import * as campaigns from 'common/campaigns';
import * as globalinfo from 'common/globalinfo';
import * as properties from 'common/properties';
import * as users from 'common/users';
import { isDefined, isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export { setUserDetails, track };

const getPropertyTrackingDetails = globalInfo => {
  const propertySettings = properties.getCurrentPropertySettings(globalInfo);
  const propertyTrackingDetails = {
    'Subscriber Provides Name':
      propertySettings[PROPERTY_SETTING_TYPES.PERSONALISATION]
        ?.isFirstNameLastNameEnabled ?? false,
    'Domain Authentication Complete':
      propertySettings[PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE]
        ?.isComplete ?? false,
  };
  return propertyTrackingDetails;
};

const hashEmailAddress = async email => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email.toLowerCase());
  const hash = await crypto.subtle.digest('SHA-1', data);
  const array = Array.from(new Uint8Array(hash));
  const hex = array.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return hex;
};

const setUserDetails = async globalInfo => {
  if (typeof mixpanel !== 'undefined') {
    const currentUser = globalInfo.user;
    const currentProperty =
      globalInfo.properties[globalInfo.current.propertyURN];
    const currentPropertyURN = globalInfo.current.propertyURN;
    const currentRole = users.isAdministrator(globalInfo) ? 'Admin' : 'Editor';

    const distinctId = await hashEmailAddress(currentUser.emailAddress);
    mixpanel.people.set({
      $distinct_id: distinctId,
      $name: currentUser.name,
      $property: currentProperty?.propertyName ?? 'Not set',
      $email: currentUser.emailAddress,
      'Property ID': currentPropertyURN ?? 'Not set',
      Staff: currentUser.userType === USER_TYPES.ECHOBOX_STAFF,
      Role: currentRole,
      HV: sessionStorage.getItem('currentVersion'),
    });
    mixpanel.identify(distinctId);
  }
};

/**
 * track
 * @param {{
 *  eventName: string;
 *  trackingParams?: object;
 *  options?: object;
 * }} args
 */
const track = ({
  eventName = mandatory('eventName'),
  trackingParams = {},
  options = {},
} = {}) => {
  /* Set default options */
  options.campaignURN = options.campaignURN ?? null;
  options.includeCampaignDetails = options.includeCampaignDetails ?? true;
  options.includePropertyDetails = options.includePropertyDetails ?? true;
  options.labelPropertyAsPrevious = options.labelPropertyAsPrevious ?? false;
  options.labelCampaignAsPrevious = options.labelCampaignAsPrevious ?? false;
  options.currentUserDetails = options.currentUserDetails ?? null;
  options.currentCampaignDetails = options.currentCampaignDetails ?? null;
  options.currentCampaignSettings = options.currentCampaignSettings ?? null;

  const trackInternal = async () => {
    const globalInfo = globalinfo.getGlobalInfo();
    if (!isNull(globalInfo)) {
      const currentUser = options?.currentUserDetails ?? globalInfo.user;
      const currentProperty = properties.getCurrentProperty(globalInfo);
      const currentPropertyURN = properties.getCurrentPropertyURN(globalInfo);
      let currentCampaign;
      let currentCampaignURN;
      if (options.campaignURN) {
        currentCampaign = campaigns.getCampaign(
          options.campaignURN,
          globalInfo
        );
        currentCampaignURN = options.campaignURN;
      } else {
        currentCampaign = campaigns.getCurrentCampaign(globalInfo);
        currentCampaignURN = campaigns.getCurrentCampaignURN(globalInfo);
      }
      const currentRole = users.isAdministrator(globalInfo)
        ? 'Admin'
        : 'Editor';

      const products = currentUser.accessGroups?.reduce((acc, userType) => {
        if (PRODUCT_USER_TYPE_MAP[userType]) {
          acc.push(PRODUCT_USER_TYPE_MAP[userType]);
        }
        return acc;
      }, []);

      let propertyURN = !isNullOrUndefined(currentProperty)
        ? currentPropertyURN
        : 'Not set';
      let propertyName = !isNullOrUndefined(currentProperty)
        ? currentProperty.propertyName
        : 'Not set';
      if (isDefined(trackingParams.PropertyURN)) {
        propertyURN = trackingParams.PropertyURN;
        delete trackingParams.PropertyURN;
      }
      if (isDefined(trackingParams.PropertyName)) {
        propertyName = trackingParams.PropertyName;
        delete trackingParams.PropertyName;
      }

      if (!isDefined(trackingParams['Domain Authentication Complete'])) {
        trackingParams['Domain Authentication Complete'] =
          properties.getCurrentPropertySettingValue(
            globalInfo,
            PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
            'isComplete'
          );
      }

      const propertyLabel = options.labelPropertyAsPrevious
        ? 'Previous Property'
        : 'Property';
      const campaignLabel = options.labelCampaignAsPrevious
        ? 'Previous Campaign'
        : 'Campaign';

      trackingParams['$distinct_id'] = await hashEmailAddress(
        currentUser.emailAddress
      );
      trackingParams['$name'] = currentUser.name;
      trackingParams['$property'] = propertyName;
      trackingParams['$email'] = currentUser.emailAddress;
      if (options.includePropertyDetails) {
        trackingParams[`${propertyLabel} Name`] = propertyName;
        trackingParams[`${propertyLabel} ID`] = propertyURN;
        trackingParams = Object.assign(
          trackingParams,
          getPropertyTrackingDetails(globalInfo)
        );
      }
      if (options.includeCampaignDetails) {
        trackingParams[`${campaignLabel} Name`] = currentCampaign.campaignName;
        trackingParams[`${campaignLabel} ID`] = currentCampaignURN;
        const campaignDetails =
          options.currentCampaignDetails ??
          campaigns.getCurrentCampaign(globalInfo);
        const campaignSettings =
          options.currentCampaignSettings ??
          campaigns.getCurrentCampaignSettings(globalInfo);
        trackingParams = Object.assign(
          trackingParams,
          campaigns.getCampaignTrackingDetails({
            campaignDetails,
            campaignSettings,
          })
        );
        trackingParams = Object.assign(
          trackingParams,
          campaigns.getCampaignBrandingDetails({
            campaignSettings,
          })
        );
      }
      trackingParams['Staff'] = await authentication.isStaffUser();
      trackingParams['Role'] = currentRole;
      trackingParams['HV'] = sessionStorage.getItem('currentVersion');
      trackingParams['Products'] = products?.join(', ');

      if (typeof mixpanel !== 'undefined') {
        mixpanel.track(eventName, trackingParams);
      } else {
        console.log(eventName, trackingParams);
      }
    }
  };

  if (!authentication.isImpersonating()) {
    // Don't track events in Mixpanel when impersonating another user as this may skew usage statistics
    trackInternal();
  }
};
