import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { StoreContext } from 'store/store';

import { isNull } from 'common/utility';

function RequireAuth({ children, redirector }) {
  const { state } = useContext(StoreContext);
  const location = useLocation();
  const path = location.pathname;

  const redirectTo = redirector(path, state.globalInfo);

  return isNull(redirectTo) ? children : <Redirect to={redirectTo} />;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
  redirector: PropTypes.func.isRequired,
};

export default RequireAuth;
