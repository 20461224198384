import { useContext } from 'react';
import { Link } from 'react-router-dom';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import * as properties from 'common/properties';
import * as users from 'common/users';
import { StoreContext } from 'store/store';

import { PROPERTY_PERMISSION_TYPES } from 'common/enums';
import PropertyViewError from 'components/campaigns/ViewError';
import SettingsPanel from '../campaigns/SettingsPanel';
import PlanDetails from './PlanDetails';

import './Property.css';

const getPropertyPath = path => `/settings/property/${path}`;

function PropertySettings() {
  const { state } = useContext(StoreContext);

  const propertyName = properties.getCurrentPropertyName(state.globalInfo);
  const propertyURN = properties.getCurrentPropertyURN(state.globalInfo);

  const permissionType = users.getPropertyPermission(
    propertyURN,
    state.globalInfo?.user?.permissions ?? []
  );

  if (permissionType !== PROPERTY_PERMISSION_TYPES.ADMIN) {
    return <PropertyViewError />;
  }

  return (
    <div className="property-settings">
      <div className="campaign-title ebx-title-colour">
        <span className="pr-3">Property</span>
        <span className="ebx-label-new mr-2 px-2 py-1 text-center ebx-h3">
          <AccountBalanceOutlinedIcon
            className="mr-1"
            style={{
              width: '16px',
              height: '18px',
            }}
          />
          {propertyName}
        </span>
      </div>
      <PlanDetails propertyURN={propertyURN} />
      <Link className="link-unstyled" to={getPropertyPath('feeds')}>
        <SettingsPanel
          title="RSS feeds and sitemaps"
          description="Edit your content feeds"
        />
      </Link>
      <Link className="link-unstyled" to={getPropertyPath('website')}>
        <SettingsPanel
          title="Website"
          description="The root domain of your website"
        />
      </Link>
      <Link className="link-unstyled" to={getPropertyPath('address')}>
        <SettingsPanel
          title="Business address"
          description="The address will be displayed in the footer of your Editions"
        />
      </Link>
    </div>
  );
}

export default PropertySettings;
