import { useState } from 'react';

import * as campaigns from 'common/campaigns';
import * as clipboard from 'common/clipboard';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import useGlobalInfo from 'hooks/useGlobalInfo';
import kebabCase from 'lodash.kebabcase';

import { ReactComponent as ContentCopyIcon } from 'assets/svg/copy.svg';
import { ReactComponent as ViewIcon } from 'assets/svg/view.svg';
import { generateSubscriptionLink } from 'common/string';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import './EmbeddedForm.css';

const CopiedPopover = (
  <Popover className="copied__popover">
    <Popover.Content className="ebx-h3">Copied</Popover.Content>
  </Popover>
);

function EmbeddedForm() {
  const { urn: campaignURN } = useParams();
  const globalInfo = useGlobalInfo();
  const [showCopiedSnippet, setShowCopiedSnippet] = useState(false);

  /**
   * Generate a random 9-digit number starting with a non-zero digit
   * This will be passed to the subscription page as a query parameter
   * so that when we track subscribe events on that page, they can be
   * associated with the user clicking on the link from this page
   */
  const pageId = Math.floor(100000000 + Math.random() * 900000000);

  const getTitleAndLink = () => {
    let title;
    let link;
    if (campaignURN) {
      const campaignDetails = campaigns.getCampaign(campaignURN, globalInfo);
      title = campaignDetails.campaignName;
      link = generateSubscriptionLink(properties, globalInfo, campaignDetails);
    } else {
      title = properties.getCurrentPropertyName(globalInfo);
      const propertyURN = properties.getCurrentPropertyURN(globalInfo);
      link = `https://${kebabCase(
        title
      )}.campaign-list.com/subscribe/${propertyURN}`;
    }
    return [title, link];
  };

  const [link] = getTitleAndLink();
  const iframeCode = `<iframe src="${link}?embed" width="600" height="400"></iframe>`;
  const settingsLink = campaignURN
    ? `/settings/campaigns/${campaignURN}/branding`
    : `/settings/campaigns/allcampaigns/branding`;
  const handleCopy = event => {
    setShowCopiedSnippet(true);
    event.preventDefault();
    clipboard.setClipboardData(null, iframeCode);
    tracker.track({
      eventName: 'Copy Subscription Snippet',
      trackingParams: {
        'Snippet Type': 'Address',
      },
      options: { campaignURN },
    });
    setTimeout(() => setShowCopiedSnippet(false), 3000);
  };

  const handleViewPage = () => {
    tracker.track({
      eventName: 'Display Subscription Page',
      trackingParams: {
        'Subscription Page Displayed to Reader': link,
        '# Campaigns Offered': campaignURN
          ? 1
          : campaigns.getCurrentPropertyCampaigns(globalInfo).length,
        'Page Instance ID': pageId,
      },
    });
  };

  const showIframePreview = false; /* NL-862 */

  return (
    <div>
      <Row>
        <Col xs={12} sm={8}>
          <div className="form-title">Embedded form</div>
          <div className="snippet-comment">
            Use this snippet to sign up readers using your own form
          </div>
          <div className="snippet-label">Snippet</div>
          <div id="snippet">{iframeCode}</div>
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={CopiedPopover}
            show={showCopiedSnippet}
            rootClose
          >
            <Button
              className="ebx-btn-secondary mr-2 custom-button"
              onClick={handleCopy}
            >
              <ContentCopyIcon />
              <span className="button-text">Copy snippet</span>
            </Button>
          </OverlayTrigger>
          <Button
            className="custom-button ebx-btn-secondary mr-3"
            href={`${link}?pageId=${pageId}`}
            onClick={handleViewPage}
            target="_blank"
          >
            <ViewIcon />
            <span className="button-text">View page</span>
          </Button>
        </Col>
        <Col xs={12} sm={4}>
          <div className="embedded-form-help">
            <div className="ebx-h4">What is an embedded form?</div>
            <div className="ebx-body-1 mt-2">
              An embedded form is a way to display your form as part of your own
              web page, without your form users needing to click a link to
              Echobox.
            </div>
          </div>
          <div className="embedded-form-help mt-4">
            <div className="ebx-h4">Your embedded form</div>
            <div className="ebx-body-1 mt-2">
              You can customize this form in your{' '}
              <Link
                to={settingsLink}
                className="ebx-link ebx-cursor font-weight-bold ml-auto ebx-h3"
              >
                branding settings.
              </Link>
            </div>
            {showIframePreview && (
              <div className="mt-2 w-100">
                <iframe
                  src={`${link}?embed`}
                  width="100%"
                  height="400"
                  title="Embedded form"
                  sandbox="allow-same-origin"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default EmbeddedForm;
