import { WatchTimeIcon } from '@ebx-ui/ebx-ui-component-library-sdk';
import DisplayEditionSendTiming from './DisplayEditionSendTiming';

export interface EditionSendTimesProps {
  className?: string | null;
  scheduledUnixTime: number;
  finishedSendingTime?: number | null;
  scheduleEditionButton?: boolean;
  editionState?: string | null;
}

function EditionSendTimes({
  className = null,
  scheduledUnixTime,
  finishedSendingTime = null,
  scheduleEditionButton = false,
  editionState = null,
}: EditionSendTimesProps) {
  return (
    <div className="d-flex align-items-center ml-0 ml-lg-auto my-1 ebx-h3 ebx-title-colour text-lg-right">
      {scheduleEditionButton && <WatchTimeIcon mr={1.5} />}
      <DisplayEditionSendTiming
        scheduledUnixTime={scheduledUnixTime}
        finishedSendingTime={finishedSendingTime}
        className={className}
        editionState={editionState}
      />
    </div>
  );
}

export default EditionSendTimes;
