import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Spinner } from 'react-bootstrap';

export interface LoadingProps {
  className?: string;
}

function Loading({ className = '' }: LoadingProps) {
  return (
    <div
      className={`loading--height d-flex align-items-center justify-content-center ${className}`}
    >
      <Spinner animation="border" role="status" variant="primary">
        <VisuallyHidden>Loading...</VisuallyHidden>
      </Spinner>
    </div>
  );
}

Loading.defaultProps = {
  className: null,
};

export default Loading;
