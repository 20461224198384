import {
  Card,
  Divider,
  Flex,
  HStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React from 'react';

interface MetricsContainerProps {
  children?: React.ReactNode;
}

function MetricsContainer({ children }: MetricsContainerProps) {
  // Wraps metrics in Flex to stretch to the width of the container
  const metrics = React.Children.map(children, metric => (
    <Flex flexBasis="0" flexGrow={1}>
      {metric}
    </Flex>
  ));

  return (
    <Card p={6} flexGrow={1}>
      <HStack
        height="100%"
        divider={<Divider orientation="vertical" />}
        spacing={6}
        flexGrow={1}
        alignItems="flex-start"
      >
        {metrics}
      </HStack>
    </Card>
  );
}

export default MetricsContainer;
