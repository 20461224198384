import * as campaigns from 'common/campaigns';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useGlobalInfo from 'hooks/useGlobalInfo';
import { BackLink, Container, Title } from '../../../common';

function VerificationContent({ setSettingsPanel, settings }) {
  const { urn } = useParams();
  const globalInfo = useGlobalInfo();
  const campaignName = campaigns.getCampaignName(urn, globalInfo);

  const header = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
    'verificationEmailTitleCopy'
  );

  const description = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
    'verificationEmailBodyCopy'
  );

  const buttonText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
    'verificationEmailButtonText'
  );

  let subjectLine = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.SUBSCRIPTION,
    'doubleOptInSubjectLine'
  );
  if (subjectLine) {
    subjectLine = subjectLine.replace('{campaignName}', campaignName);
  }

  const setButtonText = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
      settingKey: 'verificationEmailButtonText',
      settingValue: event.target.value,
    });
  };

  const setHeader = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
      settingKey: 'verificationEmailTitleCopy',
      settingValue: event.target.value,
    });
  };

  const setDescription = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_VERIFICATION,
      settingKey: 'verificationEmailBodyCopy',
      settingValue: event.target.value,
    });
  };

  const setSubjectLine = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SUBSCRIPTION,
      settingKey: 'doubleOptInSubjectLine',
      settingValue: event.target.value,
    });
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_VERIFICATION_PAGE.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to Verification" />
      </Button>
      <Title className="mb-3">
        <span className="mr-3">Content</span>
      </Title>
      <Title className="settings-subtitle">
        <span className="mr-4">Email Subject Line</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        required
        placeholder="Thank you for subscribing"
        value={subjectLine}
        onChange={setSubjectLine}
      />
      <Title className="settings-subtitle">
        <span className="mr-4">Header</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        required
        placeholder="Please confirm your subscription!"
        value={header}
        onChange={setHeader}
      />
      <Title className="settings-subtitle">
        <span className="mr-4">Description</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        as="textarea"
        type="text"
        rows={3}
        placeholder="Thank you for signing up to our newsletter. Please confirm your subscription by clicking the link below."
        required
        value={description}
        onChange={setDescription}
        autoComplete="off"
      />
      <Title className="settings-subtitle">
        <span className="mr-4">Button</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        required
        placeholder="Confirm subscription"
        value={buttonText}
        onChange={setButtonText}
      />
    </Container>
  );
}

VerificationContent.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default VerificationContent;
