import { Td, Th, Tr } from '@ebx-ui/ebx-ui-component-library-sdk';

export const trProps: React.ComponentProps<typeof Tr> = {
  boxShadow: 'borderTop',
};

export const thProps: React.ComponentProps<typeof Th> = {
  borderBottomColor: 'gray.300',
  borderBottomWidth: '1px',
  color: 'gray.900',
  cursor: 'pointer',
  fontSize: 'sm',
  fontWeight: 'medium',
  letterSpacing: 'normal',
  px: 5,
  py: 4,
  textTransform: 'none',
};

export const tdProps: React.ComponentProps<typeof Td> = {
  background: 'white',
  borderBottomColor: 'gray.300',
  borderBottomWidth: '1px',
  color: 'gray.900',
  fontSize: 'sm',
  overflow: 'hidden',
  px: 5,
  py: 4,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
