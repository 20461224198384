/* eslint default-param-last: "off" */
/* eslint import/prefer-default-export: "off" */

import { initialState } from 'store/initial';
import { types } from 'store/types';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GLOBAL_INFO:
      return {
        ...state,
        globalInfo: action.payload,
      };

    case types.SET_GLOBAL_INFO_STATE:
      return {
        ...state,
        globalInfo: {
          ...state.globalInfo,
          state: action.payload,
        },
      };

    case types.SET_STATUS_PAGE_NOTIFICATIONS:
      return {
        ...state,
        statusPageNotifications: action.payload,
      };

    default:
      return state;
  }
};

export { reducer };
