import {
  DeleteTrashIcon,
  Dropdown,
  EditPenIcon,
  MoreOptionsIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { MediaDataSources } from 'common/types';
import EditContentModal from './EditContentModal';

interface EditArticleProps {
  articleURL: string;
  description?: string;
  handleSaveArticleDetails: (
    sectionURN: string,
    index: number,
    finalURL: string,
    title: string,
    description: string,
    imageURL: string,
    mediaURN: string,
    callback: () => void
  ) => void;
  handleDeleteArticle: (sectionURN: string, index: number) => void;
  imageURL: string;
  index: number;
  isSavingArticleDetails: boolean;
  sectionURN: string;
  dataSources: MediaDataSources;
  title: string;
  isShowModal: boolean;
  hideModal: () => void;
  showModal: () => void;
}

function EditArticle({
  articleURL,
  description,
  handleSaveArticleDetails,
  handleDeleteArticle,
  imageURL,
  index,
  isSavingArticleDetails,
  sectionURN,
  dataSources,
  title,
  isShowModal,
  hideModal,
  showModal,
}: EditArticleProps) {
  return (
    <>
      <Dropdown placement="bottom-end">
        <Dropdown.Button
          lineHeight={1}
          fontSize={4}
          display="none"
          sx={{
            '.article:hover &': {
              display: 'inline-block',
            },
          }}
        >
          <MoreOptionsIcon />
        </Dropdown.Button>
        <Dropdown.List minW="160px">
          <Dropdown.Item
            key="edit article"
            icon={<EditPenIcon />}
            onClick={showModal}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            key="delete article"
            icon={<DeleteTrashIcon color="red.600" />}
            color="red.600"
            onClick={() => handleDeleteArticle(sectionURN, index)}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>

      {isShowModal && (
        <EditContentModal
          articleURL={articleURL}
          description={description}
          handleSaveArticleDetails={handleSaveArticleDetails}
          hideModal={hideModal}
          imageURL={imageURL}
          index={index}
          isSavingArticleDetails={isSavingArticleDetails}
          sectionURN={sectionURN}
          dataSources={dataSources}
          isShowModal={isShowModal}
          title={title}
        />
      )}
    </>
  );
}

export default EditArticle;
