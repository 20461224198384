import * as authentication from 'common/authentication';
import * as logger from 'common/logger';
import { cloneObject, isNull } from 'common/utility';

import { STATUS_PAGE_IDS } from 'common/constants';

export const API_TIMEOUTS = {
  XXS: 5000, // 5 seconds
  XS: 15000, // 15 seconds
  S: 60000, // 1 minute
  M: 120000, // 2 minutes
  LM: 180000, // 3 minutes
  L: 240000, // 4 minutes
  XL: 360000, // 6 minutes
  XXL: 720000, // 12 minutes
  XXXL: 1500000, // 25 minutes
};

export const APIS = {
  CORE: 'CORE',
  NEWSLETTERS: 'NEWSLETTERS',
  NEWSLETTERS_SUBS: 'NEWSLETTERS_SUBS',
  OPTIMISATION_SERVICE: 'OPTIMISATION_SERVICE',
  SOURCE: 'SOURCE',
};

let USE_PRODUCTION_ENDPOINTS;
const environmentVariable = process.env.REACT_APP_USE_PRODUCTION_ENDPOINTS;
if (environmentVariable === null) {
  USE_PRODUCTION_ENDPOINTS = false;
} else {
  USE_PRODUCTION_ENDPOINTS = environmentVariable === 'true';
}
if (logger && logger.info) {
  logger.info(`USE_PRODUCTION_ENDPOINTS: ${USE_PRODUCTION_ENDPOINTS}`);
}

export const API_BASE_URLS = {
  [APIS.CORE]: {
    stage: 'https://coreapi.service.echobox.com',
    live: 'https://coreapi.service.echobox.com',
  },
  [APIS.NEWSLETTERS]: {
    // stage: 'http://localhost:8080',
    stage: 'https://campaignapi-stage.service.echobox.com',
    live: 'https://campaignapi.service.echobox.com',
  },
  [APIS.NEWSLETTERS_SUBS]: {
    // stage: 'http://localhost:9000',
    stage: 'https://stage.campaign-list.com',
    live: 'https://www.campaign-list.com',
  },
  [APIS.OPTIMISATION_SERVICE]: {
    stage: 'https://prod.newslettersoptimisation.service.echobox.com',
    live: 'https://prod.newslettersoptimisation.service.echobox.com',
  },
  [APIS.SOURCE]: {
    stage: 'https://api.echobox.com',
    live: 'https://api.echobox.com',
  },
};

if (USE_PRODUCTION_ENDPOINTS) {
  Object.keys(APIS).forEach(api => {
    API_BASE_URLS[api].stage = API_BASE_URLS[api].live;
  });
}

export const apiBaseURL = (api, version = '1') => {
  const environmentName =
    window.location.hostname === 'newsletters.echobox.com' ||
    window.location.hostname === 'email.echobox.com'
      ? 'live'
      : 'stage';
  let apiURL = `${API_BASE_URLS[api][environmentName]}`;
  if (version !== '') {
    apiURL = `${apiURL}/v${version}`;
  }
  return apiURL;
};

export function getRequestHeaders(
  additionalHeaders = {},
  shouldSendCognitoTokens = false
) {
  const requestHeaders = cloneObject(additionalHeaders);

  const clientServiceToken = authentication.getClientServiceToken();
  if (!isNull(clientServiceToken) && !shouldSendCognitoTokens) {
    requestHeaders['X-EBX-ClientServiceToken'] = clientServiceToken;
  } else {
    requestHeaders['X-EBX-AccessToken'] = authentication.getAccessToken();
    requestHeaders['X-EBX-IdToken'] = authentication.getIdToken();
  }
  return requestHeaders;
}

export const statusPageURLs = {
  [STATUS_PAGE_IDS.CORE]: null,
  [STATUS_PAGE_IDS.NEWSLETTERS]: 'https://42yqqd04dt45.statuspage.io/api/v2',
};
