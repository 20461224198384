import {
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as campaigns from 'common/campaigns';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as zendesk from 'common/zendesk';

interface EditionTemplateModalProps {
  handleNewEdition: () => {};
  globalInfo: any;
  isOpen: boolean;
  onClose: () => void;
}

function EditionTemplateModal({
  handleNewEdition,
  globalInfo,
  isOpen,
  onClose,
}: EditionTemplateModalProps) {
  const propertyName = properties.getCurrentPropertyName(globalInfo);
  const campaignName = campaigns.getCampaignName(
    globalInfo.current.campaignURN,
    globalInfo
  );

  const selectEchoboxTemplate = () => {
    onClose();
    handleNewEdition();
  };

  const selectCustomTemplate = () => {
    onClose();
    zendesk.openWidget();
    zendesk.prefillWidgetDescription(
      `I would like to upload my own custom template to ${campaignName} (${propertyName})`
    );
    zendesk.onSubmit(trackCustomTemplate);
  };

  const trackCustomTemplate = () => {
    tracker.track({
      eventName: 'Request Custom Template',
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <Modal.Header>
        <Modal.Title>Choose templates</Modal.Title>
      </Modal.Header>
      <Modal.Body px={6} maxWidth="fit-content">
        <Flex gap={6}>
          <Card px={4}>
            <Card.CardContent align="center" gap={0}>
              <Image
                src="/assets/img/echobox-template.png"
                alt="Echobox template"
                fit="contain"
                width="242px"
                height="182px"
                mb={4}
              />
              <Heading variant="h3" mb={2}>
                Echobox template
              </Heading>
              <Text size="sm" mb={6} color="gray.600">
                Full template optimization
              </Text>
              <Button width="100%" onClick={selectEchoboxTemplate}>
                Choose
              </Button>
            </Card.CardContent>
          </Card>
          <Card px={4}>
            <Card.CardContent align="center" gap={0}>
              <Image
                src="/assets/img/customer-template.png"
                alt="Custom template"
                fit="contain"
                width="242px"
                height="182px"
                mb={4}
              />
              <Heading variant="h3" mb={2}>
                Custom template
              </Heading>
              <Text size="sm" mb={6} color="gray.600">
                No template optimization
              </Text>
              <Button
                variant="secondary"
                width="100%"
                onClick={selectCustomTemplate}
              >
                Contact us
              </Button>
            </Card.CardContent>
          </Card>
        </Flex>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-start" gap={2}>
        <Checkbox />
        <Heading variant="h5">Set Echobox template as default</Heading>
      </Modal.Footer>
    </Modal>
  );
}

export default EditionTemplateModal;
