import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Spinner } from 'react-bootstrap';

import './Loading.css';

function Loading() {
  return (
    <div className="loading--height d-flex align-items-center justify-content-center w-100">
      <Spinner animation="border" role="status" variant="primary">
        <VisuallyHidden>Loading...</VisuallyHidden>
      </Spinner>
    </div>
  );
}

export default Loading;
