/* eslint no-use-before-define: "off" */

import * as Sentry from '@sentry/react';

import * as logger from 'common/logger';
import { generateGuid } from 'common/string';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isRunningTests,
  isUndefined,
} from 'common/utility';

import { SENTRY_LOG_SPANS, SENTRY_LOG_TRANSACTIONS } from 'common/config';
import { KEY_TRANSACTIONS } from 'common/constants';

/**
 * Usage: API metrics
 *
 *   import * as metrics from 'common/metrics';
 *
 *   // At the start of the operation you want to track
 *   const guid = metrics.start('label');
 *
 *   // When the tracked operation completes successfully
 *   metrics.end('label', guid);
 *
 *   // If the tracked operation fails
 *   metrics.fail('label', guid);
 *
 *   // To render tracked results for a single label
 *   metrics.print('label');
 *
 *   // To render tracked results for all labels
 *   metrics.print();
 *
 *   // To reset all tracked results
 *   metrics.reset();
 *
 *   // Because the metrics object is attached to the global window object, you can
 *   // also show statistics from the developer console:
 *
 *   window.EBX.metrics.print();
 *
 *
 * Usage: Frontend performance metrics
 *
 *   import * as metrics from 'common/metrics';
 *   import { FRONTEND_METRICS } from 'common/constants';
 *
 *   // Start tracking
 *   metrics.mark(FRONTEND_METRICS.KEY);
 *
 *   // Stop tracking
 *   metrics.measure(FRONTEND_METRICS.KEY);
 *
 *   When we need to wait for a component to be "stable" before measuring:
 *   this._trackLastUpdate(FRONTEND_METRICS.EVENT_NAME);
 *
 */

export { start, end, fail, clear, stringify, mark, measure };

/**
 * API performance methods
 */

// start - starts a timer for the specified label

function start(label) {
  const guid = generateGuid();
  initialise(label, guid);
  window.EBX.metrics[label][guid].start = new Date().getTime();
  if (SENTRY_LOG_TRANSACTIONS && SENTRY_LOG_SPANS) {
    if (
      isDefined(window.EBX) &&
      isDefined(window.EBX.sentry) &&
      isDefined(window.EBX.sentry.transactions)
    ) {
      if (isNullOrUndefined(window.EBX.sentry.spans)) {
        window.EBX.sentry.spans = {};
      }
      const noOfTransactions = Object.keys(
        window.EBX.sentry.transactions
      ).length;
      if (noOfTransactions > 0) {
        const firstTransactionName = Object.keys(
          window.EBX.sentry.transactions
        )[noOfTransactions - 1];
        window.EBX.sentry.spans[label] = window.EBX.sentry.transactions[
          firstTransactionName
        ].startChild({
          op: label,
        });
      }
    }
  }
  return guid;
}

// end - ends a timer for the specified label (success)

function end(label, guid) {
  finish(label, guid, true);
}

// fail - ends a timer for the specified label (failure)

function fail(label, guid) {
  finish(label, guid, false);
}

// print - renders metrics results in tabular form

function print(label) {
  if (isDefined(label)) {
    // Display metrics for the specified label
    initialise(label);
    console.table(window.EBX.metrics[label]);
  } else {
    // Display all metrics (so filter out the debug method from window.EBX.metrics)
    const keys = Object.keys(window.EBX.metrics)
      .filter(key => key !== 'debug' && key !== 'reset')
      .sort();
    const filtered = {};
    keys.forEach(key => {
      filtered[key] = window.EBX.metrics[key];
    });
    console.table(filtered);
  }
}

// reset - resets all metrics

function reset() {
  if (isNullOrUndefined(window.EBX)) {
    window.EBX = {};
  }
  window.EBX.metrics = {
    print,
    reset,
    stringify,
  };
}

// stringify - converts all metrics to JSON

function stringify() {
  return JSON.stringify(window?.EBX?.metrics);
}

// initialise - creates relevant data structures

function initialise(label, guid) {
  if (isUndefined(window.EBX) || isUndefined(window.EBX.metrics)) {
    reset();
  }
  if (isUndefined(window.EBX.metrics[label])) {
    window.EBX.metrics[label] = {
      calls: 0,
      success: 0,
      failure: 0,
      timed: 0,
      elapsed: 0,
      min: Infinity,
      max: 0,
      average: 0,
    };
  }
  if (isDefined(guid) && isUndefined(window.EBX.metrics[label][guid])) {
    window.EBX.metrics[label][guid] = {
      start: null,
    };
  }
}

// finish - ends a timer with the specified outcome (success or failure)

function finish(label, guid, success) {
  initialise(label, guid);
  const entry = window?.EBX?.metrics[label];
  entry.calls += 1;
  if (success) {
    entry.success += 1;
  } else {
    entry.failure += 1;
  }
  if (!isNull(entry[guid].start)) {
    entry.timed += 1;
    const elapsed = new Date().getTime() - entry[guid].start;
    entry.elapsed += elapsed;
    entry.average = Math.round((entry.elapsed * 100) / entry.timed) / 100;
    entry.min = Math.min(elapsed, entry.min);
    entry.max = Math.max(elapsed, entry.max);
  }
  delete entry[guid];
  if (
    SENTRY_LOG_TRANSACTIONS &&
    SENTRY_LOG_SPANS &&
    isDefined(window.EBX) &&
    isDefined(window.EBX.sentry) &&
    isDefined(window.EBX.sentry.spans) &&
    isDefined(window.EBX.sentry.spans[label])
  ) {
    window.EBX.sentry.spans[label].finish();
    delete window.EBX.sentry.spans[label];
  }
}

/**
 * Frontend performance methods
 */

// clear - abandon performance tracking

function clear(name) {
  if (!isRunningTests()) {
    logger.info(`Metrics:clear ${name}`);

    performance.clearMarks(name);
    performance.clearMeasures(name);
  }
}

// mark - start performance tracking

function mark(name) {
  if (!isRunningTests()) {
    logger.info(`Metrics:mark ${name}`);

    performance.mark(name);
    if (SENTRY_LOG_TRANSACTIONS && KEY_TRANSACTIONS.indexOf(name) !== -1) {
      if (isNullOrUndefined(window.EBX)) {
        window.EBX = {};
      }
      if (isNullOrUndefined(window.EBX.sentry)) {
        window.EBX.sentry = {};
      }
      if (isNullOrUndefined(window.EBX.sentry.transactions)) {
        window.EBX.sentry.transactions = {};
      }
      window.EBX.sentry.transactions[name] = Sentry.startTransaction({ name });
    }
  }
}

// measure - finish performance tracking

function measure(name, includesDelay) {
  if (!isRunningTests()) {
    const marks = performance.getEntriesByName(name, 'mark');
    if (marks.length > 0) {
      logger.info(`Metrics:measure ${name}`);

      const prefix = includesDelay ? 'DEL' : 'EBX';
      performance.measure(`${prefix}:${name}`, name);
      performance.clearMarks(name);
      performance.clearMeasures(name);
      if (
        SENTRY_LOG_TRANSACTIONS &&
        isDefined(window.EBX) &&
        isDefined(window.EBX.sentry) &&
        isDefined(window.EBX.sentry.transactions) &&
        isDefined(window.EBX.sentry.transactions[name])
      ) {
        window.EBX.sentry.transactions[name].finish();
        delete window.EBX.sentry.transactions[name];
      }
    }
  }
}
