import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import { handleCognitoError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

export default async function authCompleteNewPassword({
  user = mandatory('user'),
  password = mandatory('password'),
  attributes = mandatory('attributes'),
} = {}) {
  const guid = metrics.start('authCompleteNewPassword');
  const username = authentication.getUsername();
  logger.info(`API:authCompleteNewPassword ${username}`);

  try {
    const response = await Auth.completeNewPassword(user, password, attributes);
    metrics.end('authCompleteNewPassword', guid);
    return response;
  } catch (error) {
    metrics.fail('authCompleteNewPassword', guid);
    throw handleCognitoError({
      originalError: error,
      errorLocation: 'api/authCompleteNewPassword',
      args: { username },
    });
  }
}
