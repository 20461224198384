import { useEffect /* , useState */ } from 'react';
// import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import * as environment from 'common/environment';
import * as logger from 'common/logger';
import * as loginApp from 'common/loginapp';
// import * as logger from 'common/logger';
// import queryString from 'query-string';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';

import { VisuallyHidden } from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  IFRAME_MESSAGE_TIMEOUT,
  LOCAL_STATE_STORAGE_KEY,
  LOGIN_APP_MESSAGES,
  ROUTE_REDIRECTIONS,
} from 'common/constants';
import { CENTRALISED_LOGIN } from 'common/toggles';

const loginUrl = environment.getLoginUrl();
const LOGOUT_KEY = 'isLoggingOut';

function SignOut() {
  // const [shouldRedirect, setRedirect] = useState(false);

  useEffect(() => {
    window.onmessage = async event => {
      if (event.origin === loginUrl) {
        const {
          data: { status },
        } = event;
        if (status === 200) {
          sessionStorage.removeItem(LOGOUT_KEY);
          window.location.href = `${loginUrl}${window.location.search}`;
        }
      } else {
        logger.info(`Received message from unexpected origin: ${event.origin}`);
      }
    };

    const performSignOut = async () => {
      // TODO - delete all flash messages
      const isSignedIn = authentication.isSignedIn();
      /*
      const { search } = props.location;
      const query = queryString.parse(search);
      const wasTimedOut = isDefined(query.timeout);
      const wasBadAuth =
        isDefined(query.badauth) ||
        (isDefined(this.props.location.pathname) &&
          this.props.location.pathname.indexOf('badauth') !== -1);
      */

      if (isSignedIn) {
        await API.authSignOut();
        /* Track signout event in Mixpanel */
        tracker.track({ eventName: 'Logout' });
        // Destroy Beamer and ensure all processes are stopped
        // if (!isNullOrUndefined(window.Beamer)) {
        //   window.Beamer.destroy();
        // }
        /* Destroy client-side authentication details */
        authentication.signOut();
        // Reset Beamer
        // setTimeout(() => {
        //   delete window.beamer_config;
        // }, 500);
        // setRedirect(true);
        /* Redirect to sign in page */
        if (CENTRALISED_LOGIN) {
          loginApp.postMessage(LOGIN_APP_MESSAGES.SIGN_OUT);
        } else {
          window.location.href = ROUTE_REDIRECTIONS.SIGNIN;
        }
      }
      /* Destroy client-side authentication details */
      authentication.signOut();
      /* Clear global info from local storage */
      window.localStorage.removeItem(LOCAL_STATE_STORAGE_KEY);
      /* Redirect to sign in page */
      if (CENTRALISED_LOGIN) {
        // logout in login app
        sessionStorage.setItem(LOGOUT_KEY, true);
        utility.until(
          () => !sessionStorage.getItem(LOGOUT_KEY),
          () => loginApp.postMessage(LOGIN_APP_MESSAGES.SIGN_OUT),
          50,
          IFRAME_MESSAGE_TIMEOUT
        );
        loginApp.postMessage(LOGIN_APP_MESSAGES.SIGN_OUT);
      } else {
        window.location.href = ROUTE_REDIRECTIONS.SIGNIN;
      }
    };

    performSignOut();
  }, []);

  /*
  if (shouldRedirect) {
    return <Redirect to={ROUTE_REDIRECTIONS.SIGNIN} push={true} />;
  }
  */

  return (
    <div style={{ marginTop: '20%', textAlign: 'center' }}>
      <Spinner animation="border" role="status" size="md" className="mr-2">
        <VisuallyHidden>Logging out...</VisuallyHidden>
      </Spinner>
    </div>
  );
}

/*
SignOut.propTypes = {
  location: {
    search: PropTypes.string,
  },
};

SignOut.defaultProps = {
  location: {
    search: '',
  },
};
*/

export default SignOut;
