/* eslint react-hooks/exhaustive-deps: "off" */

import { positionZendeskRight } from 'common/zendesk';
import * as levenshtein from 'js-levenshtein';
import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { Box, Flex, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';

import ContentPersonalisation from 'components/editor/curate/ContentPersonalisation';
import CurateError from 'components/editor/curate/CurateError';
import CurateWrapper from 'components/editor/curate/CurateWrapper';
import CustomBlock from 'components/editor/curate/CustomBlock';
import EmailSubject from 'components/editor/curate/EmailSubject';
import PreviewText from 'components/editor/curate/PreviewText';
import Promotion from 'components/editor/curate/Promotion';
import Section from 'components/editor/curate/Section';
import TextBlock from 'components/editor/curate/TextBlock';
import Loading from 'components/editor/Loading';
import Header from 'components/header/Header';

import * as API from 'api/api';
import * as blocks from 'common/blocks';
import * as campaigns from 'common/campaigns';
import {
  CAMPAIGN_SETTING_TYPES,
  EDITION_APPROVAL_STATE_NAMES,
  EDITION_DISPLAY_STATES,
  EDITION_FIELDS,
  PROPERTY_SETTING_TYPES,
  URN_TYPES,
} from 'common/constants';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import {
  EDITION_APPROVAL_STATES,
  EDITION_SUBJECT_TYPES,
  EMAIL_PREVIEW_TEXT_TYPES,
  PREVIEW_TYPES,
} from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import fetchPreview from 'common/preview';
import {
  getBlock as getPromotionBlock,
  updateBlocks as updatePromotionBlocks,
} from 'common/promotionblocks';
import * as properties from 'common/properties';
import * as sanitise from 'common/sanitise';
import * as schedule from 'common/schedule';
import * as sections from 'common/sections';
import * as tags from 'common/tags';
import { getTextBlock, updateTextBlocks } from 'common/textblocks';
import * as tracker from 'common/tracker';
import * as url from 'common/url';
import { getURNType } from 'common/urns';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useStaffMode from 'hooks/useStaffMode';

import './Curation.css';

function Curation() {
  const { id } = useParams();
  const globalInfo = useGlobalInfo();
  const toast = useToast();
  const campaignDetails = campaigns.getCurrentCampaign(globalInfo);

  const [isStaffModeActive] = useStaffMode();
  const [isLoadingEdition, setLoadingEdition] = useState(true);
  const [isLoadingPreview, setLoadingPreview] = useState(true);
  const [editionDetails, setEditionDetails] = useState(null);
  const [preview, setPreview] = useState(null);

  const [emailSubject, setEmailSubject] = useState(null);
  const emailSubjectInputRef = useRef();
  const [aiSubjectLines, setAISubjectLines] = useState(null);
  const [hasSeenAISubjectLines, setHasSeenAISubjectLines] = useState(false);
  const [usedAISubjectLine, setUsedAISubjectLine] = useState(null);

  const [hasArticlesChanged, setHasArticlesChanged] = useState(false);
  const [displayArticleChangedAlert, setDisplayArticleChangedAlert] =
    useState(false);

  const [duplicateArticles, setDuplicateArticles] = useState(null);
  const [displayDuplicateArticlesAlert, setDisplayDuplicateArticlesAlert] =
    useState(false);

  const [isApproving, setApproving] = useState(false);
  const [isSavingArticleDetails, setSavingArticleDetails] = useState(false);
  const [isSavingEditionSubject, setSavingEditionSubject] = useState(false);
  const [isSavingTextBlock, setSavingTextBlock] = useState(false);
  const [isSavingPromotionBlock, setSavingPromotionBlock] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [errorShow, setErrorShow] = useState(errorMessage !== '');

  const [editionDisplayState, setEditionDisplayState] = useState(null);
  const [isEditionEditable, setIsEditionEditable] = useState(null);

  // Email subject state needs to be shared
  const [tempEmailSubject, setTempEmailSubject] = useState(emailSubject);

  useEffect(() => {
    setTempEmailSubject(emailSubject);
  }, [emailSubject]);

  /* If the Campaign's domain isn't verified then we won't allow them to approve the Edition */
  const isDomainVerificationComplete =
    properties.getCurrentPropertySettingValue(
      globalInfo,
      PROPERTY_SETTING_TYPES.DOMAIN_VERIFICATION_COMPLETE,
      'isComplete'
    );

  /* If content personalisation is enabled, then no article changes will be allowed */
  const isContentPersonalisationEnabled =
    campaigns.getCurrentCampaignSettingValue(
      globalInfo,
      CAMPAIGN_SETTING_TYPES.CONTENT_PERSONALISATION,
      'isContentOrderingEnabled'
    );

  /* If full content selection is enabled, then AI subject line options will be hidden */
  const isFullContentSelectionEnabled =
    campaigns.getCurrentCampaignSettingValue(
      globalInfo,
      CAMPAIGN_SETTING_TYPES.CONTENT_PERSONALISATION,
      'isContentSelectionEnabled'
    );

  /* Campaign settings */
  const subjectType =
    campaigns.getCurrentCampaignSettingValue(
      globalInfo,
      CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
      'subjectType'
    ) ?? EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE;
  const subjectLineUsesArticleTitle =
    subjectType === EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE;
  const personalisationSettings = campaigns.getCurrentCampaignSettingsType(
    globalInfo,
    CAMPAIGN_SETTING_TYPES.SUBSCRIBER_PERSONALISATION
  );

  const previewTextType =
    campaigns.getCurrentCampaignSettingValue(
      globalInfo,
      CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
      'defaultEmailPreviewTextType'
    ) ?? EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_DESCRIPTION;

  const [areAllArticlesOptimal, setAllArticlesOptimal] = useState(null);

  async function getPreview() {
    await fetchPreview({
      setPreview,
      setLoadingPreview,
      setErrorMessage,
      setErrorShow,
      logger,
      previewDetails: { editionURN: id },
      previewType: PREVIEW_TYPES.EDITION,
    });
  }

  const defaultEmailSubject = () => {
    const tomorrow = datetime.getUnixTimestamp() + 24 * 60 * 60;
    const date = new Date(tomorrow * 1000);

    return editions.getEditionSubject(
      campaignDetails,
      date,
      subjectType,
      findFirstArticleTitle(editionDetails.sections)
    );
  };

  const updateEditionAsync = async (
    fieldsToUpdate,
    options = { updatePreview: true, refreshCurate: true }
  ) => {
    await editions.updateEdition(editionDetails, fieldsToUpdate);
    const updatedEditionDetails = await fetchEditionDetails(
      options.refreshCurate
    );
    if (options.updatePreview) {
      getPreview();
    }
    return updatedEditionDetails;
  };

  const handleCloseDuplicateArticlesAlert = () => {
    setDisplayDuplicateArticlesAlert(false);
  };

  useEffect(() => {
    if (editionDisplayState !== null) {
      setIsEditionEditable(
        editions.checkIsEditionEditable(editionDisplayState)
      );
    }
  }, [editionDisplayState]);

  const loadAISubjectLines = async () => {
    try {
      const subjectLines = await API.getEditionAISubjectLines({
        editionURN: editionDetails.editionURN,
      });
      setAISubjectLines(subjectLines);
      setHasSeenAISubjectLines(false);
    } catch (error) {
      logger.error({
        event: 'api:getEditionAISubjectLines',
        error,
      });
    }
  };

  const handleOpenAISubjectLines = () => {
    if (aiSubjectLines.length > 0) {
      setHasSeenAISubjectLines(true);
      tracker.track({
        eventName: 'Generate AI Subject Line',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
          'Email Type': 'Newsletter',
          'Generated Subject Line': aiSubjectLines,
        },
      });
    }
  };

  const setAISubjectLine = aiSubjectLine => {
    setUsedAISubjectLine(aiSubjectLine);
    setHasArticlesChanged(false);
  };

  const handleArticlesChanged = () => {
    setHasArticlesChanged(true);
    loadAISubjectLines();
  };

  const handleCloseArticleChangedAlert = () => {
    setDisplayArticleChangedAlert(false);
    setHasArticlesChanged(false);
  };

  useEffect(() => {
    if (isEditionEditable && !isFullContentSelectionEnabled) {
      loadAISubjectLines();
    }
  }, [isEditionEditable, isFullContentSelectionEnabled]);

  useEffect(() => {
    positionZendeskRight();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setErrorShow(true);
    }
  }, [errorMessage]);

  /* Update various text block fields when campaign and edition details have been loaded */

  const approvalStateAfterChange = () => {
    if (!isStaffModeActive) {
      if (
        editionDetails.approvalState === EDITION_APPROVAL_STATES.CLIENT_APPROVED
      ) {
        return EDITION_APPROVAL_STATES.ECHOBOX_APPROVED;
      }
    }

    // Make changes in Staff Mode without changing the approval state.
    return editionDetails.approvalState;
  };

  const fetchEditionDetails = async (refreshCurate = true) => {
    try {
      if (refreshCurate) {
        setLoadingEdition(true);
      }
      const editionData = await API.getEditions({
        editionURNs: [id],
        fieldList: EDITION_FIELDS.REVIEW,
      });
      const editionDetail = editionData[0];
      if (!editionDetail.sections) {
        editionDetail.sections = campaignDetails.sections.map(section => ({
          sectionURN: section.sectionURN,
          articles: section.articles ?? [],
        }));
      }
      if (!editionDetail.bodyElementPositioning) {
        editionDetail.bodyElementPositioning =
          campaignDetails.bodyElementPositioning;
      }
      flushSync(() => {
        setEditionDetails(editionDetail);
        setEmailSubject(editionDetail.editionSubject);
        setTempEmailSubject(editionDetail.editionSubject);
        setAllArticlesOptimal(editions.areAllArticlesOptimal(editionDetail));
        if (refreshCurate) {
          setLoadingEdition(false);
        }
      });
      return editionDetail;
    } catch (error) {
      logger.error({
        event: 'Curation:fetchEditionDetails',
        error,
      });
      setErrorMessage('Unable to load Edition details');
      if (refreshCurate) {
        setLoadingEdition(false);
      }
      return null;
    }
  };

  useEffect(() => {
    const initialiseComponent = async () => {
      logger.info('Curation:initialiseComponent');

      setLoadingEdition(true);
      const editionDetail = await fetchEditionDetails();
      getPreview();
      tracker.track({
        eventName: 'Open Curate Page',
        trackingParams: {
          'Email ID': editionDetail.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetail.approvalState],
        },
      });
      const currentTime = datetime.getUnixTimestamp();
      const {
        approvalState,
        editionSource,
        lastUpdatedUnixTime,
        editionState,
        scheduledUnixTime,
      } = editionDetail;

      setEditionDisplayState(
        editions.getEditionDisplayState(
          approvalState,
          editionSource,
          lastUpdatedUnixTime,
          editionState,
          scheduledUnixTime,
          currentTime
        )
      );
      setLoadingEdition(false);
    };
    initialiseComponent();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleApprove = async isDraft => {
    logger.info(`Curation:handleApprove - isDraft ${isDraft}`);

    const articles = editionDetails.sections.flatMap(
      section => section.articles
    );

    const articlesByMediaURN = articles
      .filter(article => !article?.isAdditionalForContentSelection)
      .reduce(
        (acc, article) => ({
          ...acc,
          [article.mediaURN]: [...(acc[article.mediaURN] || []), article],
        }),
        {}
      );
    const newDuplicateArticles = Object.keys(articlesByMediaURN)
      .filter(mediaURN => articlesByMediaURN[mediaURN].length > 1)
      .reduce(
        (acc, mediaURN) => ({
          ...acc,
          [mediaURN]: articlesByMediaURN[mediaURN],
        }),
        {}
      );
    setDuplicateArticles(newDuplicateArticles);

    const shouldDisplayDuplicateArticlesAlert =
      Object.keys(newDuplicateArticles).length > 0 &&
      // Only display the alert once
      !duplicateArticles;
    if (
      !isDraft &&
      !displayDuplicateArticlesAlert &&
      shouldDisplayDuplicateArticlesAlert
    ) {
      setDisplayDuplicateArticlesAlert(true);
      return;
    }

    const shouldDisplayArticleChangedAlert =
      hasArticlesChanged && usedAISubjectLine;
    if (
      !isDraft &&
      !displayArticleChangedAlert &&
      shouldDisplayArticleChangedAlert
    ) {
      setDisplayArticleChangedAlert(true);
      return;
    }

    try {
      const eventName = isDraft ? 'Save draft Edition' : 'Approve Edition';

      flushSync(() => {
        setApproving(true);
      });

      // When Approve or save draft button is pressed, update the send times if necessary
      const updatedTimes = schedule.updateTimes(
        editionDetails.scheduledUnixTime,
        editionDetails.sendingTimeslotSizeSeconds
      );

      editionDetails.scheduledUnixTime = updatedTimes.scheduledUnixTime;
      editionDetails.sendingTimeslotSizeSeconds = updatedTimes.timeSlotSize;

      const fieldsToUpdate = {
        approvalState: isDraft
          ? EDITION_APPROVAL_STATES.ECHOBOX_APPROVED
          : EDITION_APPROVAL_STATES.CLIENT_APPROVED,
      };
      await editions.updateEdition(editionDetails, fieldsToUpdate);

      const trackingParams = {
        'Email ID': editionDetails.editionURN,
        'Edition State Before':
          EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
        Subject: editionDetails.editionSubject,
        'Preview Text': editionDetails.emailPreviewText,
        'Number of Sections': editionDetails.sections.length,
        Sections: JSON.stringify(editionDetails.sections),
      };

      if (usedAISubjectLine) {
        const aiSubjectLineEdited = !aiSubjectLines.includes(emailSubject);
        trackingParams['Subject Line Type'] = aiSubjectLineEdited
          ? 'AI-edited'
          : 'AI';
        trackingParams['AI Subject Line'] = usedAISubjectLine;
        trackingParams['Subject Line / AI Subject Line Levenshtein Distance'] =
          levenshtein(usedAISubjectLine, emailSubject);
      } else {
        trackingParams['Subject Line Type'] = 'Manual';
      }

      tracker.track({
        eventName,
        trackingParams,
      });
      if (isDraft) {
        toast({ variant: 'success', title: 'Draft Saved' });
      }

      window.location.href = `/campaigns/${campaignDetails.campaignURN}`;
    } catch (error) {
      logger.error({
        event: 'Curation:handleApprove',
        error,
      });
      const message = isDraft
        ? 'Unable to save draft Edition'
        : 'Unable to approve Edition';

      const extractedErrorMessage = errors.getErrorMessage(error);
      if (extractedErrorMessage) {
        setErrorMessage(`${message}: ${extractedErrorMessage}`);
      } else {
        setErrorMessage(message);
      }
      setApproving(false);
    }
  };

  const handleErrorClose = () => setErrorShow(false);

  const findFirstArticleTitle = listOfSections => {
    if (listOfSections) {
      for (let i = 0; i < listOfSections.length; i += 1) {
        if (listOfSections[i]) {
          const { articles } = listOfSections[i];
          for (let j = 0; j < articles.length; j += 1) {
            if (articles[j] && articles[j].title && articles[j].title !== '') {
              return articles[j].title;
            }
          }
        }
      }
    }
    return null;
  };

  const findSafeFirstArticleTitle = listOfSections => {
    const title = findFirstArticleTitle(listOfSections);
    if (title) {
      return title;
    }
    return campaignDetails.campaignName;
  };

  const shouldUpdateSubjectLine = updatedEdition => {
    logger.info('Curation:shouldUpdateSubjectLine');

    if (subjectLineUsesArticleTitle) {
      const oldTitle = findFirstArticleTitle(editionDetails.sections);
      if (
        oldTitle === updatedEdition.editionSubject ||
        (oldTitle === null &&
          updatedEdition.editionSubject === campaignDetails.campaignName)
      ) {
        return true;
      }
    }
    return false;
  };

  const shouldUpdatePreviewText = () => {
    // If it's custom you never want to update preview text when changing article
    if (previewTextType === EMAIL_PREVIEW_TEXT_TYPES.CUSTOM) return false;

    const settingsPreviewText = getSettingsPreviewText(editionDetails.sections);

    // If we have the article field required by the settings, we check if the user has made any manual changes to the preview text.
    if (settingsPreviewText)
      return editionDetails.emailPreviewText === settingsPreviewText;

    // If we don't have access to the article required by the settings, we assume we want to update the preview text
    return true;
  };

  const getSettingsPreviewText = currentSections => {
    switch (previewTextType) {
      case EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_TITLE: {
        const article = sections.getArticle(currentSections, 0);
        return sanitise.noHTML(article?.title);
      }
      case EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_DESCRIPTION: {
        const article = sections.getArticle(currentSections, 0);
        return sanitise.noHTML(article?.description);
      }
      case EMAIL_PREVIEW_TEXT_TYPES.SECOND_ARTICLE_TITLE: {
        const article = sections.getArticle(currentSections, 1);
        return sanitise.noHTML(article?.title);
      }
      default:
        return null;
    }
  };

  const handleSaveArticleDetails = async (
    sectionURN,
    index,
    finalURL,
    title,
    description,
    imageURL,
    mediaURN,
    callback
  ) => {
    logger.info(
      `Curation:handleSaveArticleDetails url ${finalURL} index ${index}`
    );

    const articleDetails = sections.getSectionArticle(
      sectionURN,
      index - 1, // The index passed down to child components is 1-based for Mixpanel purposes
      editionDetails.sections
    );
    const trackSaveArticleDetails = success => {
      const sectionDetails = sections.getSection(
        sectionURN,
        editionDetails.sections
      );
      tracker.track({
        eventName: 'Update Article Information',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
          'Headline Before': articleDetails?.title,
          Headline: title,
          'Description Before': articleDetails?.description,
          Description: description,
          'Article Before': articleDetails?.articleURL,
          Article: finalURL,
          'Image Link Before': articleDetails?.imageURL,
          'Image Link': imageURL,
          Section: sectionDetails?.sectionName,
          'Section Index': (sectionDetails?.index ?? 0) + 1,
          'Article Index': index,
          Result: success ? 'Success' : 'Failure',
        },
      });
    };

    try {
      setSavingArticleDetails(true);

      const updatedSections = sections.updateArticleDetails(
        editionDetails.sections,
        {
          sectionURN,
          articleURL: url.addTrackingParameters(
            finalURL,
            campaignDetails.campaignName,
            editionDetails.scheduledUnixTime
          ),
          index,
          title,
          description,
          ...(imageURL && { imageURL }),
          ...(mediaURN && { mediaURN }),
        }
      );

      const subjectLineNeedsUpdating = shouldUpdateSubjectLine({
        ...editionDetails,
        sections: updatedSections,
      });
      let subjectLine = editionDetails.editionSubject;
      if (subjectLineNeedsUpdating) {
        subjectLine = findSafeFirstArticleTitle(updatedSections);
      }
      let previewText = editionDetails.emailPreviewText;
      if (shouldUpdatePreviewText()) {
        previewText = getSettingsPreviewText(updatedSections);
      }
      logger.info(
        `Curation:handleSaveArticleDetails - subject ${subjectLine} preview ${previewText}`
      );

      const fieldsToUpdate = {
        approvalState: approvalStateAfterChange(),
        editionSubject: subjectLine,
        emailPreviewText: previewText,
        sections: updatedSections,
      };
      await updateEditionAsync(fieldsToUpdate);

      trackSaveArticleDetails(true);
      callback();
      setSavingArticleDetails(false);

      if (finalURL !== articleDetails?.articleURL) {
        handleArticlesChanged();
      }
    } catch (error) {
      callback();
      logger.error({
        event: 'Curation:handleSaveArticleDetails',
        error,
      });
      setErrorMessage('Unable to save article changes');
      trackSaveArticleDetails(false);
      setSavingArticleDetails(false);
    }
  };

  const handleDeleteArticle = async (sectionURN, index) => {
    logger.info(
      `Curation:handleDeleteArticle - section ${sectionURN} index ${index}`
    );

    const trackDeleteArticleDetails = success => {
      const sectionDetails = sections.getSection(
        sectionURN,
        editionDetails.sections
      );
      tracker.track({
        eventName: 'Delete Article',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
          Section: sectionDetails?.sectionName,
          'Section Index': (sectionDetails?.index ?? 0) + 1,
          Result: success ? 'Success' : 'Failure',
        },
      });
    };

    try {
      setSavingArticleDetails(true);

      let newSection = {
        ...sections.getSection(sectionURN, editionDetails.sections),
      };

      const newArticles = [...newSection.articles];
      newSection = { ...newSection, articles: [...newSection.articles] };
      newArticles.splice(index - 1, 1);
      newSection = { ...newSection, articles: [...newArticles] };

      const updatedSections = sections.updateSections(
        [...editionDetails.sections],
        newSection
      );

      const subjectLineNeedsUpdating = shouldUpdateSubjectLine({
        ...editionDetails,
        sections: updatedSections,
      });
      let subjectLine = editionDetails.editionSubject;
      if (subjectLineNeedsUpdating) {
        subjectLine = findSafeFirstArticleTitle(updatedSections);
      }
      let previewText = editionDetails.emailPreviewText;
      if (shouldUpdatePreviewText()) {
        previewText = getSettingsPreviewText(updatedSections);
      }
      logger.info(
        `Curation:handleDeleteArticle - subject ${subjectLine} preview ${previewText}`
      );

      const fieldsToUpdate = {
        approvalState: approvalStateAfterChange(),
        editionSubject: subjectLine,
        emailPreviewText: previewText,
        sections: updatedSections,
      };
      await updateEditionAsync(fieldsToUpdate);

      trackDeleteArticleDetails(true);
      setSavingArticleDetails(false);

      handleArticlesChanged();
    } catch (error) {
      logger.error({
        event: 'Curation:handleSaveArticleDetails',
        error,
      });
      setErrorMessage('Unable to save article changes');
      trackDeleteArticleDetails(false);
      setSavingArticleDetails(false);
    }
  };

  const handleSaveEditionSubject = async (finalEditionSubject, callback) => {
    logger.info(
      `Curation:handleSaveEditionSubject - subject ${finalEditionSubject}`
    );

    try {
      setSavingEditionSubject(true);

      const fieldsToUpdate = {
        approvalState: approvalStateAfterChange(),
        editionSubject: finalEditionSubject,
      };
      await updateEditionAsync(fieldsToUpdate, {
        updatePreview: false,
        refreshCurate: false,
      });

      if (finalEditionSubject !== editionDetails.editionSubject) {
        tracker.track({
          eventName: 'Edit Subject',
          trackingParams: {
            'Email ID': editionDetails.editionURN,
            'Edition State Before':
              EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
            'Subject Before': editionDetails.editionSubject,
            Subject: finalEditionSubject,
          },
        });
      }
      callback();
      setSavingEditionSubject(false);
      setEmailSubject(finalEditionSubject);
      toast({ variant: 'success', title: 'Subject line saved' });
    } catch (error) {
      callback();
      logger.error({
        event: 'Curation:handleSaveEditionSubject',
        error,
      });
      setErrorMessage('Unable to save subject changes');
      setSavingArticleDetails(false);
    }
  };

  const handleSaveTextBlock = async (
    textBlockURN,
    textBlockBody,
    textBlockTitle,
    imageURL,
    callback
  ) => {
    logger.info(
      `Curation:handleSaveTextBlock - block ${textBlockURN} title ${textBlockTitle}`
    );

    /**
     * When saving a custom block, the imageURL parameter isn't provided,
     * so the fourth is actually the callback, in which case copy the callback
     * to the correct parameter and give the image field a default value
     */
    if (!callback) {
      /* eslint-disable-next-line no-param-reassign */
      callback = imageURL;
      /* eslint-disable-next-line no-param-reassign */
      imageURL = null;
    }

    try {
      setSavingTextBlock(true);
      const originalTextBlock = getTextBlock(
        textBlockURN,
        editionDetails.textBlocks
      );

      const originalBody = originalTextBlock.textBlockBody;
      const originalTitle = originalTextBlock.textBlockTitle;
      const originalImageURL = originalTextBlock.imageURL;

      const fieldsToUpdate = {
        approvalState: approvalStateAfterChange(),
        textBlocks: updateTextBlocks(editionDetails.textBlocks, {
          textBlockURN,
          textBlockTitle,
          textBlockBody,
          imageURL,
        }),
      };
      await updateEditionAsync(fieldsToUpdate, {
        updatePreview: true,
        refreshCurate: false,
      });

      const readerFirstNameUsed = tags.isTagUsedInBlock(
        'first_name',
        textBlockTitle,
        textBlockBody
      );
      const readerLastNameUsed = tags.isTagUsedInBlock(
        'last_name',
        textBlockTitle,
        textBlockBody
      );
      tracker.track({
        eventName: 'Edit Text Block',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
          'Title Before': originalTitle,
          Title: textBlockTitle,
          'Text Body Before': originalBody,
          'Text Body': textBlockBody,
          'Image Before': originalImageURL,
          Image: imageURL,
          Origin: 'Curate Screen',
          'Email Type': 'Newsletter',
          'Reader First Name Used': readerFirstNameUsed,
          'Reader Last Name Used': readerLastNameUsed,
        },
      });
      callback();
      setSavingTextBlock(false);
    } catch (error) {
      callback();
      logger.error({
        event: 'Curation:handleSaveTextBlock',
        error,
      });
      setErrorMessage('Unable to save text changes');
      setSavingTextBlock(false);
    }
  };

  const handleSavePromotionBlock = async (
    promotionBlockURN,
    imageURL,
    inputDestinationURL,
    callback
  ) => {
    logger.info(
      `Curation:handleSavePromotionBlock - block ${promotionBlockURN} image ${imageURL}`
    );

    try {
      const originalBlock = getPromotionBlock(
        promotionBlockURN,
        editionDetails.promotionBlocks
      );
      const originalImageURL = originalBlock ? originalBlock.imageURL : null;
      const originalDestinationURL = originalBlock
        ? originalBlock.destinationURL
        : null;

      // Add UTM tags to the input destination URL
      const destinationURL =
        inputDestinationURL === ''
          ? ''
          : url.addTrackingParameters(
              inputDestinationURL,
              campaignDetails.campaignName,
              editionDetails.scheduledUnixTime
            );

      const fieldsToUpdate = {
        approvalState: approvalStateAfterChange(),
        promotionBlocks: updatePromotionBlocks(editionDetails.promotionBlocks, {
          promotionBlockURN,
          imageURL,
          destinationURL,
        }),
      };
      await updateEditionAsync(fieldsToUpdate, {
        updatePreview: true,
        refreshCurate: false,
      });

      tracker.track({
        eventName: 'Save Promotion Blocks',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
          'Block Image URL Before': originalImageURL,
          'Block Image URL After': imageURL,
          'Block Destination URL Before': originalDestinationURL,
          'Block Destination URL After': destinationURL,
        },
      });
      callback();
    } catch (error) {
      callback();
      logger.error({
        event: 'Curation:handleSavePromotionBlock',
        error,
      });
      setErrorMessage('Unable to save Promotion Block changes');
    }
    setSavingPromotionBlock(false);
  };

  const handleSavePromotionBlockURLs =
    urn => (imageURL, destinationURL, callback) => {
      handleSavePromotionBlock(urn, imageURL, destinationURL, callback);
    };

  const handleSwapArticles = async (sectionURN, fromIndex, toIndex) => {
    logger.info(
      `Curation:handleSwapArticles - from ${fromIndex} to ${toIndex}`
    );

    try {
      setSavingArticleDetails(true);

      const updatedSections = sections.swapArticleDetails(
        editionDetails.sections,
        {
          sectionURN,
          fromIndex,
          toIndex,
        }
      );

      const subjectLineNeedsUpdating = shouldUpdateSubjectLine({
        ...editionDetails,
        sections: updatedSections,
      });
      let subjectLine = editionDetails.editionSubject;
      if (subjectLineNeedsUpdating) {
        subjectLine = findSafeFirstArticleTitle(updatedSections);
      }
      let previewText = editionDetails.emailPreviewText;
      if (shouldUpdatePreviewText()) {
        previewText = getSettingsPreviewText(updatedSections);
      }
      logger.info(
        `Curation:handleSwapArticles - subject ${subjectLine} preview ${previewText}`
      );

      const fieldsToUpdate = {
        approvalState: approvalStateAfterChange(),
        editionSubject: subjectLine,
        emailPreviewText: previewText,
        sections: updatedSections,
      };
      await updateEditionAsync(fieldsToUpdate);

      const articleDetails = sections.getSectionArticle(
        sectionURN,
        fromIndex,
        editionDetails.sections
      );
      const sectionDetails = sections.getSection(
        sectionURN,
        editionDetails.sections
      );
      tracker.track({
        eventName: 'Drag-and-Drop Articles',
        trackingParams: {
          'Email ID': editionDetails.editionURN,
          'Edition State Before':
            EDITION_APPROVAL_STATE_NAMES[editionDetails.approvalState],
          Headline: articleDetails?.title,
          Description: articleDetails?.description,
          Article: articleDetails?.articleURL,
          Section: sectionDetails?.sectionName,
          'Section Index': (sectionDetails?.index ?? 0) + 1,
          'Source Article Index': fromIndex,
          'Destination Article Index': toIndex,
          'Updated Subject Line': subjectLineNeedsUpdating,
        },
      });
      setSavingArticleDetails(false);
    } catch (error) {
      logger.error({
        event: 'Curation:handleSwapArticles',
        error,
      });
      setErrorMessage('Unable to save article changes');
      setSavingArticleDetails(false);
    }
  };

  if (isLoadingEdition || isEditionEditable === null) {
    return (
      <div>
        <Header centered={true} />
        <div className="align-items-center curation__loading mx-2 d-flex justify-content-center">
          <div className="m-0">
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  const createSectionReactComponent = sectionURN => {
    const editionSection = sections.getSection(
      sectionURN,
      editionDetails.sections
    );
    const campaignSection = sections.getSection(
      sectionURN,
      campaignDetails.sections
    );

    return (
      <Box key={`section-${editionSection.sectionURN}`}>
        {isContentPersonalisationEnabled && <ContentPersonalisation />}
        <Section
          areAllArticlesOptimal={areAllArticlesOptimal}
          dataSources={campaignSection?.dataSources}
          handleDeleteArticle={handleDeleteArticle}
          handleSaveArticleDetails={handleSaveArticleDetails}
          handleSwapArticles={handleSwapArticles}
          isEditionEditable={
            isEditionEditable && !isContentPersonalisationEnabled
          }
          isSavingArticleDetails={isSavingArticleDetails}
          section={editionSection}
        />
      </Box>
    );
  };

  const createPromotionBlockReactComponent = urn => {
    const block = getPromotionBlock(urn, editionDetails.promotionBlocks);
    if (block) {
      return (
        <Promotion
          {...block}
          campaignDetails={campaignDetails}
          handleSavePromotionBlock={handleSavePromotionBlockURLs(urn)}
          isEditionEditable={isEditionEditable}
          isSavingPromotionBlock={isSavingPromotionBlock}
          setSaving={setSavingPromotionBlock}
          key={`promotion-${block.promotionBlockURN}`}
        />
      );
    }
    return null;
  };

  const createTextBlockReactComponent = urn => {
    const block = getTextBlock(urn, editionDetails.textBlocks);
    let component = null;
    if (block) {
      if (blocks.isCustomBlock(block.textBlockTitle)) {
        component = (
          <CustomBlock
            customBlock={block}
            handleSave={handleSaveTextBlock}
            isEditable={isEditionEditable}
            isSaving={isSavingTextBlock}
            key={block.textBlockURN}
          />
        );
      } else {
        component = (
          <TextBlock
            campaignDetails={campaignDetails}
            editionDetails={editionDetails}
            textBlock={block}
            handleSaveTextBlock={handleSaveTextBlock}
            isSavingTextBlock={isSavingTextBlock}
            isEditionEditable={isEditionEditable}
            key={block.textBlockURN}
            personalisationSettings={personalisationSettings}
          />
        );
      }
    }
    return component;
  };

  const getElementReactComponent = element => {
    const urn = element.elementURN;
    switch (getURNType(urn)) {
      case URN_TYPES.SECTION:
        return createSectionReactComponent(urn);
      case URN_TYPES.PROMOTION_BLOCK:
        return createPromotionBlockReactComponent(urn);
      case URN_TYPES.TEXT_BLOCK:
        return createTextBlockReactComponent(urn);
      default:
        return null;
    }
  };

  const campaignDetailsMap = editionDetails?.bodyElementPositioning.map(
    element => getElementReactComponent(element)
  );

  const showLeftPanel = editionDisplayState !== EDITION_DISPLAY_STATES.SENT;

  return (
    <ErrorBoundary FallbackComponent={CurateError}>
      <CurateWrapper
        campaignDetails={campaignDetails}
        handleApprove={handleApprove}
        isApproving={isApproving}
        editionDetails={editionDetails}
        // eslint-disable-next-line react/jsx-no-bind
        updateEditionDetails={updateEditionAsync}
        errorShow={errorShow}
        handleErrorClose={handleErrorClose}
        errorMessage={errorMessage}
        editionPreview={preview}
        isLoadingPreview={isLoadingPreview}
        isEditionEditable={isEditionEditable}
        isDomainVerificationComplete={isDomainVerificationComplete}
        displayDuplicateArticlesAlert={displayDuplicateArticlesAlert}
        duplicateArticles={duplicateArticles}
        handleCloseDuplicateArticlesAlert={handleCloseDuplicateArticlesAlert}
        displayArticleChangedAlert={displayArticleChangedAlert}
        handleCloseArticleChangedAlert={handleCloseArticleChangedAlert}
        emailSubjectInputRef={emailSubjectInputRef}
        aiSubjectLines={aiSubjectLines}
        setAISubjectLine={setAISubjectLine}
        handleOpenAISubjectLines={handleOpenAISubjectLines}
        showLeftPanel={showLeftPanel}
        setTempEmailSubject={setTempEmailSubject}
      >
        {showLeftPanel && (
          <Flex flexDir="column" gap={16}>
            <Flex flexDir="column" gap={8}>
              <EmailSubject
                approvalState={editionDetails?.approvalState}
                emailSubject={emailSubject}
                emailSubjectInputRef={emailSubjectInputRef}
                aiSubjectLines={aiSubjectLines}
                setAISubjectLine={setAISubjectLine}
                hasSeenAISubjectLines={hasSeenAISubjectLines}
                handleOpenAISubjectLines={handleOpenAISubjectLines}
                defaultEmailSubject={defaultEmailSubject()}
                handleSaveEditionSubject={handleSaveEditionSubject}
                isSavingEditionSubject={isSavingEditionSubject}
                campaignName={campaignDetails.campaignName}
                sections={editionDetails.sections}
                findFirstArticleTitle={findSafeFirstArticleTitle}
                isEditionEditable={isEditionEditable}
                isApproving={isApproving}
                tempEmailSubject={tempEmailSubject}
                setTempEmailSubject={setTempEmailSubject}
              />
              <PreviewText
                edition={editionDetails}
                updateEdition={updateEditionAsync}
              />
            </Flex>
            <Flex flexDir="column" gap={8}>
              {campaignDetailsMap}
            </Flex>
          </Flex>
        )}
      </CurateWrapper>
    </ErrorBoundary>
  );
}

export default Curation;
