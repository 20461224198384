import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function putSubscriberUploads({
  campaignURN = mandatory('campaignURN'),
  uploadState = mandatory('uploadState'),
  uploadURN = mandatory('uploadURN'),
}) {
  const guid = metrics.start('putSubscriberUploads');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/subscribers/uploads`,
    method: 'POST', // Yes this is deliberate
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      uploadState,
      uploadURN,
    }),
  };
  logger.info(
    `API:putSubscriberUploads /campaigns/${campaignURN}/subscribers/uploads`
  );

  try {
    const response = await axios(config);
    metrics.end('putSubscriberUploads', guid);
    return response.data;
  } catch (error) {
    metrics.fail('putSubscriberUploads', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/putSubscriberUploads',
      args: { campaignURN, uploadURN, uploadState },
    });
    throw apiError;
  }
}
