import EditionState from 'components/campaigns/editions/EditionState';

import useDeveloperMode from 'hooks/useDeveloperMode';
import useStaffMode from 'hooks/useStaffMode';

function Modes() {
  const [isStaffModeActive] = useStaffMode();
  const [isDeveloperModeActive] = useDeveloperMode('TEC-17271'); // Developer Mode

  return (
    <div className="d-flex justify-content-center align-items-center">
      {isStaffModeActive && (
        <EditionState
          editionDisplayState="STAFF_MODE"
          labelClass="ebx-staff-mode-label"
          displayLabel="Staff Mode"
        />
      )}
      {isDeveloperModeActive && (
        <EditionState
          editionDisplayState="DEVELOPER_MODE"
          labelClass="ebx-developer-mode-label"
          displayLabel="Developer Mode"
        />
      )}
    </div>
  );
}

export default Modes;
