import { Box, Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import AlignPicker from 'components/editor/blocks/attributes/AlignPicker';
import ColorPicker from 'components/editor/blocks/attributes/ColorPicker';
import FontPicker from 'components/editor/blocks/attributes/FontPicker';
import NumberPicker from 'components/editor/blocks/attributes/NumberPicker';
import SelectPicker from 'components/editor/blocks/attributes/SelectPicker';

import * as blocks from 'common/blocks';

interface TextProps {
  onChange: (key: string, value: any) => void;
  value: {
    align: string;
    color: string;
    fontFamily: string;
    fontSize: string;
    fontStyle: string;
    fontWeight: string;
    letterSpacing: string;
    textDecoration: string;
    textTransform: string;
  };
}

function Text({ onChange, value }: TextProps) {
  if (!value) return null;

  const handleChange = (name: string, newValue: any) => {
    onChange(name, newValue);
  };

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h4">Text</Heading>
      <Flex direction="column" gap={4}>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Font Family</Box>
          <Box w="50%">
            <FontPicker
              name="fontFamily"
              onChange={handleChange}
              options={blocks.WYSIWYG_FONT_FAMILIES}
              value={value.fontFamily}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Font Size</Box>
          <Box w="50%">
            <NumberPicker
              min={0}
              name="fontSize"
              onChange={handleChange}
              unit="px"
              value={value.fontSize}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Font Style</Box>
          <Box w="50%">
            <SelectPicker
              name="fontStyle"
              onChange={handleChange}
              options={blocks.WYSIWYG_FONT_STYLES}
              value={value.fontStyle}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Font Weight</Box>
          <Box w="50%">
            <SelectPicker
              name="fontWeight"
              onChange={handleChange}
              options={blocks.WYSIWYG_FONT_WEIGHTS}
              value={value.fontWeight}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Color</Box>
          <Box w="50%">
            <ColorPicker
              name="color"
              onChange={handleChange}
              value={value.color}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Alignment</Box>
          <Box w="50%">
            <AlignPicker
              name="align"
              onChange={handleChange}
              value={value.align}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Letter Spacing</Box>
          <Box w="50%">
            <NumberPicker
              min={-1}
              max={2}
              name="letterSpacing"
              onChange={handleChange}
              step={0.1}
              unit="em"
              value={value.letterSpacing}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Text Decoration</Box>
          <Box w="50%">
            <SelectPicker
              name="textDecoration"
              onChange={handleChange}
              options={blocks.WYSIWYG_TEXT_DECORATIONS}
              value={value.textDecoration}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="row">
          <Box w="50%">Text Transform</Box>
          <Box w="50%">
            <SelectPicker
              name="textTransform"
              onChange={handleChange}
              options={blocks.WYSIWYG_TEXT_TRANSFORMS}
              value={value.textTransform}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Text;
