import { Box, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import Edition from 'components/campaigns/editions/Edition';

import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import { checkIsEditionVisible, getEditionDisplayState } from 'common/editions';
import type { Edition as EditionType } from 'common/types';
import useStaffMode from 'hooks/useStaffMode';

interface UnsentEmailsProps {
  editionList: ReadonlyArray<EditionType>;
  loadEditionData: () => void;
  title: string;
}

function UnsentEmails({
  editionList,
  loadEditionData,
  title,
}: UnsentEmailsProps) {
  const [isStaffModeActive] = useStaffMode();

  const currentTime = datetime.getUnixTimestamp();
  // Get number of visible editions
  const numOfVisibleEditions = editionList
    .map(edition =>
      editions.isEdition(edition)
        ? Number(
            checkIsEditionVisible(
              getEditionDisplayState(
                edition.approvalState,
                edition.editionSource,
                edition.lastUpdatedUnixTime,
                edition.editionState,
                edition.scheduledUnixTime,
                currentTime
              )
            )
          )
        : 1
    )
    .reduce((a, b) => a + b, 0);

  const showUnsentEmailSection =
    numOfVisibleEditions > 0 || (editionList.length > 0 && isStaffModeActive);

  if (!showUnsentEmailSection) {
    return null;
  }
  return (
    <Box data-cy="scheduledEmails" mb={8}>
      <Heading variant="h3" color="gray.900" mb={4}>
        {title}
      </Heading>
      {editionList.map(edition => (
        <Edition
          edition={edition}
          key={edition.editionURN}
          loadEditionData={loadEditionData}
        />
      ))}
    </Box>
  );
}

export default UnsentEmails;
