import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ReactComponent as Ellipsis } from 'assets/svg/ellipsis.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/link-no-fill.svg';

import Thumbnail from 'components/common/Thumbnail';

import './PromotionBlock.css';

interface PromotionBlockProps {
  imageURL: string;
  destinationURL: string | null | undefined;
  onShowModal: () => void;
  isEditionEditable: boolean;
}

function PromotionBlock({
  imageURL,
  destinationURL,
  onShowModal,
  isEditionEditable,
}: PromotionBlockProps) {
  return (
    <Button
      variant="link"
      className={`${
        isEditionEditable
          ? 'ebx-article-section'
          : 'ebx-disabled-article ebx-disabled-article-button'
      } article-section w-100 article-rounded p-0 add-promotion`}
      onClick={isEditionEditable ? onShowModal : undefined}
    >
      <div
        className={`article-content ebx-body-2 ebx-faded-colour d-flex align-items-center px-3 ${
          !isEditionEditable && 'ebx-disabled-text'
        }`}
      >
        <Thumbnail
          image={imageURL}
          height="48px"
          width="85px"
          alt="Promotion block image"
        />
        {destinationURL && destinationURL !== '' && (
          <div className="ebx-h3 ebx-faded-link promotion-block-link">
            <span>
              <LinkIcon className="mr-1" fill="inherit" />
              {destinationURL}
            </span>
          </div>
        )}
        {isEditionEditable && (
          <div className="d-flex justify-content-end promotion-block-ellipsis">
            <Link
              className="p-0 promotion-block-ellipsis-button"
              to="#"
              onClick={onShowModal}
            >
              <Ellipsis />
            </Link>
          </div>
        )}
      </div>
    </Button>
  );
}

export default PromotionBlock;
