import * as datetime from 'common/datetime';

const getLastEditedLabel = (timestamp?: number) => {
  if (timestamp) {
    const secondAgo = datetime.getUnixTimestamp() - timestamp;
    if (secondAgo < 60 * 60) {
      return `${Math.round(secondAgo / 60)} min ago`;
    }

    if (secondAgo < 24 * 60 * 60) {
      return `${Math.round(secondAgo / (60 * 60))} hr ago`;
    }

    return `${Math.round(secondAgo / (60 * 60 * 24))} days ago`;
  }

  return '';
};

export interface EditionLastUpdateProps {
  lastUpdatedByEmail: string;
  lastUpdatedUnixTime?: number;
}

function EditionLastUpdate({
  lastUpdatedByEmail,
  lastUpdatedUnixTime,
}: EditionLastUpdateProps) {
  const showLastUpdated =
    lastUpdatedByEmail && lastUpdatedByEmail !== '' && lastUpdatedUnixTime;

  if (!showLastUpdated) {
    return null;
  }

  return (
    <div className="d-flex flex-xl-shrink-0 ebx-body-2 ebx-faded-colour ml-2">
      <div className="pr-1">{lastUpdatedByEmail}</div>
      <div> • </div>
      <div className="pl-1">{getLastEditedLabel(lastUpdatedUnixTime)}</div>
    </div>
  );
}

EditionLastUpdate.defaultProps = {
  lastUpdatedUnixTime: null,
};

export default EditionLastUpdate;
