import { Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useState } from 'react';

import DatePickerInput from 'components/editor/schedule/DatePickerInput';
import DatePicker from 'react-datepicker';

import { DATE_PICKER_START_DAY } from 'common/constants';
import { getOptimalTimeslot } from 'common/editions';

import { POPPER_MODIFIERS } from 'common/config';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

function OptimalSend({
  scheduledUnixTime,
  setScheduledUnixTime,
  setFinishedSendingTime,
}) {
  const [date, setDate] = useState(new Date(scheduledUnixTime * 1000));

  const changeDate = newDate => {
    setDate(newDate);

    // Get optimal range for chosen date
    const optimalRange = getOptimalTimeslot(newDate);
    setScheduledUnixTime(optimalRange.optimalStartTime);
    setFinishedSendingTime(optimalRange.optimalEndTime);
  };

  return (
    <div className="mt-2 pl-4 w-100">
      <Heading variant="h5" color="gray.700" fontWeight="normal">
        Let Echobox optimize the send time for each individual email to increase
        Open Rates.
      </Heading>
      <div className="mt-2">
        <div className="date-picker-container">
          <DatePicker
            customInput={<DatePickerInput date={true} />}
            dateFormat="d-MMM-yy"
            minDate={new Date()}
            popperModifiers={POPPER_MODIFIERS}
            onChange={changeDate}
            selected={date}
            calendarStartDay={DATE_PICKER_START_DAY}
          />
        </div>
      </div>
    </div>
  );
}

OptimalSend.propTypes = {
  scheduledUnixTime: PropTypes.number.isRequired,
  setScheduledUnixTime: PropTypes.func.isRequired,
  setFinishedSendingTime: PropTypes.func.isRequired,
};

export default OptimalSend;
