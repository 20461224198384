import axios from 'axios';

import * as cookie from 'common/cookie';
import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import * as url from 'common/url';
import * as utility from 'common/utility';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';
import {
  NO_PERMISSIONS_PREFIX,
  NO_PERMISSIONS_STATUS_CODES,
} from 'common/constants';
import { CENTRALISED_LOGIN } from 'common/toggles';

export default async function postServiceAuth({
  overrideUsername = null,
  overrideCampaignURN = null,
  overridePropertyURN = null,
} = {}) {
  const guid = metrics.start('postServiceAuth');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/serviceauth`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(
      {},
      true // shouldSendCognitoTokens
    ),
  };

  if (overrideUsername) {
    config.data = JSON.stringify({ overrideUsername });
    logger.info(
      `API:postServiceAuth /serviceauth overrideUsername: ${overrideUsername}`
    );
  } else if (overrideCampaignURN) {
    config.data = JSON.stringify({ overrideCampaignURN });
    logger.info(
      `API:postServiceAuth /serviceauth overrideCampaignURN: ${overrideCampaignURN}`
    );
  } else if (overridePropertyURN) {
    config.data = JSON.stringify({ overridePropertyURN });
    logger.info(
      `API:postServiceAuth /serviceauth overridePropertyURN: ${overridePropertyURN}`
    );
  } else {
    logger.info(`API:postServiceAuth /serviceauth`);
  }

  try {
    const response = await axios(config);
    // Add the client service token from the header to the response
    const resp = response.data;
    resp.clientServiceToken = response.headers['x-ebx-clientservicetoken'];
    metrics.end('postServiceAuth', guid);
    cookie.deleteCookie(`${NO_PERMISSIONS_PREFIX}${window.location.host}`, {
      domain: url.getTLD(window.location.hostname),
    });
    return resp.clientServiceToken;
  } catch (error) {
    metrics.fail('postServiceAuth', guid);
    if (CENTRALISED_LOGIN && doesNotHavePermissions(error)) {
      const newError = utility.cloneError(error);
      newError.noPermissions = true;
      throw newError;
    }

    // Just rethrow if the user doesn't exist
    if (isUserDoesNotExistError(error)) {
      throw error;
    }

    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postServiceAuth',
      args: { overrideUsername },
    });
    throw apiError;
  }
}

export function isUserDoesNotExistError(error) {
  return (
    error.response?.status === 404 &&
    error.response.data?.error?.target === 'usernameToOverride'
  );
}

function doesNotHavePermissions(error) {
  const { response } = error;
  return NO_PERMISSIONS_STATUS_CODES.includes(response?.status);
}
