import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSetRecoilState } from 'recoil';

import { Box, Flex, useTheme } from '@ebx-ui/ebx-ui-component-library-sdk';

import Header from 'components/header/Header';
import HeaderError from 'components/header/HeaderError';
import CreateCampaignButton from 'components/layout/create/CreateCampaignButton';
import Sidebar from 'components/layout/Sidebar';
import SidebarError from 'components/layout/SidebarError';
import SidebarLinks from 'components/layout/SidebarLinks';
import StatusPageNotifications from 'components/StatusPageNotifications';
import { StoreContext } from 'store/store';

import * as campaigns from 'common/campaigns';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as users from 'common/users';

import { changingCampaign } from 'state/changingEntity';

function PageWrapper({ children }) {
  const { actions, state } = useContext(StoreContext);
  const { globalInfo } = state;

  // Gets navbar styles defined in the component library theme
  const {
    components: { navbar },
  } = useTheme();

  const setChangingCampaign = useSetRecoilState(changingCampaign);

  // TODO work out whether we actually need to store current.campaignURN
  //      if so ensure it gets updated when viewing a campaign from campaigns/list
  const handleCampaignChange = event => {
    const campaignURN = event.currentTarget.id;
    logger.info(`Sidebar:handleCampaignChange ${campaignURN}`);
    tracker.track({
      eventName: 'Switch Campaign',
      trackingParams: {
        'Campaign Name': campaigns.getCampaignName(
          campaignURN,
          state.globalInfo
        ),
        'Campaign ID': campaignURN,
      },
      options: {
        labelCampaignAsPrevious: true,
      },
    });
    setChangingCampaign(true);
    actions.changeCampaign(campaignURN);
  };

  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const campaignList = campaigns.getPropertyCampaigns(propertyURN, globalInfo);
  const sortedCampaignList = campaigns.sortCampaignList(campaignList);

  const links = sortedCampaignList.map(campaign => (
    <SidebarLinks
      {...campaign}
      isCurrent={campaign.campaignURN === globalInfo?.current?.campaignURN}
      key={campaign.campaignURN}
      onClick={handleCampaignChange}
    />
  ));

  return (
    <Box bgColor="gray.100">
      <ErrorBoundary FallbackComponent={HeaderError}>
        <Header />
      </ErrorBoundary>
      <Flex
        pt={{ base: '52px', md: '60px' }}
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        height="auto"
      >
        <Box w={{ base: 'full', md: 'xs' }} minWidth="xs" style={{ zIndex: 9 }}>
          <ErrorBoundary FallbackComponent={SidebarError}>
            <Box
              w="inherit"
              position={{ md: 'fixed' }}
              top={navbar.height}
              bottom={0}
              left={0}
            >
              <Sidebar style={{ overscrollBehavior: 'contain' }}>
                {links}
                {users.isAdministrator(globalInfo) && <CreateCampaignButton />}
              </Sidebar>
            </Box>
          </ErrorBoundary>
        </Box>
        <Flex flexDir="column" width="100%" px={10} py={6} gap={6}>
          <StatusPageNotifications />
          {children}
        </Flex>
      </Flex>
    </Box>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
