import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { BRANDING_SETTINGS_SUB_PAGES } from 'common/constants';
import { BackLink, Container } from '../../../common';
import SettingsPanel from '../../../SettingsPanel';

function ConfirmationLinks({ urn, setSettingsPanel }) {
  return (
    <Container>
      <Button variant="link" className="m-0 p-0">
        <BackLink
          linkURL={`/settings/campaigns/${urn}/branding`}
          linkText="Back to Branding"
        />
      </Button>
      <Button
        variant="link"
        className="link-unstyled ebx-no-underline w-100 p-0"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_CONFIRMATION_PAGE.CONTENTS
          )
        }
      >
        <SettingsPanel title="Content" />
      </Button>
      <Button
        variant="link"
        className="link-unstyled ebx-no-underline w-100 p-0"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_CONFIRMATION_PAGE.FOOTER
          )
        }
      >
        <SettingsPanel title="Footer" />
      </Button>
    </Container>
  );
}

ConfirmationLinks.propTypes = {
  urn: PropTypes.string.isRequired,
  setSettingsPanel: PropTypes.func.isRequired,
};

export default ConfirmationLinks;
