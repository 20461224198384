import React, { useState } from 'react';

import {
  Flex,
  Input,
  Radio,
  RadioGroup,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import PreviewTextTooltip from 'components/common/PreviewTextTooltip';
import ContentSectionWrapper from 'components/settings/campaigns/content/ContentSectionWrapper';

import { EditionSubjectSettings, FixTypeLater } from 'common/types';

import { CAMPAIGN_SETTING_TYPES } from 'common/constants';
import { EDITION_SUBJECT_TYPES, EMAIL_PREVIEW_TEXT_TYPES } from 'common/enums';

interface PreviewTextProps {
  settings: FixTypeLater;
  editionSubjectSettings: EditionSubjectSettings | null;
  updatePreviewTextSettings: (
    type: EMAIL_PREVIEW_TEXT_TYPES,
    text: string | null
  ) => void;
  onTextChange: (newValue: string | null) => void;
}

function PreviewText({
  settings,
  editionSubjectSettings,
  updatePreviewTextSettings,
  onTextChange,
}: PreviewTextProps) {
  const [tempCustomPreviewText, setTempCustomPreviewText] = useState(
    editionSubjectSettings?.customDefaultEmailPreviewText ?? ''
  );

  const [hasBeenTypedIn, setHasBeenTypedIn] = useState(false);

  // Gets current setting or uses the default
  const currentPreviewTextType =
    editionSubjectSettings?.defaultEmailPreviewTextType ??
    EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_DESCRIPTION;

  // Get the initial value, ignoring any local changes
  const initialCustomPreviewText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
    'customDefaultEmailPreviewText',
    true
  );

  const handleOptionChange = (newValue: EMAIL_PREVIEW_TEXT_TYPES) => {
    let text;

    // If user selects an option other than custom, reset the text so it doesn't save any changes user made in the meantime
    if (newValue !== EMAIL_PREVIEW_TEXT_TYPES.CUSTOM) {
      text = initialCustomPreviewText;
    } else {
      text = tempCustomPreviewText;
    }
    updatePreviewTextSettings(newValue, text);
  };

  const handleTextChange = (event: React.FormEvent<HTMLInputElement>) => {
    const text = event.currentTarget.value;
    onTextChange(text);
    setTempCustomPreviewText(text);

    // Signal that use has type in the input field
    setHasBeenTypedIn(true);
  };

  return (
    <ContentSectionWrapper
      title="Preview text"
      tooltip={<PreviewTextTooltip />}
      description="Choose a preview text format for your Campaign"
    >
      <Flex flexDir="column">
        <RadioGroup
          onChange={(currentValue: EMAIL_PREVIEW_TEXT_TYPES) =>
            handleOptionChange(currentValue)
          }
          defaultValue={currentPreviewTextType}
        >
          <Flex flexDir="column" gap={4}>
            <Radio
              value={EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_TITLE}
              mb={0}
              // If the subject also uses First Article Title this option can't be used for Preview Text
              isDisabled={
                editionSubjectSettings?.subjectType ===
                EDITION_SUBJECT_TYPES.FIRST_ARTICLE_TITLE
              }
            >
              The title of the first article in the Edition
            </Radio>
            <Radio value={EMAIL_PREVIEW_TEXT_TYPES.SECOND_ARTICLE_TITLE} mb={0}>
              The title of the second article in the Edition
            </Radio>
            <Radio
              value={EMAIL_PREVIEW_TEXT_TYPES.FIRST_ARTICLE_DESCRIPTION}
              mb={0}
            >
              The description of the first article in the Edition
            </Radio>
            <Flex flexDir="column" gap={2}>
              <Radio value={EMAIL_PREVIEW_TEXT_TYPES.CUSTOM} mb={0}>
                Custom (set a default value for all of your Editions)
              </Radio>
              {currentPreviewTextType === EMAIL_PREVIEW_TEXT_TYPES.CUSTOM && (
                <>
                  <Input
                    width="unset"
                    ml={6}
                    placeholder="Add text..."
                    _placeholder={{
                      opacity: 1,
                      color: 'gray.600',
                    }}
                    _hover={{
                      boxShadow:
                        '0px 1px 2px rgba(14, 30, 57, 0.12), 0px 4px 8px rgba(14, 30, 57, 0.08);',
                    }}
                    value={tempCustomPreviewText}
                    onChange={handleTextChange}
                  />
                  {/* Only show warning if user has already typed in input field */}
                  {!tempCustomPreviewText && hasBeenTypedIn && (
                    <Text
                      ml={6}
                      color="red.600"
                      fontWeight="medium"
                      fontSize="sm"
                    >
                      Custom preview text cannot be empty
                    </Text>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </RadioGroup>
      </Flex>
    </ContentSectionWrapper>
  );
}

export default PreviewText;
