import PropTypes from 'prop-types';
import { Button, Modal, ProgressBar } from 'react-bootstrap';

import ErrorMessage from 'components/campaigns/upload/ErrorMessage';

import './Uploading.css';

function Uploading({
  errorMessage,
  handleClose,
  uploadProgress,
  handleSuccess,
  selectedFile,
}) {
  return (
    <>
      <Modal.Body className="ebx-upload-body ebx-default-modal-body ebx-body-1 d-flex flex-wrap align-items-center justify-content-center">
        <div className="ebx-upload-file d-flex flex-wrap align-items-center p-3 w-100 mx-4">
          <div className="pr-2 d-flex align-items-center w-100 mb-2">
            <div className="uploading__title">{selectedFile.name}</div>
            <div className="ml-auto">{uploadProgress}%</div>
          </div>
          <div className="w-100">
            <ProgressBar now={uploadProgress} className="ebx-progress-bar" />
          </div>
        </div>
        <div className="w-100 pt-2 mx-4">
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
      </Modal.Body>

      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}

Uploading.propTypes = {
  errorMessage: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  uploadProgress: PropTypes.number.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

Uploading.defaultProps = {
  errorMessage: null,
};

export default Uploading;
