import { useEffect, useState } from 'react';

import * as authentication from 'common/authentication';

const useStaffUser = () => {
  const [isStaffUser, setStaffUser] = useState(null);

  useEffect(() => {
    const checkStaffUser = async () => {
      setStaffUser(await authentication.isStaffUser());
    };
    checkStaffUser();
  });

  return isStaffUser;
};

export default useStaffUser;
