import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import './Success.css';

function Success({ handleCancel }) {
  return (
    <>
      <Modal.Body className="ebx-body-1 p-4">
        <div className="ebx-body-1">
          Your email quotas have been successfully updated.
        </div>
      </Modal.Body>
      <Modal.Footer className="ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleCancel}
        >
          Done
        </Button>
      </Modal.Footer>
    </>
  );
}

Success.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Success;
