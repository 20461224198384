import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface GetSyndfeedArgs {
  propertyURN: string;
}

export default async function getSyndfeeds({ propertyURN }: GetSyndfeedArgs) {
  const guid = metrics.start('getSyndfeeds');

  const config = {
    url: `${apiBaseURL(APIS.CORE, '1.1')}/properties/${propertyURN}/syndfeeds`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getSyndfeeds /properties/${propertyURN}/syndfeeds`);

  try {
    // Send a request to /properties/{propertyURN}/syndfeeds
    const response = await axios(config);
    metrics.end('getSyndfeeds', guid);
    return response?.data;
  } catch (error) {
    metrics.fail('getSyndfeeds', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getSyndfeeds',
            args: { propertyURN },
          })
        : error;
    throw apiError;
  }
}
