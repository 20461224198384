/* eslint @typescript-eslint/no-shadow: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */
/* eslint prefer-template: "off" */

export { decimals, formatPercentage, percent, randomish, round, mod };

function decimals(value: number) {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  return value.toString().split('.')[1].length || 0;
}

function formatPercentage(percentage: number, decimals: number) {
  // Removes decimal points if 0
  const formattedPercentage =
    percentage === 0 ? percentage : Math.abs(percentage).toFixed(decimals);

  return `${formattedPercentage}%`;
}

function percent(value: number, decimals: number) {
  const percentage = value * 100;

  return formatPercentage(percentage, decimals);
}

function randomish(value: number, variance: number) {
  return value - variance + Math.floor(Math.random() * (variance * 2 + 1));
}

function round(value: number, decimals: number) {
  return Number(Math.round(Number(`${value}e${decimals}`)) + 'e-' + decimals);
}

function mod(x: number, y: number) {
  return x - y * Math.floor(x / y);
}
