import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { UI_MESSAGES } from 'common/messages';

import AddImageModal from './AddImageModal';

import { BackLink, Container, Description, Title } from '../../common';
import HeaderPreview from './HeaderPreview';

function Header({ setSettingsPanel, settings }) {
  const [headerLinkErrorMessage, setHeaderLinkErrorMessage] = useState('');
  const [showHeaderModal, setShowHeaderModal] = useState(false);

  const headerImageURL = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'headerImageURL'
  );

  const headerLinkURL = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'headerLinkURL'
  );

  const setHeaderImageURL = value => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'headerImageURL',
      settingValue: value,
    });
  };

  const setHeaderLinkURL = newURL => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'headerLinkURL',
      settingValue: newURL,
    });
    setHeaderLinkErrorMessage('');
  };

  const handleChangeLink = event => {
    setHeaderLinkURL(event.target.value);
  };

  const handleSave = imageURL => {
    setHeaderImageURL(imageURL);
    setShowHeaderModal(false);
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.GENERAL_STYLING_PAGE_SETTINGS.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to General Styling" />
      </Button>
      <Title className="mb-3">
        <span className="mr-3">Header image</span>
      </Title>
      {headerImageURL && <HeaderPreview img={headerImageURL} />}
      <div className="header-image-actions">
        {!headerImageURL && (
          <Button
            className="ebx-btn-secondary ebx-btn-md ebx-h3"
            disabled={settings.isSaving}
            onClick={() => setShowHeaderModal(true)}
          >
            <PlusIcon className="mr-2" fill="#0e1e39" />
            Add header image
          </Button>
        )}
        {headerImageURL && (
          <>
            <Button
              className="ebx-btn-secondary ebx-btn-md ebx-h3 mr-2"
              disabled={settings.isSaving}
              onClick={() => setShowHeaderModal(true)}
            >
              Change image
            </Button>
            <Button
              variant="link"
              className="ebx-h3 image-btn mr-2"
              disabled={settings.isSaving}
              onClick={() => setHeaderImageURL('')}
            >
              Remove image
            </Button>
          </>
        )}
      </div>
      {!headerImageURL && (
        <Description className="mb-0">
          {UI_MESSAGES.IMAGE_GUIDELINES}
        </Description>
      )}
      {showHeaderModal && (
        <AddImageModal
          imageURL={headerImageURL}
          onClose={() => setShowHeaderModal(false)}
          onSave={handleSave}
          title="Add header image"
          description={UI_MESSAGES.IMAGE_GUIDELINES}
        />
      )}
      {headerImageURL && (
        <>
          <Title className="settings-subtitle">
            <span className="mr-4">Header link</span>
            <span className="ebx-label-optional px-3 py-2 text-center ebx-h3">
              Optional
            </span>
          </Title>
          <Form.Control
            className="ebx-input ebx-input-group ebx-body-1 mt-3"
            style={{ width: '100%' }}
            type="text"
            placeholder=""
            value={headerLinkURL}
            onChange={handleChangeLink}
          />
          {headerLinkErrorMessage && (
            <div className="d-flex align-items-center ebx-error-colour ebx-h3 mt-2 mb-2">
              {headerLinkErrorMessage}
            </div>
          )}
        </>
      )}
    </Container>
  );
}

Header.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default Header;
