import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Default from 'components/campaigns/subscribers/Default';
import Loading from 'components/campaigns/subscribers/Loading';
import NoSubscribers from 'components/campaigns/subscribers/NoSubscribers';
import Processing from 'components/campaigns/subscribers/Processing';

import { FRONTEND_UPLOAD_STATES } from 'common/constants';
import { SERVER_UPLOAD_STATE } from 'common/enums';

function Insights({
  campaignDetails,
  campaignInsights,
  isShutOff,
  latestUpload,
  localState,
}) {
  const history = useHistory();

  const openAnalytics = () => {
    history.push(`/campaigns/${campaignDetails.campaignURN}/analytics`);
  };

  const isUploading =
    localState === FRONTEND_UPLOAD_STATES.LOADING ||
    localState === FRONTEND_UPLOAD_STATES.PROCESSING ||
    localState === FRONTEND_UPLOAD_STATES.UPLOADING ||
    localState === FRONTEND_UPLOAD_STATES.VALIDATING ||
    latestUpload?.uploadState === SERVER_UPLOAD_STATE.UPLOADING ||
    latestUpload?.uploadState === SERVER_UPLOAD_STATE.VALIDATING ||
    latestUpload?.uploadState === SERVER_UPLOAD_STATE.INSERTING;

  if (!(campaignInsights || isShutOff)) {
    return <Loading openAnalytics={openAnalytics} />;
  }

  if (
    campaignInsights?.activeSubscribers === 0 &&
    !(isUploading || isShutOff)
  ) {
    return (
      <NoSubscribers
        latestUpload={latestUpload}
        openAnalytics={openAnalytics}
      />
    );
  }

  if (isUploading && !isShutOff) {
    return (
      <Processing latestUpload={latestUpload} openAnalytics={openAnalytics} />
    );
  }

  return (
    <Default
      campaignDetails={campaignDetails}
      campaignInsights={campaignInsights}
      isShutOff={isShutOff}
      isUploading={isUploading}
      latestUpload={latestUpload}
      openAnalytics={openAnalytics}
    />
  );
}

Insights.propTypes = {
  // TODO - implement using TypeScript
  campaignDetails: PropTypes.shape({
    campaignURN: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    createdByEmail: PropTypes.string.isRequired,
    createdUnixTime: PropTypes.number.isRequired,
    lastUpdatedByEmail: PropTypes.string,
    lastUpdatedUnixTime: PropTypes.number,
  }).isRequired,
  campaignInsights: PropTypes.shape({
    activeSubscribers: PropTypes.number.isRequired,
    hardBouncedSubscribers: PropTypes.number.isRequired,
    complainedSubscribers: PropTypes.number.isRequired,
    unsubscribedSubscribers: PropTypes.number.isRequired,
    lastRetrievedUnixTime: PropTypes.number,
  }),
  isShutOff: PropTypes.bool.isRequired,
  latestUpload: PropTypes.shape({
    processingCompletedUnixTime: PropTypes.number,
    processingStartUnixTime: PropTypes.number,
    uploadedByEmail: PropTypes.string,
    uploadURN: PropTypes.string.isRequired,
    uploadState: PropTypes.string.isRequired,
    uploadTriggerUnixTime: PropTypes.number,
    uploadType: PropTypes.string.isRequired,
    errorLogURL: PropTypes.string,
  }),
  localState: PropTypes.string,
};

Insights.defaultProps = {
  campaignInsights: null,
  latestUpload: null,
  localState: null,
};

export default React.memo(Insights);
