/* eslint @typescript-eslint/no-use-before-define: "off" */

import { SUBJECT_LINE_WARNINGS } from 'common/enums';
import SPAM_PHRASES from 'common/spamphrases';

export { getSubjectWarningTypes };

const SUBJECT_LINE_WARN_LENGTH = 60;

const getSubjectWarningTypes = (subjectLine: string) => {
  const subjectWarnings = [];
  if (isSubjectLineTooLong(subjectLine)) {
    subjectWarnings.push(SUBJECT_LINE_WARNINGS.LENGTH);
  }

  if (doesSubjectContainSpam(subjectLine)) {
    subjectWarnings.push(SUBJECT_LINE_WARNINGS.SPAM);
  }

  if (doesSubjectContainHashTag(subjectLine)) {
    subjectWarnings.push(SUBJECT_LINE_WARNINGS.HASHTAG);
  }

  if (doesSubjectContainRepeatedPunctuation(subjectLine)) {
    subjectWarnings.push(SUBJECT_LINE_WARNINGS.PUNCTUATION);
  }

  return subjectWarnings;
};

const isSubjectLineTooLong = (subjectLine: string) =>
  subjectLine.length > SUBJECT_LINE_WARN_LENGTH;

const doesSubjectContainSpam = (subjectLine: string) => {
  const subjectLineLowerCase = subjectLine.toLowerCase();
  return SPAM_PHRASES.some(spamPhrase =>
    subjectLineLowerCase.includes(spamPhrase)
  );
};

const doesSubjectContainHashTag = (subjectLine: string) =>
  subjectLine.includes('#');

const doesSubjectContainRepeatedPunctuation = (subjectLine: string) =>
  ['??', '!!'].some(chars => subjectLine.includes(chars));
