import { ReactComponent as DragHandle } from 'assets/svg/drag-handle.svg';
import { ReactComponent as Ellipsis } from 'assets/svg/ellipsis.svg';
import BodyElement from 'components/settings/campaigns/content/BodyElement';
import { Dropdown } from 'react-bootstrap';

interface BodyTextBlockProps {
  textBlockURN: string;
  textBlockBody: string;
  textBlockTitle: string;
  index: number;
  onDelete: (promotionBlockURN: string) => void;
  onOpenModal: (index: number) => void;
}

function BodyTextBlock({
  textBlockURN,
  textBlockBody,
  textBlockTitle,
  index,
  onDelete,
  onOpenModal,
}: BodyTextBlockProps) {
  return (
    <BodyElement>
      <div className="d-flex align-items-center">
        <div className="drop-handle mr-2">
          <DragHandle />
        </div>
        <div className="ebx-text-overflow ebx-whitescape-nowrap w-100">
          {textBlockTitle ? (
            <span className="ebx-h2 mr-2 ebx-title-colour">
              {textBlockTitle}
            </span>
          ) : (
            <span className="ebx-h2 mr-2 ebx-title-colour">Text</span>
          )}
        </div>
        <div className="ml-auto d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              className="p-0 border-0 section-dropdown-toggle"
              bsPrefix="p-0" // Removes the caret
            >
              <Ellipsis />
            </Dropdown.Toggle>
            <Dropdown.Menu className="ebx-profile-menu">
              <Dropdown.Item
                className="ebx-profile-option px-3 py-2"
                onClick={() => {
                  onOpenModal(index);
                }}
              >
                <span className="ebx-h3 body-promotion-link">Edit</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="ebx-profile-option px-3 py-2"
                onClick={() => onDelete(textBlockURN)}
              >
                <span className="ebx-h3 body-promotion-delete">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </BodyElement>
  );
}

export default BodyTextBlock;
