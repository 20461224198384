import { useHistory } from 'react-router-dom';

import { Circle, Dropdown } from '@ebx-ui/ebx-ui-component-library-sdk';

import { ReactComponent as SignOut } from 'assets/svg/sign-out.svg';

import useGlobalInfo from 'hooks/useGlobalInfo';

import { REACT_PREVENT_RENDER, ROUTE_REDIRECTIONS } from 'common/constants';

function getInitials({ name }) {
  if (name) {
    const nameSplit = name.split(' ');
    if (nameSplit.length === 1) {
      return nameSplit[0].charAt(0);
    }
    if (nameSplit.length === 2) {
      return nameSplit[0].charAt(0).concat(nameSplit[1].charAt(0));
    }
    return nameSplit[0]
      .charAt(0)
      .concat(nameSplit[nameSplit.length - 1].charAt(0));
  }
  return REACT_PREVENT_RENDER;
}

function UserDetailsMenu() {
  const history = useHistory();
  const globalInfo = useGlobalInfo();
  const { user } = globalInfo;
  const initials = getInitials(user);

  return (
    <Dropdown>
      <Dropdown.Button
        borderRadius="full"
        bg="gray.200"
        display={{ base: 'none', md: 'inline-flex' }}
        _hover={{ bg: 'gray.300' }}
        _active={{ boxShadow: 'focus' }}
        _expanded={{ bg: 'gray.200' }}
      >
        <Circle
          id="initials"
          size={9}
          border="1px"
          borderColor="gray.300"
          color="gray.900"
          fontWeight="normal"
          fontSize="xs"
        >
          {initials}
        </Circle>
      </Dropdown.Button>
      <Dropdown.List>
        <Dropdown.Group
          id="email"
          title={user?.emailAddress ?? ''}
          fontSize="xs"
          textTransform="uppercase"
          color="gray.600"
        >
          <Dropdown.Item
            icon={<SignOut />}
            data-cy-action="signOut"
            onClick={() => history.push(ROUTE_REDIRECTIONS.SIGNOUT)}
          >
            Sign out
          </Dropdown.Item>
        </Dropdown.Group>
      </Dropdown.List>
    </Dropdown>
  );
}

export default UserDetailsMenu;
