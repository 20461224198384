import { Hub } from 'aws-amplify';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import { isNull } from 'common/utility';

import {
  AXIOS_ERROR_CODES,
  HUB_CHANNELS,
  HUB_EVENTS,
  ROUTE_REDIRECTIONS,
} from 'common/constants';
import { UI_MESSAGES } from 'common/messages';

export default async function postSignIn(user) {
  authentication.signIn(user);
  try {
    /* Exchange access and id tokens for client service token */
    const clientServiceToken = await API.postServiceAuth();
    /* Extract user details, including permissions, from the two responses */
    const userDetail = authentication.getUserDetails(user, clientServiceToken);
    if (isNull(userDetail.userType)) {
      throw new ReferenceError(UI_MESSAGES.SIGNIN_NULL_USER_TYPE);
    }

    /* Signin successful */
    Hub.dispatch(HUB_CHANNELS.AUTHENTICATION, {
      event: HUB_EVENTS.AUTHENTICATION.SIGN_IN,
      user: { userDetail, clientServiceToken },
    });
  } catch (error) {
    if (error?.code === AXIOS_ERROR_CODES.BAD_REQUEST) {
      await API.authSignOut();
    }
    authentication.signOut();
    window.location.href = ROUTE_REDIRECTIONS.SIGNIN;
  }
}
