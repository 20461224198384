import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postProperties({
  propertyName = mandatory('propertyName'),
  companyDetails = mandatory('companyDetails'),
  initialUsers = null,
  rootURL = null,
}) {
  const guid = metrics.start('postProperties');
  const url = `${apiBaseURL(APIS.NEWSLETTERS, '1')}/properties/create`;

  const config = {
    url,
    method: 'post',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      propertyName,
      companyDetails,
      initialUsers,
      rootURL,
    }),
  };
  logger.info(`API:postProperties /properties`);

  try {
    const { data } = await axios(config);
    metrics.end('postProperties', guid);
    return data;
  } catch (error) {
    metrics.fail('postProperties', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/postProperties',
            args: {
              propertyName,
              companyDetails,
              initialUsers,
              rootURL,
            },
          })
        : error;
    throw apiError;
  }
}
