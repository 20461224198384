import LaunchIcon from '@mui/icons-material/Launch';
import { FormControl, InputGroup } from 'react-bootstrap';

import * as url from 'common/url';

import './URLInput.css';

export interface URLInputProps {
  disabled?: boolean;
  getFullURL?: (value: string) => string;
  invalidURL?: boolean;
  onChange: (newValue: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  value: string;
  setHasBeenTypedIn?: (newValue: boolean) => void;
}

function URLInput({
  disabled,
  getFullURL,
  invalidURL,
  onChange,
  onBlur,
  placeholder = '',
  value,
  setHasBeenTypedIn,
}: URLInputProps) {
  const fullLink = getFullURL ? getFullURL(value) : value;
  const showInputGroupAppend = value !== '';

  const handleChange = (event: { target: { value: string } }) => {
    onChange(event.target.value);
    if (setHasBeenTypedIn) {
      setHasBeenTypedIn(true);
    }
  };

  return (
    <InputGroup className="url-input-group">
      <FormControl
        className={`ebx-input url-input ebx-body-1 form-control rounded-left ${
          showInputGroupAppend ? 'url-input-with-append' : ''
        }`}
        placeholder={placeholder}
        aria-label="Social media link"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {showInputGroupAppend && url.isValidURL(fullLink) && (
        <InputGroup.Append className="rounded-right">
          <a
            href={fullLink}
            className={`d-flex ${invalidURL ? 'invalid' : ''}`}
            target="blank"
            rel="noopener noreferrer"
          >
            <div className="px-3 d-flex align-items-center">
              <LaunchIcon className="external-link-icon" />
            </div>
          </a>
        </InputGroup.Append>
      )}
    </InputGroup>
  );
}

URLInput.defaultProps = {
  disabled: false,
  getFullURL: null,
  invalidURL: false,
  onBlur: null,
  placeholder: null,
  setHasBeenTypedIn: null,
};

export default URLInput;
