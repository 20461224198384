import React from 'react';

import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import EmailPropertySelect from 'components/layout/EmailPropertySelect';

export interface SidebarProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

function Sidebar({ children, style }: SidebarProps) {
  return (
    <Flex
      flexDir="column"
      backgroundColor="gray.100"
      boxShadow="borderRight"
      h="100%"
      pt={6}
      pb={4}
      gap={10}
    >
      <EmailPropertySelect />
      <Flex flexDir="column" overflow="auto" style={style}>
        {children}
      </Flex>
    </Flex>
  );
}

export default Sidebar;
