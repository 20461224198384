import { useState } from 'react';

import { AddIcon, Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import Create from 'components/layout/create/Create';

import * as logger from 'common/logger';

function CreateCampaignButton() {
  const [isModalActive, setModalActive] = useState(false);

  const handleOpen = () => {
    logger.info('View:handleOpen');
    setModalActive(true);
  };

  const handleClose = () => {
    logger.info('View:handleClose');
    setModalActive(false);
  };

  return (
    <>
      <Button
        variant="tertiary"
        id="create-campaign"
        justifyContent="space-between"
        border="1px"
        borderColor="gray.300"
        fontSize={16}
        color="gray.600"
        filter="none"
        px={4}
        mx={4}
        mt={2}
        onClick={handleOpen}
      >
        Create Campaign
        <AddIcon size={5} color="gray.600" />
      </Button>
      {isModalActive && (
        <Create handleClose={handleClose} show={isModalActive} />
      )}
    </>
  );
}

export default CreateCampaignButton;
