import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getPropertyBrandingArgs {
  propertyURN: string;
}

export default async function getPropertyBranding({
  propertyURN,
}: getPropertyBrandingArgs) {
  const guid = metrics.start('getPropertyBranding');

  const config = {
    url: `${apiBaseURL(APIS.NEWSLETTERS)}/properties/${propertyURN}/branding`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(`API:getPropertyBranding /properties/${propertyURN}/branding`);

  try {
    const response = await axios(config);
    metrics.end('getPropertyBranding', guid);
    return response.data.brandingSettings;
  } catch (error) {
    metrics.fail('getPropertyBranding', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getPropertyBranding',
            args: { propertyURN },
          })
        : error;
    throw apiError;
  }
}
