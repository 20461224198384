/* eslint camelcase: ["error", {allow: ["impersonated_by_staff_email", "jwt_decode"]}] */
/* eslint no-async-promise-executor: "off" */
/* eslint no-promise-executor-return: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import type { ChangeEvent } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import ProfileWrapper from 'components/settings/profile/ProfileWrapper';
import VerifyEmailModal from 'components/settings/profile/VerifyEmailModal';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import * as errors from 'common/errors';
import * as tracker from 'common/tracker';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import useStaffUser from 'hooks/useStaffUser';
import { StoreContext } from 'store/store';

const PersonalDetailsSchema = Yup.object().shape({
  name: Yup.string().default('').required('Full name is required!').trim(),
  emailAddress: Yup.string()
    .default('')
    .email('This is not a valid email address')
    .required('Email is required!')
    .trim(),
});

interface PersonalDetailsForm {
  name: string;
  emailAddress: string;
}

function PersonalDetails() {
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const globalInfo = useGlobalInfo();
  const { isSaving, setChanged, setUnchanged, setOnSave, setDone, setError } =
    useSettings({ storeStateInHook: false });
  const currentUser = globalInfo.user;
  const { actions } = useContext(StoreContext);
  const toast = useToast();
  const isStaffUser = useStaffUser();

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    getValues,
  } = useForm<PersonalDetailsForm>({
    mode: 'onSubmit',
    resolver: yupResolver(PersonalDetailsSchema),
    shouldUnregister: true,
    defaultValues: {
      name: currentUser?.name,
      emailAddress: currentUser?.emailAddress,
    },
  });
  const currentEmail = getValues('emailAddress');

  const handleChange =
    (field: 'name' | 'emailAddress') =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === currentUser?.[field]) {
        setUnchanged();
      } else {
        setChanged();
      }
    };

  const sendTrackingEvent = useCallback(() => {
    tracker.track({
      eventName: 'Change User Settings',
      trackingParams: {
        'Name Before': currentUser?.name,
        'Email Before': currentUser?.emailAddress,
        'Password Changed?': false,
      },
      options: {
        currentUserDetails: {
          name: getValues('name'),
          emailAddress: getValues('emailAddress'),
        },
      },
    });
  }, [currentUser, getValues]);

  useEffect(() => {
    setOnSave(() => {
      handleSubmit(async ({ name, emailAddress }) => {
        try {
          /* Update user details */
          await API.authUpdateUserDetails({ name, email: emailAddress });
          setShowVerifyEmailModal(true);
        } catch (error) {
          const errorMessage = errors.getErrorMessage(
            errors.determineError(error)
          );
          setError(errorMessage);
          toast({ variant: 'error', title: errorMessage });
          setChanged();
        }
      })();
    });
  }, [
    actions,
    currentUser?.emailAddress,
    handleSubmit,
    sendTrackingEvent,
    setChanged,
    setDone,
    setError,
    setOnSave,
    toast,
  ]);

  const handleCloseModal = () => {
    setShowVerifyEmailModal(false);
    sendTrackingEvent();
    setDone();
  };

  useEffect(() => {
    if (formErrors?.name?.message || formErrors?.emailAddress?.message) {
      setError();
    }
  }, [formErrors, setError]);

  const description = isStaffUser
    ? 'Edit your name'
    : 'Edit your name and email address';

  return (
    <>
      <ProfileWrapper title="Personal details" description={description}>
        <FormGroup
          className={formErrors.name ? '' : 'profile-settings-form-group-valid'}
        >
          <FormLabel className="ebx-h3 mb-1">
            <div>Full name</div>
          </FormLabel>

          <FormControl
            {...register('name', { onChange: handleChange('name') })}
            type="text"
            placeholder="Full name"
            className={`ebx-input settings--input--sm ebx-body-1 ${
              formErrors.name ? 'ebx-input-error' : ''
            }`}
            disabled={isSaving || authentication.isImpersonating()}
          />
          {formErrors.name && (
            <div className="d-flex align-items-center error--text mt-2 mb-2">
              <span className="w-100">{formErrors.name.message}</span>
            </div>
          )}
        </FormGroup>

        {!isStaffUser ? (
          <FormGroup
            className={
              formErrors.name ? '' : 'profile-settings-form-group-valid'
            }
          >
            <FormLabel className="ebx-h3 mb-1">Email address</FormLabel>

            <FormControl
              {...register('emailAddress', {
                onChange: handleChange('emailAddress'),
              })}
              type="text"
              placeholder="Email address"
              className={`ebx-input settings--input--sm ebx-body-1 ${
                formErrors.emailAddress ? 'ebx-input-error' : ''
              }`}
              disabled={isSaving || authentication.isImpersonating()}
            />
            {formErrors.emailAddress && (
              <div className="d-flex align-items-center error--text mt-2 mb-2">
                <span className="w-100">{formErrors.emailAddress.message}</span>
              </div>
            )}
          </FormGroup>
        ) : (
          <FormControl
            {...register('emailAddress', {
              onChange: handleChange('emailAddress'),
            })}
            type="hidden"
          />
        )}
      </ProfileWrapper>
      {showVerifyEmailModal && currentEmail && (
        <VerifyEmailModal
          onClose={handleCloseModal}
          emailAddress={currentEmail}
        />
      )}
    </>
  );
}

export default PersonalDetails;
