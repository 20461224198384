import { useEffect, useRef, useState } from 'react';

const useIsVisible = (target, options = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observerInstance = useRef(null);

  useEffect(() => {
    const callback = entries => {
      setIsIntersecting(entries[0].isIntersecting);
    };

    observerInstance?.current?.disconnect();

    if (target.current) {
      const observer = new IntersectionObserver(callback, options);
      observer.observe(target.current);
      observerInstance.current = observer;
    }

    return () => {
      observerInstance?.current?.disconnect();
    };
  }, [target, options]);

  return isIntersecting;
};

export default useIsVisible;
