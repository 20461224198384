import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import ErrorIcon from '@material-ui/icons/Error';

import './Warning.css';

function Warning({ handleClose, handleOpen }) {
  return (
    <>
      <Modal.Body className="ebx-warning-modal-body d-flex ebx-body-1 ebx-modal-body">
        <ErrorIcon className="ebx-warning-icon ebx-warning-colour mr-2" />
        <span className="ebx-body-colour">
          Before reviewing your Editions, please add subscribers to this
          Campaign.
        </span>
      </Modal.Body>

      <Modal.Footer className="py-2 ebx-modal-footer">
        <Button
          className="ebx-btn-secondary ebx-btn-md ebx-h3"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="ebx-btn-primary ebx-btn-md ebx-h3"
          onClick={handleOpen}
        >
          Add Subscribers
        </Button>
      </Modal.Footer>
    </>
  );
}

Warning.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default Warning;
