/* eslint global-require: "off" */

import axios from 'axios';

import { API_TIMEOUTS } from 'api/settings';

import pkg from '../../../package.json';

export default async function getVersion() {
  const dev =
    window.location.hostname === 'local' ||
    window.location.hostname === 'localhost';
  if (dev) {
    return pkg.version;
  }
  const url = `${window.location.protocol}//${window.location.host}/package.json`;
  const config = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.XXS,
  };

  try {
    const response = await axios(config);
    return response.data.version;
  } catch (error) {
    return '0.0.0';
  }
}
