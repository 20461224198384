/* eslint react-hooks/exhaustive-deps: "off" */

import { Flex, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import SplitView from 'components/common/splitview/SplitView';
import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import Body from 'components/settings/campaigns/content/Body';
import ContentSectionWrapper from 'components/settings/campaigns/content/ContentSectionWrapper';
import Footer from 'components/settings/campaigns/content/Footer';
import SubjectLine from 'components/settings/campaigns/content/SubjectLine';
import TemplateUsed from 'components/settings/campaigns/content/TemplateUsed';
import Loading from 'components/settings/Loading';

import { getCampaign } from 'common/campaigns';
import {
  CAMPAIGN_SETTING_TYPES,
  EDITION_SUBJECT_TYPE_DESCRIPTIONS,
  EMAIL_PREVIEW_TYPE_DESCRIPTIONS,
} from 'common/constants';
import isTestProperty from 'common/demo';
import {
  EDITION_SUBJECT_TYPES,
  EMAIL_PREVIEW_TEXT_TYPES,
  PREVIEW_TYPES,
} from 'common/enums';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as string from 'common/string';
import * as tracker from 'common/tracker';
import {
  EditionSubjectSettings,
  FixTypeLater,
  FooterTextSettings,
  PrivacyPolicySettings,
  SocialLinksSettings,
  SocialPageLinksSettings,
} from 'common/types';
import * as utility from 'common/utility';

import useBodyEntities from 'hooks/useBodyEntities';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import PreviewText from './content/PreviewText';

const UNDEFINED = 'undefined';

const sendTrackingEvents = (
  campaignURN: string,
  before: FixTypeLater,
  after: FixTypeLater
) => {
  const previousDefaultSubject =
    EDITION_SUBJECT_TYPE_DESCRIPTIONS[
      before.settings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
        ?.subjectType as EDITION_SUBJECT_TYPES
    ];
  const newDefaultSubject =
    EDITION_SUBJECT_TYPE_DESCRIPTIONS[
      after.settings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
        ?.subjectType as EDITION_SUBJECT_TYPES
    ];

  const previousDefaultPreviewText =
    EMAIL_PREVIEW_TYPE_DESCRIPTIONS[
      before.settings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
        ?.defaultEmailPreviewTextType as EMAIL_PREVIEW_TEXT_TYPES
    ];
  const newDefaultPreviewText =
    EMAIL_PREVIEW_TYPE_DESCRIPTIONS[
      after.settings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
        ?.defaultEmailPreviewTextType as EMAIL_PREVIEW_TEXT_TYPES
    ];

  const previousCustomPreviewText =
    before.settings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
      ?.customDefaultEmailPreviewText;
  const newCustomPreviewText =
    after.settings[CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]
      ?.customDefaultEmailPreviewText;

  if (
    previousDefaultSubject !== newDefaultSubject ||
    previousDefaultPreviewText !== newDefaultPreviewText ||
    previousCustomPreviewText !== newCustomPreviewText
  ) {
    const trackingParams = {
      'Previous Default Subject': previousDefaultSubject,
      'New Default Subject': newDefaultSubject,
      'Previous Default Email Preview Text': previousDefaultPreviewText,
      'New Default Email Preview Text': newDefaultPreviewText,
      'Previous Custom Email Preview Text': previousCustomPreviewText,
      'New Custom Email Preview Text': newCustomPreviewText,
    };

    tracker.track({
      eventName: 'Update Campaign Content Settings',
      trackingParams,
      options: { campaignURN },
    });
  }

  after.settings[CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS]?.socialPageLinks.forEach(
    (linkSetting: SocialPageLinksSettings) => {
      const previousLinkSetting = before.settings[
        CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS
      ]?.socialPageLinks.find(
        (setting: SocialPageLinksSettings) =>
          setting.socialNetworkType === linkSetting.socialNetworkType
      );

      if (
        !previousLinkSetting ||
        linkSetting.isEnabled !== previousLinkSetting.isEnabled ||
        linkSetting.linkPath !== previousLinkSetting.linkPath
      ) {
        const trackingParams = {
          'Footer Type': linkSetting.socialNetworkType,
          Footer: linkSetting.isEnabled ? linkSetting.linkPath : UNDEFINED,
          'Footer Before': previousLinkSetting?.isEnabled
            ? previousLinkSetting.linkPath
            : UNDEFINED,
        };

        tracker.track({
          eventName: 'Update Footer',
          trackingParams,
          options: { campaignURN },
        });
      }
    }
  );

  const afterPrivacyPolicySettings =
    after.settings[CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY];
  const beforePrivacyPolicySettings =
    before.settings[CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY];

  if (
    afterPrivacyPolicySettings &&
    (!beforePrivacyPolicySettings ||
      beforePrivacyPolicySettings.isEnabled !==
        afterPrivacyPolicySettings.isEnabled ||
      beforePrivacyPolicySettings.privacyPolicy !==
        afterPrivacyPolicySettings.privacyPolicy)
  ) {
    const trackingParams = {
      'Footer Type': 'PRIVACY POLICY',
      Footer: afterPrivacyPolicySettings.isEnabled
        ? afterPrivacyPolicySettings.privacyPolicy
        : UNDEFINED,
      'Footer Before': beforePrivacyPolicySettings?.isEnabled
        ? beforePrivacyPolicySettings.privacyPolicy
        : UNDEFINED,
    };

    tracker.track({
      eventName: 'Update Footer',
      trackingParams,
      options: { campaignURN },
    });
  }

  const afterFooterTextSettings =
    after.settings[CAMPAIGN_SETTING_TYPES.FOOTER_TEXT];
  const beforeFooterTextSettings =
    before.settings[CAMPAIGN_SETTING_TYPES.FOOTER_TEXT];
  if (
    afterFooterTextSettings &&
    (!beforeFooterTextSettings ||
      beforeFooterTextSettings.isEnabled !==
        afterFooterTextSettings.isEnabled ||
      beforeFooterTextSettings.footerText !==
        afterFooterTextSettings.footerText)
  ) {
    const trackingParams = {
      'Footer Type': 'FOOTER TEXT',
      Footer: afterFooterTextSettings.isEnabled
        ? afterFooterTextSettings.footerText
        : UNDEFINED,
      'Footer Before': beforeFooterTextSettings?.isEnabled
        ? beforeFooterTextSettings.footerText
        : UNDEFINED,
    };

    tracker.track({
      eventName: 'Update Footer',
      trackingParams,
      options: { campaignURN },
    });
  }
};

function Content() {
  const settings = useSettings();

  const { urn: campaignURN } = useParams<{ urn: string }>();
  const globalInfo = useGlobalInfo();
  const toast = useToast();

  const campaign = getCampaign(campaignURN, globalInfo);
  // this is the list of body entities (some Sections, some Text Blocks and some Promotion Blocks)
  const {
    globalSections,
    globalPromotionBlocks,
    globalTextBlocks,
    globalBodyElementPositioning,
    bodyEntities,
    setBodyEntities,
  } = useBodyEntities(
    campaign?.sections,
    campaign?.promotionBlocks,
    campaign?.textBlocks,
    campaign?.bodyElementPositioning
  );

  useEffect(() => {
    settings.getCampaignSettings(campaignURN);
  }, [campaignURN]);

  const editionSubjectSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT
  ) as EditionSubjectSettings | null;

  const privacyPolicySettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY
  ) as PrivacyPolicySettings | null;

  const footerTextSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.FOOTER_TEXT
  ) as FooterTextSettings | null;

  const socialLinksSettings = settings.getCampaignSettingType(
    CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS
  ) as SocialLinksSettings | null;

  const contentSettings = useMemo(
    () => ({
      [CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT]: editionSubjectSettings,
      [CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY]: privacyPolicySettings,
      [CAMPAIGN_SETTING_TYPES.FOOTER_TEXT]: footerTextSettings,
      [CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS]: socialLinksSettings,
    }),
    [
      editionSubjectSettings,
      privacyPolicySettings,
      footerTextSettings,
      socialLinksSettings,
    ]
  );

  const [forceReload, setForceReload] = useState(string.generateGuid());

  useEffect(() => {
    settings.setOnReload(() => {
      settings.setDone();
      toast({ variant: 'success', title: 'Changes saved successfully' });
      setForceReload(string.generateGuid()); // Hack to force the preview to re-render
    });
    settings.setOnSave(async () => {
      logger.info('Content:handleSave');
      try {
        const before = {
          settings: settings.getInitialCampaignSettings(),
        };
        const after = {
          settings: utility.cloneObject(contentSettings),
        };

        if (socialLinksSettings?.socialPageLinks) {
          for (const social of socialLinksSettings.socialPageLinks) {
            if (social.isEnabled === true && !social.linkPath) {
              throw new Error(
                `Social link for ${social.socialNetworkType.toLowerCase()} cannot be blank!`
              );
            }
          }
        }

        if (
          editionSubjectSettings?.defaultEmailPreviewTextType ===
            EMAIL_PREVIEW_TEXT_TYPES.CUSTOM &&
          !editionSubjectSettings.customDefaultEmailPreviewText
        ) {
          throw new Error(`Custom preview text cannot be empty!`);
        }
        await settings.saveCampaignSettings({
          campaignURN,
        });

        sendTrackingEvents(campaignURN, before, after);
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    });
  }, [campaignURN, settings, globalBodyElementPositioning]);

  const handleSubjectChange = (newValue: EDITION_SUBJECT_TYPES) => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
      settingKey: 'subjectType',
      settingValue: newValue,
    });
  };

  const handlePreviewTextChange = (
    type: EMAIL_PREVIEW_TEXT_TYPES,
    text: string | null
  ) => {
    const typeChange = {
      settingType: CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
      settingKey: 'defaultEmailPreviewTextType',
      settingValue: type,
    };
    const textChange = {
      settingType: CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
      settingKey: 'customDefaultEmailPreviewText',
      settingValue: text,
    };
    settings.setCampaignSettingValues([typeChange, textChange]);
  };

  const handleCustomPreviewTextChange = (newValue: string | null) => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.EDITION_SUBJECT,
      settingKey: 'customDefaultEmailPreviewText',
      settingValue: newValue,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <SplitView>
      <Flex flexDir="column" gap={12}>
        {isTestProperty(globalInfo) && (
          <TemplateUsed globalInfo={globalInfo} campaignURN={campaignURN} />
        )}
        <SubjectLine
          editionSubjectSettings={editionSubjectSettings}
          onChange={handleSubjectChange}
        />
        <PreviewText
          settings={settings}
          editionSubjectSettings={editionSubjectSettings}
          updatePreviewTextSettings={handlePreviewTextChange}
          onTextChange={handleCustomPreviewTextChange}
        />
        <Body
          campaignURN={campaignURN}
          globalSections={globalSections}
          globalPromotionBlocks={globalPromotionBlocks}
          globalBodyElementPositioning={globalBodyElementPositioning}
          globalTextBlocks={globalTextBlocks}
          bodyEntities={bodyEntities}
          setBodyEntities={setBodyEntities}
        />
        <ContentSectionWrapper
          title="Footer"
          description="Select the content you want to be displayed on the footer of your Campaign"
        >
          <Footer
            settings={settings}
            socialLinksSettings={socialLinksSettings}
            privacyPolicySettings={privacyPolicySettings}
            footerTextSettings={footerTextSettings}
          />
        </ContentSectionWrapper>
      </Flex>
      <ResizablePreviewWrapper
        campaignURN={campaignURN}
        forceReload={forceReload}
      />
    </SplitView>
  );
}

export default Content;

interface ResizablePreviewWrapperProps {
  campaignURN: string;
  forceReload: string;
  width?: number;
}

function ResizablePreviewWrapper({
  campaignURN,
  forceReload,
  width,
}: ResizablePreviewWrapperProps) {
  const X = 680;
  // @ts-ignore
  const scale = width > X ? 1 : width / X;

  return (
    <PreviewWrapper
      campaignURN={campaignURN}
      previewTypes={[PREVIEW_TYPES.EDITION_TEMPLATE]}
      // @ts-ignore
      settings={{}}
      forceReload={forceReload}
      // @ts-ignore
      scale={scale}
      maxHeight="140vh"
    />
  );
}
