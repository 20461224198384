import React from 'react';

import ErrorDisplayBox from 'components/settings/profile/ErrorDisplayBox';

export interface ProfileWrapperProps {
  title: string;
  description: string;
  errorMessage?: string;
  children: React.ReactNode;
}

function ProfileWrapper({
  title,
  description,
  errorMessage,
  children,
}: ProfileWrapperProps) {
  return (
    <>
      <div className="profile-title mb-2">{title}</div>
      <div className="profile-description ebx-body-1">{description}</div>
      {errorMessage && <ErrorDisplayBox errorMessage={errorMessage} />}
      <div className="form-wrapper">{children}</div>
    </>
  );
}

ProfileWrapper.defaultProps = {
  errorMessage: null,
};

export default ProfileWrapper;
