import { Route, Switch } from 'react-router-dom';

import Branding from 'components/settings/campaigns/all/branding/Branding';
import General from 'components/settings/campaigns/all/branding/General';
import AccentColour from 'components/settings/campaigns/all/branding/general/AccentColour';
import Fonts from 'components/settings/campaigns/all/branding/general/Fonts';
import Header from 'components/settings/campaigns/all/branding/general/Header';
import Navbar from 'components/settings/campaigns/all/branding/general/Navbar';
import SignUp from 'components/settings/campaigns/all/branding/general/SignUp';
import Success from 'components/settings/campaigns/all/branding/Success';
import AccessRequest from 'components/settings/campaigns/all/preferences/AccessRequest';
import EmailRequest from 'components/settings/campaigns/all/preferences/EmailRequest';
import EmailSent from 'components/settings/campaigns/all/preferences/EmailSent';
import PreferencesCenter from 'components/settings/campaigns/all/preferences/PreferencesCenter';
import PreferencesCenterSuccess from 'components/settings/campaigns/all/preferences/PreferencesCenterSuccess';
import Settings from 'components/settings/campaigns/all/Settings';
import SubscriberPreferencesCenter from 'components/settings/campaigns/all/SubscriberPreferencesCenter';
import SubscriptionPage from 'components/settings/campaigns/all/subscription/SubscriptionPage';
import EmbeddedForm from 'components/settings/campaigns/EmbeddedForm';

import PreviewWrapper from 'components/settings/campaigns/all/PreviewWrapper';
import SettingsWrapper from 'components/settings/SettingsWrapper';

function Routing() {
  return (
    <Switch>
      <Route path="/settings/campaigns/allcampaigns" exact={true}>
        <SettingsWrapper>
          <Settings />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/allcampaigns/subscription" exact={true}>
        <SettingsWrapper>
          <SubscriptionPage />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/allcampaigns/branding" exact={true}>
        <SettingsWrapper>
          <Branding />
        </SettingsWrapper>
      </Route>
      <Route path="/settings/campaigns/allcampaigns/embedded-form" exact={true}>
        <SettingsWrapper>
          <EmbeddedForm />
        </SettingsWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/subscriber-preferences-center"
        exact={true}
      >
        <SettingsWrapper>
          <SubscriberPreferencesCenter />
        </SettingsWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/general"
        exact={true}
      >
        <PreviewWrapper>
          <General />
        </PreviewWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/general/fonts"
        exact={true}
      >
        <PreviewWrapper subPath="branding/general" backLabel="General Styling">
          <Fonts />
        </PreviewWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/general/accent-color"
        exact={true}
      >
        <PreviewWrapper subPath="branding/general" backLabel="General Styling">
          <AccentColour />
        </PreviewWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/general/navbar"
        exact={true}
      >
        <PreviewWrapper subPath="branding/general" backLabel="General Styling">
          <Navbar />
        </PreviewWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/general/header"
        exact={true}
      >
        <PreviewWrapper subPath="branding/general" backLabel="General Styling">
          <Header />
        </PreviewWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/general/sign-up-section"
        exact={true}
      >
        <PreviewWrapper subPath="branding/general" backLabel="General Styling">
          <SignUp />
        </PreviewWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/branding/success"
        exact={true}
      >
        <SettingsWrapper>
          <Success />
        </SettingsWrapper>
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/subscriber-preferences-center/access"
        exact={true}
      >
        <AccessRequest />
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/subscriber-preferences-center/email-request"
        exact={true}
      >
        <EmailRequest />
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/subscriber-preferences-center/email-sent"
        exact={true}
      >
        <EmailSent />
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/subscriber-preferences-center/preferences-center"
        exact={true}
      >
        <PreferencesCenter />
      </Route>
      <Route
        path="/settings/campaigns/allcampaigns/subscriber-preferences-center/preferences-center-success"
        exact={true}
      >
        <PreferencesCenterSuccess />
      </Route>
    </Switch>
  );
}

export default Routing;
