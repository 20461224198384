import React from 'react';

export interface EditionWrapperProps {
  children: React.ReactNode;
  className?: string;
}

function EditionWrapper({ children, className = '' }: EditionWrapperProps) {
  return (
    <div className={`ebx-panel edition-panel d-flex mb-2 ${className}`}>
      <div className="w-100">{children}</div>
    </div>
  );
}

EditionWrapper.defaultProps = {
  className: null,
};

export default EditionWrapper;
