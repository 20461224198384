import { SetStateAction, useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay?: number | undefined): T;
function useDebounce<T, B extends boolean>(
  value: T,
  delay: number | undefined,
  returnSetter: B
): B extends true ? [T, React.Dispatch<SetStateAction<T>>] : T;

function useDebounce<T>(
  value: T,
  delay?: number | undefined,
  returnSetter = false
) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  if (returnSetter) {
    return [debouncedValue, setDebouncedValue];
  }

  return debouncedValue;
}

export default useDebounce;
