import { Redirect, Route, Switch } from 'react-router-dom';

import EmailEditor from 'components/editor/marketing/EmailEditor';

function Routing() {
  return (
    <Switch>
      <Route path="/marketing/:id/edit">
        <EmailEditor />
      </Route>
      <Route
        path="/marketing"
        exact={true}
        render={() => <Redirect to="/" />}
      />
    </Switch>
  );
}

export default Routing;
