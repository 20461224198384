import axios, { AxiosError } from 'axios';

import * as datetime from 'common/datetime';
import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getCampaignPerformanceArgs {
  campaignURN: string;
  startUnixTime?: number;
  endUnixTime?: number;
}

const now = datetime.getUnixTimestamp();

export default async function getCampaignPerformance({
  campaignURN,
  startUnixTime = now - datetime.DAYS(30),
  endUnixTime = now,
}: getCampaignPerformanceArgs) {
  if (isUndefined(campaignURN)) {
    throw new ReferenceError(`Missing parameter propertyURN`);
  }

  const guid = metrics.start('getCampaignPerformance');

  const url = `${apiBaseURL(
    APIS.OPTIMISATION_SERVICE,
    ''
  )}/campaigns/insights?campaignURNs=${campaignURN}&startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}`;

  const config = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(
    `API:getCampaignPerformance /campaigns/insights?campaignURNs=${campaignURN}&startUnixTime=${startUnixTime}&endUnixTime=${endUnixTime}`
  );

  try {
    const response = await axios(config);
    metrics.end('getCampaignPerformance', guid);
    return (
      response?.data?.campaigns[0] ?? {
        campaignURN,
        numDeliveries: 0,
        numUniqueOpens: 0,
        numUniqueClicks: 0,
      }
    );
  } catch (error) {
    metrics.fail('getCampaignPerformance', guid);
    const err = error as AxiosError;
    if (err?.response?.status !== 403) {
      const apiError =
        err instanceof Error
          ? await handleAPIError({
              originalError: err,
              errorLocation: 'api/getCampaignPerformance',
              args: { campaignURN },
              ignore403Errors: true,
            })
          : error;
      throw apiError;
    } else {
      return null;
    }
  }
}
