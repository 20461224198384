import * as campaigns from 'common/campaigns';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useGlobalInfo from 'hooks/useGlobalInfo';
import { BackLink, Container, Title } from '../../../common';

function ConfirmationContent({ setSettingsPanel, settings }) {
  const { urn } = useParams();
  const globalInfo = useGlobalInfo();
  const campaignName = campaigns.getCampaignName(urn, globalInfo);

  let header = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
    'confirmationEmailTitleCopy'
  );
  if (header) {
    header = header.replace('{campaignName}', campaignName);
  }

  const description = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
    'confirmationEmailBodyCopy'
  );

  let subjectLine = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.SUBSCRIBER_CONFIRMATION,
    'subjectLine'
  );
  if (subjectLine) {
    subjectLine = subjectLine.replace('{campaignName}', campaignName);
  }

  const setHeader = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
      settingKey: 'confirmationEmailTitleCopy',
      settingValue: event.target.value,
    });
  };

  const setDescription = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_CONFIRMATION,
      settingKey: 'confirmationEmailBodyCopy',
      settingValue: event.target.value,
    });
  };

  const setSubjectLine = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SUBSCRIBER_CONFIRMATION,
      settingKey: 'subjectLine',
      settingValue: event.target.value,
    });
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_CONFIRMATION_PAGE.OPTIONS
          )
        }
      >
        <BackLink linkText="Back to Verification" />
      </Button>
      <Title className="mb-3">
        <span className="mr-3">Content</span>
      </Title>
      <Title className="settings-subtitle">
        <span className="mr-4">Email Subject Line</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        required
        placeholder="Thank you for subscribing"
        value={subjectLine}
        onChange={setSubjectLine}
      />
      <Title className="settings-subtitle">
        <span className="mr-4">Header</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        required
        placeholder="You've subscribed to our newsletter"
        value={header}
        onChange={setHeader}
      />
      <Title className="settings-subtitle">
        <span className="mr-4">Description</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        as="textarea"
        type="text"
        rows={3}
        placeholder="Thank you for signing up for our newsletter! Please add us to your safe senders."
        required
        value={description}
        onChange={setDescription}
        autoComplete="off"
      />
    </Container>
  );
}

ConfirmationContent.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default ConfirmationContent;
