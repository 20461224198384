import { Box, Heading, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import Edition from 'components/campaigns/editions/Edition';

import * as datetime from 'common/datetime';
import type { Edition as EditionType } from 'common/types';

interface SentEmailsProps {
  editionList: EditionType[];
  loadEditionData: () => void;
}

function SentEmails({ editionList, loadEditionData }: SentEmailsProps) {
  const latestEdition = editionList[0];

  // Show disclaimer if Edition was sent in the last 7 days
  const showDisclaimer = latestEdition
    ? latestEdition.scheduledUnixTime > datetime.NOW - datetime.DAYS(7)
    : null;

  return (
    <Box data-cy="sentEmails">
      <Heading variant="h3" color="gray.900" mb={showDisclaimer ? 2 : 4}>
        Sent
      </Heading>
      {showDisclaimer && (
        <Text size="sm" color="gray.600" mb={4}>
          Your open and click rates may fluctuate as more subscribers view their
          emails.
        </Text>
      )}
      {editionList.map(edition => (
        <Edition
          edition={edition}
          key={edition.editionURN}
          loadEditionData={loadEditionData}
        />
      ))}
    </Box>
  );
}

export default SentEmails;
