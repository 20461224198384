import {
  Box,
  Button,
  CloseIcon,
  Divider,
  Flex,
  Heading,
  Text,
  Tooltip,
  VisuallyHidden,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, Nav, Navbar, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as campaigns from 'common/campaigns';
import * as datetime from 'common/datetime';
import * as editions from 'common/editions';
import * as i18n from 'common/i18n';
import * as tracker from 'common/tracker';
import useGlobalInfo from 'hooks/useGlobalInfo';

import EditionSendTimes from 'components/campaigns/editions/EditionSendTimes';
import Modes from 'components/common/Modes';
import OptimisationLevel from 'components/editor/curate/OptimisationLevel';
import HeaderRight from 'components/editor/HeaderRight';
import Scheduler from 'components/editor/schedule/Scheduler';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CAMPAIGN_SETTING_TYPES } from 'common/constants';
import { Campaign, Edition } from 'common/types';

import { EDITION_APPROVAL_STATES } from 'common/enums';
import './Subheader.css';

// const SendPreviewPopover = (userEmail: string) => (
//   <Popover id="popover-basic" className="send-preview-popover">
//     <Popover.Content className="text-center ebx-small-text ebx-roboto">
//       Send to {userEmail}
//     </Popover.Content>
//   </Popover>
// );

export interface SubheaderProps {
  approvalState?: string | null;
  campaignDetails: Campaign;
  editionDetails?: Edition | null;
  updateEditionDetails: () => void;
  handleApprove: (isDraft: boolean) => void;
  isApproving: boolean;
  isEditionEditable: boolean;
  isDomainVerificationComplete: boolean;
}

function Subheader({
  approvalState,
  campaignDetails,
  editionDetails = null,
  updateEditionDetails,
  handleApprove,
  isApproving,
  isEditionEditable,
  isDomainVerificationComplete,
}: SubheaderProps) {
  const [isDraft, setDraft] = useState(false);

  const globalInfo = useGlobalInfo();

  const currentTime = datetime.getUnixTimestamp();

  const [editionDisplayState, setEditionDisplayState] = useState(
    editions.getEditionDisplayState(
      approvalState,
      editionDetails?.editionSource,
      editionDetails?.lastUpdatedUnixTime,
      editionDetails?.editionState,
      editionDetails?.scheduledUnixTime,
      currentTime
    )
  );

  useEffect(() => {
    setEditionDisplayState(
      editions.getEditionDisplayState(
        approvalState,
        editionDetails?.editionSource,
        editionDetails?.lastUpdatedUnixTime,
        editionDetails?.editionState,
        editionDetails?.scheduledUnixTime,
        datetime.getUnixTimestamp()
      )
    );
  }, [
    approvalState,
    editionDetails?.editionSource,
    editionDetails?.lastUpdatedUnixTime,
    editionDetails?.editionState,
    editionDetails?.scheduledUnixTime,
    editionDisplayState,
  ]);

  const browserLocale = i18n.getBrowserLocale();

  let finishedSendingTime = null;
  if (
    editionDetails?.scheduledUnixTime &&
    editionDetails?.sendingTimeslotSizeSeconds
  ) {
    finishedSendingTime =
      editionDetails.scheduledUnixTime +
      (editionDetails?.sendingTimeslotSizeSeconds ?? 0);
  } else if (editionDetails?.scheduledUnixTime) {
    finishedSendingTime = editionDetails.scheduledUnixTime;
  }

  const handleExitCurate = () => {
    const isContentPersonalisationEnabled =
      campaigns.getCurrentCampaignSettingValue(
        globalInfo,
        CAMPAIGN_SETTING_TYPES.CONTENT_PERSONALISATION,
        'isContentOrderingEnabled'
      );
    tracker.track({
      eventName: 'Exit Curate Page',
      trackingParams: {
        'Email ID': editionDetails?.editionURN,
        'Personalised Edition': isContentPersonalisationEnabled ? 'Yes' : 'No',
        'Edition State Before': editionDisplayState,
        Subject: editionDetails?.editionSubject,
        'Email Type': 'Newsletter',
      },
    });
  };

  const handleSaveApprove = () => {
    setDraft(false);
    handleApprove(false);
  };

  const handleSaveDraft = () => {
    setDraft(true);
    handleApprove(true);
  };

  return (
    <Navbar
      collapseOnSelect
      variant="light"
      expand="lg"
      id="subheader"
      className="navbar--height ebx-curation-subheader w-100"
    >
      <Nav className="d-flex flex-wrap flex-row align-items-center w-100 h-100">
        <Flex mr={4}>
          <Box mr={4}>
            <Link to="/campaigns">
              <Button variant="link" onClick={handleExitCurate}>
                <CloseIcon size={5} color="gray.900" />
              </Button>
            </Link>
          </Box>
          <Divider height="20px" mt={0.5} mr={6} orientation="vertical" />
          <Heading variant="h3">{campaignDetails.campaignName}</Heading>
          {editionDetails?.scheduledUnixTime && browserLocale && (
            <Text size="sm" color="gray.600" lineHeight={6} ml={3}>
              {new Date(
                editionDetails.scheduledUnixTime * 1000
              ).toLocaleDateString(browserLocale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </Text>
          )}
        </Flex>

        {/* @ts-ignore */}
        <OptimisationLevel editionDetails={editionDetails} />

        <Modes />

        <HeaderRight>
          {isEditionEditable &&
          editionDetails?.scheduledUnixTime &&
          finishedSendingTime ? (
            <Scheduler
              title="Schedule Edition"
              edition={editionDetails}
              editionDisplayState={editionDisplayState}
              updateEditionDetails={updateEditionDetails}
              initialFinishedSendingTime={finishedSendingTime}
              scheduleEditionButton={true}
            />
          ) : (
            editionDetails?.scheduledUnixTime &&
            finishedSendingTime && (
              <div className="mr-2">
                <EditionSendTimes
                  scheduledUnixTime={editionDetails.scheduledUnixTime}
                  finishedSendingTime={finishedSendingTime}
                  editionState={editionDetails.editionState}
                />
              </div>
            )
          )}
          {isEditionEditable && (
            <Dropdown as={ButtonGroup}>
              <Tooltip
                label="Verify your domain to approve Editions"
                placement="bottom-end"
                isDisabled={isDomainVerificationComplete}
                shouldWrapChildren
              >
                <Button
                  variant="primary"
                  height="38px"
                  borderTopRightRadius={0}
                  borderBottomRightRadius={0}
                  onClick={handleSaveApprove}
                  isDisabled={
                    isApproving ||
                    approvalState === EDITION_APPROVAL_STATES.CLIENT_APPROVED ||
                    !isDomainVerificationComplete
                  }
                >
                  {isApproving ? (
                    <div>
                      {isDraft ? 'Saving...' : 'Approving...'}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ml-2"
                      />
                      <VisuallyHidden>Loading...</VisuallyHidden>
                    </div>
                  ) : (
                    'Approve'
                  )}
                </Button>
              </Tooltip>
              {
                <>
                  <Dropdown.Toggle
                    id="approve-toggle"
                    split
                    className="ebx-btn-primary ebx-btn-sm ebx-h3"
                    // Removes the caret
                    bsPrefix="p-1 dropdown-toggle"
                  >
                    <KeyboardArrowDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight className="ebx-menu-dropdown">
                    <Dropdown.Item
                      className="ebx-h3"
                      onClick={handleSaveDraft}
                      disabled={isApproving}
                    >
                      Save as draft
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </>
              }
            </Dropdown>
          )}
        </HeaderRight>
      </Nav>
    </Navbar>
  );
}

Subheader.defaultProps = {
  editionDetails: null,
};

export default Subheader;
