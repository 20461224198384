import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function putBodyElementPositioning({
  campaignURN = mandatory('campaignURN'),
  bodyElementPositioning = mandatory('bodyElementPositioning'),
}) {
  const guid = metrics.start('putBodyElementPositioning');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/bodyelementpositioning`,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      bodyElementPositioning,
    }),
  };
  logger.info(
    `API:putBodyElementPositioning /campaigns/${campaignURN}/bodyelementpositioning`
  );

  try {
    const response = await axios(config);
    metrics.end('putBodyElementPositioning', guid);
    return response.data;
  } catch (error) {
    metrics.fail('putBodyElementPositioning', guid);
    const apiError = await handleAPIError({
      originalError: error,
      // TODO - work out how to sort out this TypeScript hack at some point
      // @ts-ignore
      errorLocation: 'api/putBodyElementPositioning',
      args: { bodyElementPositioning },
    });
    throw apiError;
  }
}
