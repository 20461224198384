import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import { DAY_NAME_MAP, OCCURRENCES, WEEKDAYS } from 'common/constants';
import { Dropdown } from 'components/settings/campaigns/common';

import './FrequencyMonthly.css';

function FrequencyMonthly({ monthlyOptions, onMonthlyChange, errorMessage }) {
  const OCCURRENCE_OPTIONS = Object.keys(OCCURRENCES).map(key => ({
    label: OCCURRENCES[key],
    value: OCCURRENCES[key],
  }));

  const DAY_OPTIONS = WEEKDAYS.map(day => ({
    label: DAY_NAME_MAP[day],
    value: day,
  }));

  const occurrenceOption = OCCURRENCE_OPTIONS.find(
    option => option.value === monthlyOptions.occurrence
  );
  const dayOption = DAY_OPTIONS.find(
    option => option.value === monthlyOptions.dayOfWeek
  );

  const handleDayChange = ({ value }) => {
    onMonthlyChange({
      ...monthlyOptions,
      dayOfWeek: value,
    });
  };

  const handleOccurrenceChange = ({ value }) => {
    onMonthlyChange({
      ...monthlyOptions,
      occurrence: value,
    });
  };

  return (
    <div className="frequency-monthly">
      <Row className="px-3 pb-2 d-flex align-items-center">
        <div className="ebx-hr-text-colour monthly-editions-message">
          Generate and send Editions on the{' '}
        </div>
        <div className="ebx-h3 ebx-title-colour dropdown-occurrence-container">
          <Dropdown
            className="mx-2"
            placeholder="-"
            options={OCCURRENCE_OPTIONS}
            value={occurrenceOption}
            onChange={handleOccurrenceChange}
            isSearchable
          />
        </div>
        <div className="ebx-h3 ebx-title-colour dropdown-day-container">
          <Dropdown
            className="mr-2"
            placeholder="-"
            options={DAY_OPTIONS}
            value={dayOption}
            onChange={handleDayChange}
            isSearchable
          />
        </div>
        <div className="ebx-hr-text-colour monthly-editions-message">
          of each month{' '}
        </div>
      </Row>
      <div className="ebx-error-colour ebx-h3">{errorMessage}</div>
    </div>
  );
}

FrequencyMonthly.propTypes = {
  monthlyOptions: PropTypes.shape({
    occurrence: PropTypes.string,
    dayOfWeek: PropTypes.string,
  }).isRequired,
  onMonthlyChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

FrequencyMonthly.defaultProps = {
  errorMessage: '',
};

export default FrequencyMonthly;
