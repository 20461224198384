import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function postSyndfeed({
  propertyURN = mandatory('propertyURN'),
  feedURL = mandatory('feedURL'),
}) {
  const guid = metrics.start('postSyndfeed');

  const config = {
    url: `${apiBaseURL(APIS.CORE, '1')}/properties/${propertyURN}/syndfeeds`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      feedURL,
    }),
  };
  logger.info(`API:postSyndfeed /properties/${propertyURN}/syndfeeds`);

  try {
    const response = await axios(config);
    metrics.end('postSyndfeed', guid);
    return response.data.feedData;
  } catch (error) {
    metrics.fail('postSyndfeed', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postSyndfeed',
      args: { feedURL },
    });
    throw apiError;
  }
}
