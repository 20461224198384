import { useLocation } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

import * as campaigns from 'common/campaigns';
import HeaderLink from 'components/settings/header/HeaderLink';
import useGlobalInfo from 'hooks/useGlobalInfo';

import { APPS_AND_INTEGRATIONS } from 'common/constants';

const getURNSublevelName = (sublevel: string) => {
  switch (sublevel) {
    case 'general':
      return 'Campaign details';
    case 'content':
      return 'Content';
    case 'automation':
      return 'Automation';
    case 'branding':
      return 'Branding';
    case 'sign-up-forms':
      return 'Sign-up forms';
    case 'apps-and-integrations':
      return 'Apps & integrations';
    default:
      return sublevel;
  }
};

const getBrandingPage = (
  campaignURNSublevel: string,
  brandingOption: string
) => {
  if (campaignURNSublevel === 'apps-and-integrations') {
    try {
      return APPS_AND_INTEGRATIONS.filter(
        app => app.key === brandingOption
      ).map(app => app.name);
    } catch {
      return brandingOption;
    }
  }

  switch (brandingOption) {
    case 'general-styling':
      return 'General styling';
    case 'email-confirmation':
      return 'Confirmation email';
    case 'email-edition':
      return 'Edition';
    case 'email-verify':
      return 'Verification email';
    case 'sub-default':
      return 'Subscription page';
    case 'sub-verify':
      return 'Verification page';
    case 'sub-success':
      return 'Success page';
    case 'sub-unsubscribe':
      return 'Unsubscribe page';
    case 'subscription-page':
      return 'Subscription page';
    case 'embedded-form':
      return 'Embedded form';
    default:
      return brandingOption;
  }
};

const getAllCampaignsPage = (subPage: string) => {
  switch (subPage) {
    case 'subscription':
      return 'Subscription page';
    case 'branding':
      return 'Branding';
    case 'embedded-form':
      return 'Embedded form';
    case 'subscriber-preferences-center':
      return 'Subscriber preferences center';
    default:
      return subPage;
  }
};

const getAllCampaignsBrandingPage = (subPage: string) => {
  switch (subPage) {
    case 'general':
      return 'General styling';
    case 'success':
      return 'Success';
    case 'access':
      return 'Access request page';
    case 'email-sent':
      return 'Email sent';
    case 'email-request':
      return 'Email request';
    case 'preferences-center':
      return 'Preferences center';
    case 'preferences-center-success':
      return 'Preferences center success';
    default:
      return subPage;
  }
};

const getAllCampaignsBrandingSubPage = (subPage: string) => {
  switch (subPage) {
    case 'fonts':
      return 'Fonts';
    case 'accent-color':
      return 'Accent color';
    case 'navbar':
      return 'Navbar';
    case 'header':
      return 'Header';
    case 'sign-up-section':
      return 'Sign up section';
    default:
      return subPage;
  }
};

function CampaignLinks() {
  const globalInfo = useGlobalInfo();
  const location = useLocation();

  /* Render nothing if we are not showing a campaign settings page or sub-page */
  if (!location.pathname.startsWith('/settings/campaigns')) {
    return null;
  }

  const campaignSettingsPath = '/settings/campaigns';
  const campaignSubpath = location.pathname
    .replace(campaignSettingsPath, '')
    .substring(1);

  const [campaignURN, campaignURNSublevel, brandingPage, brandingSubPage] =
    campaignSubpath.split('/');

  if (campaignURN === 'allcampaigns') {
    return (
      <>
        <ChevronRight />
        <HeaderLink to={campaignSettingsPath}>
          <span className="ebx-h1">Campaigns</span>
        </HeaderLink>
        <ChevronRight />
        <HeaderLink to={`${campaignSettingsPath}/allcampaigns`}>
          <span className="ebx-h1">All Campaigns</span>
        </HeaderLink>
        {campaignURNSublevel && (
          <>
            <ChevronRight />
            <HeaderLink
              to={`${campaignSettingsPath}/allcampaigns/${campaignURNSublevel}`}
            >
              <span className="ebx-h1">
                {getAllCampaignsPage(campaignURNSublevel)}
              </span>
            </HeaderLink>
          </>
        )}
        {brandingPage && (
          <>
            <ChevronRight />
            <HeaderLink
              to={`${campaignSettingsPath}/allcampaigns/${campaignURNSublevel}/${brandingPage}`}
            >
              <span className="ebx-h1">
                {getAllCampaignsBrandingPage(brandingPage)}
              </span>
            </HeaderLink>
          </>
        )}
        {brandingSubPage && (
          <>
            <ChevronRight />
            <HeaderLink
              to={`${campaignSettingsPath}/allcampaigns/${campaignURNSublevel}/${brandingPage}/${brandingSubPage}`}
            >
              <span className="ebx-h1">
                {getAllCampaignsBrandingSubPage(brandingSubPage)}
              </span>
            </HeaderLink>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <ChevronRight />
      <HeaderLink to={campaignSettingsPath}>
        <span className="ebx-h1">Campaigns</span>
      </HeaderLink>
      {campaignURN && (
        <>
          <ChevronRight />
          <HeaderLink to={`${campaignSettingsPath}/${campaignURN}`}>
            <span className="ebx-h1">
              {campaigns.getCampaignName(campaignURN, globalInfo)}
            </span>
          </HeaderLink>
        </>
      )}
      {campaignURNSublevel && (
        <>
          <ChevronRight />
          <HeaderLink
            to={`${campaignSettingsPath}/${campaignURN}/${campaignURNSublevel}`}
          >
            <span className="ebx-h1">
              {getURNSublevelName(campaignURNSublevel)}
            </span>
          </HeaderLink>
        </>
      )}
      {brandingPage && (
        <>
          <ChevronRight />
          <HeaderLink
            to={`${campaignSettingsPath}/${campaignURN}/${campaignURNSublevel}/${brandingPage}`}
          >
            <span className="ebx-h1">
              {getBrandingPage(campaignURNSublevel, brandingPage)}
            </span>
          </HeaderLink>
        </>
      )}
    </>
  );
}

export default CampaignLinks;
