import React, { createRef } from 'react';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

interface LeftPanelProps {
  children: React.ReactNode;
  leftWidth: number | undefined;
  setLeftWidth: (value: number) => void;
}

function LeftPanel({ children, leftWidth, setLeftWidth }: LeftPanelProps) {
  const leftRef = createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (leftRef.current) {
      if (!leftWidth) {
        setLeftWidth(leftRef.current?.clientWidth);
        return;
      }

      leftRef.current.style.width = `${leftWidth}px`;
    }
  }, [leftRef, leftWidth, setLeftWidth]);

  return (
    <Box py={6} ref={leftRef}>
      {children}
    </Box>
  );
}

export default LeftPanel;
