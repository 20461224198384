import { Button, Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

function SendEmail({
  canSend,
  isApproved,
  isSaving,
  isDomainVerificationComplete,
  handleSaveEmail,
}) {
  const buttonLabel = isApproved ? 'Save' : 'Approve';

  return (
    <Dropdown as={ButtonGroup}>
      <Tooltip
        label="Verify your domain to send emails"
        placement="bottom-end"
        isDisabled={isDomainVerificationComplete}
        shouldWrapChildren
      >
        <Button
          variant="primary"
          height="38px"
          borderTopRightRadius={0}
          borderBottomRightRadius={0}
          isDisabled={!canSend || isSaving || !isDomainVerificationComplete}
          onClick={() => handleSaveEmail(false)}
        >
          {buttonLabel}
        </Button>
      </Tooltip>
      <Dropdown.Toggle
        id="approve-toggle"
        split
        className="ebx-btn-primary ebx-btn-sm ebx-h3"
        bsPrefix="p-1 dropdown-toggle"
      >
        <KeyboardArrowDownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight className="ebx-menu-dropdown">
        <Dropdown.Item
          className="ebx-h3"
          disabled={!canSend || isSaving}
          onClick={() => handleSaveEmail(true)}
        >
          Save as draft
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

SendEmail.propTypes = {
  canSend: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isDomainVerificationComplete: PropTypes.bool.isRequired,
  handleSaveEmail: PropTypes.func.isRequired,
};

export default SendEmail;
