import {
  Alert,
  Check2Icon,
  Flex,
  Heading,
  Modal,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface SendPreviewResultProps {
  isOpen: boolean;
  onClose: () => void;
  sendPreviewError: string;
}

function SendPreviewResult({
  isOpen,
  onClose,
  sendPreviewError,
}: SendPreviewResultProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="small">
      <Modal.Header>
        <Modal.Title>Send preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sendPreviewError ? (
          <Flex flexDir="column" alignItems="center" gap={1}>
            <Alert variant="error">
              <Alert.AlertDescription>
                <Heading variant="h5">
                  Preview was unable to send: {sendPreviewError}
                </Heading>
              </Alert.AlertDescription>
            </Alert>
          </Flex>
        ) : (
          <Flex flexDir="column" alignItems="center" gap={1}>
            <Check2Icon size={8} color="primary.600" />
            <Heading variant="h5" color="gray.600">
              Preview sent!
            </Heading>
          </Flex>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default SendPreviewResult;
