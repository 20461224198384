/* eslint @typescript-eslint/no-use-before-define: "off" */

import * as utility from 'common/utility';
import { TextBlock } from './types';

export { getTextBlock, updateTextBlocks };

const getTextBlock = (blockURN: string, textBlocks: TextBlock[]) => {
  let textBlock = null;
  textBlocks.forEach(block => {
    if (block.textBlockURN === blockURN) {
      textBlock = block;
    }
  });
  return textBlock;
};
const updateTextBlocks = (
  textBlocks: TextBlock[],
  blockDetails: {
    textBlockURN: string;
    textBlockTitle: string;
    textBlockBody: string;
    imageURL: string;
  }
) => {
  const updatedTextBlocks = utility.cloneObject(textBlocks);

  updatedTextBlocks.forEach((block: TextBlock, blockIndex: number) => {
    if (block.textBlockURN === blockDetails.textBlockURN) {
      updatedTextBlocks[blockIndex].textBlockBody = blockDetails.textBlockBody;
      updatedTextBlocks[blockIndex].textBlockTitle =
        blockDetails.textBlockTitle;
      updatedTextBlocks[blockIndex].imageURL = blockDetails.imageURL;
    }
  });

  return updatedTextBlocks;
};
