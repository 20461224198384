import {
  Button,
  Flex,
  Heading,
  Modal,
  Radio,
  RadioGroup,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

// import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
// import * as datetime from 'common/datetime';
// import * as properties from 'common/properties';
// import * as url from 'common/url';
// import * as utility from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';

interface ExportAnalyticsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ExportAnalyticsModal({ isOpen, onClose }: ExportAnalyticsModalProps) {
  const globalInfo = useGlobalInfo();
  // const toast = useToast();
  const campaignName = campaigns.getCurrentCampaignName(globalInfo);

  const handleSubmit = async () => {
    console.log('Not yet implemented');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered>
      <Modal.Header>
        <Modal.Title>Export analytics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Heading variant="h4" mb={4}>
          Which Editions?
        </Heading>
        <RadioGroup defaultValue="campaign">
          <Flex flexDir="column" gap={1}>
            <Radio value="campaign">
              <Text as="span" size="sm" color="gray.900" fontWeight={500}>
                {campaignName}
              </Text>{' '}
              <Text as="span" size="sm" color="gray.600" fontWeight={500}>
                (This campaign)
              </Text>
            </Radio>
            <Radio value="property">
              <Text as="span" size="sm" color="gray.900" fontWeight={500}>
                All campaigns
              </Text>{' '}
              <Text as="span" size="sm" color="gray.600" fontWeight={500}>
                (This property)
              </Text>
            </Radio>
          </Flex>
        </RadioGroup>
        <Heading variant="h4" mt={6} mb={4}>
          Compare against
        </Heading>
        <RadioGroup defaultValue="0">
          <Flex flexDir="column" gap={1}>
            <Radio value="0">Since the last Edition was sent</Radio>
            <Radio value="7">Last 7 days</Radio>
            <Radio value="14">Last 14 days</Radio>
            <Radio value="30">Last 30 days</Radio>
          </Flex>
        </RadioGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExportAnalyticsModal;
