import InfoIcon from '@material-ui/icons/Info';

interface ErrorDisplayBoxProps {
  errorMessage: string;
}

function ErrorDisplayBox({ errorMessage }: ErrorDisplayBoxProps) {
  return (
    <div className="ebx-error-display-box w-100 px-3 py-2 d-flex mb-3 align-items-center">
      <div className="ebx-body-1 d-flex align-items-center ebx-input-box-font ebx-faded-colour">
        <div>
          <InfoIcon className="ebx-error-colour ebx-error-icon mr-1" />
          <span className="ebx-h3 mr-1">{errorMessage}</span>
        </div>
      </div>
    </div>
  );
}

export default ErrorDisplayBox;
