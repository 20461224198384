import { useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import * as campaigns from 'common/campaigns';
import * as clipboard from 'common/clipboard';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import useGlobalInfo from 'hooks/useGlobalInfo';
import kebabCase from 'lodash.kebabcase';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import ViewIcon from '@mui/icons-material/VisibilityOutlined';

const CopiedPopover = (
  <Popover className="copied__popover">
    <Popover.Content className="ebx-h3">Copied</Popover.Content>
  </Popover>
);

function generateSubscriptionLink(globalInfo) {
  const propertyName = kebabCase(properties.getCurrentPropertyName(globalInfo));
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  return `https://${propertyName}.campaign-list.com/subscribe/${propertyURN}`;
}

function AddressURL() {
  const [showCopiedAddress, setShowCopiedAddress] = useState(false);
  const [overflowAddress, setOverflowAddress] = useState(false);

  const globalInfo = useGlobalInfo();
  const subscriptionLink = generateSubscriptionLink(globalInfo);

  const address = useRef();

  /**
   * Generate a random 9-digit number starting with a non-zero digit
   * This will be passed to the subscription page as a query parameter
   * so that when we track subscribe events on that page, they can be
   * associated with the user clicking on the link from this page
   */
  const pageId = Math.floor(100000000 + Math.random() * 900000000);

  const handleCopyAddress = event => {
    setShowCopiedAddress(true);
    event.preventDefault();
    clipboard.setClipboardData(null, `${subscriptionLink}?pageId=${pageId}`);
    tracker.track({
      eventName: 'Copy Subscription Snippet',
      trackingParams: {
        'Snippet Type': 'Address',
      },
    });
    setTimeout(() => setShowCopiedAddress(false), 3000);
  };

  const handleViewPage = () => {
    tracker.track({
      eventName: 'Display Subscription Page',
      trackingParams: {
        'Subscription Page Displayed to Reader': subscriptionLink,
        '# Campaigns Offered':
          campaigns.getCurrentPropertyCampaigns(globalInfo).length,
        'Page Instance ID': pageId,
      },
    });
  };

  // Sets variable that adds overflow class to the address bar
  useEffect(() => {
    setOverflowAddress(
      address.current.scrollWidth > address.current.clientWidth
    );
  }, []);

  return (
    <>
      <div className="ebx-h2 mb-2">Address URL</div>
      <a
        className={`ebx-display-box px-3 d-flex mb-3 align-items-center justify-content-between subscription--display subscription__address--input ${
          overflowAddress ? 'overflow' : ''
        }`}
        href={`${subscriptionLink}?pageId=${pageId}`}
        target="_blank"
        rel="noreferrer"
      >
        <span className="ebx-body-1" ref={address}>
          {subscriptionLink}
        </span>
        <LaunchIcon className="external-link-icon ml-3" />
      </a>
      <Button
        className="ebx-btn-secondary copy--button mr-3"
        href={`${subscriptionLink}?pageId=${pageId}`}
        onClick={handleViewPage}
        target="_blank"
      >
        <ViewIcon className="view-icon" />
        <span className="ml-2 ebx-h3">View page</span>
      </Button>
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={CopiedPopover}
        show={showCopiedAddress}
        rootClose
      >
        <Button
          className="ebx-btn-secondary copy--button"
          onClick={handleCopyAddress}
        >
          <ContentCopyIcon className="copy-icon" />
          <span className="ml-2 ebx-h3">Copy link</span>
        </Button>
      </OverlayTrigger>
    </>
  );
}

export default AddressURL;
