import {
  ActiveLiveIcon,
  LightingHighlightIcon,
  SpinnerLoadingIcon,
  Tag,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { EDITION_DISPLAY_STATES } from 'common/constants';

export interface EditionStateProps {
  editionDisplayState: string | null;
  displayLabel: string;
}

function EditionState({
  editionDisplayState,
  displayLabel,
}: EditionStateProps) {
  let showAutomationIcon = false;
  let showPopulatingIcon = false;
  let showStateLabel = false;
  let staffModeIcon = false;
  let devMode = false;

  if (editionDisplayState) {
    showAutomationIcon =
      editionDisplayState === EDITION_DISPLAY_STATES.AUTOMATED;
    showPopulatingIcon =
      editionDisplayState === EDITION_DISPLAY_STATES.POPULATING;
    showStateLabel =
      editionDisplayState === EDITION_DISPLAY_STATES.AUTOMATED ||
      editionDisplayState === EDITION_DISPLAY_STATES.POPULATING ||
      editionDisplayState === 'STAFF_MODE' ||
      editionDisplayState === 'DEVELOPER_MODE';
    staffModeIcon = editionDisplayState === 'STAFF_MODE';
    devMode = editionDisplayState === 'DEVELOPER_MODE';
  }

  if (!showStateLabel) {
    return null;
  }

  return (
    <>
      {showAutomationIcon && (
        <Tag color="blue" icon={<LightingHighlightIcon />}>
          {displayLabel}
        </Tag>
      )}
      {showPopulatingIcon && (
        <Tag color="amber" icon={<SpinnerLoadingIcon />}>
          {displayLabel}
        </Tag>
      )}
      {staffModeIcon && (
        <Tag color="green" icon={<ActiveLiveIcon />}>
          {displayLabel}
        </Tag>
      )}
      {devMode && <Tag color="amber">{displayLabel}</Tag>}
    </>
  );
}

export default EditionState;
