import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

/**
 * deleteTextBlock
 * @param {{
 *  textBlockURN: string;
 * }}
 */

interface DeleteTextBlockArgs {
  textBlockURN: string;
}

export default async function deleteTextBlock({
  textBlockURN,
}: DeleteTextBlockArgs) {
  if (isUndefined(textBlockURN)) {
    mandatory('textBlockURN');
  }

  const guid = metrics.start('deleteTextBlock');

  const url = `${apiBaseURL(APIS.NEWSLETTERS)}/textblocks/${textBlockURN}`;

  const config = {
    url,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };

  logger.info(`API:deleteTextBlock /textblocks/${textBlockURN}`);

  try {
    const response = await axios(config);
    metrics.end('deleteTextBlock', guid);
    return response.data;
  } catch (error) {
    metrics.fail('deleteTextBlock', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/deleteTextBlock',
            args: { textBlockURN },
          })
        : error;
    throw apiError;
  }
}
