import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Button,
  Flex,
  Spinner,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import ExportEditionAnalyticsModal from 'components/common/ExportEditionAnalyticsModal';
import ExportHTML from 'components/editor/preview/ExportHTML';
import PreviewDevices from 'components/editor/preview/PreviewDevices';
import SendPreview from 'components/editor/preview/SendPreview';

import * as authentication from 'common/authentication';
import {
  CURATION_PAGE_MOBILE_PREVIEW_WIDTH,
  PREVIEW_TYPE_PROPERTIES,
} from 'common/constants';
import { EDITION_APPROVAL_STATES, PREVIEW_TYPES } from 'common/enums';
import * as i18n from 'common/i18n';

import { StoreContext } from 'store/store';

import './Preview.css';

interface PreviewProps {
  campaignName?: string;
  approvalState?: keyof typeof EDITION_APPROVAL_STATES | null;
  editionURN?: string | null;
  isLoadingPreview: boolean;
  isSent: boolean;
  isToolbarDisabled?: boolean;
  previewSource?: string | null;
  previewTitle?: string;
  previewType: keyof typeof PREVIEW_TYPES;
  scheduledUnixTime?: number | null;
  scale?: number;
  maxHeight?: string | null;
  children: React.ReactNode;
}

function Preview({
  campaignName = 'Campaign',
  approvalState = null,
  editionURN = null,
  isLoadingPreview,
  isSent,
  isToolbarDisabled = false,
  previewSource = '',
  previewTitle = 'Title',
  previewType,
  scheduledUnixTime = null,
  scale = 1,
  maxHeight,
  children,
}: PreviewProps) {
  const [vh, setVh] = useState(window.innerHeight);
  const [height, setHeight] = useState(vh);
  const [isMobilePreview, setIsMobilePreview] = useState(false);
  const [isReloadingPreview, setReloadingPreview] = useState(false);
  const {
    isOpen: exportAnalyticsIsOpen,
    onOpen: exportAnalyticsOnOpen,
    onClose: exportAnalyticsonClose,
  } = useDisclosure();

  const iframeLoaded = useCallback(() => {
    const iFrameID = document.getElementById(
      'previewIframe'
    ) as HTMLIFrameElement;

    if (iFrameID) {
      const iFrameHeight =
        iFrameID?.contentWindow?.document?.body?.scrollHeight;
      setVh(window.innerHeight * scale);

      if (!iFrameHeight || iFrameHeight < vh) {
        setHeight(vh);
      } else {
        setHeight(iFrameHeight);
      }
    }
  }, [vh, scale]);

  useEffect(() => {
    iframeLoaded();
  }, [iframeLoaded, isMobilePreview]);

  function resize() {
    iframeLoaded();
  }

  useEffect(() => {
    setReloadingPreview(true);
    setTimeout(() => {
      setReloadingPreview(false);
      const iframe = document.getElementById(
        'previewIframe'
      ) as HTMLIFrameElement;
      iframe?.contentWindow?.location?.reload();
    }, 0);
  }, [previewType]);

  const { state } = useContext(StoreContext);

  const defaultSendPreviewEmail =
    authentication.impersonatingByStaffEmail() ??
    state.globalInfo.user?.emailAddress;

  const handleExport = () => {
    const exportName = `${campaignName} - ${
      scheduledUnixTime &&
      new Date(scheduledUnixTime * 1000).toLocaleDateString(
        i18n.getBrowserLocale() ?? 'en-GB'
      )
    } - Preview.html`;

    const exportLink = document.createElement('a');
    const exportURL = `data:application/octet-stream, ${encodeURIComponent(
      previewSource ?? ''
    )}`;
    exportLink.href = exportURL;
    exportLink.download = exportName;
    document.body.appendChild(exportLink);
    exportLink.click();
    document.body.removeChild(exportLink);
    window.URL.revokeObjectURL(exportURL);
  };

  window.onresize = resize;

  if (isReloadingPreview) {
    return null;
  }

  const iFrameDimensions = {
    height,
    ...(isMobilePreview && {
      width: CURATION_PAGE_MOBILE_PREVIEW_WIDTH,
    }),
  };

  const previewProperties = PREVIEW_TYPE_PROPERTIES[previewType];

  const sandboxProperties = `allow-same-origin allow-popups allow-popups-to-escape-sandbox ${
    previewProperties?.ASSET_TYPE === 'PAGE' ? 'allow-scripts' : ''
  }`;

  return (
    <Flex flexDir="column" gap={4} flexGrow={1}>
      {exportAnalyticsIsOpen && (
        <ExportEditionAnalyticsModal
          editionURN={editionURN}
          isOpen={exportAnalyticsIsOpen}
          onClose={exportAnalyticsonClose}
        />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        {children}
        <Flex gap={3}>
          {isSent && (
            <Button variant="secondary" onClick={exportAnalyticsOnOpen}>
              Export analytics
            </Button>
          )}
          {defaultSendPreviewEmail && previewProperties?.SENDABLE && (
            <SendPreview
              approvalState={approvalState}
              editionURN={editionURN}
              emailAddress={defaultSendPreviewEmail}
              isDisabled={isToolbarDisabled || !previewSource}
            />
          )}
          {previewProperties?.EXPORTABLE && (
            <ExportHTML handleExport={handleExport} />
          )}
          <PreviewDevices
            isMobilePreview={isMobilePreview}
            setIsMobilePreview={setIsMobilePreview}
            isToolbarDisabled={isToolbarDisabled}
          />
        </Flex>
      </Flex>
      {isLoadingPreview ? (
        <Flex flex="1 0 auto" alignItems="center" justifyContent="center">
          <Spinner size="lg" color="gray.500" />
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          bg="#F2F2F2"
          py={previewProperties?.ASSET_TYPE === 'EMAIL' ? 10 : 0}
          border="1px solid var(--chakra-colors-gray-300)"
          borderRadius="6px"
          overflowX="auto"
          maxH={maxHeight ?? 'unset'}
        >
          <iframe
            id="previewIframe"
            onLoad={iframeLoaded}
            loading="lazy"
            srcDoc={previewSource ?? ''}
            title={previewTitle}
            className={`preview__iframe second-row ${
              isMobilePreview ? 'm-auto' : 'desktop'
            }`}
            allowFullScreen
            {...iFrameDimensions}
            sandbox={sandboxProperties}
            style={{ transform: `scale(${scale})` }}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default Preview;
