import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Box, Flex, useTheme } from '@ebx-ui/ebx-ui-component-library-sdk';

import { ReactComponent as CampaignsIcon } from 'assets/svg/campaigns-icon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/profile.svg';
import { ReactComponent as PropertyIcon } from 'assets/svg/property.svg';
import { ReactComponent as TeamIcon } from 'assets/svg/team.svg';
import Sidebar from 'components/layout/Sidebar';
import SidebarError from 'components/layout/SidebarError';
import SidebarLink from 'components/settings/SidebarLink';

import * as campaigns from 'common/campaigns';
import { PROPERTY_PERMISSION_TYPES } from 'common/enums';
import * as properties from 'common/properties';
import * as users from 'common/users';

import useGlobalInfo from 'hooks/useGlobalInfo';

export interface SettingsProps {
  hideSidebar?: boolean;
  children: React.ReactNode;
}

function SettingsWrapper({ hideSidebar = false, children }: SettingsProps) {
  const globalInfo = useGlobalInfo();
  let showProperty = false;

  // Gets navbar styles defined in the component library theme
  const {
    components: { navbar },
  } = useTheme();

  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const campaign = campaigns.getCurrentCampaign(globalInfo);

  if (propertyURN) {
    const userPermissions = globalInfo?.user?.permissions ?? [];
    const propertyPermission = users.getPropertyPermission(
      propertyURN,
      userPermissions
    );
    if (
      (propertyPermission as PROPERTY_PERMISSION_TYPES) ===
      PROPERTY_PERMISSION_TYPES.ADMIN
    ) {
      showProperty = true;
    }
  }

  return (
    <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }} width="100%" height="auto">
      {!hideSidebar && (
        <ErrorBoundary FallbackComponent={SidebarError}>
          <Box w={{ base: 'full', md: 'xs' }} minWidth="xs">
            <Box
              w="inherit"
              position={{ md: 'fixed' }}
              top={navbar.height}
              bottom={0}
              left={0}
              style={{ zIndex: 10 }}
            >
              <Sidebar>
                {showProperty && (
                  <SidebarLink to="/settings/property">
                    <div className="d-flex align-items-center">
                      <PropertyIcon />
                      <span className="ml-2">Property</span>
                    </div>
                  </SidebarLink>
                )}
                {campaign && (
                  <SidebarLink to="/settings/campaigns">
                    <div className="d-flex align-items-center">
                      <CampaignsIcon />
                      <span className="ml-2">Campaigns</span>
                    </div>
                  </SidebarLink>
                )}
                {showProperty && (
                  <SidebarLink to="/settings/team">
                    <div className="d-flex align-items-center">
                      <TeamIcon />
                      <span className="ml-2">Team</span>
                    </div>
                  </SidebarLink>
                )}
                <SidebarLink to="/settings/profile">
                  <div className="d-flex align-items-center">
                    <ProfileIcon />
                    <span className="ml-2">Profile</span>
                  </div>
                </SidebarLink>
              </Sidebar>
            </Box>
          </Box>
        </ErrorBoundary>
      )}
      <Box py={hideSidebar ? 0 : 6} px={10} width="100%" lineHeight="inherit">
        {children}
      </Box>
    </Flex>
  );
}

export default SettingsWrapper;
