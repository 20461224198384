import GenericError from 'components/GenericError';

function PreviewError() {
  return (
    <div className="pt-3 px-2 py-4">
      <GenericError />
    </div>
  );
}

export default PreviewError;
