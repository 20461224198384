import {
  ArrowDownloadIcon,
  ArrowUploadIcon,
  Button,
  ChevronButton,
  Dropdown,
  Flex,
  Heading,
  SearchInput,
  useDisclosure,
  UserAddIcon,
  UserRemoveIcon,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddModal from 'components/campaigns/subscribers/add/AddManuallyModal';
import ExportModal from 'components/campaigns/subscribers/export/ExportModal';
import UnsubscribeModal from 'components/campaigns/subscribers/unsubscribe/UnsubscribeModal';
import UploadModal from 'components/campaigns/upload/Upload';

import * as API from 'api/api';
import * as campaigns from 'common/campaigns';
import * as i18n from 'common/i18n';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import useDebounce from 'hooks/useDebounce';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useLocalStorage from 'hooks/useLocalStorage';

import {
  DEFAULT_DEBOUNCE_DELAY,
  FRONTEND_UPLOAD_STATES,
  LOCAL_STATE_STORAGE_KEY,
} from 'common/constants';
import { SUBSCRIBER_MODAL_STATES } from 'common/enums';
import { SubscriberInsights } from 'common/types';

interface HeaderProps {
  campaignInsights: SubscriberInsights | null;
  searchString: string;
  setSearchString: (searchString: string) => void;
  handleSubscribersChanged?: () => void;
}

function Header({
  campaignInsights,
  searchString,
  setSearchString,
  handleSubscribersChanged,
}: HeaderProps) {
  const { id: campaignURN } = useParams<{ id: string }>();

  const [modalState, setModalState] = useState(SUBSCRIBER_MODAL_STATES.CLOSED);

  const [tempSearchString, setTempSearchString] = useState(searchString);
  const debounceSearchString = useDebounce(
    tempSearchString,
    DEFAULT_DEBOUNCE_DELAY
  );

  useEffect(() => {
    setSearchString(debounceSearchString);
  }, [setSearchString, debounceSearchString]);

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  const {
    isOpen: isOpenExportModal,
    onOpen: onOpenExportModal,
    onClose: onCloseExportModal,
  } = useDisclosure();
  const {
    isOpen: isOpenUnsubscribeModal,
    onOpen: onOpenUnsubscribeModal,
    onClose: onCloseUnsubscribeModal,
  } = useDisclosure();
  const {
    isOpen: isOpenUploadModal,
    onOpen: onOpenUploadModal,
    onClose: onCloseUploadModal,
  } = useDisclosure();

  const [localState, setLocalState] = useLocalStorage(
    LOCAL_STATE_STORAGE_KEY,
    null
  );

  const toast = useToast();

  const globalInfo = useGlobalInfo();
  const currentCampaign = campaigns.getCurrentCampaign(globalInfo);

  const numberOfSubscribers = campaignInsights?.activeSubscribers ?? 0;

  const handleError = (message: string) =>
    toast({ variant: 'error', title: message });

  const handleExport = async () => {
    try {
      logger.info('Header:handleSubscribersExport');
      tracker.track({
        eventName: 'Export Subscribers',
      });
      await API.postSubscribersExport({
        campaignURN,
      });
      onOpenExportModal();
    } catch (error) {
      toast({
        variant: 'error',
        title: 'There was a problem requesting your export file',
      });
    }
  };

  const AddDropdown = useCallback(
    () => (
      <Dropdown placement="bottom-end">
        <Dropdown.Button
          as={ChevronButton}
          variant="secondary"
          chevronColor="gray.600"
        >
          Add
        </Dropdown.Button>
        <Dropdown.List>
          <Dropdown.Group title="Add subscribers">
            <Dropdown.Item
              icon={<ArrowDownloadIcon size={5} />}
              onClick={() => {
                setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
                setModalState(SUBSCRIBER_MODAL_STATES.SUBSCRIBE);
                onOpenUploadModal();
              }}
            >
              Upload CSV
            </Dropdown.Item>
            <Dropdown.Item
              icon={<UserAddIcon size={5} />}
              onClick={onOpenAddModal}
            >
              Manually
            </Dropdown.Item>
          </Dropdown.Group>
        </Dropdown.List>
      </Dropdown>
    ),
    [onOpenAddModal, onOpenUploadModal, setLocalState]
  );

  const UnsubscribeDropdown = useCallback(
    () => (
      <Dropdown placement="bottom-end">
        <Dropdown.Button
          as={ChevronButton}
          variant="secondary"
          chevronColor="gray.600"
        >
          Unsubscribe
        </Dropdown.Button>
        <Dropdown.List>
          <Dropdown.Group title="Remove subscribers">
            <Dropdown.Item
              icon={<ArrowDownloadIcon size={5} />}
              onClick={() => {
                setLocalState(FRONTEND_UPLOAD_STATES.SELECTING);
                setModalState(SUBSCRIBER_MODAL_STATES.UNSUBSCRIBE);
                onOpenUploadModal();
              }}
            >
              Upload CSV
            </Dropdown.Item>
            <Dropdown.Item
              icon={<UserRemoveIcon size={5} />}
              onClick={onOpenUnsubscribeModal}
            >
              Manually
            </Dropdown.Item>
          </Dropdown.Group>
        </Dropdown.List>
      </Dropdown>
    ),
    [onOpenUnsubscribeModal, onOpenUploadModal, setLocalState]
  );

  const browserLocale: string = i18n.getBrowserLocale() ?? 'en-GB';

  return (
    <>
      <Flex direction="column">
        <Flex alignItems="center" gap={3} mb={8}>
          <Heading variant="h2">{currentCampaign.campaignName}</Heading>
          {campaignInsights && (
            <Heading variant="h5" color="gray.600">
              {campaignInsights?.activeSubscribers.toLocaleString(
                browserLocale
              )}{' '}
              subscriber
              {campaignInsights?.activeSubscribers !== 1 && 's'}
            </Heading>
          )}
        </Flex>
        <Flex alignItems="center">
          <SearchInput
            placeholder="Search subscribers by their email address"
            searchText={tempSearchString}
            onChange={setTempSearchString}
            maxWidth={400}
            mr={12}
            fontSize="14px"
          />
          <Flex gap={3}>
            <AddDropdown />
            <UnsubscribeDropdown />
            <Button
              variant="secondary"
              leftIcon={<ArrowUploadIcon color="gray.600" />}
              onClick={handleExport}
            >
              Export
            </Button>
          </Flex>
        </Flex>
      </Flex>
      {isOpenAddModal && (
        <AddModal
          campaignURN={campaignURN}
          hasActiveSubscribers={true}
          handleClose={onCloseAddModal}
          handleSuccess={handleSubscribersChanged}
        />
      )}
      {isOpenExportModal && <ExportModal handleClose={onCloseExportModal} />}
      {isOpenUnsubscribeModal && (
        <UnsubscribeModal
          campaignURN={campaignURN}
          isOpen={isOpenUnsubscribeModal}
          onClose={onCloseUnsubscribeModal}
          handleSuccess={handleSubscribersChanged}
        />
      )}
      {isOpenUploadModal && (
        <UploadModal
          campaignURN={campaignURN}
          handleClose={onCloseUploadModal}
          handleError={handleError}
          handleSuccess={handleSubscribersChanged}
          hasActiveSubscribers={numberOfSubscribers > 0}
          localState={localState}
          modalState={modalState}
          setLocalState={setLocalState}
        />
      )}
    </>
  );
}

export default Header;
