import {
  Box,
  Button,
  CloseIcon,
  Divider,
  Flex,
  Heading,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Modes from 'components/common/Modes';
import HeaderRight from 'components/editor/HeaderRight';
import SaveStatus from 'components/editor/marketing/SaveStatus';
import SendEmail from 'components/editor/marketing/SendEmail';
import TimingOptions from 'components/editor/marketing/TimingOptions';

import * as editions from 'common/editions';
import { EDITION_APPROVAL_STATES } from 'common/enums';

import 'components/editor/curate/Subheader.css';
import './EditorHeader.css';

function EditorHeader({
  campaignDetails,
  emailDetails,
  editionDisplayState,
  handleUpdateEmail,
  handleSaveEmail,
  isSaving,
  isDomainVerificationComplete,
}) {
  const canSend = editions.checkIsEditionEditable(editionDisplayState);

  return (
    <Navbar
      collapseOnSelect
      variant="light"
      expand="lg"
      id="subheader"
      className="navbar--height ebx-curation-subheader w-100"
    >
      <Nav className="d-flex flex-wrap flex-row align-items-center w-100 h-100">
        <Flex mr={4}>
          <Box mr={4}>
            <Link to="/campaigns">
              <Button variant="link">
                <CloseIcon size={5} color="gray.900" />
              </Button>
            </Link>
          </Box>
          <Divider height="20px" mt={0.5} mr={6} orientation="vertical" />
          <Heading variant="h3">{campaignDetails.campaignName}</Heading>
        </Flex>

        <Modes />

        <HeaderRight>
          <SaveStatus isSaving={isSaving} />
          <TimingOptions
            emailDetails={emailDetails}
            handleUpdateEmail={handleUpdateEmail}
          />
          <SendEmail
            canSend={canSend}
            isApproved={
              emailDetails.approvalState ===
              EDITION_APPROVAL_STATES.CLIENT_APPROVED
            }
            isSaving={isSaving}
            isDomainVerificationComplete={isDomainVerificationComplete}
            handleSaveEmail={handleSaveEmail}
          />
        </HeaderRight>
      </Nav>
    </Navbar>
  );
}

EditorHeader.propTypes = {
  campaignDetails: PropTypes.shape({
    campaignURN: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
  }).isRequired,
  emailDetails: PropTypes.shape({
    editionURN: PropTypes.string.isRequired,
    editionState: PropTypes.string.isRequired,
    approvalState: PropTypes.string.isRequired,
  }).isRequired,
  editionDisplayState: PropTypes.string.isRequired,
  handleUpdateEmail: PropTypes.func.isRequired,
  handleSaveEmail: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isDomainVerificationComplete: PropTypes.bool.isRequired,
};

EditorHeader.defaultProps = {
  isSaving: null,
};

export default EditorHeader;
