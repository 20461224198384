import PropTypes from 'prop-types';

import * as zendesk from 'common/zendesk';

const TABS = {
  IMPORT: '#import',
  EXPORT: '#export',
};

function ImportExportHelp({ activeTab, appName }) {
  const helpText =
    activeTab === TABS.IMPORT
      ? 'We are here to help! Please contact us if anything is unclear.'
      : "We are here to help you setup these pages. Feel free to contact us, we'll get back to you as soon as possible.";
  const zendeskText =
    activeTab === TABS.IMPORT
      ? `I want to import subscribers into Echobox from ${appName}`
      : `I want to export subscribers from Echobox to ${appName}`;

  const handleContactUs = () => {
    zendesk.openWidget();
    zendesk.prefillWidgetDescription(zendeskText);
  };

  return (
    <div className="ebx-help-box">
      <div className="ebx-help-box-title">Looking for help?</div>
      <div className="ebx-help-box-description">{helpText}</div>
      <button
        className="btn ebx-btn-secondary ebx-help-box-button"
        type="button"
        onClick={handleContactUs}
      >
        Contact us
      </button>
    </div>
  );
}

ImportExportHelp.propTypes = {
  activeTab: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
};

export default ImportExportHelp;
