import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import { API_TIMEOUTS, getRequestHeaders } from 'api/settings';
import { PREVIEW_TYPE_PROPERTIES } from 'common/constants';

export default async function getPreview(
  previewDetails,
  previewURL,
  previewType
) {
  const guid = metrics.start('getPreview');

  const assetType = PREVIEW_TYPE_PROPERTIES[previewType].ASSET_TYPE;

  logger.info(`API:getPreview ${previewURL}`);

  try {
    const response = await axios(
      getPreviewConfig({ assetType, previewURL, previewDetails })
    );
    metrics.end('getPreview', guid);
    return response.data;
  } catch (error) {
    metrics.fail('getPreview', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPreview',
      args: { previewDetails },
    });
    throw apiError;
  }
}

function getPreviewConfig({ assetType, previewURL, previewDetails }) {
  switch (assetType) {
    case 'EMAIL':
      return {
        url: previewURL,
        method: 'GET',
        timeout: API_TIMEOUTS.S,
        headers: getRequestHeaders(),
      };
    case 'PAGE':
      return {
        url: previewURL,
        method: 'POST',
        timeout: API_TIMEOUTS.S,
        headers: getRequestHeaders({
          'Content-Type': 'application/json',
        }),
        data: JSON.stringify(previewDetails),
      };
    default:
      throw new Error(`Unable to get config: Unknown asset type ${assetType}`);
  }
}
