import PropTypes from 'prop-types';

import BodyElement from 'components/settings/campaigns/content/BodyElement';

import { ReactComponent as DragHandle } from 'assets/svg/drag-handle.svg';

import './Campaign.css';

function Campaign({ campaignURN, campaignName }) {
  return (
    <BodyElement className="campaign-sequence-element">
      <div className="d-flex align-items-center">
        <div className="drop-handle mr-2">
          <DragHandle />
        </div>
        <div className="ebx-h2 ebx-text-overflow-ellipsis ebx-whitescape-nowrap ebx-title-colour">
          {campaignName}
        </div>
      </div>
    </BodyElement>
  );
}
Campaign.propTypes = {
  campaignURN: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
};

export default Campaign;
