/* eslint react-hooks/exhaustive-deps: "off" */

import {
  Flex,
  FormControl,
  Heading,
  Input,
  Tag,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { zodResolver } from '@hookform/resolvers/zod';
import isEqual from 'fast-deep-equal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import * as authentication from 'common/authentication';
import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';
import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';
import useStaffUser from 'hooks/useStaffUser';

import { FORM_VALIDATION_MODES } from 'common/enums';

function Address() {
  const globalInfo = useGlobalInfo();
  const property = properties.getCurrentProperty(globalInfo);
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);

  const AddressSchema = z.object({
    addressLine1: z
      .string()
      .trim()
      .min(1, { message: 'Address line 1 is required' }),
    addressLine2: z.string().trim().optional(),
    city: z.string().trim().min(1, { message: 'City is required' }),
    country: z.string().trim().min(1, { message: 'Country is required' }),
    postcode: z
      .string()
      .trim()
      .min(1, { message: 'ZIP / postal code is required' }),
    phoneNumber: z
      .string()
      .trim()
      .min(1, { message: 'Phone number is required' }),
  });
  type AddressType = z.infer<typeof AddressSchema>;

  const [defaultValues, setDefaultValues] = useState<AddressType>({
    addressLine1: property.companyDetails?.addressLine1,
    addressLine2: property.companyDetails?.addressLine2,
    city: property.companyDetails?.city,
    country: property.companyDetails?.country,
    postcode: property.companyDetails?.postcode,
    phoneNumber: property.companyDetails?.phoneNumber,
  });
  const initialValues = useRef(utility.cloneObject(defaultValues));
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    resolver: zodResolver(AddressSchema),
    mode: FORM_VALIDATION_MODES.ONSUBMIT,
    shouldUnregister: true,
    defaultValues,
  });

  const isStaffUser = useStaffUser();
  const isOrIsImpersonatingStaffUser =
    isStaffUser &&
    (!authentication.isImpersonating() ||
      authentication.isImpersonatingStaffUser());
  const toast = useToast();
  const settings = useSettings();

  const setChanged = useCallback(() => {
    if (!isEqual(defaultValues, initialValues.current)) {
      settings.setChanged();
    } else {
      settings.setUnchanged();
    }
  }, [defaultValues, settings, initialValues.current]);

  useEffect(() => {
    settings.setOnReload(() => {
      settings.setDone();
    });
    const handleSave = async (updatedValues: any) => {
      const addressNow = () =>
        addressFormat([
          updatedValues.addressLine1,
          updatedValues.addressLine2,
          updatedValues.city,
          updatedValues.country,
          updatedValues.postcode,
        ]);

      const addressBefore = () =>
        addressFormat([
          initialValues.current.addressLine1,
          initialValues.current.addressLine2,
          initialValues.current.city,
          initialValues.current.country,
          initialValues.current.postcode,
        ]);

      const addressFormat = (addressLines: string[]) =>
        addressLines
          .filter(addressLine => addressLine && addressLine !== '')
          .join(', ');

      logger.info('Address:handleSave');
      try {
        await settings.savePropertyDetails({
          propertyURN,
          isStaffUser: isOrIsImpersonatingStaffUser,
          companyDetails: {
            companyName:
              property.companyDetails?.companyName &&
              property.companyDetails?.companyName !== ''
                ? property.companyDetails?.companyName
                : property.propertyName,
            addressLine1: updatedValues.addressLine1,
            addressLine2: updatedValues.addressLine2,
            city: updatedValues.city,
            country: updatedValues.country,
            phoneNumber: updatedValues.phoneNumber,
            postcode: updatedValues.postcode,
          },
        });
        tracker.track({
          eventName: 'Edit Business Address',
          trackingParams: {
            'Business Address': addressNow(),
            'Business Address before': addressBefore(),
          },
          options: {
            includeCampaignDetails: false,
          },
        });
        toast({ variant: 'success', title: 'Changes successfully saved' });
        settings.requestGlobalInfo();
        setDefaultValues(utility.cloneObject(updatedValues));
        initialValues.current = utility.cloneObject(updatedValues); // NL-111
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        toast({
          variant: 'error',
          title:
            errorMessage ??
            'An unidentified problem occurred while saving changes',
        });
        settings.setChanged();
      }
    };
    settings.setOnSave(handleSubmit(handleSave));
  }, [property.companyDetails, propertyURN, settings]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    defaultValues[event.target.name as keyof AddressType] = event.target.value;
    setChanged();
  };

  return (
    <>
      <Heading variant="h2">Business address</Heading>

      <Text size="md" pt={2} pb={6}>
        This address will be displayed in the footer of your Editions
      </Text>

      <FormControl mb={4} isInvalid={!!formErrors.addressLine1}>
        <FormControl.FormLabel htmlFor="addressLine1">
          Address line 1
        </FormControl.FormLabel>
        <Input
          type="text"
          id="addressLine1"
          maxLength={120}
          {...register('addressLine1')}
          onChange={handleChange}
        />
        <FormControl.FormErrorMessage>
          {String(formErrors?.addressLine1?.message)}
        </FormControl.FormErrorMessage>
      </FormControl>

      <FormControl mb={4} isInvalid={!!formErrors.addressLine2}>
        <FormControl.FormLabel htmlFor="addressLine2">
          <Flex alignItems="center" gap={2}>
            Address line 2<Tag color="gray">Optional</Tag>
          </Flex>
        </FormControl.FormLabel>
        <Input
          type="text"
          id="addressLine2"
          maxLength={120}
          {...register('addressLine2')}
          onChange={handleChange}
        />
        <FormControl.FormErrorMessage>
          {String(formErrors?.addressLine2?.message)}
        </FormControl.FormErrorMessage>
      </FormControl>

      <FormControl mb={4} isInvalid={!!formErrors.city}>
        <FormControl.FormLabel htmlFor="city">City</FormControl.FormLabel>
        <Input
          type="text"
          id="city"
          maxLength={120}
          {...register('city')}
          onChange={handleChange}
        />
        <FormControl.FormErrorMessage>
          {String(formErrors?.city?.message)}
        </FormControl.FormErrorMessage>
      </FormControl>

      <FormControl mb={4} isInvalid={!!formErrors.country}>
        <FormControl.FormLabel htmlFor="country">Country</FormControl.FormLabel>
        <Input
          type="text"
          id="country"
          maxLength={120}
          {...register('country')}
          onChange={handleChange}
        />
        <FormControl.FormErrorMessage>
          {String(formErrors?.country?.message)}
        </FormControl.FormErrorMessage>
      </FormControl>

      <FormControl mb={4} isInvalid={!!formErrors.postcode}>
        <FormControl.FormLabel htmlFor="postcode">
          ZIP / postal code
        </FormControl.FormLabel>
        <Input
          type="text"
          id="postcode"
          maxLength={120}
          {...register('postcode')}
          onChange={handleChange}
        />
        <FormControl.FormErrorMessage>
          {String(formErrors?.postcode?.message)}
        </FormControl.FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formErrors.phoneNumber}>
        <FormControl.FormLabel htmlFor="phoneNumber">
          Phone number
        </FormControl.FormLabel>
        <Input
          type="text"
          id="phoneNumber"
          maxLength={120}
          {...register('phoneNumber')}
          onChange={handleChange}
        />
        <FormControl.FormErrorMessage>
          {String(formErrors?.phoneNumber?.message)}
        </FormControl.FormErrorMessage>
      </FormControl>
    </>
  );
}

export default Address;
