import { Alert, AlertProps } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as datetime from 'common/datetime';
import { DateObject, PropertyBilling } from 'common/types';

export interface propertySuspensionBannerProps {
  propertyBilling: PropertyBilling;
}

const getDisplayTime = (shutOffUnixTime: number) => {
  const { hourZero, minuteZero, day, monthName, year } =
    datetime.getDateFromUnix(shutOffUnixTime) as DateObject;
  const { year: currentYear } = datetime.getDateFromUnix(
    datetime.getUnixTimestamp()
  ) as DateObject;

  return `${hourZero}:${minuteZero} on ${day} ${monthName}${
    year === currentYear ? '' : ` ${year}`
  }`;
};

type BannerConfigOption = {
  variant: AlertProps['variant'];
  title: (propertyBilling: PropertyBilling) => string;
  description: (propertyBilling: PropertyBilling) => string;
};

const bannerConfigOptions: Record<
  'pendingShutOff' | 'shutOff',
  BannerConfigOption
> = {
  pendingShutOff: {
    variant: 'warning',
    title: ({ invoices }: PropertyBilling) =>
      `Payment overdue for invoice(s) ${invoices}`,
    description: ({ invoices, shutOffUnixTime }: PropertyBilling) =>
      `Please make the payment for the overdue invoice(s) ${invoices}. If not made before ${getDisplayTime(
        shutOffUnixTime
      )} then this account will be suspended. If you have already paid, contact finance@echobox.com immediately.`,
  },
  shutOff: {
    variant: 'error',
    title: () => 'Account suspended due to overdue payment',
    description: ({ invoices }: PropertyBilling) =>
      `This account has been suspended for non-payment of invoice(s) ${invoices}. 
      Echobox will not send any further emails. 
      To access your account, please arrange payment immediately 
      and contact finance@echobox.com with a confirmation of payment.`,
  },
};

function PropertySuspensionBanner({
  propertyBilling,
}: propertySuspensionBannerProps) {
  const { variant, title, description } =
    datetime.getUnixTimestamp() < propertyBilling.shutOffUnixTime
      ? bannerConfigOptions.pendingShutOff
      : bannerConfigOptions.shutOff;
  return (
    <Alert variant={variant} gap="3">
      <Alert.AlertTitle>{title(propertyBilling)}</Alert.AlertTitle>
      <Alert.AlertDescription>
        {description(propertyBilling)}
      </Alert.AlertDescription>
    </Alert>
  );
}

export default PropertySuspensionBanner;
