/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';

import { Container, Title } from 'components/settings/campaigns/common';
import Loading from 'components/settings/Loading';

import useGlobalInfo from 'hooks/useGlobalInfo';
import useSettings from 'hooks/useSettings';

import * as errors from 'common/errors';
import * as logger from 'common/logger';
import * as properties from 'common/properties';

import { PROPERTY_SETTING_TYPES } from 'common/constants';

function Header() {
  const globalInfo = useGlobalInfo();
  const propertyURN = properties.getCurrentPropertyURN(globalInfo);
  const settings = useSettings();
  const toast = useToast();

  const descriptionCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'descriptionCopy'
  );
  const titleCopy = settings.getPropertySettingValue(
    PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
    'titleCopy'
  );

  useEffect(() => {
    settings.getPropertySettings(propertyURN);
  }, [propertyURN]);

  useEffect(() => {
    const handleSave = async () => {
      logger.info('AllCampaigns-Branding-General-Header:handleSave');

      try {
        await settings.savePropertySettings({
          propertyURN,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, propertyURN]);

  const handleDescriptionChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'descriptionCopy',
      settingValue: event.target.value,
    });
  };

  const handleTitleChange = event => {
    settings.setPropertySettingValue({
      settingType: PROPERTY_SETTING_TYPES.MULTI_CAMPAIGN_HOSTED_SUBS_PAGE,
      settingKey: 'titleCopy',
      settingValue: event.target.value,
    });
  };

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Container>
      <Title className="mb-3">
        <span className="mr-3">Header</span>
      </Title>
      <div className="mb-4">
        <FormLabel className="ebx-h3 mb-1">Title</FormLabel>
        <FormControl
          name="titleCopy"
          id="titleCopy"
          required
          className="ebx-input ebx-body-1"
          type="text"
          value={titleCopy}
          onChange={handleTitleChange}
          autoComplete="off"
        />
      </div>
      <div className="mb-4">
        <FormLabel className="ebx-h3 mb-1">Subtitle</FormLabel>
        <FormControl
          name="verificationTitleCopy"
          id="verificationTitleCopy"
          required
          className="ebx-input ebx-body-1"
          type="text"
          value={descriptionCopy}
          onChange={handleDescriptionChange}
          autoComplete="off"
        />
      </div>
    </Container>
  );
}

export default Header;
