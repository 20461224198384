import { URN_TYPES } from 'common/constants';
import * as logger from 'common/logger';
import type {
  BodyElementPositioning as BodyElementPositioningType,
  BodyEntities,
  CampaignSection as SectionType,
  PromotionBlock as PromotionBlockType,
  TextBlock as TextBlockType,
} from 'common/types';
import { getURNType } from 'common/urns';
import { useState } from 'react';

const orderElementsBasedOnPositioning = (
  sections: SectionType[],
  promotionBlocks: PromotionBlockType[],
  textBlocks: TextBlockType[],
  bodyElementPositioning: BodyElementPositioningType[]
) => {
  const orderedSections: SectionType[] = [];
  const sectionsMap = new Map();
  for (const section of sections) {
    sectionsMap.set(section.sectionURN, section);
  }

  const orderedPromotionBlocks: PromotionBlockType[] = [];
  const promotionsMap = new Map();
  for (const promotion of promotionBlocks) {
    promotionsMap.set(promotion.promotionBlockURN, promotion);
  }

  const orderedTextBlocks: TextBlockType[] = [];
  const textsMap = new Map();
  for (const text of textBlocks) {
    textsMap.set(text.textBlockURN, text);
  }

  const bodyEntities: BodyEntities[] = [];
  for (const position of bodyElementPositioning) {
    const urn = position.elementURN;
    // Add the right entity based on the URN type
    switch (getURNType(urn)) {
      case URN_TYPES.SECTION: {
        const currentSection = sectionsMap.get(urn);

        if (currentSection) {
          orderedSections.push(currentSection);
          bodyEntities.push(currentSection);
        } else {
          // Couldn't find the matching section
          logger.info(
            `Could not find the section matching position URN ${urn}`
          );
        }

        break;
      }
      case URN_TYPES.PROMOTION_BLOCK: {
        const currentPromotionBlock = promotionsMap.get(urn);

        if (currentPromotionBlock) {
          orderedPromotionBlocks.push(currentPromotionBlock);
          bodyEntities.push(currentPromotionBlock);
        } else {
          // Couldn't find the matching promotion block
          logger.info(
            `Could not find the promotion block matching position URN ${urn}`
          );
        }

        break;
      }
      case URN_TYPES.TEXT_BLOCK: {
        const currentTextBlock = textsMap.get(urn);

        if (currentTextBlock) {
          orderedTextBlocks.push(currentTextBlock);
          bodyEntities.push(currentTextBlock);
        } else {
          // Couldn't find the matching text block
          logger.info(
            `Could not find the text block matching position URN ${urn}`
          );
        }

        break;
      }
      default:
        logger.error(`Unrecognised URN ${urn} could not be processed`);
    }
  }
  return {
    orderedSections,
    orderedPromotionBlocks,
    orderedTextBlocks,
    bodyEntities,
  };
};

const useBodyEntities = (
  sections: SectionType[],
  promotionBlocks: PromotionBlockType[],
  textBlocks: TextBlockType[],
  bodyElementPositioning: BodyElementPositioningType[]
) => {
  const orderedElements = orderElementsBasedOnPositioning(
    sections,
    promotionBlocks,
    textBlocks,
    bodyElementPositioning
  );

  const [globalSections, setSections] = useState<SectionType[]>(
    orderedElements.orderedSections
  );
  const [globalPromotionBlocks, setPromotionBlocks] = useState<
    PromotionBlockType[]
  >(orderedElements.orderedPromotionBlocks);
  const [globalTextBlocks, setTextBlocks] = useState<TextBlockType[]>(
    orderedElements.orderedTextBlocks
  );
  const [globalBodyElementPositioning, setBodyElementPositioning] = useState<
    BodyElementPositioningType[]
  >(bodyElementPositioning);
  const [bodyEntities, setState] = useState<ReadonlyArray<BodyEntities>>(
    orderedElements.bodyEntities
  );

  const setBodyEntities = (
    newSections: SectionType[],
    newPromotionBlocks: PromotionBlockType[],
    newTextBlocks: TextBlockType[],
    newBodyElementPositioning: BodyElementPositioningType[]
  ) => {
    const newOrderedElements = orderElementsBasedOnPositioning(
      newSections,
      newPromotionBlocks,
      newTextBlocks,
      newBodyElementPositioning
    );

    setSections(newOrderedElements.orderedSections);
    setPromotionBlocks(newOrderedElements.orderedPromotionBlocks);
    setTextBlocks(newOrderedElements.orderedTextBlocks);
    setBodyElementPositioning(newBodyElementPositioning);
    setState(newOrderedElements.bodyEntities);
  };

  return {
    globalSections,
    globalPromotionBlocks,
    globalTextBlocks,
    globalBodyElementPositioning,
    bodyEntities,
    setBodyEntities,
  };
};

export default useBodyEntities;
