import {
  Button,
  ButtonGroup,
  ButtonProps,
  DesktopIcon,
  PhoneIcon,
  useToken,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface PreviewDevicesProps {
  isMobilePreview: boolean;
  setIsMobilePreview: (value: boolean) => void;
  isToolbarDisabled: boolean;
}

function PreviewDevices({
  isMobilePreview,
  setIsMobilePreview,
  isToolbarDisabled,
}: PreviewDevicesProps) {
  const [buttonDropShadow] = useToken('shadows', ['button.dropShadow']);

  const getDeviceButtonProps = (
    mobilePreviewProps: boolean,
    setMobile: boolean
  ) =>
    ({
      variant: 'secondary',
      p: 2,
      minWidth: 'none',
      boxShadow: 'none',
      filter: 'none',
      disabled: isToolbarDisabled,
      bg: mobilePreviewProps ? 'primary.100' : 'base',
      color: mobilePreviewProps ? 'primary.600' : 'gray.900',
      _hover: {
        bg: mobilePreviewProps ? 'primary.100' : 'base',
      },
      _active: {
        bg: mobilePreviewProps ? 'primary.100' : 'base',
      },
      _focus: {
        _before: { boxShadow: 'none' },
      },
      onClick: () => setIsMobilePreview(setMobile),
    } as ButtonProps);

  const phoneProps = getDeviceButtonProps(isMobilePreview, true);
  const desktopProps = getDeviceButtonProps(!isMobilePreview, false);

  return (
    <ButtonGroup
      spacing={0}
      p={0.5}
      bg="base"
      boxShadow="border"
      borderRadius="md"
      alignItems="center"
      filter={`drop-shadow(${buttonDropShadow})`}
    >
      <Button {...phoneProps}>
        <PhoneIcon />
      </Button>
      <Button {...desktopProps}>
        <DesktopIcon />
      </Button>
    </ButtonGroup>
  );
}

export default PreviewDevices;
