/* eslint react/prop-types: "off" */

import * as blocks from 'common/blocks';
import useDeveloperMode from 'hooks/useDeveloperMode';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

export interface AddElementToggleProps {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const AddElementToggle = React.forwardRef<
  HTMLButtonElement,
  AddElementToggleProps
>(({ children, onClick }: AddElementToggleProps, ref) => (
  <Button
    variant="link"
    className="ebx-h3 ebx-link ebx-cursor add-body-element p-0 border-0 mt-3"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

AddElementToggle.defaultProps = {
  children: null,
};

function CustomBlockList() {
  const blockList = blocks.getBlockList();

  return (
    <>
      {blockList.map(block => (
        <Dropdown.Item
          className="ebx-h3 ebx-body-colour py-2"
          eventKey={block.blockURN}
          key={block.blockURN}
        >
          {block.title}
        </Dropdown.Item>
      ))}
    </>
  );
}

interface BodyAddElementProps {
  onNewElement: (type: string | null) => void;
}

function BodyAddElement({ onNewElement }: BodyAddElementProps) {
  const isDeveloperModeActive = useDeveloperMode('NL-4427'); // Template Editor - New Blocks

  return (
    <Dropdown onSelect={onNewElement}>
      <Dropdown.Toggle as={AddElementToggle} id="dropdown-custom-components">
        + Add
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          className="ebx-h3 ebx-body-colour py-2"
          eventKey="advertising"
        >
          Advertising
        </Dropdown.Item>
        <Dropdown.Item
          className="ebx-h3 ebx-body-colour py-2"
          eventKey="section"
        >
          Articles
        </Dropdown.Item>
        <Dropdown.Item
          className="ebx-h3 ebx-body-colour py-2"
          eventKey="sponsored"
        >
          Articles (sponsored)
        </Dropdown.Item>
        <Dropdown.Item className="ebx-h3 ebx-body-colour py-2" eventKey="image">
          Image Block
        </Dropdown.Item>
        <Dropdown.Item
          className="ebx-h3 ebx-body-colour py-2"
          eventKey="textBlock"
        >
          Text Block
        </Dropdown.Item>
        {isDeveloperModeActive && <CustomBlockList />}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default BodyAddElement;
