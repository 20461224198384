import PropTypes from 'prop-types';

function SaveStatus({ isSaving }) {
  if (isSaving === null) {
    return null;
  }
  const saveMessage = isSaving ? 'Saving changes...' : 'Changes saved';
  return <div className="editor-header-save-status mr-2">{saveMessage}</div>;
}

SaveStatus.propTypes = {
  isSaving: PropTypes.bool,
};

SaveStatus.defaultProps = {
  isSaving: null,
};

export default SaveStatus;
