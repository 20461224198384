import { Hub } from 'aws-amplify';

import * as API from 'api/api';
import { mandatory } from 'common/validation';

import {
  COGNITO_CHALLENGE_NAMES,
  HUB_CHANNELS,
  HUB_EVENTS,
} from 'common/constants';
import postSignIn from 'services/postSignIn';

export default async function signIn({
  username = mandatory('username'),
  password = mandatory('password'),
} = {}) {
  /* Perform signin */
  const user = await API.authSignIn({
    username: username.trim(),
    password,
  });

  /**
   * If MFA is enabled, fire off a message that will be picked up
   * in the sign in page and cause a redirect to the verify MFA page
   */

  if (user.challengeName === COGNITO_CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA) {
    Hub.dispatch(HUB_CHANNELS.AUTHENTICATION, {
      event: HUB_EVENTS.AUTHENTICATION.MFA,
      user,
    });
    return;
  }

  /* User must reset password before signing in */
  if (user.challengeName === COGNITO_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
    /* User must set new password */
    Hub.dispatch(HUB_CHANNELS.AUTHENTICATION, {
      event: HUB_EVENTS.AUTHENTICATION.NEW_PASSWORD,
    });
    return;
  }
  postSignIn(user);
}
