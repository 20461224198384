import { Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import {
  Box,
  Button as ButtonNEW,
  CloseIcon,
  Divider,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Modes from 'components/common/Modes';
import CampaignLinks from 'components/settings/header/CampaignLinks';
import HeaderLink from 'components/settings/header/HeaderLink';
import ProfileLinks from 'components/settings/header/ProfileLinks';
import PropertyLinks from 'components/settings/header/PropertyLinks';
import TeamLinks from 'components/settings/header/TeamLinks';

import * as logger from 'common/logger';

import useSettings from 'hooks/useSettings';

import { HUB_CHANNELS, HUB_EVENTS } from 'common/constants';

import './Header.css';

const BUTTON_STATES = {
  STATUS_CHANGED: 'STATUS_CHANGED',
  STATUS_ERROR: 'STATUS_ERROR',
  STATUS_SAVING: 'STATUS_SAVING',
  STATUS_UNCHANGED: 'STATUS_UNCHANGED',
};

const BUTTON_LABELS = {
  [BUTTON_STATES.STATUS_CHANGED]: 'Save changes',
  [BUTTON_STATES.STATUS_ERROR]: 'Save changes',
  [BUTTON_STATES.STATUS_SAVING]: 'Saving...',
  [BUTTON_STATES.STATUS_UNCHANGED]: 'Done',
};

function Header() {
  const [buttonState, setButtonState] = useState(
    BUTTON_STATES.STATUS_UNCHANGED
  );

  const settings = useSettings({ storeStateInHook: false });

  const handleSettingsMessage = (data: {
    payload: {
      event: string;
      message?: string;
    };
  }) => {
    const { payload } = data;
    // TODO - this could be more elegant, maybe events needs to be split into
    //        request and response types, and we then only need to listen to
    //        response events (or more accurately, listen to everything on
    //        the relevant event channel but ignore anything that isn't a
    //        response event?)
    if (payload.event !== HUB_EVENTS.SETTINGS.REQUEST_SAVE) {
      logger.info(`Header:handleSettingsMessage ${payload.event}`);
      setButtonState(payload.event);
      if (payload?.message) {
        // TODO - if the event is an error then payload.message will contain
        //        the error message, so this should presumably be rendered
        //        somewhere...
        logger.info(`Header:handleSettingsMessage ${payload.message}`);
      }
    }
  };

  useEffect(() => {
    Hub.listen(HUB_CHANNELS.SETTINGS, handleSettingsMessage);

    return () => {
      Hub.remove(HUB_CHANNELS.SETTINGS, handleSettingsMessage);
    };
  });

  const history = useHistory();

  const handleSaveDone = () => {
    if (buttonState === BUTTON_STATES.STATUS_UNCHANGED) {
      logger.info('Header:handleDone');
      history.push('/');
    } else {
      logger.info('Header:handleSave');
      setButtonState(BUTTON_STATES.STATUS_SAVING);
      settings.requestSave();
    }
  };

  const isDisabled =
    buttonState === BUTTON_STATES.STATUS_SAVING ||
    buttonState === BUTTON_STATES.STATUS_ERROR;
  const buttonLabel = BUTTON_LABELS[buttonState];

  return (
    <Navbar
      collapseOnSelect
      variant="light"
      expand="lg"
      className="ebx-header ebx-background-colour w-100 settings-header"
    >
      <Nav className="d-flex flex-wrap flex-row align-items-center w-100 h-100">
        <Box mx={4}>
          <Link to="/campaigns" className="d-flex">
            <ButtonNEW variant="link">
              <CloseIcon size={5} color="gray.900" />
            </ButtonNEW>
          </Link>
        </Box>
        <Divider height="20px" orientation="vertical" />
        <Navbar.Collapse>
          <div className="d-flex align-items-center h-100 flex-wrap">
            <HeaderLink to="/settings">
              <span className="ebx-h1">Settings</span>
            </HeaderLink>
            <PropertyLinks />
            <CampaignLinks />
            <TeamLinks />
            <ProfileLinks />
          </div>
        </Navbar.Collapse>
        <span className="ml-auto d-flex align-items-center">
          <div className="ml-2">
            <Modes />
          </div>
          <Button
            className={`ebx-btn-${
              buttonState === BUTTON_STATES.STATUS_UNCHANGED
                ? 'secondary'
                : 'primary'
            } ebx-btn-md ebx-h3 ml-2 settings-button`}
            onClick={handleSaveDone}
            disabled={isDisabled}
          >
            {buttonLabel}
          </Button>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ml-2 sidebar__toggle"
          />
        </span>
      </Nav>
    </Navbar>
  );
}

export default Header;
