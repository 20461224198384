import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { WEEKDAYS } from 'common/constants';

import './FrequencyWeekly.css';

function Checkbox(props) {
  return <Form.Check inline name="frequency" type="checkbox" {...props} />;
}

function FrequencyWeekly({ selectedDays, onDailyChange, errorMessage }) {
  const handleChange = e => {
    const toggledDay = e.target.value;
    if (selectedDays.includes(toggledDay)) {
      onDailyChange(selectedDays.filter(day => day !== toggledDay));
    } else {
      onDailyChange([...selectedDays, toggledDay]);
    }
  };

  return (
    <>
      {WEEKDAYS.map(day => (
        <Checkbox
          checked={selectedDays.includes(day)}
          key={day}
          label={day}
          value={day}
          id={day}
          className="frequency-checkbox mb-2"
          onChange={handleChange}
        />
      ))}
      <div className="ebx-error-colour ebx-h3">{errorMessage}</div>
    </>
  );
}

FrequencyWeekly.propTypes = {
  selectedDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDailyChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

FrequencyWeekly.defaultProps = {
  errorMessage: '',
};

export default FrequencyWeekly;
