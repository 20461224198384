import { Box, Image, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

interface PreviewTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  preview: string;
}

function PreviewTemplate({
  isOpen,
  onClose,
  name,
  preview,
}: PreviewTemplateProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="large">
      <Modal.Header>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        py={8}
        bg="#F7F7F7"
        borderRadius="md"
        display="flex"
        justifyContent="center"
      >
        <Image
          w="588px"
          h="100%"
          src={preview}
          fallback={<Box h="800px" w="588px" bg="white" />}
          alt={`${name} preview`}
          fit="cover"
        />
      </Modal.Body>
    </Modal>
  );
}

export default PreviewTemplate;
