import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

interface getMarketingTemplateListArgs {
  campaignURN: string;
}

export default async function getMarketingTemplateList({
  campaignURN,
}: getMarketingTemplateListArgs) {
  const guid = metrics.start('getMarketingTemplateList');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/marketingtemplates`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(
    `API:getMarketingTemplatesList /campaigns/${campaignURN}/marketingtemplates`
  );

  try {
    const response = await axios(config);
    metrics.end('getMarketingTemplateList', guid);
    return response.data.marketingTemplates;
  } catch (error) {
    metrics.fail('getMarketingTemplateList', guid);
    const apiError =
      error instanceof Error
        ? await handleAPIError({
            originalError: error,
            errorLocation: 'api/getMarketingTemplateList',
            args: { campaignURN },
          })
        : error;
    throw apiError;
  }
}
