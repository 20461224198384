import { Box, Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import ColorPicker from 'components/editor/blocks/attributes/ColorPicker';
import NumberPicker from 'components/editor/blocks/attributes/NumberPicker';
import SelectPicker from 'components/editor/blocks/attributes/SelectPicker';

import * as blocks from 'common/blocks';

interface DividerBorderProps {
  onChange: (key: string, value: any) => void;
  value: {
    color: string;
    style: string;
    width: string;
  };
}

function DividerBorder({ onChange, value }: DividerBorderProps) {
  if (!value) return null;

  const handleChangeColor = (name: string, color: string) => {
    onChange('borderColor', color);
  };

  const handleChangeStyle = (name: string, style: string) => {
    onChange('borderStyle', style);
  };

  const handleChangeWidth = (name: string, width: number) => {
    onChange('borderWidth', `${width}px`);
  };

  return (
    <Flex direction="column" gap={4}>
      <Heading variant="h4">Border</Heading>
      <Flex direction="column" gap={4}>
        <Flex direction="row" alignItems="center">
          <Box w="50%">Color</Box>
          <Box w="50%">
            <ColorPicker
              name="border-color"
              onChange={handleChangeColor}
              value={value.color}
            />
          </Box>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box w="50%">Style</Box>
          <Box w="50%">
            <SelectPicker
              name="style"
              onChange={handleChangeStyle}
              // @ts-ignore
              options={blocks.WYSIWYG_LINE_STYLES}
              value={value.style}
            />
          </Box>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box w="50%">Width</Box>
          <Box w="50%">
            <NumberPicker
              max={10}
              min={0}
              name="border-width"
              // @ts-ignore
              onChange={handleChangeWidth}
              unit="px"
              // @ts-ignore
              value={value.width}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DividerBorder;
