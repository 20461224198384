import { useState } from 'react';

import { Flex, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import FooterCheckbox from 'components/settings/campaigns/content/FooterCheckbox';
import FooterFreeTextInput from 'components/settings/campaigns/content/FooterFreeTextInput';
import FooterPrivacyPolicyInput from 'components/settings/campaigns/content/FooterPrivacyPolicyInput';
import FooterSocial from 'components/settings/campaigns/content/FooterSocial';

import { CAMPAIGN_SETTING_TYPES, SOCIAL_LINKS } from 'common/constants';
import {
  FixTypeLater,
  FooterTextSettings,
  PrivacyPolicySettings,
  SocialLinksSettings,
  SocialPageLinksSettings,
} from 'common/types';
import { addOrReplaceItem } from 'common/utility';

interface SocialPageLinkSettingsObject {
  facebookSettings?: SocialPageLinksSettings;
  instagramSettings?: SocialPageLinksSettings;
  twitterSettings?: SocialPageLinksSettings;
  linkedInSettings?: SocialPageLinksSettings;
  pinterestSettings?: SocialPageLinksSettings;
  youtubeSettings?: SocialPageLinksSettings;
  tiktokSettings?: SocialPageLinksSettings;
}

interface FooterProps {
  settings: FixTypeLater;
  socialLinksSettings: SocialLinksSettings | null;
  privacyPolicySettings: PrivacyPolicySettings | null;
  footerTextSettings: FooterTextSettings | null;
  isEditionSettings?: boolean;
}

function Footer({
  settings,
  socialLinksSettings,
  privacyPolicySettings,
  footerTextSettings,
  isEditionSettings = true,
}: FooterProps) {
  const [errorMessage, setErrorMessage] = useState('');

  const getSocialSettings = (
    socialPageLinks: SocialPageLinksSettings[] | []
  ) => {
    const facebookSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.FACEBOOK
    );
    const instagramSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.INSTAGRAM
    );
    const twitterSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.TWITTER
    );
    const linkedInSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.LINKEDIN
    );
    const pinterestSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.PINTEREST
    );
    const youtubeSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.YOUTUBE
    );
    const tiktokSettings = socialPageLinks.find(
      socialPage => socialPage.socialNetworkType === SOCIAL_LINKS.TIKTOK
    );

    return {
      facebookSettings,
      instagramSettings,
      twitterSettings,
      linkedInSettings,
      pinterestSettings,
      youtubeSettings,
      tiktokSettings,
    };
  };

  const socialPageLinkSettings: SocialPageLinkSettingsObject =
    socialLinksSettings
      ? getSocialSettings(socialLinksSettings.socialPageLinks)
      : {};

  const handlePrivacyPolicyChange = (newValue: string) => {
    // TODO: Need to change this to when you save rather than onChange
    const formattedValue = checkLinkSecure(newValue);

    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
      settingKey: 'privacyPolicy',
      settingValue: formattedValue,
    });
  };

  const handlePrivacyPolicyToggle = (newValue: boolean) => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
      settingKey: 'isEnabled',
      settingValue: newValue,
    });
  };

  const handleFreeTextChange = (newValue: string) => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.FOOTER_TEXT,
      settingKey: 'footerText',
      settingValue: newValue,
    });
  };

  const handleFreeTextToggle = (newValue: boolean) => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.FOOTER_TEXT,
      settingKey: 'isEnabled',
      settingValue: newValue,
    });
  };

  const handleSocialLinksToggle = (
    newValue: boolean,
    socialSettings: SocialPageLinksSettings | undefined,
    socialNetwork: keyof typeof SOCIAL_LINKS
  ) => {
    const newSocialSetting = {
      isEnabled: newValue,
      socialNetworkType: socialNetwork,
      linkPath: socialSettings?.linkPath ?? '',
    };

    updateSocialLinkSetting(newSocialSetting);
  };

  const handleSocialLinksChange = (
    newValue: string,
    socialSettings: SocialPageLinksSettings | undefined,
    socialNetwork: keyof typeof SOCIAL_LINKS
  ) => {
    const newSocialSetting = {
      isEnabled: socialSettings?.isEnabled ?? true,
      socialNetworkType: socialNetwork,
      linkPath: newValue,
    };

    updateSocialLinkSetting(newSocialSetting);
  };

  const updateSocialLinkSetting = (newSetting: SocialPageLinksSettings) => {
    const newSocialPageLinks = addOrReplaceItem(
      socialLinksSettings?.socialPageLinks,
      newSetting,
      'socialNetworkType'
    );

    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.SOCIAL_LINKS,
      settingKey: 'socialPageLinks',
      settingValue: newSocialPageLinks,
    });
  };

  const checkLinkSecure = (value: string) => {
    let checkedLink = value.replace(/^http:\/\//i, 'https://');
    if (!checkedLink.startsWith('https://')) {
      checkedLink = 'https://';
      setErrorMessage('Link must start with https://');
    } else if (!/^https?:\/\//i.test(checkedLink)) {
      checkedLink = `https://${checkedLink}`;
    }
    return checkedLink;
  };

  return (
    <>
      <FooterSocial
        socialPageSettings={socialPageLinkSettings}
        handleSocialLinksChange={handleSocialLinksChange}
        handleSocialLinksToggle={handleSocialLinksToggle}
      />

      <Heading variant="h4" mb={4}>
        Other
      </Heading>
      <Flex flexDir="column" gap={4}>
        <FooterCheckbox
          label="Privacy Policy link"
          id="privacy-policy-link"
          name="privacy-policy-link"
          onCheck={() => handlePrivacyPolicyToggle(true)}
          onUncheck={() => handlePrivacyPolicyToggle(false)}
          value={privacyPolicySettings?.isEnabled ?? false}
        >
          <FooterPrivacyPolicyInput
            value={privacyPolicySettings?.privacyPolicy ?? ''}
            validationError={errorMessage}
            onChange={handlePrivacyPolicyChange}
          />
        </FooterCheckbox>
        {isEditionSettings && (
          <FooterCheckbox
            label="Free text"
            id="free-text"
            name="free-text"
            onCheck={() => handleFreeTextToggle(true)}
            onUncheck={() => handleFreeTextToggle(false)}
            value={footerTextSettings?.isEnabled ?? false}
          >
            <FooterFreeTextInput
              value={footerTextSettings?.footerText ?? ''}
              onChange={handleFreeTextChange}
            />
          </FooterCheckbox>
        )}
      </Flex>
    </>
  );
}

export default Footer;
