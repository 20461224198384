import { Input } from '@ebx-ui/ebx-ui-component-library-sdk';

import Wrapper from 'components/editor/blocks/Wrapper';

interface ColorPickerProps {
  header?: string;
  label?: string;
  name: string;
  onChange: (name: string, value: string) => void;
  value: string;
}

function ColorPicker({
  header,
  label,
  name,
  onChange,
  value,
}: ColorPickerProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, event.target.value);
  };

  return (
    <Wrapper header={header} label={label}>
      <Input onChange={handleChange} type="color" value={value} />
    </Wrapper>
  );
}

export default ColorPicker;
