import PropTypes from 'prop-types';
import { useState } from 'react';

import DatePickerInput from 'components/editor/schedule/DatePickerInput';
import DatePicker from 'react-datepicker';

import { DATE_PICKER_START_DAY } from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';

import { POPPER_MODIFIERS } from 'common/config';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

function SpecificTime({
  scheduledUnixTime,
  setScheduledUnixTime,
  setFinishedSendingTime,
}) {
  const [startDate, setStartDate] = useState(
    new Date(scheduledUnixTime * 1000)
  );

  const changeStartDate = date => {
    // Makes sure new date is larger than the current date so user can't set time in the past
    const newDate = date >= new Date() ? date : new Date();

    setStartDate(newDate);
    setScheduledUnixTime(getUnixTimestamp(newDate));
    setFinishedSendingTime(getUnixTimestamp(newDate));
  };

  return (
    <div className="mt-2 pl-4 w-100">
      <div className="d-flex flex-wrap">
        <div className="mr-2 date-picker-container">
          <DatePicker
            customInput={<DatePickerInput date={true} />}
            dateFormat="d-MMM-yy"
            minDate={new Date()}
            popperModifiers={POPPER_MODIFIERS}
            onChange={changeStartDate}
            selected={startDate}
            calendarStartDay={DATE_PICKER_START_DAY}
          />
        </div>
        <div>
          <DatePicker
            customInput={<DatePickerInput date={false} />}
            dateFormat="HH:mm"
            onChange={changeStartDate}
            popperModifiers={POPPER_MODIFIERS}
            selected={startDate}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="Start Time"
            timeFormat="HH:mm"
            timeIntervals={30}
          />
        </div>
      </div>
    </div>
  );
}

SpecificTime.propTypes = {
  scheduledUnixTime: PropTypes.number.isRequired,
  setScheduledUnixTime: PropTypes.func.isRequired,
  setFinishedSendingTime: PropTypes.func.isRequired,
};

export default SpecificTime;
