/* eslint react-hooks/exhaustive-deps: "off" */

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import { PREVIEW_TYPES } from 'common/enums';
import * as errors from 'common/errors';
import { loadFont } from 'common/googlefonts';
import * as logger from 'common/logger';
import PreviewWrapper from 'components/editor/preview/PreviewWrapper';
import Loading from 'components/settings/Loading';
import useSettings from 'hooks/useSettings';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Content, Sidebar } from '../../common';
import ContentSetting from './ContentSetting';

export default function UnsubscribeBranding() {
  const { urn } = useParams();

  const settings = useSettings();
  const toast = useToast();

  useEffect(() => {
    settings.getCampaignSettings(urn);
  }, [urn]);

  useEffect(() => {
    // pub-sub
    const handleSave = async () => {
      logger.info('UnsubscribeBranding:handleSave');

      try {
        await settings.saveCampaignSettings({
          campaignURN: urn,
        });
        settings.setDone();
        toast({ variant: 'success', title: 'Changes saved successfully' });
      } catch (error) {
        const errorMessage = errors.getErrorMessage(error);
        settings.setError(errorMessage);
        toast({ variant: 'error', title: errorMessage });
        settings.setChanged();
      }
    };

    settings.setOnSave(handleSave);
  }, [settings, urn]);

  const titleFont = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'titleFont'
  );

  const bodyFont = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'bodyFont'
  );

  loadFont(titleFont);
  loadFont(bodyFont);

  const unsubscribeTitleCopy = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
    'unsubscribeTitleCopy'
  );

  const unsubscribeBodyCopy = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
    'unsubscribeBodyCopy'
  );

  const unsubscribeCancelButtonLabel = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
    'cancelButtonLabel'
  );

  const unsubscribeRedirectMessage = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.HOSTED_SUBS_UNSUBSCRIBE,
    'redirectMessage'
  );

  if (settings.isLoading) {
    return (
      <div className="w-100 mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <Row className="settings-branding w-100 m-0">
      <Col xs={12} md={4} xl={3} className="p-0">
        <Sidebar>
          <Link
            to={`/settings/campaigns/${urn}/branding`}
            className="d-flex align-items-center mb-3"
          >
            <ArrowBackIcon className="settings-back-arrow mr-1" />
            <span className="ebx-h3">Back to Branding Settings</span>
          </Link>
          <ContentSetting
            settingType={BRANDING_SETTINGS_SUB_PAGES.UNSUBSCRIPTION_PAGE}
            unsubscribeTitleCopy={unsubscribeTitleCopy}
            unsubscribeBodyCopy={unsubscribeBodyCopy}
            unsubscribeCancelButtonLabel={unsubscribeCancelButtonLabel}
            unsubscribeRedirectMessage={unsubscribeRedirectMessage}
            settings={settings}
          />
        </Sidebar>
      </Col>
      <Col xs={12} md={8} xl={9} className="p-0">
        <Content>
          <PreviewWrapper
            campaignURN={urn}
            previewTypes={[PREVIEW_TYPES.HOSTED_SUBS_UNSUBSCRIBE]}
            settings={settings}
          />
        </Content>
      </Col>
    </Row>
  );
}
