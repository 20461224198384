import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useReducer, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import PasswordRules from 'components/users/PasswordRules';
import SignInTemplate from 'components/users/SignInTemplate';

import * as API from 'api/api';
import * as authentication from 'common/authentication';
import { hideWidget } from 'common/zendesk';

import { ROUTE_REDIRECTIONS } from 'common/constants';

function NewPassword() {
  const [password, setPassword] = useState('');
  const [showPassword, toggleShowPassword] = useReducer(
    previous => !previous,
    false
  );
  const history = useHistory();
  const toast = useToast();

  const passwordShowText = showPassword ? 'Hide password' : 'Show password';
  const textType = showPassword ? 'text' : 'password';
  const containsUpperCase = /(?=.*[A-Z])/.test(password);
  const containsLowerCase = /(?=.*[a-z])/.test(password);
  const containsNumberCharacter = /^(?=.*[0-9])/.test(password);
  const containsTenCharacters = password.length >= 10;
  const canSave =
    containsLowerCase &&
    containsUpperCase &&
    containsNumberCharacter &&
    containsTenCharacters;

  useEffect(() => {
    hideWidget();
  }, []);

  if (!authentication.hasChallengeDetails()) {
    /* Redirect to login page if no challenge details exist */
    history.push(ROUTE_REDIRECTIONS.SIGNIN);
  }

  const handleSubmit = async () => {
    try {
      const challengeDetails = authentication.getChallengeDetails();
      const challengeUser = authentication.getChallengeUser();
      await API.authCompleteNewPassword({
        user: challengeUser,
        password,
        attributes: challengeDetails.requiredAttributes,
      });
      authentication.signOut();
      toast({
        variant: 'success',
        title:
          'Password reset successfully, please sign in using your new credentials',
      });
      setTimeout(() => {
        history.push(ROUTE_REDIRECTIONS.SIGNIN);
      }, 2000);
    } catch (error) {
      toast({
        variant: 'error',
        title: 'An error occurred setting your new password',
      });
    }
  };

  const handleChangePassword = event => {
    event.preventDefault();
    const newPasswordValue = event.target.value;
    setPassword(newPasswordValue);
  };

  return (
    <SignInTemplate title="Set your new password" hideDemo>
      <div className="signin_box">
        <Form>
          <Form.Label htmlFor="password" className="w-100">
            <div className="d-flex align-items-center justify-content-between">
              <span className="ebx-faded-colour ebx-h5">New password</span>
              <Button
                className="ebx-h5 ebx-body-colour pr-0"
                variant="link"
                onClick={toggleShowPassword}
              >
                {passwordShowText}
              </Button>
            </div>
            <Form.Control
              className="ebx-input ebx-body-1 mb-3"
              type={textType}
              id="newpassword"
              value={password}
              onChange={handleChangePassword}
            />
            <PasswordRules
              passwordRules={{
                containsTenCharacters,
                containsLowerCase,
                containsUpperCase,
                containsNumberCharacter,
              }}
            />
          </Form.Label>
          <Button
            className="btn w-100 mt-3 p-2"
            variant="dark"
            type="button"
            onClick={handleSubmit}
            disabled={!canSave}
          >
            Reset your password
          </Button>
        </Form>
      </div>
    </SignInTemplate>
  );
}

export default NewPassword;
