import axios from 'axios';

import { handleAPIError } from 'common/errors';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

import {
  apiBaseURL,
  APIS,
  API_TIMEOUTS,
  getRequestHeaders,
} from 'api/settings';

export default async function getSubscriberUploadList({
  campaignURN = mandatory('campaignURN'),
  stateIds = '1,2,3,4,5',
} = {}) {
  const guid = metrics.start('getSubscriberUploadList');

  const config = {
    url: `${apiBaseURL(
      APIS.NEWSLETTERS
    )}/campaigns/${campaignURN}/subscribers/uploads/state/${stateIds}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getRequestHeaders(),
  };
  logger.info(
    `API:getSubscriberUploadList /campaigns/${campaignURN}/subscribers/uploads/state/${stateIds}`
  );

  try {
    const response = await axios(config);
    metrics.end('getSubscriberUploadList', guid);
    return response.data.uploadURNs;
  } catch (error) {
    metrics.fail('getSubscriberUploadList', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getSubscriberUploadList',
      args: { campaignURN },
    });
    throw apiError;
  }
}
