import ProfileSectionPanel from 'components/settings/profile/ProfileSectionPanel';

import useGlobalInfo from 'hooks/useGlobalInfo';

import { USER_TYPES } from 'common/enums';

import './Profile.css';

function Profile() {
  const globalInfo = useGlobalInfo();
  const currentUser = globalInfo.user;

  return (
    <>
      <div className="profile-title-wrapper">
        <h2 className="profile-title">Profile</h2>
      </div>
      <ProfileSectionPanel
        className="mb-2"
        title="Personal details"
        description="Edit your name and email address"
        url="personal-details"
      />
      {currentUser?.userType !== USER_TYPES.ECHOBOX_STAFF && (
        <ProfileSectionPanel
          title="Password"
          description="Update your password"
          url="password"
        />
      )}
    </>
  );
}

export default Profile;
