import { Select } from '@ebx-ui/ebx-ui-component-library-sdk';

interface SelectPickerProps {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  onChange: (name: string, value: string) => void;
  value: string;
}

function SelectPicker({ name, onChange, options, value }: SelectPickerProps) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(name, event.target.value);
  };

  return (
    <Select onChange={handleChange} value={value} w="100%">
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}

export default SelectPicker;
