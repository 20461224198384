import {
  BRANDING_SETTINGS_SUB_PAGES,
  CAMPAIGN_SETTING_TYPES,
} from 'common/constants';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';

import { BackLink, Container, Title } from '../../common';

function ButtonText({ setSettingsPanel, settings }) {
  const callToActionText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'callToActionText'
  );

  const unsubscribeLinkText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
    'unsubscribeLinkText'
  );

  const privacyPolicyLinkText = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
    'privacyPolicyLinkText'
  );

  const privacyPolicy = settings.getCampaignSettingValue(
    CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
    'privacyPolicy'
  );

  const setCallToActionText = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'callToActionText',
      settingValue: event.target.value,
    });
  };

  const setUnsubscribeLinkText = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.TEMPLATE_STYLING,
      settingKey: 'unsubscribeLinkText',
      settingValue: event.target.value,
    });
  };

  const setPrivacyPolicyLinkText = event => {
    settings.setCampaignSettingValue({
      settingType: CAMPAIGN_SETTING_TYPES.PRIVACY_POLICY,
      settingKey: 'privacyPolicyLinkText',
      settingValue: event.target.value,
    });
  };

  return (
    <Container>
      <Button
        variant="link"
        className="m-0 p-0 mb-3"
        onClick={() =>
          setSettingsPanel(
            BRANDING_SETTINGS_SUB_PAGES.EMAIL_EDITION_PAGE.TEXT_OPTIONS
          )
        }
      >
        <BackLink linkText="Back to Text Options" />
      </Button>
      <Title className="mb-3">
        <span className="mr-3">Button text</span>
      </Title>
      <Title className="settings-subtitle">
        <span className="mr-4">Open article text</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        placeholder="Read More"
        value={callToActionText}
        onChange={setCallToActionText}
      />
      <Title className="settings-subtitle">
        <span className="mr-4">Unsubscribe text</span>
      </Title>
      <Form.Control
        className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
        style={{ width: '100%' }}
        type="text"
        placeholder="Unsubscribe"
        value={unsubscribeLinkText}
        onChange={setUnsubscribeLinkText}
      />
      {privacyPolicy && (
        <>
          <Title className="settings-subtitle">
            <span className="mr-4">Privacy Policy text</span>
          </Title>
          <Form.Control
            className="ebx-input ebx-input-group ebx-body-1 mt-3 mb-3"
            style={{ width: '100%' }}
            type="text"
            placeholder="Privacy Policy"
            value={privacyPolicyLinkText}
            onChange={setPrivacyPolicyLinkText}
          />
        </>
      )}
    </Container>
  );
}

ButtonText.propTypes = {
  setSettingsPanel: PropTypes.func.isRequired,
  settings: PropTypes.node.isRequired,
};

export default ButtonText;
